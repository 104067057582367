import React from "react";
import { GSForm, GSSidePanelPage, GSEmptyList } from "golfstatus_react_components";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-default-watch-items.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";
import {
  defaultWatchItemsForm,
} from "../../../forms/SettingsForms";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentDefaultWatchItems,
  selectDefaultWatchItems,
  selectSettingsNotifications,
  setCurrentWatchItem,
} from "../../../reducers/settingsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";

//Name the component
const TouranmentSettingsDefaultWatchItems = (props) => {
  const [search, setSearch] = useState("")
  const defaultWatchItems = useSelector(selectDefaultWatchItems);

  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/settings/default-watch-items");

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentDefaultWatchItems(match?.params?.tournamentID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.tournamentID]);

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.getFilteredWatchItems = () => {
    return defaultWatchItems?.filter?.((wi) => wi?.email?.toLowerCase?.()?.includes?.(search?.toLowerCase?.()))
  }

  context.getSearch = () => {
    return {search, setSearch, placeholder: "Search by Email Address..."}
  }

  context.getEmptyMessage = () => {
    if(search === ""){
      return <GSEmptyList title="Tournament Watch Emails"
      detail="Manage who receives email notifications for all tournament activity." 
      actions={[
        {
          title: "Add Email",
          buttonIcon: faPlus,
          onClick: context.addWatchEmail,
          type: "black",
        },
      ]}/>
    }

    return <GSEmptyList title="We couldn’t find the email address you’re looking for…"
    detail="Try changing the search term or add a new email address." 
    actions={[
      {
        title: "Clear Search",
        onClick: () => {
          setSearch("");
        },
        type: "light-grey",
      },
      {
        title: "Add Email",
        buttonIcon: faPlus,
        onClick: context.addWatchEmail,
        type: "black",
      },
      
    ]}/>
    
  }

  context.editWatchEmail = (item) => {
    dispatch(setCurrentWatchItem({}));
    navigate(item.id);
  };

  context.addWatchEmail = () => {
    dispatch(setCurrentWatchItem({}));
    navigate("add");
  };

  const navigate = useNavigate();
  const notifications = useSelector(selectSettingsNotifications);

  const save = () => {
    setIsValid(false);
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Tournament Watch Emails`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormActions = () => {
    return [
      { title: "add email", type: "black no-wrap mobile-icon", actionIcon: faPlus, actionClick: context?.addWatchEmail },
    ]
  }

  const getContent = () => {
    return (
      <GSForm
        formTitle="Tournament Watch Emails"
        formActions={getFormActions()}
        formSections={defaultWatchItemsForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  let notificationSettings = {
    notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-settings-default-watch-items>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-settings-default-watch-items>
  );
};

//export the new namet
export default TouranmentSettingsDefaultWatchItems;
