import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-discount-add-edit.scss";
import { getDiscountForm } from "../../../../forms/DiscountForm";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  createTournamentDiscount,
  getDiscountCode,
  saveTournamentDiscount,
  selectCurrentDiscountCode,
  selectNotifications,
  setCurrentDiscountCode,
} from "../../../../reducers/discountSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useFormValidation } from "../../../../hooks/formHooks";

//Name the component
const TournamentDiscountAddEdit = (props) => {
  const [promotionType, setPromotionType] = useState({
    label: "Amount Off",
    value: 1,
  });
  const [usageLimit, setUsageLimit] = useState({ value: 1 });

  //hooks
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/promotion/discounts/:discountID");
  const dispatch = useDispatch();

  //selectors
  const currentDiscountCode = useSelector(selectCurrentDiscountCode);
  const currentTournament = useSelector(selectCurrentTournament);
  const discountNotifications = useSelector(selectNotifications);

  //custom hooks
  useNotificationNavigation("created", discountNotifications, -1);

  //effects
  useEffect(() => {
    if (match?.params?.discountID && match?.params?.discountID !== "add") {
      dispatch(getDiscountCode(match.params.discountID));
    }
  }, [dispatch, match?.params?.discountID]);

  //context
  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateDiscount = (value, property) => {
    setIsValid(true);
    let update = { ...currentDiscountCode };
    update[property] = value;
    dispatch(setCurrentDiscountCode(update));
  };

  context.getPromotionType = () => {
    return promotionType;
  };

  context.setPromotionType = (option) => {
    setPromotionType(option);
  };

  context.getUsageLimit = () => {
    return usageLimit;
  };

  context.setUsageLimit = (option) => {
    setUsageLimit(option);
  };

  context.generateCode = () => {
    let newCode = Math.random()
      .toString(36)
      .substring(2, 10)
      .toUpperCase();
    context.updateDiscount(newCode, "code");
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentDiscountCode };
    update.tournament = currentTournament;
    if (update.activateOn) {
      const activationDate = new Date(update?.activateOn);
      update.activateOn = activationDate?.toISOString?.()
    }
    if (update.deactivateOn) {
      const deactivationDate = new Date(update?.deactivateOn);
      update.deactivateOn = deactivationDate?.toISOString?.()
    }
    if (promotionType.value === 1) {
      update.discountPercent = undefined;
    } else {
      update.discountDollarAmount = undefined;
    }
    if (update.id) {
      dispatch(saveTournamentDiscount(update));
    } else {
      dispatch(createTournamentDiscount(update));
    }
  };

  const removeDiscount = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if (currentDiscountCode.id) {
      actions.push({ name: "Delete Discount", action: removeDiscount, type: "transparent red" });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Discount Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getActiveAction = () => {
    return [
      {
        actionType: "toggle",
        value: true,
        pageActionProps: {
          falseDescription: "Inactive",
          rowReverse: true,
          onClick: (e) =>
            context.updateDiscount(
              currentDiscountCode?.status === "active" ? "inactive" : "active",
              "status"
            ),
          trueDescription: "Active",
          value: currentDiscountCode?.status === "active",
        },
      },
    ];
  };

  const getFormActionBar = () => {
    return {
      formTitle: match?.params?.discountID === "add" ? "Add Discount" : "Edit Discount",
      formActions: getActiveAction(),
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormActionBar()}
        formSections={getDiscountForm(context, currentDiscountCode)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: discountNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-discount-add-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-discount-add-edit>
  );
};

//export the new namet
export default TournamentDiscountAddEdit;
