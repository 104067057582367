import moment from "moment";

export function getDate(dateTime) {
  return `${moment(dateTime).format("ll")}`;
}
export function getTime(dateTime) {
  return `${moment(dateTime).format("LT")}`;
}

export function getUtcDate(dateTime) {
  return `${moment(dateTime)
    .utc()
    .format("ll")}`;
}

export function getDateTime(dateTime) {
  return `${moment(dateTime).format("ll")} at ${moment(dateTime).format("LT")}`;
}

export function getDateTimeOn(dateTime) {
  return `${moment(dateTime).format("LT")} on ${moment(dateTime).format("ll")}`;
}

export function getDateTimeFromTo(dateTimeFrom, dateTimeTo) {
  return `from ${moment(dateTimeFrom).format("LL")} to ${moment(
    dateTimeTo
  ).format("LL")}`;
}

export function getDateMDY(date) {
  return moment(date).format("DD-MM-YYYY");
}

export function getDateYMD(date) {
  return moment(date).format("yyyy-MM-DD");
}

export function getDateTimeYMD(date) {
  return moment(date).format("YYYY-MM-DDTHH:mm")
}

export function getUtcYMD(date) {
  return moment(date)
    .utc()
    .format("YYYY-MM-DDTHH:mm");
}

export function getIsoDateTime(date) {
  return moment(date).format();
}

// returns datetime set to noon that date
export function getIsoDateTimeNoon(date) {
  let val = moment(date);
  val.hours(12);
  return val.format();
}


export function getDaysTill(date1, date2){
  const d1 = moment(date1)
  const d2 = moment(date2)
  return d1.diff(d2, 'days')
}

export function getDaysTillNow(date1){
  const d1 = moment(date1)
  const d2 = moment()
  return d1.diff(d2, 'days')
}

export function getYearsTillNow(date1){
  const d1 = moment(date1)
  const d2 = moment()
  return d1.diff(d2, 'years')
}

export function getShortMonthDate(date) {
  return moment(date).format("MMM DD, YYYY")
}
