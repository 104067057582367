import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";

const TournamentLeaderboardLineItem = (props) => {
  const { name, customName, main, privacy, flighted } = props;

  const mainSection = {
    sectionItems: [
      {
        type: "no-wrap full-width",
        value: (
          <GSButton type="mobile-icon" rightIcon={privacy === "public" ? faEye : faEyeSlash} title={privacy}/>
        ),
      },
    ]
  }

  const section = {
    sectionItems: [
      {
        type: "primary full-width",
        value: ( name),
      },
      {
        type: "secondary full-width",
        value: (customName),
      },
      
    ],
  };

  if(!main){
    section.sectionItems.push({
      type: "secondary no-wrap full-width",
      value: flighted ? "Flighted" : "",
    })
  }

  const section2 = {
    sectionItems: [
      {
        type: "primary",
        value: main? <GSButton type="status secondary pill green" title="Main Leaderboard"/> : ""
      }
    ],
  };

  //colums
  const firstColumn = {
    fit: "stretch",
    sections: [
      {
        ...section,
      },
    ],
  };

  if(main){
    firstColumn.sections.push({...section2})
  }

  
  const secondColumn = {
    sections: [
      {
        ...mainSection,
      },
    ],
  };

  

  const getGroup = () => {
    let group = [{ ...firstColumn}, {...secondColumn}];
    return group;
  };

  return (
    <tournament-leaderboard-line-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-leaderboard-line-item>
  );
};

export default TournamentLeaderboardLineItem;
