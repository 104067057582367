import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-order-void-invoice.scss";
import { useNotificationAction, useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";
import { getWarningTitle, warnItemForm } from "../../../forms/DeleteForm";
// import { getReportingForm } from "../../../forms/ReportingForm";
import { useSelector } from "react-redux";
import { clearNotifications, getOrders, voidInvoice, selectNotifications, selectCurrentOrder, getOrder } from "../../../reducers/reportingSlice";
import { useDispatch } from "react-redux";
import TournamentReportingOrderItem from "./tournament-reporting-order-item";

//Name the component
const TournamentReportingOrderVoidInvoice = (props) => {
  const [isValid, setIsValid] = useState(true);
  const match = useMatch("/:tournamentID/reporting/:orderID/void-invoice");
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const currentOrder = useSelector(selectCurrentOrder);
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    if(match?.params?.orderID){
      dispatch(getOrder(match.params.orderID))
    }
  }, [dispatch, match?.params?.orderID])

  useNotificationNavigation("saved", notifications, -1, "Void Invoice")
  useNotificationAction("saved", notifications, (notification) => {dispatch(getOrders(match?.params?.tournamentID))}, "Void Invoice")

  const voidIt = () => {
    setIsValid(false);
    dispatch(voidInvoice(match?.params?.orderID))
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Void Invoice",
        isDisabled: !isValid,
        action: voidIt,
        type: "orange",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Void Invoice`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getWarningTitle("Void Invoice")}
        formSections={warnItemForm(
          "Are you sure you want to void this invoice? This cannot be undone.",
          <TournamentReportingOrderItem {...currentOrder} />
        )}
      />
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-order-void-invoice>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-order-void-invoice>
  );
};

//export the new namet
export default TournamentReportingOrderVoidInvoice;
