import React, { useState } from "react";
import {
  GSSidePanelPage,
  GSItemList,
  GSInput,
  GSActionBar,
  GSEmptyList,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faPlus,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-packages.scss";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentPackages,
  selectLoading,
  selectNotifications,
  selectTournamentPackages,
  setCurrentPackage,
  setCurrentPackageItems,
} from "../../../../reducers/packageSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import TournamentPackagesItem from "./tournament-packages-item";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionPackages = (props) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/promotion/packages");

  const tournamentPackages = useSelector(selectTournamentPackages);
  const notifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading)

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPackages(match.params.tournamentID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.tournamentID]);

  const addPackage = () => {
    dispatch(setCurrentPackage({ state: "active" }));
    dispatch(setCurrentPackageItems([]));
    navigate("add");
  };

  const packageDetails = (tournamentPackage) => {
    navigate(`${tournamentPackage.id}`);
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Add & Manage Packages"
        detail="Add registration, sponsorship, skins, and other packages to your event site."
        actions={[
          {
            title: "Add Package",
            buttonIcon: faPlus,
            onClick: addPackage,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the package you’re looking for…"
        detail="Try changing the search term or add a new package."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
          {
            title: "Add Package",
            onClick: addPackage,
            buttonIcon: faPlus,
            type: "black",
          },
        ]}
      />
    );
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(`/${match?.params?.tournamentID}/promotion`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Packages`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFilteredPackages = () => {
    return tournamentPackages?.filter?.((tp) => tp.name.includes?.(search));
  };

  const getPackageActions = () => {
    return [
      {
        title: "Add package",
        actionIcon: faPlus,
        actionClick: addPackage,
        type: "mobile-icon black",
      },
    ];
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar
          type="form-header"
          header="Packages"
          pageActions={getPackageActions()}
        />
        <div className="search">
          <GSInput
            textValue={search}
            placeholder="Search Packages…"
            leftIcon={faSearch}
            rightIcon={faTimesCircle}
            rightIconClick={() => setSearch("")}
            onChange={(e) => setSearch(e.target.value)}
          ></GSInput>
        </div>
        <GSItemList
          type="vertical selectable"
          items={getFilteredPackages()}
          itemSelected={packageDetails}
          listItem={(item) => <TournamentPackagesItem {...item} />}
          emptyMessage={
            search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
          }
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications,
    timeoutAction,
  });

  return (
    //name the component tag
    <tournament-promotion-packages>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-promotion-packages>
  );
};

//export the new namet
export default TournamentPromotionPackages;
