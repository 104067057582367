import { GSPageBanner, GSItemInfo } from "golfstatus_react_components";

export function getNotificationItemInfo(value, icon, header, description){
  return <GSItemInfo header={header} description={description} value={value} icon={icon}></GSItemInfo>
}

export function bannerNotificationConverter(notifications, actions) {
  if (notifications) {
    const bannerNotifications = notifications.map((notification) => ({
      header: <GSItemInfo {...notification.info}></GSItemInfo>,
      pageActions: notification.actions,
      state: notification.state,
    }));
    return <GSPageBanner notifications={bannerNotifications} navigationActions={actions}></GSPageBanner>;
  }
}

export const getRoundName = (round) => {
  return round?.roundName ? round?.roundName : round?.number
}

export const getTotalPar = (roundHoles) => {
  return (roundHoles?.reduce?.((total, hole) => (total += hole.par), 0) ?? 0)
}
export const getTotalYardage = (roundHoles) => {
  return (roundHoles?.reduce?.((total, hole) => (total += hole.yardage), 0) ?? 0)
}


export const getStateColor = (round) => {
  switch (round.state) {
    case "live":
      return "green";
    case "ready":
      return "black";
    case "draft":
      return "light-grey";
    case "final":
      return "black";
    default:
      return "transparent";
  }
};