
import { moneyWithCommas } from "../helpers/RegexHelper";
import TournamentReportingOrderItem from "../components/tournament/tournament-reporting/tournament-reporting-order-item";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { getShortMonthDate } from "../helpers/Dates";

export const getReportingForm = (context, currentOrder) => {
  if (context && currentOrder) {
    let orderDetailsFields = [
      ...(currentOrder?.registrationOrderItems?.map?.((roi) => ({
        label: `${roi.tournamentPackage.name} (${roi.quantity}x)`,
        value: moneyWithCommas(roi.tournamentPackage.packageCost),
        type: "row",
      })) ?? []),
      {
        label: "Fee",
        type: "row",
        value: moneyWithCommas(currentOrder?.feesPaidByRegistrant),
      },
    ]

    if(currentOrder?.discountCodes?.length > 0)
    {
      orderDetailsFields.push({
        label: "Subtotal",
        type: "row",
        value: moneyWithCommas(currentOrder?.totalCostBeforeDiscounts),
      },
      {
        label: `Discount ${currentOrder.discountCodes?.length === 0 ? "" : `code ${currentOrder.discountCodes?.[0]?.code}`}`,
        type: "row",
        value: `- ${moneyWithCommas(currentOrder?.discountAmount)}`,
      })
    }

    if(currentOrder?.donationOrderItems?.length > 0)
    {
      let donationFields = currentOrder?.donationOrderItems?.map?.(
        (donation) => (
          {
            label: donation?.roundUp ? "Round Up" : "donation",
            value: moneyWithCommas(donation.usdTotalCost),
            type: "row"
          }
        )
      )

      orderDetailsFields.push(...donationFields)
      
    }

    orderDetailsFields.push({
      label: "Total",
      type: "row",
      value: moneyWithCommas(currentOrder?.purchaseCost),
    })

    const totalRefundAmount = currentOrder?.registrationOrderRefunds?.reduce((total, refund) => total + (refund?.amount || 0), 0)

    let refundFields = [
      {
        label: "Non-Refundable Fee",
        type: "row",
        value: `- ${moneyWithCommas(currentOrder?.nonRefundableFees)}`,
      },
    ]

    if (currentOrder?.registrationOrderRefunds?.length > 0) {
      let refundRows = currentOrder?.registrationOrderRefunds?.map(refund => ({
        label: `Refund - ${getShortMonthDate(refund.updatedAt)}`,
        type: "row",
        value: `- ${moneyWithCommas(refund?.amount)}`
      }))
      refundFields?.push?.(...refundRows)
    }

    refundFields?.push?.(
      {
        label: "Refundable amount",
        type: "row",
        value: moneyWithCommas(currentOrder?.paidElsewhere ? 0 : (currentOrder?.netProfit - totalRefundAmount)),
      },
    )

    let form =  [
      {
        type: "vertical large-gap",
        extras: (
          <TournamentReportingOrderItem
            {...currentOrder}
          ></TournamentReportingOrderItem>
        ),
      },
      {
        type: "vertical xx-large-gap row-fields",
        title: "Order Details",
        fields: orderDetailsFields ,
      },
    ].concat(currentOrder?.paymentType === 'customer_invoice' ? [] : [
      {
        type: "vertical xx-large-gap row-fields",
        title: "Refund Details",
        fields: refundFields
      },
    ]);

    return form
  }
};

export const getPayoutRequestForm = (context) => {
  if (context) {
    return [
      {
        type: "vertical large-gap",
        fields: [
          //{label: "Paid By",placeholder: "Paid By", value:<GSRadioGroup options={[{label: "Check"},{label: "Write Off"},{label: "Balance Transfer"}, {label: "Golfstatus Fees"}]}/>, customView: true, isEditable: true  },
          {
            label: "Payout Amount",
            placeholder: "0.00",
            leftIcon: faDollarSign,
            value: context?.getData?.()?.amount,
            onChange: (e) => {context?.updateData?.(e.target.value, "amount")},
            isEditable: true,
            failedValidation: context.validationFailed,
            required: true,
          },
          {
            label: "Note",
            placeholder: "Note",
            value: context?.getData?.()?.memo,
            onChange: (e) => {context?.updateData?.(e.target.value, "memo")},
            type: "text-area",
            rows: 5,
            isEditable: true,
            
          },
        ],
      },
    ];
  }
};
