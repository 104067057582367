import {
  faPlus,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSInfoCard,
  GSButton,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { useMatch } from "react-router-dom";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { getPromotionPages } from "../../../forms/PromotionForm";
import {
  getTournamentPackages,
  selectTournamentPackages,
} from "../../../reducers/packageSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import NavigationListItem from "../../navigation-list-item";
import TournamentPackagesItem from "./tournament-packages/tournament-packages-item";
import "./tournament-promotion.scss";
const TournamentPromotion = (props) => {
  const [search, setSearch] = useState("");

  const match = useMatch("/:tournamentID/promotion/*");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPackages(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentPackages = useSelector(selectTournamentPackages);

  const getPageActions = () => {
    return [];
  };

  const getSearchActions = () => {
    return [];
  };

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        placeholder="Search Event Site and Packages…"
        rightIconClick={() => {
          setSearch("");
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></GSInput>
    );
  };

  const settingSelected = (page) => {
    if (isSidePanelOpen()) {
      navigate(`/${match?.params?.tournamentID}/promotion`);
      return;
    }
    if (page.isExternal) {
      window.open(page.url);
    } else {
      navigate(page.url);
    }
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Tournament Promotion"
        detail="Promote your tournament with the settings provided"
        actions={[
          {
            title: "Add",
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find what you’re looking for…"
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getPackageList = () => {
    return (
      <GSItemList
        type="horizontal scrollable x-large-pad large-gap bottom-border"
        items={tournamentPackages}
        itemSelected={(item) => {isSidePanelOpen() ? navigate("./") : navigate(`packages/${item.id}`)}}
        listItem={(item) => (
          <GSInfoCard
            size="large"
            layout={<TournamentPackagesItem {...item}></TournamentPackagesItem>}
          ></GSInfoCard>
        )}
        emptyMessage={
          <GSInfoCard size="large centered" layout={<GSButton buttonIcon={faPlus} title="add package" onClick={(e) => {navigate("packages/add"); e?.stopPropagation?.()} }/>}></GSInfoCard>
        }
      ></GSItemList>
    );
  };

  const getFilteredPages = () => {
    return getPromotionPages(currentTournament, getPackageList()).filter(
      (p) => p.label.includes(search) || p.details.includes(search)
    );
  };

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical selectable"
        items={getFilteredPages()}
        listItem={(item) => <NavigationListItem page={item} />}
        itemSelected={settingSelected}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  return (
    <tournament-promotion>
      <GSListPage
        title="Event Site & Packages"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-promotion>
  );
};

export default TournamentPromotion;
