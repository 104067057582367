import {
  GSFileSelect,
  GSQuickFilter,
  GSToggle,
  GSButton,
  GSSelect,
} from "golfstatus_react_components";
import MemberLineItem from "../components/member-line-item";
import OrganizationLineItem from "../components/organization-line-item";
import { faArrowCircleUp, faPercent } from "@fortawesome/free-solid-svg-icons";


export const getFormSections = (tournament, context) => {
  if (context) {
    let generalInformation = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Tournament Name",
          placeholder: "Tournament Name",
          value: tournament?.name,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTournament?.(e.target.value, "name"),
          isEditable: true,
          required: true,
        },
        {
          label: "Tournament Image",
          value: (
            <GSFileSelect
              description="Tap or drag file to upload. Accepted Files: .JPG, .PNG, .GIF"
              setSelectedFiles={context?.setLogo}
              removeSourceItem={context?.removeLogo}
              sourceList={context?.getLogo?.()}
              accept=".jpg, .jpeg, .png, mp4"
              title={
                <GSButton buttonIcon={faArrowCircleUp} title="Upload"></GSButton>
              }
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          isEditable: true,
        },
      ],
    };
    let fieldInformation = {
      title: "Team Details",
      type: "vertical  xx-large-gap mobile-vertical",
      fields: [
        {
          label: "Number Of Teams",
          placeholder: "Number of Teams (Maximum of 144 Teams)",
          hintText:
            "For individual tournament formats, treat each player as a team of one.",
          value: tournament?.numberOfTeams,
          onChange: (e) =>
            context?.updateTournament?.(e.target.value, "numberOfTeams"),
          isEditable: true,
        },
        {
          label: "Waitlist",
          placeholder: "Field Size",
          value: (
            <GSToggle
              value={tournament?.hasWaitList ?? false}
              label="Automatically create a registration waitlist once the number of available teams is reached."
              trueDescription="Yes"
              falseDescription="No"
              onClick={context.toggleTournamentWaitlist}
            />
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };

    // let flightingInformation = {
    //   title: "Flighting Details",
    //   type: "vertical  xx-large-gap mobile-vertical",
    //   fields: [
    //     {
    //       label: "Number Of Flights",
    //       placeholder: "Number Of Flights",
    //       type: "number",
    //       hintText:
    //         "You'll be able to edit flight names in the advanced settings.",
    //       value: tournament?.numberOfFlights,
    //       onChange: (e) =>
    //         context?.updateTournament?.(
    //           parseInt(e.target.value),
    //           "numberOfFlights"
    //         ),
    //       isEditable: true,
    //     },
    //     {
    //       label: "Pre-Flighting",
    //       value: (
    //         <GSToggle
    //           value={tournament?.preflight ?? false}
    //           label="This tournament will be pre-flighted."
    //           trueDescription="Yes"
    //           falseDescription="No"
    //           onClick={context.togglePreflight}
    //         />
    //       ),
    //       customView: true,
    //       isEditable: true,
    //     },
    //   ],
    // };

    let organizationInformation = {
      title: "Organization Details",
      type: "vertical  xx-large-gap",
      fields: [
        {
          label: "Organization",
          description: context.getSelectedOrganization()
            ? ""
            : "Please select the organization you would like this tournament to be associated with!",
          failedValidation: context?.validationFailed,
          value: (
            <GSQuickFilter
              getDefaultSearch={context?.getOrganizationSearch}
              selectedItem={context?.getSelectedOrganization()}
              filteredList={context.getOrganizationList()}
              itemSelected={context.selectOrganization}
              itemRemoved={context.removeSelectedOrganization}
              getItem={(organization) => (
                <OrganizationLineItem type="compact" {...organization} />
              )}
            />
          ),
          validation: {
            isValid: () => context?.getSelectedOrganization?.()?.id,
            invalidLabel: "Organization selection is required.",
          },
          customView: true,
          isEditable: true,
          required: true,
        },
        {
          label: "Point Of Contact",
          value: (
            <GSQuickFilter
              getDefaultSearch={context?.getMemberSearch}
              selectedItem={context?.getSelectedPointOfContact()}
              filteredList={context.getOrganizationMembers()}
              itemSelected={context.selectPointOfContact}
              itemRemoved={context.removePointOfContact}
              emptySearchList="select an organization to show members"
              getItem={(user) => <MemberLineItem {...user} />}
            />
          ),
          validation: {
            isValid: () => context?.getSelectedPointOfContact?.(),
            invalidLabel: "Organization selection is required.",
          },
          failedValidation: context?.validationFailed,
          required: true,
          customView: true,
          isEditable: true,
        },
        {
          label: "Custom Identifier",
          placeholder: "Custom Identifier",
          hintText: "This is an internal value used for record keeping",
          value: tournament?.customIdentifier,
          onChange: (e) =>
            context?.updateTournament?.(e.target.value, "customIdentifier"),
          isEditable: true,
        },
      ],
    };
    let handicapInformation = {
      title: "Handicap Details",
      type: "vertical  xx-large-gap",
      fields: [
        {
          label: "Tournament Handicaps",
          placeholder: "Field Size",
          value: (
            <GSToggle
              value={tournament?.handicapScoring ?? false}
              label="This tournament will use handicaps."
              trueDescription="Yes"
              falseDescription="No"
              onClick={context.toggleHandicapScoring}
            />
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };
    if (tournament.handicapScoring) {
      handicapInformation.fields.push({
        label: "Handicap Percentage",
        rightIcon: faPercent,
        placeholder: "Handicap percentage",
        value: tournament?.handicapPercentage ?? "100",
        onChange: (e) =>
          context?.updateTournament?.(e.target.value, "handicapPercentage"),
        isEditable: true,
      });
      handicapInformation.fields.push({
        label: "Course Handicaps",
        description: (
          <div>
            For more Information, review the rules{" "}
            <a
              rel="noreferrer"
              href="https://www.usga.org/handicapping/roh/2020-rules-of-handicapping.html#!rule-14373,3-3"
              target={"_blank"}
            >
              USGA Rules of Handicapping.
            </a>
          </div>
        ),
        value: (
          <GSSelect
            options={handicapTypes}
            onChange={(type) => {
              context.updateTournament(type.value, "handicappingType");
            }}
            selectedOption={getSelectedHandicapType(tournament)}
          />
        ),
        customView: true,
        isEditable: true,
      });
      handicapInformation.fields.push({
        label: "Team Handicaps",
        value: (
          <GSToggle
          label="Use average handicap of players as team handicap."
            value={tournament?.usePlayerAverageHandicap ?? false}
            trueDescription="Yes"
            falseDescription="No"
            onClick={context.toggleUsePlayerAverageHandicap}
          />
        ),
        customView: true,
        isEditable: true,
      });
    }

    // let tieBreakerInformation = {
    //   title: "Tie Breaker Information",
    //   type: "vertical  xx-large-gap",
    //   fields: [
    //     {
    //       label: "Tie Breaker Method",
    //       value: (
    //         <GSSelect
    //           options={tieBreakerMethods}
    //           onChange={(method) => {
    //             context.updateTournament(method.value, "tieBreakerMethod");
    //           }}
    //           selectedOption={getSelectedTieBreaker(tournament)}
    //         />
    //       ),
    //       customView: true,
    //       isEditable: true,
    //     },
    //   ],
    // };
    return [
      { ...generalInformation },
      { ...organizationInformation },
      { ...fieldInformation },
      { ...handicapInformation },
      //{ ...flightingInformation },
      //{ ...tieBreakerInformation },
    ];
  }
};

export const tieBreakerMethods = [
  { id: 1, label: "Hole Handicaps", value: "hole_handicap" },
  {
    id: 2,
    label: "Cumulative Score: Last 9, 6, 3, 1 Holes",
    value: "ninesixthreeone",
  },
  {
    id: 3,
    label: "Reverse Hole Position (18-1)",
    value: "reverse_hole_position",
  },
];

const handicapTypes = [
  {
    id: 1,
    label: "Default Calculation",
    value: "none",
    subLabel: "Do not calculate course handicaps.",
  },
  {
    id: 2,
    label: "World Handicap System",
    subLabel: "Calculate course handicaps using the World Handicap System Calculation.",
    value: "world_handicap_system",
  },
];

export const getSelectedTieBreaker = (tournament) => {
  return tieBreakerMethods.find(
    (method) => method.value === tournament?.tieBreakerMethod
  );
};

const getSelectedHandicapType = (tournament) => {
  let selection = handicapTypes.find(
    (type) => type.value === tournament?.handicappingType
  );
  return (
    selection ?? {
      id: 1,
      label: "Default Calculation",
      value: "none",
      subLabel: "Do not calculate course handicaps.",
    }
  );
};

export const getCopyForm = (context) => {
  if (context) {
    let form = [];
    let tournamentDetails = {
      title: "Tournament Details",
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Tournament Name",
          placeholder: "Tournament Name",
          isEditable: true,
          value: context?.getData?.()?.name ?? "",
          onChange: (e) => {
            context?.updateData?.(e.target.value, "name");
          },
          required: true,
          failedValidation: context?.validationFailed,
        },
        {
          label: "Start Date & Time",
          placeholder: "Start Date & Time",
          type: "datetime-local",
          value: context?.getData?.()?.date ?? "",
          onChange: (e) => {
            context?.updateData?.(e.target.value, "date");
          },
          isEditable: true,
          required: true,
          failedValidation: context?.validationFailed,
        },
      ],
    };

    let tournamentCopySettings = {
      title: "Tournament Setup",
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Copy Event Site",
          value: (
            <GSToggle
              value={context?.getData?.().copyTournamentPromotion ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={() => {context?.updateData?.(!context?.getData?.().copyTournamentPromotion, "copyTournamentPromotion")}}
            />
          ),
          customView: true,
          isEditable: true,
          failedValidation: context?.validationFailed,
        },
        {
          label: "Copy Packages",
          value: (
            <GSToggle
              value={context?.getData?.().copyRegistrationPackages ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={() => {context?.updateData?.(!context?.getData?.().copyRegistrationPackages, "copyRegistrationPackages")}}
            />
          ),
          customView: true,
          isEditable: true,
          failedValidation: context?.validationFailed,
        },
        {
          label: "Copy Payment Settings",
          value: (
            <GSToggle
              value={context?.getData?.().copyPaymentSettings ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={() => {context?.updateData?.(!context?.getData?.().copyPaymentSettings, "copyPaymentSettings")}}
            />
          ),
          customView: true,
          isEditable: true,
          failedValidation: context?.validationFailed,
        },
        {
          label: "Copy Players & Teams",
          value: (
            <GSToggle
              value={context?.getData?.().copyTeams ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={() => {context?.updateData?.(!context?.getData?.().copyTeams, "copyTeams")}}
            />
          ),
          customView: true,
          isEditable: true,
          failedValidation: context?.validationFailed,
        },
        {
          label: "Copy Sponsors",
          value: (
            <GSToggle
              value={context?.getData?.().copySponsors ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={() => {context?.updateData?.(!context?.getData?.().copySponsors, "copySponsors")}}
            />
          ),
          customView: true,
          isEditable: true,
          failedValidation: context?.validationFailed,
        },
      ],
    };

    form = [tournamentDetails, tournamentCopySettings];
    return form;
  }
};
