import React, {
  useEffect,
  //useMemo
} from "react";
import {
  GSItemList,
  GSScorecard,
  GSSidePanelPage,
  ScoreTotal,
} from "golfstatus_react_components";
import { faChevronLeft, faClock } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-result-details.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboardSetting,
  selectTournamentLeaderboards,
} from "../../../reducers/leaderboardSlice";
import TournamentLeaderboardResultLineItem from "./tournament-leaderboard-result-line-item";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { getDateTimeOn } from "../../../helpers/Dates";
import { useMemo } from "react";
import { useResizeSplit } from "../../../hooks/scorecardHooks";

//Name the component
const TournamentLeaderboardResultDetails = (props) => {
  //const defaultAvatar = useMemo(() => ("https://api.golfstatus.dev/images/user_round.png"), []) ;
  const splitNines = useResizeSplit();

  const navigate = useNavigate();
  const match = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/results/:teamKey"
  );
  const matchFlight = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/:flightID/results/:teamKey"
  );

  const dispatch = useDispatch();

  const tournamentLeaderboards = useSelector(selectTournamentLeaderboards);
  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    if (matchFlight?.params?.leaderboardSettingID) {
      dispatch(getLeaderboardSetting(matchFlight.params.leaderboardSettingID));
    }
  }, [dispatch, matchFlight?.params?.leaderboardSettingID]);

  const currentLeaderboard = useMemo(() => {
    let leaderboard = {};
    if (match?.params?.leaderboardSettingID) {
      leaderboard = tournamentLeaderboards.find(
        (l) =>
          l.leaderboardTypeSetting?.id === match?.params?.leaderboardSettingID
      );
    }
    if (
      matchFlight?.params?.leaderboardSettingID &&
      matchFlight.params?.flightID
    ) {
      if (currentTournament.preflight) {
        leaderboard = tournamentLeaderboards.find(
          (l) =>
            l.leaderboardTypeSetting?.id ===
              matchFlight?.params?.leaderboardSettingID &&
            l.tournamentFlight?.id === matchFlight.params?.flightID
        );
      } else {
        leaderboard = tournamentLeaderboards.find(
          (l) =>
            l.leaderboardTypeSetting?.id ===
            matchFlight?.params?.leaderboardSettingID
        );
      }
    }
    return leaderboard;
  }, [
    currentTournament?.preflight,
    match?.params?.leaderboardSettingID,
    matchFlight?.params?.leaderboardSettingID,
    matchFlight?.params?.flightID,
    tournamentLeaderboards,
  ]);

  const getResult = (leaderbaord) => {
    const m = match ?? matchFlight;
    let result = {};
    if (leaderbaord?.type === "round_match") {
      result = leaderbaord?.scores?.find?.((s) =>
        s.teamKeys.includes(m?.params?.teamKey)
      );
    } else {
      result = leaderbaord?.scores?.find?.(
        (s) => s.teamKey === m?.params?.teamKey
      );
    }
    return result;
  };

  const getDrawerActions = () => {
    let actions = [];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Scores`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getScorecards = (result, showPoints) => {
    let currentMatch = match ?? matchFlight;
    if (result?.rounds) {
      return (
        <GSItemList
          type="vertical selectable x-large-gap"
          items={result?.rounds}
          itemSelected={(round) => {
            navigate(
              `/${currentMatch?.params?.tournamentID}/rounds/leaderboard/scoring/${round?.scorecardKey}`
            );
          }}
          listItem={(round) => (
            <GSScorecard
              totalScore={
                <ScoreTotal scores={round.roundScore.scores}></ScoreTotal>
              }
              splitNines={splitNines}
              user={{ name: round.roundDisplayName }}
              holes={round.roundScore?.scores}
              showPoints={showPoints}
              startingHole={round?.startingHole}
              showTotalColumn
              playerScores={
                round?.playerScores?.length > 0 ? round?.playerScores : []
              }
            ></GSScorecard>
          )}
        ></GSItemList>
      );
    } else if (result?.roundScore) {
      return (
        <div
          className="round-score"
          onClick={() => {
            navigate(
              `/${currentMatch?.params?.tournamentID}/rounds/leaderboard/scoring/${result?.scorecardKey}`
            );
          }}
        >
          <GSScorecard
            totalScore={
              <ScoreTotal scores={result?.roundScore?.scores}></ScoreTotal>
            }
            splitNines={splitNines}
            showTotalColumn
            user={{ name: result?.roundDisplayName }}
            holes={result?.roundScore?.scores}
            showPoints={showPoints}
            startingHole={result?.startingHole}
            playerScores={
              result.playerScores?.length > 0 ? result.playerScores : []
            }
          ></GSScorecard>
        </div>
      );
    } else if (result?.matchScores) {
      return (
        <div className="match-scores">
          <GSScorecard
            holes={result?.matchScores?.[0].roundScore.scores}
            splitNines={splitNines}
            startingHole={result.startingHole}
            showTotalColumn
            match={result}
          ></GSScorecard>
          <GSItemList
            type="vertical selectable x-large-gap"
            items={result?.matchScores}
            itemSelected={(round) => {
              navigate(
                `/${currentMatch?.params?.tournamentID}/rounds/leaderboard/scoring/${round?.scorecardKey}`
              );
            }}
            listItem={(round) => (
              <GSScorecard
                totalScore={
                  <ScoreTotal scores={round.roundScore.scores}></ScoreTotal>
                }
                splitNines={splitNines}
                user={{ name: round.name }}
                holes={round.roundScore?.scores}
                showPoints={showPoints}
                startingHole={round?.startingHole}
                showTotalColumn
                playerScores={
                  round?.playerScores?.length > 0 ? round?.playerScores : []
                }
              ></GSScorecard>
            )}
          ></GSItemList>
        </div>
      );
    }
  };

  const getContent = () => {
    let result = getResult(currentLeaderboard);
    let showPoints = [
      "match_play",
      "stableford",
      "modified_stableford",
    ].includes(currentLeaderboard?.scoringSystem);
    return (
      <gs-form>
        <TournamentLeaderboardResultLineItem
          leaderboard={currentLeaderboard}
          {...result}
        ></TournamentLeaderboardResultLineItem>
        {getScorecards(result, showPoints)}
      </gs-form>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    defaultBanner: {
      title: getNotificationItemInfo(
        currentLeaderboard?.refreshedAt
          ? `This leaderboard was last updated at ${getDateTimeOn(
              currentLeaderboard?.refreshedAt
            )}`
          : `This leaderboard was last fetched at ${getDateTimeOn()}`,
        faClock
      ),
      state: "grey",
    },
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-leaderboard-result-details>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-result-details>
  );
};

//export the new namet
export default TournamentLeaderboardResultDetails;
