import "./tournament-team-details.scss";
import React, { useEffect } from "react";
import {
  GSButton,
  GSInfoCard,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faBan,
  faComment,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useMatch } from "react-router-dom";
import NavigationListItem from "../../navigation-list-item";

import TournamentTeamPlayerCard from "./tournament-team-player-card";
import TournamentTeamDetailHero from "./tournament-team-detail-hero";
import { useDispatch, useSelector } from "react-redux";

import {
  selectCurrentTeam,
  getTournamentTeam,
  saveTournamentTeam,
  selectTeamsNotifications,
  clearNotifications,
  notifySlideOut,
  getTournamentTeams,
  selectCurrentTeamRegistrationOrder,
  getTournamentTeamRegistrationOrder,
  selectLoading,
} from "../../../reducers/teamsSlice";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentTeamDetails = (props) => {
  const currentTeam = useSelector(selectCurrentTeam);
  const loading = useSelector(selectLoading);
  const currentTeamRegistrationOrder = useSelector(
    selectCurrentTeamRegistrationOrder
  );

  const notifications = useSelector(selectTeamsNotifications);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/teams/:teamID");

  useEffect(() => {
    if (currentTeam.id !== match?.params?.teamID) {
      dispatch(getTournamentTeam(match.params.teamID));
      dispatch(getTournamentTeamRegistrationOrder(match.params.teamID));
    }
  }, [dispatch, match.params.teamID, currentTeam?.id]);

  useEffect(() => {
    return () => {
      dispatch(getTournamentTeams(match?.params?.tournamentID));
    };
  }, [dispatch, match.params.tournamentID]);

  const disqualify = () => {
    navigate("disqualify");
  };

  const requalifyTeam = () => {
    dispatch(saveTournamentTeam({ ...currentTeam, disqualified: false }));
  };

  const remove = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [
      currentTeam.disqualified
        ? {
            name: "Qualify Team",
            action: requalifyTeam,
            type: "light-grey",
          }
        : {
            name: "Disqualify Team",
            buttonIcon: faBan,
            action: disqualify,
            type: "light-grey",
          },
      { name: "Delete Team", action: remove, type: "transparent red" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Team Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getTeamPages = () => {
    let pages = [
      {
        hero: <TournamentTeamDetailHero {...currentTeam} />,
        isEdit: true,
        isExternal: false,
        type: "hero",
        url: "edit",
        extras: getTeamMemberList(),
      },
      {
        label: "Handicaps",
        isExternal: false,
        url: "rounds",
        hidden: false,
      },
      {
        label: "Tees",
        isExternal: false,
        url: "tees",
        hidden: false,
      },
      {
        label: "Scorecards",
        isExternal: false,
        url: "scorecards",
        hidden: false,
      },
      {
        label: "Notes",
        details: "View player registration notes.",
        isExternal: false,
        url: "notes",
        hidden: false,
      },
    ];
    if (currentTeamRegistrationOrder?.id) {
      pages.push({
        label: "Payments",
        details: "View team payment and order details.",
        isExternal: false,
        url: `/${match.params.tournamentID}/reporting/${currentTeamRegistrationOrder.id}`,
        hidden: false,
      });
    }
    return pages;
  };

  const pageSelected = (page) => {
    navigate(page.url);
  };

  const playerSelected = (player) => {
    navigate(`edit/players/edit/${player.id}`);
  };

  const getMessage = (player) => {
    return {
      header: getNotificationItemInfo(
        player.signupMessage,
        faComment,
        player.contactName
      ),
      state: "grey",
    };
  };
  const showMessage = (player) => {
    dispatch(notifySlideOut(getMessage(player)));
  };

  const getTeamMemberList = () => {
    return (
      <GSItemList
        type="horizontal selectable x-large-pad large-gap horizontal-scroll border-bottom team-list"
        items={currentTeam.players}
        itemSelected={playerSelected}
        emptyMessage={
          <GSInfoCard
            size="large centered"
            layout={
              <GSButton
                buttonIcon={faPlus}
                onClick={() => {
                  navigate("edit");
                }}
                title="add player"
              />
            }
          />
        }
        listItem={(item) => (
          <TournamentTeamPlayerCard
            {...item}
            team={currentTeam}
            messageClicked={showMessage}
          />
        )}
      ></GSItemList>
    );
  };

  const getContent = () => {
    return (
      <gs-form className="content">
        <GSItemList
          type="vertical"
          itemSelected={pageSelected}
          items={getTeamPages()}
          listItem={(item) => <NavigationListItem page={item} />}
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-team-details>
      <GSSidePanelPage
        loading={loading?.length > 0}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-details>
  );
};

//export the new namet
export default TournamentTeamDetails;
