import React, { useState } from "react";
import { GSEmptyList, GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-scoring-settings-ghin.scss";
import { useFormValidation } from "../../../../hooks/formHooks";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getPostScoresToGHINForm } from "../../../../forms/RoundScoresForm";
import { useSelector } from "react-redux";
import { clearRoundNotifications, getRound, getRoundScorecards, postScoresToGhin, selectCurrentRound, selectCurrentRoundScorecards, selectRoundNotifications } from "../../../../reducers/roundSlice";
import TournamentRoundLineItem from "../tournament-round-line-item";
import {
  getTournamentTeams,
  selectTournamentTeams,
} from "../../../../reducers/teamsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectCurrentLiveTournamentRounds } from "../../../../reducers/scorecardSlice";
//Name the component
const TournamentRoundScoringSettingsGHIN = (props) => {
  const [search, setSearch] = useState("")
  const [selectedScoreType, setSelectedScoreType] = useState({ value: "h" });
  const [selectedPlayers, setSelectedPlayers] = useState([])

  const [context, isValid, setIsValid] = useFormValidation(false);

  const navigate = useNavigate();
  const notifications = useSelector(selectRoundNotifications);

  const currentRound = useSelector(selectCurrentRound);
  const teams = useSelector(selectTournamentTeams);
  const scorecards = useSelector(selectCurrentRoundScorecards);
  const currentLiveTournamentRounds = useSelector(selectCurrentLiveTournamentRounds)


  const match = useMatch(
    "/:tournamentID/rounds/:roundID/scoring/settings/ghin"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
      dispatch(getRoundScorecards(match.params.roundID));
    }
    return () => {clearRoundNotifications()}
  }, [dispatch, match?.params?.roundID]);

  context.search = search
  context.setSearch = setSearch

  context.updateScoreType = (option) => {
    setSelectedScoreType(option);
  };

  context.getSelectedScoreType = () => {
    return selectedScoreType;
  };

  context.getSelectedPlayers = () => {
    return selectedPlayers
  }

  context.addSelectedPlayer = (player) => {
    setIsValid(true)
    setSelectedPlayers([...selectedPlayers, player])
  }

  context.removeSelectedPlayer = (player) => {
    setIsValid(true)
    let newList = selectedPlayers.filter?.(p => p.id !== player.id)
    setSelectedPlayers(newList)
  }

  context.selectAllPlayers = () => {
    setIsValid(true)
    setSelectedPlayers(getFilteredPlayers())
  }

  context.clearPlayers = () => {
    setIsValid(false)
    setSelectedPlayers([])
  }

  context.validationFailed = () => {
    if(isValid){
      setIsValid(false)
    }
  }

  context.getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the scorecard you’re looking for…"
        detail="Try changing the search term or view your players list to make sure the player you’re looking for has a GHIN number."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {setSearch('')},
            type: "light-grey",
          },
          {
            title: "View Players & Teams",
            onClick: () => {navigate(`/${match.params?.tournamentID}/teams`)},
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const save = () => {
    setIsValid(false);
    let ghinSettings = {scoreType : selectedScoreType.value, players: selectedPlayers.map(sp => ({...sp, liveRound: currentLiveTournamentRounds.find(cltr => cltr.player.id === sp.id) }))}
    dispatch(postScoresToGhin(ghinSettings))
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Post Scores",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Post Scores to GHIN`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFilteredTeams = () => {
    if (scorecards?.length === 0) {
      return [];
    }

    let filteredTeams = teams.filter?.((t) =>
      `${t?.contactName} ${t?.name} ${t?.players
        ?.map?.((p) => p.name)
        ?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search?.toLowerCase?.()) && (t?.players?.[0]?.ghinNumber && !t?.players?.[0]?.badGhinNumber )
    );

    let teamsWithScorecards = filteredTeams?.map?.((ft) => ({
      ...ft,
      liveTournamentScorecard: scorecards?.find?.((sc) =>
        sc?.teams?.map?.((t) => t.tournamentTeamKey)?.includes?.(ft.id)
      ),
    }));

    return teamsWithScorecards;
  };

  const getFilteredPlayers = () => {
    if (scorecards?.length === 0) {
      return [];
    }

    let players = getFilteredTeams().flatMap?.(t => t.players.map(p => ({...p, teamID: t.id, liveTournamentScorecard: t.liveTournamentScorecard})))

    let filteredPlayers = players.filter((p) => p.name?.toLowerCase?.().includes?.(search?.toLowerCase?.()) && (p?.ghinNumber && !p?.badGhinNumber ))
    
    return filteredPlayers;
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          <TournamentRoundLineItem round={currentRound} hideCopy hideActions />
        }
        formSections={getPostScoresToGHINForm(context, getFilteredPlayers())}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-round-scoring-settings-ghin>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-scoring-settings-ghin>
  );
};

//export the new namet
export default TournamentRoundScoringSettingsGHIN;
