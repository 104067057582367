import {
  faCommentAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSInfoCard,
  GSInfoGroup,
  GSCircleImage,
} from "golfstatus_react_components";
import React from "react";

import "./tournament-team-card.scss";

const TournamentTeamPlayerCard = (props) => {
  const { name, email, avatar, signupMessage, mainContact, messageClicked, handicap } = props;

  //const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

  const avatarInfo = {
    sectionItems: [
      {
        type: "primary",
        value: <GSCircleImage size="xx-large" url={avatar}></GSCircleImage>,
      },
    ],
  };

  const contactInfo = {
    sectionItems: [
      {
        type: "primary no-wrap full-width capitalize",
        value: `${name} ${handicap ? `(${handicap})` : ''}`,
      },
      {
        type: "secondary nowrap",
        value: email,
      },
    ],
  };

  const actionInfo = {
    sectionItems: [],
  };

  if (mainContact) {
    actionInfo.sectionItems.push({
      type: "primary no-wrap full-width",
      value: <GSButton buttonIcon={faUserCircle} type="secondary" />,
    });
  }

  if (signupMessage) {
    actionInfo.sectionItems.push({
      type: "primary no-wrap full-width",
      value: <GSButton type="secondary" buttonIcon={faCommentAlt}  onClick={(e) => {messageClicked?.({...props}); e?.stopPropagation?.()}}/>,
    });
  }

  const teamInfo = {
    fit: "stretch",
    sections: [{ ...avatarInfo }, { ...contactInfo }],
  };

  const actions = {
    sections: [{ ...actionInfo }],
  };

  const getGroup = () => {
    let group = [{ ...teamInfo }, { ...actions }];
    return group;
  };

  return (
    <tournament-team-card>
      <GSInfoCard
        size="large"
        layout={
          <GSInfoGroup
            dataGroups={getGroup()}
          ></GSInfoGroup>
        }
      ></GSInfoCard>
    </tournament-team-card>
  );
};

export default TournamentTeamPlayerCard;
