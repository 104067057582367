import {
  GSField,
  GSInfoGroup,
  GSItemList,
  GSRadioGroup,
} from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFacilityCourseTees } from "../reducers/facilitySlice";
import "./course-line-item.scss";

const CourseLineItem = (props) => {
  const {
    id,
    course,
    enableTeeSelect,
    enableHoleSelect,
    holesToPlay,
    roundTees
  } = props;
  const {
    name,
    numberOfHoles,
    active,
  } = course ?? {};
  const dispatch = useDispatch()

  const getHoles = () => {
    return [{id: 1, label: "All Holes", value: "all_holes", numberOfHoles: numberOfHoles},{id: 1, label: "Front Nine", value: "front_nine", numberOfHoles:9},{id: 2, label: "Back Nine", value: "back_nine", numberOfHoles: 9}];
  };

  const [availableTees, setAvailableTees] = useState([])
  const [selectedTees, setSelectedTees] = useState(roundTees? [...roundTees?.map?.(t => ({name: t?.name, id: t?.teeId, rating: t?.rating, slope: t?.slope}))]: [])
  const [selectedHoles, setSelectedHoles] = useState(holesToPlay ? getHoles().find?.(h => h.value === holesToPlay) : getHoles()?.[0])

  useEffect(() => {
    dispatch(getFacilityCourseTees(course?.id)).then((response) => {
      setAvailableTees([...response?.payload])
    })
  }, [dispatch, course.id])

  const getTees = () => {
    const at = availableTees?.filter?.(t => t?.active);
    const st = [...selectedTees]
    const ft = at.filter(t => (!st.map(t2 => t2.id).includes(t.id)))
    return [...st, ...ft]
  };
  const addTee = (tee) => {
    setSelectedTees([...selectedTees, tee ])
    props?.teeSelected?.(id, tee)
  }
  const removeTee = (tee) => {
    setSelectedTees(selectedTees?.filter?.(t => t.id !== tee.id))
    props?.teeRemoved?.(id, tee)
  }
  const setHoles = (holes) => {
    setSelectedHoles(holes)
    props?.holesSelected?.(id, holes)
  }
  const getInfoGroup = () => {
    let groups = [];
    let courseInfo = {
      fit: "stretch",
      sections: [
        {
          sectionItems: [
            { type: "primary", value: name },
            { type: "secondary", value: active ? "Active" : "Inactive" },
            {
              type: "secondary",
              value: `${numberOfHoles} Hole Course`,
            },
          ],
        },
      ],
    };
    groups.push({ ...courseInfo });

    if(enableHoleSelect && numberOfHoles === 18)
    {
      courseInfo.sections.push?.({
        sectionItems: [
          { type: "primary", value: <GSField label="Available holes"/>},
          {
            type: "primary",
            value: (
              <GSRadioGroup isLtr={true} selectedOption={selectedHoles} selectionChanged={(option) => setHoles(option)} options={getHoles()}/>
            ),
          },
        ],
      },)
    }

    if(enableTeeSelect)
    {
      courseInfo.sections.push?.({
        sectionItems: [
          { type: "primary", value: <GSField  label="Available tees"/>},
          {
            type: "secondary scrollable ",
            value: (
              <GSItemList
                isCheckList={true}
                type="vertical large-gap small-pad check-key-handle"
                columns="4"
                items={getTees()}
                selectedItems={selectedTees}
                itemChecked={addTee}
                itemUnchecked={removeTee}
                listItem={(item) => <div className="tee-option">{`${item.name} - ${item.rating}/${item.slope}`}</div>}
              />
            ),
          },
        ],
      },)
    }
    
    return groups;
  };
  return (
    <course-line-item>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getInfoGroup()}
      />
    </course-line-item>
  );
};

export default CourseLineItem;
