import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-remove.scss";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  deleteTournamentPackage,
  getTournamentPackage,
  selectCurrentTournamentPackage,
  selectNotifications,
} from "../../../../reducers/packageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { removeItemForm } from "../../../../forms/DeleteForm";
import TournamentPackagesItem from "./tournament-packages-item";

//Name the component
const TournamentPackageRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/promotion/packages/:packageID/delete");

  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  useNotificationNavigation("removed", notifications, -2);

  const remove = () => {
    setIsValid(false);
    if (match?.params?.packageID) {
      dispatch(deleteTournamentPackage(match.params.packageID));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: remove,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Package`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitle = () => {
    return (
      <div className="delete-header">
        <div className="icon">
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
        </div>{" "}
        Delete Package
      </div>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getFormTitle()}
        formSections={removeItemForm(
          currentPackage.name,
          <TournamentPackagesItem {...currentPackage} />
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications,
    timeoutAction,
  });

  return (
    //name the component tag
    <tournament-package-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-remove>
  );
};

//export the new namet
export default TournamentPackageRemove;
