import "./tournament-team-import.scss";
import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faArrowCircleDown,
  faChevronLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { getImportForm } from "../../../forms/TeamForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentTeams,
  importTeamList,
  selectLoading,
  selectTeamsNotifications,
  selectTournamentTeams,
  selectValidatedTeam,
  setValidatedTeam,
  validateTeamImport,
} from "../../../reducers/teamsSlice";
import { createFormFromFile } from "../../../helpers/Utilities";
import {
  clearUtility,
  clearUtilityNotifications,
  selectUploadedFile,
  setUploadedFile,
  uploadFile,
} from "../../../reducers/utilitySlice";
import {
  downloadTeamImportTemplate,
  selectCurrentTournament,
  selectTournamentNotifications,
  clearTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  useNotificationAction,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { setNotifications } from "../../../reducers/appSlice";
import { getNotificationItemInfo } from "../../../helpers/Converters";

//Name the component
const TournamentTeamImport = (props) => {
  const [context, isValid, setIsValid] = useFormValidation(false);

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentTeams = useSelector(selectTournamentTeams);

  const [selectedFile, setSelectedFile] = useState("");
  const uploadedFile = useSelector(selectUploadedFile);
  const validatedTeam = useSelector(selectValidatedTeam);
  const loading = useSelector(selectLoading)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/teams/import");

  const notifications = [...useSelector(selectTournamentNotifications), ...useSelector(selectTeamsNotifications)];

  context.setFile = (files) => {
    const file = files[0];
    if (file) {
      const data = createFormFromFile(file);
      dispatch(uploadFile(data));
    }
  };

  context.removeFile = (file) => {
    setSelectedFile({});
    dispatch(setUploadedFile(""));
    dispatch(setValidatedTeam({}));
    setSelectedFile("");
  };

  const backToList = () => {
    dispatch(clearNotifications());
    dispatch(clearUtilityNotifications());
    setSelectedFile({});
    dispatch(setValidatedTeam({}));
    navigate(`/${match.params.tournamentID}/teams`);
  };

  useEffect(() => {
    if (uploadedFile && uploadedFile !== selectedFile) {
      setSelectedFile(uploadedFile);
    }
    return () => {
      dispatch(setUploadedFile(""));
      dispatch(clearNotifications());
      dispatch(clearTournamentNotifications());
      dispatch(clearUtility());
    };
  }, [uploadedFile, dispatch, selectedFile]);

  useEffect(() => {
    if ((selectedFile ?? "") !== "" && currentTournament?.id) {
      const verify = { filename: selectedFile, id: currentTournament?.id };
      dispatch(validateTeamImport(verify));
    } else {
      // if no file is currently selected, make sure there is no preview left over
      dispatch(setValidatedTeam({}));
    }
  }, [selectedFile, tournamentTeams, dispatch, currentTournament.id]);

  useEffect(() => {
    if (validatedTeam?.identifier && !isValid) {
      setIsValid(true);
    }
  }, [validatedTeam, setIsValid, isValid]);

  useNotificationAction(
    "uploaded",
    notifications,
    (notification) => {
      dispatch(getTournamentTeams(match?.params?.tournamentID));
      dispatch(setNotifications([{...notification, timeout: 5000}]));
      dispatch(clearNotifications());
    },
    "Team List"
  );

  useNotificationNavigation("uploaded", notifications, -1, "Team List");

  const uploadTeams = () => {
    setIsValid(false);
    dispatch(clearNotifications());
    if ((selectedFile ?? "") !== "" && currentTournament?.id) {
      const teamFile = { filename: selectedFile, id: currentTournament?.id };
      dispatch(importTeamList(teamFile));
    }
  };

  const downloadTemplate = () => {
    dispatch(downloadTeamImportTemplate(currentTournament));
  };

  const getBannerActions = () => {
    return [
      {
        id: 1,
        title: "Back to Teams List",
        result: "uploaded",
        size: "secondary",
        actionClick: backToList,
      },
      {
        id: 1,
        title: "Upload Teams",
        result: "validated",
        size: "secondary",
        type: "green",
        actionClick: uploadTeams,
      },
      {
        id: 1,
        title: "Download Template",
        result: "failed",
        size: "secondary",
        actionClick: downloadTemplate,
      },
    ];
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Upload",
        isDisabled: !isValid,
        action: uploadTeams,
        type: "black",
      },
      {
        name: "cancel",
        action: leftNavigation,
        type: "grey"
      },
      {
        name: "Template",
        buttonIcon: faArrowCircleDown,
        action: downloadTemplate,
        type: "light-grey no-wrap",
        isDisabled: !tournamentHasRound(),
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    backToList();
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Upload Teams`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm formTitle={"Upload Teams"} formSections={getImportForm(validatedTeam, context)}></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const tournamentHasRound = () => {
    return currentTournament.numberOfRounds > 0;
  };

  const getDefaultBanner = () => {
    if(!tournamentHasRound()) {
      return {
        title: getNotificationItemInfo(`To upload teams, this tournament must have at least one round.`, faExclamationCircle),
        state: 'warning',
        bannerActions: [{
          title: 'Add Round',
          type: 'secondary',
          actionClick: () => navigate(`../../rounds/new`)
        }]
      }
    }
    return false
  }

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction,
    bannerActions: getBannerActions(),
    defaultBanner: getDefaultBanner(),
  });

  return (
    //name the component tag
    <tournament-team-import>
      <GSSidePanelPage
      loading={loading?.includes?.("importTeamList")}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-import>
  );
};

//export the new name
export default TournamentTeamImport;
