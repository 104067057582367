import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-form-remove.scss";
import { useSelector } from "react-redux";
import {
  getTournamentForm,
  savePackageForm,
  selectCurrentTournamentPackageForm,
  selectNotifications,
} from "../../../../reducers/packageSlice";
import { useDispatch } from "react-redux";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import TournamentPackageFormItem from "./tournament-package-form-item";

//Name the component
const TournamentPackageFormRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/forms/:formID/remove"
  );

  const currentPackageForm = useSelector(selectCurrentTournamentPackageForm);
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    if (match?.params?.formID) {
      dispatch(getTournamentForm(match.params.formID));
    }
  }, [dispatch, match?.params?.formID]);

  const remove = () => {
    setIsValid(false);
    let update = { ...currentPackageForm };
    update.active = false;
    const { tournamentID, packageID } = match.params;
    dispatch(savePackageForm(update)).then(() => {
      navigate(`/${tournamentID}/promotion/packages/${packageID}/forms`);
    });
  };

  useNotificationNavigation("removed", notifications, -2);

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: remove,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    const { tournamentID, packageID, formID } = match.params;
    navigate(
      `/${tournamentID}/promotion/packages/${packageID}/forms/${formID}`
    );
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Form`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Form")}
        formSections={removeItemForm(
          currentPackageForm.name,
          <TournamentPackageFormItem {...currentPackageForm} />,
          "delete",
          "Are you sure you want to delete this form? This cannot be undone and will be unlinked from all packages."
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({ notifications });

  return (
    //name the component tag
    <tournament-package-form-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-form-remove>
  );
};

//export the new namet
export default TournamentPackageFormRemove;
