import { GSActionBar, GSItemList } from "golfstatus_react_components";
import React from "react";
import { getTime } from "../../../../helpers/Dates";

import "./tournament-round-scoring-hole-assignment-item.scss";
import TournamentRoundScoringTeamItem from "./tournament-round-scoring-team-item";

const TournamentRoundScoringHoleAssignmentItem = (props) => {
  const {hole, position, tournamentTeams, editScore, teeTime} = props

  const getHeader = () => {
    return `Hole ${hole} ${position}  - ${getTime(teeTime) }`
    
  }
  return (
    <tournament-round-scoring-hole-assignment-item>
      <GSActionBar type="grey-header x-large-pad" header={getHeader()}></GSActionBar>
      <GSItemList
        type=" vertical"
        items={tournamentTeams}
        itemSelected={editScore}
        listItem={(item) => <TournamentRoundScoringTeamItem {...item} />}
      />
    </tournament-round-scoring-hole-assignment-item>
  );
};

export default TournamentRoundScoringHoleAssignmentItem;
