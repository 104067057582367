import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  leaderboardTypeSettings,
  tournament,
  tournamentRounds,
} from "../app/api";
import { getRoundName } from "../helpers/Converters";
import {
  getTournamentLeaderboardSettings,
  selectTournamentLeaderboardSettings,
} from "../reducers/leaderboardSlice";
import {
  getTournament,
  selectCurrentTournament,
} from "../reducers/tournamentSlice";

export const useDocuments = (match) => {
  const { tournamentID } = match?.params ?? {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentLeaderboardSettings(match.params.tournamentID));
      dispatch(getTournament(match.params.tournamentID));
    }
  }, [match?.params?.tournamentID, dispatch]);

  const currentTournament = useSelector(selectCurrentTournament);
  const leaderboardSettings = useSelector(selectTournamentLeaderboardSettings);

  const teamDocuments = {
    label: "Team Documents",
    details:
      "Teams list, teams export, team codes, registration export, form responses, and scores.",
    url: `../teams/documents`,
    documents:
      currentTournament.numberRegisteredTeams === 0
        ? [
            {
              label: "Team Export",
              details:
                "Current list of the registered teams in the tournament, can also be used in the team import.",
              ext: ".xlsx",
              isDownload: true,
              url: `${tournament}/${tournamentID}/team-export`,
              name: `${currentTournament.name}-team-export`,
            },
          ]
        : [
            {
              label: "Team List",
              details: "A list of the registered teams.",
              ext: ".pdf",
              isDownload: true,
              url: `${tournament}/${tournamentID}/team-list`,
              name: `${currentTournament.name}-team-list`,
            },
            {
              label: "Live-Scoring Codes",
              details:
                "A list of the registered teams and their live-scoring codes.",
              ext: ".pdf",
              isDownload: true,
              url: `${tournament}/${tournamentID}/player-code-list`,
              name: `${currentTournament.name}-team-codes`,
            },
            {
              label: "Players Export",
              details: "A list of the registered players.",
              ext: ".xlsx",
              isDownload: true,
              url: `${tournament}/${tournamentID}/registration-export`,
              name: `${currentTournament.name}-registrations`,
            },
            {
              label: "Team Export",
              details:
                "A list of the registered teams, formatted for teams upload.",
              ext: ".xlsx",
              isDownload: true,
              url: `${tournament}/${tournamentID}/team-export`,
              name: `${currentTournament.name}-team-export`,
            },
            {
              label: "Form Responses",
              details: "A list of the form responses from registrants.",
              ext: ".xlsx",
              isDownload: true,
              url: `${tournament}/${tournamentID}/registration-order-custom-field-responses`,
              name: `${currentTournament.name}-registration-reponses`,
            },
          ],
  };

  const roundsDocuments = {
    label: "Round Documents",
    details:
      "Scorecards, cart signs, hole assignments, rules, scoring results, and more...",
    url: "../rounds/documents",
    documents:
      currentTournament?.tournamentRounds?.length > 0
        ? [
            ...(currentTournament?.tournamentRounds ?? [])?.map?.((round) => ({
              label: `Round ${getRoundName(round)} Documents`,
              name: `Round ${round.number} ${
                round.roundName ? `(${round.roundName})` : ""
              } Documents`,
              details: `Scorecards, cart signs, hole assignments, rules, scoring results, and more...`,
              url: `/${currentTournament.id}/rounds/${round.id}/documents`,
              documents: getRoundDocuments(round),
              id: round.id,
            })),
          ]
        : [],
  };

  let leaderboardDocuments = {
    label: "Leaderboard Documents",
    details: "Tournament, round, and skins leaderboards.",
    url: "../leaderboards/documents",
    documents: [],
  };

  if (
    currentTournament?.tournamentRounds?.find?.(
      (round) => round.state === "live" || round.state === "final",
    )
  ) {
    roundsDocuments.documents.unshift(
      {
        label: "Gross Scores",
        details: "Total gross scores for all rounds.",
        ext: ".xlsx",
        isDownload: true,
        url: `${tournament}/${tournamentID}/scores`,
        name: `${currentTournament.name}-gross-scores`,
        data: { "score-type": "gross" },
      },
      {
        label: "Net Scores",
        details: "Total net scores for all rounds.",
        ext: ".xlsx",
        isDownload: true,
        url: `${tournament}/${tournamentID}/scores`,
        name: `${currentTournament.name}-net-scores`,
        data: { "score-type": "net" },
      },
      {
        label: "Avery Bag Labels",
        details:
          'Player list with starting details for all rounds formatted for Avery Label Template 8160 - 1" x 2-5/8"',
        ext: ".pdf",
        isDownload: true,
        url: `${tournament}/${tournamentID}/tournament-avery-bag-labels`,
        name: `${currentTournament.name}-tournament-avery-bag-labels`,
      },
    );
    leaderboardDocuments.documents = (leaderboardSettings ?? [])?.map?.(
      (leaderboardTypeSetting) => ({
        label: `${leaderboardTypeSetting.name}`,
        details: leaderboardTypeSetting.customName,
        ext: ".pdf",
        isDownload: true,
        url: `${leaderboardTypeSettings}/${leaderboardTypeSetting.id}/leaderboard-export`,
        name: `${currentTournament.name}-${leaderboardTypeSetting.name}-export`,
        id: leaderboardTypeSetting?.id,
        roundID: leaderboardTypeSetting?.tournamentRound?.id,
        disabled: ["draft", "ready"].includes(
          leaderboardTypeSetting?.tournamentRound?.state,
        ),
      }),
    );
  }

  const reportingDocuments = {
    label: "Reporting Documents",
    details: "Orders, teams and sponsors list, and form responses.",
    url: `../reporting/documents`,
    documents: [
      {
        label: "Orders",
        details: "Report of all orders processed through the event site.",
        ext: ".xlsx",
        isDownload: true,
        url: `${tournament}/${tournamentID}/registration-orders-report`,
        name: `${currentTournament.name}-registration-orders-report`,
      },
      {
        label: "Teams & Sponsors List",
        details: "Report of all teams and sponsors in this tournament.",
        ext: ".xlsx",
        isDownload: true,
        url: `${tournament}/${tournamentID}/registration-export`,
        name: `${currentTournament.name}-registrations`,
      },
      {
        label: "Form Responses",
        details:
          "Report of all form responses collected through the event site.",
        ext: ".xlsx",
        isDownload: true,
        url: `${tournament}/${tournamentID}/registration-order-custom-field-responses`,
        name: `${currentTournament.name}-registration-responses`,
      },
    ],
  };

  const sponsorDocuments = {
    label: "Sponsor Documents",
    details: "All sponsor documents.",
    url: `../sponsors/documents`,
    documents: [
      {
        label: "Sponsor Images",
        details: "All sponsor and in-app sponsor images.",
        ext: ".zip",
        isDownload: true,
        url: `${tournament}/${tournamentID}/sponsor-images`,
        name: `${currentTournament.name}-sponsor-images`,
      },
    ],
  };

  return [
    teamDocuments,
    roundsDocuments,
    leaderboardDocuments,
    reportingDocuments,
    sponsorDocuments,
  ];
};

export const getRoundDocuments = (round) => {
  let roundID = round?.id;
  const notAvailableText = "Not available until the round has been started.";
  const getIndividualOptions = () => {
    if (round?.numberOfPlayers === 1) {
      return [
        {
          label: "USGA Perforated Scorecards (Bottom Tear Off)",
          details:
            round.state === "draft"
              ? notAvailableText
              : 'Single team scorecards - 11" x 4.25"',
          ext: ".pdf",
          isDownload: true,
          url: `${tournamentRounds}/${roundID}/print-perforated-scorecards`,
          id: roundID,
          disabled: round.state === "draft",
          data: { useHandicaps: "false", bottomPerferation: "true" },
        },
        {
          label: "USGA Perforated Scorecards (Top Tear Off)",
          details:
            round.state === "draft"
              ? notAvailableText
              : 'Single team scorecards - 11" x 4.25"',
          ext: ".pdf",
          isDownload: true,
          url: `${tournamentRounds}/${roundID}/print-perforated-scorecards`,
          id: roundID,
          disabled: round.state === "draft",
          data: { useHandicaps: "false", bottomPerferation: "true" },
        },
      ];
    }
    return [];
  };
  return [
    {
      label: "Scorecards (Portrait)",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Single team scorecards - 8.5" x 5.5"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-scorecards`,
      name: `Round${round.number}-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        useHandicaps: "true",
        splitBox: "false",
        orientation: "portrait",
      },
    },
    {
      label: "Undotted Scorecards (Portrait)",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Single team scorecards - 8.5" x 5.5""',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-scorecards`,
      name: `Round${round.number}-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        useHandicaps: "false",
        splitBox: "false",
        orientation: "portrait",
      },
    },
    //player only
    ...getIndividualOptions(),
    //player only
    {
      label: "Scorecards (Landscape)",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Single team scorecards - 11" x 4.25""',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-scorecards`,
      name: `Round${round.number}-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        useHandicaps: "true",
        splitBox: "false",
        orientation: "landscape",
      },
    },
    {
      label: "Undotted Scorecards (Landscape)",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Single team scorecards - 11" x 4.25""',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-scorecards`,
      name: `Round${round.number}-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: { useHandicaps: "false", orientation: "landscape" },
    },
    {
      label: "Full Pairing Scorecards",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Multi-team scorecards - 11" x 4.25"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-full-pairing-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        useHandicaps: "true",
        splitBox: "true",
        orientation: "landscape",
      },
    },
    {
      label: "Undotted Full Pairing Scorecards",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Multi-team scorecards - 11" x 4.25"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-full-pairing-scorecards`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        useHandicaps: "false",
        splitBox: "true",
        orientation: "landscape",
      },
    },
    {
      label: "Cart Signs",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Player and team details for golf carts - 8.5" x 5.5"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/cart-assignments.pdf`,
      id: roundID,
      disabled: round.state === "draft",
    },
    {
      label: "Cart Signs (No Team Codes)",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Player and team details for golf carts - 8.5" x 5.5"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/cart-assignments.pdf`,
      id: roundID,
      disabled: round.state === "draft",
      data: { show_team_codes: "false" },
    },
    {
      label: "Rule Sheet",
      isDownload: true,
      ext: ".pdf",
      url: `${tournamentRounds}/${roundID}/rule-sheet`,
      id: roundID,
    },
    {
      label: "Alpha Player List",
      details:
        round.state === "draft"
          ? notAvailableText
          : "Alphabetical player list with starting details and live scoring code.",
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/alpha-player-list.pdf`,
      id: roundID,
      disabled: round.state === "draft",
    },
    {
      label: "Hole Assignments",
      details:
        round.state === "draft"
          ? notAvailableText
          : "Course layout with starting details and live scoring code.",
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/hole-assignment-list.pdf`,
      id: roundID,
      disabled: round.state === "draft",
    },
    {
      label: "Avery Bag Labels",
      details:
        round.state === "draft"
          ? notAvailableText
          : 'Player list with starting details formatted for Avery Label Template 8160 - 1" x 2-5/8"',
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/avery-bag-labels`,
      name: `Round${round.number}-avery-bag-labels`,
      id: roundID,
      disabled: round.state === "draft",
    },
    {
      label: "Sponsor Assignments",
      details:
        round.state === "draft"
          ? notAvailableText
          : "Course layout with sponsor assignments and images.",
      ext: ".pdf",
      isDownload: true,
      url: `${tournamentRounds}/${roundID}/print-sponsor-list`,
      id: roundID,
      disabled: round.state === "draft",
      data: {
        orientation: "portrait",
      },
    },
    {
      label: "Main & Flighted Results",
      isDownload: true,
      ext: ".pdf",
      url: `${tournamentRounds}/${roundID}/round-results`,
      id: roundID,
      disabled: ["draft", "ready"].includes(round?.state),
    },
  ];
};
