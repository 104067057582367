import { faLock, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSItemList } from "golfstatus_react_components";
import React from "react";
import { getTime } from "../../../../helpers/Dates";
import TournamentTeamItem from "../../tournament-leaderboards/tournament-team-item";

import "./tournament-round-hole-assignment-grid-item.scss";

const TournamentRoundHoleAssignmentGridItem = (props) => {
  const { assignment, currentRound, tournamentTeams, hideUnassigned } = props;

  const getTeam = (id) => {
    return tournamentTeams?.find?.((t) => t?.id === id);
  };

  const teams = [...Array(assignment.maxTeamsPerHole)]?.map((x, index) =>
    assignment.tournamentTeams.length > index
      ? getTeam(assignment.tournamentTeams[index]?.id)
      : {}
  );

  const getTeams = () => {
    if(teams?.length > 0 && teams?.[0]?.id === undefined && hideUnassigned){
      return null
    }
    return <GSItemList
    type="vertical small-gap"
    items={teams}
    listItem={(item) =>
      item?.id ? (
        <TournamentTeamItem {...item}></TournamentTeamItem>
      ) : (
        getUnassigned()
      )
    }
  />
  }

  const getUnassigned = () => {
    if(hideUnassigned)
    {
      return null
    }
    return (
      <div className={`unassigned ${assignment.locked ? "locked" : ""}`}>
        {assignment.locked ? (
          <GSButton
            type="secondary"
            buttonIcon={faLock}
            title="Locked"
          ></GSButton>
        ) : (
          <GSButton
            type="secondary"
            buttonIcon={faUserPlus}
            title="Assign Team"
          ></GSButton>
        )}
      </div>
    );
  };

  const getItem = () => {
    if (currentRound.startType === "interval_start") {
      return (
        <div className="hole-assignment tee-time">
          <div className="bold hole">{getTime(assignment.teeTime)}</div>
          {getTeams()}
        </div>
      );
    } else if (currentRound.startType === "two_tee_interval_start") {
      return (
        <div className="hole-assignment tee-time">
          <div className="hole">
            <div className="bold">Hole {assignment.hole}</div>
            <div className="par">{getTime(assignment.teeTime)}</div>
          </div>
          {getTeams()}
        </div>
      );
    } else {
      return (
        <div className="hole-assignment shotgun">
          <div className="hole">
            <div className="bold">
              {`Hole ${assignment.hole} ${
                assignment.position
              } ${assignment.subPosition ?? ""}`}
            </div>
            <div className="par">Par {assignment.holePar}</div>
          </div>
          {getTeams()}
        </div>
      );
    }
  };

  return (
    <tournament-round-hole-assignment-grid-item>
      {getItem()}
    </tournament-round-hole-assignment-grid-item>
  );
};

export default TournamentRoundHoleAssignmentGridItem;
