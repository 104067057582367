import {faBan, faPen, faQrcode} from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import "./tournament-team-detail-hero.scss";

const TournamentTeamDetailHero = (props) => {
  const {
    name,
    handicap,
    entryCode,
    disqualified,
    contactName,
    email,
    phone,
    editClicked,
    disqualificationReason,
  } = props;

  const endsWithS = contactName?.toLowerCase?.()?.endsWith("s");
  const teamName = endsWithS ? `${contactName}' Team` : `${contactName}'s Team`;

  const teamInfoSection = {
    sectionItems: [
      {
        type: "header full-width",
        value: name ?? `${teamName} ${handicap ? `(${handicap})` : ""}`,
      },
    ],
  };

  const teamContactSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: contactName,
      },
      {
        type: "secondary no-wrap full-width",
        value: email,
      },
      {
        type: "secondary no-wrap full-width",
        value: phone,
      },
    ],
  };

  const actionSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: (
          <GSButton
            type="light-grey"
            buttonIcon={faPen}
            onClick={(e) => {
              editClicked?.();
              e.stopPropagation?.();
            }}
          />
        ),
      },
    ],
  };

  const disqualifiedSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            buttonIcon={disqualified ? faBan : faQrcode}
            type={`status secondary pill ${
              disqualified ? "orange" : "light-grey"
            }`}
            title={
              disqualified
                ? disqualificationReason.replace?.("_", " ")
                : entryCode
            }
          />
        ),
      },
    ],
  };

  const groupInfo = {
    fit: "stretch",
    sections: [
      { ...teamInfoSection },
      { ...disqualifiedSection },
      { ...teamContactSection },
    ],
  };

  const actionInfo = {
    sections: [{ ...actionSection }],
  };

  const getGroup = () => {
    let group = [{ ...groupInfo }];
    if (editClicked) {
      group.push({ ...actionInfo });
    }
    return group;
  };

  return (
    <tournament-team-detail-hero>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-team-detail-hero>
  );
};

export default TournamentTeamDetailHero;
