import {
  GSCircleImage,
  GSInfoGroup,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import "./tournament-leaderboard-result-line-item.scss";

const TournamentLeaderboardResultLineItem = (props) => {
  const {
    leaderboard,
    name,
    matchScores,
    matchResult,
    matchWinnerKey,
    netTotal,
    thru,
    totalScore,
    displayPosition,
    hole,
    score,
    scoreTerm,
    winner,
  } = props;

  const getMatchTeam = (item, direction) => {
    return (
      <div className={`teammate ${direction}`}>
        <div className={`teammate-image `}>
          <GSCircleImage size="large" url={item?.avatar}></GSCircleImage>
        </div>
        <div>{item?.name}</div>
      </div>
    );
  };

  //skins

  const skinHoleSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width position-width ",
        value: "Hole",
      },
      {
        type: "primary no-wrap full-width position-width centered ",
        value: hole,
      },
    ],
  };
  const skinScoreSection = {
    sectionItems: [
      {
        type: `primary no-wrap full-width position-width score ${scoreTerm}`,
        value: score,
      },
    ],
  };
  const skinWinnerSection = {
    fit: "stretch",
    sectionItems: [
      {
        type: winner === "-" ? "secondary full-width " : "primary full-width ",
        value:
          winner === "-" ? "No skins have been won for this hole." : winner,
      },
    ],
  };

  const skinHoleColumn = {
    sections: [
      {
        ...skinHoleSection,
      },
    ],
  };
  const skinScoreColumn = {
    sections: [
      {
        ...skinScoreSection,
      },
    ],
  };
  const skinWinnerColumn = {
    fit: "stretch",
    sections: [
      {
        ...skinWinnerSection,
      },
    ],
  };

  //stroke play
  const positionSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width position-width ",
        value: displayPosition,
      },
    ],
  };

  const teamSection = {
    sectionItems: [
      {
        type: "primary full-width",
        value: name,
      },
    ],
  };

  const scoreSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: "Total",
      },
      {
        type: "primary no-wrap full-width centered",
        value: netTotal === 0 ? "E" : netTotal > 0 ? `+${netTotal}` : netTotal,
      },
    ],
  };

  const thruSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: "Thru",
      },
      {
        type: "primary no-wrap full-width centered ",
        value: thru,
      },
    ],
  };

  const pointsSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: ["match_play", "stableford", "modified_stableford"].includes(
          leaderboard?.scoringSystem
        )
          ? "Points"
          : "Strokes",
      },
      {
        type: "primary no-wrap full-width centered ",
        value: totalScore,
      },
    ],
  };

  const positionColumn = {
    sections: [
      {
        ...positionSection,
      },
    ],
  };
  const teamColumn = {
    fit: "stretch",
    sections: [
      {
        ...teamSection,
      },
    ],
  };
  const scoreColumn = {
    sections: [
      {
        ...scoreSection,
      },
    ],
  };

  const thruColumn = {
    sections: [
      {
        ...thruSection,
      },
    ],
  };

  const pointsColumn = {
    sections: [
      {
        ...pointsSection,
      },
    ],
  };

  //match play
  const teamAAvatarSection = {
    sectionItems: [
      {
        type: `primary full-width team green ${
          matchScores?.[0].teamKey === matchWinnerKey ? "winner " : "loser"
        }`,
        value: (
          <GSItemList
            type={"vertical medium-large-gap"}
            items={matchScores?.[0]?.playerHash}
            listItem={(item) => getMatchTeam(item)}
          ></GSItemList>
        ),
      },
    ],
  };

  const matchResultSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: matchResult,
      },
      {
        type: "secondary no-wrap full-width",
        value: thru === "F" ? "Final" : `Thru ${thru}`,
      },
      
    ],
  };

  const teamBAvatarSection = {
    sectionItems: [
      {
        type: `primary full-width team orange ${
          matchScores?.[1].teamKey === matchWinnerKey ? "winner " : "loser"
        }`,
        value: (
          <GSItemList
            type={"vertical medium-large-gap"}
            items={matchScores?.[1]?.playerHash}
            listItem={(item) => getMatchTeam(item)}
          ></GSItemList>
        ),
      },
    ],
  };

  const teamAColum = {
    fit: "stretch",
    sections: [{ ...teamAAvatarSection }],
  };

  const matchResultColumn = {
    fit: "team-width",
    sections: [
      {
        ...matchResultSection,
      },
    ],
  };

  const teamBColumn = {
    fit: "stretch",
    sections: [{ ...teamBAvatarSection }],
  };

  const getGroup = () => {
    let group = [];

    if (matchResult) {
      group = [{ ...matchResultColumn },{ ...teamAColum },  { ...teamBColumn }];
    } else if (leaderboard?.type === "skins") {
      group = [
        { ...skinHoleColumn },

        { ...skinWinnerColumn },
        { ...skinScoreColumn },
      ];
    } else {
      group = [
        { ...positionColumn },
        { ...teamColumn },
        { ...scoreColumn },
        { ...thruColumn },
        { ...pointsColumn },
      ];
    }
    return group;
  };

  return (
    <tournament-leaderboard-result-line-item>
      <GSInfoGroup
        listType={matchResult ? `mobile-vertical` : ""}
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-leaderboard-result-line-item>
  );
};

export default TournamentLeaderboardResultLineItem;
