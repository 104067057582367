/* eslint-disable */

export function isEmail(text) {
  const expression = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return testRegex(expression, text)
}

export function isLink(text) {
  var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  return testRegex(expression, text);
}

export function isPhone(text)
{
  var expression = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gi;
  return testRegex(expression, text);
}

export function isHex(text)
{
  var expression = /[0-9A-Fa-f]{6}/g;
  return testRegex(expression, text);
}

export function isNumeric(text)
{
  var expression = /^-?\d*\.?\d*$/;
  return testRegex(expression, text);
}

export function integerWithCommas(x) {
  return x.toLocaleString("en-US", {minimumFractionDigits: 0});
}

export function numberWithCommas(x) {
  return x.toLocaleString("en-US", {minimumFractionDigits: 2});
}

export function moneyWithCommas(x) {
  if(x === undefined)
  {
    return "$0.00"
  }
  return `$${fixedWithCommas(x)}`;
}

export function fixedWithCommas(x) {
  return `${numberWithCommas(Number.parseFloat(`${x}`))}`;
}

export function centsToDollars(x)
{
  let dollars = x / 100;
  return moneyWithCommas(dollars);
}

function testRegex(expression, text)
{
  var regex = new RegExp(expression);
  return regex.test(text);
}
