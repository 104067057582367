
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-settings.scss"
import { leaderboardSettingsForm } from "../../../forms/LeaderboardForm";
import { useDispatch, useSelector } from "react-redux";
import { clearTournamentNotifications, getTournament, saveTournament, selectCurrentTournament, selectTournamentNotifications, setCurrentTournament } from "../../../reducers/tournamentSlice";
import { clearNotifications, createTeamFlights, deleteTeamFlights, selectFlightNotifications } from "../../../reducers/flightSlice";
import { useEffect } from "react";
import { useFormValidation } from "../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentLeaderboardSettings = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch("/:tournamentID/leaderboards/settings")

  const currentTournament = useSelector(selectCurrentTournament)
  const tournamentNotifications = useSelector(selectTournamentNotifications)
  const flightNotifications = useSelector(selectFlightNotifications)

  const [context, isValid, setIsValid] = useFormValidation(false)

  useEffect(() => {
    if(match?.params?.tournamentID)
    {
      dispatch(getTournament(match?.params?.tournamentID))
    }
  },[dispatch, match?.params?.tournamentID])

  useEffect(() => {
    return () => {dispatch(clearNotifications()); dispatch(clearTournamentNotifications())}
  }, [dispatch])

  context.updateTournament = (value, property) => {
    setIsValid(true);
    let tourney = { ...currentTournament };
    tourney[property] = value;
    return dispatch(setCurrentTournament(tourney));
  };

  context.destroyFlights = () => {
    dispatch(deleteTeamFlights(currentTournament.id))?.then?.(() => {dispatch(getTournament(currentTournament.id))})
  }

  context.createFlights = () => {
    dispatch(createTeamFlights(currentTournament.id))?.then?.(() => {dispatch(getTournament(currentTournament.id))})
  }

  const save = () => {
    setUnsaved(false)
    setIsValid(false)
    dispatch(saveTournament(currentTournament));
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Leaderboard Settings`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle={"Leaderboard Settings"} formSections={leaderboardSettingsForm(context, currentTournament )}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: [...tournamentNotifications, ...flightNotifications],
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-leaderboard-settings>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-settings>
  );
};

//export the new namet
export default TournamentLeaderboardSettings;