import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-payments-invoice.scss";
import { useFormDataValidation } from "../../../hooks/formHooks";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  createCustomerInvoice,
  getTournamentCustomerInvoice,
  getTournamentPaymentSettings,
  saveCustomerInvoice,
  selectCurrentPaymentSettings,
  selectCustomerInvoice,
  selectSettingsNotifications,
  setCustomerInvoice
} from "../../../reducers/settingsSlice";
import { paymentSettingsInvoiceForm } from "../../../forms/SettingsForms";
import { useSelector } from "react-redux";
import {
  getTournamentDonationWatchItems,
  selectCurrentTournamentDonationWatchItems,
  setCurrentDonationWatchItem,
  selectCurrentTournament
} from "../../../reducers/tournamentSlice";

//Name the component
const TournamentSettingsPaymentsInvoice = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/settings/payments/invoice");
  const notifications = useSelector(selectSettingsNotifications);

  const currentPaymentSettings = useSelector(selectCurrentPaymentSettings);
  const customerInvoice = useSelector(selectCustomerInvoice);

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: true,
    data: customerInvoice,
    setData: setCustomerInvoice,
  });

  context.currentTournament = useSelector(
    selectCurrentTournament
  );

  context.currentTournamentDonationWatchItems = useSelector(
    selectCurrentTournamentDonationWatchItems
  );

  context.addWatchEmail = () => {
    dispatch(setCurrentDonationWatchItem({}));
    navigate("add");
  };

  context.editWatchEmail = (watchItem) => {
    navigate(`${watchItem.id}`);
  };

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPaymentSettings(match.params.tournamentID))?.then?.((result) => {
        dispatch(getTournamentCustomerInvoice(result?.payload?.id))
      });
      dispatch(getTournamentDonationWatchItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const save = () => {
    setIsValid(false);
    let update = { ...customerInvoice };
    update["tournament-payment-setting"] = currentPaymentSettings;
    //serializer does not like to properly case numbers
    update["address-line-1"] = update.addressLine1;
    update["address-line-2"] = update.addressLine2;
    update["address-line-3"] = update.addressLine3;
    update["address-line-4"] = update.addressLine4;
    if(customerInvoice?.id){
      dispatch(saveCustomerInvoice(update))
    }
    else{
      dispatch(createCustomerInvoice(update))
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Invoices`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle="Invoices"
        formSections={paymentSettingsInvoiceForm(
          context,
          currentPaymentSettings
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-settings-payments-invoice>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-payments-invoice>
  );
};

//export the new namet
export default TournamentSettingsPaymentsInvoice;
