import React, { useCallback, useEffect, useState } from "react";
import "./tournament-round-create-edit.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  getTournament,
  getTournamentFormatTypes,
  selectCurrentTournament,
  selectTournamentFormatTypes,
} from "../../../reducers/tournamentSlice";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { GSSidePanelPage, GSForm } from "golfstatus_react_components";
import { getRoundDetailForm } from "../../../forms/TournamentRoundForms";
import {
  createRound,
  getRound,
  saveRound,
  selectCurrentRound,
  selectRoundNotifications,
  setCurrentRound,
  setRoundNotifications,
} from "../../../reducers/roundSlice";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { useFormValidation } from "../../../hooks/formHooks";

const TournamentRoundCreateEdit = (props) => {
  //state
  const [selectedFacility, setSelectedFacility] = useState();
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedTees, setSelectedTees] = useState([]);

  //hooks
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/*");
  const navigate = useNavigate();
  const copy = match?.params?.["*"];

  //selectors
  const tournamentRoundNotifications = useSelector(selectRoundNotifications);
  const currentRound = useSelector(selectCurrentRound);
  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentFormatTypes = useSelector(selectTournamentFormatTypes);
  const [selectedFormatType, setSelectedFormatType] = useState({});

  //form context
  const [context, isValid, setIsValid] = useFormValidation();

  //callbacks
  const loadRound = useCallback(() => {
    if (match.params.roundID !== "new") {
      dispatch(getRound(match.params.roundID)).then((response) => {
        const round = response?.payload;
        const roundTees = round?.tees?.map?.((t) => ({
          ...t,
          courseID: round.roundCourses?.find?.((c) =>
            c?.tees?.map?.((t) => t.id).includes(t.id)
          )?.id,
        }));
        setSelectedTees(roundTees);
        setSelectedFacility({
          ...round?.facility,
          courses: [...round?.courses],
        });
        setSelectedCourses(round?.roundCourses);
      });
    }
  }, [dispatch, match.params.roundID]);

  const getCompatibleFormats = useCallback(() => {
    return tournamentFormatTypes?.filter?.(
      (f) =>
        f.numberOfPlayers ===
        (currentTournament.numberOfPlayers !== 0
          ? currentTournament.numberOfPlayers
          : f.numberOfPlayers)
    );
  }, [tournamentFormatTypes, currentTournament.numberOfPlayers]);

  //effects
  useEffect(() => {
    dispatch(getTournamentFormatTypes());
  }, [dispatch]);

  useEffect(() => {
    if (copy === "copy" && currentRound.id) {
      dispatch(
        setCurrentRound({
          ...currentRound,
          id: undefined,
          startAt: "",
          number: undefined,
        })
      );
    }
  }, [dispatch, copy, currentRound]);

  useEffect(() => {
    if (currentRound.tournamentFormatTypes) {
      setSelectedFormatType(currentRound.tournamentFormatTypes?.[0]);
    }
  }, [currentRound.tournamentFormatTypes]);

  useEffect(() => {
    loadRound();
  }, [loadRound]);

  useEffect(() => {
    return () => {
      dispatch(getTournament(match.params.tournamentID));
    };
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.roundID === "new") {
      if (tournamentFormatTypes?.length !== 0) {
        let formattypes = getCompatibleFormats();
        let index = formattypes.findIndex((ft) => ft.id === "1");
        setSelectedFormatType(formattypes?.[index !== -1 ? index : 0]);
      }
    }
  }, [tournamentFormatTypes, getCompatibleFormats, match.params.roundID]);

  useEffect(() => {
    if (match?.params?.roundID === "new") {
      dispatch(
        setCurrentRound({
          startType: "shotgun_start",
          startAt: "",
          teeTimeInterval: "7/8",
          holeSize: 4,
        })
      );
    }
  }, [dispatch, match?.params?.roundID]);

  useEffect(() => {
    if (currentRound.id) {
      setSelectedCourses(currentRound.roundCourses);
    }
  }, [currentRound.id, currentRound.roundCourses]);

  useEffect(() => {
    if (
      currentRound.startType &&
      selectedFormatType?.numberOfPlayers &&
      selectedFormatType?.availableHoleSizes &&
      !selectedFormatType?.availableHoleSizes?.includes?.(currentRound.holeSize)
    ) {
      let defaultSize =
        selectedFormatType?.numberOfPlayers > 3 &&
        selectedFormatType.availableHoleSizes.includes(
          selectedFormatType.numberOfPlayers
        )
          ? selectedFormatType?.numberOfPlayers
          : selectedFormatType?.availableHoleSizes?.[0];

      context.updateCurrentRound(defaultSize, "holeSize");
    }
  }, [
    selectedFormatType?.numberOfPlayers,
    selectedFormatType?.availableHoleSizes,
    context,
    currentRound.startType,
    currentRound.holeSize,
  ]);

  //context for the form

  context.selectFacility = (facility) => {
    if (facility?.id) {
      setIsValid(true);
      setSelectedFacility(facility);
    }
  };

  context.removeFacility = (facility) => {
    setSelectedFacility(false);
    setSelectedCourses([]);
  };

  context.addTee = (courseID, tee) => {
    setIsValid(true);
    setSelectedTees([...selectedTees, { courseID: courseID, ...tee }]);
  };

  context.removeTee = (courseID, tee) => {
    setIsValid(true);
    setSelectedTees(selectedTees.filter((t) => t.id !== tee.id));
  };

  context.holesSelected = (courseID, holes) => {
    setIsValid(true);
    let courseIndex = selectedCourses.findIndex((c) => c.id === courseID);
    let course = { ...selectedCourses?.[courseIndex] };
    course.holesToPlay = holes?.value;
    course.numberOfHoles = holes?.numberOfHoles;
    setSelectedCourses([
      ...selectedCourses.filter((sc) => sc.id !== courseID),
      course,
    ]);
  };

  context.getFacility = () => {
    return selectedFacility;
  };

  context.selectCourse = (course) => {
    setIsValid(true);
    setSelectedCourses([...(selectedCourses ?? []), course]);
  };

  context.removeCourse = (course) => {
    setSelectedCourses(selectedCourses.filter?.((c) => c.id !== course.id));
  };

  context.getSelectedCourses = () => {
    return selectedCourses;
  };

  context.getTournament = () => {
    return currentTournament;
  };

  context.getTournamentFormatTypes = () => {
    return getCompatibleFormats()?.map((type, index) => ({
      label: `${type.name} (${type.numberOfPlayers})`,
      value: type,
    }));
  };

  context.hasFacility = () => {
    return selectedFacility?.id !== undefined;
  };

  context.updateCurrentRound = (value, property) => {
    setIsValid(true);
    let updatedRound = { ...currentRound };
    updatedRound[property] = value;
    dispatch(setCurrentRound(updatedRound));
  };

  context.formatChanged = (type) => {
    setIsValid(true);
    setSelectedFormatType(type.value);
  };

  context.getSelectedFormatType = () => {
    return selectedFormatType;
  };

  context.getSelectedFacility = () => {
    return selectedFacility;
  };

  context.getRoundTees = () => {
    return selectedTees;
  };

  context.isCreate = () => {
    return match?.params?.roundID === "new" || match?.params?.["*"] === "copy";
  };

  //save functions

  const save = () => {
    setIsValid(false);
    setUnsaved(false);
    if (match?.params?.roundID === "new" || match?.params?.["*"] === "copy") {
      createNewRound();
    } else {
      let updatedRound = { ...currentRound };
      updatedRound.facility = selectedFacility;
      updatedRound.tournamentFormatTypes = [selectedFormatType];
      updatedRound.startAt = new Date(updatedRound.startAt).toISOString();
      updatedRound.roundCourses = selectedCourses.map((sc) => ({
        ...sc,
        tees: [...selectedTees.filter((t) => t.courseID === sc.id)],
      }));
      dispatch(saveRound(updatedRound));
    }
  };

  const createNewRound = () => {
    let updatedRound = { ...currentRound };
    updatedRound.id = undefined;
    updatedRound.state = "draft";
    updatedRound.numberOfHoles = 18;
    updatedRound.number =
      context?.getTournament?.()?.tournamentRounds?.length + 1;
    updatedRound.facility = { ...selectedFacility };
    updatedRound.tournament = { ...currentTournament };
    updatedRound.tournamentFormatTypes = [selectedFormatType];
    updatedRound.roundCourses = selectedCourses.map((sc) => ({
      ...sc,
      id: undefined,
      tees: sc.tees ?? selectedTees.filter((t) => t.courseID === sc.id),
    }));
    updatedRound.startAt = new Date(updatedRound.startAt).toISOString();
    dispatch(createRound(updatedRound));
  };

  //component functionality
  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const timeoutAction = () => {
    dispatch(setRoundNotifications([]));
  };

  const getActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const getNavigation = () => {
    return {
      title: "Round Details",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          context.isCreate?.()
            ? copy === "copy"
              ? "Copy Round"
              : "Add Round"
            : "Edit Round"
        }
        formSections={getRoundDetailForm(context, currentRound)}
      />
    );
  };

  const getDrawer = () => {
    return {
      actions: getActions(),
    };
  };

  //custom hooks
  const notificationSettings = {
    notifications: tournamentRoundNotifications,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };
  useNotificationNavigation(
    "created",
    tournamentRoundNotifications,
    `/${match.params.tournamentID}/rounds/[id]`
  );
  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  //view
  return (
    <tournament-round-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-round-create-edit>
  );
};

export default TournamentRoundCreateEdit;
