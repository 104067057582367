
import { faCommentAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSInfoCard,
  GSInfoGroup,
} from "golfstatus_react_components";
import React from "react";

import "./tournament-team-card.scss";

const TournamentTeamCard = (props) => {
  const { contactName, entryCode, phone, email, unassigned, header, signupMessage, messageClicked, waitlistType} = props;

  //const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";


  const contactInfo = {
    sectionItems: [
      {
        type: "primary no-wrap full-width capitalize",
        value: `${contactName}`,
      },
      {
        type: "secondary nowrap",
        value: email
      },
      {
        type: "secondary nowrap",
        value: phone
      },
    ],
  }

  const waitListTypeSection = {
    sectionItems: [
      {
        type: "secondary nowrap capitalize",
        value: waitlistType
      },
    ],
  }

  const playerInfo = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton type={`status secondary ${unassigned ? "light-grey" : "light-grey"}`} buttonIcon={faPlus} title={entryCode}/>,
      },
    ],
  }

  const message = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton type="secondary" buttonIcon={faCommentAlt} onClick={(e) => {messageClicked?.({...props}); e?.stopPropagation?.()}} />,
      },
    ],
  }
  

  const teamInfo = {
    fit: "stretch",
    sections: [
      {...contactInfo},  
    ],
  };

  const messageInfo = {
    sections: [
      {...message},  
    ],
  }

  const getGroup = () => {
    if(waitlistType){
      teamInfo.sections.push(waitListTypeSection)
    }
    teamInfo.sections.push(playerInfo)
    let group = [{ ...teamInfo }];
    if(signupMessage)
    {
      group.push({...messageInfo})
    }
    
    return group;
  };
  
  return (
    <tournament-team-card class={header ? "header" : ""}>
      <GSInfoCard size="large" layout={ <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>}></GSInfoCard>
    </tournament-team-card>
  );
};

export default TournamentTeamCard;
