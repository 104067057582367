import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-team-round-settings.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentTeam,
  getTournamentTeamRounds,
  selectCurrentTeam,
  selectLoading,
  selectTeamsNotifications,
  selectTournamentTeamRounds,
  setCurrentTeamRound,
  updateTeamRound,
} from "../../../reducers/teamsSlice";
import { getTeamRoundSettingsForm } from "../../../forms/TeamForm";
import TournamentTeamDetailHero from "./tournament-team-detail-hero";

//Name the component
const TournamentTeamRoundSettings = (props) => {
  const [context, isValid, setIsValid] = useFormValidation(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = useSelector(selectTeamsNotifications);
  const match = useMatch("/:tournamentID/teams/:teamID/rounds");

  const teamRounds = useSelector(selectTournamentTeamRounds);
  const currentTeam = useSelector(selectCurrentTeam);
  const loading = useSelector(selectLoading);

  context.getTeamRounds = () => {
    return teamRounds;
  };

  context.updateRound = (round, value, property) => {
    setIsValid(true)
    let update = { ...round };
    update[property] = value;
    update.updated = true
    dispatch(setCurrentTeamRound(update));
  };

  useEffect(() => {
    if (match?.params?.teamID) {
      dispatch(getTournamentTeamRounds(match?.params?.teamID));
      dispatch(getTournamentTeam(match?.params?.teamID))
    }
  }, [match?.params?.teamID, dispatch]);

  const save = () => {
    setIsValid(false);
    teamRounds.forEach?.((round) => {
      if(round.updated)
      dispatch(updateTeamRound(round)) 
    })
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Handicaps`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={ <TournamentTeamDetailHero {...currentTeam}/>}
        formSections={getTeamRoundSettingsForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-team-round-settings>
      <GSSidePanelPage
        loading={loading?.length > 0}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-round-settings>
  );
};

//export the new namet
export default TournamentTeamRoundSettings;
