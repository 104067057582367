import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { getSuccessTitle, successForm } from "../../forms/SuccessForm";
import "./tournament-quick-start-success.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournament,
  selectCurrentTournament,
} from "../../reducers/tournamentSlice";
import TournamentPromotionQuickView from "../tournament/tournament-promotion/tournament-promotion-quick-view";

const TournamentQuickStartSuccess = (props) => {
  const navigate = useNavigate();
  const match = useMatch(`/tournaments/success/:id`);
  const dispatch = useDispatch();

  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    if (match?.params?.id) {
      dispatch(getTournament(match?.params?.id));
    }
  }, [match?.params?.id, dispatch]);

  const leftNavigation = () => {
    navigate("/tournaments");
  };

  const getNavigation = () => {
    return {
      title: `Tournament Created`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getSuccessTitle("Tournament Created")}
        formSections={successForm(
          `${currentTournament?.name} has been successfully created.`,
          <TournamentPromotionQuickView {...currentTournament} />,
          "overview",
          `${currentTournament?.name} has been successfully created!`,
        )}
      ></GSForm>
    );
  };

  const overview = () => {
    navigate(`/${match?.params?.id}`);
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Tournament Overview",
        action: overview,
        type: "black",
      },
      { name: "close", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    <tournament-quick-start-success>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-quick-start-success>
  );
};

export default TournamentQuickStartSuccess;
