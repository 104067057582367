import {
  faExclamationTriangle,
  faCheck,
  faTrash,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { getNotificationItemInfo } from "../helpers/Converters";
import { extraLongDelay, getErrors, longDelay, mediumDelay, shortDelay } from "../helpers/JSONapi";

export const baseURL = `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}`;

export const resetPassword = `${baseURL}/v1/users/change_password.json`;
export const usersURL = `${baseURL}v2/users`;
export const employeesURL = `${baseURL}v2/golf-status-employees`;
export const userCheckins = `${baseURL}v2/check-ins`;
export const userRounds = `${baseURL}v2/user-rounds`;
export const pushNotificationsURL = `${baseURL}v2/push-notifications`;
export const scorecardsURL = `${baseURL}v2/scorecards`;
export const tournamentRounds = `${baseURL}v2/tournament-rounds`;
export const roundHoleAssignments = `${baseURL}v2/round-hole-assignments`;
export const tournamentTeams = `${baseURL}v2/tournament-teams`;
export const players = `${baseURL}v2/players`;
export const inProgressPlayers = `${baseURL}v2/in-progress-players`;
export const waitListMembers = `${baseURL}v2/wait-list-members`;
export const tournament = `${baseURL}v2/tournaments`;
export const tournamentPromotions = `${baseURL}v2/tournament-promotions`;
export const externalLinks = `${baseURL}v2/external-links`;
export const facilities = `${baseURL}v2/facilities`;
export const courses = `${baseURL}v2/courses`;
export const tees = `${baseURL}v2/tees`;
export const invoices = `${baseURL}v2/invoices`;
export const organizations = `${baseURL}v2/organizations`;
export const organizationSettings = `${baseURL}v2/organization-settings`;
export const organizationLinks = `${baseURL}v2/organization-additional-links`;
export const organizationMembers = `${baseURL}v2/organization-members`;
export const organizationManagers = `${baseURL}v2/organization-managers`;
export const products = `${baseURL}v2/products`;
export const registrationCustomers = `${baseURL}v2/registration-customers`;
export const igolf = `${baseURL}v2/igolf`;
export const adminStats = `${baseURL}admin/admin-stats`;
export const fileUplaod = `${baseURL}v2/file`;
export const sponsors = `${baseURL}v2/sponsors`;
export const sponsorRoundHoles = `${baseURL}v2/sponsor-round-holes`;

export const liveTournamentRounds = `${baseURL}v2/live-tournament-rounds`;
export const liveTournamentScorecards = `${baseURL}v2/live-tournament-scorecards`;

export const roundCourses = `${baseURL}v2/round-courses`;
export const roundTees = `${baseURL}v2/round-tees`;
export const roundTeeChoices = `${baseURL}v2/round-tee-choices`;
export const roundTeePlayerChoices = `${baseURL}v2/round-tee-player-choices`;
export const teamRounds = `${baseURL}v2/team-rounds`;

export const tournamentFormatTypes = `${baseURL}v2/tournament-format-types`;
export const tournamentFormats = `${baseURL}v2/tournament-formats`;
export const leaderboardTypeSettings = `${baseURL}v2/leaderboard-type-settings`;
export const tournamentFlights = `${baseURL}v2/tournament-flights`;
export const teamPreflights = `${baseURL}v2/team-preflights`;
export const teamFlights = `${baseURL}v2/team-flights`;
export const preflightingTournamentAndFlights = `${baseURL}v2/preflighting-tournament-and-flights`;
export const addOnTeams = `${baseURL}v2/add-on-teams`;

export const tournamentPackages = `${baseURL}v2/tournament-packages`;
export const tournamentPackageItems = `${baseURL}v2/tournament-package-items`;
export const teamPackageItems = `${baseURL}v2/team-package-items`;
export const playerPackageItems = `${baseURL}v2/player-package-items`;
export const sponsorPackageItems = `${baseURL}v2/sponsor-package-items`;
export const technologySponsorPackageItems = `${baseURL}v2/technology-sponsor-package-items`;
export const skinsAddOnPackageItems = `${baseURL}v2/skins-add-on-package-items`;
export const addOnPackageItems = `${baseURL}v2/add-on-package-items`;
export const packageWatchItems = `${baseURL}v2/package-watch-items`;
export const registrationFieldSets = `${baseURL}v2/registration-field-sets`;
export const TournamentPackageRegistrationFieldSets = `${baseURL}v2/tournament-package-registration-field-sets`;
export const registrationCustomFields = `${baseURL}v2/registration-custom-fields`;
export const discountCodes = `${baseURL}v2/discount-codes`;
export const accessGroups = `${baseURL}v2/access-groups`;

export const registrationOrders = `${baseURL}v2/registration-orders`
export const payouts = `${baseURL}v2/payouts`
export const tournamentPaymentSettings = `${baseURL}v2/tournament-payment-settings`
export const donationWatchItems = `${baseURL}v2/donation-watch-items`
export const defaultWatchItems = `${baseURL}v2/default-watch-items`
export const customerInvoiceWatchEmails = `${baseURL}v2/customer-invoice-watch-emails`

export const customerInvoices = `${baseURL}v2/customer_invoices`


export const userTournaments = (userID) => `${usersURL}/${userID}/tournaments`;

const getName = (thunk) => {
  if(thunk?.typePrefix?.includes?.("/")){
    return thunk?.typePrefix?.split?.("/")?.[1]
  }
  else return thunk.typePrefix
}

export const thunkResponse = (thunk, callback, name, notifications) => {
  return {
    [thunk.pending]: (state, action) => {
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(notifications?.pendingText, faSpinner),
          timeout: notifications?.pendingDelay,
          result: "pending"
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(notifications.fulfilledText, faCheck),
          state: "success",
          result: "saved",
          resultPayload: action.payload,
          name: name,
          timeout: notifications.fulfilledDelay
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: notifications.rejectedDelay,
        },
      ];
    },
  };
};

// THUNK responses from the api
export const getThunkResponse = (thunk, callback) => {
  return {
    [thunk.pending]: (state, action) => {
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: longDelay,
        },
      ];
    },
  };
};

export const createThunkResponse = (thunk, callback, name, timeout) => {
  return {
    [thunk.pending]: (state, action) => {
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(`Saving ${name}`, faSpinner),
          timeout: timeout ? timeout : shortDelay,
          result: "pending"
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(`${name} has been created!`, faCheck),
          state: "success",
          result: "created",
          resultPayload: action.payload,
          name: name,
          timeout: shortDelay,
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          reult: "failed",
          timeout: longDelay,
          result: "error"
        },
      ];
    },
  };
};

export const saveThunkResponse = (thunk, callback, name, timeout) => {
  return {
    [thunk.pending]: (state, action) => {
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(`Saving ${name}`, faSpinner),
          timeout: timeout ? timeout : shortDelay,
          result: "pending"
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(`${name} has been saved!`, faCheck),
          state: "success",
          result: "saved",
          resultPayload: action.payload,
          name: name,
          timeout: mediumDelay
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: longDelay,
        },
      ];
    },
  };
};

export const deleteThunkResponse = (thunk, callback, name, timeout) => {
  return {
    [thunk.pending]: (state, action) => {
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(`Removing ${name}`, faSpinner),
          timeout: timeout ? timeout : shortDelay,
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(`${name} has been removed!`, faTrash),
          state: "success",
          result: "removed",
          timeout: shortDelay,
          name: name,
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: longDelay,
        },
      ];
    },
  };
};

export const uploadThunkResponse = (thunk, callback, name, timeout) => {
  return {
    [thunk.pending]: (state, action) => {
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(`Uploading ${name}`, faSpinner),
          timeout: timeout,
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(`${name} has been uploaded!`, faCheck),
          state: "success",
          result: "uploaded",
          resultPayload: action.payload,
          name: name,
          timeout: timeout,
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: shortDelay,
        },
      ];
    },
  };
};

export const validateThunkResponse = (thunk, callback, name, timeout) => {
  return {
    [thunk.pending]: (state, action) => {
      
      state.notifications = [
        {
          state: "grey",
          header: getNotificationItemInfo(`Validating file`, faSpinner),
          timeout: timeout,
        },
      ];
      state.loading.push(getName(thunk));
    },
    [thunk.fulfilled]: (state, action) => {
      state.notifications = [
        {
          header: getNotificationItemInfo(`This file has been validated!`, faCheck),
          state: "success",
          result: "validated",
          resultPayload: action.payload,
          name: name,
          timeout: timeout,
        },
      ];
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      callback?.(state, action);
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter(
        (f) => f !== getName(thunk)
      );
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: shortDelay,
        },
      ];
    },
  };
};

export const fileDownloadThunkResponse = (thunk, nameFunction) => {
  return {
    [thunk.fulfilled]: (state, action) => {
      const filename = nameFunction?.(state, action);
      let blob = new Blob([action.payload.data]);
      if (window.navigator.msSaveBlob) {
        // IE
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        let windowUrl = window.URL || window.webkitURL;
        let fileUrl = windowUrl.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = fileUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(fileUrl);
        }, 0);
      }
      state.notifications = [
        {
          header: getNotificationItemInfo(`File has been successfully Downloaded.`, faCheck),
          state: "success",
          timeout: shortDelay,
        },
      ];
      state.loading = state.loading.filter((f) => f !== getName(thunk));
    },
    [thunk.pending]: (state, action) => {
      state.loading.push(getName(thunk));
      state.notifications = [
        {
          header: getNotificationItemInfo(`Downloading file... this may take a moment.`, faSpinner),
          timeout: extraLongDelay,
        },
      ];
    },
    [thunk.rejected]: (state, action) => {
      state.loading = state.loading.filter((f) => f !== getName(thunk));
      state.notifications = [
        {
          header: getNotificationItemInfo(
            getErrors(action),
            faExclamationTriangle
          ),
          state: "warning",
          result: "failed",
          timeout: shortDelay,
        },
      ];
    }
  }
}
