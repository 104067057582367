
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-scoring-settings.scss"
import { getScoringSettings } from "../../../../forms/RoundScoresForm";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { clearNotifications, generateFakeScores, selectAnnounceScore, selectNotifications, setAnnounceScore } from "../../../../reducers/scorecardSlice";
import { useSelector } from "react-redux";
import { clearRoundNotifications, deactivateRoundScorecards, getRound, selectCurrentRound, selectRoundNotifications } from "../../../../reducers/roundSlice";
import { useEffect } from "react";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { getOrgSettings, selectOrganizationSettings } from "../../../../reducers/organizationSlice";
import { getRoundName } from "../../../../helpers/Converters";

//Name the component
const TournamentRoundScoringSettings = (props) => {
  const navigate = useNavigate()
  const match = useMatch("/:tournamentID/rounds/:roundID/scoring/settings")
  const dispatch = useDispatch()

  const currentTournament = useSelector(selectCurrentTournament)
  const currentTournamentOrganizationSettings = useSelector(selectOrganizationSettings)

  const currentRound = useSelector(selectCurrentRound)
  const notifications = [...useSelector(selectRoundNotifications), ...useSelector(selectNotifications)]
  const announceScore = useSelector(selectAnnounceScore)

  useEffect(() => {
    if(match?.params?.roundID){
      dispatch(getRound(match.params.roundID))
    }
  }, [dispatch, match?.params?.roundID])

  useEffect(() => {
    if(currentTournament?.organization?.id){
      dispatch(getOrgSettings(currentTournament.organization.id))
    }
  }, [currentTournament?.organization?.id, dispatch])

  const context = useMemo(() => ({}),[])

  context.generateFakeScores = () => {
    dispatch(generateFakeScores(match?.params?.roundID))
  }

  context.postScoresToGHIN = () => {
    navigate("ghin")
  }

  context.getSpeech = () => {
    return announceScore
  }

  context.toggleSpeech = () => {
    dispatch(setAnnounceScore(!announceScore))
  }

  context.removeScorecards = () => {
    dispatch(deactivateRoundScorecards(match.params.roundID));
  };

  context.getTournamentOrgSettings = () => {
    return currentTournamentOrganizationSettings
  }

  const getDrawerActions = () => {
    let actions = [
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${getRoundName(currentRound)} Scorecard Settings`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle={`Round ${getRoundName(currentRound)} Scorecard Settings`} formSections={getScoringSettings(context, currentRound)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
    dispatch(clearRoundNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-round-scoring-settings>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-scoring-settings>
  );
};

//export the new namet
export default TournamentRoundScoringSettings;