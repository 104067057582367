import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  inProgressPlayers,
  players,
  roundCourses,
  roundTeeChoices,
  roundTeePlayerChoices,
  roundTees,
  saveThunkResponse,
  teamRounds,
  tournament,
  tournamentTeams,
  uploadThunkResponse,
  validateThunkResponse,
  waitListMembers,
} from "../app/api";
import gsApi from "../auth/auth";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  shortDelay,
} from "../helpers/JSONapi";

//get
export const getTournamentTeams = createAsyncThunk(
  "teams/getTournamentTeams",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${id}/tournament-teams`);
    return response;
  }
);

export const getTournamentTeam = createAsyncThunk(
  "teams/getTournamentTeam",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentTeams}/${id}`);
    return response;
  }
);

export const getTournamentTeamTeeChoices = createAsyncThunk(
  "teams/getTournamentTeamTeeChoices",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentTeams}/${id}/round-tee-choices`
    );
    let tees = [];
    for (const teeChoice of response) {
      const t = await gsApi.get(`${roundTees}/${teeChoice.roundTee.id}`);
      const c = await gsApi.get(
        `${roundCourses}/${teeChoice?.roundCourse?.id}`
      );
      tees.push({ ...teeChoice, roundTee: t, roundCourse: c });
    }
    return tees;
  }
);

export const getTournamentTeamPlayerTeeChoices = createAsyncThunk(
  "teams/getTournamentTeamPlayerTeeChoices",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${players}/${id}/round-tee-player-choices`
    );
    let tees = [];
    for (const teeChoice of response) {
      const t = await gsApi.get(`${roundTees}/${teeChoice?.roundTee?.id}`);
      const c = await gsApi.get(
        `${roundCourses}/${teeChoice?.roundCourse?.id}`
      );
      tees.push({ ...teeChoice, roundTee: t, roundCourse: c });
    }
    return tees;
  }
);

export const getTournamentTeamPlayer = createAsyncThunk(
  "teams/getTournamentTeamPlayer",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${players}/${id}`);
    return response;
  }
);

export const getTournamentTeamScorecards = createAsyncThunk(
  "teams/getTournamentTeamScorecards",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentTeams}/${id}/live-tournament-scorecards`
    );
    return response;
  }
);

export const getTournamentTeamRegistrationOrder = createAsyncThunk(
  "teams/getTournamentTeamRegistrationOrder",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentTeams}/${id}/registration-order`
    );
    return response;
  }
);

export const getTournamentWaitList = createAsyncThunk(
  "teams/getTournamentWaitList",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${id}/wait-list-members`);
    return response;
  }
);

export const getTournamentUnassignedPlayers = createAsyncThunk(
  "teams/getTournamentUnassignedPlayers",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${id}/unassigned-players`);
    return response;
  }
);

export const getTournamentTeamRounds = createAsyncThunk(
  "teams/getTournamentTeamRounds",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentTeams}/${id}/team-rounds`);
    return response;
  }
);

//create

export const createTournamentTeam = createAsyncThunk(
  "teams/createTournamentTeam",
  async (team, thunkAPI) => {
    let serializedData = getSerializedData(
      team,
      "tournament-teams",
      getOpts(team, [...getTournamentRelationship()])
    );

    const serializedPlayers = team.players.map(
      (p) => getSerializedData({ ...p, id: null }, "players", getOpts(p))?.data
    );

    serializedData.data.relationships["players"] = {
      data: serializedPlayers,
    };

    return await getResponse(
      gsApi.post,
      `${tournamentTeams}/team-rounds-and-players`,
      serializedData,
      thunkAPI
    );
  }
);

//save

export const saveTournamentTeam = createAsyncThunk(
  "teams/saveTournamentTeam",
  async (team, thunkAPI) => {
    let serializedData = getSerializedData(
      team,
      "tournament-teams",
      getOpts(team, [...getTournamentRelationship()])
    );

    const serializedPlayers = team.players.map(
      (p) =>
        getSerializedData(
          { ...p, id: p?.id?.length > 1 ? p.id : null },
          "players",
          getOpts(p)
        )?.data
    );

    serializedData.data.relationships["players"] = {
      data: serializedPlayers,
    };

    return await getResponse(
      gsApi.patch,
      `${tournamentTeams}/${team.id}`,
      serializedData,
      thunkAPI
    );
  }
);
export const saveTournamentTeamPlayer = createAsyncThunk(
  "teams/saveTournamentTeamPlayer",
  async (player, thunkAPI) => {
    let serializedData = getSerializedData(
      player,
      "players",
      getOpts(player, [...getTournamentTeamRelationship()])
    );
    return await getResponse(
      gsApi.patch,
      `${players}/${player.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const updateUnassignedPlayer = createAsyncThunk(
  "teams/updateUnassignedPlayer",
  async (player, thunkAPI) => {
    let serializedData = getSerializedData(
      player,
      "in-progress-players",
      getOpts(player)
    );
    return await getResponse(
      gsApi.patch,
      `${inProgressPlayers}/${player.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const updateWaitListMember = createAsyncThunk(
  "teams/updateWaitListMember",
  async (member, thunkAPI) => {
    let serializedData = getSerializedData(
      member,
      "wait-list-members",
      getOpts(member)
    );
    return await getResponse(
      gsApi.patch,
      `${waitListMembers}/${member.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const updateTeamRoundsAndPlayers = createAsyncThunk(
  "teams/updateTeamRoundsAndPlayers",
  async (team, thunkAPI) => {
    let serializedData = getSerializedData(
      team,
      "tournament-teams",
      getOpts(team, [...getTournamentRelationship()])
    );

    const serializedPlayers = team.players.map(
      (p) => getSerializedData({ ...p, id: null }, "players", getOpts(p))?.data
    );

    serializedData.data.relationships["players"] = {
      data: serializedPlayers,
    };

    return await getResponse(
      gsApi.patch,
      `${tournamentTeams}/team-rounds-and-players`,
      serializedData,
      thunkAPI
    );
  }
);

export const updateTeamRound = createAsyncThunk(
  "teams/updateTeamRound",
  async (teamRound, thunkAPI) => {
    let serializedData = getSerializedData(
      teamRound,
      "team-rounds",
      getOpts(teamRound)
    );

    return await getResponse(
      gsApi.patch,
      `${teamRounds}/${teamRound.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTeamTeeChoice = createAsyncThunk(
  "teams/saveTeamTeeChoice",
  async (teeChoice, thunkAPI) => {
    let serializedData = getSerializedData(
      teeChoice,
      "round-tee-choices",
      getOpts(teeChoice, [...getIDRelationship("roundTee")])
    );

    return await getResponse(
      gsApi.patch,
      `${roundTeeChoices}/${teeChoice.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTeamPlayerTeeChoice = createAsyncThunk(
  "teams/saveTeamPlayerTeeChoice",
  async (teeChoice, thunkAPI) => {
    let serializedData = getSerializedData(
      teeChoice,
      "round-tee-player-choices",
      getOpts(teeChoice, [...getIDRelationship("roundTee")])
    );

    return await getResponse(
      gsApi.patch,
      `${roundTeePlayerChoices}/${teeChoice.id}`,
      serializedData,
      thunkAPI
    );
  }
);
//remove team

export const removeTournamentTeam = createAsyncThunk(
  "teams/removeTournamentTeam",
  async (id, thunkAPI) => {
    const response = await getResponse(
      gsApi.delete,
      `${tournamentTeams}/${id}`,
      { data: {} },
      thunkAPI
    );

    return response;
  }
);

//remove player

export const removeTournamentPlayer = createAsyncThunk(
  "teams/removeTournamentPlayer",
  async (id, thunkAPI) => {
    const response = await gsApi.delete(`${players}/${id}`, {
      data: {},
    });
    return response;
  }
);

//validate
export const validateTeamImport = createAsyncThunk(
  "teams/validateTeamImport",
  async (verify, thunkAPI) => {
    const response = await getResponse(
      gsApi.post,
      `${tournament}/${verify?.id}/validate-teams-import`,
      verify,
      thunkAPI
    );
    return response;
  }
);

//import
export const importTeamList = createAsyncThunk(
  "teams/importTeamList",
  async (verify, thunkAPI) => {
    const response = await getResponse(
      gsApi.post,
      `${tournament}/${verify?.id}/import-teams`,
      verify,
      thunkAPI
    );
    return response;
  }
);

export const teamSlice = createSlice({
  name: "teams",
  initialState: {
    tournamentTeams: [],
    tournamentWaitList: [],
    tournamentUnassignedPlayers: [],
    currentTeam: {},
    currentTeamPlayer: {},
    currentTeamScorecards: [],
    currentTeamTeeChoices: [],
    currentPlayerTeeChoices: [],
    currentTeamRounds: [],
    currentTeamregistrationOrder: {},
    validatedTeam: {},
    notifications: [],
    loading: [],
  },
  reducers: {
    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
      const teamIndex = state?.tournamentTeams?.findIndex?.(
        (t) => t.id === action.payload.id
      );
      if (teamIndex) {
        state.tournamentTeams[teamIndex] = action.payload;
      }
    },
    setCurrentTempPlayer: (state, action) => {
      state.currentTeamPlayer = state.currentTeam?.players?.find?.(
        (p) => p.tempID === action.payload
      );
    },
    setTournamentTeams: (state, action) => {
      state.tournamentTeams = action.payload;
    },
    setValidatedTeam: (state, action) => {
      state.validatedTeam = action.payload;
    },
    notifySlideOut: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    setCurrentTeamRounds: (state, action) => {
      state.currentTeamRounds = action.payload;
    },
    setCurrentTeamRound: (state, action) => {
      const index = state.currentTeamRounds?.findIndex?.(
        (ctr) => ctr.id === action.payload.id
      );
      if (index !== -1) {
        state.currentTeamRounds[index] = action.payload;
      }
    },
    setTeamTeeChoice: (state, action) => {
      let index = state.currentTeamTeeChoices?.findIndex?.(
        (c) => c.id === action.payload.id
      );
      state.currentTeamTeeChoices[index] = action.payload;
    },
    setPlayer: (state, action) => {
      let index = state.currentTeam?.players?.findIndex(
        (p) => p.id === action.payload.id
      );
      state.currentTeam.players[index] = action.payload;
    },
  },
  extraReducers: {
    //get Teams
    ...getThunkResponse(getTournamentTeams, (state, action) => {
      state.tournamentTeams = action.payload;
    }),
    //get Team
    ...getThunkResponse(getTournamentTeam, (state, action) => {
      state.currentTeam = action.payload;
    }),
    //get Team Scorecards
    ...getThunkResponse(getTournamentTeamScorecards, (state, action) => {
      state.currentTeamScorecards = action.payload;
    }),
    //get Registration Order
    ...getThunkResponse(getTournamentTeamRegistrationOrder, (state, action) => {
      state.currentTeamregistrationOrder = action.payload;
    }),
    //get wait list
    ...getThunkResponse(getTournamentWaitList, (state, action) => {
      state.tournamentWaitList = action.payload;
    }),
    //get unassigned players
    ...getThunkResponse(getTournamentUnassignedPlayers, (state, action) => {
      state.tournamentUnassignedPlayers = action.payload;
    }),
    //get player
    ...getThunkResponse(getTournamentTeamPlayer, (state, action) => {
      state.currentTeamPlayer = action.payload;
    }),
    //Create Team
    ...createThunkResponse(
      createTournamentTeam,
      (state, action) => {
        state.tournamentTeams = [action.payload, ...state.tournamentTeams];
        state.currentTeam = action.payload;
      },
      "Tournament Team"
    ),
    //Save Team
    ...saveThunkResponse(
      saveTournamentTeam,
      (state, action) => {
        const teamIndex = state.tournamentTeams.findIndex(
          (t) => t.id === action.payload.id
        );
        state.tournamentTeams[teamIndex] = action.payload;
        if ((state.currentTeam.id = action.payload.id)) {
          state.currentTeam = action.payload;
        }
      },
      "Tournament Team"
    ),
    //Save player
    ...saveThunkResponse(
      saveTournamentTeamPlayer,
      (state, action) => {
        const teamIndex = state.currentTeam?.players?.findIndex?.(
          (t) => t.id === action.payload.id
        );
        state.currentTeam.players[teamIndex] = action.payload;
      },
      "Player"
    ),
    //update unassigned player
    ...saveThunkResponse(
      updateUnassignedPlayer,
      (state, action) => {
        const unassignedIndex = state.tournamentUnassignedPlayers.findIndex?.(
          (p) => p.id === action.payload.id
        );
        state.tournamentUnassignedPlayers[unassignedIndex] = action.payload;
      },
      "Player"
    ),
    //update wait list member
    ...saveThunkResponse(
      updateWaitListMember,
      (state, action) => {
        const unassignedIndex = state.tournamentWaitList.findIndex?.(
          (p) => p.id === action.payload.id
        );
        state.tournamentWaitList[unassignedIndex] = action.payload;
      },
      "Player"
    ),
    //remove Team
    ...deleteThunkResponse(
      removeTournamentTeam,
      (state, action) => {
        const teams = state.tournamentTeams;
        state.tournamentTeams = teams.filter?.((t) => t.id !== action.meta.arg);
        state.currentTeam = {};
      },
      "Team"
    ),

    ...deleteThunkResponse(
      removeTournamentPlayer,
      (state, action) => {
        state.currentTeam.players = state?.currentTeam?.players?.filter?.(
          (p) => p.id !== action.meta.arg
        );
      },
      "Player"
    ),

    //validate team import
    ...validateThunkResponse(
      validateTeamImport,
      (state, action) => {
        let { data } = action.payload;
        const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";
        const contact = data.player1_name || data.player_name;
        let teamCheck = {
          identifier: data.id,
          contactName: contact,
          players: [],
        };
        if (contact) {
          teamCheck.players.push({
            name: contact,
            avatar: defaultAvatar,
          });
        }
        if (data.player2_name) {
          teamCheck.players.push({
            name: data.player2_name,
            avatar: defaultAvatar,
          });
        }
        if (data.player3_name) {
          teamCheck.players.push({
            name: data.player3_name,
            avatar: defaultAvatar,
          });
        }
        if (data.player4_name) {
          teamCheck.players.push({
            name: data.player4_name,
            avatar: defaultAvatar,
          });
        }
        if (data.player5_name) {
          teamCheck.players.push({
            name: data.player5_name,
            avatar: defaultAvatar,
          });
        }
        if (data.player6_name) {
          teamCheck.players.push({
            name: data.player6_name,
            avatar: defaultAvatar,
          });
        }
        state.validatedTeam = teamCheck;
      },
      "Team List"
    ),
    //import
    ...uploadThunkResponse(importTeamList, (state, action) => {}, "Team List"),
    ...getThunkResponse(getTournamentTeamRounds, (state, action) => {
      state.currentTeamRounds = action.payload;
    }),
    ...saveThunkResponse(
      updateTeamRound,
      (state, action) => {},
      "Team Round",
      shortDelay
    ),
    ...getThunkResponse(getTournamentTeamTeeChoices, (state, action) => {
      state.currentTeamTeeChoices = action.payload;
    }),
    ...getThunkResponse(getTournamentTeamPlayerTeeChoices, (state, action) => {
      state.currentPlayerTeeChoices = action.payload;
    }),
    ...saveThunkResponse(
      saveTeamTeeChoice,
      (state, action) => {},
      "Tee Choice",
      shortDelay
    ),
    ...saveThunkResponse(
      saveTeamPlayerTeeChoice,
      (state, action) => {},
      "Tee Choice",
      shortDelay
    ),
  },
});

export const {
  setCurrentTeam,
  setTournamentTeams,
  setValidatedTeam,
  notifySlideOut,
  clearNotifications,
  setCurrentTempPlayer,
  setCurrentTeamRound,
  setCurrentTeamRounds,
  setTeamTeeChoice,
  setPlayer,
} = teamSlice.actions;

export const selectCurrentTeam = (state) => state.teams.currentTeam;
export const selectCurrentTeamPlayer = (state) => state.teams.currentTeamPlayer;
export const selectCurrentTeamScorecards = (state) =>
  state.teams.currentTeamScorecards;
export const selectCurrentTeamRegistrationOrder = (state) =>
  state.teams.currentTeamregistrationOrder;
export const selectTournamentTeams = (state) => state.teams.tournamentTeams;
export const selectQualifiedTeams = (state) => state.teams.tournamentTeams.filter((t) => !t.disqualified);
export const selectTournamentWaitList = (state) =>
  state.teams.tournamentWaitList;
export const selectTournamentUnassignedPlayers = (state) =>
  state.teams.tournamentUnassignedPlayers;
export const selectTeamsNotifications = (state) => state.teams.notifications;
export const selectLoading = (state) => state.teams.loading;
export const selectValidatedTeam = (state) => state.teams.validatedTeam;
export const selectTournamentTeamRounds = (state) =>
  state.teams.currentTeamRounds;
export const selectTournamentTeamTeeChoices = (state) =>
  state.teams.currentTeamTeeChoices;
export const selectTournamentTeamPlayerTeeChoices = (state) =>
  state.teams.currentPlayerTeeChoices;
export default teamSlice.reducer;

function getTournamentRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournament",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getTournamentTeamRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournamentTeam",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
