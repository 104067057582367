import React from "react";
import {
  GSSidePanelPage,
  GSItemList,
  GSEmptyList,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-payouts.scss";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  getPayouts,
  selectNotifications,
  selectOrderSummary,
  selectPayouts,
} from "../../../reducers/reportingSlice";
import TournamentReportingPayoutItem from "./tournament-reporting-payout-item";
import SearchBar from "../../search-bar";
import TournamentReportingOrderSummary from "./tournament-reporting-order-summary";
import { useState } from "react";
import { getDateTimeOn } from "../../../helpers/Dates";

import { GSActionBar } from "golfstatus_react_components";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

//Name the component
const TournamentReportingPayouts = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/reporting/payouts");

  const payouts = useSelector(selectPayouts);
  const tournamentEventOrderSummary = useSelector(selectOrderSummary);
  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getPayouts(match.params.tournamentID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [match?.params?.tournamentID, dispatch]);
  const notifications = useSelector(selectNotifications);

  const save = () => {
    dispatch(clearNotifications());
    navigate("request");
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Payouts`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const filteredPayouts = () => {
    return payouts.filter?.((p) =>
      `${p.payoutType} ${p.status} ${getDateTimeOn(p.requestedOn)}`.includes(
        search
      )
    );
  };

  const getPageActions = () => {
    if (currentTournament.state !== "final" || !currentTournament?.organization?.stripeId || tournamentEventOrderSummary?.availableFunds === 0) {
      return [];
    }
    return [
      {
        title: "Request Payout",
        type: "no-wrap black",
        actionIcon: faHandHoldingUsd,
        actionClick: save,
      },
    ];
  };

  const getEmptyUnavailableMessage = () => {
    return (
      <GSEmptyList
        title="Request a Payout"
        detail="Funds can be requested after the tournament has been finalized."
        actions={[
          {
            title: "View Tournament Overview",
            onClick: () => {
              dispatch(clearNotifications);
              navigate(`/${match.params.tournamentID}`);
            },
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="No funds have been paid out"
        detail="A list of transactions and their status will be listed here once a payout is requested."
        actions={currentTournament.state !== "final" || !currentTournament?.organization?.stripeId || tournamentEventOrderSummary?.availableFunds === 0 ? [] :[
          {
            title: "Request Payout",
            onClick: save,
            buttonIcon: faHandHoldingUsd,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the payout you’re looking for…"
        detail="Try changing the search term or contact GolfStatus support."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {setSearch("")},
            type: "light-grey no-wrap",
          },
        ]}
      />
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Payouts" pageActions={getPageActions()} />
        <TournamentReportingOrderSummary
          hideNetRevenue
          hideRefunds
          {...tournamentEventOrderSummary}
        />
        <SearchBar
          placeholder="Search Payouts..."
          search={search}
          setSearch={setSearch}
        />
        <GSItemList
          emptyMessage={
            currentTournament.state !== "final"
              ? getEmptyUnavailableMessage()
              : search === ""
              ? getEmptyListMessage()
              : getEmptySearchMessage()
          }
          type="vertical"
          items={filteredPayouts()}
          listItem={(item) => <TournamentReportingPayoutItem {...item} />}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-payouts>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-reporting-payouts>
  );
};

//export the new namet
export default TournamentReportingPayouts;
