import "./tournament-sponsor-remove.scss";
import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentSponsor,
  removeTournamentSponsor,
  selectCurrentSponsor,
  selectSponsorNotifications,
} from "../../../reducers/sponsorSlice";
import { addNotification } from "../../../reducers/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotificationAction, useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";
import { removeItemForm } from "../../../forms/DeleteForm";
import TournamentSponsorLineItem from "./tournament-sponsor-line-item";

//Name the component
const TournamentSponsorRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/sponsors/:sponsorID/remove");

  const currentSponsor = useSelector(selectCurrentSponsor);
  const notifications = useSelector(selectSponsorNotifications);

  useEffect(() => {
    dispatch(getTournamentSponsor(match.params.sponsorID));
  }, [dispatch, match?.params?.sponsorID]);

  useNotificationAction("removed", notifications, (notification) => {
    dispatch(addNotification(notification));
    dispatch(clearNotifications());
  }, "Tournament Sponsor");

  useNotificationNavigation("removed", notifications, "../", "Tournament Sponsor")

  const removeSponsor = () => {
    setIsValid(false);
    dispatch(removeTournamentSponsor(match.params.sponsorID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removeSponsor,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Sponsor`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitle = () => {
    return (
      <div className="delete-header">
        <div className="icon">
          <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
        </div>{" "}
        Delete Sponsor
      </div>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getFormTitle()}
        formSections={removeItemForm(currentSponsor.name, <TournamentSponsorLineItem {...currentSponsor} />)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-sponsor-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-sponsor-remove>
  );
};

//export the new namet
export default TournamentSponsorRemove;
