import React from "react";
import "./tournament-line-item.scss";
import { GSInfoGroup, GSImage, GSButton } from "golfstatus_react_components";
import { getDate } from "../helpers/Dates";
import { faClone } from "@fortawesome/free-solid-svg-icons";

const TournamentLineItem = (props) => {
  const { tournament, copyTournament } = props;
  const teams = (tournament?.numberRegisteredTeams ?? 0)
  const teamOrPlayers = tournament?.numberOfPlayers === 1 ? "player" : "team"
  const getStartDate = () => {
    if (tournament?.startAt !== null) {
      return tournament?.numberOfRounds > 1
        ? `${getDate(tournament?.startAt)} - ${getDate(tournament?.endAt)}`
        : getDate(tournament?.startAt);
    }
    return "No date selected";
  };
  const getStateColor = (state) => {
    switch (state) {
      case "final":
        return "black";
      case "active":
        return "green";
      case "draft":
        return "light-grey";
      default:
        return "";
    }
  };
  const image = {
    fit: "hide-mobile",
    sections: [
      {
        sectionItems: [
          {
            type: "primary full-width",
            value: <GSImage size="medium wide" src={tournament?.logo} />,
          },
        ],
      },
    ],
  };
  const tournamentDetails = {
    fit: "stretch",
    sections: [
      {
        sectionItems: [
          { type: "primary", value: tournament?.name },
          {
            type: "secondary no-wrap",
            value: tournament?.organization?.name,
          },
        ],
      },
      {
        sectionItems: [
          {
            type: "secondary no-wrap",
            value: getStartDate(),
          },
          {
            type: "secondary",
            value: tournament?.facilities?.[0]?.name ?? "No course selected",
          },
        ],
      },
      {
        sectionItems: [
          {
            type: "primary full-width",
            value: (
              <GSButton
                type={`secondary status pill ${getStateColor(
                  tournament?.state
                )}`}
                title={
                  tournament.state !== "final" ? tournament.state : "complete"
                }
              />
            ),
          },
        ],
      }
    ],
  };
  const roundInfo = {
    fit: "hide-mobile",
    sections: [
      {
        sectionItems: [
          {
            type: "tertiary no-wrap full-width",
            value: `${tournament?.numberOfRounds} round${
              tournament?.numberOfRounds !== 1 ? "s" : ""
            }`,
          },
          {
            type: "tertiary no-wrap full-width",
            value: `${teams} ${teamOrPlayers}${
              teams !== 1 ? "s" : ""
            }`,
          },
        ],
      },
    ],
  };
  const copyButton = {
    sections: [
      {
        sectionItems: [
          {
            type: "primary full-width",
            value: (
              <GSButton
              type="light-grey "
                buttonIcon={faClone}
                onClick={(e) => {
                  copyTournament?.(tournament);
                  e?.stopPropagation?.();
                }}
              />
            ),
          },
        ],
      },
    ],
  };
  const getTournamentDataGroup = (tournament) => {
    let item = [
      { ...image },
      { ...tournamentDetails },
      { ...roundInfo },
    ];
    if (copyTournament) {
      item.push({ ...copyButton });
    }
    return item;
  };
  return (
    <tournament-line-item>
      <GSInfoGroup dataGroups={getTournamentDataGroup(tournament)} />
    </tournament-line-item>
  );
};

export default TournamentLineItem;
