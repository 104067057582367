import {
  GSFileSelect,
  GSButton,
  GSItemList,
  GSSelect,
  GSRadioGroup,
  GSQuickFilter,
  GSToggle,
} from "golfstatus_react_components";
import {
  faArrowCircleUp,
  faPlus,
  faQrcode,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import TournamentTeamCard from "../components/tournament/tournament-teams/tournament-team-card";
import TournamentTeamPlayerItem from "../components/tournament/tournament-teams/tournament-team-player-item";
import TournamentTeamItem from "../components/tournament/tournament-teams/tournament-team-item";
import OrganizationAccessListItem from "../components/tournament/tournament-promotion/tournament-packages/organization-access-list-item";
import { moneyWithCommas } from "../helpers/RegexHelper";
import { generateCode } from "../helpers/Utilities";
import { getRoundName } from "../helpers/Converters";

export const getNewTeamFormSections = (team, context) => {
  if (context) {
    let players = {
      type: "vertical large-gap",
      title: "Players",
      sectionActions: !context.teamFull?.()
        ? [
            {
              title: "Add Player",
              type: "black no-wrap mobile-icon",
              actionIcon: faPlus,
              actionClick: context?.addPlayer,
            },
          ]
        : [],
      fields: [
        {
          label: "Players",
          value: (
            <GSItemList
              type="vertical selectable large-gap grey-empty-list rounded-empty-list"
              items={context.getTeamPlayers()}
              emptyMessage={context?.getEmptyPlayersListMessage(
                `Add a new player ${
                  context.hasUnassignedPlayers()
                    ? "or select from the unassigned players list below"
                    : ""
                }`
              )}
              itemSelected={context?.editPlayer}
              isSortable={true}
              dragOffset={0}
              itemMoved={context?.reOrderTeam}
              listItem={(item) => <TournamentTeamPlayerItem {...item} />}
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "name"),
          isEditable: true,
          required: true,
        },
      ],
    };

    if (!context.teamFull?.()) {
      if (
        context.hasUnassignedPlayers() &&
        context.getUnassignedPlayers?.()?.length > 0
      ) {
        players.fields.push({
          label: "Unassigned Players List",
          value: (
            <GSItemList
              type="horizontal selectable x-large-pad large-gap"
              items={context.getUnassignedPlayers()}
              itemSelected={context?.addUnassigned}
              listItem={(item) => <TournamentTeamCard {...item} />}
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "name"),
          isEditable: true,
        });
      }
    }

    let teamInformation = {
      type: "vertical large-gap",
      title: "Team Information",
      fields: [
        {
          label: "Team Name",
          placeholder: "Team Name",
          value: team?.name,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "name"),
          isEditable: true,
        },
        {
          label: "Team Handicap",
          placeholder: "Team Handicap",
          value: team?.handicap,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "handicap"),
          isEditable: true,
        },
        
        {
          label: "Live Scoring Code",
          placeholder: "Automatically generated after team creation",
          value: team?.entryCode,
          leftIcon: faQrcode,
          leftIconClick: () => {
            context?.updateTeam?.(generateCode(), "entryCode");
          },
          rightIconClick: () => {
            context?.updateTeam?.("", "entryCode");
          },
          rightIcon: faTimesCircle,
          failedValidation: context?.validationFailed,
          validation: {
            isValid: (value) => value.length >= 6,
            invalidLabel: "Code must be at least 6 characters long",
          },
          onChange: (e) =>
            context?.updateTeam?.(e.target.value.toUpperCase?.(), "entryCode"),
          isEditable: true,
          hintText:
            "If the code is updated, the team will need to be manually notified.",
        },
      ],
    };

    let contactInformation = {
      type: "vertical large-gap",
      title: "Contact Information",
      fields: [
        {
          label: "Full Name",
          placeholder: "Full Name",
          value: team?.contactName,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "contactName"),
          isEditable: true,
          required: true,
        },
        {
          label: "Email Address",
          placeholder: "Email Address",
          value: team?.email,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "email"),
          isEditable: true,
        },
        {
          label: "Phone Number",
          placeholder: "Phone Number",
          value: team?.phone,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updateTeam?.(e.target.value, "phone"),
          isEditable: true,
        },
      ],
    };

    let form = [{ ...players }, { ...teamInformation }, contactInformation];

    return form;
  }
};

export const getAddPlayerForm = (player, context) => {
  if (context) {
    let playerInfo = {
      type: "vertical large-gap",
      fields: [
        {
          label: "First Name",
          placeholder: "First Name",
          value: player?.firstName,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updatePlayer?.(e.target.value, "firstName"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
          required: true,
        },
        {
          label: "Last Name",
          placeholder: "Last Name",
          value: player?.lastName,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updatePlayer?.(e.target.value, "lastName"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
          required: true,
        },
        {
          label: "Email Address",
          placeholder: "Email Address",
          value: player?.email,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updatePlayer?.(e.target.value, "email"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
        },
        {
          label: "Phone Number",
          placeholder: "Phone Number",
          value: player?.phone,
          failedValidation: context?.validationFailed,
          onChange: (e) => context?.updatePlayer?.(e.target.value, "phone"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
        },
      ],
    };

    let handicapInfo = {
      type: "vertical large-gap",
      title: "Additional Information",
      fields: [
        {
          label: "Handicap",
          placeholder: "Handicap",
          value: player?.handicap,
          failedValidation: context?.validationFailed,

          onChange: (e) => context?.updatePlayer?.(e.target.value, "handicap"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
        },
        {
          label: "GHIN Number",
          placeholder: "GHIN Number",
          value: player?.ghinNumber,
          failedValidation: context?.validationFailed,
          hintText: "Enter GHIN number without dashes.",
          onChange: (e) =>
            context?.updatePlayer?.(e.target.value, "ghinNumber"),
          onSubmit: () => {
            context?.submit();
          },
          isEditable: true,
        },
        {
          label: "Player Notes",
          placeholder: "Player Notes",
          value: player?.signupMessage,
          failedValidation: context?.validationFailed,
          type: "text-area",
          rows: 5,
          onChange: (e) =>
            context?.updatePlayer?.(e.target.value, "signupMessage"),
          isEditable: true,
        },
      ],
    };

    let form = [{ ...playerInfo }, { ...handicapInfo }];

    return form;
  }
};

export const getImportForm = (validatedTeam, context) => {
  if (context) {
    let formSections = [];

    let importInfo = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Teams",
          value: (
            <GSFileSelect
              description="Tap or drag file to upload. Accepted Files: .xlsx, .xls, .csv"
              setSelectedFiles={context?.setFile}
              removeSourceItem={context?.removeFile}
              accept=".xls, .xlsx, .csv"
              title={
                <GSButton
                  buttonIcon={faArrowCircleUp}
                  title="Upload"
                ></GSButton>
              }
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          isEditable: true,
          required: true,
        },
      ],
    };

    let preview = {
      type: "vertical xx-large-gap",
      title: "File Preview",
      fields: [
        {
          label: "Team Preview",
          value: <TournamentTeamItem {...validatedTeam}></TournamentTeamItem>,
          isEditable: false,
          customView: true,
        },
      ],
    };

    formSections = [{ ...importInfo }];

    if (validatedTeam.identifier) {
      formSections.push({ ...preview });
    }

    return formSections;
  }
};

export const getRegistrationForm = (order, context) => {
  if (context) {
    let cardInfo = {
      type: "vertical xx-large-gap",
      title: "Card Information",
      fields: [
        {
          label: "Card",
          value: order.cardBrand,
        },
        {
          label: "Name On Card",
          value: order.nameOnCard ?? "No Name",
        },
        {
          label: "Card Number",
          value: `**** **** **** ${order.last4}`,
        },
      ],
    };

    let costInfo = {
      type: "vertical xx-large-gap",
      title: "Cost Information",
      fields: [
        {
          label: "Cost",
          value: moneyWithCommas(order.totalCost),
        },
        {
          label: "Discounts",
          value: moneyWithCommas(order.discountAmount),
        },
        {
          label: "Total Platform Fee",
          value: moneyWithCommas(order.totalPlatformFee),
        },
        {
          label: "Total Cost With Fees",
          value: moneyWithCommas(order.totalCostWithFees),
        },

        {
          label: "Total",
          value: moneyWithCommas(order.purchaseCost),
        },
      ],
    };

    return [{ ...costInfo }, { ...cardInfo }];
  }
};

const getTeeOptions = () => {
  return [
    { label: "Default Tees", value: "default_tees" },
    { label: "Back Tees", value: "back_tees" },
    { label: "Forward Tees", value: "forward_tees" },
    { label: "Women's Tees", value: "womens_tees" },
  ];
};

const getSelectedTee = (tee) => {
  return getTeeOptions()?.find((t) => t.value === tee);
};

//remove team
export const removeTeamForm = (context, team) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        extras: (
          <div className="delete-team">
            <div className="disclaimer">{`Are you sure you want to remove ${team.name ??
              team.contactName} from your tournament? This cannot be undone.`}</div>
            <TournamentTeamItem {...team} verticalPlayers={true} />
          </div>
        ),
      },
    ];
    return form;
  }
};

export const disqualifyTeamForm = (context, team) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            value: (
              <div className="disqualify-team">
                <div className="disclaimer">{`Are you sure you want to disqualify ${team.name ??
                  team.contactName} from your tournament?`}</div>
                <TournamentTeamItem {...team} verticalPlayers={true} />
              </div>
            ),
            customView: true,
          },
          {
            type: "vertical xx-large-gap",
            label: "Disqualification Reason",
            value: (
              <GSRadioGroup
                options={getDisqualifyOptions()}
                selectedOption={getSelectedDisqualifyOption(
                  team.disqualificationReason
                )}
                selectionChanged={context.selectDisqualificationReason}
              ></GSRadioGroup>
            ),
            customView: true,
          },
        ],
      },
    ];
    return form;
  }
};

const getDisqualifyOptions = () => {
  return [
    { label: "Disqualified", value: "disqualified" },
    { label: "Withdrawn", value: "withdrawn" },
    { label: "No Show", value: "no_show" },
    { label: "Cut", value: "cut" },
  ];
};

const getSelectedDisqualifyOption = (disqualificationReason) => {
  if (disqualificationReason === "none") {
    return getDisqualifyOptions()?.[0];
  }
  return getDisqualifyOptions()?.find?.(
    (r) => r.value === disqualificationReason
  );
};

export const getHandicapUpdateForm = (context, updateType) => {
  if (context) {
    let indexTypesSection = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Handicap Source",
          description:
            "Syncing player handicaps from the source below will update and save all eligible handicaps.",
          value: (
            <GSRadioGroup
              options={[
                { label: "GHIN Indexes", value: 1 },
                { label: "Access List", value: 2 },
              ]}
              selectedOption={updateType}
              selectionChanged={context?.updateType}
            />
          ),
          customView: true,
        },
      ],
    };
    let accessGroupSection = {
      title: "Access Lists",
      type: "xx-large-gap vertical",
      fields: [
        {
          label: "Access Lists",
          value: (
            <GSQuickFilter
              getDefaultSearch={context?.getAccessListSearch}
              filteredList={context?.getFilteredAccessLists()}
              getItem={(item) => <OrganizationAccessListItem {...item} />}
              itemSelected={context?.selectAccessGroup}
              selectedItem={context?.getSelectedAccessGroup?.()}
              itemRemoved={context?.removeAccessGroup}
              emptySearchList={context?.getEmptyAccessGroupMessage()}
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          validation: {
            isValid: () => context?.getSelectedAccessGroup(),
            invalidLabel: "Access group is required",
          },
        },
      ],
    };
    let form = [indexTypesSection];
    if (updateType.value === 2) {
      form.push(accessGroupSection);
    }
    return form;
  }
};

export const getTeamRoundSettingsForm = (context) => {
  if (context) {
    let form = context?.getTeamRounds?.()?.map?.((round) => ({
      title: `Round ${getRoundName(round?.tournamentRound)}`,
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Handicap",
          value: round?.handicap,
          type: "number",
          placeholder: "Handicap",
          onChange: (e) => {
            context.updateRound(round, e.target.value, "handicap");
          },
          isEditable: true,
        },
      ],
    }));
    return form;
  }
};

const getRoundSections = (context) => {
  let rounds = context?.getTeeChoices?.()?.map?.((choice) => ({
    label: `Round ${getRoundName(choice?.roundCourse?.tournamentRound)} Tee`,
    value: (
      <GSSelect
        selectedOption={{
          label: choice?.roundTee?.name,
          subLabel: `${choice?.roundTee?.slope}/${choice?.roundTee?.rating}`,
          value: choice?.roundTee,
        }}
        options={choice?.roundCourse?.roundTees?.map?.((t) => ({
          label: t.name,
          subLabel: `${t.slope}/${t.rating}`,
          value: t,
        }))}
        onChange={(option) => {
          context?.setTee?.(option, choice);
        }}
      />
    ),
    customView: true,
    type: "number",
    isEditable: true,
  }));


  return rounds;
};

const getPlayerRoundTeeSections = (player, context) => {
  if (player.overrideTeePreference && player?.teeChoices) {
    return player?.teeChoices?.map?.((choice) => ({
      label: `Round ${getRoundName(choice?.roundCourse?.tournamentRound)} Tee`,
      value: (
        <GSSelect
          selectedOption={{
            label: choice?.roundTee?.name,
            subLabel: `${choice?.roundTee?.slope}/${choice?.roundTee?.rating}`,
            value: choice?.roundTee,
          }}
          options={choice?.roundCourse?.roundTees?.map?.((t) => ({
            label: t.name,
            subLabel: `${t.slope}/${t.rating}`,
            value: t,
          }))}
          onChange={(option) => {
            context?.setPlayerTee?.(option, choice, player);
          }}
        />
      ),
      isEditable: true,
      customView: true,
    }));
  }
  return [{
    label: "Tee",
    value: (
      <GSSelect
        selectedOption={getSelectedTee(player.teePreference)}
        placeholder="Select a Tee..."
        onChange={(option) => {
          context?.updatePlayer?.(option.value, "teePreference", player);
        }}
        options={getTeeOptions()}
      />
    ),
    customView: true,
    failedValidation: context?.validationFailed,
    isEditable: true,
  }];
};

const getPlayerRoundSections = (context) => {
  let playerSection = context?.getPlayers?.()?.map?.((player) => ({
    title: `${player.name}`,
    type: "vertical xx-large-gap",
    fields: player?.teeChoices?.length > 0 ? [
      {
        label: "Round Tees",
          description:
            "Select specific tees for round formats scored by player (e.g. best ball).",
        value: (
          <GSToggle
          falseDescription = "No"
            trueDescription = "Yes"
            value={player.overrideTeePreference}
            onClick={() => {
              context.toggleOverridePlayerTeePreference(player);
            }}
          />
        ),
        customView: true,
        type: "number",
        isEditable: true,
      },
      ...getPlayerRoundTeeSections(player, context),
    ] : getPlayerRoundTeeSections(player, context),
  }));

  return playerSection;
};

export const getTeamTeeChoicesForm = (context) => {
  if (context) {
    let form = [];

    let teeSection = {
      type: "vertical xx-large-gap",
      title: "Team Tees",
      fields: context?.getTeeChoices?.().length > 0 ? [
        {
          label: "Round Tees",
          description:
            "Select specific tees for round formats scored by team (e.g. scramble).",
          value: (
            <GSToggle
            falseDescription = "No"
            trueDescription = "Yes"
              value={context?.getTeam?.()?.overrideTeePreference}
              onClick={context?.toggleOverrideTeePreference}
            />
          ),
          isEditable: true,
          customView: true,
        },
      ] : [],
    }

    if (context?.getTeam?.()?.overrideTeePreference) {
      teeSection.fields.push(...getRoundSections(context));
    }
    else{
      teeSection.fields.push({
        label: "Tee",
        value: (
          <GSSelect
            selectedOption={getSelectedTee(context?.getTeam?.()?.teePreference)}
            placeholder="Select a Tee..."
            onChange={(option) => {
              context?.updateTeam?.(option.value, "teePreference");
            }}
            options={getTeeOptions()}
          />
        ),
        customView: true,
        failedValidation: context?.validationFailed,
        onChange: (e) =>
          context?.updateTeam?.(e.target.value, "teePreference"),
        isEditable: true,
      },)
    }
    

    form.push(teeSection);

    if (context?.getPlayers?.()?.length > 0) {
      form.push(...getPlayerRoundSections(context));
    }

    return form;
  }
};
