import React, { useEffect, useState } from "react";
import "./golfstatus-tms.scss";
import { connect } from "react-redux";
import {
  GSAppLayout,
  GSAppNavigationHeader,
  GSListPage,
  GSInput,
  GSCircleImage,
  GSPageBanner,
} from "golfstatus_react_components";
import logo from "../images/GSLogo.svg";
import { setNav, selectUser, clearNotifications, setHistory, addPathToHistory, removeNotification } from "../reducers/appSlice";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";

import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { getCurrentUser } from "../app/gs-session";
import TMSDashboard from "./dashboard/tms-dashboard";
import TmsDashboard from "./dashboard/tms-dashboard";
import TmsGettingStarted from "./dashboard/tms-getting-started";
import TournamentDetail from "./tournament/tournament-details";
import TournamentOverview from "./tournament/tournament-overview/tournament-overview";

import { acceptOrganizationinvite, getOrganizationList } from "../reducers/organizationSlice";
import { getManagerInvitations } from "../reducers/appSlice";
import { useNotificationBanner } from "../hooks/notificationHooks";
import { getTournamentRoutes } from "../routes/tournaments";
import { getTournamentRoundRoutes } from "../routes/tournament/rounds";
import { getTournamentTeamsRoutes } from "../routes/tournament/teams";
import { getSettingsRoutes } from "../routes/tournament/settings";
import { getTournamentSponsorsRoutes } from "../routes/tournament/sponsors";
import { getTournamentPromotionRoutes } from "../routes/tournament/promotion";
import { getTournamentFlightingRoutes } from "../routes/tournament/flighting";
import { getTournamentLeaderboardRoutes } from "../routes/tournament/leaderboards";
import { getTournamentPrintoutRoutes } from "../routes/tournament/printouts";
import { getReportingRoutes } from "../routes/tournament/reporting";

const GolfstatusTMS = (props) => {
  const { nav, appNotifications, dispatch, currentTournament } = props;
  const routeNavigate = useNavigate();
  const { pathname } = useLocation();
  const [hideEnvironment, setHideEnvironment] = useState(false);

  useEffect(() => {
    const user = getCurrentUser();
    const nav = [
      {
        id: 1,
        label: "Tournaments Overview",
        url: "/",
        isSelectable: true,
      },
      {
        id: 2,
        label: "My Tournaments",
        url: "/tournaments",
        isSelectable: true,
      },
      {
        id: 3,
        label: "Revenue Enhancements",
        url: `https://events${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/event/revenue-enhancements/register`,
        isExternal: true,
        isSelectable: true,
      },
    ];
    dispatch(selectUser(user?.uid));
    dispatch(getManagerInvitations(user?.uid))
    if (!pathname.includes(currentTournament?.id)) {
      dispatch(setNav(nav));
      dispatch(setHistory([]))
    }
    else {
      dispatch(addPathToHistory(pathname))
    }
  }, [dispatch, pathname, currentTournament?.id]);

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.uid) {
      dispatch(getOrganizationList({ userID: user?.uid }));
      dispatch(getManagerInvitations(user.uid));
    }
  }, [dispatch]);

  const getListPage = (title) => {
    if (title === "Dashboard") {
      return <TMSDashboard />;
    }
    const page = { title: title, getDefaultSearch: getDefaultSearch };
    return <GSListPage {...page} />;
  };

  const getActiveRoute = () => {
    return `${pathname}`;
  };

  const getTitle = () => {
    return (
      <GSAppNavigationHeader
        logo={logo}
        title="Tournament Management"
      ></GSAppNavigationHeader>
    );
  };

  const getFooter = () => {
    const env =
      process.env.REACT_APP_ENV === "staging" ||
        process.env.REACT_APP_ENV === "development"
        ? "dev"
        : "com";
    const user = getCurrentUser();
    const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";
    return [
      // {
      //   id: 5,
      //   label: "Documentation",
      //   url: "documentation",
      //   isSelectable: true,
      // },
      {
        id: 0,
        label: (
          <GSCircleImage
            url={user?.avatar ? user.avatar : defaultAvatar}
            size="large"
          ></GSCircleImage>
        ),
        isSelectable: true,
        url: `https://profile.golfstatus.${env}/account-settings`,
        isExternal: true,
      },
    ];
  };

  const navigate = (item) => {
    if (item.isExternal) {
      window.open(item?.url);
    } else {
      if (item?.url) {
        routeNavigate(item?.url);
      }
    }
  };

  const getEnvironmentActions = () => {
    return [
      {
        actionIcon: faTimesCircle,
        type: "navigation",
        size: "secondary",
        actionClick: hideNavEnvironment,
      },
    ];
  };

  const getShowEnvironment = () => {
    if (hideEnvironment) {
      return false;
    }
    return (
      process.env.REACT_APP_ENV !== "production" &&
      process.env.REACT_APP_ENV !== "demo"
    );
  };

  const hideNavEnvironment = () => {
    setHideEnvironment(true);
  };

  const getNav = () => {
    return {
      navItems: nav,
      title: getTitle(),
      footerItems: getFooter(),
      itemSelected: navigate,
      activeRoute: getActiveRoute(),
      showEnv: getShowEnvironment(),
      environmentActions: getEnvironmentActions(),
      environment: `${process.env.REACT_APP_ENV} environment`,
      environmentDescription: process.env.REACT_APP_GOLFSTATUS_BASE_API_URL,
    };
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getNavigationActions = () => {
    return [
      {
        title: "Clear",
        size: "secondary",
        onClick: timeoutAction,
      },
    ];
  };

  const getDefaultSearch = () => {
    return <GSInput leftIcon={faSearch} rightIcon={faTimesCircle}></GSInput>;
  };

  const getRouter = () => {
    return (
      <Routes>
        <Route path={"/"} element={<TmsDashboard />} />
        {getTournamentRoutes()}
        <Route path={"/documentation"} element={getListPage("Documentation")} />
        <Route path={"/copy/:id"} element={<TmsGettingStarted />} />
        <Route path={"/:id"} element={<TournamentDetail />}>
          <Route index element={<TournamentOverview />} />
          {getTournamentRoundRoutes()}
          {getTournamentTeamsRoutes()}
          {getSettingsRoutes()}
          {getTournamentSponsorsRoutes()}
          {getTournamentPromotionRoutes()}
          {getTournamentFlightingRoutes()}
          {getTournamentLeaderboardRoutes()}
          {getTournamentPrintoutRoutes()}
          {getReportingRoutes()}
        </Route>
      </Routes>
    );
  };

  const acceptManagerInvitation = (notification) => {
    dispatch(acceptOrganizationinvite(notification.invitation))
    dispatch(removeNotification(notification));
  };

  const clearInvitation = (notification) => {
    dispatch(removeNotification(notification));
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications: appNotifications?.map?.((notification) => ({
      ...notification,
      bannerActions: notification?.invitation
        ? [
          {
            title: "Accept",
            actionClick: () => {
              acceptManagerInvitation(notification);
            },
            type: "secondary green",
          },
          {
            actionIcon: faTimesCircle,
            actionClick: () => {
              clearInvitation(notification);
            },
            type: "secondary",
          },
        ]
        : [],
    })),
    navigationActions: getNavigationActions(),
    timeoutAction,
  });

  return (
    <golfstatus-tms>
      <GSAppLayout
        nav={getNav()}
        navigationItemSelected={navigate}
        notification={
          appNotifications?.length > 0 ? (
            <GSPageBanner {...bannerNotifications} />
          ) : (
            false
          )
        }
        routes={getRouter()}
      ></GSAppLayout>
    </golfstatus-tms>
  );
};

const mapStateToProps = (state) => ({
  nav: state.app.nav,
  appNotifications: state.app.notifications,
  currentTournament: state.tournaments.currentTournament,
});

export default connect(mapStateToProps)(GolfstatusTMS);