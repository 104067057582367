import React from "react";
import "./organization-access-list-item.scss";

import {
  GSInfoGroup,
  GSButton,
} from "golfstatus_react_components";

const OrganizationAccessListItem = (props) => {
  const { name, description, accessGroupMemberCount, status } = props;

  const getInfoGroup = () => {
    const group = [
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              { type: "primary", value: name },
              { type: "secondary", value: description },
            ],
          },
          {
            sectionItems: [
              { type: "secondary", value: `${accessGroupMemberCount} codes` },
            ],
          },
        ],
      },
      {
        sections: [
          {
            sectionItems: [
              { type: "primary", value: <GSButton type={`secondary status pill ${status} `} title={status} /> },
            ],
          },
        ],
      },
    ];
    return group;
  };
  return (
    <organization-access-list-item>
      <GSInfoGroup type="list-item x-large-pad" dataGroups={getInfoGroup()} />
    </organization-access-list-item>
  );
};

export default OrganizationAccessListItem;