import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-create-edit.scss";
import { getFlightForm } from "../../../forms/FlightingForm";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  createTournamentFlight,
  getTournamentFlight,
  saveTournamentFlight,
  selectCurrentTournamentFlight,
  selectFlightLoading,
  selectFlightNotifications,
  selectRoundFlights,
  selectTournamentFlights,
  updateCurrentFlight,
} from "../../../reducers/flightSlice";
import { useDispatch } from "react-redux";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import {
  getTournamentLeaderboardSettings,
  selectTournamentLeaderboardSettings,
} from "../../../reducers/leaderboardSlice";
import { useEffect } from "react";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentFlightCreateEdit = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/flighting/:flightID/*");

  const currentTournamentFlight = useSelector(selectCurrentTournamentFlight);
  const tournamentFlights = useSelector(selectTournamentFlights);
  const currentTournament = useSelector(selectCurrentTournament);
  const leaderboardSettings = useSelector(selectTournamentLeaderboardSettings);
  const flightNotifications = useSelector(selectFlightNotifications);
  const roundFlights = useSelector(selectRoundFlights);
  const loading = useSelector(selectFlightLoading)

  const [context, isValid, setIsValid] = useFormValidation(false);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentLeaderboardSettings(match?.params?.tournamentID))
      if (match.params.flightID !== "add") {
        dispatch(getTournamentFlight(match.params.flightID));
      }
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.tournamentID, match?.params?.flightID]);


  useNotificationNavigation(
    "created",
    flightNotifications,
    `/${match.params.tournamentID}/flighting/[id]`
  );

  context.updateFlight = (value, property) => {
    setIsValid(true);
    let update = { ...currentTournamentFlight };
    update[property] = value;
    dispatch(updateCurrentFlight(update));
  };

  context.submitForm = () => {
    if (isValid) {
      save();
    }
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    if (match?.params?.flightID === "add") {
      let update = { ...currentTournamentFlight };
      update.tournament = currentTournament;
      if (tournamentFlights?.length > 0) {
        update.position =
          Math.max(...tournamentFlights?.map?.((f) => f.position)) + 1;
      } else {
        update.position = 1;
      }
      leaderboardSettings.forEach((ls) => {
        if (ls.flighted) {
          update["leaderboard-type-setting"] = ls;
          dispatch(createTournamentFlight(update));
        }
      });
    } else {
      const flights = tournamentFlights?.filter?.(
        (tf) => tf.position === currentTournamentFlight.position
      );
      flights.forEach?.((f) => {
        let update = { ...f };
        update.name = currentTournamentFlight.name;
        update.flightRounds = roundFlights?.filter?.(
          (rf) => rf.tournamentFlight.id === f.id
        );
        dispatch(saveTournamentFlight(update));
      });
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Flight Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle= {match.params.flightID === "add" ? "Add Flight" : "Edit Flight"}
        formSections={getFlightForm(context, currentTournamentFlight)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: flightNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-flight-create-edit>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flight-create-edit>
  );
};

//export the new namet
export default TournamentFlightCreateEdit;
