import { configureStore } from "@reduxjs/toolkit";

import appReducer from "../reducers/appSlice";
import tournamentsReducer from "../reducers/tournamentSlice";
import organizationSlice from "../reducers/organizationSlice";
import userSlice from "../reducers/userSlice";
import utilityReducer from "../reducers/utilitySlice";
import roundsReducer from "../reducers/roundSlice";
import facilityReducer from "../reducers/facilitySlice";

import teamsReducer from '../reducers/teamsSlice';
import sponsorReducer from '../reducers/sponsorSlice';
import settingsReducer from '../reducers/settingsSlice';
import leaderboardReducer from "../reducers/leaderboardSlice";
import flightReducer from "../reducers/flightSlice";
import packageReducer from "../reducers/packageSlice";
import discountReducer from "../reducers/discountSlice";
import accessReducer from "../reducers/accessSlice";
import scorecardReducer from "../reducers/scorecardSlice";
import reportingReducer from "../reducers/reportingSlice";


export const store = configureStore({
  reducer: {
    app: appReducer,
    tournaments: tournamentsReducer,
    orgs: organizationSlice,
    users: userSlice,
    utilities: utilityReducer,
    rounds: roundsReducer,
    facilities: facilityReducer,
    teams: teamsReducer,
    sponsors: sponsorReducer,
    settings: settingsReducer,
    leaderboards: leaderboardReducer,
    flights: flightReducer,
    packages: packageReducer,
    discounts: discountReducer,
    access: accessReducer,
    scorecards: scorecardReducer,
    reporting: reportingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
