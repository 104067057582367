
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft, faCreditCard, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-order.scss"
import { useFormValidation } from "../../../hooks/formHooks";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearNotifications, getOrder, resendReceipt, resendInvoice, selectNotifications, selectOrders } from "../../../reducers/reportingSlice";
import { getReportingForm } from "../../../forms/ReportingForm";
import { useSelector } from "react-redux";
import { getNotificationItemInfo } from "../../../helpers/Converters";

//Name the component
const TournamentReportingOrder = (props) => {

  const [context] = useFormValidation(false)

  const match = useMatch("/:tournamentID/reporting/:orderID")

  const dispatch = useDispatch()

  useEffect(() => {
    if(match?.params?.orderID)
    {
      dispatch(getOrder(match.params.orderID))
    }
    return () => {dispatch(clearNotifications())}
  },[dispatch, match?.params?.orderID])

  const navigate = useNavigate()
  const currentOrder = useSelector(selectOrders)?.find?.(o => o.id === match?.params?.orderID)
  const notifications = useSelector(selectNotifications)

  const refOrVoid = currentOrder?.voidable ? 'Void' : 'Refund';

  const save = () => {
    if (currentOrder?.voidable) {
      navigate("void-order")
    } else {
      navigate("refund")
    }
  }

  const voidInvoice = () => {
    navigate("void-invoice")
  }

  const getDrawerActions = () => {
    if(currentOrder?.registrationOrderRefunds?.length > 0) {
      return [];
    }
    let actions = [];
    if (currentOrder?.status === 'sent') {
      actions = [
        {
          name: "Void Invoice",
          action: voidInvoice,
          type: "light-grey",
        },
      ]
    } else if (currentOrder?.paymentType !== 'customer_invoice') {
      actions = [
        {
          name: `${refOrVoid} Order`,
          action: save,
          type: "light-grey",
        },
      ];
    }

    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Order Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitle = () => {
    if (currentOrder?.status === 'sent' || currentOrder?.status === 'paid') {
      const isInvoice = currentOrder?.paymentType === "customer_invoice";
      return {
        formTitle: "Order Details",
        formActions: [{
          buttonTitle: `Resend ${isInvoice ? "Invoice" : "Receipt"}`,
          type: "no-wrap light-grey",
          actionClick: () => {dispatch(isInvoice ? resendInvoice(currentOrder.id) : resendReceipt(currentOrder.id))},
          actionIcon: faPaperPlane,
        }]
      }
    }
  }

  const getContent = () => {
    return <GSForm {...getFormTitle()} formSections={getReportingForm(context, currentOrder)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications,
    timeoutAction,
    defaultBanner: currentOrder?.voidable
      ? {
        title: getNotificationItemInfo(
          "Voiding this order will remove it from the list of transactions and the card won't be charged.",
          faCreditCard,
          "Pending Credit Card Charge"
        ),
        state: "grey",
      }
      : false
  }

  const [banner] = useNotificationBanner(notificationSettings)

  return (
    //name the component tag
    <tournament-reporting-order>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-order>
  );
};

//export the new namet
export default TournamentReportingOrder;