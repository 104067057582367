import React from "react";
import "./tms-getting-started.scss";

import { connect } from "react-redux";
import { GSForm } from "golfstatus_react_components";
import { getFormSections } from "../../forms/TournamentForms";

const TMSGettingStarted = (props) => {
  
  return (
    <tms-getting-started>
      <div className="logo-hero">
        <img
          alt=""
          src="https://api.golfstatus.dev/images/tournament.jpg"
        ></img>
        <div className="hero-shade">
          <div className="welcome">Welcome to Golfstatus</div>
          <img
            alt=""
            src="https://api.golfstatus.dev/images/tournament.jpg"
          ></img>
        </div>
      </div>
      <GSForm
        formTitle="Start building your Tournament."
        formSections={getFormSections()}
      ></GSForm>
    </tms-getting-started>
  );
};

const mapStateToProps = (state) => ({
  currentEvent: state.tournaments.currentEvent,
});

export default connect(mapStateToProps)(TMSGettingStarted);
