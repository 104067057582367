
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-payments-organization.scss"
import { useFormDataValidation } from "../../../hooks/formHooks";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getTournamentPaymentSettings, selectCurrentPaymentSettings, setPaymentSetting } from "../../../reducers/settingsSlice";
import { paymentSettingsOrganizationForm } from "../../../forms/SettingsForms";
import { useSelector } from "react-redux";
import { clearTournamentNotifications, getTournamentDonationWatchItems, selectCurrentTournament, selectCurrentTournamentDonationWatchItems, selectTournamentNotifications, setCurrentDonationWatchItem } from "../../../reducers/tournamentSlice";

//Name the component
const TournamentSettingsPaymentsOrganization = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch("/:tournamentID/settings/payments/organization")
  const notifications = useSelector(selectTournamentNotifications)

  const currentPaymentSettings = useSelector(selectCurrentPaymentSettings)
  const currentTournament = useSelector(selectCurrentTournament)

  const [context] = useFormDataValidation({initialValid: true, data: currentPaymentSettings, setData: setPaymentSetting })

  context.currentTournamentDonationWatchItems = useSelector(selectCurrentTournamentDonationWatchItems)
  context.currentTournament = currentTournament

  context.addWatchEmail = () => {
    dispatch(setCurrentDonationWatchItem({}))
    navigate("add")
  }

  context.editWatchEmail = (watchItem) => {
    navigate(`${watchItem.id}`)
  }

  useEffect(() => {
    if(match?.params?.tournamentID){
      dispatch(getTournamentPaymentSettings(match.params.tournamentID))
      dispatch(getTournamentDonationWatchItems(match.params.tournamentID))
    }
  }, [dispatch, match?.params?.tournamentID])

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Donations`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Donations" formSections={paymentSettingsOrganizationForm(context, currentPaymentSettings)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-settings-payments-organization>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-settings-payments-organization>
  );
};

//export the new namet
export default TournamentSettingsPaymentsOrganization;