import {
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDocuments } from "../../../forms/TournamentPrintoutForms";
import NavigationListItem from "../../navigation-list-item";
import './tournament-printouts.scss';

const TournamentPrintouts = (props) => {
  const match = useMatch("/:tournamentID/documents/*");
  const navigate = useNavigate()

  const documents = useDocuments(match);
  const [searchParams] = useSearchParams()

  const [search, setSearch] = useState((searchParams?.get("search") ?? ""));

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick= {clearSearch}
        placeholder="Search Documents..."
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></GSInput>
    );
  };

  const clearSearch = () => {
    setSearch("")
  }

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Tournament Documents."
        detail="When the tournament is set up, you will be able to find all the printable documents from your tournament here."
        actions={[
          {
            title: "Tournament Details",
            type: "black",
            onClick: () => {navigate("../settings/details")}
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for..."
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            type: "light-grey",
            onClick: clearSearch
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const itemSelected = (page) => {
    let nestedSearch = filteredDocuments(page.documents)
    if(nestedSearch?.length > 0 )
    {
      navigate(`${page.url}?search=${search}`)
    }
    else{
      navigate(`${page.url}`)
    }
  }

  const getMatchString = (doc) => {
    let matchString  = `${doc.label} ${doc.details}`.toLowerCase()
    if(doc.documents)
    {
      doc.documents.forEach?.(d => {matchString = `${matchString} ${getMatchString(d)}`})
    }
    return matchString
  }

  const searchMatch = (doc) => {
    return getMatchString(doc)?.includes?.(search?.toLowerCase())
  }

  const filteredDocuments = (docs) => {
   return docs.filter?.(doc => searchMatch(doc))
  }

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical selectable"
        items={filteredDocuments(documents)}
        listItem={(item) => (<NavigationListItem page={item}/>)}
        itemSelected={itemSelected}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  return (
    <tournament-printouts>
      <GSListPage
        title="Documents"
        getDefaultSearch={getDefaultSearch}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-printouts>
  );
};

export default TournamentPrintouts;
