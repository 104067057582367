import React from "react";
import "./tms-dashboard-tournaments.scss";
import {
  GSListPage,
  GSItemList,
  GSInfoCard,
  GSEmptyList,
  GSPageLayout,
} from "golfstatus_react_components";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect, useDispatch, useSelector } from "react-redux";

import TournamentInfoGroup from "../tournament-info-group";
import TournamentLineItem from "../tournament-line-item";

import { useNavigate } from "react-router-dom";
import TournamentHero from "../tournament-hero";
import {
  selectEmptyTournament,
  setCurrentTournament,
} from "../../reducers/tournamentSlice";
import { selectLoggedInUser } from "../../reducers/appSlice";
import { selectOrganizationList } from "../../reducers/organizationSlice";
import { useIntercom } from "react-use-intercom";

const TMSDashboardTournaments = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emptyTournament = useSelector(selectEmptyTournament);
  const currentUser = useSelector(selectLoggedInUser);
  const organizations = useSelector(selectOrganizationList);
  const intercom = useIntercom();

  const userHasAccess = () => {
    const tmsOrgs = organizations?.filter?.((org) => org.tms);
    return currentUser.tms || tmsOrgs?.length > 0;
  };

  const contactGolfStatus = () => {
    intercom.show();
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Create & Manage Tournaments"
        detail="GolfStatus simplifies and streamlines golf tournaments. Let's get started!"
        actions={[
          {
            title: "Create Tournament",
            buttonIcon: faPlus,
            onClick: createEvent,
            type: "black",
          },
        ]}
      />
    );
  };

  const getUnAuthorizedListMessage = () => {
    return (
      <GSEmptyList
        title="Your organization does not currently have access to TMS."
        detail="Please contact GolfStatus to get started!"
        actions={[
          {
            title: "Contact Golfstatus",
            onClick: contactGolfStatus,
            type: "black",
          },
        ]}
      />
    );
  };

  const getDraftList = () => {
    return (
      <GSItemList
        items={props.draftEvents}
        listItem={(event) => (
          <GSInfoCard
            size="large"
            selectable={true}
            layout={<TournamentInfoGroup tournament={event} />}
          />
        )}
        itemSelected={gotoEvent}
        type="horizontal large-pad large-gap"
      ></GSItemList>
    );
  };
  const getUpcomingList = () => {
    return (
      <GSItemList
        items={props.upcomingEvents}
        listItem={(event) => (
          <GSInfoCard
            size="large"
            selectable={true}
            layout={<TournamentInfoGroup tournament={event} />}
          />
        )}
        itemSelected={gotoEvent}
        type="horizontal large-pad large-gap"
      ></GSItemList>
    );
  };
  const getPastList = () => {
    return (
      <GSItemList
        items={props.pastEvents}
        listItem={(event) => (
          <TournamentLineItem
            tournament={event}
            copyTournament={copyTournament}
          />
        )}
        itemSelected={gotoEvent}
        type="vertical selectable"
        emptyMessage={
          userHasAccess() ? getEmptyListMessage() : getUnAuthorizedListMessage()
        }
        loading={props.loading?.includes?.("searchPastTournaments")}
        loadingMainText={"Loading tournaments..."}
      ></GSItemList>
    );
  };
  const gotoEvent = (event) => {
    navigate(`/${event.id}`);
  };
  const copyTournament = (event) => {
    navigate(`tournaments/copy/${event.id}`);
  };
  const createEvent = () => {
    dispatch(setCurrentTournament(emptyTournament));
    navigate("/tournaments/new");
  };
  const getPageActions = () => {
    if (!userHasAccess()) {
      return [];
    }
    return [
      {
        id: 1,
        buttonTitle: "Create Tournament",
        type: "white mobile-icon",
        actionIcon: faPlus,
        actionClick: createEvent,
      },
    ];
  };
  const upcoming = {
    title: "Upcoming Tournaments",
    getItemList: getUpcomingList,
  };
  const past = {
    title: "Past Tournaments",
    getItemList: getPastList,
  };
  const draft = {
    title: "Tournament Drafts",
    getItemList: getDraftList,
  };
  const getHappeningNow = () => {
    if (hasHappeningNow()) {
      return props.upcomingEvents[0];
    }
  };
  const hasHappeningNow = () => {
    return props.upcomingEvents?.length > 0;
  };

  const getHappeningNowView = () => {
    return (
      <TournamentHero onClick={gotoEvent} tournament={getHappeningNow()} />
    );
  };

  const getTournaments = () => {
    return (
      <div className="tournaments">
        {props.upcomingEvents?.length > 0 && (
          <>
            {hasHappeningNow() && getHappeningNowView()}
            <div className="upcoming-events">
              <GSListPage {...upcoming} />
            </div>
          </>
        )}
        {props.draftEvents?.length > 0 && (
          <div className="draft-events">
            <GSListPage {...draft} />
          </div>
        )}
        <div className="past-events">
          <GSListPage {...past} />
        </div>
      </div>
    );
  };

  return (
    <tms-dashboard-tournaments>
      <GSPageLayout title="Tournaments Overview" pageActions={getPageActions()}>
        {getTournaments()}
      </GSPageLayout>
    </tms-dashboard-tournaments>
  );
};

const mapStateToProps = (state) => ({
  draftEvents: state.tournaments.draftEvents,
  upcomingEvents: state.tournaments.upcomingEvents,
  pastEvents: state.tournaments.pastEvents,
  loading: state.tournaments.loading,
  currentUser: state.app.loggedInUser,
});

export default connect(mapStateToProps)(TMSDashboardTournaments);
