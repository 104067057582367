import {
  faDollarSign,
  faPercent,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { GSRadioGroup } from "golfstatus_react_components";
import { getDateTimeYMD } from "../helpers/Dates";
export const getDiscountForm = (context, discountCode) => {
  if (context) {
    let discountUsageFields = [{
      label: "Discount Limit",
      value: (
        <GSRadioGroup
          selectedOption={context.getUsageLimit()}
          selectionChanged={context.setUsageLimit}
          options={[
            { label: "Unlimited Use", value: 1 },
            { label: "Limited Use", value: 2 },
          ]}
        />
      ),
      customView: true,
      isEditable: true,
    }]
    if(context.getUsageLimit?.()?.value === 2){
      discountUsageFields.push({
        label: "Uses Per Code",
        placeholder: "Uses Per Code",
        value: (discountCode.quantity ?? ""),
        failedValidation: context.validationFailed,
        onChange: (e) => {
          context.updateDiscount(e?.target?.value, "quantity");
        },
        isEditable: true,
        required: true
      })
    }
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Discount Label",
            placeholder: "Discount Label",
            value: discountCode?.description ?? "",
            onChange: (e) => {
              context.updateDiscount(e?.target?.value, "description");
            },
            isEditable: true,
            required: true,
            failedValidation: context.validationFailed,
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Discount Usage",
        fields: discountUsageFields,
      },
      {
        type: "vertical xx-large-gap",
        title: "Discount Details",
        fields: [
          {
            label: "Discount Code",
            placeholder: "Discount Code (Tap Icon to Generate Code)",
            leftIcon: faQrcode,
            leftIconClick: context?.generateCode,
            value: discountCode?.code ?? "",
            onChange: (e) => {
              context.updateDiscount(e?.target?.value, "code");
            },
            isEditable: true,
            required: true,
            failedValidation: context.validationFailed,
          },
          {
            label: "Discount Type",
            value: (
              <GSRadioGroup
                selectedOption={context.getPromotionType()}
                selectionChanged={context.setPromotionType}
                options={[
                  { label: "Fixed Amount", value: 1 },
                  { label: "Percentage", value: 2 },
                ]}
              />
            ),
            customView: true,
            isEditable: true,
          },
          context.getPromotionType().value === 1
            ? {
                label: "Discount Amount",
                placeholder: "0.00",
                value: discountCode.discountDollarAmount ?? "",
                leftIcon: faDollarSign,
                onChange: (e) => {
                  context.updateDiscount(
                    e?.target?.value,
                    "discountDollarAmount"
                  );
                },
                isEditable: true,
                required: true,
                failedValidation: context.validationFailed,
              }
            : {
                label: "Discount Percentage",
                placeholder: "0",
                value: discountCode.discountPercent ?? "",
                rightIcon: faPercent,
                onChange: (e) => {
                  context.updateDiscount(e?.target?.value, "discountPercent");
                },
                isEditable: true,
                required: true,
                failedValidation: context.validationFailed,
              },
        ],
      },
      
      {
        type: "vertical xx-large-gap",
        title: "Discount Activation",
        fields: [
          {
            label: "Activate On",
            value: discountCode.activateOn
              ? getDateTimeYMD(discountCode.activateOn)
              : "",
            onChange: (e) => {
              context.updateDiscount(e?.target?.value, "activateOn");
            },
            type: "datetime-local",
            isEditable: true,
          },
          {
            label: "Deactivate On",
            value: discountCode.deactivateOn
              ? getDateTimeYMD(discountCode.deactivateOn)
              : "",
            onChange: (e) => {
              context.updateDiscount(e?.target?.value, "deactivateOn");
            },
            type: "datetime-local",
            isEditable: true,
          },
        ],
      },
    ];

    return form;
  }
};
