import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  createThunkResponse,
  deleteThunkResponse,
  discountCodes,
  getThunkResponse,
  organizations,
  saveThunkResponse,
  tournament,
} from "../app/api";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
} from "../helpers/JSONapi";

export const getTournamentDiscountCodes = createAsyncThunk(
  "discount/getTournamentDiscountCodes",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/discount-codes`
    );
    return response;
  }
);

export const getDiscountCode = createAsyncThunk(
  "discount/getDiscountCode",
  async (discountCodeID, thunkAPI) => {
    const response = await gsApi.get(`${discountCodes}/${discountCodeID}`);
    return response;
  }
);

export const getOrganizationDiscountCodes = createAsyncThunk(
  "discounts/getOrganizationDiscountCodes",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}/discount-codes`);
    return response;
  }
);

//save
export const saveTournamentDiscount = createAsyncThunk(
  "discounts/saveTournamentDiscount",
  async (tournamentDiscount, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentDiscount,
      "discount-codes",
      getOpts(
        tournamentDiscount,
        getOpts(tournamentDiscount, [...getIDRelationship("tournament")])
      )
    );
    return await getResponse(
      gsApi.patch,
      `${discountCodes}/${tournamentDiscount.id}`,
      serializedData,
      thunkAPI
    );
  }
);

//create
export const createTournamentDiscount = createAsyncThunk(
  "discounts/createTournamentDiscount",
  async (tournamentDiscount, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentDiscount,
      "discount-codes",
      getOpts(tournamentDiscount, [...getIDRelationship("tournament")])
    );
    return await getResponse(
      gsApi.post,
      `${discountCodes}`,
      serializedData,
      thunkAPI
    );
  }
);

//delete

export const deleteDiscountCode = createAsyncThunk(
  "discount/deleteDiscountCode",
  async (discountCodeID, thunkAPI) => {
    const response = await gsApi.delete(`${discountCodes}/${discountCodeID}`, {
      data: {},
    });
    return response;
  }
);

//replace all occurences of template with the name of your new slice
export const discountSlice = createSlice({
  name: "discounts",
  initialState: {
    tournamentDiscounts: [],
    organizationDiscounts: [],
    currentDiscountCode: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setTournamentDiscounts: (state, action) => {
      state.tournamentDiscounts = action.payload;
    },
    setCurrentDiscountCode: (state, action) => {
      state.currentDiscountCode = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...getThunkResponse(getTournamentDiscountCodes, (state, action) => {
      state.tournamentDiscounts = action.payload;
    }),
    ...getThunkResponse(getDiscountCode, (state, action) => {
      state.currentDiscountCode = action.payload;
    }),
    ...getThunkResponse(getOrganizationDiscountCodes, (state, action) => {
      state.organizationDiscounts = action.payload;
    }),
    ...saveThunkResponse(
      saveTournamentDiscount,
      (state, action) => {},
      "Discount"
    ),
    ...createThunkResponse(
      createTournamentDiscount,
      (state, action) => {},
      "Discount"
    ),
    ...deleteThunkResponse(
      deleteDiscountCode,
      (state, action) => {},
      "Discount"
    ),
  },
});

export const {
  setTournamentDiscounts,
  setCurrentDiscountCode,
  addNotification,
  clearNotifications,
} = discountSlice.actions;

//export any selectors needed
export const selectTournamentDiscountCodes = (state) =>
  state?.[discountSlice.name]?.tournamentDiscounts;

export const selectOrganizationDiscountCodes = (state) =>
  state?.[discountSlice.name]?.organizationDiscounts;

export const selectCurrentDiscountCode = (state) =>
  state?.[discountSlice.name]?.currentDiscountCode;

export const selectNotifications = (state) =>
  state?.[discountSlice.name]?.notifications;

export const selectLoading = (state) => state?.[discountSlice.name]?.loading;

export default discountSlice.reducer;

//ADD reducer to store.js
