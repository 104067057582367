import { Route } from "react-router-dom"
import TournamentPlayersTeams from "../../components/tournament/tournament-teams/tournament-players-teams"
import TournamentTeamAddEdit from "../../components/tournament/tournament-teams/tournament-team-add-edit"
import TournamentTeamAddEditPlayer from "../../components/tournament/tournament-teams/tournament-team-add-edit-player"
import TournamentTeamDetails from "../../components/tournament/tournament-teams/tournament-team-details"
import TournamentTeamDisqualify from "../../components/tournament/tournament-teams/tournament-team-disqualify"
import TournamentTeamDisqualifiedList from "../../components/tournament/tournament-teams/tournament-team-disqualified-list"
import TournamentTeamExports from "../../components/tournament/tournament-teams/tournament-team-exports"
import TournamentTeamFilter from "../../components/tournament/tournament-teams/tournament-team-filter"
import TournamentTeamHandicapUpdate from "../../components/tournament/tournament-teams/tournament-team-handicap-update"
import TournamentTeamImport from "../../components/tournament/tournament-teams/tournament-team-import"
import TournamentTeamNotes from "../../components/tournament/tournament-teams/tournament-team-notes"
import TournamentTeamPayments from "../../components/tournament/tournament-teams/tournament-team-payments"
import TournamentTeamRemove from "../../components/tournament/tournament-teams/tournament-team-remove"
import TournamentTeamRemovePlayer from "../../components/tournament/tournament-teams/tournament-team-remove-player"
import TournamentTeamScorecards from "../../components/tournament/tournament-teams/tournament-team-scorecards"
import TournamentTeamRoundSettings from "../../components/tournament/tournament-teams/tournament-team-round-settings"
import TournamentTeamTeeChoices from "../../components/tournament/tournament-teams/tournament-team-tee-choices"

export const getTournamentTeamsRoutes = () => {
  return (
    <Route path="teams" element={<TournamentPlayersTeams />}>
            <Route path=":teamID" element={<TournamentTeamDetails />}></Route>
            <Route path="handicaps" element={<TournamentTeamHandicapUpdate />}></Route>
            <Route path="disqualified" element={<TournamentTeamDisqualifiedList />}></Route>
            <Route
              path=":teamID/notes"
              element={<TournamentTeamNotes />}
            ></Route>
            <Route
              path=":teamID/payment"
              element={<TournamentTeamPayments />}
            ></Route>
            <Route
              path=":teamID/rounds"
              element={<TournamentTeamRoundSettings />}
            ></Route>
            <Route
              path=":teamID/tees"
              element={<TournamentTeamTeeChoices />}
            ></Route>
            <Route
              path=":teamID/scorecards"
              element={<TournamentTeamScorecards />}
            ></Route>
            <Route
              path=":teamID/remove"
              element={<TournamentTeamRemove />}
            ></Route>
            <Route
              path=":teamID/disqualify"
              element={<TournamentTeamDisqualify />}
            ></Route>
            <Route
              path=":teamID/edit"
              element={<TournamentTeamAddEdit />}
            ></Route>
            <Route
              path=":teamID/edit/players/add/:playerID"
              element={<TournamentTeamAddEditPlayer />}
            ></Route>
            <Route
              path=":teamID/edit/players/edit/:playerID"
              element={<TournamentTeamAddEditPlayer />}
            ></Route>
            <Route
              path=":teamID/edit/players/edit/:playerID/remove"
              element={<TournamentTeamRemovePlayer />}
            ></Route>
            <Route path="add" element={<TournamentTeamAddEdit />}></Route>
            <Route
              path="add/players/add/:playerID"
              element={<TournamentTeamAddEditPlayer />}
            ></Route>
            <Route
              path="add/players/edit/:playerID"
              element={<TournamentTeamAddEditPlayer />}
            ></Route>
            <Route path="import" element={<TournamentTeamImport />}></Route>
            <Route path="filter" element={<TournamentTeamFilter />}></Route>
            <Route
              path="unassigned/:playerID"
              element={<TournamentTeamAddEdit />}
            ></Route>
            <Route
              path="waitlist/:playerID"
              element={<TournamentTeamAddEdit />}
            ></Route>
            <Route
              path="documents"
              element={<TournamentTeamExports />}
            ></Route>
          </Route>
  )
}