import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  addSerializedRelationships,
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  longDelay,
} from "../helpers/JSONapi";
import {
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  saveThunkResponse,
  teamFlights,
  teamPreflights,
  tournament,
  tournamentFlights,
  tournamentRounds,
} from "../app/api";

export const getTournamentFlights = createAsyncThunk(
  "flight/getTournamentFlights",
  async (tournamentID) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/tournament-flights`
    );
    return response;
  }
);

export const getTournamentPreFlights = createAsyncThunk(
  "flight/getTournamentPreFlights",
  async (tournamentID) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/team-preflights`
    );
    return response;
  }
);

export const getTournamentFlight = createAsyncThunk(
  "flight/getTournamentFlight",
  async (flightID) => {
    const response = await gsApi.get(`${tournamentFlights}/${flightID}`);
    return response;
  }
);

export const getRoundFlight = createAsyncThunk(
  "flight/getRoundFlight",
  async (roundID) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${roundID}/flight-rounds`
    );
    return response;
  }
);

//save

export const saveTournamentFlight = createAsyncThunk(
  "flight/saveTournamentFlight",
  async (flight, thunkAPI) => {
    let serializedData = getSerializedData(
      flight,
      "tournament-flights",
      getOpts(flight, [...getIDRelationship("tournament")])
    );
    return await getResponse(
      gsApi.patch,
      `${tournamentFlights}/${flight.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTeamFlight = createAsyncThunk(
  "flight/saveTeamFlight",
  async (teamFlight, thunkAPI) => {
    let serializedData = getSerializedData(
      teamFlight,
      "team-flights",
      getOpts(teamFlight, [...getIDRelationship("tournament"),...getIDRelationship("tournament-flight"),...getIDRelationship("tournament-team")])
    );

    return await getResponse(
      gsApi.patch,
      `${teamFlights}/${teamFlight.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const preflightTournamentAndFlights = createAsyncThunk(
  "flight/preflightTournamentAndFlights",
  async (tournamentAndFlights, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentAndFlights,
      "tournaments",
      getOpts(tournamentAndFlights, [])
    );

    addSerializedRelationships(
      serializedData.data,
      "tournament-flights",
      tournamentAndFlights["tournament-flights"],
      [
        "flight-rounds",
        "flight-round-tees",
        "round-tee",
        "round-course",
        "tournament",
        "leaderboard-type-setting",
        "tournament-round",
        "tournament-flight",
        "tournament",
        "flight-round"
      ]
    );

    return await getResponse(
      gsApi.patch,
      `${tournament}/${tournamentAndFlights.id}/preflighting-tournament-and-flights`,
      serializedData,
      thunkAPI
    );
  }
);

export const savePreflight = createAsyncThunk(
  "flight/savePreflight",
  async (flight, thunkAPI) => {
    let serializedData = getSerializedData(
      flight,
      "team-preflights",
      getOpts(flight, [
        ...getIDRelationship("preflightSet"),
        ...getIDRelationship("tournamentTeam"),
      ])
    );
    return await getResponse(
      gsApi.patch,
      `${teamPreflights}/${flight.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const autoAssignFlights = createAsyncThunk(
  "flight/autoAssignFlights",
  async (assignSettings, thunkAPI) => {
    if (assignSettings?.tournamentID && assignSettings?.teamIds) {
      return await getResponse(
        gsApi.post,
        `${tournament}/${assignSettings.tournamentID}/auto-preflight`,
        { teamIds: assignSettings.teamIds },
        thunkAPI
      );
    }
  }
);

//create

export const createTournamentFlight = createAsyncThunk(
  "flight/createTournamentFlight",
  async (flight, thunkAPI) => {
    let serializedData = getSerializedData(
      flight,
      "tournament-flights",
      getOpts(flight, [
        ...getIDRelationship("tournament"),
        ...getIDRelationship("leaderboard-type-setting"),
      ])
    );
    return await getResponse(
      gsApi.post,
      `${tournamentFlights}`,
      serializedData,
      thunkAPI
    );
  }
);

//delete

export const removeTournamentFlight = createAsyncThunk(
  "flight/removeTournamentFlight",
  async (flightID) => {
    const response = await gsApi.delete(`${tournamentFlights}/${flightID}`, {
      data: {},
    });
    return response;
  }
);

//calculate flights

export const createTeamFlights = createAsyncThunk(
  "flight/createTeamFlights",
  async (tournamentID) => {
    const response = await gsApi.post(`${tournament}/${tournamentID}/create_team_flights`,{});
    return response;
  }
);

export const deleteTeamFlights = createAsyncThunk(
  "flight/deleteTeamFlights",
  async (tournamentID) => {
    const response = await gsApi.delete(`${tournament}/${tournamentID}/delete_team_flights`,{data:{}});
    return response;
  }
);


export const removeAllFlightAssignments = createAsyncThunk(
  "flight/removeAllFlightAssignments",
  async (tournamentID) => {
    const response = await gsApi.delete(
      `${tournament}/${tournamentID}/team-preflight-assignments`,
      { data: {} }
    );
    return response;
  }
);

//replace all occurences of flight with the name of your new slice
export const flightSlice = createSlice({
  name: "flights",
  initialState: {
    tournamentFlights: [],
    roundFlights: [],
    tournamentTeamPreflights: [],
    currentTournamentFlight: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setTournamentFlights: (state, action) => {
      state.tournamentFlights = action.payload;
    },
    updateCurrentFlight: (state, action) => {
      state.currentTournamentFlight = action.payload;
    },
    updateTeamFlight: (state, action) => {
      let preflightIndex = -1;
      let flightTeamIndex = -1;
      state.tournamentTeamPreflights.forEach((ttpf, index) => {
        preflightIndex = index;
        flightTeamIndex = ttpf.teamPreflights.findIndex(
          (tpf) => tpf.id === action.payload.update.id
        );
      });
      if (preflightIndex !== -1 && flightTeamIndex !== -1) {
        state.tournamentTeamPreflights[preflightIndex].teamPreflights[
          flightTeamIndex
        ] = action.payload.update;
      }
    },
    updateTeamResult: (state, action) => {
      state.tournamentFlights = action.payload
    },
    updateFlightTeams: (state, action) => {
      let index = state.tournamentFlights.findIndex?.(f => f.id === action.payload.id && f.leaderboardTypeSetting.id === action.payload.leaderboardTypeSetting.id)
      state.tournamentFlights[index] = action.payload
    },
    addRoundFlight: (state, action) => {
      const fr = action.payload;
      let index = state.roundFlights.findIndex?.(
        (rf) =>
          rf?.tournamentRound?.id === fr?.tournamentRound?.id &&
          rf?.tournamentFlight?.id === fr?.tournamentFlight?.id
      );
      if (index === -1) {
        state.roundFlights.push(fr);
      } else {
        state.roundFlights[index] = fr;
      }
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    //get tournament flights
    ...getThunkResponse(getTournamentFlights, (state, action) => {state.tournamentFlights = action.payload;}),
    //get round flights
    ...getThunkResponse(getRoundFlight, (state, action) => {
      action.payload.forEach((fr) => {
        let index = state.roundFlights.findIndex?.((rf) => rf.id === fr.id);
        if (index === -1) {
          state.roundFlights.push(fr);
        } else {
          state.roundFlights[index] = fr;
        }
      });
    }),
    //get tournament team preflights
    ...getThunkResponse(getTournamentPreFlights, (state, action) => {
      state.tournamentTeamPreflights = action.payload;
    }),
    //get tournament flight
    ...getThunkResponse(getTournamentFlight, (state, action) => {
      state.currentTournamentFlight = action.payload;
    }),
    //save flight
    ...saveThunkResponse(saveTournamentFlight, (state, action) => {
      const flightIndex = state.tournamentFlights.findIndex(
        (tf) => tf.id === action.payload.id
      );
      if (flightIndex !== -1) {
        state.tournamentFlights[flightIndex] = {
          ...state.tournamentFlights[flightIndex],
          ...action.payload,
        };
      }
    }, "Tournament Flight"),
    //create flight
    ...createThunkResponse(createTournamentFlight, (state, action) => {state.tournamentFlights.push(action.payload);}, "Tournament Flight"),
    // remove flight
    ...deleteThunkResponse(removeTournamentFlight, (state, action) => {
      state.tournamentFlights = state.tournamentFlights?.filter?.(
        (f) => f.id !== action.meta.arg
      );
    }, "Tournament Flight"),
    // remove all flight assignments
    ...deleteThunkResponse(removeAllFlightAssignments, (state, action) => {
      state.tournamentTeamPreflights = action.payload;
    }, "Flight Assignments"),
    // remove all flight assignments
    ...saveThunkResponse(autoAssignFlights, (state, action) => {}, "Flight Teams"),
    ...saveThunkResponse(savePreflight, () => {}, "Flight Assignment"),
    ...saveThunkResponse(createTeamFlights, (state, action) => {}, "Flights", longDelay),
    ...deleteThunkResponse(deleteTeamFlights, (state, action) => {}, "Flights"),
    ...saveThunkResponse(saveTeamFlight, (state, action) => {}, "Team Flight"),
    ...saveThunkResponse(preflightTournamentAndFlights, (state, action) => {
      state.roundFlights = []
    }, "preflight")
  },
});

export const {
  setTournamentFlights,
  updateCurrentFlight,
  addRoundFlight,
  updateTeamFlight,
  addNotification,
  clearNotifications,
  updateTeamResult,
  setNotifications,
  updateFlightTeams
} = flightSlice.actions;

//export any selectors needed
export const selectTournamentFlights = (state) =>
  state?.[flightSlice.name]?.tournamentFlights;

export const selectRoundFlights = (state) =>
  state?.[flightSlice.name]?.roundFlights;

export const selectTournamentTeamPreflights = (state) =>
  state?.[flightSlice.name]?.tournamentTeamPreflights;

export const selectFlightNotifications = (state) =>
  state?.[flightSlice.name]?.notifications;

export const selectFlightLoading = (state) =>
  state?.[flightSlice.name]?.loading;

export const selectCurrentTournamentFlight = (state) =>
  state?.[flightSlice.name]?.currentTournamentFlight;

export default flightSlice.reducer;

//ADD reducer to store.js
