import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-player-info.scss";
import { getPackagePlayerInfoForm } from "../../../../forms/TournamentPackageForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentPackage,
  saveTournamentPackage,
  selectCurrentTournamentPackage,
  selectNotifications,
  setCurrentPackage,
} from "../../../../reducers/packageSlice";
import { useFormValidation } from "../../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../../hooks/notificationHooks";

//Name the component
const TournamentPackagePlayerInfo = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/player-info"
  );

  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const packageNotifications = useSelector(selectNotifications);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  const [context, isValid, setIsValid] = useFormValidation(false)

  context.updatePackage = (value, property) => {
    setIsValid(true);
    let update = { ...currentPackage };
    update[property] = value;
    dispatch(setCurrentPackage(update));
  };

  const save = () => {
    setUnsaved(false)
    setIsValid(false)
    let update = { ...currentPackage };
    dispatch(saveTournamentPackage(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if(isValid && !bannerNotifications){
      setUnsaved(true)
      return
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${currentPackage?.name} Player Information`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={`${currentPackage?.name} Player Information`}
        formSections={getPackagePlayerInfoForm(context, currentPackage)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-player-info>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-player-info>
  );
};

//export the new namet
export default TournamentPackagePlayerInfo;
