import "./tournament-players-teams.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  faArrowCircleUp,
  faComment,
  faFolderOpen,
  faPlus,
  faSearch,
  faSync,
  faTimesCircle, faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSActionBar,
} from "golfstatus_react_components";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeams,
  getTournamentUnassignedPlayers,
  getTournamentWaitList,
  selectLoading,
  selectTournamentTeams,
  selectTournamentUnassignedPlayers,
  selectTournamentWaitList,
  setCurrentTeam,
  setTournamentTeams,
} from "../../../reducers/teamsSlice";
import TournamentTeamItem from "./tournament-team-item";
import TournamentTeamCard from "./tournament-team-card";
import {
  addNotification,
  clearNotifications,
} from "../../../reducers/appSlice";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

const TournamentPlayersTeams = (props) => {
  const [search, setSearch] = useState("");
  const match = useMatch("/:tournamentID/teams/*");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadCallback = useCallback(() => {
    dispatch(setTournamentTeams([]));
    dispatch(getTournamentTeams(match?.params?.tournamentID));
    dispatch(getTournamentWaitList(match?.params?.tournamentID));
    dispatch(getTournamentUnassignedPlayers(match?.params?.tournamentID));
  }, [dispatch, match?.params?.tournamentID]);

  const tournamentTeams = useSelector(selectTournamentTeams);
  const waitList = useSelector(selectTournamentWaitList);
  const unassignedPlayers = useSelector(selectTournamentUnassignedPlayers);
  const currentTournament = useSelector(selectCurrentTournament);

  const loading = useSelector(selectLoading);

  useEffect(() => {
    loadCallback();
  }, [loadCallback]);

  const addTeam = () => {
    dispatch(setCurrentTeam({}));
    navigate("add");
  };

  const importTeams = () => {
    navigate("import");
  };

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Add Team",
        type: "black mobile-icon",
        actionClick: addTeam,
        actionIcon: faPlus,
      },
      {
        id: 2,
        buttonTitle: "Upload Teams",
        type: "light-grey mobile-icon",
        actionClick: importTeams,
        actionIcon: faArrowCircleUp,
      },
      {
        id: 3,
        title: "Documents",
        type: "light-grey mobile-icon",
        actionClick: exportTeams,
        actionIcon: faFolderOpen,
      },
    ];
  };

  const getSearchActions = () => {
    return [];
  };

  const clearSearch = () => {
    setSearch("");
  };

  const getDefaultSearch = () => {
    return (
      <div className="quick-filter">
        <GSInput
          textValue={search}
          leftIcon={faSearch}
          rightIcon={faTimesCircle}
          rightIconClick={clearSearch}
          placeholder="Search Players & Teams…"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></GSInput>
      </div>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Manage Players & Teams"
        detail="Players and teams registering on the event site will automatically be shown here."
        actions={[
          {
            title: "Add Team",
            buttonIcon: faPlus,
            onClick: addTeam,
            type: "black",
          },
          {
            title: "Upload Teams",
            buttonIcon: faArrowCircleUp,
            onClick: importTeams,
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term or add a new team."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "Add Team",
            buttonIcon: faPlus,
            onClick: addTeam,
            type: "black",
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const teamFilter = (teams) => {
    return teams?.filter?.((t) =>
      `${t?.contactName} ${t?.name} ${t?.players
        ?.map?.((p) => p.name)
        ?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search.toLowerCase?.())
    );
  };

  const getFilteredUnassigned = () => {
    return unassignedPlayers?.filter((p) => p.status !== "assigned") ?? [];
  };

  const getFilteredWaitlist = () => {
    return waitList?.filter?.((m) => m.status !== "assigned");
  };

  const exportTeams = () => {
    navigate("documents");
    // dispatch(downloadTeamExport(currentTournament.id))
  };

  const getRegisteredTeamsActions = () => {
    let actions = [];
    if (tournamentTeams.filter(t => t.disqualified).length > 0) {
      actions.push({
        title: "Disqualified Teams",
        type: "light-grey mobile-icon",
        actionIcon: faUsersSlash,
        actionClick: () => {
          navigate("disqualified");
        },
      });
    }
    if (currentTournament.handicapScoring) {
      actions.push({
        title: "update Handicaps",
        type: "light-grey mobile-icon",
        actionIcon: faSync,
        actionClick: () => {
          navigate("handicaps");
        },
      });
    }

    return actions;
  };

  const getFilteredList = () => {
    let groups = [];
    let unassigned = teamFilter(
      getFilteredUnassigned?.()?.map?.((p) => ({
        tempID: p.id,
        entryCode: "Add To Team",
        unassigned: true,
        contactName: p.name,
        firstName: p?.name?.split?.(" ")?.[0],
        lastName: p?.name?.split?.(" ")?.[1],
        name: p.name,
        phone: p.phone,
        email: p.email,
        adjustedHandicap: 0,
        hasPaid: p.hasPaid,
        signupMessage: p.signupMessage,
        player: p,
      }))
    );
    let waitListPlayers = teamFilter(
      getFilteredWaitlist?.().map?.((p) => ({
        tempID: p.id,
        entryCode: `Add Team`,
        unassigned: false,
        contactName: p.fullName,
        firstName: p?.fullName?.split?.(" ")?.[0],
        lastName: p?.fullName?.split?.(" ")?.[1],
        name: p.fullName,
        phone: p.phone,
        email: p.email,
        adjustedHandicap: 0,
        hasPaid: p.hasPaid,
        member: p,
        signupMessage: p.notes,
        waitlistType: `${p.waitListType} waitlist`,
        isWaitList: true,
      }))
    );
    if (unassigned?.length > 0) {
      groups.push({
        header: "Unassigned Players",
        items: unassigned,
      });
    }

    if (waitListPlayers?.length > 0) {
      groups.push({
        header: "Waitlist Entries",
        items: waitListPlayers,
      });
    }

    groups.push({
      header: "Registered Teams",
      actions: getRegisteredTeamsActions(),
      items: teamFilter(tournamentTeams),
    });

    return groups;
  };

  const getMessage = (player) => {
    return {
      header: getNotificationItemInfo(
        player.signupMessage,
        faComment,
        player.contactName
      ),
      state: "grey",
      bannerActions: [
        {
          actionIcon: faTimesCircle,
          actionClick: () => {
            dispatch(clearNotifications());
          },
        },
      ],
    };
  };

  const showMessage = (player) => {
    dispatch(addNotification(getMessage(player)));
  };

  const registeredTeamsHeader = (group) => {
    const qualifiedTeams = group.items.filter((i) => !i.disqualified);
    const disqualifiedTeams = group.items.filter((i) => i.disqualified);
    return (
      <>
        <div>{group.header} ({qualifiedTeams.length}{
          group.header === "Registered Teams" && currentTournament.numberOfTeams
            ? `/${currentTournament.numberOfTeams}`
            : ""
        })</div>
        {disqualifiedTeams.length > 0 && <div className="sub-title">Disqualified ({disqualifiedTeams.length})</div>}
      </>
    );
  }

  const getPlayerGroupList = (group) => {
    const qualifiedTeams = group.items.filter((i) => !i.disqualified);
    return (
      <div className="team-group">
        <GSActionBar
          header={registeredTeamsHeader(group)}
          pageActions={group.actions}
        ></GSActionBar>
        <GSItemList
          type={`${
            group.header === "Registered Teams"
              ? "vertical"
              : "horizontal x-large-pad large-gap"
          } selectable`}
          items={qualifiedTeams}
          listItem={(item) =>
            group.header === "Registered Teams" ? (
              <TournamentTeamItem {...item} messageClicked={showMessage} />
            ) : (
              <TournamentTeamCard
                {...item}
                messageClicked={showMessage}
              ></TournamentTeamCard>
            )
          }
          itemSelected={itemSelected}
          emptyMessage={
            search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
          }
          loading={loading.includes("getTournamentTeams")}
          loadingMainText="Loading Teams... this could take a second."
        ></GSItemList>
      </div>
    );
  };

  const itemSelected = (item) => {
    if (isSidePanelOpen()) {
      navigate(`/${match?.params?.tournamentID}/teams`);
      return;
    }

    if (item.unassigned) {
      dispatch(
        setCurrentTeam({
          contactName: item.name,
          email: item.email,
          phone: item.phone,
          players: [{ ...item, mainContact: true }],
        })
      );
      navigate("add");
    } else if (item.isWaitList) {
      dispatch(
        setCurrentTeam({
          contactName: item.name,
          email: item.email,
          phone: item.phone,
          players: [{ ...item, mainContact: true }],
        })
      );
      navigate("add");
    } else {
      navigate(item.id);
    }
  };

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical"
        items={getFilteredList()}
        listItem={(item) => getPlayerGroupList(item)}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={() => {
          loading.includes("getTournamentTeams");
        }}
        loadingMainText="Loading Teams... this could take a second."
      ></GSItemList>
    );
  };

  return (
    <tournament-players-teams>
      <GSListPage
        title="Players & Teams"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-players-teams>
  );
};

export default TournamentPlayersTeams;
