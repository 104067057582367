import { GSInfoGroup, GSButton } from "golfstatus_react_components";
import React from "react";
import { getDateTimeOn } from "../../../helpers/Dates";

import { moneyWithCommas } from "../../../helpers/RegexHelper";
import "./tournament-reporting-payout-item.scss";

const TournamentReportingPayoutItem = (props) => {
  const {
    amount,
    //fulfilledOn,
    payoutType,
    requestedOn,
    status,
    //stripeAccountID,
    //stripeTransferID,
  } = props;

  const payoutSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width capitalize",
        value: payoutType.replace("_", " "),
      },
      {
        type: "secondary no-wrap full-width",
        value: getDateTimeOn(requestedOn),
      },
    ],
  };

  const payoutColumn = {
    fit: "stretch",
    sections: [
      {
        ...payoutSection,
      },
    ],
  };

  const amountSection = {
    fit: "flex-end",
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: moneyWithCommas(amount),
      },
    ],
  };

  const statusSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            type={` ${
              status === "fulfilled" ? "green" : "orange"
            } secondary pill`}
            title={status}
          />
        ),
      },
    ],
  };

  const amountColumn = {
    sections: [
      {
        ...amountSection
      }, statusSection
    ],
  };


  const getGroup = () => {
    let group = [{ ...payoutColumn }, { ...amountColumn }];
    return group;
  };

  return (
    <tournament-reporting-payout-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-reporting-payout-item>
  );
};

export default TournamentReportingPayoutItem;
