import React from "react";

import './progress-bar.scss';

const ProgressBar = (props) => {
  const {value, max} = props
  const percentage = (value / max) * 100
  const style = {width: `${percentage}%`}
  return <progress-bar>
    <div className="percentage-bar" style={style}>
      
    </div>
    <div className="percentage-value">
      {`${Math.round(percentage)}%`}
      </div>
  </progress-bar>
}

export default ProgressBar

