import {
  GSItemList,
  GSRadioGroup,
  GSToggle,
  GSSelect,
} from "golfstatus_react_components";
import TournamentFlightLineItem from "../components/tournament/tournament-flights/tournament-flight-line-item";
import { getRoundName } from "../helpers/Converters";

export const getFlightDetailPages = (flight, leaderboards, tournamentID) => {
  let pages = [
    {
      hero: <TournamentFlightLineItem {...flight} hidePosition />,
      isEdit: true,
      hidden: true,
      url: "edit",
      type: "hero",
    },
    {
      label: "Players & Teams",
      isExternal: false,
      url: "assignments",
      hidden: false,
    },
    {
      label: "Default Tees",
      isExternal: false,
      url: "tees",
      hidden: false,
    },
  ];
  const leaderboardpages = leaderboards?.map((lb) => ({
    label: `${lb.name} Results`,
    url: `/${tournamentID}/leaderboards/${lb?.id}/${lb?.flight?.id}/results`,
  }));
  return [...pages, ...leaderboardpages];
};

export const getFlightForm = (context, flight) => {
  if (context) {
    let form = [
      {
        type: "vertical large-gap",
        fields: [
          {
            label: "Flight Name",
            placeholder: "Flight Name",
            hintText:
              "“Flight” will automatically be added to the end of your flight name.",
            value: flight?.name,
            failedValidation: context.validationFailed,
            onSubmit: context.submitForm,
            onChange: (e) => context.updateFlight(e.target.value, "name"),
            isEditable: true,
            required: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getFlightTeeForm = (context, flight) => {
  if (context) {
    let form = [];
    if (context?.isPreflighted?.() && flight?.id) {
      let roundSections = context?.getRounds?.()?.map?.((round) => ({
        title: `Round ${getRoundName(round)}`,
        type: "vertical large-gap",
        fields: [
          ...context
            ?.getFlightRoundCourses?.(round)
            ?.map?.((c) => getTeeSelector(context, round, c)),
        ],
      }));
      form.push(...roundSections);
    }
    return form;
  }
};

const getTeeSelector = (context, round, course) => {
  return {
    label: `${course.course.name} course`,
    value: (
      <GSSelect
        placeholder="Select a Tee..."
        isClearable
        onChange={(option) =>
          context.roundTeeSelected(round, option?.value, course)
        }
        selectedOption={context.getFlightRoundTee(round, course)}
        options={course?.roundTees?.map?.((rt) => ({
          label: rt?.name,
          value: rt,
        }))}
      ></GSSelect>
    ),
    customView: true,
  };
};

export const getFlightSettingsForm = (context, tournament) => {
  if (context) {
    let flightingInformation = {
      type: "vertical large-gap mobile-vertical",
      fields: [
        {
          label: "Pre-Flight",
          description:
            "Players and teams will be assigned to flights prior to the start of the tournament.",
          value: (
            <GSToggle
              value={tournament?.preflight ?? false}
              trueDescription="Yes"
              falseDescription="No"
              onClick={context.togglePreflight}
            />
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };

    let form = [
      {
        ...flightingInformation,
      },
    ];
    return form;
  }
};

export const getFlightAutoAssignForm = (context) => {
  if (context) {
    let sortingInformation = {
      type: "vertical large-gap mobile-vertical",
      fields: [
        {
          label: "Assignment Type",
          value: (
            <GSRadioGroup
              selectedOption={context.getSort()}
              options={[
                { label: "Team Handicap", value: "Team Handicap" },
                { label: "Player Handicap", value: "Player Handicap" },
              ]}
              selectionChanged={context.setSort}
            />
          ),
          customView: true,
          isEditable: true,
        },
        {
          label: "Assignment Order",
          value: (
            <GSRadioGroup
              selectedOption={context.getSortDirection()}
              options={[
                { label: "Low to High", value: "Ascending" },
                {
                  label: "High to Low",
                  value: "Descending",
                },
              ]}
              selectionChanged={context.setSortDirection}
            />
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };

    let form = [
      {
        ...sortingInformation,
      },
    ];
    return form;
  }
};

//remove team

//remove team
export const removeFlightAssignmentsForm = (flights) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-container">
          <div className="disclaimer">{`Are you sure you want to remove all the flight assignments from your tournament? This cannot be undone.`}</div>
          <div className="item">
            <GSItemList
              type="vertical"
              items={flights}
              listItem={(item) => <TournamentFlightLineItem {...item} />}
            ></GSItemList>
          </div>
        </div>
      ),
    },
  ];
  return form;
};
