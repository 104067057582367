
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-settings.scss"
import { useSelector } from "react-redux";
import { clearTournamentNotifications, saveTournament, selectCurrentTournament, selectTournamentNotifications, setCurrentTournament } from "../../../reducers/tournamentSlice";
import { getFlightSettingsForm } from "../../../forms/FlightingForm";
import { useDispatch } from "react-redux";
import { useFormValidation } from "../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../hooks/notificationHooks";
import { selectFlightLoading } from "../../../reducers/flightSlice";

//Name the component
const TournamentFlightSettings = (props) => {
  const currentTournament = useSelector(selectCurrentTournament)
  const tournamentNotifications = useSelector(selectTournamentNotifications)
  const loading = useSelector(selectFlightLoading)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateTournament = (value, property) => {
    setIsValid(true);
    let tourney = { ...currentTournament };
    tourney[property] = value;
    return dispatch(setCurrentTournament(tourney));
  };

  const save = () => {
    setUnsaved(false)
    setIsValid(false)
    let newTournament = { ...currentTournament };
    dispatch(saveTournament(newTournament));
  };

  context.togglePreflight = () => {
    context.updateTournament(!currentTournament.preflight, "preflight");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Flight Settings`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Flight Settings" formSections={getFlightSettingsForm(context, currentTournament)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-flight-settings>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flight-settings>
  );
};

//export the new namet
export default TournamentFlightSettings;