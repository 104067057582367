import React, { useCallback, useEffect, useMemo } from "react";
import {
  GSButton,
  GSFileSelect,
  GSForm,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faArrowCircleUp,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import "./tournament-sponsor-create-edit.scss";
import { editSponsorForm } from "../../../forms/SponsorForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  createTournamentSponsor,
  getTournamentSponsor,
  saveTournamentSponsor,
  selectCurrentSponsor,
  selectSponsorList,
  selectSponsorNotifications,
  setCurrentSponsor,
} from "../../../reducers/sponsorSlice";
import {
  selectCroppedTarget,
  selectUtilityNotifications,
  clearUtilityNotifications,
  clearUtility,
} from "../../../reducers/utilitySlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import {
  useFormDataValidation,
  useImageSelector,
} from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationAction,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentSponsorCreateEdit = (props) => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchAdd = useMatch("/:tournamentID/sponsors/add");
  const matchEdit = useMatch("/:tournamentID/sponsors/:sponsorID/edit");

  //selectors
  const currentTournament = useSelector(selectCurrentTournament);
  const currentSponsor = useSelector(selectCurrentSponsor);
  const sponsorNotifications = useSelector(selectSponsorNotifications);
  const utilityNotifications = useSelector(selectUtilityNotifications);
  const croppedTarget = useSelector(selectCroppedTarget);
  const tournamentSponsors = useSelector(selectSponsorList);

  const leaderboardSponsors = useMemo(() => {
    return tournamentSponsors
      .filter((s) => s.sponsorshipType === "leaderboard")
      .map((hs, index) => ({ ...hs, sortOrder: index }));
  }, [tournamentSponsors]);

  //form context
  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValue: false,
    data: currentSponsor,
    setData: setCurrentSponsor,
  });
  const [
    imageReady,
    setImageReady,
    setLogo,
    removeLogo,
    getLogo,
    uploadLogo,
  ] = useImageSelector(context, "logo");
  const [
    mobileImageReady,
    setMobileImageReady,
    setMobileLogo,
    removeMobileLogo,
    getMobileLogo,
    uploadMobileLogo,
  ] = useImageSelector(context, "logoMobile");

  const sendUpdate = useCallback(() => {
    setImageReady(false);
    setMobileImageReady(false);
    let payload = { ...currentSponsor };
    payload.tournament = currentTournament;
    payload.logo = imageReady ? imageReady : payload.logo;
    payload.logoMobile = mobileImageReady
      ? mobileImageReady
      : payload.logoMobile;
    if (
      matchEdit?.params?.sponsorID !== undefined &&
      matchEdit?.params?.sponsorID === currentSponsor.id
    ) {
      dispatch(saveTournamentSponsor(payload));
    } else {
      dispatch(createTournamentSponsor(payload));
    }
  }, [
    currentSponsor,
    currentTournament,
    mobileImageReady,
    imageReady,
    dispatch,
    matchEdit?.params?.sponsorID,
    setImageReady,
    setMobileImageReady,
  ]);

  useEffect(() => {
    if (matchEdit?.params?.sponsorID !== currentSponsor.id) {
      dispatch(getTournamentSponsor(matchEdit.params.sponsorID));
    }
  }, [dispatch, currentSponsor.id, matchEdit?.params?.sponsorID, setIsValid]);

  useEffect(() => {
    if (croppedTarget.logo || croppedTarget.logoMobile) {
      setIsValid(true);
    }
  }, [croppedTarget, matchAdd?.params?.tournamentID, setIsValid]);

  useEffect(() => {
    if (imageReady && mobileImageReady) {
      sendUpdate();
    }
  }, [imageReady, mobileImageReady, sendUpdate]);

  useNotificationNavigation(
    "created",
    sponsorNotifications,
    `/${matchAdd?.params?.tournamentID}/sponsors/[id]/edit`,
    "Tournament Sponsor"
  );
  useNotificationAction(
    "saved",
    utilityNotifications,
    () => {
      dispatch(clearUtility());
    },
    "File Upload"
  );

  context.getFileSelect = (context) => {
    return (
      <GSFileSelect
        id="logo"
        description="Tap or drag a file to upload. The image ratio is 2:1.  Accepted file types are .JPG, .PNG, .GIF"
        setSelectedFiles={(files) => {
          setLogo(files);
        }}
        removeSourceItem={() => removeLogo("logo")}
        sourceList={getLogo()}
        accept=".jpg, .jpeg, .png, mp4"
        editImage={() => {
          navigate("image?property=logo");
        }}
        minDimension={599} // images come back resized, and sometimes off by <= 1px
        ratio={2}
        warningClicked={() => {
          navigate("image?property=logo");
        }}
        title={
          <GSButton buttonIcon={faArrowCircleUp} title="upload"></GSButton>
        }
        failedValidation={context.validationFailed}
      ></GSFileSelect>
    );
  };

  context.getMobileFileSelect = (context) => {
    return (
      <GSFileSelect
        id="mobile-logo"
        description="Tap or drag a file to upload. The image ratio is 4:1. Accepted file types are .JPG, .PNG, .GIF"
        setSelectedFiles={(files) => {
          setMobileLogo(files);
        }}
        removeSourceItem={() => removeMobileLogo()}
        sourceList={getMobileLogo()}
        accept=".jpg, .jpeg, .png, mp4"
        editImage={() => {
          navigate("image?property=logoMobile");
        }}
        minDimension={200}
        ratio={4}
        warningClicked={() => {
          navigate("image?property=logoMobile");
        }}
        title={
          <GSButton buttonIcon={faArrowCircleUp} title="upload"></GSButton>
        }
        failedValidation={context.validationFailed}
      ></GSFileSelect>
    );
  };

  context.hasTechSponsor = () => {
    return (
      leaderboardSponsors?.length > 0 &&
      currentSponsor.sponsorshipType !== "leaderboard"
    );
  };

  //component functionality
  const saveSponsor = () => {
    setIsValid(false);
    setUnsaved(false);
    if (croppedTarget?.id === currentSponsor?.id) {
      uploadLogo();
      uploadMobileLogo();
    } else {
      sendUpdate();
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: saveSponsor,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(
      matchEdit
        ? `/${matchEdit.params.tournamentID}/sponsors/${matchEdit.params.sponsorID}`
        : `/${matchAdd.params.tournamentID}/sponsors`
    );
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Sponsor Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={matchAdd ? "Add Sponsor" : "Edit Sponsor"}
        formSections={editSponsorForm(context, currentSponsor)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
    dispatch(clearUtilityNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //custom hooks
  let notificationSettings = {
    notifications: [...sponsorNotifications, ...utilityNotifications],
    bannerActions: getSaveBannerActions(saveSponsor, leftNavigation),
    timeoutAction,
  };
  useNotificationNavigation(
    "created",
    sponsorNotifications,
    `/${currentTournament.id}/sponsors/[id]`
  );
  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-sponsor-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-sponsor-create-edit>
  );
};

//export the new namet
export default TournamentSponsorCreateEdit;
