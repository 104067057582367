import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  GSRadioGroup,
  GSScorecard,
  GSSelect,
} from "golfstatus_react_components";
import CourseQuickFilter from "../components/course-quick-filter";
import FacilityQuickFilter from "../components/facility-quick-filter";
import TournamentRoundLineItem from "../components/tournament/tournament-rounds/tournament-round-line-item";
import { getTotalPar, getTotalYardage } from "../helpers/Converters";
import { getDateTimeYMD } from "../helpers/Dates";
import { integerWithCommas } from "../helpers/RegexHelper";

export const RoundNavigationItems = (currentRound) => [
  {
    hero: <TournamentRoundLineItem round={currentRound} hideActions hideCopy />,
    //details: "View and edit the round details.",
    isExternal: false,
    isEdit: true,
    type: "hero",
    url: "details",
  },
  {
    label: "Hole Assignments",
    //details: "Add and manage round hole assignments.",
    isExternal: false,
    url: "hole-assignments?view=2",
  },
  {
    label: "Sponsor Assignments",
    //details: "Add and manage round hole sponsors.",
    isExternal: false,
    url: "hole-sponsors",
  },
  {
    label: "Scorecards",
    //details: "View and update round scoring.",
    isExternal: false,
    url: "scoring",
    hidden: false,
  },
  {
    label: "Documents",
    //details: "View and download round documents.",
    isExternal: false,
    url: "documents",
  },
  {
    label: "Leaderboards & Skins",
    //details: "View and edit round leaderboards.",
    isExternal: false,
    url: "leaderboards",
  },
  {
    label: "Tees",
    //details: "View and edit round tee settings.",
    isExternal: false,
    url: "courses",
  },
  {
    label: "Rules",
    //details: "View and edit round rules.",
    isExternal: false,
    url: "rules",
  },
];

export const getRoundDetailForm = (context, round) => {
  if (round) {
    //General round info
    let generalInfo = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Round Format",
          value: (
            <GSSelect
              onChange={context?.formatChanged}
              selectedOption={context
                ?.getTournamentFormatTypes?.()
                ?.find?.(
                  (t) => t.value.id === context?.getSelectedFormatType?.()?.id
                )}
              options={context?.getTournamentFormatTypes()}
            />
          ),
          customView: true,
          isEditable: true,
          required: true,
        },
        {
          label: "Round Label",
          value: round?.roundName,
          placeholder: "Round Label",
          charCount: (round?.roundName ?? "").length,
          maxLength: 3,
          hintText: "This will replace the round number.",
          onChange: (e) => {
            if (e.target.value?.length <= 3)
              context?.updateCurrentRound?.(e.target.value, "roundName");
          },
          isEditable: true,
          required: false,
        },
        {
          label: "Round Number",
          value:
            round.number ??
            context?.getTournament?.()?.tournamentRounds?.length + 1,
          placeholder: "Round Number",
          type: "number",
          onChange: (e) =>
            context?.updateCurrentRound?.(e.target.value, "number"),
          isEditable: true,
          required: false,
        },
      ],
    };

    //Start Details
    let formatInfo = {
      type: "vertical xx-large-gap",
      title: "Start Details",
      fields: [
        {
          label: "Round Date & Time",
          value: round?.startAt !== "" ? getDateTimeYMD(round?.startAt) : "",
          type: "datetime-local",
          hintText: "Click on the calendar icon to select date.",
          failedValidation: context?.validationFailed,
          onChange: (e) =>
            context?.updateCurrentRound?.(e.target.value, "startAt"),
          isEditable: true,
          required: true,
        },
        {
          label: "Start Type",
          value: (
            <GSRadioGroup
              selectionChanged={(option) => {
                context.updateCurrentRound(option.value, "startType");
              }}
              selectedOption={getStartTypes?.().find?.(
                (st) => st.value === round?.startType
              )}
              options={getStartTypes?.()}
            />
          ),
          customView: true,
          isEditable: true,
          required: true,
        },
      ],
    };

    if (
      round.startType === "interval_start" ||
      round.startType === "two_tee_interval_start"
    ) {
      formatInfo.fields.push({
        label: "Tee Time Intervals",
        value: (
          <GSSelect
            selectedOption={getIntervalOptions()?.find?.(
              (option) => option.value === round.teeTimeInterval
            )}
            placeholder='Select Tee Time Intervals…'
            onChange={(option) => {
              context.updateCurrentRound(option.value, "teeTimeInterval");
            }}
            options={getIntervalOptions()}
          />
        ),
        customView: true,
        isEditable: true,
        failedValidation: context?.validationFailed,
        required: true,
      });
    }

    if (round.startType === "two_tee_interval_start") {
      formatInfo.fields.push({
        label: "Back Tee Offset",
        hintText:
          "This will offset the back nine tee times from the front nine. Shown in minutes.",
        value: round.backTeeTimeOffset,
        onChange: (e) =>
          context?.updateCurrentRound?.(e.target.value, "backTeeTimeOffset"),
        placeholder: "Back Tee Offset",
        isEditable: true,
      });
    }

    if (context?.getSelectedFormatType?.()?.availableHoleSizes?.length > 1) {
      let availableHoleSizes = context
        ?.getSelectedFormatType?.()
        ?.availableHoleSizes?.map((ahs) => ({
          label: `${ahs} Players`,
          value: ahs,
        }));
      generalInfo.fields.push({
        label: "Players Per Hole",
        value: (
          <GSRadioGroup
            selectedOption={availableHoleSizes?.find?.(
              (ahs) => ahs.value === round.holeSize
            )}
            selectionChanged={(option) => {
              if (option.value)
                context?.updateCurrentRound?.(option.value, "holeSize");
            }}
            options={availableHoleSizes}
          />
        ),
        type: "number",
        leftIcon: faMinus,
        rightIcon: faPlus,
        leftIconClick: context?.decrementPlayersPerHole,
        rightIconClick: context?.incrementPlayersPerHole,
        isEditable: false,
        required: true,
      });
    }
    //

    //Facility Info
    let facilityInfo = {
      type: "vertical xx-large-gap",
      title: "Facility Details",
      fields: [
        {
          label: "Facility",
          description:
            "Hit 'ENTER' to search for a facility. Then select one from the list below.",
          value: (
            <FacilityQuickFilter
              selectedFacility={context?.getFacility?.()}
              selectFacility={context?.selectFacility}
              removeFacility={context?.removeFacility}
            />
          ),
          validation: {
            isValid: () => context.hasFacility?.(),
            invalidLabel: "Round Facility is required.",
          },
          failedValidation: context?.validationFailed,
          customView: true,
          isEditable: true,
          required: true,
        },
      ],
    };

    if (context?.hasFacility()) {
      facilityInfo.fields.push({
        label: "Round Course",
        value: (
          <CourseQuickFilter
            selectedFacility={context?.getFacility?.()}
            selectCourse={context?.selectCourse}
            removeCourse={context?.removeCourse}
            teeSelected={context?.addTee}
            teeRemoved={context.removeTee}
            holesSelected={context.holesSelected}
            selectedCourses={context.getSelectedCourses}
          />
        ),
        customView: true,
        isEditable: true,
        required: true,
        failedValidation: context?.validationFailed,
        validation: {
          isValid: () =>
            context.getSelectedCourses?.()?.length > 0 &&
            context?.getRoundTees?.()?.length > 0 &&
            context
              .getSelectedCourses?.()
              ?.reduce?.((total, course) => total + course?.numberOfHoles, 0) <=
              18,
          invalidLabel:
            context
              .getSelectedCourses?.()
              ?.reduce?.((total, course) => total + course?.numberOfHoles, 0) <=
            18
              ? "Round Course and Tees are required"
              : "Course selections can be no longer than 18 holes.",
        },
      });
    }

    let roundForm = [
      { ...generalInfo },
      { ...formatInfo },
      { ...facilityInfo },
    ];
    return roundForm;
  }
};

const getStartTypes = () => {
  return [
    { label: "Shotgun Start", value: "shotgun_start" },
    { label: "Interval Start", value: "interval_start" },
    { label: "Two Tee Interval", value: "two_tee_interval_start" },
  ];
};

const getIntervalOptions = () => {
  return [
    { label: "7 & 8 minutes", value: "7/8" },
    { label: "8 minutes", value: "8" },
    { label: "8/9 minutes", value: "8/9" },
    { label: "9 minutes", value: "9" },
    { label: "10 minutes", value: "10" },
    { label: "12 minutes", value: "12" },
    { label: "15 minutes", value: "15" },
  ];
};

export const getRulesForm = (context) => {
  if (context) {
    let form = [
      ...context?.getData?.()?.map?.((format) => ({
        type: "vertical xx-large-gap",
        fields: [
          {
            label: format.name,
            value: format.description,
          },
          {
            label: "rules",
            value: format.rules,
            placeholder: "Rules",
            isEditable: true,
            type: "text-area",
            onChange: (e) => {
              context.setData(e.target.value, format);
            },
            rows: 20,
          },
        ],
      })),
    ];
    return form;
  }
};

export const getTeeForm = (context) => {
  if (context) {
    const roundCourse = context?.getCourse?.();
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "tee name",
            placeholder: "Tee Name",
            value: context?.getTee?.().name,
            onChange: (e) => context?.updateTee(e.target.value, "name"),
            isEditable: context?.getRound?.()?.state !== "live",
            required: true,
          },
          {
            label: "gender",
            value: (
              <GSRadioGroup
                options={getTeeGenderOptions(context)}
                selectedOption={{ value: context?.getTee?.().gender }}
                selectionChanged={(option) =>
                  context?.updateTee(option.value, "gender")
                }
              />
            ),
            customView: true,
            isEditable: context?.getRound?.()?.state !== "live",
            required: true,
          },
        ],
      },
      {
        title: roundCourse?.course?.name,
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "scorecard",
            value: (
              <GSScorecard
                editable={
                  context?.getRound?.()?.state === "live"
                    ? []
                    : ["yardage", "handicap", "par"]
                }
                splitNines={context?.getSplitNines?.()}
                holes={context.getTeeHoles?.()}
                scorecardChanged={context.updateScorecard}
                scorecardFocused={context.scorecardFocused}
              />
            ),
          },
          {
            label: "tee rating",
            placeholder: "Tee Rating",
            value: context?.getTee?.().rating,
            onChange: (e) => context?.updateTee(e.target.value, "rating"),
            isEditable: context?.getRound?.()?.state !== "live",
          },
          {
            label: "tee slope",
            placeholder: "Tee Slope",
            value: context?.getTee?.().slope,
            onChange: (e) => context?.updateTee(e.target.value, "slope"),
            isEditable: context?.getRound?.()?.state !== "live",
          },
          {
            label: "total par",
            value: getTotalPar(context.getTeeHoles?.()),
            isEditable: false,
          },
          {
            label: "total yardage",
            value: `${integerWithCommas(
              getTotalYardage(context.getTeeHoles?.())
            )} Yards`,
            isEditable: false,
          },
        ],
      },
    ];

    return form;
  }
};

export const getHoleAssignmentForm = (context) => {
  if (context) {
    let form = [
      {
        type: "vertical",
        fields: [
          {
            label: "Assigned Players & Teams",
            value: context.getQuickFilter(),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

const getTeeGenderOptions = (context) => {
  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Both", value: "both" },
    { label: "Unknown", value: "unknown" },
  ];

  const editible = context?.getRound?.()?.state !== "live";
  if (!editible) {
    const selection = context?.getTee?.().gender;
    return options.filter((o) => o.value === selection);
  }
  return options;
};
