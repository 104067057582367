import React, { useEffect, useState } from "react";
import {
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
  GSActionBar,
} from "golfstatus_react_components";
import {
  faCheckCircle,
  faChevronLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-skins-management.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeams,
  selectLoading,
  selectTournamentTeams,
} from "../../../reducers/teamsSlice";
import {
  getLeaderboardSetting,
  getTournamentAddOnTeams,
  getTournamentAddOns,
  saveAddOnteam,
  selectCurrentLeaderboardSetting,
  selectTournamentAddOns,
  selectTournamentAddOnTeams,
  selectLeaderboardLoading,
  selectLeaderboardNotifications,
  clearNotifications,
} from "../../../reducers/leaderboardSlice";
import TournamentTeamItem from "./tournament-team-item";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";

//Name the component
const TournamentLeaderboardSkinsManagement = (props) => {
  const [search, setSearch] = useState("");
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/leaderboards/:leaderboardID/skins-management"
  );
  //selectors
  const tournamentTeams = useSelector(selectTournamentTeams);
  const tournamentAddOns = useSelector(selectTournamentAddOns);
  const tournamentAddOnTeams = useSelector(selectTournamentAddOnTeams);
  const currentLeaderboardSetting = useSelector(
    selectCurrentLeaderboardSetting
  );
  const loadingPlayers = useSelector(selectLoading);
  const loadingLeaderboards = useSelector(selectLeaderboardLoading);
  const leaderboardNotifications = useSelector(selectLeaderboardNotifications);

  useEffect(() => {
    if (match?.params?.tournamentID && match?.params?.leaderboardID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
      dispatch(getTournamentAddOnTeams(match.params.tournamentID));
      dispatch(getTournamentAddOns(match.params.tournamentID));
      dispatch(getLeaderboardSetting(match.params.leaderboardID));
    }
  }, [dispatch, match?.params?.tournamentID, match?.params?.leaderboardID]);

  const markAllIn = () => {
    const addOn = tournamentAddOns.find(
      (ao) => ao.leaderboardTypeSetting.id === currentLeaderboardSetting.id
    );
    navigate(`${addOn.id}/mark-all-in`);
  };

  const markAllOut = () => {
    const addOn = tournamentAddOns.find(
      (ao) => ao.leaderboardTypeSetting.id === currentLeaderboardSetting.id
    );
    navigate(`${addOn.id}/mark-all-out`);
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Mark All In",
        action: markAllIn,
        type: "light-grey",
        buttonIcon: faCheckCircle
      },
      { name: "Mark All Out", action: markAllOut, buttonIcon: faTimesCircle, type: "light-grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Manage Skins`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getAddonPlayers = () => {
    if(tournamentTeams?.length === 0){
      return []
    }
    return tournamentAddOnTeams
      ?.filter(
        (aot) =>
          aot?.addOn?.leaderboardTypeSetting?.id ===
          currentLeaderboardSetting?.id
      )
      ?.map?.((t) => ({
        ...t,
        tournamentTeam: tournamentTeams?.find?.(
          (tt) => tt.id === t?.tournamentTeam?.id
        ),
      }));
  };

  const teamFilter = (teams) => {
    return teams?.filter?.((t) =>
      `${t?.tournamentTeam?.contactName} ${
        t?.tournamentTeam?.name
      } ${t?.tournamentTeam?.players?.map?.((p) => p.name)?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search.toLowerCase?.())
    );
  };

  const getSelectedTeams = () => {
    return tournamentAddOnTeams?.filter?.(
      (aot) =>
        aot?.addOn?.leaderboardTypeSetting?.id ===
          currentLeaderboardSetting?.id && aot.status === "in"
    );
  };

  const itemChecked = (item) => {
    if (item) {
      let update = { ...item, status: "in" };
      dispatch(saveAddOnteam(update));
    }
  };

  const itemUnchecked = (item) => {
    if (item) {
      let update = { ...item, status: "out" };
      dispatch(saveAddOnteam(update));
    }
  };

  const clearSearch = () => {
    setSearch("");
  };

  const manageTeams = () => {
    navigate(`/${match.params.tournamentID}/teams`);
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Manage Who’s in the Skins Game"
        detail="This tournament does not have anyone to put in this skins game."
        actions={[
          {
            title: "Manage Players & Teams",
            onClick: manageTeams,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "Manage Players & Teams",
            onClick: manageTeams,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getInput = () => {
    return {
      placeholder: "Search Players and Teams…",
      search,
      setSearch,
      type: "sticky-side-panel pad"
    };
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header={`Manage ${currentLeaderboardSetting.name}`} />
        {tournamentTeams?.length > 0 ? <SearchBar {...getInput()} /> : null}
        <GSItemList
          isCheckList={true}
          type="vertical selectable"
          items={teamFilter(getAddonPlayers())}
          selectedItems={getSelectedTeams()}
          itemChecked={itemChecked}
          itemUnchecked={itemUnchecked}
          listItem={(item) =>
            item.player ? (
              <div>{item.player.name}</div>
            ) : (
              <TournamentTeamItem {...item.tournamentTeam} />
            )
          }
          emptyMessage={
            search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
          }
          loading={
            loadingPlayers?.includes?.("getTournamentTeams") ||
            loadingLeaderboards?.includes?.("getTournamentAddOnTeams")
          }
          loadingMainText="Loading Teams..."
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const notificationSettings = {
    notifications: leaderboardNotifications,
    timeoutAction,
    defaultBanner: {
      title: getNotificationItemInfo(
        `Selections are automatically saved.`,
        faCheckCircle
      ),
      state: "grey",
    },
  };
  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-leaderboard-skins-management>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-skins-management>
  );
};

//export the new namet
export default TournamentLeaderboardSkinsManagement;
