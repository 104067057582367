import {
  GSQuickFilter,
  validations,
  GSButton,
  GSItemList,
} from "golfstatus_react_components";
import {moneyWithCommas} from "../helpers/RegexHelper"
import TournamentSettingsManagerItem from "../components/tournament/tournament-settings/tournament-settings-manager-item";
import ProgressBar from "../components/progress-bar";
import SearchBar from "../components/search-bar";
import TournamentPackageWatchItem from "../components/tournament/tournament-promotion/tournament-packages/tournament-package-watch-item";

export const getSettingsPages = (tournamentPaymentSettings) => {
  let pages = [
    {
      label: "Donations",
      details: "View donation goals, messaging, and more.",
      url: "organization",
    }
  ];

  if(tournamentPaymentSettings?.donations){
    pages.push({
      label: "Donation Watch Emails",
      details: "Manage who’s notified when donations are made.",
      isExternal: false,
      url: "watch",
      hidden: false,
    })
  }

  if(tournamentPaymentSettings?.invoicesActive){
    pages.push({
      label: "Invoices",
      details: "Manage invoice details, due date, contact information, and more.",
      isExternal: false,
      url: "invoice",
    }, {
      label: "Invoice Watch Emails",
      details: "Manage who is copied on invoiced order email confirmations.",
      isExternal: false,
      url: "customer-invoice-watch",
    })
  }

  pages.push({
    label: "Order Receipts",
    details: "Customize registration order receipts.",
    isExternal: false,
    url: "receipts",
  })

  return [...pages];
};

export const managersForm = (context) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Tournament Organizers",
            value: (
              <GSQuickFilter
                getDefaultSearch={context.getDefaultSearch}
                filteredList={context.getFilteredList()}
                getItem={(item) => (
                  <TournamentSettingsManagerItem
                    resendInvitation={context.resendManagerInvitation}
                    {...item}
                  />
                )}
                itemSelected={context.managerSelected}
                emptySearchList={context.getEmptyListMessage?.()}
              ></GSQuickFilter>
            ),
            customView: true,
          },
          {
            label: "Organization Members",
            value: (
              <GSQuickFilter
                filteredList={context.getFilteredOrgList()}
                getItem={(item) => <TournamentSettingsManagerItem {...item} />}
                itemSelected={context.managerSelected}
              ></GSQuickFilter>
            ),
            customView: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const managerDetailForm = (context, manager) => {
  const { validEmail, validPhone } = validations;
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Full Name",
            placeholder: "Full Name",
            value: manager.name,
            failedValidation: context.validationFailed,
            onChange: (e) => context.updateManager?.(e.target.value, "name"),
            required: true,
            isEditable: true,
          },
          {
            label: "Email Address",
            placeholder: "Email Address",
            value: manager.email,
            validation: {
              isValid: validEmail,
              invalidLabel: "Not a valid email address",
            },
            failedValidation: context.validationFailed,
            onChange: (e) => context.updateManager?.(e.target.value, "email"),
            isEditable: true,
            required: true,
          },
          {
            label: "Phone Number",
            placeholder: "Phone Number",
            value: manager.phone,
            validation: {
              isValid: validPhone,
              invalidLabel: "Not a valid phone number",
            },
            failedValidation: context.validationFailed,
            onChange: (e) => context.updateManager?.(e.target.value, "phone"),
            isEditable: true,
            required: true,
          },
          {
            label: "Organization",
            placeholder: "Organization",
            value: manager.organizationName,
            failedValidation: context.validationFailed,
            onChange: (e) =>
              context.updateManager?.(e.target.value, "organizationName"),
            isEditable: true,
            required: true,
          },
        ],
      },
    ];
    return form;
  }
};



export const paymentSettingsWatchForm = (context, paymentSettings) => {

  if(context){
    let form = [
      getDonationWatchSection(context, paymentSettings),
    ]

    return form
  }
}

export const donationWatchItemForm = (context) => {
  const {validEmail} = validations
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Email Address",
            placeholder: "Email Address",
            value: (
              context?.getData?.()?.email
            ),
            onChange: (e) => {context?.updateData?.(e.target.value, "email")},
            isEditable: true,
            failedValidation: context?.validationFailed,
            onSubmit: context?.submit,
            validation: {
              isValid: validEmail,
              invalidLabel: "Not a valid email"
            },
            required: true
          },
        ],
      },
    ];
    return form;
  }
};



export const defualtWatchItemForm = (context) => {
  const {validEmail} = validations
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Email Address",
            placeholder: "Email Address",
            value: (
              context?.getData?.()?.email
            ),
            onChange: (e) => {context?.updateData?.(e.target.value, "email")},
            isEditable: true,
            required: true,
            failedValidation: context?.validationFailed,
            onSubmit: context?.submit,
            validation: {
              isValid: validEmail,
              invalidLabel: "Not a valid email"
            }
          },
        ],
      },
    ];
    return form;
  }
};

export const customerInvoiceWatchEmailForm = (context) => {
  const {validEmail} = validations;
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Email Address",
            placeholder: "Email Address",
            value: (
              context?.getData?.()?.email
            ),
            onChange: (e) => {context?.updateData?.(e.target.value, "email")},
            isEditable: true,
            required: true,
            failedValidation: context?.validationFailed,
            onSubmit: context?.submit,
            validation: {
              isValid: validEmail,
              invalidLabel: "Not a valid email"
            }
          },
        ],
      },
    ];
    return form;
  }
};

export const getCustomerInvoiceWatchEmailsSection = (context) => {
  let invoiceWatch = {
    type: "vertical large-gap",
    fields: [
      {
        label: "Invoice Watch Emails",
        value: <SearchBar type="no-pad" placeholder="Search By Email Address..." {...context?.getSearch()}></SearchBar>
      },
      {

        value: (
          <GSItemList
            type="vertical small-gap watch-list selectable grey-empty-list rounded-empty-list"
            items={context?.getFilteredWatchEmails()}
            itemSelected={(item) => {context?.editWatchEmail?.(item)}}
            emptyMessage={context?.getEmptyMessage?.()}
            listItem={(item) => (
              <TournamentPackageWatchItem {...item}/>
            )}
          />
        ),
      },
    ],
  };
  return invoiceWatch
}

export const customerInvoiceWatchEmailsForm = (context) => {

  if(context){
    let form = [
      getCustomerInvoiceWatchEmailsSection(context),
    ]

    return form
  }
}

export const paymentSettingsOrganizationForm = (context, paymentSettings) => {

  if(context){
    let form = [
      getOverallSection(context, paymentSettings),

    ]
    if(paymentSettings?.donations){
      form.push(getDonationGoal(context, paymentSettings),
      getDonationPresets(context, paymentSettings))
    }

    return form
  }
}

export const getOverallSection = (context, paymentSettings) => {
  const yesButton =  <GSButton type="green pill secondary restricted-width" title="yes"/>
  const noButton = <GSButton type="light-grey pill secondary restricted-width" title="no"/>

  let viewOnly = {
    type: "vertical xx-large-gap",
    fields:  [
      {
        label: `Donations`,
        description: "Accept donations on this tournament’s event website.",
        value: paymentSettings?.donations ? yesButton  : noButton
      },
    ]
  }

  if(paymentSettings?.donations){
    viewOnly.fields.push({
      label: "Round-up Donations",
      description: "Allow registrants to make a donation by rounding up their purchase total to the nearest dollar amount.",
      value: paymentSettings?.roundUp ? yesButton  : noButton
    })

    if(paymentSettings?.donationMessage)
    {
      viewOnly.fields.push({
        label: "Donation Message",
        value: paymentSettings?.donationMessage,
      })
    }
  }

  return viewOnly
}

export const getDonationGoal = (context, paymentSettings) => {
  const yesButton =  <GSButton type="green pill secondary restricted-width" title="yes"/>
  const noButton = <GSButton type="light-grey pill secondary restricted-width" title="no"/>

  let viewOnly = {
    type: "vertical xx-large-gap",
    title: "Donation Goals",
    fields:  [
      {
        label: `${moneyWithCommas( context?.currentTournament?.totalDonationAmount)} raised of ${moneyWithCommas( paymentSettings?.donationGoal)} donation goal`,
        value: <ProgressBar value={context?.currentTournament?.totalDonationAmount} max={paymentSettings?.donationGoal}></ProgressBar>
      },
      {
        label: "Donation Goal",
        value: moneyWithCommas( paymentSettings?.donationGoal)
      },

      {
        label: "Display Donations",
        description: "Publicly display a running total of the donations this tournament has received.",
        value: paymentSettings?.donationAmountActive ? yesButton  : noButton
      },
    ]
  }

  return viewOnly
}

export const getDonationPresets = (context, paymentSettings) => {
  let viewOnly = {
    type: "horizontal xx-large-gap mobile-vertical full-width-items",
    title: "Preset Donation Values",
    fields: paymentSettings?.customDonationValues?.map?.((cdv, index) => ({
      label: `Donation value ${index + 1}`
      , value: moneyWithCommas(cdv)
    }))
  }

  return viewOnly
}

export const getDonationWatchSection = (context, paymentSettings) => {
  let donationWatch = {
    type: "vertical large-gap",
    fields: [
      {
        label: "Donation Watch Emails",
        value: <SearchBar type="no-pad" placeholder="Search By Email Address..." {...context?.getSearch()}></SearchBar>
      },
      {

        value: (
          <GSItemList
            type="vertical small-gap watch-list selectable grey-empty-list rounded-empty-list"
            items={context?.getFitleredWatchItems()}
            itemSelected={(item) => {context?.editWatchEmail?.(item)}}
            emptyMessage={context?.getEmptyMessage?.()}
            listItem={(item) => (
              <TournamentPackageWatchItem {...item}/>
            )}
          />
        ),
      },
    ],
  };
  return donationWatch
}

export const defaultWatchItemsForm = (context, paymentSettings) => {

  if(context){
    let form = [
      getDefaultWatchItemsSection(context, paymentSettings),
    ]

    return form
  }
}

export const getDefaultWatchItemsSection = (context, paymentSettings) => {
  let donationWatch = {
    type: "vertical large-gap",
    fields: [
      {
        label: "Tournament Watch Emails",
        value: <SearchBar {...context?.getSearch?.()} type="no-pad"/>
      },
      {
        value: (
          <GSItemList
            type="vertical small-gap watch-list selectable grey-empty-list rounded-empty-list"
            items={context?.getFilteredWatchItems()}
            itemSelected={(item) => {context?.editWatchEmail?.(item)}}
            emptyMessage={context?.getEmptyMessage?.()}
            listItem={(item) => (
              <TournamentPackageWatchItem {...item}/>
            )}
          />
        ),
      },
    ],
  };
  return donationWatch
}


export const paymentSettingsInvoiceForm = (context, paymentSettings) => {

  if(context){
    let form = [
      getInvoiceSection(context, paymentSettings),
      getInvoiceMailingSection(context, paymentSettings),
      getInvoiceContactSection(context, paymentSettings),
      getInvoiceAdditionalDetailsSection(context, paymentSettings)
    ]

    return form
  }
}


export const getInvoiceSection = (context, paymentSettings) => {
  const customerInvoice = context.getData()

  let dueDateStr = "";
  if (context?.currentTournament?.startAt) {
    const dueDate = new Date(context.currentTournament.startAt);
    dueDate.setDate(dueDate.getDate()-3);
    dueDateStr = dueDate.toISOString().split('T')[0];
  }

  let invoice = {
    type: "vertical xx-large-gap",
    fields:  [
      {
        label: "Payable to",
        description: "This is who the registrant should make the check out to.",
        placeholder: "e.g. Organization Name",
        value: customerInvoice?.payable,
        onChange: (e) => context.updateData(e.target.value, "payable"),
        isEditable: true,
        required: true,
      },
      {
        label: "Due Date",
        placeholder: "Due Date",
        value: customerInvoice?.dueDate ? customerInvoice?.dueDate : dueDateStr,
        type: "date",
        onChange: (e) => context.updateData(e.target.value, "dueDate"),
        isEditable: true,
        required: true,
        failedValidation: context.validationFailed
      },
    ]
  }
  return invoice
}

export const getInvoiceMailingSection = (context, paymentSettings) => {
  const customerInvoice = context.getData()

  let invoice = {
    type: "vertical xx-large-gap",
    title: "Mailing Details",
    fields:  [
      {
        label: "Recipient Name",
        description: "This is who the registrant should mail the check to.",
        placeholder: "e.g. Organization Name",
        value: customerInvoice?.name,
        onChange: (e) => context.updateData(e.target.value, "name"),
        isEditable: true,
        required: true,
      },
      {
        label: "Address",
        placeholder: "e.g. 2604 Washington Rd",
        value: customerInvoice?.addressLine1,
        onChange: (e) => context.updateData(e.target.value, "addressLine1"),
        isEditable: true,
        required: true,
      },
      {
        label: "Address Line 2",
        placeholder: "e.g. Suite 72",
        value: customerInvoice?.addressLine2,
        onChange: (e) => context.updateData(e.target.value, "addressLine2"),
        isEditable: true
      },
      {
        label: "City, State & Postal Code",
        placeholder: "e.g. Augusta, GA 30904",
        value: customerInvoice?.addressLine3,
        onChange: (e) => context.updateData(e.target.value, "addressLine3"),
        isEditable: true,
        required: true,
      },
      {
        label: "Country",
        placeholder: "e.g. United States",
        value: customerInvoice?.addressLine4,
        onChange: (e) => context.updateData(e.target.value, "addressLine4"),
        isEditable: true,
      },
    ]
  }
  return invoice
}

export const getInvoiceContactSection = (context, paymentSettings) => {
  const {validEmail, validPhone} = validations
  const customerInvoice = context.getData()

  let invoice = {
    type: "vertical xx-large-gap",
    title: "Contact Details",
    fields:  [
      {
        label: "Full Name",
        placeholder: "Full Name",
        value: customerInvoice?.contactName ? customerInvoice?.contactName: "",
        onChange: (e) => context.updateData(e.target.value, "contactName"),
        isEditable: true,
        required: true,
        failedValidation: context.validationFailed
      },
      {
        label: "Email Address",
        placeholder: "Email Address",
        value: customerInvoice?.contactEmail ? customerInvoice?.contactEmail : "",
        onChange: (e) => context.updateData(e.target.value, "contactEmail"),
        isEditable: true,
        required: true,
        failedValidation: context.validationFailed,
        validation: {
          isValid: validEmail,
          invalidLabel: "Not a valid email"
        }
      },
      {
        label: "Phone Number",
        placeholder: "Phone Number",
        value: customerInvoice?.contactPhone ? customerInvoice?.contactPhone : "",
        onChange: (e) => context.updateData(e.target.value, "contactPhone"),
        isEditable: true,
        required: true,
        failedValidation: context.validationFailed,
        validation: {
          isValid: validPhone,
          invalidLabel: "Not a valid phone"
        }
      },
    ]
  }
  return invoice
}

export const getInvoiceAdditionalDetailsSection = (context, paymentSettings) => {
  const customerInvoice = context.getData()

  let invoice = {
    type: "vertical xx-large-gap",
    title: "Additional Details",
    fields:  [
      {
        label: "Invoice Memo",
        placeholder: "Invoice Memo",
        maxLength: 150,
        charCount: customerInvoice?.memo?.length,
        value: customerInvoice?.memo,
        onChange: (e) => context.updateData(e.target.value, "memo"),
        type:"text-area",
        rows: 5,
        isEditable: true,
      },
    ]
  }
  return invoice
}