import React from "react";
import {
  GSForm,
  GSSidePanelPage,
  GSQuickFilter,
  GSEmptyList,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faInfoCircle,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-form-create-edit.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  createPackageForm,
  createPackageFormItem,
  getTournamentForm,
  getTournamentForms,
  getTournamentPackage,
  getTournamentPackageFormFields,
  savePackageForm,
  savePackageFormItem,
  selectCurrentTournamentPackageForm,
  selectCurrentTournamentPackageFormFileds,
  selectCurrentTournamentPackageForms,
  selectNotifications,
  setCurrentCustomField,
  setCurrentPackageForm,
  setCurrentPackageForms,
  setNotifications,
} from "../../../../reducers/packageSlice";
import { useSelector } from "react-redux";
import { getPackageFormCreate } from "../../../../forms/TournamentPackageForm";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { getNotificationItemInfo } from "../../../../helpers/Converters";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useFormValidation } from "../../../../hooks/formHooks";
import TournamentPackageCustomFieldItem from "./tournament-package-custom-field-item";

//Name the component
const TournamentPackageFormCreateEdit = (props) => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/forms/:formID"
  );

  //selectors
  const currentPackageForm = useSelector(selectCurrentTournamentPackageForm);
  const currentPackageFormFields = useSelector(
    selectCurrentTournamentPackageFormFileds
  );
  const currentTournament = useSelector(selectCurrentTournament);
  const packageNotifications = useSelector(selectNotifications);
  const currentPackageForms = useSelector(selectCurrentTournamentPackageForms);

  //context
  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateForm = (value, property) => {
    setIsValid(true);
    let update = { ...currentPackageForm };
    update[property] = value;
    dispatch(setCurrentPackageForm(update));
  };

  context.isEdit = () => {
    return match?.params?.formID !== "add";
  };

  context.submit = () => {
    if (isValid) {
      save();
    }
  };

  //custom hooks
  useNotificationNavigation(
    "created",
    packageNotifications,
    `/${match.params.tournamentID}/promotion/packages/${match?.params?.packageID}/forms/[id]`,
    "Package Form"
  );

  //effects
  useEffect(() => {
    if (match?.params?.formID !== "add") {
      dispatch(getTournamentForm(match.params.formID));
      dispatch(getTournamentPackageFormFields(match.params.formID));
    }
  }, [dispatch, match?.params?.formID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentForms(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  //component functionality
  const addField = () => {
    dispatch(clearNotifications());
    dispatch(setCurrentCustomField({}));
    navigate("add");
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentPackageForm };
    update.tournament = currentTournament;
    update.active = true;
    if (update.id) {
      dispatch(savePackageForm(update));
      saveAllItems(update);
    } else {
      createTournamentPackage(update);
    }
  };

  const createTournamentPackage = (update) => {
    dispatch(createPackageForm(update)).then((response) => {
      if (response?.payload) {
        saveAllItems(response.payload);
      }
    });
  };

  const saveAllItems = (form) => {
    setUnsaved(false);
    setIsValid(false);
    currentPackageFormFields.forEach?.((pff) => {
      let update = { ...pff };
      update.registrationFieldSet = form;
      if (update.id) {
        dispatch(savePackageFormItem(update));
      } else {
        dispatch(createPackageFormItem(update));
      }
    });
  };

  const remove = () => {
    navigate("remove");
  };

  const formIsInPackage = () => {
    return currentPackageForms
      ?.map?.((f) => f.registrationFieldSetKey)
      ?.includes?.(match?.params?.formID);
  };

  const getDrawerActions = () => {
    let actions = [];
    if (isValid || match?.params?.formID === "add") {
      actions.push({
        name: match?.params?.formID === "add" ? "Save & Continue" : "Save",
        action: save,
        isDisabled: !isValid,
        type: "black",
      });
      actions.push({
        name: "Cancel",
        action: leftNavigation,
        type: "grey",
      });
    } else if (!formIsInPackage()) {
      actions.push({
        name: "Add To package",
        buttonIcon: faPlusCircle,
        action: addToPackage,
        type: "light-grey",
      });
      actions.push({ name: "Delete Form", action: remove, type: "transparent red" });
    } else {
      actions.push({
        name: "Done",
        action: leftNavigation,
        type: "black",
      });
      
    }
    return actions;
  };

  const addToPackage = () => {
    dispatch(
      setCurrentPackageForms([
        ...currentPackageForms,
        {
          registrationFieldSetKey: match?.params?.formID,
          occurrence: "per_player",
          tempID: currentPackageForms.length,
        },
      ])
    );
    dispatch(
      setNotifications([
        {
          header: getNotificationItemInfo(
            `The form has been added to you package, please confirm the occurrence and save.`,
            faInfoCircle
          ),
          result: "form added",
          timeout: 5000,
          state: "grey",
        },
      ])
    );
    navigate(
      `/${match.params.tournamentID}/promotion/packages/${match?.params?.packageID}/forms`
    );
  };

  const leftNavigation = () => {
    if (isValid && bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(
      `/${match.params.tournamentID}/promotion/packages/${match?.params?.packageID}/forms`
    );
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: "Form Details",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`Add & Manage Form Questions`}
        detail="This form does not have any questions."
      />
    );
  };

  const itemSelected = (item) => {
    dispatch(clearNotifications());
    navigate(item.id);
  };

  context.getPackageWatchList = () => {
    return (
      <GSQuickFilter
        filteredList={currentPackageFormFields}
        itemSelected={itemSelected}
        getItem={(item) => <TournamentPackageCustomFieldItem {...item}/>}
        emptySearchList={getEmptyListMessage()}
        searchListType="grey-empty-list rounded-empty-list"
      ></GSQuickFilter>
    );
  };

  context.getFieldActions = () => {
    return [
      {
        buttonTitle: "Add Question",
        actionIcon: faPlus,
        actionClick: addField,
        type: "black no-wrap",
      },
    ]
  }

  const getFormTitle = () => {
    return {
      formTitle: match?.params?.formID === "add" ? "Add Form" : "Edit Form",
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormTitle()}
        formSections={getPackageFormCreate(context, currentPackageForm)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-form-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-form-create-edit>
  );
};

//export the new namet
export default TournamentPackageFormCreateEdit;
