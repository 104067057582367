import {
  faBolt,
  faCog,
  faPlus,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import {
  getTournamentFlights,
  selectFlightLoading,
  selectTournamentFlights,
  updateCurrentFlight,
} from "../../../reducers/flightSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import TournamentFlightLineItem from "./tournament-flight-line-item";

import "./tournament-flights.scss";

const TournamentFlights = (props) => {
  const [search, setSearch] = useState("");

  const match = useMatch("/:tournamentID/flighting/*");
  const navigate = useNavigate()

  const tournamentFlights = useSelector(selectTournamentFlights);
  const currentTournament = useSelector(selectCurrentTournament);
  const loading = useSelector(selectFlightLoading)

  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentFlights(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const addFlight = () => {
    dispatch(updateCurrentFlight({}))
    navigate("add")
  }

  const flightSettings = () => {
    navigate("settings")
  }

  const autoAssign = () => {
    navigate("assign")
  }

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Add Flight",
        type: "black mobile-icon",
        actionClick: addFlight,
        actionIcon: faPlus,
      },
      {
        id: 1,
        type: "light-grey mobile-icon",
        actionClick: flightSettings,
        actionIcon: faCog,
      },
    ];
  };

  const getSearchActions = () => {
    if(currentTournament.preflight)
    {
      return [
        {
          buttonTitle: "Assign Flights",
          actionClick: autoAssign,
          actionIcon: faBolt,
          type: "light-grey mobile-icon"
        }
      ];
    }
    return []
  };

  const clearSearch = () => {
    setSearch("")
  }

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={clearSearch}
        placeholder="Search Flights..."
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></GSInput>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Add & Manage Flights"
        detail="Flights are grouping of golfers who are competing against one another rather than against the entire field."
        actions={[
          {
            title: "Add Flight",
            buttonIcon: faPlus,
            onClick: addFlight,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the flight you’re looking for…"
        detail="Try changing the search term or add a new flight."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "Add flight",
            onClick: addFlight,
            type: "black",
            buttonIcon: faPlus
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getFlights = () => {
    return tournamentFlights?.reduce((flights, flight) => {
      const flightIndex = flights.findIndex?.(f => f.position === flight.position)
      if(flightIndex === -1)
      {
        flights?.push?.({...flight, preflights: [flight]});
      }
      else{
        flights[flightIndex].preflights = [...(flights?.[flightIndex]?.preflights ?? []), flight]
      }
      return flights;
    }, []);
  };

  const getFilteredFlights = () => {
    return getFlights()?.filter?.((tf) => tf.name?.toLowerCase?.()?.includes(search.toLowerCase?.()));
  };

  const flightSelected = (item) => {
    if(isSidePanelOpen()){
      navigate(`/${match?.params?.tournamentID}/flighting`)
      return
    }
    navigate(item.id)
  }

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical selectable flights"
        items={getFilteredFlights()}
        listItem={(item) => <TournamentFlightLineItem {...item}/>}
        itemSelected={flightSelected}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={loading.includes("getTournamentFlights")}
        loadingMainText="Loading Flights..."
      ></GSItemList>
    );
  };

  return (
    <tournament-flights>
      <GSListPage
        title="Flights"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-flights>
  );
};

export default TournamentFlights;
