import React from "react";
import { GSItemList, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-details.scss";
import { getFlightDetailPages } from "../../../forms/FlightingForm";
import NavigationListItem from "../../navigation-list-item";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentFlight,
  getTournamentFlights,
  selectCurrentTournamentFlight,
  selectFlightLoading,
  selectTournamentFlights
} from "../../../reducers/flightSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getTournamentLeaderboardSettings,
  selectTournamentLeaderboardSettings,
} from "../../../reducers/leaderboardSlice";

//Name the component
const TournamentFlightDetails = (props) => {
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/flighting/:flightID");
  const dispatch = useDispatch();

  const currentFlight = useSelector(selectCurrentTournamentFlight);
  const tournamentLeaderboardSettings = useSelector(
    selectTournamentLeaderboardSettings
  );
  const tournamentFlights = useSelector(selectTournamentFlights);
  const loading = useSelector(selectFlightLoading)
  
  useEffect(() => {
    if (match?.params?.tournamentID && match?.params?.flightID) {
      dispatch(getTournamentFlight(match.params.flightID));
      dispatch(getTournamentLeaderboardSettings(match.params.tournamentID));
    }
    return () => {
      dispatch(getTournamentFlights(match.params.tournamentID));
    };
  }, [dispatch, match?.params?.tournamentID, match?.params?.flightID]);

  const removeFlight = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete Flight",
        action: removeFlight,
        type: "transparent red",
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Flight Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const pageSelected = (page) => {
    navigate(page.url);
  };

  const getLeaderboards = () => {
    let flights = tournamentFlights.filter(
      (tf) => tf.position === currentFlight.position
    );
    return tournamentLeaderboardSettings
      ?.filter?.((ls) => ls.flighted)
      ?.map((ls) => ({
        ...ls,
        flight: flights.find((f) => f.leaderboardTypeSetting?.id === ls.id),
      }));
  };

  const getCurrentFlight = () => {
    let flight = {...tournamentFlights?.find?.(tf => tf.id === currentFlight.id)}
    flight.preflights = tournamentFlights.filter(tf => tf.position === currentFlight.position)
    return flight
  }

  const getContent = () => {
    return (
      <gs-form>
        <GSItemList
          type="vertical selectable"
          itemSelected={pageSelected}
          items={getFlightDetailPages(
            getCurrentFlight(),
            getLeaderboards(),
            match?.params?.tournamentID
          )}
          listItem={(page) => <NavigationListItem page={page} />}
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    //name the component tag
    <tournament-flight-details>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flight-details>
  );
};

//export the new namet
export default TournamentFlightDetails;
