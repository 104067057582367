import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//import scss
import "./tournament-settings-donation-watch-email.scss";
import { useMatch, useNavigate } from "react-router-dom";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { useFormDataValidation } from "../../../hooks/formHooks";
import { useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  createDonationWatchItem,
  getTournament,
  getTournamentDonationWatchItem,
  selectCurrentDonationWatchItem,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCurrentDonationWatchItem,
  updateDonationWatchItem,
} from "../../../reducers/tournamentSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { donationWatchItemForm } from "../../../forms/SettingsForms";

//Name the component
const TournamentSettingsDonationWatchEmail = (props) => {
  const notifications = useSelector(selectTournamentNotifications);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentWatchItem = useSelector(selectCurrentDonationWatchItem);
  const currentTournament = useSelector(selectCurrentTournament);

  const match = useMatch("/:tournamentID/settings/payments/watch/:watchID");

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: currentWatchItem,
    setData: setCurrentDonationWatchItem,
  });

  useEffect(() => {
    if (match?.params?.watchID !== "add") {
      dispatch(getTournamentDonationWatchItem(match.params.watchID));
    }
  }, [match?.params?.watchID, dispatch]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournament(match.params.tournamentID));
    }
  }, [match?.params?.tournamentID, dispatch]);

  useNotificationNavigation(
    "created",
    notifications,
    -1,
    "Donation Watch Item"
  );

  const save = () => {
    setIsValid(true);
    let update = { ...currentWatchItem };
    update.tournament = currentTournament;
    if (match.params.watchID === "add") {
      dispatch(createDonationWatchItem(update));
    } else {
      dispatch(updateDonationWatchItem(update));
    }
  };

  context.submit = () => {
    if (isValid) {
      save();
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        action: save,
        isDisabled: !isValid,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];

    if (match.params?.watchID !== "add") {
      actions.push({
        name: "Delete Email",
        action: () => {
          navigate("delete")
        },
        type: "transparent red",
      });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1)
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Email Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle={match?.params?.watchID !== "add" ? "Edit Email" : "Add Email"} formSections={donationWatchItemForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-settings-donation-watch-email>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-donation-watch-email>
  );
};

//export the new namet
export default TournamentSettingsDonationWatchEmail;
