import "./tournament-settings-remove.scss";
import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteOrganizationManager,
  getOrganizationManager,
  selectManager,
  selectUsersNotifications,
} from "../../../reducers/userSlice";
import {
  getRemoveTitle,
  removeItemForm,
} from "../../../forms/DeleteForm";
import TournamentSettingsManagerItem from "./tournament-settings-manager-item";
import { useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";

//Name the component
const TournamentSettingsRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/settings/managers/:managerType/:managerID/remove");

  const currentManager = useSelector(selectManager);

  const notifications = useSelector(selectUsersNotifications);

  useEffect(() => {
    if (match?.params?.managerID) {
      dispatch(getOrganizationManager(match.params.managerID));
    }
  }, [dispatch, match?.params?.managerID]);


  useNotificationNavigation("removed", notifications, -2);

  const removeManager = () => {
    setIsValid(false)
    dispatch(deleteOrganizationManager(match.params.managerID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removeManager,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1, {replace: true});
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Tournament Manager`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Tournament Manager")}
        formSections={removeItemForm(
          currentManager.name,
          <TournamentSettingsManagerItem {...currentManager} hideInvitation/>,
          "delete",
          `Are you sure you want to delete ${currentManager.name} as a Tournament Manager?`
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications})

  return (
    //name the component tag
    <tournament-settings-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-remove>
  );
};

//export the new namet
export default TournamentSettingsRemove;
