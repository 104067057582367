import React from "react";
import "./custom-field-option-line-item.scss";

import { GSInfoGroup, GSButton } from "golfstatus_react_components";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const CustomFieldOptionLineItem = (props) => {
  const {
    value,
    removeOption
  } = props;
  
  const getInfoGroup = () => {
    let item = {
      fit: "stretch",
      sections: [
        {
          sectionItems: [
            { type: "secondary", value: value },
          ],
        },
      ],
    };

    let remove = {
      sections: [
        {
          sectionItems: [
            { type: "primary", value: <GSButton buttonIcon={faTimesCircle} onClick={(e) => {removeOption?.(props); e?.stopPropagation?.()}} /> },
          ],
        },
      ],
    }

    return [
      {
        ...item
      },
      {
         ...remove
      },
    ];
  };
  return (
    <custom-field-option-line-item>
      <GSInfoGroup type="list-item grey x-large-pad" dataGroups={getInfoGroup()} />
    </custom-field-option-line-item>
  );
};

export default CustomFieldOptionLineItem;
