import { Route } from "react-router-dom"
import TournamentSponsors from "../../components/tournament/tournament-sponsorships/tournament-sponsors";
import TournamentSponsorCreateEdit from "../../components/tournament/tournament-sponsorships/tournament-sponsor-create-edit";
import TournamentSponsorsCreateEditImage from "../../components/tournament/tournament-sponsorships/tournament-sponsors-create-edit-image";
import TournamentSponsorDetail from "../../components/tournament/tournament-sponsorships/tournament-sponsor-detail";
import TournamentSponsorRemove from "../../components/tournament/tournament-sponsorships/tournament-sponsor-remove";
import TournamentSponsorHoleAssignment from "../../components/tournament/tournament-sponsorships/tournament-sponsor-hole-assignment";
import TournamentSponsorExports from "../../components/tournament/tournament-sponsorships/tournament-sponsor-exports";

export const getTournamentSponsorsRoutes = () => {
  return (
    <Route path="sponsors" element={<TournamentSponsors />}>
            <Route
              path=":sponsorID"
              element={<TournamentSponsorDetail />}
            ></Route>
            <Route
              path=":sponsorID/holes"
              element={<TournamentSponsorHoleAssignment />}
            ></Route>
            <Route
              path=":sponsorID/remove"
              element={<TournamentSponsorRemove />}
            ></Route>
            <Route
              path=":sponsorID/edit"
              element={<TournamentSponsorCreateEdit />}
            ></Route>
            <Route
              path=":sponsorID/edit/image"
              element={<TournamentSponsorsCreateEditImage />}
            ></Route>
            <Route path="add" element={<TournamentSponsorCreateEdit />}></Route>
            <Route
              path="documents"
              element={<TournamentSponsorExports />}
            ></Route>
            <Route
              path="add/image"
              element={<TournamentSponsorsCreateEditImage />}
            ></Route>
            <Route
              path="add/image-mobile"
              element={<TournamentSponsorsCreateEditImage isMobile={true} />}
            ></Route>
          </Route>
  )
}