import React, { useEffect, useMemo } from "react";
import "./tournament-round-scoring.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  faArrowCircleUp,
  faCheckCircle,
  faChevronLeft,
  faCog,
  faSearch,
  faTimesCircle,
  faArrowCircleDown,
  faUndoAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSActionBar,
  GSEmptyList,
  GSInput,
  GSItemList,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  activateScorecards,
  clearRoundNotifications,
  finalizeScorecards,
  getRound,
  getRoundHoleAssignments,
  getRoundScorecards,
  lockScoring,
  selectCurrentRound,
  selectCurrentRoundHoleAssignments,
  selectCurrentRoundScorecards,
  selectRoundNotifications,
  selectLoading as tournamentRoundLoading,
  setCurrentRound,
  undoFinalizeScorecards,
  updateRound,
  saveRoundHoleAssignment,
  saveMissingRoundHoleAssignment,
  saveRound,
} from "../../../../reducers/roundSlice";
import {
  getTournamentTeams,
  selectLoading,
  selectTournamentTeams,
} from "../../../../reducers/teamsSlice";
import { useState } from "react";
import { tournamentRounds } from "../../../../app/api";
import {
  clearLiveTournamentRounds,
  selectScoringGroup,
  setScoringGroup,
  setValidatedScorecard,
} from "../../../../reducers/scorecardSlice";
import TournamentRoundScoringPlayerItem from "./tournament-round-scoring-player-Item";
import TournamentRoundScoringHoleAssignmentItem from "./tournament-round-scoring-hole-assignment-item";
import TournamentRoundScoringTeamItem from "./tournament-round-scoring-team-item";
import {
  saveTournament,
  selectCurrentTournament,
  updateCurrentTournamentRound,
  downloadTournamentDocument,
} from "../../../../reducers/tournamentSlice";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import {
  getNotificationItemInfo,
  getRoundName,
} from "../../../../helpers/Converters";

const TournamentRoundScoring = (props) => {
  //state
  const [search, setSearch] = useState("");

  //hooks

  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/scoring/*");
  const navigate = useNavigate();

  //selectors
  const currentTournament = useSelector(selectCurrentTournament);
  const notifications = useSelector(selectRoundNotifications);
  const currentRound = useSelector(selectCurrentRound);
  const tournamentTeams = useSelector(selectTournamentTeams);
  const scorecards = useSelector(selectCurrentRoundScorecards);
  const loadingTeams = useSelector(selectLoading);
  const holeAssignments = useSelector(selectCurrentRoundHoleAssignments);
  const group = useSelector(selectScoringGroup);
  const roundLoading = useSelector(tournamentRoundLoading);

  //memos
  const scorecardTeamKeys = useMemo(
    () =>
      scorecards?.flatMap?.((s) => s.teams)?.map?.((t) => t.tournamentTeamKey),
    [scorecards]
  );

  const teamsWithoutKeys = useMemo(() => {
    let teams = tournamentTeams
      ?.filter?.((team) => !scorecardTeamKeys.includes(team.id))
      ?.map?.((t) => t.id);
    return teams;
  }, [scorecardTeamKeys, tournamentTeams]);

  const holeAssignmentsWithoutKeys = useMemo(() => {
    const teamsAssignedToHoles = holeAssignments.flatMap?.(ha => ha.tournamentTeams).map(team => team.id)
    return teamsAssignedToHoles.length === scorecardTeamKeys.length
  }, [holeAssignments, scorecardTeamKeys])

  //effects
  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundScorecards(match.params.roundID));
    dispatch(getRoundHoleAssignments(match.params.roundID));
  }, [dispatch, match.params.roundID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
    }
    return () => {
      dispatch(clearRoundNotifications());
    };
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    return () => {
      dispatch(clearRoundNotifications());
    };
  }, [dispatch]);

  //empty lists

  const getEmptyListMessage = () => {
    let actions = [
      {
        title: "View Hole Assignments",
        onClick: goToHoleAssignments,
        type: "light-grey",
      },
    ];
    if (currentTournament.state === "active") {
      if (currentRound.state === "live") {
        actions.unshift({
          title: "Generate Scorecards",
          onClick: createScorecards,
          type: "black",
        });
      }
      else {
        actions.unshift({
          title: "Start Round",
          onClick: createScorecards,
          type: "green",
        });
      }

    } else {
      actions.push({
        title: "Activate Tournament",
        onClick: () => {
          dispatch(saveTournament({ ...currentTournament, state: "active" }));
        },
        type: "green",
      });
    }
    return (
      <GSEmptyList
        title="View & Edit Scorecards"
        detail={
          currentTournament.state === "active"
            ? "Scorecards allow teams to score via the GolfStatus App. Scores are editable and adjustments will be reflected in the app and on all leaderboards."
            : "Your tournament must be active to create scorecards. Would you like to activate it now?"
        }
        actions={actions}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the scorecard you’re looking for…"
        detail="Try changing the search term or check the hole assignments to make sure they’re assigned."
        actions={[
          {
            title: "Clear All",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "View Hole Assignments",
            onClick: goToHoleAssignments,
            type: "black",
          },
        ]}
      />
    );
  };
  // scorecard lists

  const getFilteredTeams = () => {
    if (scorecards?.length === 0) {
      return [];
    }
    let filteredTeams = tournamentTeams.filter?.((t) =>
      `${t?.contactName} ${t?.name} ${t?.players
        ?.map?.((p) => p.name)
        ?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search?.toLowerCase?.())
    );

    let teamsWithScorecards = filteredTeams?.map?.((ft) => ({
      ...ft,
      liveTournamentScorecard: scorecards?.find?.((sc) =>
        sc?.teams?.map?.((t) => t.tournamentTeamKey)?.includes?.(ft.id)
      ),
    }));

    return teamsWithScorecards;
  };

  const getHoleAssignments = () => {
    let teams = getFilteredTeams();
    return holeAssignments
      ?.map?.((ha) => ({
        ...ha,
        tournamentTeams: ha.tournamentTeams
          ?.map?.((tt) => teams.find((t) => t.id === tt.id))
          ?.filter?.((mt) => mt?.id),
      }))
      ?.filter((mha) => mha.tournamentTeams?.length > 0);
  };

  const getTeamScores = () => {
    if (group.value === 1) {
      return (
        <GSItemList
          type="vertical"
          items={getFilteredTeams()}
          emptyMessage={
            search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
          }
          loading={roundLoading.includes("activateScorecards")}
          loadingMainText="Loading teams, This could take a minute..."
          listItem={(item) => (
            <TournamentRoundScoringPlayerItem
              {...item}
              search={search}
              selectPlayer={(player) => {
                editPlayerScore(item, player);
              }}
            />
          )}
        />
      );
    }
    return (
      <GSItemList
        type="selectable vertical"
        items={getFilteredTeams()}
        itemSelected={editTeamScore}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={
          loadingTeams.includes("getTournamentTeams") ||
          roundLoading.includes("activateScorecards")
        }
        loadingMainText="Loading teams..."
        listItem={(item) => <TournamentRoundScoringTeamItem {...item} />}
      />
    );
  };

  const getHoleAssignmentScores = () => {
    return (
      <GSItemList
        type="selectable vertical"
        items={getHoleAssignments()}
        itemSelected={editHoleAssignment}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={
          loadingTeams.includes("getTournamentTeams") ||
          roundLoading.includes("activateScorecards")
        }
        loadingMainText="Loading teams..."
        listItem={(item) => (
          <TournamentRoundScoringHoleAssignmentItem
            {...item}
            editScore={(team) => {
              if (team?.liveTournamentScorecard?.id) {
                editScore(team);
              } else {
                dispatch(saveRoundHoleAssignment(item)).then(() => {
                  dispatch(getRoundScorecards(match.params.roundID));
                });
              }
            }}
          />
        )}
      />
    );
  };

  const getScorecards = () => {
    return group.value === 3 ? getHoleAssignmentScores() : getTeamScores();
  };

  //navigation

  const goToHoleAssignments = () => {
    navigate(`../${match.params?.roundID}/hole-assignments?view=1`);
  };

  const uploadScores = () => {
    dispatch(setValidatedScorecard({}));
    navigate("upload");
  };

  const settings = () => {
    navigate("settings");
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const editTeamScore = (team) => {
    dispatch(clearLiveTournamentRounds());
    if (team?.liveTournamentScorecard?.id) {
      navigate(`${team.liveTournamentScorecard.id}?teamID=${team.id}`);
    } else {
      goToHoleAssignments();
    }
  };

  const editPlayerScore = (team, player) => {
    dispatch(clearLiveTournamentRounds());
    if (team?.liveTournamentScorecard?.id) {
      navigate(`${team.liveTournamentScorecard.id}?playerID=${player.id}`);
    }
  };

  const editScore = (team) => {
    dispatch(clearLiveTournamentRounds());
    if (team?.liveTournamentScorecard?.id) {
      navigate(`${team.liveTournamentScorecard.id}`);
    }
  };

  const editHoleAssignment = (holeAssignment) => {
    if (holeAssignment?.tournamentTeams) {
      editScore(holeAssignment?.tournamentTeams[0]);
    } else {
      dispatch(saveRoundHoleAssignment(goToHoleAssignments));
    }
  };

  //component functionality
  const clearSearch = () => {
    setSearch("");
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications([]));
  };

  const getNavigation = () => {
    return {
      title: `Round ${getRoundName(currentRound)} Scorecards `,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const createScorecards = () => {
    dispatch(activateScorecards(match.params.roundID));
  };

  const downloadScores = () => {
    let download = {
      url: `${tournamentRounds}/${match.params.roundID}/scorecards-export`,
      name: `Round ${currentRound.number}-scores`,
      ext: ".xlsx",
    };
    dispatch(downloadTournamentDocument(download));
  };

  const toggleLock = () => {
    dispatch(lockScoring(match.params.roundID)).then(() => {
      let update = { ...currentRound };
      update.scoringLocked = !currentRound.scoringLocked;
      dispatch(updateRound(update));
      dispatch(updateCurrentTournamentRound(update));
      dispatch(setCurrentRound(update));
    });
  };

  const mobileScoringOff = () => {
    dispatch(finalizeScorecards(match.params.roundID)).then(() => {
      let update = { ...currentRound };
      update.state = "final";
      dispatch(updateRound(update));
      dispatch(updateCurrentTournamentRound(update));
      dispatch(setCurrentRound(update));
    });
  };

  const mobileScoringOn = () => {
    dispatch(undoFinalizeScorecards(match.params.roundID)).then(() => {
      let update = { ...currentRound };
      update.state = "live";
      dispatch(updateRound(update));
      dispatch(updateCurrentTournamentRound(update));
      dispatch(setCurrentRound(update));
    });
  };

  //drawer
  const getDrawerActions = () => {
    if (scorecards.length === 0) {
      return [];
    }
    let actions = [];
    if (currentRound.state === "live") {
      actions.push({
        name: currentRound.scoringLocked
          ? "Start Live Scoring"
          : "End Live Scoring",
        action: toggleLock,
        type: "light-grey no-wrap",
      });
    }
    if (currentRound.state !== "ready") {
      actions.unshift({
        name:
          currentRound.state === "final"
            ? "Reactivate Round"
            : "Finalize Round",
        buttonIcon: currentRound.state === "final" ? faUndoAlt : faCheckCircle,
        action:
          currentRound.state === "final" ? mobileScoringOn : mobileScoringOff,
        type:
          currentRound.state === "final"
            ? "light-grey no-wrap"
            : "green no-wrap",
      });
    }
    return actions;
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //content
  const getPageActions = () => {
    if (scorecards.length === 0) {
      return [];
    }
    let actions = [
      {
        buttonTitle: "Upload",
        actionIcon: faArrowCircleUp,
        actionClick: uploadScores,
        type: "light-grey no-wrap mobile-icon",
      },
      {
        buttonTitle: "Download",
        actionIcon: faArrowCircleDown,
        actionClick: downloadScores,
        type: "light-grey no-wrap mobile-icon",
      },
      {
        actionIcon: faCog,
        actionClick: settings,
        type: "no-wrap light-grey",
      },
    ];
    return actions;
  };

  const getGroup = () => {
    if (currentRound?.numberOfPlayers === 1) {
      return [
        { label: "Players", value: 2 },
        { label: "Assignments", value: 3 },
      ];
    } else if (currentRound?.scoreBy === "player") {
      return [
        { label: "Players", value: 1 },
        { label: "Teams", value: 2 },
        { label: "Assignments", value: 3 },
      ];
    } else {
      return [
        { label: "Teams", value: 2 },
        { label: "Assignments", value: 3 },
      ];
    }
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar
          type="form-header"
          header={`Round ${getRoundName(currentRound)} Scorecards`}
          pageActions={getPageActions()}
        ></GSActionBar>
        {scorecards?.length > 0 ? (
          <div className="search">
            <GSInput
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              textValue={search}
              placeholder="Search Players & Teams…"
              leftIcon={faSearch}
              rightIcon={faTimesCircle}
              rightIconClick={clearSearch}
            />
            <GSSelect
              selectedOption={group}
              options={getGroup()}
              onChange={(option) => {
                dispatch(setScoringGroup(option));
              }}
            />
          </div>
        ) : null}

        {getScorecards()}
      </gs-form>
    );
  };

  const generateMissingScorecards = () => {
    console.log(holeAssignmentsWithoutKeys)
    const missingAssignments = teamsWithoutKeys?.map((key) =>
      holeAssignments.find((ha) =>
        ha.tournamentTeams?.map?.((tt) => tt.id).includes?.(key)
      )
    );
    let deduped = [];
    missingAssignments.forEach?.((assignment) => {
      if (!deduped?.map?.((ma) => ma?.id).includes(assignment?.id)) {
        deduped.push(assignment);
      }
    });
    if (deduped.length > 0) {
      dispatch(saveMissingRoundHoleAssignment(deduped)).then(() => dispatch(getRoundScorecards(match.params.roundID)));
    }
  };

  const activateRound = () => {
    let update = { ...currentRound };
    update.state = "live";
    dispatch(setCurrentRound(update))
    dispatch(saveRound(update));
  };

  let notificationSettings = {
    notifications: notifications,
    timeoutAction,
    defaultBanner:
      teamsWithoutKeys?.length > 0 && scorecards?.length > 0 && !holeAssignmentsWithoutKeys
        ? {
          title: getNotificationItemInfo(
            `Please check the hole assignments page to make sure all teams are assigned to a hole`,
            faExclamationCircle,
            "Some teams do not have scorecards generated",
            currentRound.state !== "live"
              ? "To generate these scorecards please activate the round."
              : "To generate these scorecards please select them from the list below or tap 'Generate Scorecards'."
          ),
          state: "warning",
          bannerActions:
            currentRound.state === "live"
              ? [
                {
                  title: "generate scorecards",
                  actionClick: generateMissingScorecards,
                },
              ]
              : [
                {
                  title: "Activate Round",
                  actionClick: activateRound,
                },
              ],
        }
        : false,
  };

  const [banner] = useNotificationBanner(notificationSettings);

  return (
    <tournament-round-scoring>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-round-scoring>
  );
};

export default TournamentRoundScoring;
