import React, { useState } from "react";
import {
  GSForm,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-hole-auto-assigment.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  autoAssignHoles,
  clearRoundNotifications,
  deactivateRoundScorecards,
  getRound,
  getRoundHoleAssignments,
  getRoundScorecards,
  saveRound,
  saveRoundHoleAssignment,
  selectCurrentRound,
  selectCurrentRoundHoleAssignments,
  selectCurrentRoundScorecards,
  selectRoundNotifications,
  setCurrentRound,
} from "../../../../reducers/roundSlice";
import { useSelector } from "react-redux";
import TournamentRoundHoleAssignmentItem from "./tournament-round-hole-assignment-item";
import {
  getTournamentTeams,
  selectQualifiedTeams,
} from "../../../../reducers/teamsSlice";
import { holeAssignmentForm } from "../../../../forms/HoleAssignmentForm";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useFormValidation } from "../../../../hooks/formHooks";
import { getNotificationItemInfo } from "../../../../helpers/Converters";
//Name the component
const TournamentRoundHoleAutoAssignment = (props) => {
  const [sort, setSort] = useState({ value: 1 });
  const [direction, setDirection] = useState({ value: 1 });

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/rounds/:roundID/hole-assignments/auto-assign"
  );

  //selectors
  const roundHoleAssignments = useSelector(selectCurrentRoundHoleAssignments);
  const tournamentTeams = useSelector(selectQualifiedTeams);
  const notifications = useSelector(selectRoundNotifications);
  const currentRound = useSelector(selectCurrentRound);
  const scorecards = useSelector(selectCurrentRoundScorecards);

  //context
  const [context, isValid] = useFormValidation(true);

  context.getAutoAssignSortOptions = () => {
    if (currentRound.numberOfPlayers > 1) {
      return [
        { label: "Team Handicap", value: 1 },
        { label: "Player Handicap", value: 2 },
      ];
    }
    return [{ label: "Player Handicap", value: 1 }];
  };

  context.getAutoAssignDirectionOptions = () => {
    return [
      { label: "Low to High", value: 1 },
      { label: "High to Low", value: 2 },
    ];
  };

  context.getAutoAssignSort = () => {
    return sort;
  };

  context.setAutoAssignSort = (option) => {
    setSort(option);
  };

  context.getAutoAssignDirection = () => {
    return direction;
  };

  context.setAutoAssignDirection = (option) => {
    setDirection(option);
  };

  //custom hooks
  useNotificationNavigation("saved", notifications, -1, "Hole Assignments");

  //effects
  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRoundHoleAssignments(match.params.roundID));
      dispatch(getRoundScorecards(match.params.roundID));
      dispatch(getRound(match.params.roundID));
    }
    return () => {
      dispatch(clearRoundNotifications());
    };
  }, [dispatch, match?.params?.roundID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  //component functionality

  const getSort = () => {
    if (sort.value === 1) {
      return direction.value === 1
        ? (a, b) => a.adjustedHandicap - b.adjustedHandicap
        : (a, b) => a.adjustedHandicap - b.adjustedHandicap;
    } else {
      return direction.value === 1
        ? (a, b) =>
            Math.min(...a.players.map((p) => p.adjustedHandicap)) -
            Math.min(...b.players.map((p) => p.adjustedHandicap))
        : (a, b) =>
            Math.max(...b.players.map((p) => p.adjustedHandicap)) -
            Math.max(...a.players.map((p) => p.adjustedHandicap));
    }
  };

  const autoAssign = () => {
    let sortedTeams = [...tournamentTeams].sort(getSort());
    let assign = {
      roundID: match?.params?.roundID,
      teamIds: sortedTeams?.map?.((tt) => tt.id),
    };
    dispatch(autoAssignHoles(assign)).then(() => {
      dispatch(getRoundHoleAssignments(match.params.roundID));
    });
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Assign",
        isDisabled: !isValid,
        action: autoAssign,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Auto Assign Holes`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getHoleAssignments = () => {
    return roundHoleAssignments?.map?.((rha) => ({
      ...rha,
      tournamentTeams: rha.tournamentTeams?.map?.((hatt) =>
        tournamentTeams?.find?.((tt) => tt?.id === hatt?.id)
      ),
    }));
  };

  const toggleLock = (roundHoleAssignment) => {
    let update = { ...roundHoleAssignment };
    update.locked = !update.locked;
    dispatch(saveRoundHoleAssignment(update));
  };

  context.getHoleAssignmentList = () => {
    return (
      <GSItemList
        type="vertical small-gap"
        items={getHoleAssignments()}
        listItem={(item) => (
          <TournamentRoundHoleAssignmentItem
            {...item}
            toggleLock={toggleLock}
          />
        )}
      ></GSItemList>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Auto Assign Holes"
        formSections={holeAssignmentForm(context)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications());
  };

  const revertRound = () => {
    let update = {...currentRound}
    update.state = "ready"
    dispatch(setCurrentRound(update))
    dispatch(saveRound(update))
  }

  let notificationSettings = {
    notifications: notifications,
    timeoutAction,
    defaultBanner:
      currentRound.state === "live"
        ? {
            title: getNotificationItemInfo(
              `Round can't be live when auto assigning hole assignments`,
              faExclamationCircle,
              "This round is live."
            ),
            state: "warning",
            bannerActions: [
              {
                title: "Deactivate Round",
                actionClick: revertRound,
              },
            ],
          }
        : 
        scorecards?.length > 0 ?
        {
          title: getNotificationItemInfo(
            `Adding new scorecards might have undesired effects.`,
            faExclamationCircle,
            "Scorecards have already been generated"
          ),
          state: "warning",
          bannerActions: [
            {
              title: "clear scorecards",
              actionClick: () => {dispatch(deactivateRoundScorecards(match.params.roundID));},
            },
          ],
        }:
        false,
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-round-hole-auto-assigment>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-hole-auto-assigment>
  );
};

//export the new namet
export default TournamentRoundHoleAutoAssignment;
