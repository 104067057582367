import React from "react";
import "./navigation-list-item.scss";

import {  faArrowCircleDown, faChevronRight, faExternalLinkSquareAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";

import pdfLogo from "../images/PDF.svg"
import excelLogo from "../images/Excel.svg"
import pdfDisabledLogo from "../images/PDF_Disabled.svg"
import excelDisabledLogo from "../images/Excel_Disabled.svg"
import fileArchiveLogo from "../images/File_Archive.svg"

const NavigationListItem = (props) => {
  const { page } = props;

  const pdf = pdfLogo
  const xls = excelLogo
  const pdfDisabled = pdfDisabledLogo
  const xlsDisabled = excelDisabledLogo
  const fileArchive = fileArchiveLogo

  const images = [
    {
      ext : ".xls",
      logo: page?.disabled ? xlsDisabled : xls
    },
    {
      ext : ".xlsx",
      logo: page?.disabled ? xlsDisabled : xls
    },
    {
      ext : ".pdf",
      logo: page?.disabled ? pdfDisabled : pdf
    },
    {
      ext : ".zip",
      logo: fileArchive
    }
  ]

  const getPageDataGroup = () => {
    let item = [
      page.hero ? {
        fit: "stretch",
        sections: [
          {
            fit: "stretch",
            sectionItems: [
              { type: "hero-item", value: page?.hero },
            ],
          },
        ],
      } : {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              { type: "primary", value: page?.label },
              { type: "secondary", value: page?.details },
            ],
          },
        ],
      },
    ];
    if (page?.isExternal) {
      item.push({ title: <GSButton buttonIcon={faExternalLinkSquareAlt} /> });
    }
    else if(page?.isDownload)
    {
      if(!page.disabled)
      {
        item.push({ title: <GSButton buttonIcon={faArrowCircleDown} /> });
      }
      else{
        //item.push({ title: <GSButton buttonIcon={faBan} /> });
      }
    }
    else if(page?.isEdit)
    {
      item.push({ title: <GSButton type="light-grey" buttonIcon={faPen} /> });
    }
    else{
      item.push({ title: <GSButton buttonIcon={faChevronRight} /> });
    }

    if(page?.ext)
    {
      item.unshift({
        sections: [
          {
            sectionItems: [
              {
                type: "primary",
                value : <img className="nav-icon-img" src={images?.find?.(i => i.ext === page.ext)?.logo} alt={page.ext}></img>
              }
            ]
          },
        ]
      },)
    }
    return item;
  };
  return (
    <navigation-list-item>
      <GSInfoGroup
        type={`list-item separator selectable x-large-pad ${page?.disabled ? "disabled" : ""} ${(page?.type ?? "")}`}
        dataGroups={getPageDataGroup()}
      ></GSInfoGroup>
      {page?.extras}
    </navigation-list-item>
  );
};

export default NavigationListItem
