import React from "react";
import {
  GSEmptyList,
  GSForm,
  GSInput,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faSearch,
  faSync,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-team-handicap-update.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { getHandicapUpdateForm } from "../../../forms/TeamForm";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getOrganizationAccessLists,
  selectAccessLists,
} from "../../../reducers/accessSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  clearTournamentNotifications,
  manuallyUpdateHandicaps,
  selectCurrentTournament,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { getDateTimeOn } from "../../../helpers/Dates";
import { getNotificationItemInfo } from "../../../helpers/Converters";

//Name the component
const TournamentTeamHandicapUpdate = (props) => {
  const [updateType, setUpdateType] = useState({ value: 1 });
  const [search, setSearch] = useState("");
  const [selectedAccessGroup, setSelectedAccessGroup] = useState(false);

  const dispatch = useDispatch();
  const accessLists = useSelector(selectAccessLists);
  const currentTournament = useSelector(selectCurrentTournament);

  const [context, isValid, setIsValid] = useFormValidation(true);

  useEffect(() => {
    if (currentTournament?.organization?.id) {
      dispatch(getOrganizationAccessLists(currentTournament.organization.id));
    }
  }, [dispatch, currentTournament?.organization?.id]);

  context.updateType = (option) => {
    setIsValid(true);
    setUpdateType(option);
  };

  context.validationFailed = () => {
    if (isValid) {
      setIsValid(false);
    }
  };

  context.getFilteredAccessLists = () => {
    return accessLists?.filter?.((al) =>
      al.name?.toLowerCase?.()?.includes?.(search.toLowerCase?.())
    );
  };

  context.getAccessListSearch = () => {
    return (
      <GSInput
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        textValue={search}
        placeholder="Search Access Lists..."
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    );
  };

  context.selectAccessGroup = (item) => {
    setIsValid(true);
    setSelectedAccessGroup(item);
  };

  context.removeAccessGroup = (item) => {
    setSelectedAccessGroup(false);
  };

  context.getSelectedAccessGroup = () => {
    return selectedAccessGroup;
  };

  const goToOrganization = () => {
    const url = `http://organizations${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/${currentTournament?.organization?.id}/access`;
    window.open(url);
  };

  context.getEmptyAccessGroupMessage = () => {
    return (
      <GSEmptyList
        title="Organization Access Groups"
        detail="Organization access groups allow you to restrict the amount of people that a package will be available for."
        actions={[
          {
            title: "Manage Access Groups",
            onClick: goToOrganization,
            type: "black",
          },
        ]}
      />
    );
  };

  const navigate = useNavigate();
  const notifications = useSelector(selectTournamentNotifications);

  const save = () => {
    setIsValid(false);
    let settings = { id: currentTournament?.id };
    if (updateType.value === 1) {
      settings["update_handicap_source"] = "ghin";
    } else {
      settings["update_handicap_source"] = "access_group";
      settings["update_handicap_source_value"] = selectedAccessGroup.id;
    }
    dispatch(manuallyUpdateHandicaps(settings));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Update",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Update handicaps`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={"Update Handicaps"}
        formSections={getHandicapUpdateForm(context, updateType)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction,
    defaultBanner: currentTournament?.handicapsManuallyFetchedAt
      ? {
          title: getNotificationItemInfo(
            `Handicap indexes last updated at ${getDateTimeOn(
              currentTournament?.handicapsManuallyFetchedAt
            )}`,
            faSync
          ),
          state: "grey",
        }
      : undefined,
  });

  return (
    //name the component tag
    <tournament-team-handicap-update>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-handicap-update>
  );
};

//export the new namet
export default TournamentTeamHandicapUpdate;
