import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useMatch } from "react-router-dom";
import { setNav, setNotifications } from "../../reducers/appSlice";
import {
  getTournament,
  getTournamentPointOfContact,
  selectCurrentTournament,
} from "../../reducers/tournamentSlice";
import "./tournament-details.scss";
import { GSButton } from "golfstatus_react_components";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { getNotificationItemInfo } from "../../helpers/Converters";
import { tournament } from "../../app/api";
const TournamentDetail = (props) => {
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/*");
  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    dispatch(getTournament(match.params.tournamentID));
  }, [dispatch, match.params.tournamentID]);

  useEffect(() => {
    if (currentTournament?.id && currentTournament.pointOfContact === undefined) {
      dispatch(getTournamentPointOfContact(currentTournament?.id));
    }
  }, [dispatch, currentTournament?.id, currentTournament?.pointOfContact]);

  useEffect(() => {
    if (currentTournament?.id && currentTournament.state === "draft" && tournament?.tournamentRounds?.length > 0) {
      dispatch(setNotifications([{
        header: getNotificationItemInfo("Your round is ready for activation.", faCheck, "Your tournament is looking good.",),
        state: "success",
        pageActions: [
          {
            type: "",
            title: "Activate Your Tournament",
          },
        ]
      }]))
    }
  }, [dispatch, currentTournament?.id, currentTournament?.state, currentTournament?.tournamentRounds?.length]);

  useEffect(() => {
    const nav = [
      {
        id: 1,
        label: <GSButton type="secondary" title="All tournaments" buttonIcon={faChevronLeft} />,
        url: `/`,
        isSelectable: true,
      },
      {
        id: 2,
        label: currentTournament.name,
        isSelectable: false,
        type: "bold"
      },
      {
        id: 3,
        label: "Overview",
        url: `/${currentTournament.id}`,
        isSelectable: true,
      },
      {
        id: 4,
        label: "Rounds & Scorecards",
        url: `/${currentTournament.id}/rounds`,
        isSelectable: true,
      },
      {
        id: 7,
        label: "Event Site & Packages",
        url: `/${currentTournament.id}/promotion`,
        isSelectable: true,
      },
      {
        id: 5,
        label: "Players & Teams",
        url: `/${currentTournament.id}/teams`,
        isSelectable: true,
      },
      {
        id: 6,
        label: "Sponsors",
        url: `/${currentTournament.id}/sponsors`,
        isSelectable: true,
      },
      {
        id: 8,
        label: "Flights",
        url: `/${currentTournament.id}/flighting`,
        isSelectable: true,
      },
      {
        id: 9,
        label: "Leaderboards & Skins",
        url: `/${currentTournament.id}/leaderboards`,
        isSelectable: true,
      },
      {
        id: 9,
        label: "Documents",
        url: `/${currentTournament.id}/documents`,
        isSelectable: true,
      },
      {
        id: 10,
        label: "Orders & Payouts",
        url: `/${currentTournament.id}/reporting`,
        isSelectable: true,
      },
      {
        id: 11,
        label: "Messages",
        url: `https://users${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/app/messaging?newMessage=true&tournaments=${currentTournament.id}`,
        isExternal: true,
        isSelectable: true,
      },
      {
        id: 12,
        label: "Revenue Enhancements",
        url: `https://events${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/event/revenue-enhancements/register`,
        isExternal: true,
        isSelectable: true,
      },
      {
        id: 13,
        label: "Settings & Preferences",
        url: `/${currentTournament.id}/settings`,
        isSelectable: true,
      },

    ];
    dispatch(setNav(nav));
  }, [currentTournament, dispatch]);
  return (
    <tournament-detail>
      <Outlet></Outlet>
    </tournament-detail>
  );
};

export default TournamentDetail;
