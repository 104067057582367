import { faFolderOpen, faPlus, faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSInput,
  GSEmptyList,
  GSItemList,
} from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  selectCurrentTournament,
  selectLoading,
} from "../../../reducers/tournamentSlice";
import { Outlet } from "react-router-dom";

import "./tournament-rounds.scss";
import {
  getTournamentRounds,
  selectTournamentRounds,
  setCurrentRound,
  selectLoading as roundLoading
} from "../../../reducers/roundSlice";
import TournamentRoundLineItem from "./tournament-round-line-item";

const TournamentRounds = (props) => {
  const [name, setName] = useState("");

  const currentTournament = useSelector(selectCurrentTournament);
  const currentTournamentRounds = useSelector(selectTournamentRounds);
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/rounds/*");
  const dispatch = useDispatch();
  const isBase = match?.params?.["*"] === ""

  useEffect(() => {
    if(currentTournament.id && isBase)
    {
      dispatch(getTournamentRounds(currentTournament?.id));
    }
  }, [dispatch, currentTournament, isBase]);

  const loading = useSelector(selectLoading);
  const tournamentRoundLoading = useSelector(roundLoading);

  const clearText = () => {
    setName("");
  };

  const createRound = (round) => {
    dispatch(setCurrentRound({}));
    navigate("new");
  };
  const clearSearchFields = () => {
    setName("")
  };
  const printouts = () => {
    navigate("documents")
  }

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Add Round",
        type: "black mobile-icon",
        actionClick: createRound,
        actionIcon: faPlus,
      },
      {
        id: 1,
        buttonTitle: "Documents",
        type: "light-grey mobile-icon",
        actionClick: printouts,
        actionIcon: faFolderOpen,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Add & Manage Rounds"
        detail="Add new rounds and manage all round details in one spot."
        actions={[
          {
            title: "Add Round",
            buttonIcon: faPlus,
            onClick: createRound,
            type: "black",
          },
        ]}
      />
    );
  };
  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the round you’re looking for…"
        detail="Try changing the search term or add a new round."
        actions={[
          {
            title: "Clear Search",
            onClick: (clearSearchFields),
            type: "light-grey",
          },
          {
            title: "Add Round",
            onClick: createRound,
            buttonIcon: faPlus,
            type: "black",
          },
        ]}
      />
    );
  };

  const getFilteredRounds = () => {
    return currentTournamentRounds?.filter?.(
      (round) =>
        (round.roundName ?? "").includes?.(name) ||
        round.number === parseInt(name)
    );
  };

  const getRoundLineItem = (round) => {
    return <TournamentRoundLineItem round={round}/>;
  };

  const selectRound = (round) => {
    if(isSidePanelOpen())
    {
      navigate(`/${match.params.tournamentID}/rounds`);
    }
    else{
      navigate(round.id);
    }
    
  };
  const getItemList = () => {
    return (
      <GSItemList
        items={getFilteredRounds()}
        listItem={(round) => getRoundLineItem(round)}
        itemSelected={selectRound}
        type="vertical selectable round-list"
        emptyMessage={
          name !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={loading?.includes?.("getTournament") || tournamentRoundLoading?.includes?.("getTournamentRounds")}
        loadingMainText="Loading Rounds"
      ></GSItemList>
    );
  };
  const searchChanged = (e) => {
    setName(e.target.value);
  };

  const getDefaultSearch = () => {
    return (
      <div className="round-search">
        <GSInput
          textValue={name}
          leftIcon={faSearch}
          rightIconClick={clearText}
          rightIcon={faTimesCircle}
          onChange={searchChanged}
          placeholder="Search by Round Number or Label…"
        ></GSInput>
      </div>
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };
  const getRouter = () => {
    return <Outlet />;
  };

  const getListPage = (title) => {
    const page = {
      title: title,
      getDefaultSearch: getDefaultSearch,
      getPageActions: getPageActions,
      getItemList: getItemList,
      isSidePanelOpen: isSidePanelOpen,
      getRouter: getRouter,
    };
    return <GSListPage {...page} />;
  };

  return (
    <tournament-rounds>{getListPage("Rounds & Scorecards")}</tournament-rounds>
  );
};

export default TournamentRounds;
