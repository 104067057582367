import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
  faExclamationCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-item-create-edit.scss";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  createPackageItem,
  createTournamentPackageItem,
  findCurrentPackageItemWithId,
  getAddOnPackageItems,
  getPlayerPackageItems,
  getSkinsAddOnPackageItems,
  getSponsorPackageItems,
  getTeamPackageItems,
  getTechnologySponsorPackageItems,
  getTournamentPackages,
  savePackageItem,
  saveTournamentPackageItem,
  selectAvailablePackageItems,
  selectCurrentTournamentPackage,
  selectCurrentTournamentPackageItem,
  selectCurrentTournamentPackageItems,
  selectMinTechSponsorPackagePrice,
  selectNotifications,
  selectTournamentPackages,
  setCurrentPackageItem,
  setCurrentPackageItems,
} from "../../../../reducers/packageSlice";
import {
  getItemTypes,
  getPackageItemForm,
} from "../../../../forms/TournamentPackageForm";
import { useDispatch } from "react-redux";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { useFormValidation } from "../../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { getNotificationItemInfo } from "../../../../helpers/Converters";

//Name the component
const TournamentPackageItemCreateEdit = (props) => {
  const [itemType, setItemType] = useState();

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/package-items/item/:packageItemID"
  );

  //selectors
  const currentPackageItem = useSelector(selectCurrentTournamentPackageItem);
  const allPackageItems = useSelector(selectAvailablePackageItems);
  const currentTournament = useSelector(selectCurrentTournament);
  const packageNotifications = useSelector(selectNotifications);
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageItems = useSelector(selectCurrentTournamentPackageItems);
  const minTechSponsorPrice = useSelector(selectMinTechSponsorPackagePrice);
  const tournamentPackages = useSelector(selectTournamentPackages);

  //effects
  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTeamPackageItems(match.params.tournamentID));
      dispatch(getPlayerPackageItems(match.params.tournamentID));
      dispatch(getSponsorPackageItems(match.params.tournamentID));
      dispatch(getTechnologySponsorPackageItems(match.params.tournamentID));
      dispatch(getSkinsAddOnPackageItems(match.params.tournamentID));
      dispatch(getAddOnPackageItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.packageID !== "add") {
      dispatch(setCurrentPackageItem(match?.params?.packageItemID));
    }
  }, [dispatch, match?.params?.packageItemID, match?.params?.packageID]);

  useEffect(() => {
    if (match?.params?.packageItemID) {
      dispatch(findCurrentPackageItemWithId(match.params.packageItemID));
    }
  }, [allPackageItems?.length, dispatch, match?.params?.packageItemID]);

  useEffect(() => {
    if (currentPackageItem?.id) {
      let t = getItemTypes(currentPackageItem);
      if (t.length === 1) {
        setItemType(t?.[0]);
      }
    }
  }, [currentPackageItem]);

  //form context
  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updatePackageItem = (value, property) => {
    setIsValid(true);
    let update = { ...currentPackageItem };
    update[property] = value;
    dispatch(setCurrentPackageItem(update));
  };

  context.getSelectedItemType = () => {
    return itemType;
  };

  context.setSelectedType = (type) => {
    setIsValid(true);
    if ((currentPackageItem?.name ?? "") === "") {
      context.updatePackageItem(type.label, "name");
    }
    setItemType(type);
  };

  context.currentPackage = currentPackage;
  context.minTechSponsorPrice = minTechSponsorPrice;

  const save = () => {
    setIsValid(false);
    setUnsaved(false);
    let update = { ...currentPackageItem };
    update.itemType = itemType.value;
    update.tournament = currentTournament;
    if (match?.params?.packageItemID === "add") {
      update.tournamentKey = match?.params?.tournamentID;
      dispatch(createPackageItem(update));
    } else {
      dispatch(savePackageItem(update));
    }
  };

  const updateQuantity = (item, quantity) => {
    let cpiIndex = currentPackageItems.findIndex?.(
      (cpi) => cpi.packageItemKey === item.id
    );
    let cpis = [...currentPackageItems];
    cpis[cpiIndex] = {
      ...cpis[cpiIndex],
      quantity: quantity,
    };
    dispatch(setCurrentPackageItems(cpis));
    dispatch(saveTournamentPackageItem(cpis[cpiIndex])).then(() =>
      dispatch(getTournamentPackages(currentTournament?.id))
    );
  };

  const addToPackage = () => {
    let found = currentPackageItems.find?.(
      (cpi) => cpi.packageItemKey === currentPackageItem.id
    );
    if (found) {
      updateQuantity(currentPackageItem, found.quantity + 1);
    } else {
      let newPi = {
        packageItemKey: currentPackageItem.id,
        quantity: 1,
        tournamentPackageKey: match?.params?.packageID,
        packageItem: currentPackageItem,
        tournamentPackage: currentPackage,
      };
      dispatch(createTournamentPackageItem(newPi));
    }
  };

  const removeItem = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [];
    if (isValid || match?.params?.packageItemID === "add") {
      actions.push({
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      });
      actions.push({ name: "cancel", action: leftNavigation, type: "" });
    } else {
      if (
        currentPackageItem?.itemType !== "technology-sponsor" ||
        currentPackage?.cost > minTechSponsorPrice
      ) {
        actions.push({
          name: "Add To Package",
          action: addToPackage,
          buttonIcon: faPlusCircle,
          type: "light-grey",
        });
      }
      actions.push({
        name: "delete item",
        action: removeItem,
        type: "transparent red",
      });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Package Item Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          match?.params?.packageID !== "add"
            ? "Add Package Item"
            : "Edit Package Item"
        }
        formSections={getPackageItemForm(context, currentPackageItem)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  context.hasTechSponsor =
    tournamentPackages?.some(
      (item) =>
        item.hasTechPackageItem === true && item.id !== currentPackage.id
    ) ||
    currentPackageItems?.some((pi) =>
      allPackageItems.find(
        (api) =>
          pi.packageItemKey === api.id && api.itemType === "technology-sponsor"
      )
    );

  const getDefaultBanner = () => {
    if (
      currentPackageItem?.itemType === "technology-sponsor" &&
      currentPackage?.cost < minTechSponsorPrice
    ) {
      return {
        title: getNotificationItemInfo(
          `To include a Tech Sponsor package item, the package price must be at least $${minTechSponsorPrice}.`,
          faExclamationCircle
        ),
        state: "warning",
        bannerActions: [
          {
            title: "Edit Package",
            type: "secondary",
            actionClick: () =>
              navigate(`../packages/${match?.params?.packageID}/edit`),
          },
        ],
      };
    }
    return false;
  };

  //custom hooks
  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: savePackageItem,
    bannerActions: getSaveBannerActions(savePackageItem, leftNavigation),
    timeoutAction,
    defaultBanner: getDefaultBanner(),
  };
  useNotificationNavigation("created", packageNotifications, -1);
  useNotificationNavigation("saved", packageNotifications, -1);
  const [bannerNotifications, setUnsaved] =
    useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-package-item-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-item-create-edit>
  );
};

//export the new namet
export default TournamentPackageItemCreateEdit;
