import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-receipt-customization.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  createPromotion,
  saveTournamentPromotions,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCurrentTournament,
} from "../../../reducers/tournamentSlice";
import { getRegistrationReceiptForm } from "../../../forms/PromotionForm";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionReceiptCustomization = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentNotifications = useSelector(selectTournamentNotifications);

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateTournamentPromotion = (value, property) => {
    setIsValid(true);
    let updateTournament = { ...currentTournament };
    let updateTournamentPromotion = {
      ...currentTournament.tournamentPromotion,
    };
    updateTournamentPromotion[property] = value;
    updateTournament.tournamentPromotion = updateTournamentPromotion;
    dispatch(setCurrentTournament(updateTournament));
  };

  context.getLogo = (property) => {
    if (
      currentTournament.tournamentPromotion?.[property] &&
      currentTournament.tournamentPromotion?.[property] !==
        "/logos/default/missing.png"
    ) {
      return [
        {
          url: currentTournament.tournamentPromotion?.[property],
          type: "image",
        },
      ];
    }
    return [];
  };

  context.setLogo = (files, property) => {
    if (files.length === 0) {
      context.updateTournamentPromotion(undefined, property);
      return;
    }
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        context.updateTournamentPromotion(reader.result, property);
      });
      reader.readAsDataURL(file);
    }
  };

  context.isValid = (value) => {
    setIsValid(value)
  }

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let promotion = { ...currentTournament.tournamentPromotion };
    promotion.tournament = currentTournament;
    if (promotion.id) {
      dispatch(saveTournamentPromotions(promotion));
    } else {
      dispatch(createPromotion(promotion));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Order Receipt`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle="Order Receipt"
        formSections={getRegistrationReceiptForm(
          context,
          currentTournament.tournamentPromotion
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-promotion-receipt-customization>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-promotion-receipt-customization>
  );
};

//export the new namet
export default TournamentPromotionReceiptCustomization;
