import React, { useState } from "react";
import {
  GSForm,
  GSRadioGroup,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-filter.scss";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrderFilter,
  setOrderFilter,
} from "../../../reducers/reportingSlice";
import { getDateTime } from "../../../helpers/Dates";

//Name the component
const TournamentReportingFilter = (props) => {
  const orderFilter = useSelector(selectOrderFilter);

  const [orderState, setOrderState] = useState(
    orderFilter.find?.((filter) => filter.property === "status")?.value ?? {
      label: "All",
      value: "all",
    }
  );
  const [paymentType, setPaymentType] = useState(
    orderFilter.find?.((filter) => filter.property === "paymentType")
      ?.value ?? { label: "All", value: "all" }
  );
  const [startDate, setStartDate] = useState(
    orderFilter.find?.((filter) => filter.property === "startDate")?.value ?? ""
  );
  const [endDate, setEndDate] = useState(
    orderFilter.find?.((filter) => filter.property === "endDate")?.value ?? ""
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notifications = [];

  const save = () => {
    let filters = [];
    if (orderState.value !== "all") {
      filters.push({
        name: orderState.label,
        property: "status",
        filter: (value) => {
          return (
            ((value?.registrationOrderRefunds?.length ?? 0) > 0 &&
              orderState.value === "refunded") ||
            ((value?.registrationOrderRefunds?.length ?? 0) === 0 &&
              value.status === orderState.value)
          );
        },
        value: orderState,
      });
    }
    if (paymentType.value !== "all") {
      filters.push({
        name: paymentType.label,
        property: "paymentType",
        filter: (value) => {
          return value.paymentType === paymentType.value;
        },
        value: paymentType,
      });
    }
    if (startDate !== "") {
      filters.push({
        name: `from ${ getDateTime(startDate) }`,
        property: "startDate",
        filter: (value) => {
          return new Date(value.orderPlacedAt) > new Date(startDate);
        },
        value: startDate,
      });
    }
    if (endDate !== "") {
      filters.push({
        name: `to ${getDateTime(endDate)}`,
        property: "endDate",
        filter: (value) => {
          return new Date(value.orderPlacedAt) < new Date(endDate);
        },
        value: endDate,
      });
    }

    dispatch(setOrderFilter(filters));

    navigate(-1);
  };

  const selectorderState = (option) => {
    setOrderState(option);
  };

  const selectPaymentType = (option) => {
    setPaymentType(option);
  };

  const getFilterForm = () => {
    return [
      {
        title: "Date",
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "From",
            isEditable: true,
            value: startDate,
            onChange: (e) => {
              setStartDate(e?.target?.value);
            },
            rightIcon: faTimesCircle,
            rightIconClick: () => {
              setStartDate("");
            },
            type: "datetime-local",
          },
          {
            label: "to",
            value: endDate,
            isEditable: true,
            onChange: (e) => {
              setEndDate(e?.target?.value);
            },
            rightIcon: faTimesCircle,
            rightIconClick: () => {
              setStartDate("");
            },
            type: "datetime-local",
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Payments",
        fields: [
          {
            label: "Payment Status",
            value: (
              <GSSelect
                onChange={selectorderState}
                selectedOption={orderState}
                clearable
                options={[
                  { label: "All", value: "all" },
                  { label: "Paid", value: "paid" },
                  { label: "Refunded", value: "refunded" },
                  { label: "Sent", value: "sent" },
                  { label: "Void", value: "void" },
                  { label: "Cancelled", value: "cancelled" },
                  { label: "Declined", value: "declined" },
                ]}
              />
            ),
            isEditable: true,
            customView: true,
          },
          {
            label: "Payment Type",
            value: (
              <GSRadioGroup
                selectionChanged={selectPaymentType}
                selectedOption={paymentType}
                options={[
                  { label: "All", value: "all" },
                  { label: "Credit/Debit Card", value: "credit_card" },
                  { label: "Bank Account", value: "bank_account" },
                  { label: "Invoice", value: "customer_invoice" },
                  { label: "Pay At Course", value: "pay_at_course" },
                ]}
              />
            ),
            isEditable: true,
            customView: true,
          },
        ],
      },
    ];
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Apply",
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Filter Orders`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Filter Orders" formSections={getFilterForm()}></GSForm>;
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-filter>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-filter>
  );
};

//export the new namet
export default TournamentReportingFilter;
