import React, { useState } from "react";
import {
  GSEmptyList,
  GSField,
  GSForm,
  GSQuickFilter,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  useMatch,
  useNavigate,
} from "react-router-dom";

//import scss
import "./tournament-package-forms.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  createTournamentPackageFieldSet,
  deleteTournamentPackageFieldSet,
  getTournamentForms,
  getTournamentPackage,
  getTournamentPackageForms,
  saveTournamentPackageFieldSet,
  selectCurrentTournamentPackage,
  selectCurrentTournamentPackageForms,
  selectLoading,
  selectNotifications,
  selectTournamentForms,
  setCurrentPackageForm,
  setCurrentPackageFormFields,
  setCurrentPackageForms,
  updateCurrentPackageForm,
} from "../../../../reducers/packageSlice";
import { useSelector } from "react-redux";
import { getPackageFormsForm } from "../../../../forms/TournamentPackageForm";
import TournamentPackageFormItem from "./tournament-package-form-item";
import { useFormValidation } from "../../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationAction,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import SearchBar from "../../../search-bar";
import { selectHistory } from "../../../../reducers/appSlice";

//Name the component
const TournamentPackageForms = (props) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/promotion/packages/:packageID/forms");

  const tournamentForms = useSelector(selectTournamentForms);
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageForms = useSelector(selectCurrentTournamentPackageForms);
  const packageNotifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading);
  const history = useSelector(selectHistory)

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.createForm = () => {
    dispatch(setCurrentPackageFormFields([]));
    dispatch(setCurrentPackageForm({}));
    dispatch(clearNotifications());
    navigate("add");
  };

  context.updateSearch = (value) => {
    setSearch(value);
  };

  context.getCurrentPackage = () => {
    return currentPackage;
  };

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentForms(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    let previous = history?.[1]
    if (match?.params?.packageID && !previous?.includes?.("forms")) {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getTournamentPackageForms(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID, history]);

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    currentPackageForms.forEach?.((pf) => {
      if (pf.occurrence) {
        let update = { ...pf };
        update.registrationFieldSet = tournamentForms.find(
          (cpf) => cpf.id === pf.registrationFieldSetKey
        );
        update.tournamentPackage = currentPackage;
        if (pf.id) {
          dispatch(saveTournamentPackageFieldSet(update));
        } else {
          dispatch(createTournamentPackageFieldSet(update))?.then?.(() => {
            dispatch(getTournamentPackage(match.params.packageID));
            dispatch(getTournamentPackageForms(match.params.packageID));
          });
        }
      }
    });
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        action: save,
        isDisabled: !isValid,
        type: "black",
      },
      {
        name: "Cancel",
        action: leftNavigation,
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    const { tournamentID, packageID } = match.params;
    navigate(`/${tournamentID}/promotion/packages/${packageID}`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Forms`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormActionBar = () => {
    return {
      formTitle: "Forms",
      formActions: [
        {
          buttonTitle: "Add Form",
          actionIcon: faPlus,
          actionClick: context.createForm,
          type: "black no-wrap",
        },
      ],
    };
  };

  const getDefaultSearch = () => {
    if (tournamentForms.length === 0) {
      return;
    }
    let input = {
      search,
      setSearch,
      placeholder: "Search Available Forms…",
      type: "no-pad",
    };
    return (
      <div className="package-list">
        <GSField
          value={<SearchBar {...input} />}
          label="Available Forms"
        ></GSField>
      </div>
    );
  };

  const packageFormChecked = (item) => {
    setIsValid(true);
    dispatch(
      setCurrentPackageForms([
        ...currentPackageForms,
        {
          registrationFieldSetKey: item.id,
          occurrence: "per_player",
          tempID: currentPackageForms.length,
        },
      ])
    );
  };

  const packageFormUnchecked = (item) => {
    if (item?.packageForm?.id) {
      dispatch(deleteTournamentPackageFieldSet(item.packageForm.id));
    }
    dispatch(
      setCurrentPackageForms(
        currentPackageForms?.filter(
          (pf) =>
            (pf.tempID ?? pf.id) !==
            (item.packageForm?.tempID ?? item.packageForm.id)
        )
      )
    );
  };

  const getFilteredForms = () => {
    return tournamentForms.filter?.((f) =>
      f.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
    );
  };

  const getSelectedForms = () => {
    return currentPackageForms.map?.((pf) => ({
      ...tournamentForms?.find?.((tf) => tf.id === pf.registrationFieldSetKey),
      packageForm: pf,
    }));
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`Add & Manage Forms`}
        detail="Forms gather additional information from event registrants!"
        actions={[
          {
            title: "Add Form",
            buttonIcon: faPlus,
            onClick: context.createForm,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title={`We couldn’t find the form you’re looking for…`}
        detail="Try changing the search term or add a new form."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey no-wrap",
          },
          {
            title: "Add Form",
            buttonIcon: faPlus,
            onClick: context.createForm,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySelectedListMessage = () => {
    if (tournamentForms.length === 0) {
      return;
    }
    return (
      <GSEmptyList
        title={`${currentPackage?.name} Forms`}
        detail="Select from the list below or add a new form."
      />
    );
  };

  const editForm = (form) => {
    navigate(form?.id);
  };

  const updatePackageForm = (option, form) => {
    setIsValid(true);
    let update = { ...form.packageForm };
    update.occurrence = option?.value;
    dispatch(updateCurrentPackageForm(update));
  };

  context.getPackageFormList = () => {
    let filter = {
      getDefaultSearch: getDefaultSearch,
      itemSelected: editForm,
      filteredList: getFilteredForms(),
      selectedList: getSelectedForms(),
      itemRemoved: packageFormUnchecked,
      searchListType: "grey-empty-list rounded-empty-list",
      selectListType: "grey-empty-list rounded-empty-list",
      getItem: (item) => (
        <TournamentPackageFormItem
          {...item}
          addForm={packageFormChecked}
          updatePackageForm={updatePackageForm}
        />
      ),
      emptySearchList:
        search === "" ? getEmptyListMessage() : getEmptySearchMessage(),
      emptySelectedList: getEmptySelectedListMessage(),
      multiple: true,
    };

    return <GSQuickFilter {...filter} />;
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormActionBar()}
        formSections={getPackageFormsForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  useNotificationAction("form added", packageNotifications, () => {
    setIsValid(true);
  });

  return (
    //name the component tag
    <tournament-package-forms>
      <GSSidePanelPage
        loading={loading?.includes?.("getTournamentPackageForms")}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-forms>
  );
};

//export the new namet
export default TournamentPackageForms;
