import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-copy.scss";
import { useFormDataValidation } from "../../hooks/formHooks";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../hooks/notificationHooks";
import { getCopyForm } from "../../forms/TournamentForms";
import TournamentLineItem from "../tournament-line-item";
import { useSelector, useDispatch } from "react-redux";
import {
  clearTournamentNotifications,
  copyTournament,
  getTournament,
  selectCopySettings,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCopySettings,
} from "../../reducers/tournamentSlice";

//Name the component
const TournamentCopy = (props) => {
  const copySettings = useSelector(selectCopySettings);

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: copySettings,
    setData: setCopySettings,
  });

  const match = useMatch("/tournaments/copy/:tournamentID");
  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournament(match.params.tournamentID));
    }
    return () => {
      dispatch(clearTournamentNotifications());
    };
  }, [match?.params?.tournamentID, dispatch]);

  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    dispatch(
      setCopySettings({
        name: `${currentTournament?.name} (copy)`,
        copyTournamentPromotion: true,
        copyRegistrationPackages: true,
        copyPaymentSettings: true,
      })
    );
  }, [currentTournament, dispatch]);

  const navigate = useNavigate();
  const notifications = useSelector(selectTournamentNotifications);

  useNotificationNavigation("created", notifications, "/[id]", "Copy");

  const save = () => {
    setIsValid(false);
    let updateCopySettings = { ...copySettings };
    updateCopySettings.date = new Date(
      updateCopySettings?.date
    )?.toISOString?.();
    dispatch(
      copyTournament({ ...updateCopySettings, id: match?.params?.tournamentID })
    );
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save & Continue",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Copy Tournament`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={<TournamentLineItem tournament={currentTournament} />}
        formSections={getCopyForm(context)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications });

  return (
    //name the component tag
    <tournament-copy>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-copy>
  );
};

//export the new name
export default TournamentCopy;
