import {
  faChevronRight,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSImageEditor,
  GSRadioGroup,
  validations,
} from "golfstatus_react_components";
import TournamentSponsorHoleAssignmentRound from "../components/tournament/tournament-sponsorships/tournament-sponsor-hole-assignment-round";
import { openExternalLink } from "../helpers/Utilities";

export const editSponsorForm = (context, currentSponsor) => {
  const { validEmail, validPhone, validLink } = validations;
  if (context) {
    let sponsorType = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Sponsor Type",
          failedValidation: context.validationFailed,
          value: (
            <GSRadioGroup
              selectedOption={{ value: currentSponsor.sponsorshipType }}
              options={sponsorshipTypes(context?.hasTechSponsor?.())}
              selectionChanged={(option) => {
                context?.updateData?.(option.value, "sponsorshipType");
              }}
            />
          ),
          validation: {
            isValid: () => currentSponsor?.sponsorshipType !== "",
            invalidLabel: "Sponsorship type is required",
          },
          customView: true,
          isEditable: true,
          required: true,
        },
      ],
    };
    let sponsorInfo = {
      type: "vertical xx-large-gap",
      title: "Sponsor Information",
      fields: [
        {
          label: "Sponsor Name",
          failedValidation: context.validationFailed,
          value: currentSponsor?.name ?? "",
          isEditable: true,
          required: true,
          onChange: (e) => context.updateData(e.target.value, "name"),
          placeholder: "Sponsor Name",
        },
        {
          label: "Sponsor Website",
          failedValidation: context.validationFailed,
          rightIcon: faExternalLinkSquareAlt,
          rightIconClick: () => {
            if (currentSponsor?.website && validLink(currentSponsor.website)) {
              openExternalLink(currentSponsor.website);
            }
          },
          value: currentSponsor?.website ?? "",
          isEditable: true,
          onChange: (e) => context.updateData(e.target.value, "website"),
          placeholder: "Sponsor Website",
          hintText: "e.g. www.sponsorwebsite.com",
          validation: {
            isValid: validLink,
            invalidLabel: "Must be a valid website link.",
          },
        },
        {
          label: "In-App Sponsor Message",
          failedValidation: context.validationFailed,
          value: currentSponsor?.message,
          isEditable: true,
          onChange: (e) => context.updateData(e.target.value, "message"),
          placeholder: "In-App Sponsor Message",
          hintText:
            "This message will be shown in the sponsor details section of GolfStatus app.",
          type: "text-area",
          rows: 4,
        },
      ],
    };

    let sponsorImagery = {
      type: "vertical xx-large-gap",
      title: "Sponsor Images",
      fields: [
        {
          label: "Sponsor Image",
          value: context.getFileSelect(context),
          failedValidation: context.validationFailed,
          validation: {
            isValid: () => currentSponsor?.logo,
            invalidLabel: "logo is required",
          },
          isEditable: true,
          customView: true,
          required: true,
        },
        {
          label: "In-App Sponsor Image",
          value: context.getMobileFileSelect(context),
          failedValidation: context.validationFailed,
          validation: {
            isValid: () => currentSponsor?.logoMobile,
            invalidLabel: "In-App Sponsor Image is required",
          },
          isEditable: true,
          customView: true,
          required: true,
        },
      ],
    };

    let sponsorContact = {
      type: "vertical xx-large-gap",
      title: "Sponsor Contact",
      fields: [
        {
          label: "Full Name",
          failedValidation: context.validationFailed,
          value: currentSponsor?.contactName ?? "",
          isEditable: true,
          onChange: (e) => context.updateData(e.target.value, "contactName"),
          placeholder: "Full Name",
          required: true,
        },
        {
          label: "Email Address",
          failedValidation: context.validationFailed,
          value: currentSponsor?.email ?? "",
          validation: {
            isValid: validEmail,
            invalidLabel: "Not a valid email address",
          },
          isEditable: true,
          onChange: (e) => context.updateData(e.target.value, "email"),
          placeholder: "Email Address",
          required: true,
        },
        {
          label: "Phone Number",
          failedValidation: context.validationFailed,
          value: currentSponsor?.phone,
          isEditable: true,
          validation: {
            isValid: validPhone,
            invalidLabel: "Not a valid phone number",
          },
          onChange: (e) => context.updateData(e.target.value, "phone"),
          placeholder: "Phone Number",
        },
      ],
    };
    let form = [
      sponsorType,
      { ...sponsorInfo },
      { ...sponsorImagery },
      { ...sponsorContact },
    ];
    return form;
  }
};

export const imageEditForm = (context, showCroppedImage, croppedImg) => {
  return [
    {
      type: "vertical",
      fields: [
        {
          value: showCroppedImage ? (
            <img className="preview-image" src={croppedImg} alt="upload" />
          ) : (
            <GSImageEditor
              source={context?.getLogo?.()}
              minWidth={600}
              minHeight={context.getMinHeight?.()}
              setCroppedDataURL={context.setCroppedDataURL}
              setCroppedBlob={context.setCroppedBlob}
              resizeEnabled={true}
              ratio={context?.getRatio?.()}
            ></GSImageEditor>
          ),
        },
      ],
    },
  ];
};

const sponsorshipTypes = (hasTechSponsor) => {
  if (hasTechSponsor) {
    return [{ id: 1, label: "Sponsor", value: "hole" }];
  }
  return [
    { id: 1, label: "Sponsor", value: "hole" },
    { id: 2, label: "Technology Sponsor", value: "leaderboard" },
  ];
};

const getRoundSections = (context, rounds) => {
  if (context.lockSponsorAssignment) {
    return [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            value: context.getPaidMessage(),
            customView: true,
          },
        ],
      },
    ];
  }
  return rounds.map?.((r) => ({
    type: "vertical xx-large-gap",
    title: `Round ${r.roundName ?? r.number}`,
    sectionActions: [
      {
        buttonTitle: `View All`,
        rightIcon: faChevronRight,
        type: "light-grey no-wrap",
        actionClick: () => context.viewRoundHoleAssignments(r),
      },
    ],
    fields: [
      {
        label: r.roundCourses?.map?.((c) => c?.course?.name)?.join?.(", "),
        value: (
          <TournamentSponsorHoleAssignmentRound
            {...r}
            viewRoundHoleAssignments={context.viewRoundHoleAssignments}
            sponsor={context?.getSponsor?.()}
            roundHoleOptions={context?.getTournamentSponsorRoundHoleOptions(r)}
          />
        ),
        customView: true,
      },
    ],
  }));
};

export const holeAssignmentForm = (context, rounds) => {
  if (context) {
    let form = [...getRoundSections(context, rounds)];
    return form;
  }
};
