import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { GSForm, GSSelect, GSSidePanelPage } from "golfstatus_react_components";
import React, { useState } from "react";
import "./tournament-list-filter.scss";
import { useNavigate } from "react-router-dom";
import {
  selectListFilterStatus,
  setFilterStatus,
} from "../../reducers/tournamentSlice";
import { useDispatch, useSelector } from "react-redux";

const TournamentListFilter = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filterStatus = useSelector(selectListFilterStatus);

  const [stateFilter, setStateFilter] = useState(filterStatus);

  const getNavigation = () => {
    return {
      leftIcon: faChevronLeft,
      leftButtonClick: backClick,
      title: "Filter",
    };
  };
  const selectOption = (option) => {
    setStateFilter(option);
  };
  const getContent = () => {
    const formSections = [
      {
        type: "vertical",
        title: "Tournament Status",
        fields: [
          {
            label: "status",
            value: (
              <GSSelect
                onChange={selectOption}
                selectedOption={stateFilter}
                options={[
                  { label: "All Tournaments", value: "all" },
                  { label: "Upcoming Tournaments", value: "future" },
                  { label: "Past Tournaments", value: "past" },
                  { label: "Tournament Drafts", value: "draft" },
                ]}
              />
            ),
            isEditable: true,
            customView: true,
          },
        ],
      },
    ];
    return (
      <GSForm formTitle="Filter Tournaments" formSections={formSections} />
    );
  };

  const getDrawer = () => {
    const filterActions = [
      { id: 2, name: "Apply", action: applyFilter, type: "black" },
      { id: 3, name: "Clear All", action: clearFilter, type: "grey" },
    ];
    return { actions: filterActions };
  };
  const backClick = () => {
    navigate("/tournaments");
  };
  const applyFilter = () => {
    dispatch(setFilterStatus(stateFilter));
    backClick();
  };
  const clearFilter = () => {
    dispatch(setFilterStatus({ label: "All", value: "all" }));
    backClick();
  };
  return (
    <tournament-list-filter>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-list-filter>
  );
};

export default TournamentListFilter;
