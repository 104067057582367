
import React from "react";
import { GSActionBar, GSEmptyList, GSItemList, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-rounds-exports.scss"
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";
import { useState } from "react";
import { useDocuments } from "../../../forms/TournamentPrintoutForms";
import NavigationListItem from "../../navigation-list-item";
import { useDispatch, useSelector } from "react-redux";
import { clearTournamentNotifications, downloadTournamentDocument, selectTournamentNotifications } from "../../../reducers/tournamentSlice";


//Name the component
const TournamentRoundsExports = (props) => {
  const dispatch = useDispatch()

  const match = useMatch("/:tournamentID/rounds/documents")

  const [teamDocuments, roundDocuments, leaderboardDocuments] = useDocuments(match);

  const [searchParams] = useSearchParams()

  const [search, setSearch] = useState((searchParams?.get("search") ?? ""));

  const navigate = useNavigate()
  const notifications = useSelector(selectTournamentNotifications)

  const clearSearch = () => {
    setSearch("");
  }

  const leftNavigation = () => {
    navigate(-1);
  };

  const gotoDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`)
  }

  const gotoTeamDocs = () => {
    navigate(`/${match.params.tournamentID}/teams/documents/?search=${search}`)
  }

  const gotoLeaderboardDocs = () => {
    navigate(`/${match.params.tournamentID}/leaderboards/documents?search=${search}`)
  }

  const gotoCreateRound = () => {
    navigate(`/${match.params.tournamentID}/rounds/new`)
  }

  const getEmptySearchMessage = () => {
    let teamFilteredDocuments = getFilteredDocs(teamDocuments)
    let leaderboardFilteredDocuments = getFilteredDocs(leaderboardDocuments)
    let availableActions = [
      {
        title: "Clear Search",
        onClick: clearSearch,
        type: "light-grey no-wrap",
      },
    ]
    if(teamFilteredDocuments.length > 0)
    {
      availableActions.push(
        {
          title: "Search Team Documents",
          onClick: gotoTeamDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    else if(leaderboardFilteredDocuments.length > 0)
    {
      availableActions.push(
        {
          title: "Search Leaderboard Documents",
          onClick: gotoLeaderboardDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    else{
      availableActions.push(
        {
          title: "Search All Documents",
          onClick: gotoDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for…"
        detail="Try changing the search term."
        actions={availableActions}
      />
    );
  };

  const getEmptyRoundsMessage = () => {
    return (
      <GSEmptyList
        title="Round Documents"
        detail="All round specific documents will be displayed here."
        actions={[{
          title: "Add Round",
          buttonIcon: faPlus,
          onClick: gotoCreateRound,
          type: "black no-wrap"
        }]}
      />
    );
  }

  const getMatchString = (doc) => {
    let matchString  = `${doc.label} ${doc.details}`.toLowerCase()
    if(doc.documents)
    {
      doc.documents.forEach?.(d => {matchString = `${matchString} ${getMatchString(d)}`})
    }
    return matchString
  }

  const searchMatch = (doc) => {
    return getMatchString(doc)?.includes?.(search?.toLowerCase())
  }

  const getFilteredDocs = (group) => {
    let filtered = group?.documents?.filter?.((doc) => 
      searchMatch(doc)
    )
    return (filtered ?? [])
  }

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round Documents`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const itemSelected = (item) => {
    if(item.disabled)
    {
      return;
    }
    if(item.isDownload){
      dispatch(downloadTournamentDocument(item))
    }
    else{
      const nestedSearch = getFilteredDocs(item)
      if(nestedSearch?.length === 0){
        navigate(`${item.url}`)
      }
      else{
        navigate(`${item.url}?search=${search}`)
      }
    }
  }
  const getContent = () => {
    return <gs-form>
      <GSActionBar type="form-header" header="Round Documents"></GSActionBar>
      <SearchBar search={search} setSearch={setSearch} placeholder="Search Documents..."></SearchBar>
      <GSItemList emptyMessage={search !== "" ? getEmptySearchMessage() : getEmptyRoundsMessage()} type="vertical selectable" items={getFilteredDocs(roundDocuments)} listItem={(item) => (<NavigationListItem page ={item}/>)} itemSelected={itemSelected}></GSItemList>
    </gs-form>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-rounds-exports>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-rounds-exports>
  );
};

//export the new namet
export default TournamentRoundsExports;