import React from "react";
import { GSForm, GSSidePanelPage, GSEmptyList } from "golfstatus_react_components";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-payments-watch.scss";
import { useFormDataValidation } from "../../../hooks/formHooks";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getTournamentPaymentSettings,
  selectCurrentPaymentSettings,
  setPaymentSetting,
} from "../../../reducers/settingsSlice";
import { paymentSettingsWatchForm } from "../../../forms/SettingsForms";
import { useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  getTournamentDonationWatchItems,
  selectCurrentTournamentDonationWatchItems,
  selectTournamentNotifications,
  setCurrentDonationWatchItem,
} from "../../../reducers/tournamentSlice";
import { useState } from "react";

//Name the component
const TournamentSettingsPaymentsWatch = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/settings/payments/watch");
  const notifications = useSelector(selectTournamentNotifications);

  const currentPaymentSettings = useSelector(selectCurrentPaymentSettings);
  const [context] = useFormDataValidation({
    initialValid: true,
    data: currentPaymentSettings,
    setData: setPaymentSetting,
  });

  const currentTournamentDonationWatchItems = useSelector(
    selectCurrentTournamentDonationWatchItems
  );

  context.getFitleredWatchItems = () => {
    return currentTournamentDonationWatchItems.filter?.((wi) =>  wi.email?.toLowerCase?.().includes?.(search?.toLowerCase?.()));
  };

  context.getSearch = () => {
    return { search, setSearch };
  };

  context.addWatchEmail = () => {
    dispatch(setCurrentDonationWatchItem({}));
    navigate("add");
  };

  context.editWatchEmail = (watchItem) => {
    navigate(`${watchItem.id}`);
  };

  context.getEmptyMessage = () => {
    if(search === ""){
      return <GSEmptyList title="Donation Watch Emails"
      detail="Everyone on this list will be notified when a donation has been made." 
      actions={[
        {
          title: "Add Email",
          buttonIcon: faPlus,
          onClick: context.addWatchEmail,
          type: "black",
        },
      ]}/>
    }

    return <GSEmptyList title="We couldn’t find the email address you’re looking for…"
    detail="Try changing the search term or add a new email address." 
    actions={[
      {
        title: "Clear Search",
        onClick: () => {
          setSearch("");
        },
        type: "light-grey",
      },
      {
        title: "Add Email",
        buttonIcon: faPlus,
        onClick: context.addWatchEmail,
        type: "black",
      },
      
    ]}/>
    
  }

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPaymentSettings(match.params.tournamentID));
      dispatch(getTournamentDonationWatchItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Donation Watch Emails`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormActions = () => {
    return [
      {
        title: "add email",
        type: "black no-wrap mobile-icon",
        actionIcon: faPlus,
        actionClick: context?.addWatchEmail,
      },
    ];
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Donation Watch Emails"
        formActions={getFormActions()}
        formSections={paymentSettingsWatchForm(context, currentPaymentSettings)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-settings-payments-watch>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-settings-payments-watch>
  );
};

//export the new namet
export default TournamentSettingsPaymentsWatch;
