import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
} from "../helpers/JSONapi";
import {
  addOnTeams,
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  leaderboardTypeSettings,
  saveThunkResponse,
  tournament,
  tournamentRounds,
} from "../app/api";

//get
export const getTournamentLeaderboardSettings = createAsyncThunk(
  "leaderboards/getTournamentLeaderboardSettings",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/leaderboard-type-settings`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getLeaderboardSetting = createAsyncThunk(
  "leaderboards/getLeaderboardSetting",
  async (id, thunkAPI) => {
    let url = `${leaderboardTypeSettings}/${id}`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getLeaderboardSettingRound = createAsyncThunk(
  "leaderboards/getLeaderboardSettingRound",
  async (id, thunkAPI) => {
    let url = `${leaderboardTypeSettings}/${id}/tournament-round`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getRoundLeaderboardTypes = createAsyncThunk(
  "leaderboard/getRoundLeaderboardTypes",
  async (roundID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${roundID}/leaderboard-types`
    );
    return response;
  }
);

export const getRoundSpecialLeaderboardTypes = createAsyncThunk(
  "leaderboard/getRoundSpecialLeaderboardTypes",
  async (roundID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${roundID}/special-leaderboard-types`
    );
    return response;
  }
);

export const getTournamentLeaderboards = createAsyncThunk(
  "leaderboards/getTournamentLeaderboards",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/leaderboards`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getTournamentAddOns = createAsyncThunk(
  "leaderboards/getTournamentAddOns",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/add-ons`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getTournamentAddOnTeams = createAsyncThunk(
  "leaderboards/getTournamentAddOnTeams",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/add-on-teams`;
    const response = await gsApi.get(url);
    return response;
  }
);

//create
export const createTournamentLeaderboardSetting = createAsyncThunk(
  "leaderboards/createTournamentLeaderboardSetting",
  async (setting, thunkAPI) => {
    let serializedData = getSerializedData(
      setting,
      "leaderboard-type-settings",
      getOpts(setting, [
        ...getIDRelationship("tournament"),
        ...getIDRelationship("tournamentRound"),
        ...getIDRelationship("leaderboardType"),
      ])
    );

    return await getResponse(
      gsApi.post,
      `${leaderboardTypeSettings}`,
      serializedData,
      thunkAPI
    );
  }
);
//save

export const saveTournamentLeaderboardSetting = createAsyncThunk(
  "leaderboards/saveTournamentLeaderboardSetting",
  async (setting, thunkAPI) => {
    let serializedData = getSerializedData(
      setting,
      "leaderboard-type-settings",
      getOpts(setting, [
        ...getIDRelationship("tournament"),
        ...getIDRelationship("tournamentRound"),
        ...getIDRelationship("leaderboardType"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${leaderboardTypeSettings}/${setting.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveAddOnteam = createAsyncThunk(
  "leaderboards/saveAddOnteam",
  async (addOnTeam, thunkAPI) => {
    let serializedData = getSerializedData(
      addOnTeam,
      "add-on-teams",
      getOpts(addOnTeam, [
        ...getIDRelationship("skinsAddOn"),
        ...getIDRelationship("tournamentTeam"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${addOnTeams}/${addOnTeam.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const addOnTeamUpdateAll = createAsyncThunk(
  "leaderboards/addOnTeamUpdateAll",
  async (update, thunkAPI) => {
    return await getResponse(
      gsApi.patch,
      `${addOnTeams}/update-all`,
      update,
      thunkAPI
    );
  }
);

//delete

export const removeLeaderboardSetting = createAsyncThunk(
  "leaderboards/removeLeaderboardSetting",
  async (id, thunkAPI) => {
    let url = `${leaderboardTypeSettings}/${id}`;
    const response = await gsApi.delete(url, {
      data: {},
    });
    return response;
  }
);

export const leaderboardSlice = createSlice({
  name: "leaderboards",
  initialState: {
    tournamentLeaderboards: [],
    tournamentLeaderboardSettings: [],
    currentLeaderboardSetting: {},
    tournamentLeaderboardTypes: [],
    roundLeaderboardTypes: [],
    roundSpecialLeaderboardTypes: [],
    currentLeaderboardType: {},
    tournamentAddOns: [],
    tournamentAddOnTeams: [],
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setCurrentLeaderboardSetting: (state, action) => {
      state.currentLeaderboardSetting = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    //get tournament leaederboards
    ...getThunkResponse(getTournamentLeaderboards, (state, action) => {
      state.tournamentLeaderboards = action.payload;
    }),
    //get round leaderboard types
    ...getThunkResponse(getRoundLeaderboardTypes, (state, action) => {
      state.roundLeaderboardTypes = action.payload;
    }),
    //get round special leaderboard types
    ...getThunkResponse(getRoundSpecialLeaderboardTypes, (state, action) => {
      state.roundSpecialLeaderboardTypes = action.payload;
    }),
    //tournament leaderboard Settings
    ...getThunkResponse(getTournamentLeaderboardSettings, (state, action) => {
      state.tournamentLeaderboardSettings = action.payload;
    }),
    //current leaderboard Setting
    ...getThunkResponse(getLeaderboardSetting, (state, action) => {
      state.currentLeaderboardSetting = {
        ...state.currentLeaderboardSetting,
        ...action.payload,
      };
    }),
    //current leaderboard Setting Round
    ...getThunkResponse(getLeaderboardSettingRound, (state, action) => {
      state.currentLeaderboardSetting.tournamentRound = action.payload;
    }),
    //Get Add Ons for skins
    ...getThunkResponse(getTournamentAddOns, (state, action) => {
      state.tournamentAddOns = action.payload;
    }),
    //Get Add On teams for skins
    ...getThunkResponse(getTournamentAddOnTeams, (state, action) => {
      state.tournamentAddOnTeams = action.payload;
    }),
    //Create leaderboard setting
    ...createThunkResponse(
      createTournamentLeaderboardSetting,
      (state, action) => {
        state.currentLeaderboardSetting = action.payload;
        state.tournamentLeaderboardSettings.push(action.payload);
      },
      "Leaderboard"
    ),
    //save leaderboard setting
    ...saveThunkResponse(
      saveTournamentLeaderboardSetting,
      (state, action) => {
        state.currentLeaderboardSetting = action.payload;
        let index = state.tournamentLeaderboardSettings.findIndex?.(
          (ls) => ls.id === action?.payload?.id
        );
        if (index !== -1) {
          const currentSetting = state.tournamentLeaderboardSettings[index]
          state.tournamentLeaderboardSettings[index] = {...currentSetting, ...action.payload};
        }
      },
      "Leaderboard"
    ),
    //save add on team
    ...saveThunkResponse(
      saveAddOnteam,
      (state, action) => {
        let index = state.tournamentAddOnTeams.findIndex?.(
          (ls) => ls.id === action?.payload?.id
        );
        if (index !== -1) {
          state.tournamentAddOnTeams[index] = action.payload;
        }
      },
      "Team"
    ),
    //update All Addons
    ...saveThunkResponse(
      addOnTeamUpdateAll,
      (state, action) => {
        state.tournamentAddOnTeams = action.payload;
      },
      "Teams"
    ),
    //remove LeaderboardSetting
    ...deleteThunkResponse(
      removeLeaderboardSetting,
      (state, action) => {
        state.tournamentLeaderboardSettings = state.tournamentLeaderboardSettings.filter?.(
          (t) => t.id !== action.meta.arg
        );
        state.currentLeaderboardSetting = {};
      },
      "Leaderboard"
    ),
  },
});

export const {
  setCurrentLeaderboardSetting,
  addNotification,
  clearNotifications,
} = leaderboardSlice.actions;

//export any selectors needed
export const selectTournamentLeaderboards = (state) =>
  state.leaderboards.tournamentLeaderboards;
export const selectTournamentLeaderboardSettings = (state) =>
  state.leaderboards.tournamentLeaderboardSettings;

export const selectCurrentLeaderboardSetting = (state) =>
  state.leaderboards.currentLeaderboardSetting;

export const selectRoundLeaderboardTypes = (state) =>
  state.leaderboards.roundLeaderboardTypes;

export const selectRoundSpecialLeaderboardTypes = (state) =>
  state.leaderboards.roundSpecialLeaderboardTypes;

export const selectCurrentLeaderboardType = (state) =>
  state.leaderboards.currentLeaderboardType;

export const selectTournamentAddOns = (state) =>
  state.leaderboards.tournamentAddOns;

export const selectTournamentAddOnTeams = (state) =>
  state.leaderboards.tournamentAddOnTeams;

export const selectLeaderboardLoading = (state) => state.leaderboards.loading;

export const selectLeaderboardNotifications = (state) =>
  state.leaderboards.notifications;

export default leaderboardSlice.reducer;

//ADD reducer to store.js
