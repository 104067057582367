import React, { useEffect, useMemo, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faIntercom} from "@fortawesome/free-brands-svg-icons"
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-deactivate.scss";
import { useNotificationAction, useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";
import { getWarningTitle, warnItemForm } from "../../../forms/DeleteForm";
import { useDispatch, useSelector } from "react-redux";
import { clearRoundNotifications, deactivateRoundScorecards, getRound, getRoundScorecards, selectCurrentRound, selectCurrentRoundScorecards, selectLoading, selectRoundNotifications } from "../../../reducers/roundSlice";
import TournamentRoundLineItem from "./tournament-round-line-item";
import { getRoundName } from "../../../helpers/Converters";
import { useIntercom } from "react-use-intercom";

//Name the component
const TournamentRoundDeactivate = (props) => {

  const intercom = useIntercom();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch("/:id/rounds/:roundID/deactivate");
  const currentRound = useSelector(selectCurrentRound);
  const scorecards = useSelector(selectCurrentRoundScorecards)
  const notifications = useSelector(selectRoundNotifications);
  const loading = useSelector(selectLoading)

  const scorecardsNotEmptyTitle = "Scoring In Progress";
  const scorecardsEmptyTitle = "Deactivate Round";

  const scorecardsNotEmptyDescription =
    `Scores have already been entered for Round ${getRoundName(currentRound)}, so the round can not be deactivated. If you need help, reach out to GolfStatus’ support team.`;

    const scorecardsEmptyDescription =
    `Deactivating this round will not allow teams to score via the GolfStatus App. Scorecards will not be editable until the round has been re-started.`;


    const active = useMemo(() => {
      return scorecards.some?.(scorecard => scorecard.mobileStatus !== "no_activity")
    }, [scorecards])

    const [failedDelete, setFailedDelete] = useState(false)

    const hasScorecards = active || failedDelete

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
      dispatch(getRoundScorecards(match.params.roundID))
      dispatch(clearRoundNotifications())
      return () => {dispatch(clearRoundNotifications())}
    }
  }, [match.params.roundID, dispatch]);

  useNotificationNavigation("removed", notifications, -1, "scorecards" )

  useNotificationAction("failed", notifications, () => {
    setFailedDelete(true)
  })

  const save = () => {
    dispatch(deactivateRoundScorecards(match.params.roundID))
  };

  const getDrawerActions = () => {
    let actions = [
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if(hasScorecards){
      actions.unshift({
        name: "Contact Golfstatus",
        buttonIcon: faIntercom ,
        action: () => {intercom.show?.()},
        type: "black",
      })
    }
    else{
      actions.unshift(
        {
          name: "Deactivate Round",
          action: save,
          type: "orange",
        },
      )
    }
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Deactivate Round`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getWarningTitle(hasScorecards ? scorecardsNotEmptyTitle : scorecardsEmptyTitle)}
        formSections={warnItemForm(
          hasScorecards ? scorecardsNotEmptyDescription : scorecardsEmptyDescription,
          <TournamentRoundLineItem round = {currentRound} hideCopy = {true} hideActions={true} />
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-round-deactivate>
      <GSSidePanelPage
      loading={loading.length > 0}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-deactivate>
  );
};

//export the new namet
export default TournamentRoundDeactivate;
