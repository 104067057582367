import "./tournament-team-remove.scss";
import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentTeam,
  removeTournamentTeam,
  selectCurrentTeam,
  selectTeamsNotifications,
} from "../../../reducers/teamsSlice";
import { addNotification } from "../../../reducers/appSlice";
import { useNotificationAction, useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import TournamentTeamItem from "./tournament-team-item";
//Name the component
const TournamentTeamRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();

  const currentTeam = useSelector(selectCurrentTeam);

  const notifications = useSelector(selectTeamsNotifications);

  const match = useMatch("/:tournamentID/teams/:teamID/remove");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTournamentTeam(match?.params?.teamID));
  }, [dispatch, match.params.teamID]);

  useNotificationAction("removed", notifications, (notification) => {
    dispatch(addNotification(notification));
    dispatch(clearNotifications());
  });

  useNotificationNavigation("removed", notifications, -2)

  const removeTeam = () => {
    setIsValid(false)
    dispatch(removeTournamentTeam(match?.params?.teamID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removeTeam,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Team`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getTeamName = () => {
    return currentTeam.name ??
              `${currentTeam.contactName}`
  }

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Team")}
        formSections={removeItemForm(getTeamName(), <TournamentTeamItem {...currentTeam} verticalPlayers={true} />, "delete")}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-team-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-remove>
  );
};

//export the new namet
export default TournamentTeamRemove;
