import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-custom-field-remove.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRegistrationCustomField,
  getRegistrationCustomField,
  selectCurrentCutomField,
  selectNotifications,
} from "../../../../reducers/packageSlice";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import TournamentPackageFormItem from "./tournament-package-form-item";

//Name the component
const TournamentPackageCustomFieldRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  //hooks
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/forms/:formID/:fieldID/remove"
  );
  const navigate = useNavigate();

  //selectors
  const currentCustomField = useSelector(selectCurrentCutomField);
  const packageNotifications = useSelector(selectNotifications);

  //custom hooks
  useNotificationNavigation("removed", packageNotifications, -2);

  //effects
  useEffect(() => {
    if (match?.params?.fieldID) {
      dispatch(getRegistrationCustomField(match?.params?.fieldID));
    }
  }, [dispatch, match?.params?.fieldID]);

  //component functionality

  const remove = () => {
    setIsValid(false);
    dispatch(deleteRegistrationCustomField(match?.params?.fieldID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: remove,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Question`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Question")}
        formSections={removeItemForm(
          currentCustomField.name,
          <TournamentPackageFormItem {...currentCustomField} />,
          "delete",
          "Are you sure you want to delete this question from this form? This cannot be undone."
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications: packageNotifications,
  });

  return (
    //name the component tag
    <tournament-package-custom-field-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-custom-field-remove>
  );
};

//export the new namet
export default TournamentPackageCustomFieldRemove;
