import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-activation.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTournamentPackage,
  saveTournamentPackage,
  selectCurrentTournamentPackage,
  selectNotifications,
  setCurrentPackage,
  clearNotifications,
  getTournamentPackages,
} from "../../../../reducers/packageSlice";
import { useMemo } from "react";
import { getPackageActivationForm } from "../../../../forms/TournamentPackageForm";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";

//Name the component
const TournamentPackageActivation = (props) => {
  const [isValid, setIsValid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/activation"
  );

  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const packageNotifications = useSelector(selectNotifications);

  const context = useMemo(() => ({}), []);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.packageID]);

  context.updatePackage = (value, property) => {
    setIsValid(true);
    let update = { ...currentPackage };
    update[property] = value;
    dispatch(setCurrentPackage(update));
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentPackage };
    if (currentPackage.activateOn) {
      update.activateOn = new Date(currentPackage.activateOn).toISOString();
    }
    if (currentPackage.deactivateOn) {
      update.deactivateOn = new Date(currentPackage.deactivateOn).toISOString();
    }
    dispatch(saveTournamentPackage(update)).then(() =>
      dispatch(getTournamentPackages(match?.params?.tournamentID))
    );
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${currentPackage?.name} Activation & Deactivation`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={`${currentPackage?.name} Activation & Deactivation`}
        formSections={getPackageActivationForm(context, currentPackage)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] =
    useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-package-activation>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-activation>
  );
};

//export the new namet
export default TournamentPackageActivation;
