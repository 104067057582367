import {faBan, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import {
  GSActionBar,
  GSButton,
  GSCircleImage,
  GSInfoGroup,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";

import "./tournament-team-item.scss";

const TournamentTeamItem = (props) => {
  const {
    name,
    contactName,
    players,
    entryCode,
    adjustedHandicap,
    header,
    headerName,
    verticalPlayers,
    disqualified,
    disqualificationReason,
    mainLeaderboardScoreDetails,
    messageClicked
  } = props;

  //const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

  const contactInfo = {
    sectionItems: [
      {
        type: "primary no-wrap full-width capitalize",
        value: `${name ?? `${contactName}`} ${
          adjustedHandicap > 0 ? `(${adjustedHandicap})` : ""
        }`,
      },
      { type: "secondary", value: entryCode },
    ],
  };

  const noPlayerInfo = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width capitalize",
        value: `No Players`,
      },
    ],
  };

  const playerInfo = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: (
          <GSItemList
            type={`${
              verticalPlayers ? "vertical" : "horizontal"
            } mobile-vertical large-gap`}
            items={players}
            listItem={(player) => (
              <div className="player">
                <GSCircleImage size="large" url={player.avatar}></GSCircleImage>
                {`${player.name} ${
                  player.adjustedHandicap > 0
                    ? `(${player.adjustedHandicap})`
                    : ""
                }`}
                {player.signupMessage && (
                  <GSButton type="secondary" buttonIcon={faCommentAlt} onClick={(e) => {messageClicked?.({...player, contactName: player.name}); e?.stopPropagation?.() }}></GSButton>
                )}
              </div>
            )}
          />
        ),
      },
    ],
  };

  const teamInfo = {
    fit: "stretch",
    sections: players?.length === 0 ? [{ ...contactInfo },{...noPlayerInfo}] :  [{ ...contactInfo }, { ...playerInfo }],
  };

  const registrationInfo = {
    sections: [
    ],
  };

  if(disqualified)
  {
    registrationInfo.sections.push({
      sectionItems: [
        {
          type: "primary full-width",
          value: (
            <GSButton
              type={`full-width pill secondary ${
                disqualified ? "orange" : "transparent"
              }`}
              buttonIcon={faBan}
              title={disqualified ? disqualificationReason.replace?.("_", " ") : ""}
            ></GSButton>
          ),
        },
      ],
    })
  }

  else if(mainLeaderboardScoreDetails?.totalScore){
    registrationInfo.sections.push({
      fit:"end-justify",
      sectionItems: [
        {
          type:"primary",
          value: mainLeaderboardScoreDetails?.totalScore
        },
        {
          type:"secondary",
          value: mainLeaderboardScoreDetails?.netTotal
        }
      ]
    })
  }

  const getGroup = () => {
    let group = [{ ...teamInfo }, { ...registrationInfo }];
    return group;
  };

  return (
    <tournament-team-item class={header ? "header" : ""}>
      {header ? (
        <GSActionBar header={headerName}></GSActionBar>
      ) : (
        <GSInfoGroup
          listType="mobile-vertical"
          dataGroups={getGroup()}
        ></GSInfoGroup>
      )}
    </tournament-team-item>
  );
};

export default TournamentTeamItem;
