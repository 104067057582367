import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-watch-email-remove.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  deletePackageWatchItem,
  getPackageWatchItem,
  getTournamentPackage,
  selectCurrentTournamentPackage,
  selectCurrentWatchItem,
  selectNotifications,
} from "../../../../reducers/packageSlice";
import {
  useNotificationNavigation,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import TournamentPackageWatchItem from "./tournament-package-watch-item";

//Name the component
const TournamentPackageWatchEmailRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/watch-emails/:watchItemID/remove"
  );

  //selectors
  const currentWatchItem = useSelector(selectCurrentWatchItem);
  const currentPackage = useSelector(selectCurrentTournamentPackage)
  const notifications = useSelector(selectNotifications);

  //custom hooks
  useNotificationNavigation("removed", notifications, -2);

  useEffect(() => {
    if (match?.params?.watchItemID !== "add") {
      dispatch(getPackageWatchItem(match.params.watchItemID));
    }
  }, [dispatch, match?.params?.watchItemID]);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match?.params?.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  const remove = () => {
    setIsValid(false);
    dispatch(deletePackageWatchItem(match?.params?.watchItemID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Remove",
        isDisabled: !isValid,
        action: remove,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Email`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Email")}
        formSections={removeItemForm(
          currentWatchItem.email,
          <TournamentPackageWatchItem {...currentWatchItem} />,
          "delete",
          `Are you sure you want to delete ${currentWatchItem.email}? They will no longer receive watch emails for ${currentPackage.name}.`
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({ notifications });

  return (
    //name the component tag
    <tournament-package-watch-email-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-watch-email-remove>
  );
};

//export the new namet
export default TournamentPackageWatchEmailRemove;
