import React, { useEffect } from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faArrowAltCircleDown,
  faArrowDown,
  faArrowUp,
  faChevronLeft,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-result.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboardSetting,
  getTournamentLeaderboards,
  selectCurrentLeaderboardSetting,
  selectLeaderboardLoading,
  selectTournamentLeaderboards,
} from "../../../reducers/leaderboardSlice";
import {
  clearNotifications,
  createTeamFlights,
  getTournamentFlight,
  getTournamentFlights,
  saveTeamFlight,
  selectCurrentTournamentFlight,
  selectFlightNotifications,
  selectTournamentFlights,
  updateFlightTeams,
} from "../../../reducers/flightSlice";
import TournamentLeaderboardResultLineItem from "./tournament-leaderboard-result-line-item";
import {
  clearTournamentNotifications,
  downloadTournamentDocument,
  selectCurrentTournament,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { getDateTimeOn } from "../../../helpers/Dates";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";
import { useMemo } from "react";
import { leaderboardTypeSettings } from "../../../app/api";
import { useState } from "react";
import SearchBar from "../../search-bar";
//Name the component
const TournamentLeaderboardResult = (props) => {

  const [isValid, setIsValid] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/results/*"
  );
  const matchFlight = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/:flightID/results"
  );
  

  const currentLeaderboardSetting = useSelector(
    selectCurrentLeaderboardSetting
  );
  const currentTournamentFlight = useSelector(selectCurrentTournamentFlight);
  const tournamentLeaderboards = useSelector(selectTournamentLeaderboards);
  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentFlights = useSelector(selectTournamentFlights);
  const loadingLeaderboards = useSelector(selectLeaderboardLoading);
  const flightNotifications = useSelector(selectFlightNotifications);
  const tournamentNotifications = useSelector(selectTournamentNotifications);

  useEffect(() => {
    if (matchFlight?.params?.tournamentID) {
      dispatch(getTournamentFlights(matchFlight.params.tournamentID));
    }
  }, [dispatch, matchFlight?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentFlights(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (matchFlight?.params?.flightID) {
      dispatch(getTournamentFlight(matchFlight.params.flightID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, matchFlight?.params?.flightID]);

  useEffect(() => {
    let id =
      match?.params?.leaderboardSettingID ??
      matchFlight?.params?.leaderboardSettingID;
    if (id) {
      dispatch(getLeaderboardSetting(id));
    }
  }, [
    dispatch,
    match?.params?.leaderboardSettingID,
    matchFlight?.params?.leaderboardSettingID,
  ]);

  const downloadLeaderboard = () => {
    let document = {
      url: `${leaderboardTypeSettings}/${match.params.leaderboardSettingID}/leaderboard-export`,
      name: `${currentTournament.name}-${currentLeaderboardSetting.name}-export`,
      ext: ".pdf",
    };
    dispatch(downloadTournamentDocument(document));
  };

  const getName = (score) => {
    return score?.name?.toLowerCase?.();
  };

  const getSkinName = (score) => {
    return score?.winner?.toLowerCase?.();
  };

  const getMatchName = (score) => {
    const teams = score?.matchScores?.flatMap?.((ms) => ms?.playerHash);
    const teamNames = teams?.map((t) => t.name?.toLowerCase?.());
    return teamNames?.join?.(" ");
  };
  const getScores = () => {
    const leaderboard = currentLeaderboard;
    const skins = leaderboard?.scoresAbbrev?.filter?.((s) =>
      getSkinName(s)?.includes?.(search?.toLowerCase?.())
    );
    const results =
      leaderboard?.scoringSystem === "match_play" && leaderboard?.type === "round_match"
        ? leaderboard.scores?.filter?.((s) =>
            getMatchName(s)?.includes?.(search?.toLowerCase?.())
          )
        : leaderboard?.scores?.filter?.((s) =>
            getName(s)?.includes?.(search?.toLowerCase?.())
          );
    return currentLeaderboardSetting?.skins ? skins : results;
  };

  const saveFlights = () => {
    setUnsaved(false);
    setIsValid(false);
    tournamentFlights?.forEach?.((tf) => {
      tf.teamFlights?.forEach?.((ft) => {
        let update = { ...ft };
        update["tournament-flight"] = tf;
        update.tournament = currentTournament;
        update["tournament-team"] = { id: ft.teamScores.teamKey };
        dispatch(saveTeamFlight(update));
      });
    });
  };

  const getDrawerActions = () => {
    if (!getScores()) {
      return [];
    }
    let actions = [
      {
        name: "download",
        action: downloadLeaderboard,
        type: "light-grey no-wrap",
        buttonIcon: faArrowAltCircleDown,
      },
    ];
    if (
      tournamentFlights.length > 0 &&
      currentLeaderboard?.tournamentFlight === null &&
      currentLeaderboardSetting?.flighted
    ) {
      actions.unshift({
        name: "cancel",
        isDisabled: !isValid,
        action: leftNavigation,
        type: "grey no-wrap",
      });

      actions.unshift({
        name: "save",
        isDisabled: !isValid,
        action: saveFlights,
        type: "black no-wrap",
      });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !unsaved) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const leaderboardName = useMemo(() => {
    let leaderboardName = `${currentLeaderboardSetting?.name?.replace?.(
      "-",
      " "
    )}`;
    if (matchFlight?.params?.flightID && currentTournament.preflight) {
      leaderboardName += ` ${currentTournamentFlight?.name} Flight`;
    }
    return leaderboardName;
  }, [
    currentLeaderboardSetting?.name,
    matchFlight?.params?.flightID,
    currentTournament?.preflight,
    currentTournamentFlight?.name,
  ]);

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${leaderboardName}`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const finalizeFlights = () => {
    dispatch(createTeamFlights(matchFlight?.params?.tournamentID)).then?.(
      () => {
        dispatch(getTournamentLeaderboards(matchFlight?.params?.tournamentID));
        dispatch(getTournamentFlights(matchFlight.params.tournamentID));
      }
    );
  };

  const viewRounds = () => {
    let m = match ? match : matchFlight;
    navigate(`/${m?.params?.tournamentID}/rounds`);
  };

  const getEmptyMessage = () => {
    return (
      <GSEmptyList
        title="Scores are not available..."
        detail="Make sure the rounds are live and scorecards have been generated."
        actions={[
          {
            title: "View Rounds",
            onClick: viewRounds,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptyPostFlightMessage = () => {
    return (
      <GSEmptyList
        title="Flighted Results"
        detail="Flighted results are available once the all scores have been confirmed and the event has been finalized."
        actions={[
          {
            title: "Finalize Tournament",
            onClick: finalizeFlights,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey no-wrap",
          },
        ]}
      />
    );
  };

  const resultSelected = (result) => {
    if (result?.teamKey) {
      navigate(result.teamKey);
    }
    if (result?.teamKeys) {
      navigate(result.teamKeys[0]);
    }
  };

  const flightIncomplete = () => {
    return !currentTournament.preflight && currentLeaderboardSetting.flighted;
  };

  const currentLeaderboard = useMemo(() => {
    let leaderboard = {};
    if (
      !currentLeaderboardSetting?.flighted ||
      currentTournament?.preflight ||
      matchFlight?.params?.flightID === undefined
    ) {
      leaderboard = tournamentLeaderboards.find(
        (lb) => lb?.leaderboardTypeSetting?.id === currentLeaderboardSetting?.id && lb.tournamentFlight?.id === (matchFlight?.params?.flightID ?? lb.tournamentFlight?.id)
      );
    } else {
      leaderboard.scores = tournamentFlights
        ?.find((f) => f.id === matchFlight?.params?.flightID)
        ?.teamFlights?.map((tf) => ({
          ...tf.teamScores,
          displayPosition: tf.position,
        }));
    }
    return leaderboard;
  }, [
    currentLeaderboardSetting?.flighted,
    matchFlight?.params?.flightID,
    currentTournament?.preflight,
    tournamentFlights,
    tournamentLeaderboards,
    currentLeaderboardSetting?.id
  ]);

  const currentLeaderboardFlights = useMemo(
    () =>
      tournamentFlights?.filter?.(
        (tf) =>
          tf?.leaderboardTypeSetting?.id ===
          currentLeaderboard?.leaderboardTypeSetting?.id
      ),
    [tournamentFlights, currentLeaderboard?.leaderboardTypeSetting?.id]
  );

  const getTeamIndex = (flight) => {
    const index = getScores()?.findIndex?.(
      (s) => s.teamKey === flight?.teamFlights?.[0]?.teamScores?.teamKey
    );
    return index;
  };

  const getIndexActions = (index, flight) => {
    if (index === 0) {
      return [];
    }
    return [
      {
        actionIcon: faArrowUp,
        type: "white",
        actionClick: () => moveIndexUp(index),
      },
      {
        actionIcon: faArrowDown,
        type: "white",
        actionClick: () => moveIndexDown(index),
      },
    ];
  };

  const moveIndexUp = (index) => {
    setIsValid(true);
    let prevFlight = { ...currentLeaderboardFlights?.[index - 1] };
    let flight = { ...currentLeaderboardFlights?.[index] };

    let flightToRemoveFrom = [...prevFlight.teamFlights];
    let flightToAddTo = [...flight.teamFlights];

    let moveTeam = flightToRemoveFrom.slice(-1);

    flightToRemoveFrom = flightToRemoveFrom.slice(
      0,
      flightToRemoveFrom.length - 1
    );
    flightToAddTo.unshift(moveTeam[0]);

    prevFlight.teamFlights = flightToRemoveFrom.map?.((tf, index) => ({
      ...tf,
      position: index + 1,
      displayPosition: `${index + 1}`,
    }));
    flight.teamFlights = flightToAddTo.map?.((tf, index) => ({
      ...tf,
      position: index + 1,
      displayPosition: `${index + 1}`,
    }));

    dispatch(updateFlightTeams(prevFlight));
    dispatch(updateFlightTeams(flight));
  };

  const moveIndexDown = (index) => {
    setIsValid(true);
    let prevFlight = { ...currentLeaderboardFlights?.[index - 1] };
    let flight = { ...currentLeaderboardFlights?.[index] };

    let flightToRemoveFrom = [...flight.teamFlights];
    let flightToAddTo = [...prevFlight.teamFlights];

    let moveTeam = flightToRemoveFrom.slice(0);

    flightToRemoveFrom = flightToRemoveFrom.slice(1);
    flightToAddTo.push(moveTeam[0]);

    prevFlight.teamFlights = flightToAddTo.map?.((tf, index) => ({
      ...tf,
      position: index + 1,
      displayPosition: `${index + 1}`,
    }));
    flight.teamFlights = flightToRemoveFrom.map?.((tf, index) => ({
      ...tf,
      position: index + 1,
      displayPosition: `${index + 1}`,
    }));

    dispatch(updateFlightTeams(prevFlight));
    dispatch(updateFlightTeams(flight));
  };

  const getSearch = () => {
    return {
      placeholder: "Search Players...",
      search,
      setSearch,
      type: "sticky-side-panel pad"
    };
  };

  const getContent = () => {
    let flights = [];
    if (tournamentFlights?.length > 0 && currentLeaderboard?.type !== "skins") {
      flights = currentLeaderboardFlights?.map?.((f, index) => ({
        index: getTeamIndex(f),
        view: (
          <GSActionBar
          type="grey-header x-large-pad"
            key={f.id}
            header={`${f.name} Flight`}
            pageActions={getIndexActions(index, f)}
          />
        ),
      }));
    }
    return (
      <gs-form>
        <GSActionBar type="form-header" header={leaderboardName} />
        <SearchBar {...getSearch()} />
        <GSItemList
          type="vertical selectable"
          items={getScores()}
          indexItems={flights}
          listItem={(item) => (
            <TournamentLeaderboardResultLineItem
              leaderboard={currentLeaderboard}
              {...item}
            />
          )}
          itemSelected={resultSelected}
          emptyMessage={
            flightIncomplete()
              ? getEmptyPostFlightMessage()
              : search === ""
              ? getEmptyMessage()
              : getEmptySearchMessage()
          }
          loading={loadingLeaderboards?.includes?.("getTournamentLeaderboards")}
          loadingMainText="Loading Leaderboards"
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: [...flightNotifications, ...tournamentNotifications],
    timeoutAction,
    bannerActions: getSaveBannerActions(saveFlights, leftNavigation),
    defaultBanner: {
      title: getNotificationItemInfo(
        currentLeaderboard?.refreshedAt
          ? `This leaderboard was last updated at ${getDateTimeOn(
              currentLeaderboard?.refreshedAt
            )}`
          : `This leaderboard was last fetched at ${getDateTimeOn()}`,
        faClock
      ),
      state: "grey",
    },
  };

  const [bannerNotifications, setUnsaved, unsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-leaderboard-result>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-result>
  );
};

//export the new namet
export default TournamentLeaderboardResult;
