import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-custom-field-add-edit.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  createPackageFormItem,
  getRegistrationCustomField,
  getTournamentForm,
  getTournamentPackage,
  getTournamentPackageFormFields,
  savePackageFormItem,
  selectCurrentCutomField,
  selectCurrentTournamentPackageForm,
  selectCurrentTournamentPackageFormFileds,
  selectNotifications,
  setCurrentCustomField,
} from "../../../../reducers/packageSlice";
import { getPackageCustomFieldForm } from "../../../../forms/TournamentPackageForm";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { useEffect } from "react";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useFormValidation } from "../../../../hooks/formHooks";

//Name the component
const TournamentPackageCustomFieldAddEdit = (props) => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/forms/:formID/:fieldID"
  );

  //context
  const [context, isValid, setIsValid] = useFormValidation(false);
  const [optionsList, setOptionsList] = useState([]);
  const [currentOptionText, setCurrentOptionText] = useState("");

  context.updateCustomField = (value, property) => {
    setIsValid(true);
    let update = { ...currentCustomField };
    update[property] = value;
    dispatch(setCurrentCustomField(update));
  };

  context.submitForm = () => {
    if (isValid) {
      save();
    }
  };

  context.addOption = () => {
    setOptionsList([...optionsList, {value: currentOptionText, index: optionsList.length}]);
    setCurrentOptionText("");
    setIsValid(true);
  }
  context.removeOption = (option) => {
    let list = [...optionsList];
    list.splice(option.index, 1);
    // reset the optionsList to restore the indexes
    setOptionsList(list.map((o, ndx) => {
      return {
        value: o.value,
        index: ndx
      }
    }));
    setIsValid(true);
  }
  context.formIsValid = () => {
    return isValid;
  }
  context.updateCurrentOptionText = (value) => {
    setCurrentOptionText(value);
  }
  context.getCurrentOptionText = () => {
    return currentOptionText;
  }

  context.typeOptions = [
    {
      value: 'text',
      label: 'Text'
    },
    {
      value: 'select',
      label: 'Dropdown'
    },
    {
      value: 'number',
      label: 'Number'
    },
  ];

  //selectors
  const currentForm = useSelector(selectCurrentTournamentPackageForm);
  const currentPackageFormFields = useSelector(
    selectCurrentTournamentPackageFormFileds
  );
  const currentCustomField = useSelector(selectCurrentCutomField);
  const currentTournament = useSelector(selectCurrentTournament);
  const packageNotifications = useSelector(selectNotifications);

  //custom hooks
  useNotificationNavigation("created", packageNotifications, -1);

  //effects
  useEffect(() => {
    if (match?.params?.formID !== "add") {
      dispatch(getTournamentForm(match.params.formID));
      dispatch(getTournamentPackageFormFields(match.params.formID));
    }
  }, [dispatch, match?.params?.formID]);

  useEffect(() => {
    if (match?.params?.fieldID !== "add") {
      dispatch(getRegistrationCustomField(match?.params?.fieldID));
    }
  }, [dispatch, match?.params?.fieldID]);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  useEffect(() => {
    if (currentCustomField?.fieldType === 'select' && currentCustomField?.options) {
      setOptionsList(currentCustomField.options.map((option, ndx) => {
        return {
          value: option,
          index: ndx
        }
      }))
    }
  }, [currentCustomField?.options, currentCustomField?.fieldType])

  //component functionality

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentCustomField };
    update.active = true;
    update.tournament = currentTournament;
    update.registrationFieldSet = currentForm;
    update.options = optionsList.map((o) => o.value);

    if (update.id) {
      dispatch(savePackageFormItem(update));
    } else {
      update.position = currentPackageFormFields?.length;
      dispatch(createPackageFormItem(update));
    }
  };

  const remove = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if (match?.params?.fieldID !== "add") {
      actions.push({ name: "delete question", action: remove, type: "transparent red" });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1, { replace: true });
  };

  const getNavigation = () => {
    return {
      title: `Question Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle={match?.params?.fieldID === "add" ? "Add Question" : "Edit Question"}
        formSections={getPackageCustomFieldForm(context, currentCustomField, optionsList)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-custom-field-add-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-custom-field-add-edit>
  );
};

//export the new namet
export default TournamentPackageCustomFieldAddEdit;
