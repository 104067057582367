import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-event-details.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  createPromotion,
  saveTournament,
  saveTournamentPromotions,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCurrentTournament,
} from "../../../reducers/tournamentSlice";
import { getRegistrationEventDetailsForm } from "../../../forms/PromotionForm";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionEventDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentNotifications = useSelector(selectTournamentNotifications);

  const [context, isValid, setIsValid] = useFormValidation(false);

  useEffect(() => {
    dispatch(clearTournamentNotifications())
    return dispatch(clearTournamentNotifications())
  },[dispatch])

  context.updateTournamentPromotion = (value, property) => {
    setIsValid(true);
    let updateTournament = { ...currentTournament };
    let updateTournamentPromotion = {
      ...currentTournament.tournamentPromotion,
    };
    updateTournamentPromotion[property] = value;
    updateTournament.tournamentPromotion = updateTournamentPromotion;
    dispatch(setCurrentTournament(updateTournament));
  };

  context.updateTournament = (value, property) => {
    setIsValid(true);
    let update = { ...currentTournament };
    update[property] = value;
    dispatch(setCurrentTournament(update));
  };

  context.setIsValid = (value) => {
    setIsValid(value)
  }

  context.getLogo = (property) => {
    if (
      currentTournament.tournamentPromotion?.[property] &&
      currentTournament.tournamentPromotion?.[property] !==
        "/logos/default/missing.png"
    ) {
      return [
        {
          url: currentTournament.tournamentPromotion?.[property],
          type: "image",
        },
      ];
    }
    return [];
  };

  context.getVideo = (property) => {
    if (
      currentTournament.tournamentPromotion?.[property] &&
      currentTournament.tournamentPromotion?.[property] !==
        "/logos/default/missing.png"
    ) {
      return [
        {
          url: currentTournament.tournamentPromotion?.[property],
          type: "video/mp4",
        },
      ];
    }
    return [];
  };

  context.setLogo = (files, property) => {
    if (files.length === 0) {
      context.updateTournamentPromotion(undefined, property);
      return;
    }
    const file = files[0];
    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        context.updateTournamentPromotion(reader.result, property);
      });

      reader.readAsDataURL(file);
    }
  };

  context.getBannerImageOverlayOptions = () => {
    return [
      { label: "Dark", value: "dark" },
      { label: "Medium", value: "medium" },
      { label: "Light", value: "light" },
      { label: "None", value: "none" },
    ];
  };

  context.getBannerImageOverlayStyle = () => {
    const style = currentTournament?.tournamentPromotion?.bannerImageOverlayStyle || "medium";
    return {value: style};
  };

  context.setBannerImageOverlayStyle = (option) => {
    context.updateTournamentPromotion(option.value, "bannerImageOverlayStyle");
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    dispatch(saveTournament(currentTournament));
    let promotion = { ...currentTournament.tournamentPromotion };
    promotion.tournament = currentTournament;
    if (promotion.id) {
      dispatch(saveTournamentPromotions(promotion));
    } else {
      dispatch(createPromotion(promotion));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Event Site Homepage`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Event Site Homepage"
        formSections={getRegistrationEventDetailsForm(
          context,
          currentTournament
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-promotion-event-details>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-promotion-event-details>
  );
};

//export the new namet
export default TournamentPromotionEventDetails;
