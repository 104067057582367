import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GSInput } from "golfstatus_react_components";
import React from "react";
import "./search-bar.scss"
const SearchBar = (props) => {
  const {search, setSearch, placeholder, type} = props
  return (
    <search-bar class={type ? type : "pad"}>
      <GSInput
        onChange={(e) => {
          setSearch?.(e.target.value);
        }}
        textValue={search}
        placeholder={placeholder}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={() => {setSearch?.("")}}
      />
    </search-bar>
  );
};

export default SearchBar
