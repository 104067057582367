import { GSQuickFilter } from "golfstatus_react_components";
import React, { useState } from "react";
import "./course-quick-filter.scss";
import CourseLineItem from "./course-line-item";
import { useEffect } from "react";
import { useCallback } from "react";

const CourseQuickFilter = (props) => {
  const [courseName] = useState("");
  const [selectedCourses, setSelectedCourses] = useState(props.selectedCourses);

  const selectCourse = useCallback((course) => {
    props?.inputTouched?.()
    props?.selectCourse?.(course);
    setSelectedCourses([...(selectedCourses ?? []), course]);
  }, [props, setSelectedCourses, selectedCourses]);

  const removeCourse = (course) => {
    props?.removeCourse?.(course);
    setSelectedCourses(selectedCourses.filter((c) => c.id !== course.id));
  };

  const getFilteredCourses = useCallback(() => {
    return props.selectedFacility?.courses?.filter?.((c) => c.active && c.name.includes?.(courseName))
      ?.map?.((c, index) => ({
        id: selectedCourses?.length,
        scorecardPosition: (selectedCourses ?? [])?.length + 1,
        numberOfHoles: c.numberOfHoles,
        holesToPlay: "all_holes",
        course: { ...c },
      }));
  },[courseName, props.selectedFacility?.courses, selectedCourses ])

  useEffect(() => {
    if(selectedCourses.length === 0 && getFilteredCourses()?.length === 1 && courseName === ""){
      selectCourse(getFilteredCourses()?.[0])
    }
  }, [getFilteredCourses, courseName, selectedCourses.length, selectCourse])

  const teeSelected = (courseID, tee) => {
    props?.teeSelected?.(courseID, tee);
  };

  const teeRemoved = (courseID, tee) => {
    props?.teeRemoved?.(courseID, tee);
  };

  const holesSelected = (courseID, holes) => {
    props?.holesSelected?.(courseID, holes);
  };

  return (
    <course-quick-filter>
      <GSQuickFilter
        itemSelected={selectCourse}
        filteredList={getFilteredCourses()}
        selectedList={selectedCourses}
        emptySelectedList="Select a course from the list below."
        itemRemoved={removeCourse}
        getItem={(roundCourse) => (
          <CourseLineItem
            teeSelected={teeSelected}
            teeRemoved={teeRemoved}
            holesSelected={holesSelected}
            enableHoleSelect={selectedCourses?.includes?.(roundCourse)}
            enableTeeSelect={selectedCourses?.includes?.(roundCourse)}
            {...roundCourse}
          />
        )}
        multiple={true}
        selectListType="grey-empty-list rounded-empty-list small-gap search-list"
      />
    </course-quick-filter>
  );
};

export default CourseQuickFilter;
