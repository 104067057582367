import {
  //GSButton,
  GSInfoGroup,
  GSQuickFilter,
  GSSelect,
} from "golfstatus_react_components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveSponosorRoundHole,
  selectTournamentSponsorRoundHoles,
  setRoundHoleSponsor,
} from "../../../reducers/sponsorSlice";
import "./tournament-sponsor-hole-assignment-round.scss";

const TournamentSponsorHoleAssignmentRound = (props) => {
  const {
    id,
    //roundName,
   // number,
    roundHoleOptions,
    sponsor,
    //viewRoundHoleAssignments,
  } = props;

  const dispatch = useDispatch();

  const tournamentSponsorRoundHoles = useSelector(
    selectTournamentSponsorRoundHoles
  );

  const getRoundSponsors = () => {
    return roundHoleOptions?.filter?.(
      (rho) => rho?.value?.tournamentRound?.id === id 
    );
  };

  const getAvailableSponsors = () => {
    return getRoundSponsors()?.filter?.(
      (rho) => rho?.value?.sponsor === null
    );
  }

  const getSelectedSponsorHoles = () => {
    return getRoundSponsors()?.filter?.(
      (rho) => rho?.value?.sponsor?.id === sponsor?.id
    );
  };

  const addSponsorToHole = (option) => {
    updateSponsorOnAssignment(option, sponsor)
  };

  const updateSponsorOnAssignment = (option, spon) => {
    let update = [...tournamentSponsorRoundHoles];
    const rhsIndex = update.findIndex?.((rhs) => rhs.id === option.value.id);
    if (rhsIndex !== -1) {
      let assignment = { ...update[rhsIndex] };
      assignment.sponsor = spon;
      update[rhsIndex] = assignment;
      dispatch(setRoundHoleSponsor(assignment));
      dispatch(saveSponosorRoundHole(assignment));
    }
  }

  const getGroup = (item) => {
    let holeSection = {
      sectionItems: [
        {
          type: "primary",
          value: item.label,
        },
        {
          type: "secondary",
          value: item.subLabel,
        },
      ],
    };
    let firstColumn = {
      fit: "stretch",
      sections: [
        {
          ...holeSection,
        },
      ],
    };
    let group = [{ ...firstColumn }];
    return group;
  };

  const getInfoGroup = (item) => {
    return <GSInfoGroup dataGroups={getGroup(item)}></GSInfoGroup>;
  };

  const getDefaultSearch = () => {
    if(getAvailableSponsors()?.length === 0)
    {
      return null
    }
    return (
      <GSSelect
        options={getAvailableSponsors()}
        placeholder="Select a Hole..."
        selectedOption={null}
        onChange={(option) => {
          addSponsorToHole(option);
        }}
      ></GSSelect>
    );
  };

  const removeHoleAssignment = (option) => {
    updateSponsorOnAssignment(option, null);
  }

  return (
    <tournament-sponsor-hole-assignment-round>
      <GSQuickFilter
        multiple={true}
        selectedList={getSelectedSponsorHoles()}
        getItem={(item) => getInfoGroup(item)}
        getDefaultSearch={getDefaultSearch}
        itemRemoved={removeHoleAssignment}
      ></GSQuickFilter>
      {/* <GSButton
        onClick={() => {
          viewRoundHoleAssignments?.({ ...props });
        }}
        type="light-grey"
        title={`View Round ${roundName ?? number} Hole Assignments`}
      /> */}
    </tournament-sponsor-hole-assignment-round>
  );
};

export default TournamentSponsorHoleAssignmentRound;
