import React, { useEffect, useState, useCallback } from "react";
import "./tournament-list.scss";
import {
  GSListPage,
  GSInput,
  GSItemList,
  GSEmptyList,
} from "golfstatus_react_components";
import { useDispatch, connect, useSelector } from "react-redux";
import {
  searchAllEvents,
  selectEmptyTournament,
  setAllEvents,
  setCurrentTournament,
  setFilterStatus,
} from "../../reducers/tournamentSlice";
import {
  faTimesCircle,
  faPlus,
  faStream,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../app/gs-session";
import TournamentLineItem from "../tournament-line-item";
import { useMatch } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { selectLoggedInUser } from "../../reducers/appSlice";
import { selectOrganizationList } from "../../reducers/organizationSlice";
import { useIntercom } from "react-use-intercom";

const EventList = (props) => {
  const { allEvents, loading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const loggedInUser = useSelector(selectLoggedInUser);
  const match = useMatch("/tournaments/*");
  const [filter, setFilter] = useState({
    page: {
      page: 1,
      number: 50,
    },
    name: "",
    status: props.listFilterStatus?.value,
    search: true,
    userID: currentUser.uid,
  });
  const [filterButtons, setFilterButtons] = useState([]);
  const [name, setName] = useState("");
  const emptyTournament = useSelector(selectEmptyTournament);
  const organizations = useSelector(selectOrganizationList);
  const intercom = useIntercom();

  const userHasAccess = () => {
    const tmsOrgs = organizations?.filter?.((org) => org.tms);
    return loggedInUser.tms || tmsOrgs?.length > 0;
  };

  const getPageActions = () => {
    if (!userHasAccess()) {
      return [];
    }
    return [
      {
        id: 1,
        buttonTitle: "Create Tournament",
        type: "black mobile-icon",
        actionClick: addEvent,
        actionIcon: faPlus,
      },
    ];
  };

  const getSearchActions = () => {
    return [{ id: 1, actionIcon: faStream, actionClick: openFilter }];
  };

  const openFilter = () => {
    navigate("filter");
  };

  const loadFilter = useCallback(() => {
    if (filter.status !== "all") {
      setFilterButtons([
        {
          name: props.listFilterStatus.label,
          removeAction: () => {
            dispatch(setFilterStatus({ label: "All", value: "all" }));
          },
        },
      ]);
    } else {
      setFilterButtons([]);
    }
    if (filter.page.page === 1) {
      dispatch(setAllEvents([]));
    }
    dispatch(searchAllEvents(filter));
  }, [dispatch, filter, props.listFilterStatus.label]);

  const clearFilterItem = (filterItem) => {
    filterItem.removeAction?.();
  };

  useEffect(() => {
    loadFilter();
  }, [loadFilter]);

  useEffect(() => {
    setFilter((prevfilter) => ({
      ...prevfilter,
      status: props.listFilterStatus?.value,
      page: { page: 1, number: 50 },
    }));
  }, [props.listFilterStatus]);

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };
  const getRouter = () => {
    return <Outlet />;
  };

  const getListPage = (title) => {
    const page = {
      title: title,
      getDefaultSearch: getDefaultSearch,
      getItemList: getItemList,
      getPageActions: getPageActions,
      getSearchActions: getSearchActions,
      loadMore: loadMore,
      getRouter: getRouter,
      isSidePanelOpen: isSidePanelOpen,
      filterButtons: filterButtons,
      clearFilterItem: clearFilterItem,
    };
    return <GSListPage {...page} />;
  };

  const searchChanged = (e) => {
    setName(e.target.value);
  };

  const submitSearch = () => {
    setFilter({ ...filter, name: name, page: { ...filter?.page, page: 1 } });
  };

  const loadMore = () => {
    if(loading?.length > 0){
      return
    }
    //Paging is currently not set up for organizations
    setFilter({
      ...filter,
      page: { ...filter?.page, page: filter?.page?.page + 1 },
    });
  };

  const clearText = () => {
    setName("");
    setFilter({ ...filter, name: "", page: { ...filter?.page, page: 1 } });
  };

  const getDefaultSearch = () => {
    return (
      <div className="event-search">
        <GSInput
          textValue={name}
          leftIcon={faSearch}
          rightIconClick={clearText}
          rightIcon={faTimesCircle}
          onChange={searchChanged}
          onSubmit={submitSearch}
          placeholder="Search by Tournament Name..."
        ></GSInput>
      </div>
    );
  };

  const selectEvent = (event) => {
    if (isSidePanelOpen()) {
      navigate("/tournaments");
      return;
    }
    navigate(`/${event.id}`);
  };

  const addEvent = (event) => {
    dispatch(setCurrentTournament(emptyTournament));
    navigate(`/tournaments/new`);
  };

  const copyEvent = (event) => {
    dispatch(setCurrentTournament(emptyTournament));
    navigate(`/tournaments/copy/${event.id}`);
  };

  const contactGolfStatus = () => {
    intercom.show();
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Create & Manage Tournaments"
        detail="GolfStatus simplifies and streamlines golf tournaments. Let's get started!"
        actions={[
          {
            title: "Create Tournament",
            buttonIcon: faPlus,
            onClick: addEvent,
            type: "black",
          },
        ]}
      />
    );
  };

  const getUnAuthorizedListMessage = () => {
    return (
      <GSEmptyList
        title="Your organization does not currently have access to TMS."
        detail="Please contact GolfStatus to get started!"
        actions={[
          {
            title: "Contact Golfstatus",
            onClick: contactGolfStatus,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the tournament you’re looking for…"
        detail="Try changing the search term and filter or create a new tournament."
        actions={[
          {
            title: "Clear All",
            onClick: clearText,
            type: "light-grey",
          },
          {
            title: "Create Tournament",
            buttonIcon: faPlus,
            onClick: addEvent,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptyMessage = () => {
    if (userHasAccess()) {
      return filter?.name !== ""
        ? getEmptySearchMessage()
        : getEmptyListMessage();
    } else {
      return getUnAuthorizedListMessage();
    }
  };

  const getItemList = () => {
    return (
      <GSItemList
        items={allEvents}
        listItem={(event) => (
          <TournamentLineItem tournament={event} copyTournament={copyEvent} />
        )}
        itemSelected={selectEvent}
        type="vertical selectable tournament-list"
        emptyMessage={getEmptyMessage()}
        loading={loading?.includes?.("searchAllEvents")}
        loadingMainText="Searching for tournaments..."
      ></GSItemList>
    );
  };

  return <event-list>{getListPage("My Tournaments")}</event-list>;
};

const mapStateToProps = (state) => ({
  allEvents: state.tournaments.allEvents,
  loading: state.tournaments.loading,
  listFilterStatus: state.tournaments.listFilterStatus,
});

export default connect(mapStateToProps)(EventList);
