import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

import './tournament-flight-line-item.scss'

const TournamentFlightLineItem = (props) => {
  const {position, name, teamFlights, preflights, hidePosition} = props;

  const currentTournament = useSelector(selectCurrentTournament)

  const playerOrTeam = currentTournament?.numberOfPlayers === 1 ? "Player" : "Team"

  const positionSection = {
    sectionItems: [{ type: "primary", value: `${position}` }],
  };

  const flightNameSection = {
    sectionItems: [
      { type: "primary", value: `${name} Flight ` },
    ],
  };

  const flightInfoSection = {
    sectionItems: [
      {
        type: "secondary",
        value:teamFlights ? `${teamFlights?.length} Assigned ${playerOrTeam}${teamFlights?.length === 1 ? "" : "s"}` : "",
      },
      {
        type: "secondary",
        value: preflights ? `${preflights?.length} Leaderboard${preflights?.length === 1 ? "" : "s"}` : "",
      },
    ],
  };

  const positionColumn = {
    sections: [
      {
        ...positionSection,
      },
    ],
  };

  const flightInfoColumn = {
    fit: "stretch",
    sections: [
      flightNameSection,
      {...flightInfoSection}
    ],
  }

  const getGroup = () => {
    let group = [{...flightInfoColumn}];
    if(!hidePosition){
      group.unshift({ ...positionColumn })
    }
    return group;
  };

  return (
    <tournament-flight-line-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-flight-line-item>
  );
};

export default TournamentFlightLineItem;
