import React from "react";
import "./tournament-round-line-item.scss";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import { getDateTimeOn } from "../../../helpers/Dates";
import { useNavigate } from "react-router-dom";
import { faBolt, faClone, faPen } from "@fortawesome/free-solid-svg-icons";
import { getRoundName, getStateColor } from "../../../helpers/Converters";
import { useDispatch } from "react-redux";
import { activateScorecards } from "../../../reducers/roundSlice";

const TournamentRoundLineItem = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { round, hideCopy, hideActions, editRound } = props;

  const copyRound = (e) => {
    navigate(`${round.id}/copy`);
    e?.stopPropagation?.();
  };

  const onEditClicked = (e) => {
    editRound?.(round);
    e?.stopPropagation?.();
  };

  const holeAssignments = (e) => {
    navigate(`${round.id}/hole-assignments`);
    e?.stopPropagation?.();
  };

  const startRound = (e) => {
    navigate(`${round.id}/scoring`);
    e?.stopPropagation?.();
  };

  const createScorecards = (e) => {
    dispatch(activateScorecards(round.id));
    startRound()
    e?.stopPropagation?.()
  };

  const getStateButton = () => {
    switch (round.state) {
      case "live":
        return round.scoringLocked ? (
          <GSButton
            type={"light-grey"}
            title={"Edit Scorecards"}
            buttonIcon={faPen}
            onClick={startRound}
          ></GSButton>
        ) : (
          <GSButton
            type={"light-grey"}
            title={"Edit Scorecards"}
            buttonIcon={faPen}
            onClick={startRound}
          ></GSButton>
        );
      case "ready":
        return (
          <GSButton
            type={"green"}
            title={"Start Round"}
            onClick={createScorecards}
          ></GSButton>
        );
      case "draft":
        return (
          <GSButton
            type={"light-grey"}
            title={"Hole Assignments"}
            buttonIcon={faBolt}
            onClick={holeAssignments}
          ></GSButton>
        );
      case "final":
        return (
          <GSButton
            type={"light-grey"}
            title={"View Leaderboards"}
            onClick={(e) => {
              navigate(`${round.id}/leaderboards`);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
      default:
        return (
          <GSButton
            type={"light-grey"}
            title={"Edit Round"}
            onClick={(e) => {
              navigate(`${round.id}/details`);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
    }
  };

  const RoundInfo = {
    fit: "stretch",
    sections: [
      {
        sectionItems: [
          {
            type: "primary no-wrap",
            value: `Round ${getRoundName(round)}`,
          },
          {
            type: "secondary no-wrap",
            value: `${round?.tournamentFormats
              ?.map?.((f) => f.name)
              ?.join?.(",")}`,
          },
        ],
      },
      {
        sectionItems: [
          {
            type: "secondary no-wrap",
            value: `${getDateTimeOn(round?.startAt)}` ?? `TBD`,
          },
          {
            type: "secondary no-wrap capitalize",
            value: round?.startType?.replace?.("_", " ") ?? `Invalid Facility`,
          },
        ],
      },
      {
        sectionItems: [
          {
            type: "secondary no-wrap",
            value: round?.facility?.name ?? `Invalid Facility`,
          },
        ],
      },
    ],
  };

  const getRoundInfo = (c) => {
    let roundInfo = {
      sectionItems: [
        { type: "secondary", value: c?.course?.name },
        { type: "secondary", value: `${c?.numberOfHoles} holes` },
        {
          type: "secondary",
          value: round?.tees?.[0]?.name
            ? `${round?.tees?.map?.((t) => t.name)?.join?.(", ")} Tees`
            : ``,
        },
      ],
    };
    return roundInfo;
  };

  if (round?.roundCourses) {
    RoundInfo.sections.push(
      ...round?.roundCourses?.map?.((c) => getRoundInfo(c))
    );
  }

  RoundInfo.sections.push({
    sectionItems: [
      {
        type: " no-wrap ",
        value: (
          <GSButton
            type={`pill secondary ${getStateColor(round)}`}
            title={round?.state}
          ></GSButton>
        ),
      },
    ],
  });

  const actions = {
    fit: "max-200",
    sections: [
      {
        sectionItems: [
          {
            type: "no-wrap full-width",
            value: getStateButton(),
          },
        ],
      },
    ],
  };

  const copy = {
    sections: [
      {
        sectionItems: [
          {
            type: "no-wrap",
            value: (
              <GSButton
                type={`light-grey`}
                buttonIcon={faClone}
                onClick={copyRound}
              ></GSButton>
            ),
          },
        ],
      },
    ],
  };

  const edit = {
    sections: [
      {
        sectionItems: [
          {
            type: "no-wrap",
            value: (
              <GSButton
                type={`light-grey`}
                buttonIcon={faPen}
                onClick={onEditClicked}
              ></GSButton>
            ),
          },
        ],
      },
    ],
  };

  let group = [{ ...RoundInfo }];

  if (!hideActions) {
    group.push({ ...actions });
  }

  if (!hideCopy) {
    group.push({ ...copy });
  }

  if (editRound) {
    group.push({ ...edit });
  }

  return (
    <tournament-round-line-item>
      <GSInfoGroup listType="mobile-vertical" dataGroups={group} />
    </tournament-round-line-item>
  );
};

export default TournamentRoundLineItem;
