export const getTournamentLinkPages = (tournament, roundList) => {
  let pages = [
    {
      label: "Rounds & Scorecards",
      details:
        "Manage hole assignments, scorecards, leaderboards, rules, tees, and more.",
      extras: roundList,
      isExternal: false,
      url: "rounds",
      hidden: false,
    },
    {
      label: "Event Site & Packages",
      details:
        "Manage event website, tournament registration, packages, forms, event details, and more.",
      isExternal: false,
      url: "promotion",
      hidden: false,
    },
    {
      label: "Players & Teams",
      details:
        "Add and manage players & teams, unassigned players, and waitlists.",
      isExternal: false,
      hidden: true,
      url: "teams",
    },
    {
      label: "Sponsors",
      details: "Manage sponsors and mobile scorecard assignments.",
      isExternal: false,
      url: "sponsors",
      hidden: false,
    },

    {
      label: "Flights",
      details: "Add and manage tournament flights.",
      isExternal: false,
      url: "flighting",
      hidden: false,
    },
    {
      label: "Leaderboards & Skins",
      details: "Manage tournament leaderboards & skins games.",
      isExternal: false,
      url: "leaderboards",
      hidden: true,
    },
    {
      label: "Documents",
      details: "Download tournament printouts, reports, and exports, and more.",
      isExternal: false,
      url: "documents",
      hidden: true,
    },
    {
      label: "Orders & Payouts",
      details:
        "Manage registration orders, payouts, reporting documents, and more.",
      isExternal: false,
      url: "reporting",
      hidden: true,
    },
    {
      label: "Messages",
      details: "Send emails and in-app notifications to players and teams.",
      isExternal: true,
      url: `https://users${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/app/messaging?newMessage=true&tournaments=${tournament.id}`,
      hidden: false,
    },
    {
      label: "Revenue Enhancements",
      details:
        "Leverage exclusive packages and sponsorships to raise more money.",
      isExternal: true,
      url: `https://events${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/event/revenue-enhancements/register`,
      hidden: false,
    },
    {
      label: "Settings & Preferences",
      details:
        "Manage tournament details, users and permissions, donations, invoices, and more.",
      isExternal: false,
      url: "settings",
      hidden: false,
    },
  ];
  return pages;
};
