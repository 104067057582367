import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { mobileStatus, scorecardStatus } from "../../../../forms/RoundScoresForm";
import { getDateTimeOn } from "../../../../helpers/Dates";

const TournamentRoundScoringTeamItem = (props) => {
  const {
    contactName,
    players,
    liveTournamentScorecard,
    adjustedHandicap,
    entryCode,
    tournamentRound,
  } = props;

  //mobile info

  const getMobileActivityIcon = () => {
    if (liveTournamentScorecard === undefined) {
      return faExclamationCircle;
    }
    return mobileStatus?.find?.(
      (s) => s?.mobileStatus === liveTournamentScorecard?.mobileStatus
    )?.icon;
  };

  const getMobileActivityType = () => {
    if (liveTournamentScorecard === undefined) {
      return "transparent orange";
    }
    return mobileStatus?.find?.(
      (s) => s?.mobileStatus === liveTournamentScorecard?.mobileStatus
    )?.type;
  };

  const getStatusLabel = () => {
    if (liveTournamentScorecard === undefined) {
      return "fix";
    }
    return scorecardStatus?.find?.(
      (s) => s?.value === liveTournamentScorecard?.status
    )?.label;
  };

  const mobileActivitySection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width ",
        value: (
          <GSButton
            type={getMobileActivityType()}
            buttonIcon={getMobileActivityIcon()}
          ></GSButton>
        ),
      },
    ],
  };

  const mobileActivityColumn = {
    fit: "hide-mobile",
    sections: [
      {
        ...mobileActivitySection,
      },
    ],
  };

  //player info
  const contactInfo = {
    sectionItems: [
      {
        type: "primary full-width capitalize",
        value: `${contactName ?? `${contactName}'s Team`} ${
          adjustedHandicap > 0 ? `(${adjustedHandicap})` : ""
        }`,
      },
      {
        type: `${players?.length === 1 ? "primary" : "secondary"} full-width`,
        value: players
          ?.map?.((p) => `${p.name} (${p.adjustedHandicap})`)
          ?.join?.(", "),
      },
    ],
  };

  const playerName = {
    sectionItems: [
      {
        type: `${players?.length === 1 ? "primary" : "secondary"} full-width`,
        value: players
          ?.map?.((p) => `${p.name} (${p.adjustedHandicap})`)
          ?.join?.(", "),
      },
    ],
  };

  const playerInfo = {
    sectionItems: [
      {
        type: `secondary full-width`,
        value: entryCode,
      },
      {
        type: "secondary capitalize",
        value: liveTournamentScorecard
          ? liveTournamentScorecard?.mobileStatus.replace("_", " ")
          : "Scorecard has not been generated. Please review the hole assignments.",
      },
    ],
  };

  const teamColumn = {
    fit: "stretch",
    sections:
      players?.length === 1
        ? [{...playerName},{ ...playerInfo }]
        : [{ ...contactInfo }, { ...playerInfo }],
  };

  //round info

  const roundSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: `Round ${tournamentRound?.number} ${
          tournamentRound?.roundName ? `(${tournamentRound?.roundName})` : ""
        }`,
      },
      {
        type: "secondary no-wrap",
        value: `${tournamentRound?.tournamentFormats
          ?.map?.((f) => f?.name)
          ?.join?.(",")}`,
      },
    ],
  };

  const roundStartSection = {
    sectionItems: [
      {
        type: "secondary no-wrap",
        value: `${getDateTimeOn(tournamentRound?.startAt)}` ?? `TBD`,
      },
      {
        type: "secondary no-wrap capitalize",
        value:
          tournamentRound?.startType?.replace?.("_", " ") ?? `Invalid Facility`,
      },
    ],
  };

  const roundColumn = {
    fit: "stretch",
    sections: [
      {
        ...roundSection,
      },
      { ...roundStartSection },
    ],
  };

  //status info

  const statusSection = {
    sectionItems: [
      {
        type: " uppercase no-wrap full-width",
        value: (
          <GSButton
            type={`secondary ${liveTournamentScorecard === undefined ? 'orange' : ''}`}
            title={getStatusLabel()}
          />
        ),
      },
    ],
  };

  const statusColumn = {
    sections: [
      {
        ...statusSection,
      },
    ],
  };

  const getGroup = () => {
    let group = [
      { ...mobileActivityColumn },
      tournamentRound ? { ...roundColumn } : { ...teamColumn },
      { ...statusColumn },
    ];
    return group;
  };

  return (
    <tournament-round-scoring-team-Item>
      <GSInfoGroup listType="mobile-vertical"  dataGroups={getGroup()}></GSInfoGroup>
    </tournament-round-scoring-team-Item>
  );
};

export default TournamentRoundScoringTeamItem;
