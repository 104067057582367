import { faPen } from "@fortawesome/free-solid-svg-icons";
import { GSImage, GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import './tournament-sponsor-detail-hero.scss'

const TournamentSponsorDetailHero = (props) => {

  const {logo, name, sponsorshipType, contactName, email} = props

  const navigate = useNavigate()

  const editSponsor = () => {
    navigate("edit")
  }

  const sponorImageSection = {
    
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSImage src={logo} size="wide small"/>,
      },
    ]
  }

  const sponsorInfoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${sponsorshipType === "leaderboard" ? "Technology": ""} Sponsor`,
      },
    ]
  }

  const sponsorContactSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: contactName,
      },
      {
        type: "secondary no-wrap full-width",
        value: email,
      },
    ]
  }

  const firstColumn ={
    fit: "stretch",
    sections: [
      {
        ...sponorImageSection
      },
      {
        ...sponsorInfoSection
      },
      sponsorContactSection
    ],
  }

  const editSection = {
    sectionItems: [{
      type: "primary",
      value: <GSButton type="light-grey" buttonIcon={faPen} onClick={editSponsor}></GSButton>
    }]
  }

  const secondColumn = {
    sections:[
      {...editSection}
    ]
  }

  const getGroup = () => {
    let group = [{...firstColumn},{...secondColumn}]
    return group
  }

  return <tournament-sponsor-detail-hero>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </tournament-sponsor-detail-hero>
}


export default TournamentSponsorDetailHero