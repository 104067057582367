import React, { useEffect, useState } from "react";
import {
  GSForm,
  GSSidePanelPage,
  GSEmptyList,
  GSQuickFilter,
  GSField,
} from "golfstatus_react_components";
import {
  faBoxOpen,
  faChevronLeft,
  faExclamationCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-package-items.scss";
import { useMemo } from "react";
import { getPackageItemsForm } from "../../../../forms/TournamentPackageForm";
import { useSelector } from "react-redux";
import {
  addNotification,
  clearNotifications,
  createTournamentPackageItem,
  deleteTournamentPackageItem,
  getAddOnPackageItems,
  getPackageItems,
  getPlayerPackageItems,
  getSkinsAddOnPackageItems,
  getSponsorPackageItems,
  getTeamPackageItems,
  getTechnologySponsorPackageItems,
  getTournamentPackage,
  getTournamentPackages,
  saveTournamentPackageItem,
  selectAvailablePackageItems,
  selectCurrentTournamentPackage,
  selectCurrentTournamentPackageItems,
  selectLoading,
  selectMinTechSponsorPackagePrice,
  selectNotifications,
  selectTournamentPackages,
  setCurrentPackage,
  setCurrentPackageItem,
  setCurrentPackageItems,
} from "../../../../reducers/packageSlice";

import { useDispatch } from "react-redux";
import TournamentPackageItemsItem from "./tournament-package-items-item";
import { getNotificationItemInfo } from "../../../../helpers/Converters";
import {
  getSaveBannerActions,
  useNotificationAction,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import SearchBar from "../../../search-bar";
import { mediumDelay } from "../../../../helpers/JSONapi";

//Name the component
const TournamentPackageItems = (props) => {
  const [search, setSearch] = useState("");
  const [warn, setWarn] = useState(false);

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scorecardParams] = useSearchParams();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/package-items",
  );

  //selectors
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageItems = useSelector(selectCurrentTournamentPackageItems);
  const allPackageItems = useSelector(selectAvailablePackageItems);
  const packageNotifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading);
  const minTechSponsorPrice = useSelector(selectMinTechSponsorPackagePrice);
  const tournamentPackages = useSelector(selectTournamentPackages);

  //custom hooks
  useNotificationAction(
    "created",
    packageNotifications,
    (notification) => {
      const item = notification.resultPayload;
      let newPi = {
        packageItemKey: item.id,
        quantity: 1,
        tournamentPackageKey: match?.params?.packageID,
        packageItem: item,
        tournamentPackage: currentPackage,
      };
      dispatch(createTournamentPackageItem(newPi));
    },
    "Package Item",
  );

  //effects
  useEffect(() => {
    if (match?.params?.packageID && match?.params?.packageID !== "add") {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getPackageItems(match.params.packageID));
    }
    return () => {
      dispatch(clearNotifications());
      dispatch(getTournamentPackages(match?.params?.tournamentID));
    };
  }, [dispatch, match?.params?.packageID, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTeamPackageItems(match.params.tournamentID));
      dispatch(getPlayerPackageItems(match.params.tournamentID));
      dispatch(getSponsorPackageItems(match.params.tournamentID));
      dispatch(getTechnologySponsorPackageItems(match.params.tournamentID));
      dispatch(getSkinsAddOnPackageItems(match.params.tournamentID));
      dispatch(getAddOnPackageItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const step = scorecardParams.get("step");

  const hasTechSponsor =
    tournamentPackages?.some(
      (item) =>
        item.hasTechPackageItem === true && item.id !== currentPackage.id,
    ) ||
    currentPackageItems?.some((pi) =>
      allPackageItems.find(
        (api) =>
          pi.packageItemKey === api.id && api.itemType === "technology-sponsor",
      ),
    );

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`What type of package is this?`}
        detail="Packages are defined by their items, those items allow registrant information to be collected and automatically distributed."
        actions={[
          {
            title: "Add Item",
            onClick: context.createPackageItem,
            buttonIcon: faPlus,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySelectedListMessage = () => {
    if (hasTechSponsor && allPackageItems.length === 1) {
      return;
    }
    return (
      <GSEmptyList
        title={`What type of package is this?`}
        detail="Packages are defined by their items, those items allow registrant information to be collected and automatically distributed."
        actions={[
          {
            title: "Add Item",
            onClick: context.createPackageItem,
            buttonIcon: faPlus,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getEmptySearchListMessage = () => {
    return (
      <GSEmptyList
        title={`We couldn’t find the item you’re looking for…`}
        detail="Try changing the search term or add a new item."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey no-wrap",
          },
          {
            title: "Add Item",
            onClick: context.createPackageItem,
            buttonIcon: faPlus,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getPackageItem = (tournamentPackageItem) => {
    let packageItem = allPackageItems.find?.(
      (pi) => pi.id === tournamentPackageItem.packageItemKey,
    );
    return packageItem ?? {};
  };

  const getSelectedPackageItems = () => {
    return currentPackageItems.map((pi) => ({
      ...getPackageItem(pi),
      selectedItem: pi,
    }));
  };

  const minimumQuantityBanner = () => {
    const bannerText = `The package item quantity must be greater than 0.`;
    return {
      header: getNotificationItemInfo(bannerText, faExclamationCircle),
      state: "warning",
      timeout: mediumDelay,
    };
  };

  const updateQuantity = (item, quantity) => {
    let cpiIndex = currentPackageItems.findIndex?.(
      (cpi) => cpi.packageItemKey === item.id,
    );
    if (quantity > 0) {
      let cpis = [...currentPackageItems];
      cpis[cpiIndex] = {
        ...cpis[cpiIndex],
        quantity: quantity,
      };
      dispatch(setCurrentPackageItems(cpis));
      dispatch(saveTournamentPackageItem(cpis[cpiIndex]));
    } else {
      dispatch(addNotification(minimumQuantityBanner()));
      return;
    }
  };

  const minimumRequirementBanner = () => {
    const bannerText =
      currentPackage.cost < minTechSponsorPrice
        ? `To include a Tech Sponsor package item, the package price must be at least $${minTechSponsorPrice}.`
        : "To include a Tech Sponsor package item, the package inventory amount cannot be more than 1.";
    return {
      header: getNotificationItemInfo(bannerText, faExclamationCircle),
      state: "warning",
      bannerActions: [
        {
          title: "Edit Package",
          type: "secondary",
          actionClick: () =>
            navigate(`../packages/${match?.params?.packageID}/edit`),
        },
      ],
    };
  };

  const packageItemChecked = (item) => {
    if (
      item.itemType === "technology-sponsor" &&
      (currentPackage.cost < minTechSponsorPrice ||
        currentPackage.availableLimit > 1)
    ) {
      dispatch(addNotification(minimumRequirementBanner()));
      return;
    }

    let found = currentPackageItems.find?.(
      (cpi) => cpi.packageItemKey === item.id,
    );
    if (found) {
      updateQuantity(item, found.quantity + 1);
    } else {
      let newPi = {
        packageItemKey: item.id,
        quantity: 1,
        tournamentPackageKey: match?.params?.packageID,
        packageItem: item,
        tournamentPackage: currentPackage,
      };
      dispatch(createTournamentPackageItem(newPi));
    }
  };

  const packageItemUnchecked = (item) => {
    dispatch(
      setCurrentPackageItems([
        ...currentPackageItems.filter?.(
          (cpi) => cpi.packageItemKey !== item.id,
        ),
      ]),
    );
    if (item?.selectedItem?.id) {
      dispatch(deleteTournamentPackageItem(item?.selectedItem?.id));
    }
  };

  const context = useMemo(() => ({}), []);

  context.getPackage = () => {
    return currentPackage;
  };

  context.createPackageItem = () => {
    dispatch(clearNotifications());
    dispatch(setCurrentPackageItem({}));
    navigate("item/add");
  };

  context.editPackageItem = (item) => {
    navigate(`item/${item.id}`);
  };

  context.updateSearch = (value) => {
    setSearch(value);
  };

  context.getSearch = (value) => {
    return search;
  };

  const getFilteredPackageItems = () => {
    const packageitems = allPackageItems.filter?.((pi) => {
      if (hasTechSponsor) {
        return (
          !pi.itemType.includes("technology-sponsor") &&
          pi.name?.toLowerCase()?.includes?.(search?.toLowerCase())
        );
      } else {
        return pi.name?.toLowerCase()?.includes?.(search?.toLowerCase());
      }
    });
    return packageitems;
  };

  const getDefaultSearch = () => {
    if (allPackageItems.length === 0) {
      return;
    }
    let input = {
      search,
      setSearch,
      placeholder: "Search Available Items…",
      type: "no-pad",
    };
    if (hasTechSponsor && allPackageItems.length === 1) {
      return;
    }
    return <GSField label="Available Items" value={<SearchBar {...input} />} />;
  };

  context.getPackageItemList = () => {
    let filter = {
      getDefaultSearch: getDefaultSearch,
      itemSelected: context.editPackageItem,
      filteredList: getFilteredPackageItems(),
      selectedList: getSelectedPackageItems(),
      searchListType: "grey-empty-list rounded-empty-list",
      selectListType: "grey-empty-list rounded-empty-list",
      itemRemoved: packageItemUnchecked,
      getItem: (item) => (
        <TournamentPackageItemsItem
          {...item}
          addItem={packageItemChecked}
          updateQuantity={updateQuantity}
        />
      ),
      emptySearchList:
        search !== "" ? getEmptySearchListMessage() : getEmptyListMessage(),
      emptySelectedList: getEmptySelectedListMessage(),
      multiple: allPackageItems.length > 0,
    };
    return <GSQuickFilter {...filter} />;
  };

  context.updatePackage = (value, property) => {
    let update = { ...currentPackage };
    update[property] = value;
    dispatch(setCurrentPackage(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Done",
        isDisabled: currentPackageItems?.length === 0,
        action: leftNavigation,
        type: "black",
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (currentPackageItems.length === 0) {
      setWarn(true);
      return;
    }
    navigate(
      `/${match?.params?.tournamentID}/promotion/packages/${match?.params?.packageID}`,
    );
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Package items`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormActionBar = () => {
    return {
      formTitle: "Package Items",
      formActions: [
        {
          buttonTitle: "Add Item",
          actionIcon: faPlus,
          actionClick: context.createPackageItem,
          type: "black no-wrap",
        },
      ],
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormActionBar()}
        formSections={getPackageItemsForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //banner custom hooks
  const getDefaultBanner = () => {
    if (currentPackageItems.length === 0 && warn) {
      return {
        title: getNotificationItemInfo(
          `Packages require at least one package item. Select an existing item or create a new one.`,
          faExclamationCircle,
        ),
        state: "warning",
      };
    }
    if (step === "2") {
      return {
        title: getNotificationItemInfo(
          `Define your package type by adding package items.`,
          faBoxOpen,
          "Step 2 of 2",
        ),
        state: "grey",
      };
    }
    return false;
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: saveTournamentPackageItem,
    bannerActions: getSaveBannerActions(() => {}, leftNavigation),
    timeoutAction,
    defaultBanner: getDefaultBanner(),
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-package-items>
      <GSSidePanelPage
        loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={step === "2" ? getDrawer() : null}
      ></GSSidePanelPage>
    </tournament-package-items>
  );
};

//export the new namet
export default TournamentPackageItems;
