import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  longDelay,
  shortDelay,
} from "../helpers/JSONapi";
import {
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  liveTournamentRounds,
  roundCourses,
  roundHoleAssignments,
  roundTees,
  saveThunkResponse,
  thunkResponse,
  tournament,
  tournamentFormats,
  tournamentRounds,
} from "../app/api";
import { getNotificationItemInfo } from "../helpers/Converters";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
//get
export const getTournamentRounds = createAsyncThunk(
  "rounds/getTournamentRounds",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${id}/tournament-rounds`);
    return response;
  }
);
export const getRound = createAsyncThunk(
  "rounds/getRounds",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentRounds}/${id}`);
    return response;
  }
);
export const getRoundHoleAssignments = createAsyncThunk(
  "rounds/getRoundHoleAssignments",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${id}/round-hole-assignments`
    );
    return response;
  }
);
export const getRoundHoleAssignment = createAsyncThunk(
  "rounds/getRoundHoleAssignment",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${roundHoleAssignments}/${id}`);
    return response;
  }
);
export const getRoundHoleSponsors = createAsyncThunk(
  "rounds/getRoundHoleSponsors",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${id}/sponsor-round-holes`
    );
    return response;
  }
);
export const getRoundFlights = createAsyncThunk(
  "rounds/getRoundFlights",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentRounds}/${id}/flight-rounds`);
    return response;
  }
);
export const getRoundLeaderboards = createAsyncThunk(
  "rounds/getRoundLeaderboards",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentRounds}/${id}/leaderboard-type-settings`
    );
    return response;
  }
);
export const getRoundScorecards = createAsyncThunk(
  "rounds/getRoundScorecards",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentRounds}/${id}/scorecards`);
    return response;
  }
);
export const getRoundTees = createAsyncThunk(
  "rounds/getRoundTees",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentRounds}/${id}/round-tees`);
    return response;
  }
);
export const getRoundCourses = createAsyncThunk(
  "rounds/getRoundCourses",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentRounds}/${id}/round-courses`);
    return response;
  }
);

export const getRoundTee = createAsyncThunk(
  "rounds/getRoundTee",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${roundTees}/${id}`);
    return response;
  }
);
export const getRoundCourse = createAsyncThunk(
  "rounds/getRoundCourse",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${roundCourses}/${id}`);
    return response;
  }
);

//create
export const createRound = createAsyncThunk(
  "rounds/createRound",
  async (round, thunkAPI) => {
    let serializedData = getSerializedData(
      round,
      "tournament-rounds",
      getOpts(round, [
        ...getTournamentRelationship(round),
        ...getFacilityRelationship(),
        ...getFormatTypeRelationship(),
      ])
    );

    const serializedCourses = round.roundCourses.map(
      (c) =>
        getSerializedData(
          c,
          "round-courses",
          getOpts(c, [...getCourseRelationship(), ...getTeesRelationship()])
        )?.data
    );

    serializedData.data.relationships["round-courses"] = {
      data: serializedCourses,
    };

    return await getResponse(
      gsApi.post,
      `${tournamentRounds}`,
      serializedData,
      thunkAPI
    );
  }
);

export const postScoresToGhin = createAsyncThunk(
  "rounds/postScoresToGhin",
  async (ghinSettings, thunkAPI) => {
    let responses = [];
    for (const player of ghinSettings.players) {
      if(player.liveRound.id){
        const r = await getResponse(
          gsApi.post,
          `${liveTournamentRounds}/${player.liveRound?.id}/post-to-ghin`,
          { score_type: ghinSettings.scoreType },
          thunkAPI
        );
        responses.push({ ...r, player });
      }
      
    }

    const response = new Promise((resolve, reject) => {
      resolve({ payload: responses });
    });
    return response;
  }
);
//save
export const saveRound = createAsyncThunk(
  "rounds/saveRound",
  async (round, thunkAPI) => {
    let serializedData = getSerializedData(
      round,
      "tournament-rounds",
      getOpts(round, [
        ...getTournamentRelationship(round),
        ...getFacilityRelationship(),
        ...getFormatTypeRelationship(),
      ])
    );
    if (round.roundCourses) {
      const serializedCourses = round?.roundCourses?.map?.(
        (c) =>
          getSerializedData(
            c,
            "round-courses",
            getOpts(c, [...getCourseRelationship(), ...getTeesRelationship()])
          )?.data
      );

      serializedData.data.relationships["round-courses"] = {
        data: serializedCourses,
      };
    }
    return await getResponse(
      gsApi.patch,
      `${tournamentRounds}/${round.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveRoundTeeAndHoles = createAsyncThunk(
  "rounds/saveRoundTeesAndHoles",
  async (roundTee, thunkAPI) => {
    let serializedData = getSerializedData(
      roundTee,
      "round-tees",
      getOpts(roundTee, [
        ...getIDRelationship("round-course"),
        ...getIDRelationship("tee"),
      ])
    );

    let included = [];
    const roundCourse = roundTee?.["round-course"];
    const updatedHoles = roundCourse?.roundHoles?.filter?.((h) => h.updated);

    for (let i = 0; i < updatedHoles?.length; i++) {
      let hole = { ...updatedHoles[i] };
      hole.roundCourse = roundCourse;
      hole.roundTee = roundTee;
      const serializedHoleData = getSerializedData(
        hole,
        "round-holes",
        getOpts(hole, [
          ...getIDRelationship("roundCourse"),
          ...getIDRelationship("roundTee"),
        ])
      );
      serializedHoleData.data.relationships = serializedData.data.relationships;
      included.push(serializedHoleData.data);
    }

    serializedData.included = included;

    return await getResponse(
      gsApi.patch,
      `${roundTees}/${roundTee.id}/tee-and-holes`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveRoundHoleAssignment = createAsyncThunk(
  "rounds/saveRoundHoleAssignment",
  async (roundHoleAssignment, thunkAPI) => {
    let serializedData = getSerializedData(
      roundHoleAssignment,
      "round-hole-assignments",
      getOpts(roundHoleAssignment, [
        ...getIDRelationship("roundCourse"),
        ...getIDRelationship("tournamentRound"),
        ...getIDRelationship("tournamentTeams"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${roundHoleAssignments}/${roundHoleAssignment.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveMissingRoundHoleAssignment = createAsyncThunk(
  "rounds/saveMissingRoundHoleAssignment",
  async (missingRoundHoleAssignments, thunkAPI) => {
    let responses = []
    for(const roundHoleAssignment of missingRoundHoleAssignments){

      if(roundHoleAssignment?.id){
        let serializedData = getSerializedData(
          roundHoleAssignment,
          "round-hole-assignments",
          getOpts(roundHoleAssignment, [
            ...getIDRelationship("roundCourse"),
            ...getIDRelationship("tournamentRound"),
            ...getIDRelationship("tournamentTeams"),
          ])
        );
  
        let response = await getResponse(
          gsApi.patch,
          `${roundHoleAssignments}/${roundHoleAssignment.id}`,
          serializedData,
          thunkAPI
        )
        responses.push({...response, roundHoleAssignment})
      }
      
    }
    const response = new Promise((resolve, reject) => {
      resolve({ payload: responses });
    });
    return response;
  }
);

export const saveRoundFormat = createAsyncThunk(
  "rounds/saveRoundFormat",
  async (roundFormat, thunkAPI) => {
    let serializedData = getSerializedData(
      roundFormat,
      "tournament-formats",
      getOpts(roundFormat, [
        ...getIDRelationship("tournamentFormatType"),
        ...getIDRelationship("tournamentRound"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${tournamentFormats}/${roundFormat.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const autoAssignHoles = createAsyncThunk(
  "rounds/autoAssignHoles",
  async (autoAssign, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournamentRounds}/${autoAssign.roundID}/auto-assign-holes`,
      { teamIds: autoAssign.teamIds },
      thunkAPI
    );
  }
);

export const activateScorecards = createAsyncThunk(
  "rounds/activateScorecards",
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournamentRounds}/${roundID}/live-tournament-scorecards`,
      {},
      thunkAPI
    );
  }
);

//lock scoring

export const lockScoring = createAsyncThunk(
  "rounds/lockScoring",
  async (tournamentRoundID, thunkAPI) => {
    return await getResponse(
      gsApi.put,
      `${tournamentRounds}/${tournamentRoundID}/lock-scoring`,
      {},
      thunkAPI
    );
  }
);

//finalize scorecards
export const finalizeScorecards = createAsyncThunk(
  "rounds/finalizeScorecards",
  async (tournamentRoundID, thunkAPI) => {
    return await getResponse(
      gsApi.patch,
      `${tournamentRounds}/${tournamentRoundID}/finalize-scorecards`,
      {},
      thunkAPI
    );
  }
);

export const undoFinalizeScorecards = createAsyncThunk(
  "rounds/undoFinalizeScorecards",
  async (tournamentRoundID, thunkAPI) => {
    return await getResponse(
      gsApi.patch,
      `${tournamentRounds}/${tournamentRoundID}/undo-finalize-scorecards`,
      {},
      thunkAPI
    );
  }
);

//delete
export const removeTournamentRound = createAsyncThunk(
  "rounds/removeRound",
  async (roundID, thunkAPI) => {
    const response = await gsApi.delete(`${tournamentRounds}/${roundID}`, {
      data: {},
    });
    return response;
  }
);

export const removeHoleAssignments = createAsyncThunk(
  "rounds/removeHoleAssignments",
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournamentRounds}/${roundID}/remove-assign-holes`,
      { data: {} },
      thunkAPI
    );
  }
);

export const deactivateRoundScorecards = createAsyncThunk(
  "rounds/deactivateRoundScorecards",
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.delete,
      `${tournamentRounds}/${roundID}/live-tournament-scorecards`,
      { data: {} },
      thunkAPI
    );
  }
);

export const roundSlice = createSlice({
  name: "rounds",
  initialState: {
    currentRound: {},
    tournamentRounds: [],
    currentRoundHoleAssignments: [],
    currentRoundHoleSponsors: [],
    currentRoundFlights: [],
    currentRoundLeaderboards: [],
    currentRoundScorecards: [],
    currentRoundFormats: [],
    currentRoundTees: [],
    currentRoundCourses: [],
    currentRoundCourse: {},
    currentRoundTee: {},
    currentHoleAssignment: {},
    loading: [],
    notifications: [],
    emptyRound: { name: "", organization: {}, numberOfTeams: 144 },
  },
  reducers: {
    setCurrentRound: (state, action) => {
      state.currentRound = action.payload;
    },
    setTournamentRounds: (state, action) => {
      state.tournamentRounds = action.payload;
    },
    updateRound: (state, action) => {
      let index = state.tournamentRounds?.findIndex?.(
        (tr) => tr.id === action.payload.id
      );
      state.tournamentRounds[index] = action.payload;
    },
    setCurrentRoundHoleAssignment: (state, action) => {
      let index = state.currentRoundHoleAssignments.findIndex(
        (rha) => rha.id === action.payload.id
      );
      if (index !== -1) {
        state.currentRoundHoleAssignments[index] = action.payload;
      }
    },
    setRoundNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    clearRoundNotifications: (state, action) => {
      state.notifications = [];
    },
    setCurrentRoundFormat: (state, action) => {
      const unchanged = state.currentRoundFormats?.find?.(
        (f) => f.id !== action.payload.id
      );
      state.currentRoundFormats = [...(unchanged ?? []), action.payload];
    },
    setCurrentRoundTees: (state, action) => {
      state.currentRoundTees = action.payload;
    },
    setCurrentRoundCourses: (state, action) => {
      state.currentRoundCourses = action.payload;
    },
    setCurrentRoundTee: (state, action) => {
      state.currentRoundTee = action.payload;
    },
    setCurrentRoundCourse: (state, action) => {
      state.currentRoundCourse = action.payload;
    },
    setCurrentHoleAssignment: (state, action) => {
      state.currentHoleAssignment = action.payload;
    },
  },
  extraReducers: {
    //get Round
    ...getThunkResponse(getRound, (state, action) => {
      state.currentRound = action.payload;
      state.currentRoundFormats = action.payload?.tournamentFormats;
    }),
    //get Tournament Rounds
    ...getThunkResponse(getTournamentRounds, (state, action) => {
      state.tournamentRounds = action.payload;
    }),
    //get hole assignments
    ...getThunkResponse(getRoundHoleAssignments, (state, action) => {
      state.currentRoundHoleAssignments = action.payload;
    }),
    ...getThunkResponse(getRoundHoleAssignment, (state, action) => {
      state.currentHoleAssignment = action.payload;
    }),
    //get hole sponsors
    ...getThunkResponse(getRoundHoleSponsors, (state, action) => {
      state.currentRoundHoleSponsors = action.payload;
    }),
    //get round flights
    ...getThunkResponse(getRoundFlights, (state, action) => {
      state.currentRoundFlights = action.payload;
    }),
    //get round leaderboards
    ...getThunkResponse(getRoundLeaderboards, (state, action) => {
      state.currentRoundLeaderboards = action.payload;
    }),
    //get round scorecards
    ...getThunkResponse(getRoundScorecards, (state, action) => {
      state.currentRoundScorecards = action.payload;
    }),
    ...getThunkResponse(getRoundTees, (state, action) => {
      state.currentRoundTees = action.payload;
    }),
    ...getThunkResponse(getRoundCourses, (state, action) => {
      state.currentRoundCourses = action.payload;
    }),
    ...getThunkResponse(getRoundCourse, (state, action) => {
      state.currentRoundCourse = action.payload;
    }),
    ...getThunkResponse(getRoundTee, (state, action) => {
      state.currentRoundTee = action.payload;
    }),
    //create tournament round
    ...createThunkResponse(
      createRound,
      (state, action) => {
        state.tournamentRounds = [...state.tournamentRounds, action.payload];
      },
      "Round",
      longDelay
    ),
    //save round
    ...saveThunkResponse(
      saveRound,
      (state, action) => {
        let index = state.tournamentRounds?.findIndex(
          (tr) => tr.id === action.payload.id
        );
        if (index !== -1) {
          state.tournamentRounds[index] = action.payload;
        }
        //state.currentRound = {...state.currentRound, ...action.payload};
      },
      "Round",
      longDelay
    ),
    //save round hole assignment
    ...saveThunkResponse(
      saveRoundHoleAssignment,
      (state, action) => {
        let index = state.currentRoundHoleAssignments.findIndex(
          (rha) => rha?.id === action?.payload?.id
        );
        if (index !== -1) {
          state.currentRoundHoleAssignments[index] = action?.meta?.arg;
        }
      },
      "Hole Assignment"
    ),
    ...saveThunkResponse(
      saveMissingRoundHoleAssignment, (state, action) => {}, "Missing Scorecards", longDelay
    ),
    ...createThunkResponse(
      activateScorecards,
      (state, action) => {
        state.currentRoundScorecards = action.payload;
      },
      "Round Live Scorecards"
    ),
    ...deleteThunkResponse(
      deactivateRoundScorecards,
      (state, action) => {
        state.currentRoundScorecards = [];
      },
      "scorecards"
    ),
    //remove hole assignment
    ...deleteThunkResponse(
      removeHoleAssignments,
      (state, action) => {
        state.currentRoundHoleAssignments = action.payload;
      },
      "Hole Assignments"
    ),
    //auto assign
    ...saveThunkResponse(
      autoAssignHoles,
      (state, action) => {
        state.currentRoundHoleAssignments = action.payload;
      },
      "Hole Assignments"
    ),
    //delete round
    ...deleteThunkResponse(
      removeTournamentRound,
      (state, action) => {
        state.tournamentRounds = state.tournamentRounds?.filter?.(
          (tr) => tr.id !== action.meta.arg
        );
      },
      "Tournament Round"
    ),
    ...saveThunkResponse(
      lockScoring,
      (state, action) => {
        if (action) {
        }
      },
      "Round"
    ),
    ...saveThunkResponse(
      finalizeScorecards,
      (state, action) => {},
      "Scorecards"
    ),
    ...saveThunkResponse(
      undoFinalizeScorecards,
      (state, action) => {},
      "Scorecards"
    ),
    ...saveThunkResponse(
      saveRoundFormat,
      (state, action) => {},
      "Round Format",
      shortDelay
    ),
    ...saveThunkResponse(
      saveRoundTeeAndHoles,
      (state, action) => {},
      "Round tee",
      shortDelay
    ),
    ...thunkResponse(
      postScoresToGhin,
      (state, action) => {
        const details = action.payload?.payload?.map?.((payload) => {
          if (payload.status === 204) {
            return {
              header: getNotificationItemInfo(
                `${payload.player?.name} successfully posted to GHIN!`,
                faCheck
              ),
              state: "success",
              result: "saved",
              resultPayload: payload,
            };
          } else {
            return {
              header: getNotificationItemInfo(
                `There was an error posting GHIN for ${payload.liveRound?.userName}!`,
                faCheck
              ),
              state: "warning",
              result: "error",
            };
          }
        });
        const report = {
          header: getNotificationItemInfo(
            `${
              details.filter?.((d) => d.result === "saved")?.length
            } successful, ${
              details.filter?.((d) => d.result === "error")?.length
            } unsuccessful.`,
            faInfoCircle
          ),
          state: "grey",
        };
        state.notifications = [
          report,
          ...details.filter?.((d) => d.result === "error"),
          ...details.filter?.((d) => d.result === "saved"),
        ];
      },
      "Post Scores",
      {
        pendingText: "Posting to GHIN",
        fulfilledText: "Ghin Scores have been posted",
      }
    ),
  },
});

export const {
  setCurrentRound,
  setRoundNotifications,
  updateRound,
  setTournamentRounds,
  setCurrentRoundHoleAssignment,
  clearRoundNotifications,
  setCurrentRoundFormat,
  setCurrentRoundCourses,
  setCurrentRoundTees,
  setCurrentRoundCourse,
  setCurrentRoundTee,
  setCurrentHoleAssignment,
} = roundSlice.actions;

export const selectCurrentRound = (state) => state.rounds.currentRound;

export const selectCurrentRoundHoleAssignments = (state) =>
  state.rounds.currentRoundHoleAssignments;

export const selectCurrentRoundHoleSponsors = (state) =>
  state.rounds.currentRoundHoleSponsors;

export const selectCurrentRoundFlights = (state) =>
  state.rounds.currentRoundFlights;

export const selectCurrentRoundLeaderboards = (state) =>
  state.rounds.currentRoundLeaderboards;

export const selectCurrentRoundScorecards = (state) =>
  state.rounds.currentRoundScorecards;

export const selectRoundNotifications = (state) => state.rounds.notifications;

export const selectTournamentRounds = (state) => state.rounds.tournamentRounds;

export const selectLoading = (state) => state.rounds.loading;

export const selectCurrentRoundFormats = (state) =>
  state.rounds.currentRoundFormats;

export const selectCurrentRoundTees = (state) => state.rounds.currentRoundTees;

export const selectCurrentRoundCourses = (state) =>
  state.rounds.currentRoundCourses;

export const selectCurrentRoundTee = (state) => state.rounds.currentRoundTee;

export const selectCurrentRoundCourse = (state) =>
  state.rounds.currentRoundCourse;

export const selectCurrentHoleAssignment = (state) =>
  state.rounds.currentHoleAssignment;

export default roundSlice.reducer;

function getFacilityRelationship() {
  const relationships = [];
  relationships.push({
    name: "facility",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
function getTournamentRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournament",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
function getFormatTypeRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournamentFormatTypes",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getCourseRelationship() {
  const relationships = [];
  relationships.push({
    name: "course",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
function getTeesRelationship() {
  const relationships = [];
  relationships.push({
    name: "tees",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
