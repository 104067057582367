import {
  faArrowCircleUp,
  faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSFileSelect,
  GSToggle,
  GSTextEditor,
  GSRadioGroup,
  validations,
} from "golfstatus_react_components";
import TournamentPromotionQuickView from "../components/tournament/tournament-promotion/tournament-promotion-quick-view";
import { getDateTimeYMD } from "../helpers/Dates";

export const getPromotionPages = (tournament, packageList) => {
  let pages = [
    {
      label: "Event Site & Registration Details ",
      details:
        "Manage tournament activation, registration privacy, event site url, registration details, and registration close date.",
      isExternal: false,
      url: "registration",
      extras: <TournamentPromotionQuickView {...tournament} />,
      hidden: false,
    },
    {
      label: "Event Site Homepage",
      details: "Manage promotional content, imagery, and media.",
      isExternal: false,
      hidden: true,
      url: "customizations",
    },
    {
      label: "Packages",
      details: "Manage registration packages, package items, forms, and more.",
      isExternal: false,
      url: "packages",
      extras: packageList,
      hidden: false,
    },
    {
      label: "Additional Event Site Pages",
      details:
        "Manage the visibility of sponsorships, hole assignments, course details, leaderboards, and registrants.",
      isExternal: false,
      hidden: true,
      url: "visibility",
    },
    {
      label: "Auction",
      details: "Link to an auction on the event site.",
      isExternal: false,
      url: "auction",
      hidden: false,
    },
    {
      label: "Discounts",
      details: "Manage discounts codes.",
      isExternal: false,
      url: "discounts",
      hidden: false,
    },
  ];
  return pages;
};

const tournamentStateOptions = () => {
  return [
    {
      label: "Draft",
      subLabel: "This event is not ready to start",
      value: "draft",
    },
    {
      label: "Active",
      subLabel:
        "This event is set up and ready to start accepting registrations",
      value: "active",
    },
  ];
};

const selectedState = (state) => {
  return tournamentStateOptions().find?.((o) => o.value === state);
};

export const getRegistrationForm = (context, tournament) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Tournament Status",
            value: (
              <GSRadioGroup
                options={tournamentStateOptions()}
                selectedOption={selectedState(tournament?.state)}
                selectionChanged={(option) => {
                  context?.updateTournament(option.value, "state");
                }}
              ></GSRadioGroup>
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Event Site Details",
        fields: [
          {
            label: "Event Site Visibility",
            hintText: tournament.publiclyVisible ? "Event site is shown on events.golfstatus.com." : "Event site is only accessible by the link.",
            value: (
              <GSToggle
                value={
                  tournament.publiclyVisible
                }
                trueDescription="Visible"
                falseDescription="Hidden"
                onClick={() => {
                  context?.updateTournament(!tournament.publiclyVisible, "publiclyVisible");
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Event Site URL",
            value: tournament.publicUrl,
            onChange: (e) => {
              context?.updateTournament(e.target.value, "publicUrl");
            },
            description: "Customize your event site url to improve SEO.",
            hintText:
              "Clearing this field will generate a new random url based on your tournament details.",
            isEditable: true,
          },
          {
            label: "Registration Privacy",
            hintText: tournament.openRegistration ? "Event registration is accessible from event site." : "Event registration is only accessible by the link.",
            value: (
              <GSToggle
                value={
                  tournament.openRegistration
                }
                trueDescription="Public Registration"
                falseDescription='Private Registration'
                onClick={() => {
                  context?.updateTournament(!tournament.openRegistration, "openRegistration");
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Registration Close Date",
            value: tournament?.registrationClose
              ? getDateTimeYMD(tournament?.registrationClose)
              : "",
            type: "datetime-local",
            onChange: (e) => {
              context?.updateTournament(e.target.value, "registrationClose");
            },
            isEditable: true,
          },
        ],
      },
    ];

    return form;
  }
};

export const getRegistrationEventDetailsForm = (context, tournament) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Banner Image",
            value: (
              <GSFileSelect
                id='hero'
                description='Tap or drag a file to upload. Accepted file types: .JPG, .PNG, .GIF'
                setSelectedFiles={(files) => {
                  context.setLogo(files, "heroBannerPhoto");
                }}
                removeSourceItem={() => {
                  context.setLogo([], "heroBannerPhoto");
                }}
                accept='.jpg, .jpeg, .png'
                sourceList={context.getLogo("heroBannerPhoto")}
                title={
                  <GSButton
                    buttonIcon={faArrowCircleUp}
                    title='upload'
                  ></GSButton>
                }
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Banner Overlay *",
            description:
              "A color overlay helps the tournament details maintain readability.",
            value: (
              <GSRadioGroup
                selectionChanged={context?.setBannerImageOverlayStyle}
                options={context.getBannerImageOverlayOptions?.()}
                selectedOption={context.getBannerImageOverlayStyle?.()}
              />
            ),
          },
          {
            label: "Event Description",
            value: (
              <GSTextEditor
                defaultState='editor'
                value={tournament?.description}
                placeholder='Event Description'
                onChange={(e) => {
                  context?.updateTournament(e, "description");
                }}
                onFocus={() => {
                  context.setIsValid(true)
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Additional Event Description",
            value: (
              <GSTextEditor
                defaultState='editor'
                value={
                  tournament?.tournamentPromotion?.additionalTournamentDetails
                }
                placeholder='Additional Event Description'
                onChange={(e) => {
                  context?.updateTournamentPromotion(
                    e,
                    "additionalTournamentDetails"
                  );
                }}
                onFocus={() => {
                  context.setIsValid(true)
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Registration Details",
            value: (
              <GSTextEditor
                defaultState='editor'
                placeholder='Registration Details'
                value={tournament?.promotionDetails}
                onChange={(e) => {
                  context?.updateTournament(e, "promotionDetails");
                }}
                onFocus={() => {
                  context.setIsValid(true)
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Promotional Media",
        fields: [
          {
            label: "Promotional Image",
            value: (
              <GSFileSelect
                id='promotional-photo'
                description='Tap or drag a file to upload. Accepted file types: .JPG, .PNG, .GIF'
                setSelectedFiles={(files) => {
                  context.setLogo(files, "promotionalPhoto");
                }}
                sourceList={context.getLogo("promotionalPhoto")}
                removeSourceItem={() => {
                  context.setLogo([], "promotionalPhoto");
                }}
                title={
                  <GSButton
                    buttonIcon={faArrowCircleUp}
                    title='upload'
                  ></GSButton>
                }
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Promotional Video",
            value: (
              <GSFileSelect
                id='promotional-video'
                accept='.mp4, .mov'
                description='Tap or drag a file to upload. Accepted file types: .MP4, .MOV'
                setSelectedFiles={(files) => {
                  context.setLogo(files, "promotionalVideo");
                }}
                sourceList={context.getVideo("promotionalVideo")}
                removeSourceItem={() => {
                  context.setLogo([], "promotionalVideo");
                }}
                title={
                  <GSButton
                    buttonIcon={faArrowCircleUp}
                    title='Upload'
                  ></GSButton>
                }
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
      //admin only
      // {
      //   type: "vertical xx-large-gap",
      //   title: "Additional Event Sponsor",
      //   fields: [
      //     {
      //       label: "Additional Event Sponsor",
      //       value: (
      //         <GSFileSelect
      //           id="additional-event-sponsor"
      //           accept=".jpg, .jpeg, .png"
      //           description="Tap or drag a file to upload. Recommended images have a minimum width of 600px and a minimum height of 300px. Accepted file types: .JPG, .PNG, .GIF"
      //           setSelectedFiles={(files) => {
      //             context.setLogo(files, "secondaryEventPhoto");
      //           }}
      //           sourceList={context.getLogo("secondaryEventPhoto")}
      //           removeSourceItem={() => {context.setLogo([], "secondaryEventPhoto")}}
      //           title={
      //             <GSButton
      //               buttonIcon={faPlus}
      //               title="add additional event sponsor"
      //             ></GSButton>
      //           }
      //         />
      //       ),
      //       customView: true,
      //       isEditable: true,
      //     },
      //     {
      //       label: "Additional Event Sponsor",
      //       value: tournament.tournamentPromotion?.secondaryEventPhotoText,
      //       onChange: (e) => {
      //         context?.updateTournamentPromotion(
      //           e.target.value,
      //           "secondaryEventPhotoText"
      //         );
      //       },
      //       isEditable: true,
      //     },
      //     {
      //       label: "Additional Leaderboard Sponsor",
      //       value: (
      //         <GSFileSelect
      //           id="additional-leaderboard-sponsor"
      //           accept=".jpg, .jpeg, .png"
      //           description="Tap or drag a file to upload. Recommended images have a minimum width of 600px and a minimum height of 300px. Accepted file types: .JPG, .PNG, .GIF"
      //           setSelectedFiles={(files) => {
      //             context.setLogo(files, "secondaryLeaderboardPhoto");
      //           }}
      //           sourceList={context.getLogo("secondaryLeaderboardPhoto")}
      //           removeSourceItem={() => {context.setLogo([], "secondaryLeaderboardPhoto")}}
      //           title={
      //             <GSButton
      //               buttonIcon={faPlus}
      //               title="add additional leaderboard sponsor"
      //             ></GSButton>
      //           }
      //         />
      //       ),
      //       customView: true,
      //       isEditable: true,
      //     },
      //   ],
      // },
    ];
    return form;
  }
};

export const getEventPageVisibilityForm = (context, tournament) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Sponsorships",
            value: (
              <GSToggle
                value={
                  !tournament.tournamentPromotion?.hideSponsorship ?? false
                }
                trueDescription='Visible'
                falseDescription='Hidden'
                onClick={() => {
                  context?.updateTournamentPromotion(
                    !tournament.tournamentPromotion?.hideSponsorship,
                    "hideSponsorship"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Hole Assignments",
            value: (
              <GSToggle
                value={
                  !tournament.tournamentPromotion?.hideHoleAssignment ?? false
                }
                trueDescription='Visible'
                falseDescription='Hidden'
                onClick={() => {
                  context?.updateTournamentPromotion(
                    !tournament.tournamentPromotion?.hideHoleAssignment,
                    "hideHoleAssignment"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Course Details",
            value: (
              <GSToggle
                value={
                  !tournament.tournamentPromotion?.hideCourseDetails ?? false
                }
                trueDescription='Visible'
                falseDescription='Hidden'
                onClick={() => {
                  context?.updateTournamentPromotion(
                    !tournament.tournamentPromotion?.hideCourseDetails,
                    "hideCourseDetails"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },

          {
            label: "Leaderboards",
            value: (
              <GSToggle
                value={
                  !tournament.tournamentPromotion?.hideLeaderboards ?? false
                }
                trueDescription='Visible'
                falseDescription='Hidden'
                onClick={() => {
                  context?.updateTournamentPromotion(
                    !tournament.tournamentPromotion?.hideLeaderboards,
                    "hideLeaderboards"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Event Registrants",
            value: (
              <GSToggle
                value={
                  !tournament.tournamentPromotion?.hideRegistrants ?? false
                }
                trueDescription='Visible'
                falseDescription='Hidden'
                onClick={() => {
                  context?.updateTournamentPromotion(
                    !tournament.tournamentPromotion?.hideRegistrants,
                    "hideRegistrants"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getRegistrationReceiptForm = (context, tournamentPromotion) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Receipt Image",
            value: (
              <GSFileSelect
                id='registration-confirmation-email-photo'
                accept='.jpg, .jpeg, .png'
                description='Tap or drag a file to upload. Accepted file types: .JPG, .PNG, .GIF'
                sourceList={context.getLogo(
                  "registrationConfirmationEmailPhoto"
                )}
                setSelectedFiles={(files) => {
                  context.setLogo(files, "registrationConfirmationEmailPhoto");
                }}
                removeSourceItem={() => {
                  context.setLogo([], "registrationConfirmationEmailPhoto");
                }}
                title={
                  <GSButton
                    buttonIcon={faArrowCircleUp}
                    title='upload'
                  ></GSButton>
                }
              />
            ),
            customView: true,
            type: "text-area",
            rows: 5,
            isEditable: true,
          },
          {
            label: "Receipt Message",
            description:
              "This optional message is displayed at the beginning of the automated order receipt email.",
            value: (
              <GSTextEditor
                defaultState='editor'
                placeholder='Receipt Message'
                value={tournamentPromotion?.registrationConfirmationEmailText}
                onChange={(e) => {
                  context?.updateTournamentPromotion(
                    e,
                    "registrationConfirmationEmailText"
                  );
                }}
                onFocus = {() => {
                  context?.isValid?.(true)
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getAuctionForm = (context, externalLink) => {
  const { validLink } = validations;

  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Auction Website",
            placeholder: "Auction Website",
            value: externalLink?.externalLink,
            hintText: "e.g. https://www.auctionwebsite.com",
            onChange: (e) => context?.updateAuctionLink(e.target.value),
            rightIcon: faExternalLinkSquareAlt,
            rightIconClick: () => {
              if (validLink(externalLink?.externalLink)) {
                context.viewExternalLink(externalLink);
              }
            },
            isEditable: true,
            validation: {
              isValid: validLink,
              invalidLabel: "Not a valid link",
            },
            failedValidation: context.validationFailed,
          },
        ],
      },
    ];
    return form;
  }
};
