import { faCircleCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSCircleImage,
  GSInfoGroup,
} from "golfstatus_react_components";
import React from "react";
import { getDate } from "../../../helpers/Dates";
import "./tournament-settings-manager-item.scss";

const TournamentSettingsManagerItem = (props) => {
  const {
    avatar,
    name,
    email,
    type,
    invitationSentAt,
    invitationAcceptedAt,
    resendInvitation,
    hideInvitation,
    lastSignInAt,
    createdAt,
  } = props;

  const orgMember = type === "Organization Member";

  // Existing users that are invited to be org members don't get invited, they're automatically added.
  const existingAccount = orgMember && !invitationSentAt;

  const getInvitationStatusColor = () => {
    if ((invitationAcceptedAt && lastSignInAt) || existingAccount) {
      return "green";
    }
    return "light-grey";
  };

  const getInvitationStatus = () => {
    if (existingAccount) {
      return "Active";
    }
    if (!invitationAcceptedAt) {
      return "Pending";
    }
    if (invitationAcceptedAt && !lastSignInAt) {
      return "Accepted";
    }
    return "Active";
  };

  const getInvitationIcon = () => {
    return (invitationAcceptedAt || existingAccount) && faCircleCheck;
  };

  const getActivityText = () => {
    if (invitationSentAt && !invitationAcceptedAt) {
      return `Invitation Sent on ${getDate(invitationSentAt)}`;
    }
    if (invitationAcceptedAt && !lastSignInAt) {
      return `No Activity`;
    }
    if (invitationAcceptedAt || (existingAccount && lastSignInAt)) {
      return `Last Login on ${getDate(lastSignInAt)}`;
    }
    if (existingAccount && !lastSignInAt) {
      return `Account Created on ${getDate(createdAt)}`;
    }
  };

  const imageSection = {
    sectionItems: [
      {
        type: "primary no-wrap",
        value: <GSCircleImage size="large" url={avatar} />,
      },
    ],
  };

  const infoSection = {
    sectionItems: [
      {
        type: "primary",
        value: name,
      },
    ],
  };

  const emailSection = {
    type: "secondary",
    value: email,
  };

  if (type === "Tournament Manager") {
    infoSection?.sectionItems?.push?.(emailSection);
  }

  const typeSection = {
    sectionItems: [
      {
        type: "secondary",
        value: type ?? "Tournament Manager",
      },
    ],
  };

  const invitationSection = {
    sectionItems: [
      {
        type: "secondary no-wrap flex-end flex-start-mobile",
        value: getActivityText(),
      },
    ],
  };

  const sendActionSection = {
    type: "primary no-wrap resend-button",
    value: (
      <GSButton
        buttonIcon={faPaperPlane}
        title="Resend Invitation"
        type="light-grey"
        onClick={(e) => {
          resendInvitation?.(props);
          e?.stopPropagation?.();
        }}
      />
    ),
  };

  if (type === "Tournament Manager" && invitationAcceptedAt === null) {
    invitationSection?.sectionItems?.push?.({ ...sendActionSection });
  }

  const statusInfo = {
    sectionItems: [
      {
        type: "primary no-wrap",
        value: (
          <GSButton
            title={getInvitationStatus()}
            type={`secondary pill status ${getInvitationStatusColor()}`}
            buttonIcon={getInvitationIcon()}
          />
        ),
      },
    ],
  };

  const imageColumn = {
    sections: [
      {
        ...imageSection,
      },
    ],
  };

  const infoColumn = {
    fit: "stretch",
    sections: [
      {
        ...infoSection,
      },
      { ...typeSection },
      { ...statusInfo },
    ],
  };

  const inviteColumn = {
    sections: [
      {
        ...invitationSection,
      },
    ],
  };

  const getGroup = () => {
    let group = [{ ...imageColumn }, { ...infoColumn }];
    if (!hideInvitation) {
      group.push(inviteColumn);
    }
    return group;
  };

  return (
    <tournament-settings-manager-item>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-settings-manager-item>
  );
};

export default TournamentSettingsManagerItem;
