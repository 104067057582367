import { Route } from "react-router-dom";

import TournamentFlights from "../../components/tournament/tournament-flights/tournament-flights";
import TournamentFlightCreateEdit from "../../components/tournament/tournament-flights/tournament-flight-create-edit";
import TournamentFlightDetails from "../../components/tournament/tournament-flights/tournament-flight-details";
import TournamentFlightSettings from "../../components/tournament/tournament-flights/tournament-flight-settings";
import TournamentFlightRemove from "../../components/tournament/tournament-flights/tournament-flight-remove";
import TournamentFlightAssignments from "../../components/tournament/tournament-flights/tournament-flight-assignments";
import TournamentFlightsAssignAll from "../../components/tournament/tournament-flights/tournament-flights-assign-all";
import TournamentFlightsAutoAssign from "../../components/tournament/tournament-flights/tournament-flights-auto-assign";
import TournamentFlightsRemoveAssignments from "../../components/tournament/tournament-flights/tournament-flights-remove-assignments";
import TournamentFlightTees from "../../components/tournament/tournament-flights/tournament-flight-tees";

export const getTournamentFlightingRoutes = () => {
  return (
    <Route path={"flighting"} element={<TournamentFlights />}>
      <Route path={"add"} element={<TournamentFlightCreateEdit />}></Route>
      <Route path={"assign"} element={<TournamentFlightsAssignAll />}></Route>
      <Route
        path={"assign/auto-assign"}
        element={<TournamentFlightsAutoAssign />}
      ></Route>
      <Route
        path={"assign/remove-all"}
        element={<TournamentFlightsRemoveAssignments />}
      ></Route>
      <Route path={":id"} element={<TournamentFlightDetails />}></Route>
      <Route path={":id/edit"} element={<TournamentFlightCreateEdit />}></Route>
      <Route
        path={":id/assignments"}
        element={<TournamentFlightAssignments />}
      ></Route>
      <Route
        path={":id/tees"}
        element={<TournamentFlightTees />}
      ></Route>
      <Route path={":id/remove"} element={<TournamentFlightRemove />}></Route>
      <Route path={"settings"} element={<TournamentFlightSettings />}></Route>
    </Route>
  );
};
