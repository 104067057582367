import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  organizationLinks,
  organizationManagers,
  organizations,
  organizationSettings,
  saveThunkResponse,
  usersURL,
} from "../app/api";
import {
  getOpts,
  getResponse,
  getSerializedData,
  shortDelay,
  getAttributes,
} from "../helpers/JSONapi";

export const getOrganizationList = createAsyncThunk(
  "organizations/getOrganizationList",
  async (filter, thunkAPI) => {
    const url = filter?.userID
      ? `${usersURL}/${filter.userID}/organizations`
      : organizations;
    const response = await gsApi.get(url, {
      data: filter,
    });
    return response;
  }
);

export const getParentOrganizationList = createAsyncThunk(
  "organizations/getParentOrganizationList",
  async (filter, thunkAPI) => {
    const response = await gsApi.get(organizations, {
      data: filter,
    });
    return response;
  }
);

export const selectOrganization = createAsyncThunk(
  "organizations/selectOrganization",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}`);
    return response;
  }
);

export const getOrganizationTournaments = createAsyncThunk(
  "organizations/getOrganizationTournaments",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}/tournaments`, {
      data: {
        search: true,
        status: "future",
      },
    });
    return response;
  }
);
export const getOrganizationPastTournaments = createAsyncThunk(
  "organizations/getOrganizationPastTournaments",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}/tournaments`, {
      data: {
        search: true,
        status: "past",
      },
    });
    return response;
  }
);

export const getOrganizationFacilities = createAsyncThunk(
  "organizations/getOrganizationFacilities",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}/facilities`);
    return response;
  }
);

export const getOrganizationAvailableCurrencies = createAsyncThunk(
  "organizations/getOrganizationAvailableCurrencies",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${id}/available_currencies`
    );
    return response;
  }
);

export const getOrgGolfstatusEmployee = createAsyncThunk(
  "organizations/getOrgGolfstatusEmployee",
  async (orgID, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${orgID}/golf-status-employee`
    );
    return response;
  }
);

export const getParentOrg = createAsyncThunk(
  "organizations/getParentOrg",
  async (orgID, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${orgID}/parent-organization`
    );
    return response;
  }
);

export const getOrgSettings = createAsyncThunk(
  "organizations/getOrgSettings",
  async (orgID, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${orgID}/organization-setting`
    );
    return response;
  }
);

export const getOrgLinks = createAsyncThunk(
  "organizations/getOrgLinks",
  async (orgID, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${orgID}/organization-additional-links`
    );
    return response;
  }
);
//create
export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (organization, thunkAPI) => {
    const serializedData = getSerializedData(
      organization,
      "organization",
      getOpts(organization, getOrganizationRelationships(organization))
    );
    serializedData.data.relationships[
      "organization-signup"
    ] = getSerializedData(
      organization.organizationSignup,
      "organization-signup",
      getOpts(organization.organizationSignup)
    );
    return await getResponse(
      gsApi.post,
      `${organizations}`,
      serializedData,
      thunkAPI
    );
  }
);
export const createOrganizationSettings = createAsyncThunk(
  "organizations/createOrganizationSettings",
  async (settings, thunkAPI) => {
    const serializedData = getSerializedData(
      settings,
      "organization-settings",
      getOpts(settings, getOrganizationSettingsRelationships())
    );
    return await getResponse(
      gsApi.post,
      `${organizationSettings}`,
      serializedData,
      thunkAPI
    );
  }
);
export const createOrganizationLink = createAsyncThunk(
  "organizations/createOrganizationLink",
  async (link, thunkAPI) => {
    const serializedData = getSerializedData(
      link,
      "organization-additional-links",
      getOpts(link, getOrganizationSettingsRelationships())
    );
    return await getResponse(
      gsApi.post,
      `${organizationLinks}`,
      serializedData,
      thunkAPI
    );
  }
);
//save
export const saveOrganization = createAsyncThunk(
  "organizations/saveOrganization",
  async (organization, thunkAPI) => {
    let serializedData = getSerializedData(
      organization,
      "organization",
      getOpts(organization, getOrganizationRelationships(organization))
    );
    if (organization.facilities) {
      const serializedFacilities = organization.facilities.map(
        (f) => getSerializedFacility(f).data
      );
      serializedData.data.relationships = {
        ...serializedData.data.relationships,
        facilities: { data: serializedFacilities },
      };
    }
    return await getResponse(
      gsApi.patch,
      `${organizations}/${organization.id}`,
      serializedData,
      thunkAPI
    );
  }
);
export const saveOrganizationSettings = createAsyncThunk(
  "organizations/saveOrganizationSettings",
  async (settings, thunkAPI) => {
    const serializedData = getSerializedData(
      settings,
      "organization-settings",
      getOpts(settings, getOrganizationSettingsRelationships())
    );
    return await getResponse(
      gsApi.patch,
      `${organizationSettings}/${settings.id}`,
      serializedData,
      thunkAPI
    );
  }
);
export const acceptOrganizationinvite = createAsyncThunk(
  "organizations/acceptOrganizationinvite",
  async (invite, thunkAPI) => {
    
    let response = await getResponse(
      gsApi.patch,
      `${organizationManagers}/accept-invite`,
      { "invitation_token": invite?.invitationToken },
      thunkAPI
    );

    response.payload = invite
    return response
  }
);

//delete
export const deleteOrganizationLink = createAsyncThunk(
  "access/deleteOrganizationLink",
  async (id, thunkAPI) => {
    const response = await gsApi.delete(`${organizationLinks}/${id}`, {
      data: {},
    });
    return response;
  }
);

export const organizationSlice = createSlice({
  name: "organizations",
  initialState: {
    organizationList: [],
    parentOrgList: [],
    selectedOrganization: {},
    selectedOrganizationRepresentative: {},
    selectedOrganizationParent: {},
    selectedOrganizationTournaments: [],
    selectedOrganizationPastTournaments: [],
    selectedOrganizationSettings: {},
    selectedOrganizationLinks: [],
    selectedOrganizationAvailableCurrencies: [],
    notifications: [],
    loading: [],
  },
  reducers: {
    setOrganizations: (state, action) => {
      state.organizationList = action.payload;
    },
    setParentOrgList: (state, action) => {
      state.parentOrgList = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setSelectedOrganizationSetting: (state, action) => {
      state.selectedOrganizationSettings = action.payload;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...getThunkResponse(getOrganizationList, (state, action) => {
      state.organizationList = action.payload
    }),
    ...getThunkResponse(getParentOrganizationList, (state, action) => {
      state.parentOrgList = action.payload;
    }),
    ...getThunkResponse(selectOrganization, (state, action) => {
      state.selectedOrganization = action.payload;
    }),
    ...getThunkResponse(getOrganizationTournaments, (state, action) => {
      state.selectedOrganizationTournaments = action.payload;
    }),
    ...getThunkResponse(getOrganizationPastTournaments, (state, action) => {
      state.selectedOrganizationPastTournaments = action.payload?.reverse?.();
    }),
    ...getThunkResponse(getOrganizationFacilities, (state, action) => {
      state.selectedOrganization.facilities = action.payload;
    }),
    ...getThunkResponse(getOrganizationAvailableCurrencies, (state, action) => {
      state.selectedOrganizationAvailableCurrencies = action.payload;
    }),
    ...getThunkResponse(getParentOrg, (state, action) => {
      state.selectedOrganizationParent = action.payload;
    }),
    ...getThunkResponse(getOrgSettings, (state, action) => {
      state.selectedOrganizationSettings = action.payload;
    }),
    ...getThunkResponse(getOrgLinks, (state, action) => {
      state.selectedOrganizationLinks = action.payload;
    }),
    ...getThunkResponse(getOrgGolfstatusEmployee, (state, action) => {
      state.selectedOrganizationRepresentative = action.payload;
    }),
    ...createThunkResponse(
      createOrganization,
      (state, action) => {
        state.organizationList = [action.payload, ...state.organizationList];
      },
      "Organization",
      shortDelay
    ),
    ...createThunkResponse(
      createOrganizationSettings,
      (state, action) => {
        state.selectedOrganizationSettings = action.payload;
      },
      "Organization Settings",
      shortDelay
    ),
    ...createThunkResponse(
      createOrganizationLink,
      (state, action) => {
        state.selectedOrganizationLinks.push?.(action.payload);
      },
      "organization link",
      shortDelay
    ),
    ...saveThunkResponse(
      saveOrganizationSettings,
      (state, action) => {},
      "Organization Settings",
      shortDelay
    ),
    ...saveThunkResponse(
      saveOrganization,
      (state, action) => {
        const savedOrg = action.payload;
        let orgIndex = state.organizationList.findIndex(
          (o) => o.id === savedOrg.id
        );
        state.organizationList[orgIndex] = {
          ...state.organizationList[orgIndex],
          ...savedOrg,
        };
      },
      "Organization",
      shortDelay
    ),
    //delete
    ...deleteThunkResponse(
      deleteOrganizationLink,
      (state, action) => {
        const delID = action?.meta?.arg?.id
          ? action?.meta?.arg?.id
          : action?.meta?.arg;
        const filteredList = state.selectedOrganizationLinks?.filter?.(
          (l) => l.id !== delID
        );
        state.selectedOrganizationLinks = filteredList;
      },
      "Organization Link",
      shortDelay
    ),
  },
});

export const {
  setSelectedOrganization,
  setOrganizations,
  setParentOrgList,
  clearNotifications,
  setSelectedOrganizationSetting,
} = organizationSlice.actions;

export const selectCurrentOrganization = (state) =>
  state.orgs.selectedOrganization;

export const selectOrganizationList = (state) => state.orgs.organizationList;

export const selectOrganizationNotifications = (state) =>
  state.orgs.notifications;

export const selectAvailableCurrencies = (state) =>
  state.orgs.selectedOrganizationAvailableCurrencies;

export const selectLoading = (state) => state.orgs.loading;

export const selectOrganizationSettings = (state) =>
  state.orgs.selectedOrganizationSettings;

export const selectOrgLinks = (state) => state.orgs.selectedOrganizationLinks;

export default organizationSlice.reducer;

function getOrganizationRelationships(org) {
  const relationships = [];
  relationships.push({
    name: "golfStatusEmployee",
    opt: { ref: "id", included: true },
  });
  relationships.push({
    name: "parentOrganization",
    opt: { ref: "id", included: true },
  });
  relationships.push({
    name: "organizationSignup",
    opt: { data: getAttributes(org) },
  });
  relationships.push({
    name: "currency",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getOrganizationSettingsRelationships() {
  const relationships = [];
  relationships.push({
    name: "organization",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

const getSerializedFacility = (facility) => {
  const sd = getSerializedData(facility, "facilities", getOpts(facility));
  return sd;
};
