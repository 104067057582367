import { GSRadioGroup } from "golfstatus_react_components";

export const holeAssignmentForm = (context) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Assignment Type",
            value: (
              <GSRadioGroup
                selectionChanged={context.setAutoAssignSort}
                options={context.getAutoAssignSortOptions?.()}
                selectedOption={context.getAutoAssignSort?.()}
              />
            ),
          },
          {
            label: "Assignment Order",
            value: (
              <GSRadioGroup
                selectionChanged={context?.setAutoAssignDirection}
                options={context.getAutoAssignDirectionOptions?.()}
                selectedOption={context.getAutoAssignDirection?.()}
              />
            ),
          },
        ],
      },
      {
        title: "Available Holes",
        type: "vertical xx-large-gap",
        fields: [
          {
            label:
              "All Holes",
              description: "Players and teams will not be assigned to locked holes.",
            value: context?.getHoleAssignmentList?.(),
          },
        ],
      },
    ];
    return form;
  }
};
