import "./tournament-sponsor-detail.scss";
import React, { useEffect } from "react";
import {  GSItemList, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TournamentSponsorDetailHero from "./tournament-sponsor-detail-hero";
import { clearNotifications, getTournamentSponsor, selectCurrentSponsor, selectSponsorNotifications } from "../../../reducers/sponsorSlice";
import NavigationListItem from "../../navigation-list-item";
import { useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentSponsorDetail = (props) => {
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/sponsors/:sponsorID");
  const dispatch = useDispatch()

  const currentSponsor = useSelector(selectCurrentSponsor);
  const notifications = useSelector(selectSponsorNotifications)

  useEffect(() => {
    dispatch(getTournamentSponsor(match.params.sponsorID));
  }, [dispatch, match.params.sponsorID]);

  const getSponsorPages = () => {
    let pages = [
      {
        label: "Sponsored Holes",
        details: "View and manage sponsored holes for all rounds.",
        isExternal: false,
        url: "holes",
        hidden: false,
      },
    ];
    return pages;
  };

  const removeSponsor = () => {
    navigate("remove")
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete Sponsor",
        action: removeSponsor,
        type: "transparent red",
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(`/${match.params.tournamentID}/sponsors`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Sponsor Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const selectPage = (page) => {
    navigate(page.url)
  }

  const getContent = () => {
    return (
      <gs-form>
        <TournamentSponsorDetailHero
          {...currentSponsor}
        ></TournamentSponsorDetailHero>
        <GSItemList
        type="vertical selectable"
        items={getSponsorPages()}
        itemSelected={selectPage}
        listItem={(item) => (<NavigationListItem page={item}></NavigationListItem>)}
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-sponsor-detail>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-sponsor-detail>
  );
};

//export the new namet
export default TournamentSponsorDetail;
