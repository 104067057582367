import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getThunkResponse,
  liveTournamentRounds,
  liveTournamentScorecards,
  saveThunkResponse,
  teamRounds,
  tournamentRounds,
  tournamentTeams,
  validateThunkResponse,
} from "../app/api";
import gsApi from "../auth/auth";
import {
  extraLongDelay,
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  longDelay,
} from "../helpers/JSONapi";

export const getLiveTournamentScorecard = createAsyncThunk(
  "scorecards/getLiveTournamentScorecard",
  async (liveTournamentScorecardID, thunkAPI) => {
    const response = await gsApi.get(
      `${liveTournamentScorecards}/${liveTournamentScorecardID}`
    );
    return response;
  }
);

export const getLiveTournamentRound = createAsyncThunk(
  "scorecards/getLiveTournamentRound",
  async (liveTournamentRoundID, thunkAPI) => {
    const response = await gsApi.get(
      `${liveTournamentRounds}/${liveTournamentRoundID}`
    );
    return response;
  }
);

// we have to loop through the live rounds to get the object and the data since its not queryable with other calls
export const getLiveTournamentScorecardLiveRounds = createAsyncThunk(
  "scorecards/getLiveTournamentScorecardLiveRounds",
  async (liveTournamentScorecardID, thunkAPI) => {
    const liveTournamentScorecard = await gsApi.get(
      `${liveTournamentScorecards}/${liveTournamentScorecardID}`
    );

    let liveRounds = [];

    //must use a for loop for async to work correctly
    for (const liveRound of liveTournamentScorecard.liveRounds) {
      const lr = await gsApi.get(`${liveTournamentRounds}/${liveRound.id}`);
      liveRounds.push(lr)
    }

    let liveRoundWithTeamRounds = []

    for(const liveRound of liveRounds)
    {
      const teamAllRounds = await gsApi.get(`${tournamentTeams}/${liveRound.tournamentTeam.id}/team-rounds`)
      const teamRound = teamAllRounds.find?.(tr => tr?.tournamentRound.id === liveRound?.tournamentRound?.id)
      liveRoundWithTeamRounds.push({...liveRound, teamRound})
    }

    let liveTournamentScorecardWithRounds = {
      ...liveTournamentScorecard,
      liveRounds: liveRoundWithTeamRounds,
    };

    return liveTournamentScorecardWithRounds;
  }
);

//validate
export const validateScorecardsImport = createAsyncThunk(
  "scorecards/validateScorecardsImport",
  async (verify, thunkAPI) => {
    let response =  await getResponse(
      gsApi.post,
      `${tournamentRounds}/${verify?.id}/validate-scorecards-import`,
      verify,
      thunkAPI
    );
    return response
  }
);

export const importScorecards = createAsyncThunk(
  "scorecards/importScorecards",
  async (verify, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournamentRounds}/${verify?.id}/import-scorecards`,
      verify,
      thunkAPI
    );
  }
);

export const generateFakeScores = createAsyncThunk(
  "scorecards/generateFakeScores",
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournamentRounds}/${roundID}/fake-scores`,
      {},
      thunkAPI
    );
  }
);

// save
export const saveLiveTournamentRound = createAsyncThunk(
  "rounds/saveLiveTournamentRound",
  async (liveTournamentRound, thunkAPI) => {
    let serializedData = {
      ...getSerializedData(
        liveTournamentRound,
        "live-tournament-rounds",
        getOpts(liveTournamentRound, [
          ...getIDRelationship("tournament-team"),
          ...getIDRelationship("player"),
        ])
      ),
    };

    serializedData.data.relationships["live-scorecard"] = {
      data: {
        id: liveTournamentRound?.liveScorecard?.id,
        type: "live-tournament-scorecards",
      },
    };

    //then endpoint requires underscore case here for some reason, so we have to manually convert the scores
    const serializedHoles = liveTournamentRound.scores.map(
      (s) =>
        getSerializedData(s, "score", {
          ...getOpts(s, []),
          keyForAttribute: "underscore_case",
        })?.data?.attributes
    );

    serializedData.data.attributes = {
      ...serializedData.data.attributes,
      scores: serializedHoles,
    };

    return await getResponse(
      gsApi.patch,
      `${liveTournamentRounds}/${liveTournamentRound.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTeamRound = createAsyncThunk(
  "rounds/saveTeamRound",
  async (teamRound, thunkAPI) => {
    let serializedData = {
      ...getSerializedData(
        teamRound,
        "team-rounds",
        getOpts(teamRound, [
        ])
      ),
    };

    //then endpoint requires underscore case here for some reason, so we have to manually convert the scores
    const serializedPlayerScores = teamRound?.playerScores?.map(
      (s) =>
        getSerializedData(s, "score", {
          ...getOpts(s, []),
          keyForAttribute: "underscore_case",
        })?.data?.attributes
    );

    const serializedPointScores = teamRound?.pointsScores?.map(
      (s) =>
        getSerializedData(s, "score", {
          ...getOpts(s, []),
          keyForAttribute: "underscore_case",
        })?.data?.attributes
    );

    serializedData.data.attributes = {
      ...serializedData.data.attributes,
      "player-scores": serializedPlayerScores,
      "points-scores" : serializedPointScores
    };

    return await getResponse(
      gsApi.patch,
      `${teamRounds}/${teamRound.id}`,
      serializedData,
      thunkAPI
    );
  }
);

//replace all occurences of scorecard with the name of your new slice
export const scorecardSlice = createSlice({
  name: "scorecards",
  initialState: {
    scorecards: [],
    scoringGroup: {
      label: "Assignments",
      value: 3,
    },
    currentLiveTournamentScorecard: {},
    currentLiveTournamentRound: {},
    currentLiveTournamentRounds: [],
    validatedScorecard: {},
    announceScore: false,
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    clearLiveTournamentRounds: (state, action) => {
      state.currentLiveTournamentRounds = [];
    },
    updateLiveTournamentRound: (state, action) => {
      let liveRounds = [...state?.currentLiveTournamentScorecard?.liveRounds]
      let index = liveRounds?.findIndex?.(
        (ltr) => ltr.id === action.payload.id
      );
      if (index !== -1) {
        liveRounds[index] = action.payload;
      }
      state.currentLiveTournamentScorecard.liveRounds = liveRounds
    },
    setLiveTournamentScorecard: (state, action) => {
      state.currentLiveTournamentScorecard = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
    setScoringGroup: (state, action) => {
      state.scoringGroup = action.payload;
    },
    setAnnounceScore: (state, action) => {
      state.announceScore = action.payload;
    },
    setValidatedScorecard: (state, action) => {
      state.validatedScorecard = action.payload
    }
  },
  extraReducers: {
    ...getThunkResponse(getLiveTournamentRound, (state, action) => {
      state.currentLiveTournamentRound = action.payload;
      state.currentLiveTournamentRounds = [
        ...state.currentLiveTournamentRounds,
        action.payload,
      ];
    }),
    ...getThunkResponse(getLiveTournamentScorecard, (state, action) => {
      state.currentLiveTournamentScorecard = action.payload;
    }),
    ...getThunkResponse(getLiveTournamentScorecardLiveRounds, (state, action) => {
      state.currentLiveTournamentScorecard = action.payload;
    }),
    ...saveThunkResponse(
      saveLiveTournamentRound,
      (state, action) => {},
      "Round"
    ),
    ...validateThunkResponse(
      validateScorecardsImport,
      (state, action) => {
        state.validatedScorecard = action.payload;
      },
      "scorecard import",
      extraLongDelay
    ),
    ...saveThunkResponse(importScorecards, (state, action) => {}, "Import Scores", extraLongDelay),
    ...saveThunkResponse(generateFakeScores, (state, action) => {}, "Scores", longDelay),
  },
});

export const {
  clearLiveTournamentRounds,
  addNotification,
  clearNotifications,
  updateLiveTournamentRound,
  setLiveTournamentScorecard,
  setScoringGroup,
  setAnnounceScore,
  setValidatedScorecard
} = scorecardSlice.actions;

//export any selectors needed
export const selectCurrentLiveTournamentRound = (state) =>
  state?.[scorecardSlice.name]?.currentLiveTournamentRound;

export const selectCurrentLiveTournamentRounds = (state) =>
  state?.[scorecardSlice.name]?.currentLiveTournamentRounds;

export const selectCurrentLiveTournamentScorecard = (state) =>
  state?.[scorecardSlice.name]?.currentLiveTournamentScorecard;

export const selectValidatedScorecard = (state) =>
  state?.[scorecardSlice.name]?.validatedScorecard;

export const selectNotifications = (state) =>
  state?.[scorecardSlice.name]?.notifications;

export const selectLoading = (state) => state?.[scorecardSlice.name]?.loading;

export const selectAnnounceScore = (state) => state?.[scorecardSlice.name]?.announceScore;

export const selectScoringGroup = (state) =>
  state?.[scorecardSlice.name]?.scoringGroup;

export default scorecardSlice.reducer;

//ADD reducer to store.js
