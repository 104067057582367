import React from "react";
import {
  GSButton,
  GSEmptyList,
  GSField,
  GSForm,
  GSQuickFilter,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faCheckCircle,
  faChevronLeft,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-hole-assignment.scss";
import { useFormValidation } from "../../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoundHoleAssignment,
  saveRoundHoleAssignment,
  selectCurrentHoleAssignment,
  selectCurrentRound,
  selectCurrentRoundHoleAssignments,
  selectRoundNotifications,
  setCurrentHoleAssignment,
  setCurrentRoundHoleAssignment,
} from "../../../../reducers/roundSlice";
import { useEffect } from "react";
import TournamentTeamItem from "../../tournament-leaderboards/tournament-team-item";
import {
  getTournamentTeams,
  selectQualifiedTeams,
} from "../../../../reducers/teamsSlice";
import SearchBar from "../../../search-bar";
import { useState } from "react";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { getHoleAssignmentForm } from "../../../../forms/TournamentRoundForms";
import { getTime } from "../../../../helpers/Dates";
import { getTournamentFlights, selectTournamentFlights } from "../../../../reducers/flightSlice";

//Name the component
const TournamentRoundHoleAssignment = (props) => {
  const [search, setSearch] = useState("");
  const [context, isValid, setIsValid] = useFormValidation(false);

  const navigate = useNavigate();
  const notifications = useSelector(selectRoundNotifications);

  const holeAssignment = useSelector(selectCurrentHoleAssignment);
  const tournamentTeams = useSelector(selectQualifiedTeams);
  const currentRoundHoleAssignments = useSelector(
    selectCurrentRoundHoleAssignments
  );
  const currentRound = useSelector(selectCurrentRound);
  const currentTournament = useSelector(selectCurrentTournament);
  const flights = useSelector(selectTournamentFlights)

  const match = useMatch(
    "/:tournamentID/rounds/:roundID/hole-assignments/:assignmentID"
  );

  const dispatch = useDispatch();

  useNotificationNavigation("saved", notifications, -1);

  const getFormTitle = () => {
    return `Hole ${holeAssignment?.hole} ${holeAssignment?.position} - ${getTime(
      holeAssignment.teeTime
    )}`;
  };

  useEffect(() => {
    if (match?.params?.assignmentID) {
      dispatch(getRoundHoleAssignment(match.params.assignmentID));
    }
  }, [match?.params?.assignmentID, dispatch]);

  useEffect(() => {
    if(match?.params?.tournamentID)
    {
      dispatch(getTournamentTeams(match.params.tournamentID));
      dispatch(getTournamentFlights(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const save = () => {
    if (context) {
      setIsValid(false);

      let update = { ...holeAssignment };
      update.tournamentRound = currentRound;
      update.tournament = currentTournament;
      dispatch(setCurrentRoundHoleAssignment(update));
      dispatch(saveRoundHoleAssignment(update));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: getFormTitle(),
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const defaultSearch = () => {
    if (
      holeAssignment?.maxTeamsPerHole ===
      holeAssignment?.tournamentTeams?.length
    ) {
      return;
    }
    return (
      <GSField
        label="Available Teams"
        value={
          <SearchBar
          type="no-pad"
            search={search}
            setSearch={setSearch}
            placeholder="Search Players & teams..."
          />
        }
      />
    );
  };

  const getholeAssignmentTeams = () => {
    return holeAssignment?.tournamentTeams?.map?.((t) =>
      tournamentTeams?.find?.((tt) => tt.id === t.id)
    );
  };

  const getFilteredTeams = () => {
    let currentTeamIDs = holeAssignment.tournamentTeams?.map?.((tt) => tt.id);
    if (
      holeAssignment?.maxTeamsPerHole ===
      holeAssignment?.tournamentTeams?.length
    ) {
      return [];
    }
    let filteredTeams = tournamentTeams.filter?.(
      (t) =>
        !currentTeamIDs.includes?.(t.id) &&
          `${t?.contactName} ${t?.name} ${t?.players
            ?.map?.((p) => p.name)
            ?.join(" ")}`
            ?.toLowerCase?.()
            .includes?.(search.toLowerCase?.())
    );

    let mappedTeams = filteredTeams?.map?.((ft) => ({
      ...ft,
      holeAssignment: currentRoundHoleAssignments?.find?.((crha) =>
        crha?.tournamentTeams?.map?.((tt) => tt?.id)?.includes?.(ft?.id)
      ),
      flight: flights?.find?.((flight) => flight.position === ft.mainFlightPosition)
    }));

    let unassigned = mappedTeams?.filter?.(
      (mt) => mt.holeAssignment === undefined
    ).sort?.(
      (a, b) =>
        a.mainFlightPosition - b.mainFlightPosition
    );
    let assigned = mappedTeams
      ?.filter?.((mt) => mt.holeAssignment !== undefined)
      .sort?.(
        (a, b) =>
          a.holeAssignment?.hole - b.holeAssignment?.hole ||
          a.holeAssignment.position?.localeCompare?.(b.holeAssignment.position) ||
          a.mainFlightPosition - b.mainFlightPosition
      );

    return [...unassigned, ...assigned];
  };

  const getEmptySelectedListMessage = () => {
    return (
      <GSEmptyList
        title={getFormTitle()}
        detail="Assign players and teams from the list below."
        actions={[
          // {
          //   title: "Auto Assign",
          //   onClick: () => {
          //     navigate(
          //       `../${match.params.roundID}/hole-assignments/auto-assign`
          //     );
          //   },
          //   type: "black",
          // },
        ]}
      />
    );
  };

  const getNoTeamsMessage = () => {
    return (
      <GSEmptyList
        title={"Players & Teams"}
        detail="Once players and teams are added to the tournament, they can be assigned to starting holes."
        actions={[
          {
            title: "View Players & Teams",
            onClick: () => {
              navigate(
                `../${match.params.roundID}/hole-assignments/auto-assign`
              );
            },
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title={"We couldn’t find who you’re looking for…"}
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            type: "light-grey",
            onClick: () => {
              setSearch("");
            },
          },
        ]}
      />
    );
  };

  const addToHoleAssignment = (team) => {
    setIsValid(true);
    setSearch("");
    let update = { ...holeAssignment };
    update.tournamentTeams = [...(update.tournamentTeams ?? []), team];
    dispatch(setCurrentHoleAssignment(update));
  };

  const removeFromHoleAssignment = (team) => {
    setIsValid(true);
    let update = { ...holeAssignment };
    update.tournamentTeams = update.tournamentTeams.filter?.(
      (t) => t.id !== team.id
    );
    dispatch(setCurrentHoleAssignment(update));
  };

  const getTeam = (item) => {
    return (
      <div className="team">
        <TournamentTeamItem {...item} />
        {item?.holeAssignment ? (
          <GSButton
            type="no-wrap"
            buttonIcon={faCheckCircle}
            title={`hole ${item?.holeAssignment?.hole} ${item?.holeAssignment?.position}`}
            onClick={() => {
              navigate(
                `../${match.params.roundID}/hole-assignments/${item?.holeAssignment?.id}`
              );
            }}
          />
        ) : (
          <GSButton
            type="light-grey"
            buttonIcon={faPlusCircle}
            title={"assign"}
            onClick={() => {
              addToHoleAssignment(item);
            }}
          />
        )}
      </div>
    );
  };

  context.getQuickFilter = () => {
    return (
      <GSQuickFilter
        getDefaultSearch={defaultSearch}
        filteredList={getFilteredTeams()}
        selectedList={getholeAssignmentTeams()}
        emptySearchList={search !== "" ? getEmptySearchMessage() : getholeAssignmentTeams()?.length > 0 ? null : getNoTeamsMessage()}
        emptySelectedList={getEmptySelectedListMessage()}
        getItem={(item) => getTeam(item)}
        itemSelected={() => {}}
        itemRemoved={removeFromHoleAssignment}
        multiple={true}
        selectListType="grey-empty-list rounded-empty-list"
        searchListType="grey-empty-list rounded-empty-list"
      />
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getFormTitle()}
        formSections={getHoleAssignmentForm(context)}
      />
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-round-hole-assignment>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-hole-assignment>
  );
};

//export the new namet
export default TournamentRoundHoleAssignment;
