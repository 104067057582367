import React, { useState } from "react";
import {
  GSItemList,
  GSSidePanelPage,
  GSEmptyList,
  GSActionBar
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faCog,
  faPlus,
  faSave,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-assignments.scss";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentFlight,
  getTournamentPreFlights,
  savePreflight,
  selectCurrentTournamentFlight,
  selectFlightLoading,
  selectTournamentTeamPreflights,
  updateTeamFlight,
  selectFlightNotifications
} from "../../../reducers/flightSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import TournamentTeamItem from "../tournament-leaderboards/tournament-team-item";
import { shuffleIndex } from "../../../helpers/Utilities";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";

//Name the component
const TournamentFlightAssignments = (props) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const tournamentPreflights = useSelector(selectTournamentTeamPreflights);
  const currentFlight = useSelector(selectCurrentTournamentFlight);
  const loading = useSelector(selectFlightLoading);
  const flightNotifications = useSelector(selectFlightNotifications);

  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/flighting/:flightID/assignments");

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPreFlights(match.params.tournamentID));
      dispatch(getTournamentFlight(match.params.flightID));
    }
  }, [dispatch, match?.params?.tournamentID, match?.params?.flightID]);

  const pageLoading = () => {
    return loading?.includes?.("getTournamentFlight") || loading?.includes?.("getTournamentPreFlights")
  }

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${currentFlight.name} Players & Teams`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const teamFilter = (teams) => {
    return teams?.filter?.((t) =>
      `${t?.tournamentTeam?.contactName} ${
        t?.tournamentTeam?.name
      } ${t?.tournamentTeam?.players?.map?.((p) => p.name)?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search.toLowerCase?.())
    );
  };

  const getSelectedTeams = (preflight) => {
    return preflight.teamPreflights
      ?.filter?.((tpf) => tpf.flight === currentFlight.name)
      ?.sort?.((a, b) => a.position - b.position);
  };

  const getAvailableTeams = (preflight) => {
    if (loading.includes("getTournamentPreFlights")) {
      return [];
    }
    let availableTeams = preflight.teamPreflights.filter?.(
      (tpf) => tpf.flight === null
    );
    return [...getSelectedTeams(preflight), ...availableTeams];
  };

  const clearSearch = () => {
    setSearch("");
  };

  const manageTeams = () => {
    navigate(`/${match.params.tournamentID}/teams`);
  };

  const preflightSettings = () => {
    navigate(`/${match.params.tournamentID}/flighting/settings`);
  };

  const getEmptyPreflightMessage = () => {
    return (
      <GSEmptyList
        title="Update Flight Settings"
        detail="To assign players and teams to flights, the tournament must be pre-flighted."
        actions={[
          {
            title: "Flight Settings",
            onClick: preflightSettings,
            type: "light-grey",
            buttonIcon: faCog
          },
        ]}
      />
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`${currentFlight.name} Players & Teams`}
        detail="There are no available players or teams."
        actions={[
          {
            title: "Add Team",
            buttonIcon: faPlus,
            onClick: manageTeams,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term or search all flights."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "Search All Flights",
            buttonIcon: faSearch,
            onClick: () => {navigate(`../assign?search=${search}`)},
            type: "light-grey no-wrap",
          },
        ]}
      />
    );
  };

  const addToFlight = (team, preflight) => {
    if (team) {
      updateFlight(
        currentFlight.name,
        team,
        getSelectedTeams(preflight)?.length + 1
      );
    }
  };

  const updateFlight = (flight, team, position) => {
    if (team) {
      let update = {
        ...team,
        flight: flight,
        position: position,
      };
      dispatch(updateTeamFlight({ update }));
      dispatch(savePreflight(update));
    }
  };

  const removeFromFlight = (preflight) => {
    if (preflight) {
      let update = { ...preflight, flight: null, position: null };
      dispatch(updateTeamFlight({ update }));
      dispatch(savePreflight(update));
    }
  };

  const itemMoved = (
    sourceItem,
    sourceIndex,
    destItem,
    destIndex,
    preflight
  ) => {
    if(destItem === undefined){
      return
    }
    if (sourceItem?.flight === null) {
      addToFlight(sourceItem, preflight);
      return;
    }

    if(destItem?.flight === null && sourceItem?.flight !== null){
      removeFromFlight(sourceItem);
      return
    }

    let source = { ...sourceItem };

    let flightTeams = [...getSelectedTeams(preflight)];

    const shuffled = flightTeams.map?.((pft, index) => ({
      ...pft,
      position: shuffleIndex(pft.position, source.position, destItem.position),
    }));

    shuffled.forEach?.((ft) => updateFlight(destItem.flight, ft, ft.position));
  };

  const getPreflightInfo = (preflight) => {
    const teams = teamFilter(getAvailableTeams(preflight))
    let unassigned = [];
    if (teams?.length > 0) {
      let indexItem = {
        index: teams.findIndex?.(t => t.flight === null),
        view: <GSActionBar type="grey-header x-large-pad" header="Unassigned"/>
      }
      let top = {
        index: teams.findIndex?.(t => t.flight !== null),
        view: <GSActionBar type="grey-header x-large-pad" header={`${currentFlight.name}`}/>
      }
      unassigned.push(top)
      unassigned.push(indexItem)
    }
    return (
      <GSItemList
        type="vertical team-list check-key-handle"
        isCheckList={true}
        itemChecked={(team) => {
          addToFlight(team, preflight);
        }}
        indexItems={unassigned}
        enableStep={false}
        itemUnchecked={removeFromFlight}
        selectedItems={getSelectedTeams(preflight)}
        items={teams}
        isSortable={true}
        itemMoved={(sourceItem, sourceIndex, destItem, destIndex) =>
          itemMoved(sourceItem, sourceIndex, destItem, destIndex, preflight)
        }
        dragOffset={-40}
        topScrollMargin={150}
        listItem={(preflight) => (
          <TournamentTeamItem
            {...preflight.tournamentTeam}
          ></TournamentTeamItem>
        )}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        //loading={loading.includes("getTournamentPreFlights")}
        loadingMainText="Loading Flight Teams..."
      ></GSItemList>
    );
  };

  const getSearch = () => {
    let input = {
      search, setSearch, placeholder: "Search Players and Teams..."
    }
    return <SearchBar {...input}/>
  }

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header={`${currentFlight.name} Players & Teams`}/>
        {getSearch()}
        <GSItemList
          type="vertical"
          items={tournamentPreflights}
          listItem={(flight) => getPreflightInfo(flight)}
          emptyMessage={getEmptyPreflightMessage()}
          loading={loading.includes("getTournamentPreFlights")}
          loadingMainText="Loading Flight Teams..."
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  let notificationSettings = {
    notifications: flightNotifications,
    timeoutAction,
    defaultBanner: {title: getNotificationItemInfo(`Assignments will automatically be saved.`, faSave), state: "grey"}
  };

  const [bannerNotifications] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-flight-assignments>
      <GSSidePanelPage
      loading={pageLoading()}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-flight-assignments>
  );
};

//export the new namet
export default TournamentFlightAssignments;
