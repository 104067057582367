import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";

const TournamentPackageCustomFieldItem = (props) => {

  const {name, required} = props

  const fieldInfoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary no-wrap full-width",
        value: required ? "Required" : "Not Required",
      },
    ]
  }

  const fieldInfoColumn ={
    fit: "stretch",
    sections: [
      {
        ...fieldInfoSection
      },
    ],
  }

  const getGroup = () => {
    let group = [{...fieldInfoColumn}]
    return group
  }

  return <tournament-package-custom-field-item>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </tournament-package-custom-field-item>
}


export default TournamentPackageCustomFieldItem