import "./tournament-team-disqualify.scss";
import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faBan, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { disqualifyTeamForm } from "../../../forms/TeamForm";
import {
  clearNotifications,
  getTournamentTeam,
  saveTournamentTeam,
  selectCurrentTeam,
  selectTeamsNotifications,
  setCurrentTeam,
} from "../../../reducers/teamsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";
import { useFormValidation } from "../../../hooks/formHooks";
//Name the component
const TournamentTeamDisqualify = (props) => {
  //hooks
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/teams/:teamID/disqualify");
  const dispatch = useDispatch();
  
  //selectors
  const currentTeam = useSelector(selectCurrentTeam);
  const currentTournament = useSelector(selectCurrentTournament);
  const notifications = useSelector(selectTeamsNotifications);

  //custom hooks
  useNotificationNavigation("saved", notifications, -1)

  //effects
  useEffect(() => {
    dispatch(getTournamentTeam(match?.params?.teamID));
  }, [dispatch, match.params.teamID]);

  //context
  const [context, isValid, setIsValid] = useFormValidation(true) 

  context.selectDisqualificationReason = (reason) => {
    const update = { ...currentTeam, disqualificationReason: reason.value };
    dispatch(setCurrentTeam(update));
  };

  //component functionality
  const disqualifyTeam = () => {
    setIsValid(false)
    const update = {
      ...currentTeam,
      disqualified: true,
      tournament: currentTournament,
    };
    dispatch(saveTournamentTeam(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Disqualify  Team",
        isDisabled: !isValid,
        action: disqualifyTeam,
        type: "orange",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Disqualify Team`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitle = () => {
    return (
      <div className="disqualify-header">
        <div className="icon">
          <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
        </div>{" "}
        Disqualify Team
      </div>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getFormTitle()}
        formSections={disqualifyTeamForm(context, currentTeam)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-team-disqualify>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-disqualify>
  );
};

//export the new namet
export default TournamentTeamDisqualify;
