import React from "react";

import "./tournament-hero.scss";

import {
  GSInfoGroup,
  GSActionBar,
  GSButton,
} from "golfstatus_react_components";

import TournamentInfoGroup from "./tournament-info-group";
import { getDaysTillNow, getYearsTillNow } from "../helpers/Dates";
import {
  getPrimaryTournamentActions,
  //getSecondaryTournamentActions,
} from "../forms/TournamentHeroActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const TournamentHero = (props) => {
  const { tournament } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tournamentInfo = {
    fit: "stretch mobile-full-width",
    sections: [
      {
        sectionItems: [
          {
            type: "full-width",
            value: <TournamentInfoGroup tournament={tournament} />,
          },
        ],
      },
    ],
  };

  const buttonLinkClicked = (action) => {
    if (action.dispatchAction) {
      dispatch(action.dispatchAction).then?.(() => {
        if (action.cleanUpAction) dispatch(action.cleanUpAction);
      });
    }
    if (!action.url) {
      return;
    }
    if (action.isExternal) {
      window.open(action.url);
    } else {
      navigate(action.url);
    }
  };

  const getActionButton = (action, type) => {
    return {
      type: "primary no-wrap full-width",
      value: (
        <GSButton
          onClick={(e) => {
            buttonLinkClicked(action);
            e?.stopPropagation?.();
          }}
          type={type}
          title={action.label}
        />
      ),
    };
  };

  const getPrimaryActions = () => {
    return getPrimaryTournamentActions(tournament)?.map?.((action, index) =>
      getActionButton(action, index === 0 ? "green" : "black primary")
    );
  };

  // const getSecondaryActions = () => {
  //   return []
  //   return getSecondaryTournamentActions(tournament)?.map?.((action) =>
  //     getActionButton(action, "black primary")
  //   );
  // };

  const getFirstColumnActions = () => {
    return {
      fit: "mobile-full-width",
      sections: [
        {
          sectionItems: [...getPrimaryActions?.()],
        },
      ],
    };
  };

  // const getSecondColumnActions = () => {
  //   return {
  //     fit: "mobile-full-width",
  //     sections: [
  //       {
  //         sectionItems: [...getSecondaryActions?.()],
  //       },
  //     ],
  //   };
  // };

  const getTournamentDataGroup = (tournament) => {
    let item = [
      { ...tournamentInfo },
      { ...getFirstColumnActions() },
//{ ...getSecondColumnActions() },
    ];
    return item;
  };
  const getTimeLine = () => {
    if (tournament === undefined) {
      return "";
    }
    if (tournament.state === "draft") {
      return "Draft Tournament";
    }
    const daysTil = getDaysTillNow(tournament?.startAt);
    const yearsTil = getYearsTillNow(tournament?.startAt);
    if (daysTil > 365) {
      return `Starts in ${yearsTil} year${yearsTil !== 1 ? "s" : ""}`;
    } else if (daysTil > 0) {
      return `Starts in ${daysTil} day${daysTil !== 1 ? "s" : ""}`;
    } else if (daysTil < -365) {
      return `Started ${Math.abs(yearsTil)} year${yearsTil !== 1 ? "s" : ""} ago`;
    } else if (daysTil < 0) {
      return `Started ${Math.abs(daysTil)} day${daysTil !== 1 ? "s" : ""} ago`;
    } else {
      return `Happening now`;
    }
  };
  return (
    <tournament-hero onClick={() => props.onClick?.(tournament)}>
      <GSActionBar header={getTimeLine()} />
      <GSInfoGroup
        type="list-item"
        itemGap="medium-gap medium-pad"
        listType="mobile-vertical"
        dataGroups={getTournamentDataGroup(tournament)}
      />
    </tournament-hero>
  );
};

export default TournamentHero;
