import {
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup, GSItemList } from "golfstatus_react_components";
import React from "react";
import { mobileStatus } from "../../../../forms/RoundScoresForm";

import "./tournament-round-scoring-player-Item.scss";

const TournamentRoundScoringPlayerItem = (props) => {
  const {
    players,
    liveTournamentScorecard,
    search,
    selectPlayer,
    entryCode,
  } = props;


  const getMobileActivityIcon = () => {
    if(liveTournamentScorecard === undefined){
      return faExclamationCircle
    }
    return mobileStatus?.find?.(
      (s) => s?.mobileStatus === liveTournamentScorecard?.mobileStatus
    )?.icon ;
  };

  const getMobileActivityType = () => {
    if(liveTournamentScorecard === undefined){
      return "transparent orange"
    }
    return mobileStatus?.find?.(
      (s) => s?.mobileStatus === liveTournamentScorecard?.mobileStatus
    )?.type ;
  };

  const mobileActivitySection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton type={getMobileActivityType()} buttonIcon={getMobileActivityIcon()}></GSButton>,
      },
    ],
  };

  const mobileActivityColumn = {
    sections: [
      {
        ...mobileActivitySection,
      },
    ],
  };

  //player info

  const getPlayerInfo = (player) => ({
    sectionItems: [
      {
        type: `primary full-width`,
        value: `${player.name} (${player.adjustedHandicap})`,
      },
    ],
  });

  const mobileInfo = {
    sectionItems: [
      {
        type: `secondary full-width`,
        value: entryCode,
      },
      {
        type: "secondary capitalize",
        value: liveTournamentScorecard ? liveTournamentScorecard?.mobileStatus.replace("_", " ") : "Scorecard has not been generated. Please review the hole assignments.",
      },
    ],
  };

  const getPlayerColumn = (player) => ({
    fit: "stretch",
    sections: [{ ...getPlayerInfo(player) }, mobileInfo],
  });

  //status info

  const statusSection = {
    sectionItems: [
      {
        type: "secondary uppercase no-wrap full-width",
        value: (
          <GSButton type="secondary" title={liveTournamentScorecard?.status} />
        ),
      },
    ],
  };

  const statusColumn = {
    sections: [
      {
        ...statusSection,
      },
    ],
  };

  const getGroup = (player) => {
    let group = [
      { ...mobileActivityColumn },
      { ...getPlayerColumn(player) },
      { ...statusColumn },
    ];
    return <GSInfoGroup dataGroups={group} />;
  };

  const getFilteredPlayers = () => {
    return players?.filter?.((p) =>
      p?.name?.toLowerCase()?.includes?.(search?.toLowerCase?.())
    );
  };

  return (
    <tournament-round-scoring-player-Item>
      <GSItemList
        type="vertical selectable"
        itemSelected={(player) => {
          selectPlayer?.(player);
        }}
        items={getFilteredPlayers()}
        listItem={(item) => <div className="player">{getGroup(item)}</div>}
      ></GSItemList>
    </tournament-round-scoring-player-Item>
  );
};

export default TournamentRoundScoringPlayerItem;
