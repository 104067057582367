import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  getThunkResponse,
  payouts,
  registrationOrders,
  thunkResponse,
  tournament,
} from "../app/api";
import {
  extraLongDelay,
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  mediumDelay,
} from "../helpers/JSONapi";

export const getOrders = createAsyncThunk(
  "reporting/getOrders",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/registration-orders`,
      {
        data: {
          included: [
            "registration-customer",
            "registration-order-items",
            "registration-order-items.tournament-package",
            "donation-order-items",
            "registration-order-refunds",
            "discount-codes"
          ],
        },
      }
    );
    return response;
  }
);
export const getOrder = createAsyncThunk(
  "reporting/getOrder",
  async (orderID, thunkAPI) => {
    const response = await gsApi.get(`${registrationOrders}/${orderID}`, {
      data: {
        included: ["tournament-package"],
      },
    });
    return response;
  }
);
export const getRefunds = createAsyncThunk(
  "reporting/getRefunds",
  async (orderID, thunkAPI) => {
    const response = await gsApi.get(
      `${registrationOrders}/${orderID}/registration-order-refunds`
    );
    return response;
  }
);

export const getPayouts = createAsyncThunk(
  "reporting/getPayouts",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${tournamentID}/payouts`);
    return response;
  }
);

export const getPayoutRequestor = createAsyncThunk(
  "reporting/getPayoutRequestor",
  async (payoutID, thunkAPI) => {
    const response = await gsApi.get(`${payouts}/${payoutID}/requestor`);
    return response;
  }
);

export const getEventOrderSummary = createAsyncThunk(
  "reporting/getEventOrderSummary",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/event-order-summary`
    );
    return response;
  }
);

//resend receipt
export const resendReceipt = createAsyncThunk(
  "reporting/resendReceipt",
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${registrationOrders}/${id}/resend-receipt`,
      {},
      thunkAPI
    );
  }
);

// resend invoice
export const resendInvoice = createAsyncThunk(
  "reporting/resendInvoice",
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${registrationOrders}/${id}/resend-invoice`,
      {},
      thunkAPI
    );
  }
);

//refund order
export const refundOrder = createAsyncThunk(
  "reporting/refundOrder",
  async (currentOrder, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${registrationOrders}/${currentOrder.id}/refund`,
      {
        amount: currentOrder?.purchaseCost - currentOrder?.nonRefundableFees,
      },
      thunkAPI
    );
  }
);

//void order
export const voidOrder = createAsyncThunk(
  "reporting/refundOrder",
  async (orderID, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${registrationOrders}/${orderID}/refund`,
      {},
      thunkAPI
    );
  }
);

// void invoice
export const voidInvoice = createAsyncThunk(
  "reporting/voidInvoice",
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${registrationOrders}/${id}/void-invoice`,
      {},
      thunkAPI
    );
  }
);

//request payout
export const requestTournamentPayout = createAsyncThunk(
  "reporting/requestTournamentPayout",
  async (payout, thunkAPI) => {
    const serialiedData = getSerializedData(
      payout,
      "payouts",
      getOpts(payout, [...getIDRelationship("tournament")])
    );
    return await getResponse(gsApi.post, `${payouts}`, serialiedData, thunkAPI);
  }
);

//replace all occurences of reporting with the name of your new slice
export const reportingSlice = createSlice({
  name: "reporting",
  initialState: {
    orders: [],
    orderFilter: [],
    currentOrder: {},
    currentOrderRefunds: [],
    payouts: [],
    currentPayout: {},
    orderSummary: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
    updatePayout: (state, action) => {
      state.currentPayout = action.payload;
    },
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    }
  },
  extraReducers: {
    ...getThunkResponse(getOrders, (state, action) => {
      state.orders = action.payload;
    }),
    ...getThunkResponse(getOrder, (state, action) => {
      state.currentOrder = action.payload;
    }),
    ...getThunkResponse(getRefunds, (state, action) => {
      state.currentOrderRefunds = action.payload;
    }),
    ...getThunkResponse(getPayouts, (state, action) => {
      state.payouts = action.payload;
    }),
    ...getThunkResponse(getEventOrderSummary, (state, action) => {
      state.orderSummary = action.payload;
    }),
    ...thunkResponse(
      resendReceipt,
      (state, action) => {},
      "Resending Receipt",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Sending Receipt",
        fulfilledText: "Receipt Sent",
      }
    ),
    ...thunkResponse(
      resendInvoice,
      (state, action) => {},
      "Resending Invoice",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Sending Invoice",
        fulfilledText: "Invoice Sent",
      }
    ),
    ...thunkResponse(refundOrder, (state, action) => {}, "Refund Order", {
      pendingDelay: mediumDelay,
      fulfilledDelay: mediumDelay,
      rejectedDelay: extraLongDelay,
      pendingText: "Refunding Order",
      fulfilledText: "Order Refunded",
    }),
    ...thunkResponse(voidInvoice, (state, action) => {}, "Void Invoice", {
      pendingDelay: mediumDelay,
      fulfilledDelay: mediumDelay,
      rejectedDelay: extraLongDelay,
      pendingText: "Voiding Invoice",
      fulfilledText: "Invoice Voided",
    }),
    ...thunkResponse(
      requestTournamentPayout,
      (state, action) => {},
      "Request Tournament Payout",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Requesting Payout",
        fulfilledText: "Payout complete!",
      }
    ),
  },
});

export const {
  setOrders,
  updatePayout,
  addNotification,
  clearNotifications,
  setOrderFilter
} = reportingSlice.actions;

//export any selectors needed
export const selectOrders = (state) => state?.[reportingSlice.name]?.orders;
export const selectCurrentOrder = (state) =>
  state?.[reportingSlice.name]?.currentOrder;
export const selectCurrentOrderRefunds = (state) =>
  state?.[reportingSlice.name]?.currentOrderRefunds;
export const selectPayouts = (state) => state?.[reportingSlice.name]?.payouts;
export const selectCurrentPayout = (state) =>
  state?.[reportingSlice.name]?.currentPayout;
export const selectOrderSummary = (state) =>
  state?.[reportingSlice.name]?.orderSummary;

export const selectNotifications = (state) =>
  state?.[reportingSlice.name]?.notifications;

export const selectLoading = (state) => state?.[reportingSlice.name]?.loading;

export const selectOrderFilter = (state) => state?.[reportingSlice.name]?.orderFilter

export default reportingSlice.reducer;

//ADD reducer to store.js
