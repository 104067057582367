import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSCircleImage, GSInfoGroup } from "golfstatus_react_components";
import React from "react";

const TournamentTeamPlayerItem = (props) => {

  const { firstName, lastName, email, avatar, removePlayer} = props 

  const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

  const avatarInfo ={
    fit:"hide-mobile",
    sections: [
      {
        sectionItems: [
          {
            type: "secondary no-wrap full-width",
            value: <GSCircleImage size="large" url={avatar ?? defaultAvatar}/> ,
          },
        ],
      },
    ],
  }

  const groupInfo ={
    fit: "stretch",
    sections: [
      {
        sectionItems: [
          {
            type: "primary no-wrap full-width",
            value: `${firstName} ${lastName}` ,
          },
          {
            type: "secondary no-wrap full-width",
            value: `${email ?? ""}` ,
          },
        ],
      },
    ],
  }

  const actionsInfo ={
    sections: [
      {
        sectionItems: [
          {
            type: "secondary no-wrap full-width",
            value: <GSButton buttonIcon={faTimesCircle} onClick={(e) => {removePlayer?.({...props}); e?.stopPropagation?.()}}/> ,
          },
        ],
      },
    ],
  }

  const getGroup = () => {
    let group = [{...avatarInfo},{...groupInfo}]
    if(removePlayer){
      group.push({...actionsInfo})
    }
    return group
  }

  return <tournament-team-player-item>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </tournament-team-player-item>
}


export default TournamentTeamPlayerItem