import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentRound } from "../../../../reducers/roundSlice";
import TournamentRoundHoleAssignmentGridItem from "./tournament-round-hole-assignment-grid-item";

import "./tournament-round-hole-assignment-item.scss"

const TournamentRoundHoleAssignmentItem = (props) => {

  const {tournamentTeams, locked, toggleLock} = props

  const currentRound = useSelector(selectCurrentRound)

  let holeAssignmentSection = {
    fit: "teams",
    sectionItems: [
      {
        type: "primary full-width",
        value: <TournamentRoundHoleAssignmentGridItem hideUnassigned assignment={props} tournamentTeams={tournamentTeams} currentRound={currentRound}/>,
      },
     
    ]
  }

  const lockSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton onClick={() => {toggleLock?.(props)}} title={locked ? "Locked" : "Unlocked"} type={locked ? "black" : "light-grey"} buttonIcon={locked? faLock : faUnlock}/>,
      },
    ]
  }

  const holeAssignemntColumn ={
    fit: "stretch teams",
    sections: [
      {
        ...holeAssignmentSection
      },
    ],
  }


  const lockColumn = {
    sections: [
      {
        ...lockSection
      }
    ]
  }
  const getGroup = () => {
    let group = [{...holeAssignemntColumn},{...lockColumn}]
    return group
  }

  return <tournament-round-hole-assignment-item>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </tournament-round-hole-assignment-item>
}


export default TournamentRoundHoleAssignmentItem