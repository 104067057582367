import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faBoxOpen, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-create-edit.scss";
import { getPackageForm } from "../../../../forms/TournamentPackageForm";
import { GSItemList, GSEmptyList } from "golfstatus_react_components";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  clearNotifications,
  createTournamentPackage,
  getAddOnPackageItems,
  getPackageItems,
  getPackageRounds,
  getPlayerPackageItems,
  getSkinsAddOnPackageItems,
  getSponsorPackageItems,
  getTeamPackageItems,
  getTechnologySponsorPackageItems,
  getTournamentPackage,
  saveTournamentPackage,
  selectAvailablePackageItems,
  selectCurrentPackageRounds,
  selectCurrentTournamentPackage,
  selectCurrentTournamentPackageItems,
  selectMinTechSponsorPackagePrice,
  selectNotifications,
  setCurrentPackage,
  setCurrentPackageItem,
} from "../../../../reducers/packageSlice";
import { useSelector } from "react-redux";
import TournamentPackageItemsItem from "./tournament-package-items-item";
import { getNotificationItemInfo } from "../../../../helpers/Converters";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { useFormValidation } from "../../../../hooks/formHooks";

//Name the component
const TournamentPackageCreateEdit = (props) => {
  const [search, setSearch] = useState("");

  //hooks
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/promotion/packages/:packageID/*");
  const dispatch = useDispatch();

  //selectors
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageItems = useSelector(selectCurrentTournamentPackageItems);
  const allPackageItems = useSelector(selectAvailablePackageItems);
  const packageNotifications = useSelector(selectNotifications);
  const packageRounds = useSelector(selectCurrentPackageRounds);
  const packageMinTechSponsorPrice = useSelector(selectMinTechSponsorPackagePrice);

  //context
  const [context, isValid, setIsValid] = useFormValidation(false);

  context.createPackageItem = () => {
    dispatch(setCurrentPackageItem({}));
    navigate("item/add");
  };

  context.editPackageItem = (item) => {
    navigate(`item/${item.id}`);
  };

  context.updateSearch = (value) => {
    setSearch(value);
  };

  context.getSearch = (value) => {
    return search;
  };

  context.validationFailed = () => {
    if (isValid) {
      setIsValid(false);
    }
  };

  context.minTechSponsorPrice = packageMinTechSponsorPrice;

  //custom hooks
  useNotificationNavigation(
    "created",
    packageNotifications,
    `/${match?.params?.tournamentID}/promotion/packages/[id]/package-items?step=2`
  );

  //effects
  useEffect(() => {
    if (match?.params?.packageID && match?.params?.packageID !== "add") {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getPackageItems(match.params.packageID));
      dispatch(getPackageRounds(match.params.packageID));
    } else {
      dispatch(setCurrentPackage({ name: "", cost: "", availableLimit: "" }));
    }
  }, [dispatch, match?.params?.packageID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTeamPackageItems(match.params.tournamentID));
      dispatch(getPlayerPackageItems(match.params.tournamentID));
      dispatch(getSponsorPackageItems(match.params.tournamentID));
      dispatch(getTechnologySponsorPackageItems(match.params.tournamentID));
      dispatch(getSkinsAddOnPackageItems(match.params.tournamentID));
      dispatch(getAddOnPackageItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  //component functionality
  const getSelectedPackageItems = () => {
    return currentPackageItems.map((pi) => ({ id: pi.packageItemKey }));
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Package Items*"
        detail="Packages require package items and determine what kind of package you are selling. You can bundle items or just add a single item to the package "
        actions={[
          {
            title: "Create Item",
            onClick: context.createPackageItem,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const packageItemChecked = () => {};

  const packageItemUnchecked = () => {};

  const getFilteredPackageItems = () => {
    const packageitems = allPackageItems.filter?.((pi) =>
      pi.name.includes?.(search)
    );
    return packageitems.map((pi) => ({
      ...pi,
      selectedItem: currentPackageItems.find(
        (cpi) => cpi.packageItemKey === pi.id
      ),
    }));
  };

  const isTechPackage = () => {
    let mappedPackageItems = currentPackageItems.map((pi) => {
      return allPackageItems.find((item) => pi.packageItemKey === item.id)
    });

    const isTechPackage = mappedPackageItems.map((npi) => {
      return npi?.itemType
    }).includes('technology-sponsor');

    return isTechPackage;
  }

  context.isValidPackagePrice = () => {
    return !isTechPackage() || currentPackage.cost >= packageMinTechSponsorPrice
  }

  context.isValidPackageInventory = () => {
    // no need to do extra processing, if it's < 0, fail
    if (currentPackage.availableLimit < 0) return false;

    return !isTechPackage() || currentPackage.availableLimit <= 1;
  }

  context.getPackageItemList = () => {
    return (
      <GSItemList
        type="vertical selectable full-width items-list large-gap"
        emptyMessage={getEmptyListMessage()}
        selectedItems={getSelectedPackageItems()}
        itemChecked={packageItemChecked}
        itemUnchecked={packageItemUnchecked}
        items={getFilteredPackageItems()}
        isCheckList={true}
        listItem={(item) => (
          <TournamentPackageItemsItem
            {...item}
            editItem={context.editPackageItem}
          />
        )}
      ></GSItemList>
    );
  };

  context.updatePackage = (value, property) => {
    setIsValid(true);
    let update = { ...currentPackage };
    update[property] = value;
    dispatch(setCurrentPackage(update));
  };

  const save = () => {
    setUnsaved(false)
    setIsValid(false);
    let update = { ...currentPackage };
    update.tournamentKey = match?.params?.tournamentID;
    if (match?.params?.packageID === "add") {
      dispatch(createTournamentPackage(update));
    } else {
      update.tournamentRounds = packageRounds;
      dispatch(saveTournamentPackage(update));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: match?.params?.packageID === "add" ? "Save & Continue" : "Save",
        action: save,
        isDisabled: !isValid,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if(isValid && bannerNotifications?.type !== "unsaved")
    {
      setUnsaved(true)
      return
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Package Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitle = () => {
    return {
      formTitle:match?.params?.packageID !== "add" ? "Edit Package" : "Add Package",
      formActions: [
        {
          actionType: "toggle",
          pageActionProps: {
            value: currentPackage.state !== "inactive",
            trueDescription: "Active",
            falseDescription: "Inactive",
            rowReverse: true,
            onClick: () =>
              context.updatePackage(
                currentPackage.state === "inactive" ? "active" : "inactive",
                "state"
              ),
          },
        },
      ],
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormTitle()}
        formSections={getPackageForm(context, currentPackage)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //banner custom hooks
  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
    defaultBanner:
      match?.params?.packageID === "add"
        ? {
            title: getNotificationItemInfo(
              `Start by adding basic package details.`,
              faBoxOpen,
              "Step 1 of 2"
            ),
            state: "grey",
          }
        : false,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-create-edit>
  );
};

//export the new namet
export default TournamentPackageCreateEdit;
