import React from "react";
import {
  GSForm,
  GSSidePanelPage,
  GSEmptyList,
  GSItemList,
} from "golfstatus_react_components";
import { faChevronLeft, faCog } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-tees.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationAction,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";
import { getFlightTeeForm } from "../../../forms/FlightingForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoundFlight,
  clearNotifications,
  getRoundFlight,
  getTournamentFlight,
  preflightTournamentAndFlights,
  selectCurrentTournamentFlight,
  selectFlightLoading,
  selectFlightNotifications,
  selectRoundFlights,
  selectTournamentFlights,
  setNotifications,
  updateCurrentFlight,
} from "../../../reducers/flightSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { getTournamentLeaderboardSettings } from "../../../reducers/leaderboardSlice";
import {
  getRound,
  getTournamentRounds,
  selectTournamentRounds,
  updateRound,
} from "../../../reducers/roundSlice";
import { useEffect } from "react";

//Name the component
const TournamentFlightTees = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/flighting/:flightID/tees");

  const currentTournamentFlight = useSelector(selectCurrentTournamentFlight);
  const tournamentFlights = useSelector(selectTournamentFlights);
  const currentTournament = useSelector(selectCurrentTournament);
  const flightNotifications = useSelector(selectFlightNotifications);
  const tournamentRounds = useSelector(selectTournamentRounds);
  const roundFlights = useSelector(selectRoundFlights);
  const [context, isValid, setIsValid] = useFormValidation(false);
  const loading = useSelector(selectFlightLoading)


  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentFlight(match.params.flightID));
      dispatch(getTournamentLeaderboardSettings(match?.params?.tournamentID));
      dispatch(getTournamentRounds(match.params.tournamentID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.tournamentID, match?.params?.flightID]);

  useEffect(() => {
    if (tournamentRounds.length > 0) {
      tournamentRounds.forEach((tr) => {
        if (tr.roundTees === undefined) {
          dispatch(getRound(tr.id)).then((resp) => {
            dispatch(updateRound(resp.payload));
          });
          dispatch(getRoundFlight(tr.id));
        }
      });
    }
  }, [tournamentRounds, dispatch]);

  useNotificationAction("saved", flightNotifications, (notification) => {
    dispatch(setNotifications([{...notification, result: "reload"}]))
    dispatch(getTournamentRounds(match.params.tournamentID));
  }, "preflight")

  context.updateFlight = (value, property) => {
    setIsValid(true);
    let update = { ...currentTournamentFlight };
    update[property] = value;
    dispatch(updateCurrentFlight(update));
  };

  context.isPreflighted = () => {
    return currentTournament.preflight;
  };

  context.getRounds = () => {
    return tournamentRounds;
  };

  context.getFlightRound = (roundID) => {
    const rf = roundFlights.find(
      (rf) =>
        rf.tournamentRound?.id === roundID &&
        rf.tournamentFlight.id === match?.params?.flightID
    );
    return rf;
  };

  context.getFlightRoundTee = (round, course) => {
    const flightRound = context.getFlightRound(round.id);
    const tee = flightRound?.flightRoundTees?.find?.(
      (frt) => frt?.roundCourse?.id === course?.id
    )?.roundTee;
    return tee ? { label: tee?.name, value: { ...tee, flightRound } } : null;
  };

  context.getFlightRoundCourses = (round) => {
    return round?.roundCourses;
  };

  context.roundTeeSelected = (round, tee, roundCourse) => {
    setIsValid(true);
    let flightRound = context.getFlightRound(round.id);
    let id = flightRound?.flightRoundTees?.find?.(frt => frt.roundCourse.id === roundCourse.id)?.id
    let currentTees =
      flightRound?.flightRoundTees?.filter?.(
        (frt) => frt.roundCourse.id !== roundCourse.id
      ) ?? [];
    const roundFlight = {
      ...flightRound,
      flightRoundTees: [...currentTees, { roundCourse, roundTee: tee, id }],
      tournamentRound: round,
      tournamentFlight: currentTournamentFlight,
    };
    dispatch(addRoundFlight(roundFlight));
  };

  context.submitForm = () => {
    if (isValid) {
      save();
    }
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    saveFlightRoundTees();
  };

  const saveFlightRoundTees = () => {
    dispatch(
      preflightTournamentAndFlights({
        ...currentTournament,
        "tournament-flights": tournamentFlights.map?.((tf) => ({
          ...tf,
          "flight-rounds": roundFlights?.filter?.(
            (fr) => fr.tournamentFlight.id === tf.id
          ),
        })),
      })
    );
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Default Tees`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getEmptyPreflightMessage = () => {
    return (
      <GSEmptyList
        title="Update Flight Settings"
        detail="To assign default tees, the tournament must be pre-flighted."
        actions={[
          {
            title: "Flight Settings",
            type: "light-grey",
            buttonIcon: faCog,
          },
        ]}
      />
    );
  };

  const getContent = () => {

    if (!currentTournament.preflight) {
      return (
        <gs-form>
          <GSItemList
            type="vertical"
            emptyMessage={getEmptyPreflightMessage()}
          />
        </gs-form>
      );
    }
    return (
      <GSForm
        formTitle="Default Tees"
        formSections={getFlightTeeForm(context, currentTournamentFlight)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: flightNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-flight-tees>
      <GSSidePanelPage
      loading={loading?.includes?.("getTournamentFlight")}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flight-tees>
  );
};

//export the new namet
export default TournamentFlightTees;
