import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-registration.scss";
import { getRegistrationForm } from "../../../forms/PromotionForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  saveTournament,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCurrentTournament,
} from "../../../reducers/tournamentSlice";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionRegistration = (props) => {
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //selectors
  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentNotifications = useSelector(selectTournamentNotifications);

  //form context
  const [context, isValid, setIsValid] = useFormValidation(false);

  useEffect(() => {
    dispatch(clearTournamentNotifications());
    return dispatch(clearTournamentNotifications());
  }, [dispatch]);

  context.updateTournament = (value, property) => {
    setIsValid(true);
    let update = { ...currentTournament };
    update[property] = value;
    dispatch(setCurrentTournament(update));
  };

  context.getDetailCount = () => {
    return currentTournament?.promotionDetails?.length;
  };

  //component functionality
  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentTournament };

    if (!update.registrationClose) {
      const defaultRegistrationClose = new Date(update?.startAt);
      defaultRegistrationClose.setHours(23, 59, 0, 0);
      update.registrationClose = defaultRegistrationClose;
    }

    update.registrationClose = new Date(update?.registrationClose)?.toISOString?.();

    dispatch(saveTournament(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Event Site & Registration Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Event Site & Registration Details"
        formSections={getRegistrationForm(context, currentTournament)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //banner custom hooks
  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };
  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-promotion-registration>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-promotion-registration>
  );
};

//export the new namet
export default TournamentPromotionRegistration;
