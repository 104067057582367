import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const removeItemForm = (name, view, action, disclaimer) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          value: (
            <div className="remove-container full-width">
              <div className="disclaimer">{ disclaimer ? disclaimer : `Are you sure you want to ${action ? action : "delete"} ${name}? This cannot be undone.`}</div>
              <div className="item">{view}</div>
            </div>
          ),
          customView:true
        },
      ],
    },
  ];
  return form;
};

export const getRemoveTitle = (title) => {
  return (
    <div className="delete-header">
      <div className="icon">
        <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
      </div>
      {title}
    </div>
  );
};

export const warnItemForm = (warningText, view) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          value: (
            <div className="warn-container full-width">
              <div className="disclaimer">{`${warningText}`}</div>
              <div className="item">{view}</div>
            </div>
          ),
          customView:true
        },
      ],
    },
  ];
  return form;
};

export const getWarningTitle = (title, icon) => {
  return (
    <div className="warn-header">
      <div className="icon">
        <FontAwesomeIcon icon={icon ? icon : faExclamationCircle}></FontAwesomeIcon>
      </div>
      {title}
    </div>
  );
};
