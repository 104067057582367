import React, { useState } from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-discounts.scss";
import { useSelector } from "react-redux";
import {
  getOrganizationDiscountCodes,
  getTournamentDiscountCodes,
  selectOrganizationDiscountCodes,
  selectTournamentDiscountCodes,
  setCurrentDiscountCode,
  selectNotifications,
  clearNotifications,
} from "../../../../reducers/discountSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import TournamentDiscountItem from "./tournament-discount-item";
import { selectCurrentTournament, selectLoading } from "../../../../reducers/tournamentSlice";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import SearchBar from "../../../search-bar";
import { getTournamentPaymentSettings, selectCurrentPaymentSettings } from "../../../../reducers/settingsSlice";

//Name the component
const TournamentDiscounts = (props) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/promotion/discounts");

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentDiscounts = useSelector(selectTournamentDiscountCodes);
  const organizationDiscounts = useSelector(selectOrganizationDiscountCodes);
  const discountNotifications = useSelector(selectNotifications);
  const paymentSettings = useSelector(selectCurrentPaymentSettings);
  const tournamentLoading = useSelector(selectLoading)

  const orgRestricted = currentTournament?.organization?.allowDiscountCodes === false
  const restricted = paymentSettings?.allowDiscountCodes === false 

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentDiscountCodes(match?.params?.tournamentID));
      dispatch(getTournamentPaymentSettings(match?.params?.tournamentID))
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (currentTournament?.organization?.id) {
      dispatch(
        getOrganizationDiscountCodes(currentTournament.organization?.id)
      );
    }
  }, [currentTournament?.organization?.id, dispatch]);

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Discounts`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const addDiscount = () => {
    timeoutAction();
    dispatch(setCurrentDiscountCode({}));
    navigate("add");
  };

  const getPageActions = () => {
    if(restricted){
      return []
    }
    return [
      {
        title: "Add discount",
        actionIcon: faPlus,
        actionClick: addDiscount,
        type: "mobile-icon black no-wrap",
      },
    ];
  };

  const goToOrg = () => {
    const url = `http://organizations${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/${currentTournament?.organization?.id}/discounts`;
    window.open(url);
  };

  // const getPageOrgActions = () => {
  //   return [
  //     {
  //       title: "Manage",
  //       actionClick: goToOrg,
  //       actionIcon: faSitemap,
  //       type: "black mobile-icon no-wrap",
  //     },
  //   ];
  // };

  const discountSelected = (discount) => {
    timeoutAction();
    navigate(discount.id);
  };

  const filterList = (list) => {
    if((restricted && orgRestricted) || tournamentLoading?.length > 0){
      return []
    }
    return list?.filter?.((discount) =>
      discount.description?.toLowerCase?.()?.includes?.(search)
    );
  };

  const getList = (list, isOrg) => {
    return (
      <GSItemList
        itemSelected={discountSelected}
        type="vertical selectable"
        items={list}
        emptyMessage={
          search === ""
            ? isOrg
              ? getEmptyOrgListMessage()
              : getEmptyListMessage()
            : ""
        }
        listItem={(item) => <TournamentDiscountItem {...item} isOrg={isOrg} />}
      ></GSItemList>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Add & Manage Discounts"
        detail="Discounts can be applied at checkout on the event site."
        actions={[
          {
            title: "Add Discount",
            buttonIcon: faPlus,
            onClick: addDiscount,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the discount you’re looking for…"
        detail="Try changing the search term or add a new discount."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
          {
            title: "Add Discount",
            buttonIcon: faPlus,
            onClick: addDiscount,
            type: "black",
          },
        ]}
      />
    );
  };

  const getRestricstedMessage = () => {
    return (
      <GSEmptyList
        title="Paid Feature"
        detail="Contact your sales or client success representative to unlock the Discount Codes feature."
        actions={[
          {
            title: "Send a Message",
            onClick: emailGolfstatus,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptyOrgListMessage = () => {
    return (
      <GSEmptyList
        title="Organization Discounts"
        detail="Organization Discounts are available for all tournaments in an organization."
        actions={[
          {
            title: "Manage Organization Discounts",
            onClick: goToOrg,
            type: "black",
          },
        ]}
      />
    );
  };

  const emailGolfstatus = () => {
    const recipient = "success@golfstatus.com"
    const subject = `Unlock Discount Codes for ${currentTournament?.name}`
    const body = `Hi,\n\nI’d like to learn more about unlocking the Discount Codes feature.\n\nThank you!\n\nTournament Name: ${currentTournament.name}\n\nOrganization: ${currentTournament?.organization?.name}`
    window.open(
      `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    );
  };

  const getSearch = () => {
    if(restricted){
      return null
    }
    let searchProps = {
      search,
      setSearch,
      placeholder: "Search Discounts...",
    };
    return <SearchBar {...searchProps}/>
  };

  const getLists = () => {
    let results = [];
    const td = filterList(tournamentDiscounts);
    const od = filterList(organizationDiscounts);

    if (td?.length > 0 && !restricted) {
      results.push({
        actionBar: <GSActionBar type="grey-header x-large-pad" header="Tournament Discounts"></GSActionBar>,
        list: getList(td),
      });
    }

    if (od?.length > 0 && !orgRestricted) {
      results.push({
        actionBar: <GSActionBar type="grey-header x-large-pad" header="Organization Discounts"></GSActionBar>,
        list: getList(od, true),
      });
    }

    return results;
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar
        type="form-header"
          header="Discounts"
          pageActions={getPageActions()}
        ></GSActionBar>
        {getSearch()}
        <GSItemList
          type="vertical"
          items={getLists()}
          emptyMessage={
            restricted
              ? getRestricstedMessage()
              : search === ""
              ? getEmptyListMessage()
              : getEmptySearchMessage()
          }
          listItem={(item) => (
            <div className="discounts">
              {item.actionBar}
              {item.list}
            </div>
          )}
          loading={tournamentLoading?.length > 0}
          loadingMainText="Loading Discounts..."
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  let notificationSettings = {
    notifications: discountNotifications,
    bannerActions: getSaveBannerActions(() => {}, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-discounts>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-discounts>
  );
};

//export the new namet
export default TournamentDiscounts;
