import { Route } from "react-router-dom"
import TournamentReporting from "../../components/tournament/tournament-reporting/tournament-reporting"
import TournamentReportingDocuments from "../../components/tournament/tournament-reporting/tournament-reporting-documents"
import TournamentReportingOrder from "../../components/tournament/tournament-reporting/tournament-reporting-order"
import TournamentReportingOrderRefund from "../../components/tournament/tournament-reporting/tournament-reporting-order-refund"
import TournamentReportingOrderVoidInvoice from "../../components/tournament/tournament-reporting/tournament-reporting-order-void-invoice"
import TournamentReportingPayoutRequest from "../../components/tournament/tournament-reporting/tournament-reporting-payout-request"
import TournamentReportingPayouts from "../../components/tournament/tournament-reporting/tournament-reporting-payouts"
import TournamentReportingOrderVoidOrder from "../../components/tournament/tournament-reporting/tournament-reporting-order-void-order"
import TournamentReportingFilter from "../../components/tournament/tournament-reporting/tournament-reporting-filter"
//import SidePanelPage from "../../templates/side-panel-page"
export const getReportingRoutes = () => {
  return (
    <Route path="reporting" element={<TournamentReporting/>}>
      <Route path="filter" element={<TournamentReportingFilter/>}></Route>
      <Route path="payouts" element={<TournamentReportingPayouts/>}></Route>
      <Route path="payouts/request" element={<TournamentReportingPayoutRequest/>}></Route>
      <Route path="documents" element={<TournamentReportingDocuments/>}></Route>
      <Route path=":orderID" element={<TournamentReportingOrder/>}></Route>
      <Route path=":orderID/refund" element={<TournamentReportingOrderRefund/>}></Route>
      <Route path=":orderID/void-order" element={<TournamentReportingOrderVoidOrder/>}></Route>
      <Route path=":orderID/void-invoice" element={<TournamentReportingOrderVoidInvoice/>}></Route>
    </Route>
  )
}