import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-team-remove-player.scss";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import TournamentTeamPlayerItem from "./tournament-team-player-item";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentTeam,
  getTournamentTeamPlayer,
  removeTournamentPlayer,
  selectCurrentTeam,
  selectCurrentTeamPlayer,
  selectTeamsNotifications,
  setCurrentTempPlayer,
  setCurrentTeam,
} from "../../../reducers/teamsSlice";
import { useDispatch } from "react-redux";
import { useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";

//Name the component
const TournamentTeamRemovePlayer = (props) => {
  const [isValid, setIsValid] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const currentTeam = useSelector(selectCurrentTeam);
  const currentPlayer = useSelector(selectCurrentTeamPlayer);
  const notifications = useSelector(selectTeamsNotifications);

  const matchEdit = useMatch(
    "/:tournamentID/teams/:teamID/edit/players/edit/:playerID/remove"
  );

  useEffect(() => {
    if (matchEdit?.params?.teamID && currentTeam?.id !== matchEdit?.params?.teamID) {
      dispatch(getTournamentTeam(matchEdit.params.teamID));
    }
  }, [dispatch, matchEdit?.params?.teamID, currentTeam?.id]);

  useEffect(() => {
    if (matchEdit?.params?.playerID && `${matchEdit.params.playerID}`.includes("-")) {
      dispatch(getTournamentTeamPlayer(matchEdit.params.playerID));
    }
    else{
      dispatch(setCurrentTempPlayer(matchEdit.params.playerID))
    }
    return () => {clearNotifications()}
  }, [dispatch, matchEdit?.params?.playerID]);

  useNotificationNavigation("removed", notifications, -2)

  const removePlayer = async () => {
    setIsValid(false)
    if(currentPlayer?.id )
    {
      dispatch(removeTournamentPlayer(matchEdit?.params?.playerID))
    }
    else
    {
      if(matchEdit.params.playerID < currentTeam.players?.length)
      {
        let update = {...currentTeam, players: currentTeam.players?.filter?.(p => p.tempID !== matchEdit.params.playerID)}
        dispatch(setCurrentTeam(update))
        navigate(-2, {replace: true})
      }
    }
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removePlayer,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Player`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle={getRemoveTitle("Delete Player")}
        formSections={removeItemForm(`${currentPlayer?.name} from this team` , <TournamentTeamPlayerItem {...currentPlayer} />)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner]  = useNotificationBanner({notifications})

  return (
    //name the component tag
    <tournament-team-remove-player>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-remove-player>
  );
};

//export the new namet
export default TournamentTeamRemovePlayer;
