
import React, { useEffect } from "react";
import { GSItemList, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-details.scss"
import NavigationListItem from "../../navigation-list-item";
import TournamentLeaderboardLineItem from "./tournament-leaderboard-line-item";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications, getLeaderboardSetting, selectCurrentLeaderboardSetting, selectLeaderboardNotifications, selectTournamentLeaderboardSettings } from "../../../reducers/leaderboardSlice";
import { getTournamentFlights, selectFlightLoading, selectTournamentFlights } from "../../../reducers/flightSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentLeaderboardDetails = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch("/:tournamentID/leaderboards/:leaderboardSettingID")

  useEffect(() => {
    if (match?.params?.tournamentID)
    {
      dispatch(getTournamentFlights(match?.params?.tournamentID))
    }
    if (match?.params?.leaderboardSettingID)
    {
      dispatch(getLeaderboardSetting(match?.params?.leaderboardSettingID))
    }
    return () => {dispatch(clearNotifications())}
  }, [dispatch, match?.params?.tournamentID, match?.params?.leaderboardSettingID])

  const currentLeaderboardSetting = useSelector(selectCurrentLeaderboardSetting)
  const tournamentFlights = useSelector(selectTournamentFlights)
  const currentTournament = useSelector(selectCurrentTournament)
  const leaderboardNotifications = useSelector(selectLeaderboardNotifications)
  const leaderboardSettings = useSelector(selectTournamentLeaderboardSettings);
  const loading = useSelector(selectFlightLoading)

  const remove = () => {
    navigate("remove")
  }

  const getDrawerActions = () => {
    if(leaderboardSettings?.length === 1){
      return []
    }
    let actions = [
      {
        name: "Delete Leaderboard",
        action: remove,
        type: "transparent red no-wrap",
        isDisabled: currentLeaderboardSetting?.main,
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Leaderboard Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getDetailPages = () => {
    let pages = [
      {
        hero: <TournamentLeaderboardLineItem {...currentLeaderboardSetting}></TournamentLeaderboardLineItem>,
        isExternal: false,
        isEdit: true,
        url: "edit",
        hidden: false,
        type: "hero"
      }
    ];
    if(currentLeaderboardSetting.skins){
      pages.push({
        label: "Manage Skins",
        details: "Manage players and teams on this skins leaderboard.",
        isExternal: false,
        url: "skins-management",
        hidden: false,
      })
    }
    if(!currentTournament.preflight || !currentLeaderboardSetting.flighted || currentLeaderboardSetting.main)
    {
      pages.push({
        label: "Leaderboard Results",
        isExternal: false,
        url: "results",
        hidden: false,
      })
    }
    

    let settingsFlights = tournamentFlights?.filter(tf => tf.leaderboardTypeSetting.id === currentLeaderboardSetting.id)
    if(settingsFlights?.length > 0)
    {
      pages = [...pages, ...settingsFlights.map?.(sf => ({
        label: `${sf.name} Flight Results`,
        isExternal: false,
        url: `${sf.id}/results`,
        hidden: false,
      }))]
    }
    return pages;
  };

  const navigateToPage = (page) => {
    navigate(page.url)
  }


  const getContent = () => {
    return <gs-form>
      <GSItemList type="vertical selectable" items={getDetailPages()} itemSelected={navigateToPage} listItem={(item) => (<NavigationListItem page={item}></NavigationListItem>)}></GSItemList>
    </gs-form>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({notifications: leaderboardNotifications, timeoutAction})

  return (
    //name the component tag
    <tournament-leaderboard-details>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-details>
  );
};

//export the new namet
export default TournamentLeaderboardDetails;