import { Route } from "react-router-dom"

import TournamentLeaderboards from "../../components/tournament/tournament-leaderboards/tournament-leaderboards";
import TournamentLeaderboardCreateEdit from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-create-edit";
import TournamentLeaderboardSettings from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-settings";
import TournamentLeaderboardDetails from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-details";
import TournamentLeaderboardRemove from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-remove";
import TournamentLeaderboardResult from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-result";
import TournamentLeaderboardSkinsManagement from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-skins-management";
import TournamentLeaderboardSkinsManagementMarkAll from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-skins-management-mark-all";
import TournamentLeaderboardResultDetails from "../../components/tournament/tournament-leaderboards/tournament-leaderboard-result-details";
import TournamentLeaderboardsExports from "../../components/tournament/tournament-leaderboards/tournament-leaderboards-exports";

export const getTournamentLeaderboardRoutes = () => {
  return (
    <Route path={"leaderboards"} element={<TournamentLeaderboards />}>
            <Route
              path={"add"}
              element={<TournamentLeaderboardCreateEdit />}
            ></Route>
            <Route
              path={"documents"}
              element={<TournamentLeaderboardsExports />}
            ></Route>
            <Route
              path={"settings"}
              element={<TournamentLeaderboardSettings />}
            ></Route>
            <Route
              path={":leaderboardSettingID"}
              element={<TournamentLeaderboardDetails />}
            ></Route>
            <Route
              path={":leaderboardSettingID/skins-management"}
              element={<TournamentLeaderboardSkinsManagement />}
            ></Route>
            <Route
              path={
                ":leaderboardSettingID/skins-management/:addOnID/mark-all-in"
              }
              element={
                <TournamentLeaderboardSkinsManagementMarkAll status="in" />
              }
            ></Route>
            <Route
              path={
                ":leaderboardSettingID/skins-management/:addOnID/mark-all-out"
              }
              element={
                <TournamentLeaderboardSkinsManagementMarkAll status="out" />
              }
            ></Route>
            <Route
              path={":leaderboardSettingID/results"}
              element={<TournamentLeaderboardResult />}
            ></Route>
            <Route
              path={":leaderboardSettingID/results/:teamKey"}
              element={<TournamentLeaderboardResultDetails />}
            ></Route>
            <Route
              path={":leaderboardSettingID/:flightID/results"}
              element={<TournamentLeaderboardResult />}
            ></Route>
            <Route
              path={":leaderboardSettingID/:flightID/results/:teamKey"}
              element={<TournamentLeaderboardResultDetails />}
            ></Route>
            <Route
              path={":leaderboardSettingID/edit"}
              element={<TournamentLeaderboardCreateEdit />}
            ></Route>
            <Route
              path={":leaderboardSettingID/remove"}
              element={<TournamentLeaderboardRemove />}
            ></Route>
          </Route>
  )
}