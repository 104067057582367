import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import gsApi from '../auth/auth';
import { courses, facilities, getThunkResponse } from '../app/api';

export const getFacilityList = createAsyncThunk(
  'facilities/getFacilityList',
  async (filter) => {
    const response = await gsApi.get(facilities, {
      data: filter,
    });
    return response;
  }
);

export const getMoreFacilities = createAsyncThunk(
  'facilities/getMoreFacilities',
  async (filter) => {
    const response = await gsApi.get(facilities, {
      data: filter,
    });
    return response;
  }
);

export const selectFacility = createAsyncThunk(
  'facilities/selectFacility',
  async (facilityID) => {
    let url = `${facilities}/${facilityID}`;
    const response = await gsApi.get(url, {
      data: {
        include: 'courses,organization-members',
      },
    });
    return response;
  }
);

export const getFacilityCourseTees = createAsyncThunk(
  'facilities/getFacilityCourseTees',
  async (courseID) => {
    let url = `${courses}/${courseID}/tees`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const facilitySlice = createSlice({
  name: 'facilities',
  initialState: {
    facilityList: [],
    selectedFacility: {},
    slideOutNotifications: [],
    notifications: [], 
    loading: []
  },
  reducers: {
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload;
    },
    setFacilityList: (state, action) => {
      state.facilityList = action.payload;
    },
    notifySlideOut: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...getThunkResponse(getFacilityList, (state, action) => {
      state.facilityList = action.payload;
    }),
    ...getThunkResponse(getMoreFacilities,  (state, action) => {
      state.facilityList.push(...action.payload);
    }, ),
    ...getThunkResponse(selectFacility,(state, action) => {
      state.selectedFacility = action.payload;
    } ),
  },
});

export const {
  setSelectedFacility,
  setFacilityList,
  notifySlideOut,
  clearNotifications,
} = facilitySlice.actions;

export const selectCurrentFacility = (state) => state.facilities.selectedFacility;
export const selectFacilityList = (state) => state.facilities.facilityList
export const selectFacilityNotifications = (state) => state.facilities.notifications
export const selectFacilityLoading = (state) => state.facilities.loading

export default facilitySlice.reducer;
