import React from "react";
import { getDateTime } from "../../../../helpers/Dates";
import { GSInfoGroup, GSButton } from "golfstatus_react_components";
import { faSitemap, faTag } from "@fortawesome/free-solid-svg-icons";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";

const TournamentDiscountItem = (props) => {
  const {activateOn, code, deactivateOn, description, discountDollarAmount, discountPercent, redeemedCount, remainingCodes, status, isOrg} = props

  const getInfoGroup = () => {
    let infoSections = 
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [{ type: "primary", value: description }],
          },
          {
            sectionItems: [
              { type: "secondary", value: code },
              {
                type: "secondary",
                value: discountDollarAmount
                  ? `${moneyWithCommas(discountDollarAmount)} off`
                  : `${discountPercent}% off`,
              },
            ],
          },
          {
            sectionItems: [
              {
                type: "secondary",
                value: remainingCodes
                  ? `${remainingCodes} Available`
                  : "Unlimited Use",
              },
              { type: "secondary", value: `${redeemedCount} Redeemed` },
            ],
          },
        ],
      }

    if (activateOn && deactivateOn) {
      infoSections.sections?.push?.({
        sectionItems: [
          {
            type: "secondary",
            value: `Activates on ${getDateTime(activateOn)}`,
          },
          {
            type: "secondary",
            value: `Deactivates on ${getDateTime(deactivateOn)}`,
          },
        ],
      });
    }

    return [
      {...infoSections},
      {
        sections: [
          {
            sectionItems: [
              { type: "primary", value: <GSButton buttonIcon={isOrg ? faSitemap : faTag} /> },
            ],
          },
        ],
      },
      {
        sections: [
          {
            sectionItems: [
              {
                type: "",
                value: (
                  <GSButton
                    type={`status secondary pill ${status}`}
                    title={status}
                  />
                ),
              },
            ],
          },
        ],
      },
    ];
  };

  return <tournament-discount-item>
    <GSInfoGroup dataGroups={getInfoGroup()}></GSInfoGroup>
  </tournament-discount-item>
}


export default TournamentDiscountItem