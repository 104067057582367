import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  organizations,
  organizationMembers,
  organizationManagers,
  usersURL,
  deleteThunkResponse,
  getThunkResponse,
  saveThunkResponse,
  createThunkResponse,
  thunkResponse,
} from "../app/api";
import {
  getOpts,
  getResponse,
  getSerializedData,
  getIDRelationship,
  mediumDelay,
  extraLongDelay,
} from "../helpers/JSONapi";

export const getOrganizationMembers = createAsyncThunk(
  "users/getOrganizationMembers",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${organizations}/${id}/access-control-list`
    );
    return response;
  }
);

export const getOrganizationMember = createAsyncThunk(
  "users/getOrganizationMember",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizationMembers}/${id}`);
    return response;
  }
);

export const getOrganizationManager = createAsyncThunk(
  "users/getOrganizationManager",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizationManagers}/${id}`);
    return response;
  }
);

export const getManagerInvitations = createAsyncThunk(
  "users/getManagerInvitations",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${usersURL}/${id}/manager-invitations`);
    return response;
  }
);

//delete

export const deleteOrganizationMember = createAsyncThunk(
  "users/deleteOrganizationMember",
  async (id, thunkAPI) => {
    const response = await gsApi.delete(`${organizationMembers}/${id}`, {
      data: {},
    });
    return response;
  }
);

export const deleteOrganizationManager = createAsyncThunk(
  "users/deleteOrganizationManager",
  async (id, thunkAPI) => {
    const response = await gsApi.delete(`${organizationManagers}/${id}`, {
      data: {},
    });
    return response;
  }
);

//save

export const saveMember = createAsyncThunk(
  "users/saveMember",
  async (member, thunkAPI) => {
    const serializedData = getSerializedData(
      member,
      "organization-member",
      getOpts(member, getOrganizationRelationships(member))
    );
    return await getResponse(
      gsApi.patch,
      `${organizationMembers}/${member.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveManager = createAsyncThunk(
  "users/saveManager",
  async (manager, thunkAPI) => {
    const serializedData = getSerializedData(
      manager,
      "organization-manager",
      getOpts(manager, getOrganizationRelationships(manager))
    );
    return await getResponse(
      gsApi.patch,
      `${organizationManagers}/${manager.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTournamentOrganizer = createAsyncThunk(
  "users/saveTournamentOrganizer",
  async (manager, thunkAPI) => {
    const serializedData = getSerializedData(
      manager,
      "tournament-manager",
      getOpts(manager, [
        ...getIDRelationship("tournament"),
        ...getIDRelationship("organization"),
      ])
    );
    return await getResponse(
      gsApi.patch,
      `${organizationManagers}/${manager.id}`,
      serializedData,
      thunkAPI
    );
  }
);


//add

export const addOrganizationMember = createAsyncThunk(
  "users/addOrganizationMember",
  async (member, thunkAPI) => {
    const serializedData = getSerializedData(
      member,
      "organization-member",
      getOpts(member, getOrganizationRelationships(member))
    );
    return await getResponse(
      gsApi.post,
      `${organizationMembers}`,
      serializedData,
      thunkAPI
    );
  }
);

export const addOrganizationManager = createAsyncThunk(
  "users/addOrganizationManager",
  async (manager, thunkAPI) => {
    const serializedData = getSerializedData(
      manager,
      "organization-manager",
      getOpts(manager, getOrganizationRelationships(manager))
    );
    return await getResponse(
      gsApi.post,
      `${organizationManagers}`,
      serializedData,
      thunkAPI
    );
  }
);

export const addTournamentOrganizer = createAsyncThunk(
  "users/addTournamentOrganizer",
  async (manager, thunkAPI) => {
    const serializedData = getSerializedData(
      manager,
      "tournament-manager",
      getOpts(manager, [
        ...getIDRelationship("tournament"),
        ...getIDRelationship("organization"),
      ])
    );
    return await getResponse(
      gsApi.post,
      `${organizationManagers}`,
      serializedData,
      thunkAPI
    );
  }
);

//resend invitation
export const resendMemberInvitation = createAsyncThunk(
  "users/resendMemberInvitation",
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${organizationMembers}/${id}/resend-invitation`,
      {},
      thunkAPI
    );
  }
);

export const resendManagerInvitation = createAsyncThunk(
  "users/resendManagerInvitation",
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${organizationManagers}/${id}/resend-invitation`,
      {},
      thunkAPI
    );
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState: {
    selectedOrganizationMembers: [],
    selectedMember: {},
    selectedManager: {},
    notifications: [],
    loading: [],
  },
  reducers: {
    setMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setManager: (state, action) => {
      state.selectedManager = action.payload;
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    //get
    ...getThunkResponse(getOrganizationMembers, (state, action) => {
      const members = action.payload.organizationMembers.map?.((member) => ({
        ...member,
        type: "Organization Member",
      }));
      const managers = action.payload.organizationManagers.map?.((member) => ({
        ...member,
        avatar: "https://api.golfstatus.dev/images/user_round.png",
        type: "Tournament Organizer",
      }));
      state.selectedOrganizationMembers = [...members, ...managers];
    }),
    ...getThunkResponse(getOrganizationMember, (state, action) => {
      state.selectedMember = action.payload;
    }),
    ...getThunkResponse(getOrganizationManager, (state, action) => {
      state.selectedManager = action.payload;
    }),
    // delete
    ...deleteThunkResponse(
      deleteOrganizationMember,
      (state, action) => {
        const filteredList = state.selectedOrganizationMembers?.filter?.(
          (member) => member.id !== action.meta.arg
        );
        state.selectedOrganizationMembers = filteredList;
      },
      "Organization Member"
    ),
    ...deleteThunkResponse(
      deleteOrganizationManager,
      (state, action) => {
        const filteredList = state.selectedOrganizationMembers?.filter?.(
          (member) => member.id !== action.meta.arg
        );
        state.selectedOrganizationMembers = filteredList;
      },
      "Organization Member"
    ),
    //save
    ...saveThunkResponse(
      saveMember,
      (state, action) => {
        state.selectedMember = {
          ...action.payload,
          organizationType: { id: 1, type: 1 },
        };
        const index = state.selectedOrganizationMembers?.findIndex?.(
          (member) => member.id === action.payload.id
        );
        state.selectedOrganizationMembers[index] = {
          ...action.payload,
          type: "Organization Member",
        };
      },
      "Organization Member"
    ),
    ...saveThunkResponse(
      saveManager,
      (state, action) => {
        state.selectedMember = {
          ...action.payload,
          organizationType: { id: 1, type: 1 },
        };
        const index = state.selectedOrganizationMembers?.findIndex?.(
          (member) => member.id === action.payload.id
        );
        state.selectedOrganizationMembers[index] = {
          ...action.payload,
          type: "Organization Member",
        };
      },
      "Organization Manager"
    ),
    //tournament organizer
    ...saveThunkResponse(
      saveTournamentOrganizer,
      (state, action) => {
        state.selectedManager = {
          ...action.payload,
          type: "Tournament Manager",
        };
      },
      "Tournament Organizer"
    ),
    ...createThunkResponse(
      addTournamentOrganizer,
      (state, action) => {
        state.selectedManager = {
          ...action.payload,
          type: "Tournament Manager",
        };
      },
      "Tournament Organizer"
    ),
    //add
    ...createThunkResponse(
      addOrganizationMember,
      (state, action) => {
        const member = { ...action.payload, type: "Organization Member" };
        state.selectedOrganizationMembers = [
          member,
          ...state.selectedOrganizationMembers,
        ];
        state.selectedMember = member;
      },
      "OrganizationMember"
    ),
    ...createThunkResponse(
      addOrganizationManager,
      (state, action) => {
        let manager = {
          ...action.payload,
          type: "Tournament Organizer",
          avatar: "https://api.golfstatus.dev/images/user_round.png",
        };
        state.selectedOrganizationMembers = [
          manager,
          ...state.selectedOrganizationMembers,
        ];
        state.selectedManager = manager;
      },
      "Tournament Organizer"
    ),
    // resend invitations
    ...thunkResponse(
      resendManagerInvitation,
      (state, action) => {},
      "Manager Invitation",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Sending Invitation",
        fulfilledText: "Invitation Sent",
      }
    ),
    ...thunkResponse(
      resendMemberInvitation,
      (state, action) => {},
      "Member Invitation",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Sending Invitation",
        fulfilledText: "Invitation Sent",
      }
    ),
  },
});

export const { clearNotifications, setMember, setManager } = userSlice.actions;

export default userSlice.reducer;

export const selectUsersNotifications = (state) => state.users.notifications;

export const selectOrganizationMembers = (state) =>
  state.users.selectedOrganizationMembers;

export const selectMember = (state) => state.users.selectedMember;

export const selectManager = (state) => state.users.selectedManager;

export const selectLoading = (state) => state.users.loading;

function getOrganizationRelationships() {
  const relationships = [];
  relationships.push({
    name: "organization",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
