import React from "react";
import { useSelector } from "react-redux";
import {
  GSForm,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useMatch } from "react-router-dom";

//import scss
import "./tournament-settings-managers-edit.scss";
import { managerDetailForm } from "../../../forms/SettingsForms";
import {
  addTournamentOrganizer,
  clearNotifications,
  getOrganizationManager,
  saveTournamentOrganizer,
  selectManager,
  selectUsersNotifications,
  setManager,
} from "../../../reducers/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";

//Name the component
const TournamentSettingsManagersEdit = (props) => {
  const currentOrganizer = useSelector(selectManager);
  const currentTournament = useSelector(selectCurrentTournament);
  const userNotification = useSelector(selectUsersNotifications);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/settings/managers/:managerType/:managerID"
  );

  useEffect(() => {
    if (match?.params?.managerID && match?.params?.managerID !== "add") {
      dispatch(getOrganizationManager(match.params.managerID));
    } else {
      dispatch(
        setManager({ name: "", email: "", phone: "", organizationName: "" })
      );
    }
  }, [dispatch, match?.params?.managerID]);

  useNotificationNavigation("created", userNotification, -1)

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateManager = (value, property) => {
    setIsValid(true);
    let update = { ...currentOrganizer };
    update[property] = value;
    dispatch(setManager(update));
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentOrganizer };
    update.tournament = currentTournament;
    update.organization = currentTournament?.organization;
    if (update.id) {
      dispatch(saveTournamentOrganizer(update));
    } else {
      dispatch(addTournamentOrganizer(update));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: match === null ? "Send Invitation": "Save" ,
        isDisabled: !isValid,
        type: "black",
        action: save,
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];

    if (currentOrganizer.id) {
      actions.push({
        name: "Delete Manager",
        action: removeManager,
        type: "transparent red",
      });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1, { replace: true });
  };

  const removeManager = () => {
    timeoutAction();
    navigate("remove");
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Tournament Manager Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle={match === null ? "Add Tournament Manager" : "Edit Tournament Manager"}
        formSections={managerDetailForm(context, currentOrganizer)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: userNotification,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-settings-managers-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-managers-edit>
  );
};

//export the new namet
export default TournamentSettingsManagersEdit;
