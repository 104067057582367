
import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-upload-scores.scss"
import { getScoreImportForm } from "../../../../forms/RoundScoresForm";
import { createFormFromFile } from "../../../../helpers/Utilities";
import { clearUtility, selectUploadedFile, setUploadedFile, uploadFile } from "../../../../reducers/utilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications, importScorecards, selectNotifications, selectValidatedScorecard, validateScorecardsImport } from "../../../../reducers/scorecardSlice";
import { useFormValidation } from "../../../../hooks/formHooks";
import { useNotificationBanner, useNotificationNavigation } from "../../../../hooks/notificationHooks";
import { getRound, selectCurrentRound } from "../../../../reducers/roundSlice";
import { getRoundName } from "../../../../helpers/Converters";

//Name the component
const TournamentRoundUploadScores = (props) => {
  const [selectedFile, setSelectedFile] = useState("");
  //hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch("/:TournamentID/rounds/:roundID/scoring/upload");

  //selectors
  const uploadedFile = useSelector(selectUploadedFile);
  const validatedScorecard = useSelector(selectValidatedScorecard);
  const notifications = useSelector(selectNotifications);
  const currentRound = useSelector(selectCurrentRound)
  
  //form context
  let [context, isValid, setIsValid] = useFormValidation(false)

  context.getCurrentRound = () => {
    return currentRound
  }

  context.setFile = (files) => {
    const file = files[0];
    if (file) {
      const data = createFormFromFile(file);
      dispatch(uploadFile(data));
    }
  };

  context.removeFile = (file) => {
    setSelectedFile("")
  }

  useEffect(() => {return () => {dispatch(clearNotifications())}}, [dispatch])

  useEffect(() => {
    if(match?.params?.roundID){
      dispatch(getRound(match.params.roundID))
    }
  }, [match?.params?.roundID, dispatch])

  useEffect(() => {
    if (uploadedFile && uploadedFile !== selectedFile) {
      setSelectedFile(uploadedFile);
    }
    return () => {
      dispatch(setUploadedFile(""));
    };
  }, [uploadedFile, dispatch, selectedFile]);

  useEffect(() => {
    if ((selectedFile ?? "") !== "" && match?.params?.roundID) {
      const verify = { filename: selectedFile, id: match.params.roundID };
      dispatch(validateScorecardsImport(verify));
      dispatch(clearUtility())
    }
  }, [selectedFile, match?.params?.roundID, dispatch]);

  useEffect(() => {
    if (validatedScorecard?.data && !isValid) {
      setIsValid(true);
    }
  }, [validatedScorecard, setIsValid, isValid]);

  useNotificationNavigation("saved", notifications, -1, "Import Scores")

  const uploadScores = () => {
    setIsValid(false);
    dispatch(clearNotifications());
    if ((selectedFile ?? "") !== "" && match?.params?.roundID) {
      const scoresFile = { filename: selectedFile, id: match.params.roundID };
      dispatch(importScorecards(scoresFile));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Upload",
        isDisabled: !isValid,
        action: uploadScores,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Upload Round ${getRoundName(currentRound)} Scores`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle={`Upload Round ${getRoundName(currentRound)} Scores`} formSections={getScoreImportForm(validatedScorecard, context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-round-upload-scores>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-upload-scores>
  );
};

//export the new namet
export default TournamentRoundUploadScores;