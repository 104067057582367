import "./tournament-team-add-edit.scss";
import React, { useEffect, useState } from "react";
import { GSEmptyList, GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { getNewTeamFormSections } from "../../../forms/TeamForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  createTournamentTeam,
  getTournamentTeam,
  getTournamentUnassignedPlayers,
  removeTournamentPlayer,
  saveTournamentTeam,
  selectCurrentTeam,
  selectTeamsNotifications,
  selectTournamentUnassignedPlayers,
  setCurrentTeam,
  updateUnassignedPlayer,
  updateWaitListMember,
} from "../../../reducers/teamsSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { shuffleIndex } from "../../../helpers/Utilities";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { useFormValidation } from "../../../hooks/formHooks";
import { selectHistory } from "../../../reducers/appSlice";

const TournamentTeamAddEdit = (props) => {
  const [deletedMembers] = useState([]);

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matchEdit = useMatch("/:tournamentID/teams/:teamID/edit");
  const matchAdd = useMatch("/:tournamentID/teams/add");

  //selectors
  const currentTeam = useSelector(selectCurrentTeam);
  const currentTournament = useSelector(selectCurrentTournament);
  const teamNotifications = useSelector(selectTeamsNotifications);
  const unassignedPlayers = useSelector(selectTournamentUnassignedPlayers);
  const history = useSelector(selectHistory)

  //form context
  const [context, isValid, setIsValid] = useFormValidation(
    currentTeam.contactName && matchAdd
  );

  context.updateTeam = (value, property) => {
    setIsValid(true);
    let updateTeam = { ...currentTeam };
    updateTeam[property] = value;
    dispatch(setCurrentTeam(updateTeam));
  };

  context.reOrderTeam = (sourcePlayer, sourceIndex, destPlayer, destIndex) => {
    let players = context?.getTeamPlayers?.()?.map?.((p, index) => ({
      ...p,
      teamPosition: shuffleIndex(index, sourceIndex, destIndex),
    }));
    context.updateTeam(players, "players");
  };

  context.addPlayer = () => {
    navigate(`players/add/${currentTeam?.players?.length ?? 0}`);
  };

  context.getTeamPlayers = () => {
    return [...(currentTeam.players ?? [])].sort?.(
      (a, b) => a.teamPosition - b.teamPosition
    );
  };

  context.hasUnassignedPlayers = () => {
    return unassignedPlayers?.length;
  };

  context.teamFull = () => {
    return currentTeam?.players?.length === currentTournament.numberOfPlayers;
  };

  context.getUnassignedPlayers = () => {
    let unassigned = unassignedPlayers.filter(
      (p) =>
        p.status !== "assigned" &&
        !currentTeam?.players
          ?.map?.((p) => p.id ?? p.tempID)
          ?.join?.(" ")
          ?.includes?.(p.id)
    );
    return unassigned?.map?.((p) => ({
      tempID: p.id,
      inProgressPlayerId: p.id, // API expects the tempID to show up as inProgressPlayerId
      entryCode: "Unassigned Player",
      unassigned: true,
      contactName: p.name,
      firstName: p?.name?.split?.(" ")?.[0],
      lastName: p?.name?.split?.(" ")?.[1],
      name: p.name,
      phone: p.phone,
      email: p.email,
      adjustedHandicap: 0,
      hasPaid: p.hasPaid,
      player: p,
    }));
  };

  context.addUnassigned = (player) => {
    setIsValid(true);
    dispatch(
      setCurrentTeam({
        ...currentTeam,
        contactName:
          (currentTeam.contactName ?? "") === ""
            ? player.name
            : currentTeam.contactName,
        email:
          (currentTeam.email ?? "") === "" ? player.email : currentTeam.email,
        phone:
          (currentTeam.phone ?? "") === "" ? player.phone : currentTeam.phone,
        players: [...(currentTeam.players ?? []), player],
      })
    );
  };

  context.editPlayer = (player) => {
    navigate(`players/edit/${player.id ?? player.tempID}`);
  };

  context.removePlayer = (player) => {
    //setDeletedMembers([...deletedMembers, player]);
    dispatch(removeTournamentPlayer(player.id));
    context.updateTeam(
      currentTeam.players.filter((p) => p.id !== player.id),
      "players"
    );
  };

  context.getEmptyPlayersListMessage = (message) => {
    return (
      <GSEmptyList
        title="Add & Manage Players"
        detail={message}
      />
    );
  };

  //effects
  useEffect(() => {
    if (matchEdit && currentTeam?.id !== matchEdit?.params?.teamID) {
      dispatch(getTournamentTeam(matchEdit.params.teamID));
    }
  }, [dispatch, matchEdit, currentTeam.id]);

  useEffect(() => {
    let previous = history?.[1]
    if(previous?.includes?.("add"))
    {
      setIsValid(true)
    }
  }, [history, setIsValid])

  //component functionality
  const saveTeam = async () => {
    setIsValid(false);
    setUnsaved(false);
    let updatedTeam = { ...currentTeam };
    updatedTeam.tournament = currentTournament;
    if (matchEdit?.params?.teamID) {
      deletedMembers.forEach((m) => dispatch(removeTournamentPlayer(m.id)));
      await dispatch(saveTournamentTeam(updatedTeam));
      dispatch(getTournamentUnassignedPlayers(matchEdit.params.tournamentID));
    } else {
      updatedTeam.source = "organizer_generated";
      dispatch(createTournamentTeam(updatedTeam));
      currentTeam.players?.forEach?.((p) => {
        if (p?.unassigned) {
          dispatch(
            updateUnassignedPlayer({ ...p?.player, status: "assigned" })
          );
        }
        if (p?.isWaitList) {
          dispatch(updateWaitListMember({ ...p?.member, status: "assigned" }));
        }
      });
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: saveTeam,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    dispatch(clearNotifications());
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: `Team Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={matchAdd ? "Add Team" : "Edit Team"}
        formSections={getNewTeamFormSections(currentTeam, context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //custom hooks
  let notificationSettings = {
    notifications: teamNotifications,
    saveAction: saveTeam,
    bannerActions: getSaveBannerActions(saveTeam, leftNavigation),
    timeoutAction,
  };
  useNotificationNavigation(
    "created",
    teamNotifications,
    `/${matchAdd?.params?.tournamentID}/teams/[id]`
  );
  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    <tournament-team-add-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-add-edit>
  );
};

export default TournamentTeamAddEdit;
