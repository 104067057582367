import {
  faArrowCircleUp,
  faBan,
  faCheck,
  faCheckDouble,
  faMinus,
  faMobileAlt,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSFileSelect,
  GSItemList,
  GSRadioGroup,
  GSScorecard,
  GSToggle,
} from "golfstatus_react_components";
import TournamentPlayerGhinItem from "../components/tournament-player-ghin-item";

export const scorecardStatus = [
  { value: "unknown", label: "Unknown" },
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "deleted", label: "Deleted" },
  { value: "edited", label: "Edited" },
  { value: "finished", label: "Finished" },
  { value: "pro_finished", label: "Manually Finished" },
];

export const mobileStatus = [
  { mobileStatus: "active", icon: faMobileAlt, type: "" },
  { mobileStatus: "confirmed", icon: faCheckDouble, type: "" },
  { mobileStatus: "no_activity", icon: faMinus, type: "" },
  { mobileStatus: "submitted", icon: faCheck, type: "" },
  { mobileStatus: "scoring_disabled", icon: faBan, type: "" },
];

const getPreview = (data, currentRound) => {
  let user = {name: data.players}
  let holes = []
  for(let i = 1; i <=18; i++){
    if(data[`Hole ${i}`]){
      holes.push({position: i, score: data[`Hole ${i}`]})
    }
  }
  let preview = {
    type: "vertical xx-large-gap",
    title: "File Preview",
    fields: [
      {
        label: "Score Preview",
        value: (<GSScorecard user={user} holes={holes} splitNines={true} showTotalColumn/>),
        isEditable: false,
        customView: true,
      },
    ],
  };
  return preview
}

export const getScoreImportForm = (validatedScore, context) => {
  if (context) {
    let formSections = [];

    let importInfo = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Scores",
          value: (
            <GSFileSelect
              description="Tap or drag file to upload. Accepted Files: .xlsx, .xls, .csv"
              setSelectedFiles={context?.setFile}
              removeSourceItem={context?.removeFile}
              accept=".xls, .xlsx"
              title={<GSButton buttonIcon={faArrowCircleUp} title="Upload"></GSButton>}
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          isEditable: true,
          required: true,
        },
      ],
    };

    formSections = [{ ...importInfo }];

    if (validatedScore?.data) {
      formSections.push({ ...getPreview(validatedScore.data, context.getCurrentRound()) });
    }

    return formSections;
  }
};

const showPostScores = (round, organizationSettings) => {
  let usesPlayerScores = round.scoreBy === "player" || round.numberOfPlayers === 1
  return usesPlayerScores && round.scoringLocked && organizationSettings?.allowBulkPostToGhin
}

export const getScoringSettings = (context, round) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        title: "Accessibility",
        fields: [
          {
            label: "Speech Accessibility",
            value: (
              <GSToggle
                label="This will audibly announce the score when you tab into it."
                value={context.getSpeech()}
                onClick={context.toggleSpeech}
                falseDescription="Off"
                trueDescription="On"
              />
            ),
          },
        ],
      },
    ];

    if (showPostScores(round, context.getTournamentOrgSettings?.())) {
      form.push({
        type: "vertical xx-large-gap",
        title: "Post Scores to GHIN",
        fields: [
          {
            label: "Post Scores to GHIN",
            align: 'align-start',
            description:
              "Submit eligible scorecards for this round to GHIN on behalf of players.",
            value: (
              <GSButton
                type="light-grey"
                title="Post Scores"
                onClick={context?.postScoresToGHIN}
              />
            ),
          },
        ],
      });
    }

    if (round.state === "live") {
      form.push({
        type: "vertical xx-large-gap",
        title: "Delete Scorecards",
        fields: [
          {
            label: "Delete Scorecards",
            align: 'align-start',
            description:
              "This will delete current scorecards. Scorecards can’t be deleted if they contain scores.",
            value: (
              <GSButton
                type="red"
                title="Delete Scorecards"
                onClick={context?.removeScorecards}
              ></GSButton>
            ),
          },
        ],
      });
    }

    if (
      process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN === ".golfstatus.dev"
    ) {
      form.push({
        type: "vertical xx-large-gap",
        title: `Staging Environment Only`,
        
        fields: [
          {
            align: 'align-start',
            label: "Generate Scores",
            description:
              "This will apply random scores to empty holes on all scorecards associated with this round. This is a large, bulk action and will take all leaderboards a moment to reflect the updated scores. This can not be undone, scores will need to be removed manually from each scorecard",
            value: (
              <GSButton
                type="light-grey"
                title="Generate Scores"
                onClick={context?.generateFakeScores}
              />
            ),
          },
        ],
      });
    }

    return form;
  }
};

export const getPostScoresToGHINForm = (context, players) => {
  if (context) {
    let scoreTypeSection = {
      type: "vertical",
      title:"Scoring Type",
      fields: [
        {
          label: "Scoring Type",
          value: (
            <GSRadioGroup
              isLtr
              options={[
                { label: "Home", value: "h" },
                { label: "Away", value: "a" },
                { label: "Tournament", value: "t" },
              ]}
              selectedOption={context?.getSelectedScoreType?.()}
              selectionChanged={context.updateScoreType}
            />
          ),
        },
      ],
    };

    let eligiblePlayersSection = {
      title: "Eligible Scores",
      type: "vertical medium-gap",
      fields: [
        {
          value: context?.search,
          onChange: (e) => {
            context?.setSearch?.(e.target.value);
          },
          placeholder: "Search Scores...",
          isEditable: true,
          leftIcon: faSearch,
          rightIcon: faTimesCircle,
          rightIconClick: () => {context.setSearch("")}
        },
        {
          value: (
            <GSItemList
              type="vertical eligible-list medium-gap"
              items={players}
              listItem={(player) => <TournamentPlayerGhinItem {...player}/>}
              selectedItems={context?.getSelectedPlayers()}
              itemChecked={context?.addSelectedPlayer}
              itemUnchecked={context?.removeSelectedPlayer}
              emptyMessage={context.getEmptyListMessage?.()}
              isCheckList={true}
              checkAll={() => {
                context.selectAllPlayers();
              }}
              unCheckAll={() => {
                context.clearPlayers();
              }}
            />
          ),
          validation: {
            isValid: () => context?.getSelectedPlayers?.()?.length > 0,
            invalidLabel: "No players have been selected",
          },
          failedValidation: context?.validationFailed,
          isEditable: true,
          customView: true,
        },
      ],
    };

    let form = [scoreTypeSection, eligiblePlayersSection];
    return form;
  }
};
