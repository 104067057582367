import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  GSInfoGroup,
  GSButton,
  GSSelect,
  GSField,
} from "golfstatus_react_components";
import React from "react";
import { getDate } from "../../../../helpers/Dates";

import "./tournament-package-form-item.scss";

const TournamentPackageFormItem = (props) => {
  const { name, createdAt, packageForm, addForm, updatePackageForm } = props;

  const occurrenceOptions = () => {
    return [
      { label: "Per Order", value: "per_order" },
      { label: "Per Player", value: "per_player" },
      { label: "Per Team", value: "per_team" },
      { label: "Per Sponsor", value: "per_sponsor" },
    ];
  };

  const infoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary",
        value: `Created ${getDate(createdAt)}`,
      },
    ],
  };
  const editSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            type="light-grey"
            buttonIcon={faPlusCircle}
            title="Add to package"
            onClick={(e) => {
              addForm?.(props);
              e?.stopPropagation?.();
            }}
          />
        ),
      },
    ],
  };

  const occurrenceSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSField
            label="Form Occurrence"
            value={
              <GSSelect
                placeholder="Occurrence"
                options={occurrenceOptions()}
                selectedOption={occurrenceOptions()?.find?.(
                  (oo) => oo.value === packageForm?.occurrence
                )}
                onChange={(option) => updatePackageForm?.(option, props)}
              />
            }
          ></GSField>
        ),
      },
    ],
  };

  const infoColumn = {
    fit: "stretch",
    sections: [
      {
        ...infoSection,
      },
    ],
  };

  const editColumn = {
    sections: [
      {
        ...editSection,
      },
    ],
  };

  const occurenceColumn = {
    sections: [
      {
        ...occurrenceSection,
      },
    ],
  };

  const getGroup = () => {
    let group = [{ ...infoColumn }];
    if (packageForm) {
      group.push({ ...occurenceColumn });
    } else if(addForm) {
      group.push({ ...editColumn });
    }
    return group;
  };

  return (
    <tournament-package-form-item>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-package-form-item>
  );
};

export default TournamentPackageFormItem;
