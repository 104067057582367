import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-payout-request.scss";
import {
  useFormDataValidation,
} from "../../../hooks/formHooks";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getPayoutRequestForm } from "../../../forms/ReportingForm";
import TournamentReportingOrderSummary from "./tournament-reporting-order-summary";
import { useSelector } from "react-redux";
import {
  requestTournamentPayout,
  selectCurrentPayout,
  selectNotifications,
  selectOrderSummary,
  updatePayout,
} from "../../../reducers/reportingSlice";
import { useDispatch } from "react-redux";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useEffect } from "react";

//Name the component
const TournamentReportingPayoutRequest = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderSummary = useSelector(selectOrderSummary);
  const currentPayout = useSelector(selectCurrentPayout);
  const notifications = useSelector(selectNotifications);
  const tournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    return () => {dispatch(updatePayout({}))}
  }, [dispatch])

  useNotificationNavigation(
    "saved",
    notifications,
    -1,
    "Request Tournament Payout"
  );

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: currentPayout,
    setData: updatePayout,
  });

  const save = () => {
    setIsValid(false);
    let payout = { ...currentPayout };
    payout.payoutType = "stripe_transfer";
    payout.tournament = tournament
    dispatch(requestTournamentPayout(payout));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Request Payout",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Payout Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          <TournamentReportingOrderSummary
            hideNetRevenue
            hideRefunds
            hidePayouts
            {...orderSummary}
          />
        }
        formSections={getPayoutRequestForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-payout-request>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-payout-request>
  );
};

//export the new namet
export default TournamentReportingPayoutRequest;
