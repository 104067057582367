import {
  GSFileSelect,
  GSQuickFilter,
  GSButton,
  GSTextEditor,
  GSToggle,
} from "golfstatus_react_components";
import OrganizationLineItem from "../components/organization-line-item";
import {
  faArrowCircleUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const getFormSections = (tournament, context) => {
  if (context) {
    let generalInformation = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Tournament Name",
          placeholder: "Tournament Name",
          failedValidation: context?.validationFailed,
          value: tournament?.name || "",
          onChange: (e) => {
            context?.updateTournament?.(e.target.value, "name");
          },
          isEditable: true,
          required: true,
          rightIcon: faTimesCircle,
          rightIconClick: context?.clearTournamentName,
        },
        {
          label: "Organization",
          failedValidation: context?.validationFailed,
          value: (
            <GSQuickFilter
              getDefaultSearch={context?.getOrganizationSearch}
              selectedItem={context?.getSelectedOrganization()}
              filteredList={context?.getOrganizationList()}
              itemSelected={context?.selectOrganization}
              itemRemoved={context?.removeSelectedOrganization}
              getItem={(organization) => (
                <OrganizationLineItem type="compact" {...organization} />
              )}
            />
          ),
          validation: {
            isValid: () => context?.getSelectedOrganization?.()?.id,
            invalidLabel: "Organization selection is required.",
          },
          customView: true,
          isEditable: true,
          required: true,
        },
        {
          label: "Tournament Image",
          value: (
            <GSFileSelect
              description="Tap or drag file to upload. Accepted Files: .JPG, .PNG, .GIF"
              setSelectedFiles={context?.setLogo}
              removeSourceItem={context?.removeLogo}
              sourceList={context?.getLogo?.()}
              accept=".jpg, .jpeg, .png, mp4"
              title={
                <GSButton
                  buttonIcon={faArrowCircleUp}
                  title="Upload"
                ></GSButton>
              }
            />
          ),
          customView: true,
          failedValidation: context?.validationFailed,
          isEditable: true,
        },
        {
          label: "Event Description",
          value: (
            <GSTextEditor
              placeholder="Event Description"
              value={tournament?.description || ""}
              onChange={(text) =>
                context?.updateTournament(text, "description")
              }
            />
          ),
          isEditable: true,
          customView: true,
          required: false,
        },
      ],
    };

    let termsOfService = {
      title: "Terms of Service & Privacy Policy *",
      type: "vertical xx-large-gap",
      fields: [
        {
          description: (
            <div>
              I have reviewed and accept the{" "}
              {
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://golfstatus.com/golfstatus-terms-of-service"
                >
                  Terms of Service
                </a>
              }{" "}
              and{" "}
              {
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://golfstatus.com/golfstatus-privacy-policy"
                >
                  Privacy Policy
                </a>
              }{" "}
              on behalf of {context?.getSelectedOrganization()?.name}.
            </div>
          ),
          value: (
            <GSToggle
              value={context.tosAccepted}
              onClick={context.toggleAccepted}
              trueDescription={"Yes"}
              falseDescription={"No"}
            />
          ),
          customView: true,
          required: true,
          validation: {
            isValid: () => context?.tosAccepted,
            invalidLabel: "Terms of Service acceptance is required.",
          },
          failedValidation: context?.validationFailed,
        },
      ],
    };

    if (!context?.getSelectedOrganization()?.tosAcceptedAt) {
      return [
        { ...generalInformation },
        {
          ...termsOfService,
        },
      ];
    }

    return [generalInformation];
  }
};
