import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

//import scss
import "./tournament-settings-payments-customer-invoice-watch-email-delete.scss";
import { useMatch, useNavigate } from "react-router-dom";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomerInvoiceWatchEmail,
  getTournamentCustomerInvoiceWatchEmail,
  selectCurrentCustomerInvoiceWatchEmail,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { useNotificationNavigation } from "../../../hooks/notificationHooks";
import TournamentPackageWatchItem from "../tournament-promotion/tournament-packages/tournament-package-watch-item";

// Name the component
const TournamentSettingsPaymentsCustomerInvoiceWatchEmailDelete = (props) => {
  const notifications = useSelector(selectTournamentNotifications);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentWatchItem = useSelector(selectCurrentCustomerInvoiceWatchEmail);

  const match = useMatch(
    "/:tournamentID/settings/payments/customer-invoice-watch/:watchID/delete"
  );

  useEffect(() => {
    if (match?.params?.watchID) {
      dispatch(getTournamentCustomerInvoiceWatchEmail(match.params.watchID));
    }
  }, [match?.params?.watchID, dispatch]);

  useNotificationNavigation(
    "removed",
    notifications,
    -2,
    "Invoice Watch Email"
  );

  const save = () => {
    dispatch(deleteCustomerInvoiceWatchEmail(match.params.watchID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "delete",
        action: save,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    // Add Title to your component
    return {
      title: `Delete Email`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Email")}
        formSections={removeItemForm(
          "Invoice Watch Email",
          <TournamentPackageWatchItem {...currentWatchItem}/>, "delete", `Are you sure you want to delete ${currentWatchItem.email}? They will no longer receive watch emails for customer invoices.`
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-settings-payments-customer-invoice-watch-email-delete>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-payments-customer-invoice-watch-email-delete>
  );
};

// export the new name
export default TournamentSettingsPaymentsCustomerInvoiceWatchEmailDelete;
