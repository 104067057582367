import React, { useState } from "react";
import {
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
  GSForm,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-rounds.scss";
import { useSelector } from "react-redux";
import {
  getTournamentRounds,
  selectTournamentRounds,
} from "../../../../reducers/roundSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  getPackageRounds,
  getTournamentPackage,
  saveTournamentPackage,
  selectCurrentPackageRounds,
  selectCurrentTournamentPackage,
  selectNotifications,
  setCurrentPackageRounds,
} from "../../../../reducers/packageSlice";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import TournamentRoundLineItem from "../../tournament-rounds/tournament-round-line-item";
import SearchBar from "../../../search-bar";

//Name the component
const TournamentPackageRounds = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const tournamentRounds = useSelector(selectTournamentRounds);
  const packageRounds = useSelector(selectCurrentPackageRounds);
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const packageNotifications = useSelector(selectNotifications);

  const match = useMatch("/:tournamentID/promotion/packages/:packageID/rounds");

  const dispatch = useDispatch();

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentRounds(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getPackageRounds(match.params.packageID));
      dispatch(getTournamentPackage(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentPackage };
    update.tournamentRounds = packageRounds;
    dispatch(saveTournamentPackage(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${currentPackage.name} Rounds`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  // const getPageActions = () => {
  //   return [
  //     {
  //       type: "no-wrap light-grey",
  //       buttonTitle: "View Rounds",
  //       actionClick: gotoRounds,
  //     },
  //   ];
  // };

  const selectRound = (round) => {
    setIsValid(true);
    dispatch(setCurrentPackageRounds([...packageRounds, round]));
  };

  const removeRound = (round) => {
    setIsValid(true);
    dispatch(
      setCurrentPackageRounds(
        packageRounds?.filter?.((pr) => pr.id !== round.id)
      )
    );
  };

  const getFilteredRounds = () => {
    return tournamentRounds?.filter?.(
      (round) =>
        ((round.roundName ?? "").includes?.(search) ||
        round.number === parseInt(search)) && round.startType !== "shotgun_start"
    );
  };

  const gotoRounds = () => {
    navigate(`/${match.params.tournamentID}/rounds`);
  };

  const getEmptyRoundsMessage = () => {
    return (
      <GSEmptyList
        title={`${currentPackage?.name} Rounds`}
        detail="This tournament does not have any rounds with interval starts."
        actions={[
          {
            title: "Create Round",
            buttonIcon: faPlus,
            onClick: gotoRounds,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title={`We couldn't find the round you were looking for...`}
        detail="Try changing the search term or create a new round."
        actions={[
          {
            title: "clear search",
            onClick: () => {setSearch("")},
            type: "light-grey",
          },
          {
            title: "View rounds",
            onClick: gotoRounds,
            type: "black",
          },
        ]}
      />
    );
  };

  const getRoundsList = () => {
    return (
      <GSItemList
        type="vertical rounds medium-gap grey-empty-list rounded-empty-list"
        items={getFilteredRounds()}
        isCheckList={true}
        selectedItems={packageRounds}
        itemChecked={selectRound}
        itemUnchecked={removeRound}
        emptyMessage={search !== "" ? getEmptySearchMessage() : getEmptyRoundsMessage()}
        listItem={(item) => (
          <TournamentRoundLineItem round={item} hideActions hideCopy />
        )}
      ></GSItemList>
    );
  };

  const getInput = () => {
    return {
      placeholder: "Search Rounds...",
      search,
      setSearch,
    };
  };

  const getFieldValue = () => {
    return (
      <div className="rounds-list">
        <SearchBar {...getInput()} type="no-pad" />
        {getRoundsList()}
      </div>
    );
  };

  const getForm = () => {
    return [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Rounds",
            description: "Allow registrants to select a tee time at registration. Applicable to rounds with interval starts.",
            value: getFieldValue(),
          },
        ],
      },
    ];
  };

  const getContent = () => {
    return <GSForm formTitle={`${currentPackage?.name} Rounds`} formSections={getForm()} />;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-rounds>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-rounds>
  );
};

//export the new namet
export default TournamentPackageRounds;
