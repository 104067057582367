import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-team-tee-choices.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";
import TournamentTeamDetailHero from "./tournament-team-detail-hero";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentTeam,
  getTournamentTeamPlayerTeeChoices,
  getTournamentTeamTeeChoices,
  saveTeamPlayerTeeChoice,
  saveTeamTeeChoice,
  saveTournamentTeam,
  saveTournamentTeamPlayer,
  selectCurrentTeam,
  selectLoading,
  selectTeamsNotifications,
  selectTournamentTeamTeeChoices,
  setCurrentTeam,
  setPlayer,
  setTeamTeeChoice,
} from "../../../reducers/teamsSlice";
import { getTeamTeeChoicesForm } from "../../../forms/TeamForm";

//Name the component
const TournamentTeamTeeChoices = (props) => {
  const [context, isValid, setIsValid] = useFormValidation(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/teams/:teamID/tees");
  const notifications = useSelector(selectTeamsNotifications);
  const loading = useSelector(selectLoading);

  const currentTeam = useSelector(selectCurrentTeam);
  const currentTeamTeeChoices = useSelector(selectTournamentTeamTeeChoices);

  context.getTeeChoices = () => {
    let choices = currentTeamTeeChoices;
    return choices;
  };

  context.getTeam = () => {
    return currentTeam;
  };

  context.getPlayers = () => {
    return currentTeam?.players;
  };

  context.toggleOverrideTeePreference = () => {
    setIsValid(true);
    let update = { ...currentTeam };
    update.updated = true;
    update.overrideTeePreference = !update.overrideTeePreference;
    dispatch(setCurrentTeam(update));
  };

  context.toggleOverridePlayerTeePreference = (player) => {
    setIsValid(true);
    let update = { ...player };
    update.overrideTeePreference = !update.overrideTeePreference;
    update.updated = true;
    dispatch(setPlayer(update));
  };

  context.updateTeam = (value, property) => {
    setIsValid(true);
    let update = { ...currentTeam };
    update.updated = true;
    update[property] = value;
    dispatch(setCurrentTeam(update));
  }

  context.updatePlayer = (value, property, player) => {
    setIsValid(true);
    let update = { ...player };
    update.updated = true;
    update[property] = value;
    dispatch(setPlayer(update));
  }

  const pageLoading = () => loading?.includes("getTournamentTeamTeeChoices") || loading?.includes("getTournamentTeam") ||loading?.includes("getTournamentTeamPlayerTeeChoices")

  useEffect(() => {
    if (match?.params?.teamID) {
      dispatch(getTournamentTeamTeeChoices(match?.params?.teamID));
      dispatch(getTournamentTeam(match?.params?.teamID));
    }
  }, [match?.params?.teamID, dispatch]);

  useEffect(() => {
    if(currentTeam?.players?.[0]?.teeChoices === undefined)
    {
      currentTeam.players?.forEach((player) => {
        if (player?.id) {
          dispatch(getTournamentTeamPlayerTeeChoices(player.id))?.then?.(
            (response) => {
              let update = { ...player };
              update.teeChoices = response.payload;
              dispatch(setPlayer(update));
            }
          );
        }
      })
    }
    
  }, [currentTeam, dispatch])

  context.setTee = (option, tee) => {
    setIsValid(true);
    console.log(currentTeamTeeChoices)
    let update = { ...tee };
    update.roundTee = option.value;
    update.updated = true;
    dispatch(setTeamTeeChoice(update));
  };

  context.setPlayerTee = (option, tee, player) => {
    setIsValid(true);
    let updatePlayer = { ...player };
    let update = { ...tee };
    update.roundTee = option.value;
    update.updated = true;
    let teeIndex = updatePlayer?.teeChoices?.findIndex?.(
      (tc) => tc.id === tee.id
    );
    let updatedTeeChoices = [...updatePlayer.teeChoices];
    updatedTeeChoices[teeIndex] = update;
    updatePlayer.teeChoices = updatedTeeChoices;
    dispatch(setPlayer(updatePlayer));
  };

  const save = () => {
    setIsValid(false);
    if (currentTeam?.updated) {
      dispatch(saveTournamentTeam(currentTeam));
    }
    currentTeam.players.forEach?.((player) => {
      if (player?.updated) {
        dispatch(saveTournamentTeamPlayer(player));
      }
      player?.teeChoices?.forEach?.(teeChoice => {
        if(teeChoice.updated){
          dispatch(saveTeamPlayerTeeChoice(teeChoice))
        }
      })
    });
    currentTeamTeeChoices?.forEach?.((tc) => {
      if (tc.updated) {
        dispatch(saveTeamTeeChoice(tc));
      }
    });
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Tees`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={<TournamentTeamDetailHero {...currentTeam} />}
        formSections={getTeamTeeChoicesForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-team-tee-choices>
      <GSSidePanelPage
        loading={pageLoading()}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-tee-choices>
  );
};

//export the new namet
export default TournamentTeamTeeChoices;
