import React, { useState, useEffect, useMemo } from "react";
import "./tournament-round-hole-sponsors.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  selectCurrentTournament,
  selectTournamentNotifications,
  setTournamentNotifications,
} from "../../../../reducers/tournamentSlice";
import {
  faChevronLeft,
  faExternalLinkSquareAlt,
  faSave,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSActionBar,
  GSEmptyList,
  GSImage,
  GSInput,
  GSItemList,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  getRound,
  getRoundHoleSponsors,
  selectCurrentRound,
} from "../../../../reducers/roundSlice";
import {
  clearNotifications,
  getTournamentSponsorRoundHoles,
  getTournamentSponsors,
  saveSponosorRoundHole,
  selectLoading,
  selectSponsorList,
  selectTournamentSponsorRoundHoles,
  setRoundHoleSponsor,
  selectSponsorNotifications,
} from "../../../../reducers/sponsorSlice";
import { getTournamentPackages } from "../../../../reducers/packageSlice";
import {
  getNotificationItemInfo,
  getRoundName,
} from "../../../../helpers/Converters";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import TournamentRoundHoleSponsorItem from "./tournament-round-hole-sponsor-item";

const TournamentRoundHoleSponsors = (props) => {
  const [search, setSearch] = useState("");
  const [par, setPar] = useState();
  //hooks
  const dispatch = useDispatch();
  const match = useMatch("/:id/rounds/:roundID/hole-sponsors/*");
  const navigate = useNavigate();
  //selectors
  const tournamentNotifications = useSelector(selectTournamentNotifications);
  const sponsorNotifications = useSelector(selectSponsorNotifications);
  const notifications = [...tournamentNotifications, ...sponsorNotifications];
  const currentTournament = useSelector(selectCurrentTournament);
  const currentRound = useSelector(selectCurrentRound);
  const sponsorList = useSelector(selectSponsorList);
  const tournamentRoundHoleSponsors = useSelector(
    selectTournamentSponsorRoundHoles,
  );
  const sponsorLoading = useSelector(selectLoading);

  const lockSponsorAssignment = !sponsorList.some(
    (sponsor) => sponsor.sponsorshipType === "leaderboard",
  );

  //effects
  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundHoleSponsors(match.params.roundID));
    dispatch(getTournamentSponsors(match.params.id));
    dispatch(getTournamentSponsorRoundHoles(match?.params?.id));
    dispatch(getTournamentPackages(match?.params?.id));
  }, [dispatch, match.params.roundID, match.params.id]);

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the hole you’re looking for…"
        detail="Try changing the search term and filter."
        actions={[
          {
            title: "Clear All",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const getNavigation = () => {
    return {
      title: `Round ${getRoundName(currentRound)} Hole Sponsor Assignments `,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const defaultBanner = useMemo(
    () => ({
      state: "grey",
      title: getNotificationItemInfo(
        `Selections will automatically be saved.`,
        faSave,
      ),
    }),
    [],
  );

  const timeoutAction = () => {
    dispatch(setTournamentNotifications([]));
    dispatch(clearNotifications());
  };

  const getSponsorOptions = () => {
    return sponsorList.map?.((s) => ({
      label: s.name,
      subLabel: <GSImage size="small ultra-wide" src={s.logoMobile} />,
      value: s,
    }));
  };

  const setSponsor = (holeAssignment, option) => {
    let srh = { ...holeAssignment };
    srh.sponsor = option.value;
    srh.tournament = currentTournament;
    srh.tournamentRound = currentRound;
    dispatch(setRoundHoleSponsor(srh));
    dispatch(saveSponosorRoundHole(srh));
  };

  const sponsorMoved = (sourceItem, sourceIndex, destItem, destIndex) => {
    setSponsor(destItem, { value: sourceItem.sponsor });
    setSponsor(sourceItem, { value: destItem.sponsor });
  };

  const getRoundSponsors = () => {
    const roundSponsors = tournamentRoundHoleSponsors.filter(
      (trhs) => trhs.tournamentRound?.id === match?.params?.roundID,
    );

    return roundSponsors.filter?.(
      (s) =>
        `hole ${s.hole} `.includes(search) &&
        s.holePar === (par ? par?.value : s.holePar),
    );
  };

  const getHoleAssigmentList = () => {
    return (
      <GSItemList
        type="vertical scrollable"
        items={getRoundSponsors()}
        itemSelected={() => {}}
        itemMoved={sponsorMoved}
        isSortable={true}
        enableStep={false}
        bottomScrollMargin={100}
        topScrollMargin={100}
        dragOffset={40}
        listItem={(item) => (
          <TournamentRoundHoleSponsorItem
            {...item}
            setSponsor={setSponsor}
            getSponsorOptions={getSponsorOptions}
          />
        )}
        emptyMessage={getEmptyListMessage()}
        loading={sponsorLoading?.includes?.("getTournamentSponsorRoundHoles")}
        loadingMainText="Loading Round Sponsors..."
      />
    );
  };

  const getPaidMessage = () => {
    return (
      <GSEmptyList
        title="Paid Feature"
        detail="The Technology Sponsorship must be purchased to unlock in-app hole sponsor assignments. Please contact your sales or client success representative with questions or to learn more."
        actions={[
          {
            title: "learn more",
            rightIcon: faExternalLinkSquareAlt,
            onClick: () => {
              window.open(
                "https://golfstatus.com/technology-sponsor",
                "_blank",
              );
            },
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const getContent = () => {
    if (lockSponsorAssignment) {
      return (
        <gs-form>
          <GSActionBar
            type="form-header"
            header={`Round ${getRoundName(
              currentRound,
            )} Hole Sponsor Assignments `}
          ></GSActionBar>
          <GSItemList
            type="vertical"
            items={[]}
            emptyMessage={getPaidMessage()}
          />
        </gs-form>
      );
    } else {
      return (
        <gs-form>
          <GSActionBar
            type="form-header"
            header={`Round ${getRoundName(
              currentRound,
            )} Hole Sponsor Assignments `}
          ></GSActionBar>
          <div className="search-bar">
            <GSInput
              textValue={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search Hole Numbers…"
              leftIcon={faSearch}
              rightIcon={faTimesCircle}
              rightIconClick={() => {
                setSearch("");
              }}
            />
            <GSSelect
              options={[
                { label: "Par 3", value: 3 },
                { label: "Par 4", value: 4 },
                { label: "Par 5", value: 5 },
              ]}
              selectedOption={par}
              onChange={(option) => {
                setPar(option);
              }}
              placeholder="Select Par.."
              isClearable
            />
          </div>
          {getHoleAssigmentList()}
        </gs-form>
      );
    }
  };

  const autoAssign = () => {
    sponsorList.forEach?.((spon, index) => {
      if (index < getRoundSponsors()?.length)
        setSponsor(getRoundSponsors()?.[index], { value: spon });
    });
  };

  const clearSponsors = () => {
    getRoundSponsors()?.forEach?.((spon) => {
      setSponsor(spon, { value: undefined });
    });
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Auto Assign",
        action: autoAssign,
        type: "black",
      },
      { name: "Clear Assignments", action: clearSponsors, type: "grey" },
    ];
    actions = [];
    return actions;
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction,
    defaultBanner,
  });

  return (
    <tournament-round-hole-sponsors>
      <GSSidePanelPage
        header={getNavigation()}
        banner={lockSponsorAssignment ? "" : banner}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-round-hole-sponsors>
  );
};

export default TournamentRoundHoleSponsors;
