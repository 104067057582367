import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-watch-emails-add-edit.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createPackageWatchItem,
  getPackageWatchItem,
  savePackageWatchItem,
  selectCurrentTournamentPackage,
  selectCurrentWatchItem,
  selectNotifications,
  setCurrentWatchItem,
} from "../../../../reducers/packageSlice";
import { getPackageEmailForm } from "../../../../forms/TournamentPackageForm";
import { useEffect } from "react";
import { useFormValidation } from "../../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner, useNotificationNavigation } from "../../../../hooks/notificationHooks";

//Name the component
const TournamentPackageWatchEmailsAddEdit = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/watch-emails/:watchItemID"
  );

  const currentWatchItem = useSelector(selectCurrentWatchItem);
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const packageNotifications = useSelector(selectNotifications);

  useEffect(() => {
    if (match?.params?.watchItemID !== "add") {
      dispatch(getPackageWatchItem(match.params.watchItemID));
    }
  }, [dispatch, match?.params?.watchItemID]);

  const [context, isValid, setIsValid] = useFormValidation(false)

  context.updateWatchItem = (value, property) => {
    setIsValid(true);
    let update = { ...currentWatchItem };
    update[property] = value;
    dispatch(setCurrentWatchItem(update));
  };

  const save = () => {
    setUnsaved(false)
    setIsValid(false)
    let update = { ...currentWatchItem };
    update.tournamentPackage = currentPackage;
    if (match?.params?.watchItemID === "add") {
      dispatch(createPackageWatchItem(update));
    } else {
      dispatch(savePackageWatchItem(update));
    }
  };

  context.submitEmail = () => {
    if(isValid)
    {
      save()
    }
  }

  const remove = () => {
    navigate("remove");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if (match?.params?.watchItemID !== "add") {
      actions.push({ name: "Delete Email", action: remove, type: "transparent red" });
    }
    return actions;
  };

  const leftNavigation = () => {
    if(isValid && !bannerNotifications)
    {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Email Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle={match?.params?.watchItemID === "add" ? "Add Email" : "Edit Email"}
        formSections={getPackageEmailForm(context, currentWatchItem)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  //custom hooks
  let notificationSettings = {
    notifications: packageNotifications,
    bannerActions: getSaveBannerActions(save, leftNavigation),
  };
  useNotificationNavigation(
    "created",
    packageNotifications,
    -1
  );
  useNotificationNavigation(
    "saved",
    packageNotifications,
    -1
  );
  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-watch-emails-add-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-watch-emails-add-edit>
  );
};

//export the new namet
export default TournamentPackageWatchEmailsAddEdit;
