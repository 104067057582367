import React from "react";
import {
  GSItemList,
  GSSidePanelPage,
  GSActionBar
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-payments.scss";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getTournamentPaymentSettings,
  selectCurrentPaymentSettings,
} from "../../../reducers/settingsSlice";
import {
  getSettingsPages,
} from "../../../forms/SettingsForms";
import { useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  getTournamentCustomerInvoiceWatchEmails,
  getTournamentDonationWatchItems,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import NavigationListItem from "../../navigation-list-item";

//Name the component
const TournamentSettingsPayments = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/settings/payments");
  const notifications = useSelector(selectTournamentNotifications);
  const tournamentPaymentSettings = useSelector(selectCurrentPaymentSettings)

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPaymentSettings(match.params.tournamentID));
      dispatch(getTournamentDonationWatchItems(match.params.tournamentID));
      dispatch(getTournamentCustomerInvoiceWatchEmails(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Payment Settings`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Payment Settings"/>
        <GSItemList
         itemSelected={(item) => {navigate(item.url)}}
          type="vertical"
          items={getSettingsPages(tournamentPaymentSettings)}
          listItem={(item) => <NavigationListItem page={item} />}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-settings-payments>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-settings-payments>
  );
};

//export the new namet
export default TournamentSettingsPayments;
