import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  createThunkResponse,
  customerInvoiceWatchEmails,
  deleteThunkResponse,
  donationWatchItems,
  externalLinks,
  fileDownloadThunkResponse,
  getThunkResponse,
  saveThunkResponse,
  thunkResponse,
  tournament,
  tournamentFormatTypes,
  tournamentPromotions,
  userTournaments,
} from "../app/api";
import {
  extraLongDelay,
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  mediumDelay,
  shortDelay,
} from "../helpers/JSONapi";

export const searchUpcomingTournaments = createAsyncThunk(
  "tournaments/searchUpcomingTournaments",
  async (userID, thunkAPI) => {
    let url = userTournaments(userID);
    const response = await gsApi.get(url, {
      data: { status: "future", search: true },
    });
    return response;
  },
);

export const searchPastTournaments = createAsyncThunk(
  "tournaments/searchPastTournaments",
  async (userID, thunkAPI) => {
    let url = userTournaments(userID);
    const response = await gsApi.get(url, {
      data: { status: "past", search: true },
    });
    return response;
  },
);

export const searchDraftTournaments = createAsyncThunk(
  "tournaments/searchDraftTournaments",
  async (userID, thunkAPI) => {
    let url = userTournaments(userID);
    const response = await gsApi.get(url, {
      data: { status: "draft", search: true },
    });
    return response;
  },
);

export const searchAllEvents = createAsyncThunk(
  "tournaments/searchAllEvents",
  async (filter, thunkAPI) => {
    let url = userTournaments(filter.userID);
    const response = await gsApi.get(url, {
      data: {
        name: filter.name,
        status: filter.status?.toLowerCase?.(),
        search: true,
        page: filter?.page?.page,
        "per-page": filter?.page?.number,
      },
    });
    return response;
  },
);

export const getTournament = createAsyncThunk(
  "tournaments/getTournament",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}`;
    const response = await gsApi.get(url);
    return response;
  },
);

export const getTournamentPointOfContact = createAsyncThunk(
  "tournaments/getTournamentPointOfContact",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/point-of-contact`;
    const response = await gsApi.get(url);
    return response;
  },
);

export const getTournamentFormatTypes = createAsyncThunk(
  "tournaments/getTournamentFormatTypes",
  async (id, thunkAPI) => {
    let url = `${tournamentFormatTypes}`;
    const response = await gsApi.get(url);
    return response;
  },
);

export const getTournamentExternalLinks = createAsyncThunk(
  "tournaments/getTournamentExternalLinks",
  async (id, thunkAPI) => {
    let url = `${tournament}/${id}/external-links`;
    const response = await gsApi.get(url);
    return response;
  },
);

export const getTournamentDonationWatchItems = createAsyncThunk(
  "tournaments/getTournamentDonationWatchItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/donation-watch-items`,
    );
    return response;
  },
);

export const getTournamentDonationWatchItem = createAsyncThunk(
  "tournaments/getTournamentDonationWatchItem",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${donationWatchItems}/${id}`);
    return response;
  },
);

export const getTournamentCustomerInvoiceWatchEmails = createAsyncThunk(
  "tournaments/getTournamentCustomerInvoiceWatchEmails",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/customer-invoice-watch-emails`,
    );
    return response;
  },
);

export const getTournamentCustomerInvoiceWatchEmail = createAsyncThunk(
  "tournaments/getTournamentCustomerInvoiceWatchEmail",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${customerInvoiceWatchEmails}/${id}`);
    return response;
  },
);

//create

export const createTournament = createAsyncThunk(
  "tournaments/createTournament",
  async (newTournament, thunkAPI) => {
    let serializedData = getSerializedData(
      newTournament,
      "tournaments",
      getOpts(newTournament, [...getOrganizationRelationship()]),
    );

    let userid =
      newTournament?.pointOfContact?.id ?? newTournament?.pointOfContact?.uid;
    if (userid?.includes?.("_")) {
      userid = userid?.split?.("_")?.[0];
    }

    if (newTournament?.pointOfContact) {
      const relationship = {
        data: {
          id: userid,
          type: "users",
        },
      };
      serializedData.data.relationships["point-of-contact"] = relationship;
    }

    return await getResponse(
      gsApi.post,
      `${tournament}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const copyTournament = createAsyncThunk(
  "tournaments/copyTournament",
  async (copySettings, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournament}/${copySettings.id}/copy`,
      copySettings,
      thunkAPI,
    );
  },
);

export const createPromotion = createAsyncThunk(
  "tournaments/createPromotion",
  async (tournamentPromotion, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPromotion,
      "tournament-promotions",
      getOpts(tournamentPromotion, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.post,
      `${tournamentPromotions}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const createExternalLink = createAsyncThunk(
  "tournaments/createExternalLink",
  async (externalLink, thunkAPI) => {
    let serializedData = getSerializedData(
      externalLink,
      "external-links",
      getOpts(externalLink, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.post,
      `${externalLinks}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const createDonationWatchItem = createAsyncThunk(
  "tournaments/createDonationWatchItem",
  async (donationWatchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      donationWatchItem,
      "donation-watch-items",
      getOpts(donationWatchItem, [...getIDRelationship("tournament")]),
    );

    return await getResponse(
      gsApi.post,
      `${donationWatchItems}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const createCustomerInvoiceWatchEmail = createAsyncThunk(
  "tournaments/createCustomerInvoiceWatchEmail",
  async (customerInvoiceWatchEmail, thunkAPI) => {
    let serializedData = getSerializedData(
      customerInvoiceWatchEmail,
      "customer-invoice-watch-emails",
      getOpts(customerInvoiceWatchEmail, [...getIDRelationship("tournament")]),
    );

    return await getResponse(
      gsApi.post,
      `${customerInvoiceWatchEmails}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const manuallyUpdateHandicaps = createAsyncThunk(
  "tournaments/manuallyUpdateHandicaps",
  async (settings, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      `${tournament}/${settings.id}/manually-update-handicaps`,
      settings,
      thunkAPI,
    );
  },
);

//save
export const updateDonationWatchItem = createAsyncThunk(
  "tournaments/updateDonationWatchItem",
  async (donationWatchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      donationWatchItem,
      "donation-watch-items",
      getOpts(donationWatchItem, [...getIDRelationship("tournament")]),
    );

    return await getResponse(
      gsApi.patch,
      `${donationWatchItems}/${donationWatchItem.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const updateCustomerInvoiceWatchEmail = createAsyncThunk(
  "tournaments/updateCustomerInvoiceWatchEmail",
  async (customerInvoiceWatchEmail, thunkAPI) => {
    let serializedData = getSerializedData(
      customerInvoiceWatchEmail,
      "customer-invoice-watch-emails",
      getOpts(customerInvoiceWatchEmail, [...getIDRelationship("tournament")]),
    );

    return await getResponse(
      gsApi.patch,
      `${customerInvoiceWatchEmails}/${customerInvoiceWatchEmail.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

//

export const saveTournament = createAsyncThunk(
  "tournaments/saveTournament",
  async (newTournament, thunkAPI) => {
    let serializedData = getSerializedData(
      newTournament,
      "tournaments",
      getOpts(newTournament, [...getOrganizationRelationship()]),
    );

    if (serializedData.data.relationships) {
      let userid =
        newTournament?.pointOfContact?.id ?? newTournament?.pointOfContact?.uid;
      if (userid?.includes?.("_")) {
        userid = userid?.split?.("_")?.[0];
      }
      if (newTournament?.pointOfContact) {
        const relationship = {
          data: {
            id: userid,
            type: "users",
          },
        };
        serializedData.data.relationships["point-of-contact"] = relationship;
      }
    }

    return await getResponse(
      gsApi.patch,
      `${tournament}/${newTournament.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const saveTournamentPromotions = createAsyncThunk(
  "tournaments/saveTournamentPromotions",
  async (tournamentPromotion, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPromotion,
      "tournament-promotions",
      getOpts(tournamentPromotion, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.patch,
      `${tournamentPromotions}/${tournamentPromotion.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const saveExternalLinks = createAsyncThunk(
  "tournaments/saveExternalLinks",
  async (externalLink, thunkAPI) => {
    let serializedData = getSerializedData(
      externalLink,
      "external-links",
      getOpts(externalLink, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.patch,
      `${externalLinks}/${externalLink.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

//delete

export const deleteDonationWatchItem = createAsyncThunk(
  "tournaments/deleteDonationWatchItem",
  async (watchID, thunkAPI) => {
    const response = await gsApi.delete(`${donationWatchItems}/${watchID}`, {
      data: {},
    });
    return response;
  },
);

export const deleteCustomerInvoiceWatchEmail = createAsyncThunk(
  "tournaments/deleteCustomerInvoiceWatchEmail",
  async (watchID, thunkAPI) => {
    const response = await gsApi.delete(
      `${customerInvoiceWatchEmails}/${watchID}`,
      {
        data: {},
      },
    );
    return response;
  },
);

export const deleteTournament = createAsyncThunk(
  "tournaments/deleteTournament",
  async (deleteTournament, thunkAPI) => {
    let serializedData = getSerializedData(
      deleteTournament,
      "tournaments",
      getOpts(deleteTournament, [...getOrganizationRelationship()]),
    );

    return await getResponse(
      gsApi.patch,
      `${tournament}/${deleteTournament.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

//finalize tournament

export const finalizeTournament = createAsyncThunk(
  "tournaments/finalizeTournament",
  async (tournamentID, thunkAPI) => {
    return await getResponse(
      gsApi.patch,
      `${tournament}/${tournamentID}/finalize`,
      {},
      thunkAPI,
    );
  },
);

export const undoFinalizeTournament = createAsyncThunk(
  "tournaments/undoFinalizeTournament",
  async (tournamentID, thunkAPI) => {
    return await getResponse(
      gsApi.patch,
      `${tournament}/${tournamentID}/undo-finalize`,
      {},
      thunkAPI,
    );
  },
);

//export

export const downloadTeamExport = createAsyncThunk(
  "tournaments/downloadTeamExport",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${id}/team-export`, {
      responseType: "arraybuffer",
    });
    return response;
  },
);

export const downloadTournamentDocument = createAsyncThunk(
  "rounds/downloadTournamentDocument",
  async (download, thunkAPI) => {
    const response = await gsApi.get(`${download.url}`, {
      responseType: "arraybuffer",
      data: download.data,
    });
    return { ...response, download: download };
  },
);

export const downloadTeamImportTemplate = createAsyncThunk(
  "tournaments/downloadTeamImportTemplate",
  async (currentTournament, thunkAPI) => {
    const response = await getResponse(
      gsApi.get,
      `${tournament}/${currentTournament.id}/import-team-templates`,
      { responseType: "arraybuffer" },
      thunkAPI,
    );
    return response;
  },
);

export const tournamentSlice = createSlice({
  name: "tournaments",
  initialState: {
    currentEvent: {},
    allEvents: [],
    pastEvents: [],
    upcomingEvents: [],
    draftEvents: [],
    listFilterStatus: { label: "All", value: "all" },
    currentTournament: {},
    loading: [],
    notifications: [],
    tournamentFormatTypes: [],
    tournamentExternalLinks: [],
    currentTournamentDonationWatchItems: [],
    currentDonationWatchItem: {},
    currentTournamentCustomerInvoiceWatchEmails: [],
    currentTournamentCustomerInvoiceWatchEmail: {},
    copySettings: {},
    emptyTournament: { name: "", organization: {}, numberOfTeams: 144 },
  },
  reducers: {
    setPastEvents: (state, action) => {
      state.pastEvents = action.payload;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.listFilterStatus = action.payload;
    },
    setCurrentTournament: (state, action) => {
      state.currentTournament = action.payload;
    },
    updateCurrentTournament: (state, action) => {
      state.currentTournament = {
        ...state.currentTournament,
        ...action.payload,
      };
    },
    updateCurrentTournamentRound: (state, action) => {
      let index = state.currentTournament.tournamentRounds?.findIndex?.(
        (tr) => tr.id === action.payload.id,
      );
      if (index !== -1) {
        state.currentTournament.tournamentRounds[index] = action.payload;
      }
    },
    setAuctionLink: (state, action) => {
      let index = state.tournamentExternalLinks.findIndex(
        (l) => l.linkType === "event_auction",
      );
      if (index === -1) {
        state.tournamentExternalLinks.push({
          linkType: "event_auction",
          externalLink: action.payload,
        });
      } else {
        state.tournamentExternalLinks[index] = {
          ...state.tournamentExternalLinks[index],
          externalLink: action.payload,
        };
      }
    },
    setCurrentDonationWatchItem: (state, action) => {
      state.currentDonationWatchItem = action.payload;
    },
    setCurrentTournamentCustomerInvoiceWatchEmail: (state, action) => {
      state.currentTournamentCustomerInvoiceWatchEmail = action.payload;
    },
    setCurrentTournamentCustomerInvoiceWatchEmails: (state, action) => {
      state.currentTournamentCustomerInvoiceWatchEmails = action.payload;
    },
    setTournamentNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    clearTournamentNotifications: (state, action) => {
      state.notifications = [];
    },
    setCopySettings: (state, action) => {
      state.copySettings = action.payload;
    },
  },
  extraReducers: {
    ...getThunkResponse(getTournamentDonationWatchItems, (state, action) => {
      state.currentTournamentDonationWatchItems = action.payload;
    }),
    ...getThunkResponse(getTournamentDonationWatchItem, (state, action) => {
      state.currentDonationWatchItem = action.payload;
    }),
    ...getThunkResponse(
      getTournamentCustomerInvoiceWatchEmails,
      (state, action) => {
        state.currentTournamentCustomerInvoiceWatchEmails = action.payload;
      },
    ),
    ...getThunkResponse(
      getTournamentCustomerInvoiceWatchEmail,
      (state, action) => {
        state.currentTournamentCustomerInvoiceWatchEmail = action.payload;
      },
    ),
    ...createThunkResponse(
      createDonationWatchItem,
      (state, action) => {
        state.currentDonationWatchItem = action.payload;
      },
      "Donation Watch Item",
    ),
    ...saveThunkResponse(
      updateDonationWatchItem,
      (state, action) => {
        state.currentDonationWatchItem = action.payload;
      },
      "Donation Watch Item",
    ),
    ...deleteThunkResponse(
      deleteDonationWatchItem,
      (state, action) => {},
      "Donation Watch Item",
      shortDelay,
    ),
    ...createThunkResponse(
      createCustomerInvoiceWatchEmail,
      (state, action) => {
        state.currentTournamentCustomerInvoiceWatchEmail = action.payload;
      },
      "Invoice Watch Email",
    ),
    ...saveThunkResponse(
      updateCustomerInvoiceWatchEmail,
      (state, action) => {
        state.currentTournamentCustomerInvoiceWatchEmail = action.payload;
      },
      "Invoice Watch Email",
    ),
    ...deleteThunkResponse(
      deleteCustomerInvoiceWatchEmail,
      (state, action) => {},
      "Invoice Watch Email",
      shortDelay,
    ),
    //upcoming
    ...getThunkResponse(searchUpcomingTournaments, (state, action) => {
      state.upcomingEvents = action.payload;
    }),
    //past
    ...getThunkResponse(searchPastTournaments, (state, action) => {
      state.pastEvents = action.payload;
    }),
    //draft
    ...getThunkResponse(searchDraftTournaments, (state, action) => {
      state.draftEvents = action.payload;
    }),
    //search all
    ...getThunkResponse(searchAllEvents, (state, action) => {
      if (action?.meta?.arg?.page?.page === 1) {
        state.allEvents = action.payload;
        return;
      }
      state.allEvents = [...state.allEvents, ...action.payload];
    }),
    //tournament detail
    ...getThunkResponse(getTournament, (state, action) => {
      state.currentTournament = action.payload;
    }),
    //tournament point of contact
    ...getThunkResponse(getTournamentPointOfContact, (state, action) => {
      state.currentTournament.pointOfContact = action.payload;
    }),
    //tournament format types
    ...getThunkResponse(getTournamentFormatTypes, (state, action) => {
      state.tournamentFormatTypes = action.payload;
    }),
    //tournament external links
    ...getThunkResponse(getTournamentExternalLinks, (state, action) => {
      state.tournamentExternalLinks = action.payload;
    }),
    //
    ...createThunkResponse(
      createExternalLink,
      (state, action) => {},
      "External Link",
    ),
    ...saveThunkResponse(
      saveExternalLinks,
      (state, action) => {},
      "External Link",
    ),
    //create tournament
    ...createThunkResponse(
      createTournament,
      (state, action) => {
        state.draftEvents = [action.payload, ...state.draftEvents];
        state.allEvents = [action.payload, ...state.allEvents];
        state.currentTournament = action.payload;
      },
      "Tournament",
    ),
    ...createThunkResponse(
      copyTournament,
      (state, action) => {
        state.draftEvents = [action.payload, ...state.draftEvents];
        state.allEvents = [action.payload, ...state.allEvents];
        state.currentTournament = action.payload;
      },
      "Copy",
      mediumDelay,
    ),
    //save tournament
    ...saveThunkResponse(
      saveTournament,
      (state, action) => {
        state.currentTournament = {
          ...state.currentTournament,
          ...action.payload,
        };
        const index = state.allEvents?.findIndex?.(
          (ev) => ev.id === action.payload.id,
        );
        if (index >= 0) {
          state.allEvents[index] = action.payload;
        }

        const dindex = state.draftEvents?.findIndex?.(
          (ev) => ev.id === action.payload.id,
        );
        if (dindex >= 0) {
          state.draftEvents[dindex] = action.payload;
        }

        const uindex = state.upcomingEvents?.findIndex?.(
          (ev) => ev.id === action.payload.id,
        );
        if (uindex >= 0) {
          state.draftEvents[uindex] = action.payload;
        }

        const pindex = state.upcomingEvents?.findIndex?.(
          (ev) => ev.id === action.payload.id,
        );
        if (pindex >= 0) {
          state.draftEvents[pindex] = action.payload;
        }
      },
      "Tournament",
    ),
    ...deleteThunkResponse(
      deleteTournament,
      (state, action) => {
        state.currentTournament = {};
      },
      "Tournament",
    ),
    //create tournament Promotions
    ...createThunkResponse(
      createPromotion,
      (state, action) => {
        state.currentTournament.tournamentPromotion = action.payload;
      },
      "Tournament Promotion",
    ),
    //save tournament Promotions
    ...saveThunkResponse(
      saveTournamentPromotions,
      (state, action) => {
        state.currentTournament.tournamentPromotion = action.payload;
      },
      "Tournament Promotion",
    ),
    //export team list
    ...fileDownloadThunkResponse(
      downloadTeamExport,
      (state, action) => `${state.currentTournament.name}-team-export.xlsx`,
    ),
    //export team import template
    ...fileDownloadThunkResponse(
      downloadTeamImportTemplate,
      (state, action) => `${state.currentTournament.name}-team-import.xlsx`,
    ),
    //finalize tournament
    ...saveThunkResponse(
      finalizeTournament,
      (state, action) => {
        state.currentTournament = action.payload;
      },
      "Tournament",
    ),
    ...fileDownloadThunkResponse(
      downloadTournamentDocument,
      (state, action) => {
        return `${action?.meta?.arg?.name}${action.meta.arg.ext}`;
      },
    ),
    ...thunkResponse(
      manuallyUpdateHandicaps,
      (state, action) => {
        state.currentTournament = action.payload;
      },
      "Manually Update Handicaps",
      {
        pendingDelay: mediumDelay,
        fulfilledDelay: mediumDelay,
        rejectedDelay: extraLongDelay,
        pendingText: "Updating Handicaps...",
        fulfilledText: "Handicaps have been updated",
      },
    ),
  },
});

export const {
  setPastEvents,
  setAllEvents,
  setFilterStatus,
  setCurrentTournament,
  setAuctionLink,
  setTournamentNotifications,
  clearTournamentNotifications,
  updateCurrentTournamentRound,
  setCurrentDonationWatchItem,
  setCurrentTournamentCustomerInvoiceWatchEmail,
  setCurrentTournamentCustomerInvoiceWatchEmails,
  setCopySettings,
  updateCurrentTournament,
} = tournamentSlice.actions;

export const selectPastEvents = (state) => state.tournaments.pastEvents;

export const selectUpcomingEvents = (state) => state.tournaments.upcomingEvents;

export const selectDraftEvents = (state) => state.tournaments.draftEvents;

export const selectAllEvents = (state) => state.tournaments.allEvents;

export const selectListFilterStatus = (state) =>
  state.tournaments.listFilterStatus;

export const selectLoading = (state) => state.tournaments.loading;

export const selectCurrentTournament = (state) =>
  state.tournaments.currentTournament;

export const selectTournamentNotifications = (state) =>
  state.tournaments.notifications;

export const selectEmptyTournament = (state) =>
  state.tournaments.emptyTournament;

export const selectTournamentFormatTypes = (state) =>
  state.tournaments.tournamentFormatTypes;

export const selectTournamentExternalLinks = (state) =>
  state.tournaments.tournamentExternalLinks;

export const selectCurrentDonationWatchItem = (state) =>
  state.tournaments.currentDonationWatchItem;

export const selectCurrentTournamentDonationWatchItems = (state) =>
  state.tournaments.currentTournamentDonationWatchItems;

export const selectCurrentCustomerInvoiceWatchEmail = (state) =>
  state.tournaments.currentTournamentCustomerInvoiceWatchEmail;

export const selectCurrentCustomerInvoiceWatchEmails = (state) =>
  state.tournaments.currentTournamentCustomerInvoiceWatchEmails;

export const selectCopySettings = (state) => state.tournaments.copySettings;

export default tournamentSlice.reducer;

function getOrganizationRelationship() {
  const relationships = [];
  relationships.push({
    name: "organization",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getTournamentRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournament",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
