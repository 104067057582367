
import React from "react";
import { GSActionBar, GSEmptyList, GSItemList, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

import "./tournament-sponsor-exports.scss"
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";
import { useState } from "react";
import { useDocuments } from "../../../forms/TournamentPrintoutForms";
import NavigationListItem from "../../navigation-list-item";
import { useDispatch, useSelector } from "react-redux";
import { tournament } from "../../../app/api";
import { downloadTournamentDocument, clearTournamentNotifications, selectTournamentNotifications } from "../../../reducers/tournamentSlice";


const TournamentSponsorExports = (props) => {
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/sponsors/documents");

  const [searchParams] = useSearchParams();

  const sponsorDocuments = useDocuments(match).find(
    (docs) => docs.label === "Sponsor Documents"
  );

  const [search, setSearch] = useState((searchParams?.get("search") ?? ""));

  const navigate = useNavigate();
  const notifications = useSelector(selectTournamentNotifications);

  const clearSearch = () => {
    setSearch("");
  }

  const leftNavigation = () => {
    navigate(-1);
  };

  const gotoCreateSponsor = () => {
    navigate(`/${match.params.tournamentID}/sponsors/add`);
  }
  const gotoDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`);
  }

  const getEmptySearchMessage = () => {
    let availableActions = [
      {
        title: "Clear Search",
        onClick: clearSearch,
        type: "light-grey no-wrap",
      },
    ];
    availableActions.push(
      {
        title: "Search All Documents",
        onClick: gotoDocs,
        buttonIcon: faSearch,
        type: "light-grey no-wrap"
      }
    );
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for…"
        detail="Try changing the search term."
        actions={availableActions}
      />
    );
  };

  const getEmptySponsorsMessage = () => {
    return (
      <GSEmptyList
        title="Sponsor Documents"
        detail="All sponsor specific documents will be displayed here."
        actions={[{
          title: "Add Sponsor",
          buttonIcon: faPlus,
          onClick: gotoCreateSponsor,
          type: "black no-wrap"
        }]}
      />
    );
  }

  const getMatchString = (doc) => {
    let matchString = `${doc.label} ${doc.details}`.toLowerCase();
    if (doc.documents) {
      doc.documents.forEach?.(d => { matchString = `${matchString} ${getMatchString(d)}` });
    }
    return matchString;
  }

  const searchMatch = (doc) => {
    return getMatchString(doc)?.includes?.(search?.toLowerCase());
  }

  const getFilteredDocs = (group) => {
    let filtered = group?.documents?.filter?.((doc) =>
      searchMatch(doc)
    );
    return (filtered ?? []);
  }

  const getNavigation = () => {
    return {
      title: `Sponsor Documents`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const itemSelected = (item) => {
    if (item.disabled) {
      return;
    }
    if (item.isDownload) {
      let document = {
        url: `${tournament}/${match.params.tournamentID}/sponsor-images`,
        name: `${match.params.tournamentID}_sponsor-images`,
        ext: ".zip",
      };
      dispatch(downloadTournamentDocument(document));
    } else {
      const nestedSearch = getFilteredDocs(item);
      if (nestedSearch?.length === 0) {
        navigate(`${item.url}`);
      } else {
        navigate(`${item.url}?search=${search}`);
      }
    }
  }

  const getContent = () => {
    return <gs-form>
      <GSActionBar type="form-header" header="Sponsor Documents"></GSActionBar>
      <SearchBar search={search} setSearch={setSearch} placeholder="Search Documents..."></SearchBar>
      <GSItemList emptyMessage={search !== "" ? getEmptySearchMessage() : getEmptySponsorsMessage()} type="vertical selectable" items={getFilteredDocs(sponsorDocuments)} listItem={(item) => (<NavigationListItem page={item} />)} itemSelected={itemSelected}></GSItemList>
    </gs-form>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-sponsor-exports>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-sponsor-exports>
  );
};

export default TournamentSponsorExports;
