import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";

const TournamentPackageWatchItem = (props) => {
  const { email } = props;

  const iconSection = {
    sectionItems: [
      {
        type: "primart",
        value: <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>,
      },
    ],
  };

  const emailSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: email,
      },
    ],
  };

  const iconColumn = {
    sections: [
      {
        ...iconSection,
      },
    ],
  };

  const emailColumn = {
    fit: "stretch",
    sections: [
      {
        ...emailSection,
      },
    ],
  };

  const getGroup = () => {
    let group = [{...iconColumn},{ ...emailColumn }];
    return group;
  };

  return (
    <tournament-package-watch-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-package-watch-item>
  );
};

export default TournamentPackageWatchItem;
