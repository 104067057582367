export function createFormFromFile(file) {
  const data = new FormData();
  data.append("file", file);
  return data;
}

export function createFormFromBlob(blob, type) {
  const data = new FormData();
  if (blob) {
    data.append("file", blob, type);
  }

  return data;
}

export const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const shuffleIndex = (currentIndex, sourceIndex, destIndex) =>
  (currentIndex < sourceIndex && currentIndex < destIndex) ||
  (currentIndex > sourceIndex && currentIndex > destIndex)
    ? currentIndex
    : currentIndex === sourceIndex
    ? destIndex
    : currentIndex > sourceIndex
    ? currentIndex - 1
    : currentIndex + 1;

export const generateCode = () => {
  let newCode = Math.random()
    .toString(36)
    .substring(2, 10)
    .toUpperCase();
  return newCode
};


export const openExternalLink = (link) => {
  let validLink = link.startsWith("http") ? link : `http://${link}`
  window.open(validLink)
}