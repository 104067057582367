import React, { useEffect } from "react";
import "./tms-dashboard.scss";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import TMSDashboardTournaments from "./tms-dashboard-tournaments";
import {
  searchUpcomingTournaments,
  searchPastTournaments,
  searchDraftTournaments
} from "../../reducers/tournamentSlice";

const TMSDashboard = (props) => {
  const { currentUser } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.id) {
      dispatch(searchUpcomingTournaments(currentUser?.id));
      dispatch(searchPastTournaments(currentUser?.id));
      dispatch(searchDraftTournaments(currentUser?.id));
    }
  }, [dispatch, currentUser]);
  return (
    <tms-dashboard>
        <TMSDashboardTournaments></TMSDashboardTournaments>
    </tms-dashboard>
  );
};

const mapStateToProps = (state) => ({
  upcomingEvents: state.tournaments.upcomingEvents,
  currentUser: state.app.loggedInUser,
});
export default connect(mapStateToProps)(TMSDashboard);
