import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  accessGroups,
  addOnPackageItems,
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  packageWatchItems,
  playerPackageItems,
  registrationCustomFields,
  registrationFieldSets,
  saveThunkResponse,
  skinsAddOnPackageItems,
  sponsorPackageItems,
  teamPackageItems,
  technologySponsorPackageItems,
  tournament,
  tournamentPackageItems,
  TournamentPackageRegistrationFieldSets,
  tournamentPackages,
} from "../app/api";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
} from "../helpers/JSONapi";

export const getTournamentPackages = createAsyncThunk(
  "packages/getTournamentPackages",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/tournament-packages`
    );
    return response;
  }
);

export const getTeamPackageItems = createAsyncThunk(
  "packages/getTeamPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/team-package-items`
    );
    return response;
  }
);

export const getPlayerPackageItems = createAsyncThunk(
  "packages/getPlayerPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/player-package-items`
    );
    return response;
  }
);

export const getSponsorPackageItems = createAsyncThunk(
  "packages/getSponsorPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/sponsor-package-items`
    );
    return response;
  }
);

export const getTechnologySponsorPackageItems = createAsyncThunk(
  "packages/getTechnologySponsorPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/technology-sponsor-package-items`
    );
    return response;
  }
);

export const getSkinsAddOnPackageItems = createAsyncThunk(
  "packages/getSkinsAddOnPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/skins-add-on-package-items`
    );
    return response;
  }
);

export const getAddOnPackageItems = createAsyncThunk(
  "packages/getAddOnPackageItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/add-on-package-items`
    );
    return response;
  }
);

export const getPackageWatchItems = createAsyncThunk(
  "packages/getPackageWatchItems",
  async (packageID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentPackages}/${packageID}/package-watch-items`
    );
    return response;
  }
);

export const getTournamentPackage = createAsyncThunk(
  "packages/getTournamentPackage",
  async (packageID, thunkAPI) => {
    const response = await gsApi.get(`${tournamentPackages}/${packageID}`);
    return response;
  }
);

export const getPackageItems = createAsyncThunk(
  "packages/getPackageItems",
  async (packageID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentPackages}/${packageID}/tournament-package-items`
    );
    return response;
  }
);

export const getPackageWatchItem = createAsyncThunk(
  "packages/getPackageWatchItem",
  async (watchitemId, thunkAPI) => {
    const response = await gsApi.get(`${packageWatchItems}/${watchitemId}`);
    return response;
  }
);
export const getTournamentForms = createAsyncThunk(
  "packages/getTournamentForms",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/registration-field-sets`
    );
    return response;
  }
);

export const getTournamentForm = createAsyncThunk(
  "packages/getTournamentForm",
  async (formID, thunkAPI) => {
    const response = await gsApi.get(`${registrationFieldSets}/${formID}`);
    return response;
  }
);

export const getTournamentPackageForms = createAsyncThunk(
  "packages/getTournamentPackageForms",
  async (tournamentPackageID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentPackages}/${tournamentPackageID}/tournament-package-registration-field-sets`
    );
    return response;
  }
);

export const getTournamentPackageFormFields = createAsyncThunk(
  "packages/getTournamentPackageFormFields",
  async (tournamentPackageFormID, thunkAPI) => {
    const response = await gsApi.get(
      `${registrationFieldSets}/${tournamentPackageFormID}/registration-custom-fields`
    );
    return response;
  }
);

export const getRegistrationCustomField = createAsyncThunk(
  "packages/getRegistrationCustomField",
  async (registartionCustomFieldID, thunkAPI) => {
    const response = await gsApi.get(
      `${registrationCustomFields}/${registartionCustomFieldID}`
    );
    return response;
  }
);

export const getPackageAccessGroup = createAsyncThunk(
  "packages/getPackageAccessGroup",
  async (accessGroupID, thunkAPI) => {
    const response = await gsApi.get(`${accessGroups}/${accessGroupID}`);
    return response;
  }
);

export const getPackageRounds = createAsyncThunk(
  "packages/getPackageRounds",
  async (packageID, thunkAPI) => {
    const response = await gsApi.get(`${tournamentPackages}/${packageID}/tournament-rounds`);
    return response
  }
)

//create

export const createTournamentPackage = createAsyncThunk(
  "packages/createTournamentPackage",
  async (tournamentPackage, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackage,
      "tournament-packages",
      getOpts(tournamentPackage)
    );
    return await getResponse(
      gsApi.post,
      `${tournamentPackages}`,
      serializedData,
      thunkAPI
    );
  }
);

export const createTournamentPackageItem = createAsyncThunk(
  "packages/createTournamentPackageItem",
  async (tournamentPackageItem, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackageItem,
      "tournament-package-items",
      getOpts(tournamentPackageItem, [
        ...getIDRelationship("tournamentPackage"),
        ...getIDRelationship("packageItem"),
      ])
    );
    let response = await getResponse(
      gsApi.post,
      `${tournamentPackageItems}`,
      serializedData,
      thunkAPI
    );

    return response
  }
);

export const createPackageItem = createAsyncThunk(
  "packages/createPackageItem",
  async (packageItem, thunkAPI) => {
    const endpoints = [
      { name: "team", url: teamPackageItems },
      { name: "player", url: playerPackageItems },
      { name: "sponsor", url: sponsorPackageItems },
      { name: "technology-sponsor", url: technologySponsorPackageItems },
      { name: "skins", url: skinsAddOnPackageItems },
      { name: "add-on", url: addOnPackageItems },
    ];
    const endpoint = endpoints.find((ep) => ep.name === packageItem?.itemType);
    if (endpoint) {
      let serializedData = getSerializedData(
        packageItem,
        "tournament-package-items",
        getOpts(packageItem, [...getIDRelationship("tournament")])
      );
      return await getResponse(
        gsApi.post,
        `${endpoint.url}`,
        serializedData,
        thunkAPI
      );
    }
  }
);

export const createPackageWatchItem = createAsyncThunk(
  "packages/createPackageWatchItem",
  async (watchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      watchItem,
      "package-watch-items",
      getOpts(watchItem, [...getIDRelationship("tournamentPackage")])
    );
    return await getResponse(
      gsApi.post,
      `${packageWatchItems}`,
      serializedData,
      thunkAPI
    );
  }
);

export const createPackageForm = createAsyncThunk(
  "packages/createPackageForm",
  async (packageForm, thunkAPI) => {
    let serializedData = getSerializedData(
      packageForm,
      "registration-field-sets",
      getOpts(packageForm, [...getIDRelationship("tournament")])
    );
    return await getResponse(
      gsApi.post,
      `${registrationFieldSets}`,
      serializedData,
      thunkAPI
    );
  }
);

export const createPackageFormItem = createAsyncThunk(
  "packages/createPackageFormItem",
  async (packageFormField, thunkAPI) => {
    let serializedData = getSerializedData(
      packageFormField,
      "registration-custom-fields",
      getOpts(packageFormField, [...getIDRelationship("registrationFieldSet")])
    );
    return await getResponse(
      gsApi.post,
      `${registrationCustomFields}`,
      serializedData,
      thunkAPI
    );
  }
);

export const createTournamentPackageFieldSet = createAsyncThunk(
  "packages/createTournamentPackageFieldSet",
  async (tournamentPackageFieldSet, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackageFieldSet,
      "tournament-package-registration-field-sets",
      getOpts(tournamentPackageFieldSet, [
        ...getIDRelationship("registrationFieldSet"),
        ...getIDRelationship("tournamentPackage"),
      ])
    );
    return await getResponse(
      gsApi.post,
      `${TournamentPackageRegistrationFieldSets}`,
      serializedData,
      thunkAPI
    );
  }
);

//save

export const saveTournamentPackage = createAsyncThunk(
  "packages/saveTournamentPackage",
  async (tournamentPackage, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackage,
      "tournament-packages",
      getOpts(tournamentPackage, [...getIDRelationship("accessGroup"),...getIDRelationship("tournamentRounds")])
    );
    return await getResponse(
      gsApi.patch,
      `${tournamentPackages}/${tournamentPackage.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTournamentPackageItem = createAsyncThunk(
  "packages/saveTournamentPackageItem",
  async (tournamentPackageItem, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackageItem,
      "tournament-package-items",
      getOpts(tournamentPackageItem)
    );
    return await getResponse(
      gsApi.patch,
      `${tournamentPackageItems}/${tournamentPackageItem.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const savePackageItem = createAsyncThunk(
  "packages/savePackageItem",
  async (packageItem, thunkAPI) => {
    const endpoints = [
      { name: "team", url: teamPackageItems },
      { name: "player", url: playerPackageItems },
      { name: "sponsor", url: sponsorPackageItems },
      { name: "technology-sponsor", url: technologySponsorPackageItems },
      { name: "skins", url: skinsAddOnPackageItems },
      { name: "add-on", url: addOnPackageItems },
    ];
    const endpoint = endpoints.find((ep) => ep.name === packageItem?.itemType);
    if (endpoint) {
      let serializedData = getSerializedData(
        packageItem,
        "tournament-package-items",
        getOpts(packageItem, [...getIDRelationship("tournament")])
      );
      return await getResponse(
        gsApi.patch,
        `${endpoint.url}/${packageItem.id}`,
        serializedData,
        thunkAPI
      );
    }
  }
);

export const savePackageWatchItem = createAsyncThunk(
  "packages/savePackageWatchItem",
  async (watchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      watchItem,
      "package-watch-items",
      getOpts(watchItem)
    );
    return await getResponse(
      gsApi.patch,
      `${packageWatchItems}/${watchItem.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const savePackageForm = createAsyncThunk(
  "packages/savePackageForm",
  async (packageForm, thunkAPI) => {
    let serializedData = getSerializedData(
      packageForm,
      "registration-field-sets",
      getOpts(packageForm, [...getIDRelationship("tournament")])
    );
    return await getResponse(
      gsApi.patch,
      `${registrationFieldSets}/${packageForm.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const savePackageFormItem = createAsyncThunk(
  "packages/savePackageFormItem",
  async (packageFormField, thunkAPI) => {
    let serializedData = getSerializedData(
      packageFormField,
      "registration-custom-fields",
      getOpts(packageFormField, [...getIDRelationship("registrationFieldSet")])
    );
    return await getResponse(
      gsApi.patch,
      `${registrationCustomFields}/${packageFormField.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveTournamentPackageFieldSet = createAsyncThunk(
  "packages/saveTournamentPackageFieldSet",
  async (tournamentPackageFieldSet, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPackageFieldSet,
      "tournament-package-registration-field-sets",
      getOpts(tournamentPackageFieldSet, [
        ...getIDRelationship("registrationFieldSet"),
        ...getIDRelationship("tournamentPackage"),
      ])
    );
    return await getResponse(
      gsApi.patch,
      `${TournamentPackageRegistrationFieldSets}/${tournamentPackageFieldSet.id}`,
      serializedData,
      thunkAPI
    );
  }
);

//delete

export const deleteTournamentPackage = createAsyncThunk(
  "packages/deleteTournamentPackage",
  async (packageID, thunkAPI) => {
    const response = await gsApi.delete(`${tournamentPackages}/${packageID}`, {
      data: {},
    });
    return response;
  }
);

export const deleteTournamentPackageItem = createAsyncThunk(
  "packages/deleteTournamentPackageItem",
  async (packageItemID, thunkAPI) => {
    const response = await gsApi.delete(
      `${tournamentPackageItems}/${packageItemID}`,
      { data: {} }
    );
    return response;
  }
);

export const deletePackageItem = createAsyncThunk(
  "packages/deletePackageItem",
  async (packageItem, thunkAPI) => {
    const endpoints = [
      { name: "team", url: teamPackageItems },
      { name: "player", url: playerPackageItems },
      { name: "sponsor", url: sponsorPackageItems },
      { name: "technology-sponsor", url: technologySponsorPackageItems },
      { name: "skins", url: skinsAddOnPackageItems },
      { name: "add-on", url: addOnPackageItems },
    ];
    const endpoint = endpoints.find((ep) => ep.name === packageItem?.itemType);

    const response = await gsApi.delete(`${endpoint.url}/${packageItem.id}`, {
      data: {},
    });
    return response;
  }
);

export const deletePackageWatchItem = createAsyncThunk(
  "packages/deletePackageWatchItem",
  async (watchItemID, thunkAPI) => {
    const response = await gsApi.delete(`${packageWatchItems}/${watchItemID}`, {
      data: {},
    });
    return response;
  }
);

export const deleteTournamentPackageFieldSet = createAsyncThunk(
  "packages/deleteTournamentPackageFieldSet",
  async (tournamentPackageFieldSetID, thunkAPI) => {
    const response = await gsApi.delete(
      `${TournamentPackageRegistrationFieldSets}/${tournamentPackageFieldSetID}`,
      {
        data: {},
      }
    );
    return response;
  }
);

export const deleteRegistrationCustomField = createAsyncThunk(
  "packages/deleteRegistrationCustomField",
  async (registrationCustomFieldID, thunkAPI) => {
    const response = await gsApi.delete(
      `${registrationCustomFields}/${registrationCustomFieldID}`,
      {
        data: {},
      }
    );
    return response;
  }
);

//replace all occurences of package with the name of your new slice
export const packageSlice = createSlice({
  name: "packages",
  initialState: {
    tournamentPackages: [],
    currentTournamentPackage: {},
    currentTournamentPackageItems: [],
    currentTournamentPackageItem: {},
    teamPackageItems: [],
    playerPackageItems: [],
    sponsorPackageItems: [],
    technologySponsorPackageItems: [],
    skinsPackageItems: [],
    addOnPackageItems: [],
    packageWatchItems: [],
    currentPackageWatchItem: {},
    tournamentForms: [],
    currentPackageForms: [],
    currentPackageForm: {},
    currentPackageFormFields: [],
    currentCustomField: {},
    currentPackageAccessGroup: {},
    currentPackageRounds: [],
    notifications: [],
    loading: [],
    minTechSponsorPrice: 750
  },
  //be sure to export any reducers on line 65
  reducers: {
    setCurrentPackageAccessGroup: (state, action) => {
      state.currentPackageAccessGroup = action.payload;
    },
    setPackages: (state, action) => {
      state.tournamentPackages = action.payload;
    },
    setCurrentPackage: (state, action) => {
      state.currentTournamentPackage = action.payload;
    },
    setCurrentPackageItems: (state, action) => {
      state.currentTournamentPackageItems = action.payload;
    },
    addPackageItem: (state, action) => {
      state.currentTournamentPackageItems.unshift(action.payload);
    },
    setCurrentPackageItem: (state, action) => {
      state.currentTournamentPackageItem = action.payload;
    },
    setCurrentPackageRounds: (state, action) => {
      state.currentPackageRounds = action.payload;
    },
    findCurrentPackageItemWithId: (state, action) => {
      const packageItems = getAllPackageItems(state);
      state.currentTournamentPackageItem = packageItems.find(
        (pi) => pi.id === action.payload
      );
    },
    setWatchItems: (state, action) => {
      state.packageWatchItems = action.payload;
    },
    setCurrentWatchItem: (state, action) => {
      state.currentPackageWatchItem = action.payload;
    },
    updatePackageWatchItem: (state, action) => {
      let index = state.packageWatchItems?.findIndex?.(
        (pwi) => pwi.tempID === action.payload.tempID
      );
      if (index !== -1) {
        state.packageWatchItems[index] = action.payload;
      }
    },
    setCurrentPackageForms: (state, action) => {
      state.currentPackageForms = action.payload;
    },
    updateCurrentPackageForm: (state, action) => {
      let index = state.currentPackageForms?.findIndex?.(
        (cpf) =>
          (cpf.id ?? cpf.tempID) ===
          (action.payload.id ?? action.payload.tempID)
      );
      if (index !== -1) {
        state.currentPackageForms[index] = action.payload;
      }
    },
    setCurrentPackageForm: (state, action) => {
      state.currentPackageForm = action.payload;
    },
    setCurrentPackageFormFields: (state, action) => {
      state.currentPackageFormFields = action.payload;
    },
    setCurrentPackageFormField: (state, action) => {
      if (state.currentPackageFormFields.length > action?.payload?.position) {
        state.currentPackageFormFields[action.payload.position] =
          action.payload;
      }
    },
    setCurrentCustomField: (state, action) => {
      state.currentCustomField = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    //tournament packages
    ...getThunkResponse(getTournamentPackages, (state, action) => {
      state.tournamentPackages = action.payload;
    }),
    //tournament package
    ...getThunkResponse(getTournamentPackage, (state, action) => {
      state.currentTournamentPackage = action.payload;
    }),
    //tournament package selected items
    ...getThunkResponse(getPackageItems, (state, action) => {
      state.currentTournamentPackageItems = action.payload;
    }),
    //tournament team package items
    ...getThunkResponse(getTeamPackageItems, (state, action) => {
      state.teamPackageItems = action.payload;
    }),
    //tournament player package items
    ...getThunkResponse(getPlayerPackageItems, (state, action) => {
      state.playerPackageItems = action.payload;
    }),
    //tournament sponsor package items
    ...getThunkResponse(getSponsorPackageItems, (state, action) => {
      state.sponsorPackageItems = action.payload;
    }),
    //tournament tech sponsor package items
    ...getThunkResponse(getTechnologySponsorPackageItems, (state, action) => {
      state.technologySponsorPackageItems = action.payload;
    }),
    //tournament skins package items
    ...getThunkResponse(getSkinsAddOnPackageItems, (state, action) => {
      state.skinsPackageItems = action.payload;
    }),
    //tournament add on package items
    ...getThunkResponse(getAddOnPackageItems, (state, action) => {
      state.addOnPackageItems = action.payload;
    }),
    //tournament watch items package items
    ...getThunkResponse(getPackageWatchItems, (state, action) => {
      state.packageWatchItems = action.payload;
    }),
    //tournament forms
    ...getThunkResponse(getTournamentForms, (state, action) => {
      state.tournamentForms = action.payload;
    }),
    //registration form
    ...getThunkResponse(getTournamentForm, (state, action) => {
      state.currentPackageForm = action.payload;
    }),
    //get package forms
    ...getThunkResponse(getTournamentPackageForms, (state, action) => {
      state.currentPackageForms = action.payload;
    }),
    //get form fields
    ...getThunkResponse(getTournamentPackageFormFields, (state, action) => {
      state.currentPackageFormFields = action.payload;
    }),
    //get watch item
    ...getThunkResponse(getPackageWatchItem, (state, action) => {
      state.currentPackageWatchItem = action.payload;
    }),
    //create package
    ...createThunkResponse(
      createTournamentPackage,
      (state, action) => {},
      "Tournament Package"
    ),
    //save package
    ...saveThunkResponse(
      saveTournamentPackage,
      (state, action) => {},
      "Tournament Package"
    ),
    //remove package
    ...deleteThunkResponse(
      deleteTournamentPackage,
      (state, action) => {},
      "Tournament Package"
    ),
    //create package item
    ...createThunkResponse(
      createPackageItem,
      (state, action) => {},
      "Package Item"
    ),
    //remove tournament package item
    ...deleteThunkResponse(
      deleteTournamentPackageItem,
      (state, action) => {},
      "Tournament Package Item"
    ),
    //save package item
    ...saveThunkResponse(
      savePackageItem,
      (state, action) => {},
      "Package Item"
    ),
    //remove package item
    ...deleteThunkResponse(
      deletePackageItem,
      (state, action) => {},
      "Package Item"
    ),
    //save form
    ...saveThunkResponse(
      savePackageForm,
      (state, action) => {},
      "Package Form"
    ),
    //create form
    ...createThunkResponse(
      createPackageForm,
      (state, action) => {},
      "Package Form"
    ),
    //create package item
    ...createThunkResponse(
      createTournamentPackageItem,
      (state, action) => {
        state.currentTournamentPackageItems.unshift(action.payload);
      },
      "Tournament Package Item"
    ),
    ...saveThunkResponse(
      saveTournamentPackageItem,
      (state, action) => {},
      "Package Item"
    ),
    //create watch item
    ...createThunkResponse(
      createPackageWatchItem,
      (state, action) => {},
      "Package Watch Item"
    ),
    //create package form item
    ...createThunkResponse(
      createPackageFormItem,
      (state, action) => {},
      "Custom Field"
    ),
    ...saveThunkResponse(
      savePackageFormItem,
      (state, action) => {},
      "Custom Field"
    ),
    //save watch Item
    ...saveThunkResponse(
      savePackageWatchItem,
      (state, action) => {},
      "Package Watch Item"
    ),
    //delete watch item
    ...deleteThunkResponse(
      deletePackageWatchItem,
      (state, action) => {},
      "Package Watch Item"
    ),
    //tournament package field sets
    ...saveThunkResponse(
      saveTournamentPackageFieldSet,
      (state, action) => {},
      "Package Form"
    ),
    ...createThunkResponse(
      createTournamentPackageFieldSet,
      (state, action) => {},
      "Package Form"
    ),
    ...deleteThunkResponse(
      deleteTournamentPackageFieldSet,
      (state, action) => {},
      "Package Form"
    ),
    //custom fields
    ...getThunkResponse(getRegistrationCustomField, (state, action) => {
      state.currentCustomField = action.payload;
    }),
    ...deleteThunkResponse(
      deleteRegistrationCustomField,
      (state, action) => {},
      "Custom Field"
    ),

    //package access group
    ...getThunkResponse(getPackageAccessGroup, (state, action) => {
      state.currentPackageAccessGroup = action.payload;
    }),

    //package rounds
    ...getThunkResponse(getPackageRounds, (state, action) => {state.currentPackageRounds = action.payload})

    //end of extra reducers
  },
});

export const {
  setCurrentPackageAccessGroup,
  setpackages,
  setCurrentPackage,
  setCurrentPackageItem,
  setCurrentPackageItems,
  setCurrentPackageForms,
  updateCurrentPackageForm,
  setCurrentPackageForm,
  setCurrentPackageFormFields,
  setCurrentPackageFormField,
  setCurrentCustomField,
  findCurrentPackageItemWithId,
  setWatchItems,
  updatePackageWatchItem,
  setCurrentWatchItem,
  addNotification,
  setNotifications,
  clearNotifications,
  setCurrentPackageRounds,
  addPackageItem
} = packageSlice.actions;

//export any selectors needed

export const selectCurrentPackageAccessGroup = (state) =>
  state?.[packageSlice.name]?.currentPackageAccessGroup;

export const selectTournamentPackages = (state) =>
  state?.[packageSlice.name]?.tournamentPackages;

export const selectCurrentTournamentPackage = (state) =>
  state?.[packageSlice.name]?.currentTournamentPackage;

export const selectCurrentTournamentPackageItems = (state) =>
  state?.[packageSlice.name]?.currentTournamentPackageItems;

export const selectCurrentTournamentPackageItem = (state) =>
  state?.[packageSlice.name]?.currentTournamentPackageItem;

export const selectCurrentTournamentPackageForms = (state) =>
  state?.[packageSlice.name]?.currentPackageForms;

export const selectCurrentTournamentPackageForm = (state) =>
  state?.[packageSlice.name]?.currentPackageForm;

export const selectCurrentTournamentPackageFormFileds = (state) =>
  state?.[packageSlice.name]?.currentPackageFormFields;

export const selectCurrentCutomField = (state) =>
  state?.[packageSlice.name]?.currentCustomField;

export const selectAvailablePackageItems = (state) =>
  getAllPackageItems(state?.[packageSlice.name]);

export const selectPackageWatchItems = (state) =>
  state?.[packageSlice.name]?.packageWatchItems;

export const selectCurrentWatchItem = (state) =>
  state?.[packageSlice.name]?.currentPackageWatchItem;

export const selectTournamentForms = (state) =>
  state?.[packageSlice.name]?.tournamentForms;

export const selectNotifications = (state) =>
  state?.[packageSlice.name]?.notifications;

  export const selectCurrentPackageRounds = (state) =>
  state?.[packageSlice.name]?.currentPackageRounds;

  export const selectMinTechSponsorPackagePrice = (state) =>
  state?.[packageSlice.name]?.minTechSponsorPrice;

export const selectLoading = (state) => state?.[packageSlice.name]?.loading;

const getAllPackageItems = (state) => {
  return [
    ...(state?.teamPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "team",
    })) ?? []),
    ...(state?.playerPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "player",
    })) ?? []),
    ...(state?.sponsorPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "sponsor",
    })) ?? []),
    ...(state?.addOnPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "add-on",
    })) ?? []),
    ...(state?.technologySponsorPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "technology-sponsor",
    })) ?? []),
    ...(state?.skinsPackageItems?.map?.((pi) => ({
      ...pi,
      itemType: "skins",
    })) ?? []),
  ];
};

export default packageSlice.reducer;

//ADD reducer to store.js
