import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-remove.scss";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearRoundNotifications,
  getRound,
  removeTournamentRound,
  selectCurrentRound,
  selectRoundNotifications,
} from "../../../reducers/roundSlice";
import { useEffect } from "react";
import { setNotifications } from "../../../reducers/appSlice";
import {
  useNotificationAction,
  useNotificationNavigation,
  useNotificationBanner,
} from "../../../hooks/notificationHooks";
import TournamentRoundLineItem from './tournament-round-line-item'

//Name the component
const TournamentRoundRemove = (props) => {
  const [isValid] = useState(true);

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/remove");

  //selectors
  const currentRound = useSelector(selectCurrentRound);
  const notifications = useSelector(selectRoundNotifications);

  //custom hooks
  useNotificationAction("removed", notifications, (notification) => {
    dispatch(setNotifications([notification]));
  });
  useNotificationNavigation("removed", notifications, -2);
  

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
      return () => {
        dispatch(clearRoundNotifications());
      };
    }
  }, [dispatch, match?.params?.roundID]);

  const removeRound = () => {
    dispatch(removeTournamentRound(match?.params?.roundID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "delete",
        isDisabled: !isValid,
        action: removeRound,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Round`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle(
          `Delete Round`
        )}
        formSections={removeItemForm(
          `Round ${currentRound?.number} ${
            currentRound?.roundName ? `(${currentRound?.roundName})` : ""
          }`,
          <TournamentRoundLineItem round={currentRound} hideCopy hideActions/>
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({ notifications });

  return (
    //name the component tag
    <tournament-round-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-remove>
  );
};

//export the new namet
export default TournamentRoundRemove;
