import React, { useMemo, useState } from "react";
import {
  GSEmptyList,
  GSForm,
  GSQuickFilter,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
//import scss
import "./tournament-package-watch-emails.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  deletePackageWatchItem,
  getPackageWatchItems,
  getTournamentPackage,
  selectCurrentTournamentPackage,
  selectNotifications,
  selectPackageWatchItems,
  setCurrentWatchItem,
  setWatchItems,
} from "../../../../reducers/packageSlice";
import { useSelector } from "react-redux";
import { getPackageWatchForm } from "../../../../forms/TournamentPackageForm";
import './tournament-package-watch-emails.scss'
import TournamentPackageWatchItem from "./tournament-package-watch-item";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import SearchBar from "../../../search-bar";

//Name the component
const TournamentPackageWatchEmails = (props) => {
  const [currentEmail, setCurrentEmail] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/watch-emails"
  );

  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const watchItems = useSelector(selectPackageWatchItems);
  const packageNotifications = useSelector(selectNotifications)

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getPackageWatchItems(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  const context = useMemo(() => ({}), []);

  context.addEmail = () => {
    dispatch(clearNotifications())
    dispatch(setCurrentWatchItem({}))
    navigate("add")
  }

  context.editEmail = (item) => {
    dispatch(clearNotifications())
    dispatch(setCurrentWatchItem(item))
    navigate(item.id)
  }

  context.removeEmail = (item) => {
    dispatch(setWatchItems(watchItems?.filter?.(wi => wi.email !== item.email)))
    dispatch(deletePackageWatchItem(item.id))
  }

  context.getDefaultSearch = () => {
    if(watchItems.length === 0)
    {
      return 
    }
    let input = {
      search: currentEmail,
      placeholder: "Search by Email Address...",
      setSearch: setCurrentEmail,
      type:"no-pad"
    };
    return <SearchBar {...input}/>;
  };

  context.getPackageWatchList = () => {
    return (
      <GSQuickFilter
        filteredList={getFilteredItems()}
        getDefaultSearch={context.getDefaultSearch}
        itemSelected={context.editEmail}
        getItem={(item) => (getWatchItem(item))}
        emptySearchList = {currentEmail === "" ? getEmptyListMessage() : getEmptySearchMessage()}
        searchListType="grey-empty-list rounded-empty-list"
        selectedListType="grey-empty-list rounded-empty-list"
      ></GSQuickFilter>
    );
  };

  context.getCurrentPackage = () => {
    return currentPackage;
  }

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`${currentPackage.name} Watch Emails`}
        detail="Everyone on this list will be notified when this package has been purchased."
        actions={[
          {
            title: "Add Email",
            buttonIcon: faPlus,
            onClick: context.addEmail,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title={`We couldn’t find the email address you’re looking for…`}
        detail="Try changing the search term or add a new email address."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {setCurrentEmail("")},
            type: "light-grey",
          },
          {
            title: "Add Email",
            buttonIcon: faPlus,
            onClick: context.addEmail,
            type: "black",
          },
        ]}
      />
    );
  };

  const getWatchItem = (item) => {
    return <TournamentPackageWatchItem {...item}></TournamentPackageWatchItem>
  }

  const getFilteredItems = () => {
    return watchItems.filter(wi => wi.email.includes?.(currentEmail))
  }

  const leftNavigation = () => {
    timeoutAction();
    navigate(`/${match?.params?.tournamentID}/promotion/packages/${match?.params?.packageID}`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Watch Emails`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormActions = () => {
    return[
      {
        buttonTitle: "Add Email",
        actionIcon: faPlus,
        actionClick: context.addEmail,
        type: "black no-wrap",
      }
    ]
  }

  const getContent = () => {
    return <GSForm formTitle="Watch Emails" formActions={getFormActions()} formSections={getPackageWatchForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  }

  const notificationSettings = {
    notifications: packageNotifications,
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(
    notificationSettings
  );

  return (
    <tournament-package-watch-emails>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-package-watch-emails>
  );
};

export default TournamentPackageWatchEmails;