import React, { useEffect, useState } from "react";
import {
  GSSidePanelPage,
  GSItemList,
  GSInfoCard,
  GSButton
} from "golfstatus_react_components";
import { faBoxOpen, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-details.scss";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getAddOnPackageItems,
  getPackageItems,
  getPlayerPackageItems,
  getSkinsAddOnPackageItems,
  getSponsorPackageItems,
  getTeamPackageItems,
  getTechnologySponsorPackageItems,
  getTournamentPackage,
  selectAvailablePackageItems,
  selectCurrentTournamentPackage,
  selectCurrentTournamentPackageItems,
  selectLoading,
  selectNotifications,
} from "../../../../reducers/packageSlice";
import NavigationListItem from "../../../navigation-list-item";
import { useDispatch } from "react-redux";
import TournamentPackageItemsItem from "./tournament-package-items-item";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import TournamentPackagesItem from "./tournament-packages-item";
//Name the component
const TournamentPackageDetails = (props) => {
  const [isValid] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/promotion/packages/:packageID/*");

  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageItems = useSelector(selectCurrentTournamentPackageItems);
  const allPackageItems = useSelector(selectAvailablePackageItems);
  const notifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading)

  useEffect(() => {
    if (match?.params?.packageID && match?.params?.packageID !== "add") {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getPackageItems(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTeamPackageItems(match.params.tournamentID));
      dispatch(getPlayerPackageItems(match.params.tournamentID));
      dispatch(getSponsorPackageItems(match.params.tournamentID));
      dispatch(getTechnologySponsorPackageItems(match.params.tournamentID));
      dispatch(getSkinsAddOnPackageItems(match.params.tournamentID));
      dispatch(getAddOnPackageItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const getPackageItemCard = (item) => {
    let packageItem = allPackageItems.find?.(
      (pi) => pi.id === item.packageItemKey
    );
    let tournamentPackageItem = { ...packageItem, selectedItem: item };
    return (
      <GSInfoCard
        size="large"
        layout={
          <TournamentPackageItemsItem
            {...tournamentPackageItem}
          ></TournamentPackageItemsItem>
        }
      />
    );
  };


  const getPackageItemList = () => {
    return (
      <GSItemList
        type="horizontal x-large-pad large-gap bottom-border scrollable"
        items={currentPackageItems}
        listItem={(item) => getPackageItemCard(item)}
        emptyMessage={<GSInfoCard size="large centered" layout={<GSButton buttonIcon={faBoxOpen} title="Manage Items" />} />}
      ></GSItemList>
    );
  };

  const getPackagePages = (flight, leaderboards, tournamentID) => {
    let pages = [
      {
        hero: <TournamentPackagesItem {...currentPackage} />,
        isEdit: true,
        hidden: true,
        type: "hero",
        url: "edit",
      },
      {
        label: "Package Items",
        details: "Manage the items included in this package.",
        isExternal: false,
        extras: getPackageItemList(),
        url: "package-items",
        hidden: false,
      },
      {
        label: "Watch Emails",
        details:
          "Manage who receives email notifications for purchases.",
        isExternal: false,
        url: "watch-emails",
        hidden: false,
      },
      {
        label: "Forms",
        details: "Manage forms to collect additional registrant information.",
        isExternal: false,
        url: "forms",
        hidden: false,
      },
      {
        label: "Activation & Deactivation",
        details:
          "Manage automatic activation and deactivation dates of this package.",
        isExternal: false,
        url: "activation",
        hidden: false,
      },
      {
        label: "Player Information",
        details:
          "Require players to provide specific information at checkout.",
        isExternal: false,
        url: "player-info",
        hidden: false,
      },
      {
        label: "Exclusivity",
        details:
          "Require an access code to view and purchase this package.",
        isExternal: false,
        url: "exclusivity",
        hidden: false,
      },
      {
        label: "Rounds",
        details: "Manage rounds that this package is associated with.",
        isExternal: false,
        url: "rounds",
        hidden: false,
      },
    ];
    return [...pages];
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete Package",
        action: () => {
          navigate("delete");
        },
        isDisabled: !isValid,
        type: "transparent red",
      }
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(`/${match?.params?.tournamentID}/promotion/packages`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Package Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const pageSelected = (page) => {
    navigate(page?.url);
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSItemList
          type="vertical"
          itemSelected={pageSelected}
          items={getPackagePages()}
          listItem={(item) => (
            <NavigationListItem page={item}></NavigationListItem>
          )}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications,
    timeoutAction,
  });

  return (
    //name the component tag
    <tournament-package-details>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-details>
  );
};

//export the new namet
export default TournamentPackageDetails;
