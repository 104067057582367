import React, { useEffect } from "react";
import TournamentHero from "../../tournament-hero";
import { useDispatch, useSelector } from "react-redux";
import "./tournament-overview.scss";
import { GSItemList, GSPageLayout } from "golfstatus_react_components";
import {
  getTournament,
  selectCurrentTournament,
} from "../../../reducers/tournamentSlice";
import { getTournamentLinkPages } from "../../../forms/TournamentLinks";
import NavigationListItem from "../../navigation-list-item";
import { useMatch, useNavigate } from "react-router-dom";
import { GSButton, GSInfoGroup, GSInfoCard } from "golfstatus_react_components";
import TournamentRoundTile from "../tournament-rounds/tournament-round-tile";
import { faCog, faPlus } from "@fortawesome/free-solid-svg-icons";

const TournamentOverview = (props) => {
  const currentTournament = useSelector(selectCurrentTournament);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID");

  useEffect(() => {
    dispatch(getTournament(match?.params?.tournamentID));
  }, [dispatch, match.params.tournamentID]);

  const itemSelected = (item) => {
    if (item.isExternal) {
      window.open(item.url);
    } else {
      navigate(`${item.url}`);
    }
  };

  const getPageActions = () => {
    return [
      {
        id: 1,
        type: " mobile-icon",
        actionIcon: faCog,
        buttonTitle: "Settings & Preferences",
        actionClick: () => {
          navigate("settings");
        },
      },
    ];
  };

  const getNewRoundLayout = (round) => {
    let group = [
      {
        sections: [
          { 
            fit: "centered",
            sectionItems: [
              {
                type: "primary no-wrap centered",
                value: (
                  <GSButton  buttonIcon={faPlus} title="Add Round" onClick={(e) => {navigate("rounds/new");e.stopPropagation()}}></GSButton>
                ),
              },
            ],
          },
        ],
      },
    ];
    return <GSInfoGroup listType="centered" dataGroups={group} />;
  };

  const getRoundList = () => {
    return (
      <div className="round-list">
        <GSItemList
          type="horizontal large-gap x-large-pad"
          items={currentTournament?.tournamentRounds}
          itemSelected={(item) => itemSelected({ url: `rounds/${item.id}` })}
          listItem={(round) => (
            <GSInfoCard
              size="large"
              selectable={true}
              layout={<TournamentRoundTile round={round} />}
            ></GSInfoCard>
          )}
          emptyMessage={
            <GSInfoCard
              size="large centered"
              selectable={true}
              layout={getNewRoundLayout()}
            ></GSInfoCard>
          }
        ></GSItemList>
      </div>
    );
  };

  const getLinks = () => {
    return (
      <GSItemList
        type="vertical"
        itemSelected={itemSelected}
        items={getTournamentLinkPages(currentTournament, getRoundList())}
        listItem={(page) => getTournamentLayout(page)}
      ></GSItemList>
    );
  };

  const getTournamentLayout = (page) => {
    return <NavigationListItem page={page} />;
  };

  return (
    <tournament-overview>
      <GSPageLayout title="Overview" pageActions={getPageActions()}>
        <TournamentHero tournament={currentTournament} />
        {getLinks()}
      </GSPageLayout>
    </tournament-overview>
  );
};

export default TournamentOverview;
