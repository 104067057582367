import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { getTotalPar, getTotalYardage } from "../../../../helpers/Converters";
import { integerWithCommas } from "../../../../helpers/RegexHelper";

import "./tournament-round-tee-item.scss";

const TournamentRoundTeeItem = (props) => {
  const { defaultTee, gender, name, rating, slope, roundHoles } = props;

  const totalPar =  getTotalPar(roundHoles)
  const totalYardage = getTotalYardage(roundHoles)

  const teeInfoSection = {
    sectionItems: [
      {
        type: "primary full-width",
        value: name,
      },
      {
        type: "secondary full-width capitalize",
        value: gender,
      },
    ],
  };

  const teeRatingSection = {
    sectionItems: [
      {
        type: "secondary full-width",
        value: `Par ${totalPar}`,
      },
      {
        type: "secondary full-width",
        value: `${integerWithCommas(totalYardage) } Yards`,
      },
      {
        type: "secondary full-width",
        value: `${slope}/${rating}`,
      },
    ],
  };

  const defaultTeeSection = {
    sectionItems: [{ type: "primary", value: <GSButton type="black pill secondary" title="default" /> }],
  };

  let teeInfoColumn = {
    fit: "stretch",
    sections: [
      {
        ...teeInfoSection,
      },
      { ...teeRatingSection },
    ],
  };

  const getGroup = () => {
    if(defaultTee){
      teeInfoColumn.sections.push({...defaultTeeSection})
    }
    let group = [{ ...teeInfoColumn }];
    return group;
  };

  return (
    <tournament-round-tee-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-round-tee-item>
  );
};

export default TournamentRoundTeeItem;
