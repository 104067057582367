import "./tournament-team-disqualified-list.scss";
import React from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import {
  clearNotifications,
  selectTeamsNotifications,
  selectTournamentTeams,
  selectLoading,
} from "../../../reducers/teamsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import TournamentTeamItem from "./tournament-team-item";

//Name the component
const TournamentTeamDisqualifiedList = (props) => {
  //hooks
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/teams/disqualified");
  const dispatch = useDispatch();

  //selectors
  const tournamentTeams = useSelector(selectTournamentTeams);
  const notifications = useSelector(selectTeamsNotifications);
  const loading = useSelector(selectLoading);

  //custom hooks

  //effects

  //context

  //component functionality

  const getDrawerActions = () => {
    let actions = [];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Disqualified Teams`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getPlayerGroupList = (item) => {
    return <TournamentTeamItem {...item} />;
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Disqualified Teams"
        detail="All players and teams are qualified to play!"
        actions={[
          {
            title: "View Players & Teams",
            onClick: () => navigate(-1),
            type: "black",
          },
        ]}
      />
    );
  };

  const itemSelected = (item) => {
    if (isSidePanelOpen()) {
      navigate(`/${match?.params?.tournamentID}/teams/${item.id}`);
    }
  };

  const getContent = () => {
    return (
      <>
        <GSActionBar
          type="form-header"
          header="Disqualified Teams"
        ></GSActionBar>
        <gs-form>
          <GSItemList
            type="vertical selectable"
            items={tournamentTeams.filter((team) => team.disqualified)}
            listItem={(item) => getPlayerGroupList(item)}
            emptyMessage={getEmptyListMessage()}
            itemSelected={itemSelected}
          ></GSItemList>
        </gs-form>
      </>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-team-disqualified-list>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
        loading={loading.length !== 0}
      ></GSSidePanelPage>
    </tournament-team-disqualified-list>
  );
};

//export the new name
export default TournamentTeamDisqualifiedList;
