import React, { useEffect, useState } from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSInput,
  GSItemList,
  GSRadioGroup,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faBolt,
  faChevronLeft,
  faExclamationCircle,
  faLock,
  faPlus,
  faSave,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-round-team-hole-assignments.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getTournamentTeams,
  selectLoading,
  selectQualifiedTeams,
} from "../../../../reducers/teamsSlice";
import TournamentTeamItem from "../../tournament-leaderboards/tournament-team-item";
import {
  clearRoundNotifications,
  deactivateRoundScorecards,
  getRound,
  getRoundHoleAssignments,
  getRoundScorecards,
  saveRoundHoleAssignment,
  selectCurrentRound,
  selectCurrentRoundHoleAssignments,
  selectCurrentRoundScorecards,
  selectRoundNotifications,
  setCurrentHoleAssignment,
  setCurrentRoundHoleAssignment,
} from "../../../../reducers/roundSlice";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { useMemo } from "react";
import {
  getNotificationItemInfo,
  getRoundName,
} from "../../../../helpers/Converters";
import TournamentRoundHoleAssignmentItem from "./tournament-round-hole-assignment-item";
import { getDateTimeOn } from "../../../../helpers/Dates";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { useMinSize } from "../../../../hooks/scorecardHooks";
import TournamentRoundHoleAssignmentGridItem from "./tournament-round-hole-assignment-grid-item";
import { getTournamentFlights, selectTournamentFlights } from "../../../../reducers/flightSlice";

//Name the component
const TournamentRoundTeamHoleAssignments = (props) => {
  const [params] = useSearchParams();
  const view = params?.get?.("view");
  const [search, setSearch] = useState("");
  const [parFilter, setParFilter] = useState();
  const [viewOption, setViewOption] = useState(
    view === "2" ? { value: 2 } : { value: 1 }
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/hole-assignments");

  const tournamentTeams = useSelector(selectQualifiedTeams);
  const currentRoundHoleAssignments = useSelector(
    selectCurrentRoundHoleAssignments
  );
  const currentRound = useSelector(selectCurrentRound);
  const currentTournament = useSelector(selectCurrentTournament);
  const notifications = useSelector(selectRoundNotifications);
  const scorecards = useSelector(selectCurrentRoundScorecards);
  const flights = useSelector(selectTournamentFlights)
  
  const loading = useSelector(selectLoading);

  const isPhone = useMinSize(600);

  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundScorecards(match.params.roundID));
    dispatch(getRoundHoleAssignments(match.params.roundID));
  }, [dispatch, match?.params?.roundID]);

  useEffect(() => {
    dispatch(getTournamentTeams(match.params.tournamentID));
    dispatch(getTournamentFlights(match.params.tournamentID))
  }, [dispatch, match?.params?.tournamentID, tournamentTeams?.length]);

  useEffect(() => {
    return () => {
      dispatch(clearRoundNotifications());
    };
  }, [dispatch]);

  const getDrawerActions = () => {
    let actions = [
      {
        name: "clear assignments",
        action: clearHoleAssignments,
        type: "transparent red",
      },
    ];
    return actions;
  };

  const clearHoleAssignments = () => {
    navigate("remove-all");
  };

  const autoAssign = () => {
    navigate("auto-assign");
  };

  const leftNavigation = () => {
    dispatch(clearRoundNotifications());
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${getRoundName(currentRound)} Hole Assignments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const defaultBanner = useMemo(() => {
    let banner =
      scorecards?.length === 0 || currentRound.state === "live"
        ? {
            title: getNotificationItemInfo(
              `Assignments will automatically be saved.`,
              faSave
            ),
            state: "grey",
          }
        : {
            title: getNotificationItemInfo(
              `Adding new scorecards might have undesired effects.`,
              faExclamationCircle,
              "Scorecards have already been generated"
            ),
            state: "warning",
            bannerActions: [
              {
                title: "clear scorecards",
                actionClick: () => {dispatch(deactivateRoundScorecards(match.params.roundID));},
              },
            ],
          };
    return banner;
  }, [scorecards, currentRound.state, dispatch, match.params.roundID]);

  const getHoleAssignmentOptions = () => {
    let available = currentRoundHoleAssignments?.filter?.(
      (rha) => rha?.tournamentTeams?.length < rha.maxTeamsPerHole
    );
    return available?.map?.((ha) => ({
      label:
        currentRound.startType !== "shotgun_start"
          ? `${getDateTimeOn(ha.teeTime)}`
          : `Hole ${ha.hole} ${ha.position}${ha.subPosition ?? ""} ${
              ha.locked ? "(Locked)" : ""
            }`,
      subLabel:
        currentRound.startType !== "shotgun_start"
          ? `Hole ${ha.hole} Par ${ha.holePar}`
          : `Par ${ha.holePar}`,
      value: ha,
    }));
  };

  const getHoleAssignmentOption = (item) => {
    const ha = item.holeAssignment;
    if (ha === undefined) {
      return null;
    }
    return {
      label:
        currentRound.startType !== "shotgun_start"
          ? `${getDateTimeOn(ha.teeTime)}`
          : `Hole ${ha.hole} ${ha.position}${ha.subPosition ?? ""} ${
              ha.locked ? "(Locked)" : ""
            }`,
      subLabel: `Par ${ha.holePar}`,
      value: ha,
    };
  };

  const holeAssignmentSelected = (team, holeAssignment) => {
    if (holeAssignment === undefined) {
      let update = { ...team.holeAssignment };
      update.tournamentTeams = [
        ...(team.holeAssignment?.tournamentTeams ?? [])?.filter?.(
          (t) => t.id !== team.id
        ),
      ];
      dispatch(setCurrentRoundHoleAssignment(update));
      dispatch(saveRoundHoleAssignment(update));
      return;
    }
    if (team.holeAssignment !== undefined) {
      let update = { ...team.holeAssignment };
      update.tournamentTeams = [
        ...team.holeAssignment?.tournamentTeams?.filter?.(
          (t) => t.id !== team.id
        ),
      ];
      dispatch(setCurrentRoundHoleAssignment(update));
      dispatch(saveRoundHoleAssignment(update));
    }
    let update = { ...holeAssignment };
    update.tournamentTeams = [...holeAssignment?.tournamentTeams, team];
    update.tournamentRound = currentRound;
    update.tournament = currentTournament;
    dispatch(setCurrentRoundHoleAssignment(update));
    dispatch(saveRoundHoleAssignment(update));
  };

  const getAssignment = (item) => {
    if (item.value) {
      return (
        <div className="assignment">
          <TournamentRoundHoleAssignmentItem
            {...item.value}
          ></TournamentRoundHoleAssignmentItem>
        </div>
      );
    }
    return (
      <div className="assignment">
        <TournamentTeamItem {...item} />
        <GSSelect
          options={getHoleAssignmentOptions()}
          selectedOption={getHoleAssignmentOption(item)}
          onChange={(option) => {
            holeAssignmentSelected(item, option?.value);
          }}
          placeholder="Select a Hole Assignment..."
          isClearable
        ></GSSelect>
      </div>
    );
  };

  const getFilteredTeams = () => {
    let filteredTeams = tournamentTeams.filter?.((t) =>
      `${t?.contactName} ${t?.name} ${t?.players
        ?.map?.((p) => p.name)
        ?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search.toLowerCase?.())
    );

    let mappedTeams = filteredTeams?.map?.((ft) => ({
      ...ft,
      holeAssignment: currentRoundHoleAssignments.find?.((crha) =>
        crha?.tournamentTeams.map?.((tt) => tt?.id)?.includes?.(ft?.id)
      ),
      flight: flights.find((flight) => flight.position === ft.mainFlightPosition)
    }));

    let unassigned = mappedTeams?.filter?.(
      (mt) => mt.holeAssignment === undefined
    ).sort?.(
      (a, b) =>
        a.mainFlightPosition - b.mainFlightPosition
    );
    let assigned = mappedTeams
      ?.filter?.((mt) => mt.holeAssignment !== undefined)
      .sort?.(
        (a, b) =>
          a.holeAssignment?.hole - b.holeAssignment?.hole ||
          a.holeAssignment.position?.localeCompare?.(b.holeAssignment.position)
      );

    return [...unassigned, ...assigned];
  };

  const lockHoles = () => {
    navigate("lock-holes");
  };

  const getPageActions = () => {
    return [
      {
        title: "Auto Assign",
        actionIcon: faBolt,
        type: "black no-wrap mobile-icon",
        actionClick: autoAssign,
      },
      {
        type: "no-wrap light-grey mobile-icon",
        buttonTitle: "Lock Holes",
        actionIcon: faLock,
        actionClick: lockHoles,
      },
    ];
  };

  const manageTeams = () => {
    navigate(`/${match?.params?.tournamentID}/teams`);
  };

  const addTeam = () => {
    navigate(`/${match?.params?.tournamentID}/teams/add`);
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Players & Teams"
        detail="Once players and teams are added to the tournament, they can be assigned to starting holes."
        actions={[
          {
            title: "Add Team",
            onClick: addTeam,
            buttonIcon: faPlus,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptyHoleAssignmentListMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the hole you’re looking for…"
        detail="Try changing the search term and filter."
        actions={[
          {
            title: "Clear All",
            onClick: () => {
              setSearch("");
              setParFilter(undefined);
            },
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term and filter or review the players and teams list."
        actions={[
          {
            title: "Clear All",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
          {
            title: "View Players & Teams",
            onClick: manageTeams,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const itemMoved = (sourceItem, sourceIndex, destItem, destIndex) => {
    holeAssignmentSelected(sourceItem, destItem.value);
  };

  const getHoleAssignment = (assignment) => {
    return (
      <TournamentRoundHoleAssignmentGridItem
        assignment={assignment}
        currentRound={currentRound}
        tournamentTeams={tournamentTeams}
      />
    );
  };

  const getFilteredHoleAssignments = () => {
    let has = currentRoundHoleAssignments.filter((crha) => {
      return (
        `hole ${crha.hole} ${crha.position.toLowerCase()} ${
          crha.subPosition
        }`.includes?.(search.toLowerCase()) &&
        crha.holePar === (parFilter?.value ?? crha.holePar)
      );
    });
    if (currentRound.startType === "shotgun_start") {
      let sort = [...has];
      sort.sort(
        (a, b) =>
          a.hole - b.hole ||
          a.subPosition - b.subPosition ||
          a.position - b.position
      );
      return sort;
    }

    return has;
  };

  const getList = () => {
    const filteredTeams = getFilteredTeams();
    const holes = currentRoundHoleAssignments.filter?.(
      (ha) => ha.position === "A"
    );
    let holeIndices =
      holes?.map?.((fs, i) => ({
        index: filteredTeams?.findIndex?.(
          (t) => t?.holeAssignment?.hole === fs?.hole
        ),
        view: (
          <GSActionBar
            type="grey-header x-large-pad"
            key={i}
            header={`Hole ${fs?.hole}`}
          />
        ),
      })) ?? [];
    holeIndices = [
      ...holeIndices,
      {
        index: 0,
        view: (
          <GSActionBar
            type="grey-header x-large-pad"
            key={0}
            header={`Unassigned`}
            pageActions={[]}
          />
        ),
      },
    ];
    let dropList = [...getHoleAssignmentOptions()];

    if (viewOption.value === 1) {
      return (
        <GSItemList
          type="vertical team-list"
          isSortable={false}
          topScrollMargin={150}
          items={filteredTeams}
          dropList={dropList}
          indexItems={holeIndices}
          itemMoved={itemMoved}
          dragOffset={0}
          listItem={(item) => getAssignment(item)}
          emptyMessage={
            search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
          }
          loading={loading.includes("getTournamentTeams")}
          loadingMainText="Loading teams..."
        />
      );
    } else {
      return (
        <GSItemList
          type="grid team-list selectable"
          columns={
            isPhone
              ? "1"
              : currentRound.startType === "two_tee_interval_start"
              ? "2"
              : "3"
          }
          items={getFilteredHoleAssignments()}
          itemSelected={(item) => {
            dispatch(setCurrentHoleAssignment(item));
            dispatch(clearRoundNotifications());
            navigate(item.id);
          }}
          listItem={(item) => getHoleAssignment(item)}
          emptyMessage={getEmptyHoleAssignmentListMessage()}
          loading={loading.includes("getTournamentTeams")}
          loadingMainText="Loading Hole Assignments..."
        />
      );
    }
  };

  const getSearchBar = () => {
    return viewOption.value === 1 ? (
      <GSInput
        placeholder="Search Players and Teams…"
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={() => {
          setSearch("");
        }}
        textValue={search}
        onChange={(e) => {
          setSearch(e?.target?.value);
        }}
      ></GSInput>
    ) : (
      <div className="shotgun-search">
        <GSInput
          placeholder="Search Hole Numbers…"
          leftIcon={faSearch}
          rightIcon={faTimesCircle}
          rightIconClick={() => {
            setSearch("");
          }}
          textValue={search}
          onChange={(e) => {
            setSearch(e?.target?.value);
          }}
        ></GSInput>
        <GSSelect
          isClearable
          selectedOption={parFilter}
          onChange={(option) => setParFilter(option)}
          placeholder="Select Par..."
          options={[
            { label: "Par 3", value: 3 },
            { label: "Par 4", value: 4 },
            { label: "Par 5", value: 5 },
          ]}
        />
      </div>
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar
          type="form-header"
          header={`Round ${getRoundName(currentRound)} Hole Assignments`}
          pageActions={getPageActions()}
        ></GSActionBar>
        <div className="search">
          <GSRadioGroup
            isLtr
            options={[
              { label: "Holes", value: 2 },
              { label: "Player & Teams", value: 1 },
            ]}
            selectedOption={viewOption}
            selectionChanged={(option) => {
              navigate(`?view=${option.value}`, { replace: true });
              setViewOption(option);
            }}
          />
          {getSearchBar()}
        </div>
        {getList()}
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction,
    defaultBanner,
  });

  return (
    //name the component tag
    <tournament-round-team-hole-assignments>
      <GSSidePanelPage
        loading={loading?.length > 0}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-team-hole-assignments>
  );
};

//export the new namet
export default TournamentRoundTeamHoleAssignments;
