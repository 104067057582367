import React from "react";
import { GSForm, GSSidePanelPage, GSItemList } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flights-remove-assignments.scss";
import {
  clearNotifications,
  getTournamentFlights,
  removeAllFlightAssignments,
  selectFlightNotifications,
  selectTournamentFlights,
} from "../../../reducers/flightSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import TournamentFlightLineItem from "./tournament-flight-line-item";

//Name the component
const TournamentFlightsRemoveAssignments = (props) => {
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/flighting/assign/remove-all");
  const dispatch = useDispatch();
  const flightNotifications = useSelector(selectFlightNotifications);
  const tournamentFlights = useSelector(selectTournamentFlights);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentFlights(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  useNotificationNavigation(
    "removed",
    flightNotifications,
    -1,
    "Flight assignments"
  );

  const getFlights = () => {
    return tournamentFlights?.reduce((flights, flight) => {
      const flightIndex = flights.findIndex?.(
        (f) => f.position === flight.position
      );
      if (flightIndex === -1) {
        flights?.push?.({ ...flight, preflights: [flight] });
      } else {
        flights[flightIndex].preflights = [
          ...(flights?.[flightIndex]?.preflights ?? []),
          flight,
        ];
      }
      return flights;
    }, []);
  };

  const removeAll = () => {
    if (match?.params?.tournamentID) {
      dispatch(removeAllFlightAssignments(match.params.tournamentID));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Clear",
        action: removeAll,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Clear Flight Assignments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFlightList = () => {
    return (
      <GSItemList
        type="vertical x-large-gap"
        items={getFlights()}
        listItem={(item) => <TournamentFlightLineItem {...item} />}
      ></GSItemList>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Clear Flight Assignments")}
        formSections={removeItemForm("flights", getFlightList(), "", "Are you sure you want to clear all tournament flight assignments? This cannot be undone and all flights will need to be re-assigned.")}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications: flightNotifications,
    timeoutAction,
  });

  return (
    //name the component tag
    <tournament-flights-remove-assignments>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flights-remove-assignments>
  );
};

//export the new namet
export default TournamentFlightsRemoveAssignments;
