import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import Cookies from 'js-cookie';
import { BrowserRouter as Router } from 'react-router-dom';
import GolfstatusTMS from './components/golfstatus-tms';
import "golfstatus_react_components/dist/index.css";
import './App.scss';
import { useSelector } from "react-redux";
import { selectTheme } from "./reducers/appSlice";

function App() {

  const { update } = useIntercom();
  const gsSessionCookie = Cookies.get('golfstatus-session');

  const themeSelector = useSelector(selectTheme);
  const updateIntercomWithProps = () => {
    const gsSessionUser = JSON.parse(gsSessionCookie).authenticated;

    update({ 
      name: `${gsSessionUser.firstName} ${gsSessionUser.lastName}`,
      email: gsSessionUser.email,
      userId: gsSessionUser.uid
    })
  };

  useEffect(() => {
    if ( gsSessionCookie &&
      JSON.parse(gsSessionCookie) &&
      JSON.parse(gsSessionCookie).authenticated.uid
    ) {
      updateIntercomWithProps();
    } else {
      window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
    }
  });

  return (
    <div className={`App ${themeSelector}`}>
      <Router>
        <GolfstatusTMS></GolfstatusTMS>
      </Router>
    </div>
  );
}

export default App;
