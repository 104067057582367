import "./tournament-team-add-edit-player.scss";
import React, { useCallback, useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { getAddPlayerForm } from "../../../forms/TeamForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeam,
  saveTournamentTeamPlayer,
  selectCurrentTeam,
  setCurrentTeam,
} from "../../../reducers/teamsSlice";
import { useFormValidation } from "../../../hooks/formHooks";

//Name the component
const TournamentTeamAddEditPlayer = (props) => {
  const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

  const match = useMatch("/:tournamentID/teams/add/players/add/:playerID");
  const matchNewEdit = useMatch(
    "/:tournamentID/teams/add/players/edit/:playerID"
  );
  const matchEditAdd = useMatch(
    "/:tournamentID/teams/:teamID/edit/players/add/:playerID"
  );
  const matchEdit = useMatch(
    "/:tournamentID/teams/:teamID/edit/players/edit/:playerID"
  );

  const currentTeam = useSelector(selectCurrentTeam);

  const [player, setPlayer] = useState({
    tempID: match?.params?.playerID ?? matchEditAdd?.params?.playerID,
    avatar: defaultAvatar,
    firstName: "",
    lastName: "",
    mainContact: (currentTeam.contactName ?? "") === "",
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (matchEdit && currentTeam?.id !== matchEdit?.params?.teamID) {
      dispatch(getTournamentTeam(matchEdit.params.teamID));
    }
  }, [dispatch, matchEdit, currentTeam?.id]);

  const setPlayerCallback = useCallback((team) => {
    if (matchEdit?.params?.playerID && team) {
      setPlayer(
        team?.players?.find?.(
          (p) => (p.id ?? p.tempID) === matchEdit?.params?.playerID
        )
      );
    } else if (matchNewEdit?.params?.playerID && team) {
      setPlayer(
        team?.players?.find?.(
          (p) => p.id ?? p.tempID === matchNewEdit?.params?.playerID
        )
      );
    }
  }, [matchNewEdit?.params?.playerID, matchEdit?.params?.playerID])

  useEffect(() => {
    setPlayerCallback(currentTeam)
  }, [currentTeam, setPlayerCallback]);

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updatePlayer = (value, property) => {
    setIsValid(true);
    let updatePlayer = { ...player };
    updatePlayer[property] = value;
    setPlayer(updatePlayer);
  };

  context.validationFailed = () => {
    setIsValid(false);
  };

  context.submit = () => {
    if (isValid) {
      addPlayer();
    }
  };

  const addPlayer = () => {
    if (match || matchEditAdd) {
      dispatch(
        setCurrentTeam({
          ...currentTeam,
          contactName:
            (currentTeam.contactName ?? "") === ""
              ? `${player.firstName} ${player.lastName}`
              : currentTeam.contactName,
          email:
            (currentTeam.email ?? "") === "" ? player.email : currentTeam.email,
          phone:
            (currentTeam.phone ?? "") === "" ? player.phone : currentTeam.phone,
          players: [...(currentTeam.players ?? []), player],
        })
      );
    }
    if (matchEdit) {
      if (player.id) {
        dispatch(
          saveTournamentTeamPlayer({
            ...player,
            name: `${player.firstName} ${player.lastName}`,
            tournamentTeam: currentTeam,
          })
        );
      }
      swapPlayer(matchEdit?.params?.playerID);
    }
    if (matchNewEdit) {
      swapPlayer(matchNewEdit?.params?.playerID);
    }
    leftNavigation();
  };

  const removePlayer = () => {
    if(matchNewEdit){
      let update = {...currentTeam, players: currentTeam.players?.filter?.(p => p.tempID !== matchNewEdit?.params?.playerID)}
      dispatch(setCurrentTeam(update))
      navigate(-1, {replace: true})
      return
    }
    navigate("remove")
  }

  const swapPlayer = (playerID) => {
    const playerIndex = currentTeam?.players?.findIndex?.(
      (p) => (p.id ?? p.tempID) === playerID
    );
    const newPlayers = [...currentTeam.players];
    newPlayers[playerIndex ?? playerID] = {
      ...player,
      name: `${player.firstName} ${player.lastName}`,
    };
    dispatch(setCurrentTeam({ ...currentTeam, players: newPlayers }));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: addPlayer,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if(matchEdit || matchNewEdit)
    {
      actions.push({ name: "delete", action: removePlayer, type: "transparent red" })
    }
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Player Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContactAction = () => {
    return [
      {
        actionType: "toggle",
        value: player?.mainContact ?? false,
        pageActionProps: {
          label: "Main Contact?",
          falseDescription: "No",
          rowReverse: true,
          onClick: (e) =>
            context.updatePlayer(!player?.mainContact, "mainContact"),
          trueDescription: "Yes",
          value: player?.mainContact,
        },
      },
    ];
  };

  const getActionBar = () => {
    return {
      formTitle: match ? "Add Player" : "Edit Player",
      formActions: getContactAction(),
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getActionBar()}
        formSections={getAddPlayerForm(player, context)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    //name the component tag
    <tournament-team-add-edit-player>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-add-edit-player>
  );
};

//export the new namet
export default TournamentTeamAddEditPlayer;
