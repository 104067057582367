import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-default-watch-item-add-edit.scss";
import {
  useFormDataValidation,
} from "../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import {
  defualtWatchItemForm,
} from "../../../forms/SettingsForms";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  clearNotifications,
  createDefaultWatchItem,
  getTournamentDefaultWatchItem,
  selectCurrentDefaultWatchItem,
  selectSettingsNotifications,
  setCurrentWatchItem,
  updateDefaultWatchItem,
} from "../../../reducers/settingsSlice";
import { useSelector } from "react-redux";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

//Name the component
const TournamentSettingsDefaultWatchItemAddEdit = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/settings/default-watch-items/:watchItemID"
  );
  const notifications = useSelector(selectSettingsNotifications);

  const currentWatchItem = useSelector(selectCurrentDefaultWatchItem);
  const currentTournament = useSelector(selectCurrentTournament);

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: currentWatchItem,
    setData: setCurrentWatchItem,
  });

  context.submit = () => {
    if(isValid ){
      save();
    }
  }

  useNotificationNavigation("created", notifications, -1, "Default Watch Item");

  useEffect(() => {
    if (match?.params?.watchItemID && match?.params?.watchItemID !== "add") {
      dispatch(getTournamentDefaultWatchItem(match.params.watchItemID));
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.watchItemID]);

  const save = () => {
    setIsValid(false);
    let update = { ...currentWatchItem };
    update.tournament = currentTournament;
    if (match?.params?.watchItemID !== "add") {
      dispatch(updateDefaultWatchItem(update));
    } else {
      dispatch(createDefaultWatchItem(update));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if(match?.params?.watchItemID !== "add"){
      actions.push({
        name: "Delete Email",
        action: () => {
          navigate("delete");
        },
        type: "transparent red",
      },)
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !banner) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Email Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          match?.params?.watchItemID === "add"
            ? "Add Email"
            : "Edit Email"
        }
        formSections={defualtWatchItemForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [banner, setUnsaved] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-settings-default-watch-item-add-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-default-watch-item-add-edit>
  );
};

//export the new namet
export default TournamentSettingsDefaultWatchItemAddEdit;
