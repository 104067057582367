import { GSInfoGroup, ScoreTotal } from "golfstatus_react_components";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getLiveTournamentRound, selectCurrentLiveTournamentRounds } from "../reducers/scorecardSlice";
import "./tournament-player-ghin-item.scss"

const TournamentPlayerGhinItem = (props) => {

  const {name, ghinNumber, handicap, id, teamID, liveTournamentScorecard} = props

  const dispatch = useDispatch()

  const currentLiveTournamentRounds = useSelector(selectCurrentLiveTournamentRounds)

  const getLiveRound = useMemo(() => {
    let clr = currentLiveTournamentRounds.find?.(ltr => ltr?.player?.id === id || ltr?.tournamentTeam?.id === teamID )
    return clr
  }, [currentLiveTournamentRounds, id, teamID])
  
  useEffect(() => {
    if(liveTournamentScorecard?.liveRounds?.length > 0 && !getLiveRound?.id){
      //we dont know which live round this player is so we have to get them all
      liveTournamentScorecard.liveRounds.forEach(lr => {
        dispatch(getLiveTournamentRound(lr.id))
      })
    }
  }, [liveTournamentScorecard, getLiveRound?.id, dispatch])

  const playerSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary no-wrap full-width",
        value: ghinNumber,
      },
    ]
  }

  const handicapSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `${(handicap ?? 0)} Handicap`,
      },
    ]
  }

  const playerColumn ={
    fit: "stretch",
    sections: [
      {
        ...playerSection
      },
      handicapSection
    ],
  }

  const scoreSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <ScoreTotal scores={getLiveRound?.scores}/>,
      },
    ]
  }

  const scoreColumn ={
    sections: [
      scoreSection
    ],
  }


  const getGroup = () => {
    let group = [{...playerColumn},{...scoreColumn}]
    return group
  }

  return <tournament-player-ghin-item>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </tournament-player-ghin-item>
}


export default TournamentPlayerGhinItem