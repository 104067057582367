import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GSQuickFilter, GSInput } from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacilityList, selectFacilityList, selectFacilityLoading, setFacilityList } from "../reducers/facilitySlice";
import FacilityLineItem from "./facility-line-item";

import "./facility-quick-filter.scss";

const FacilityQuickFilter = (props) => {
  const [facilityFilter, setFacilityFilter] = useState({});
  const [facilityName, setFacilityName] = useState("");
  const [facilityState, setFacilityState] = useState("");
  const [selectedFacility, setSelectedFacility] = useState(props?.selectedFacility);
  const loading = useSelector(selectFacilityLoading)

  const facilityList = useSelector(selectFacilityList);

  const dispatch = useDispatch();

  useEffect(() => {
    if((facilityFilter?.name ?? "") !== "" || (facilityFilter?.state ?? "") !== "")
    {
      dispatch(getFacilityList(facilityFilter))
    }
  }, [facilityFilter, dispatch])

  useEffect(() => {
    setSelectedFacility(props.selectFacility)
  }, [props.selectFacility])

  const selectFacility = (facility) => {
    props?.selectFacility?.(facility);
    setSelectedFacility(facility);
  };

  const facilityNameChange = (e) => {
    setFacilityName(e.target.value);
  };

  const clearName = () => {
    setFacilityName("");
  };

  const facilityStateChange = (e) => {
    setFacilityState(e.target.value);
  };

  const clearState = () => {
    setFacilityState("");
  };

  const submitSearch = () => {
    dispatch(setFacilityList([]))
    setFacilityFilter({name: facilityName, state: facilityState, included: "courses,tees"});
  };

  const getDefaultSearch = () => {
    return (
      <div className="facility-search">
        <GSInput
          textValue={facilityName}
          leftIcon={faSearch}
          rightIconClick={clearName}
          rightIcon={faTimesCircle}
          onChange={facilityNameChange}
          onSubmit={submitSearch}
          placeholder="Facility Name"
        ></GSInput>
        <GSInput
          textValue={facilityState}
          leftIcon={faSearch}
          rightIconClick={clearState}
          rightIcon={faTimesCircle}
          onChange={facilityStateChange}
          onSubmit={submitSearch}
          placeholder="Facility State (Abbreviation)"
        ></GSInput>
      </div>
    );
  };

  const removeFacility = () => {
    props.removeFacility?.(selectedFacility);
    setSelectedFacility(false);
  };

  return (
    <facility-quick-filter>
      <GSQuickFilter
        getDefaultSearch={getDefaultSearch}
        itemSelected={selectFacility}
        loading={loading?.length > 0}
        loadingMainText="Searching for facilities"
        selectedItem={selectedFacility ?? props.selectedFacility}
        filteredList={facilityList}
        itemRemoved={removeFacility}
        emptySearchList="We couldn’t find the facility you’re looking for…"
        searchListType="grey-empty-list rounded-empty-list small-gap search-list"
        getItem={(facility) => <FacilityLineItem {...facility} />}
      />
    </facility-quick-filter>
  );
};

export default FacilityQuickFilter;
