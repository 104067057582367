import React, { useEffect } from "react";
import "./tournament-round-printouts.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import {
  faChevronLeft, faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  getRound,
  getRoundLeaderboards,
  selectCurrentRound,
} from "../../../../reducers/roundSlice";
import { downloadTournamentDocument, selectTournamentNotifications, clearTournamentNotifications } from "../../../../reducers/tournamentSlice";
import NavigationListItem from "../../../navigation-list-item";
import { useState } from "react";
import {useNotificationBanner} from "../../../../hooks/notificationHooks"
import SearchBar from "../../../search-bar";
import { useDocuments } from "../../../../forms/TournamentPrintoutForms";
import { getRoundName } from "../../../../helpers/Converters";

const TournamentRoundPrintouts = (props) => {
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/documents/*");
  const navigate = useNavigate();

  const [searchParams] = useSearchParams()
  const [search, setSearch] = useState((searchParams?.get("search") ?? ""));

  const currentRound = useSelector(selectCurrentRound);
  const notifications = useSelector(selectTournamentNotifications);

  const [teamDocuments, roundDocuments, leaderboardDocuments] = useDocuments(match)
  const currentRoundDocs = roundDocuments.documents.find?.(rd => rd.id === currentRound.id)
  const currentRoundLeaderboardDocs = leaderboardDocuments?.documents?.filter?.(l => l.roundID === currentRound?.id)

  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundLeaderboards(match.params.roundID));
  }, [dispatch, match.params.roundID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: `Round ${getRoundName(currentRound)} Documents `,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const selectPrintout = (page) => {
    if(page.disabled)
    {
      return;
    }
    dispatch(
      downloadTournamentDocument({
        ...page,
        name: `Round${currentRound?.number}-${page.label}`,
      })
    );
  };

  const gotoDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`)
  }

  const gotoTeamDocs = () => {
    navigate(`/${match.params.tournamentID}/teams/documents/?search=${search}`)
  }

  const gotoLeaderboardDocs = () => {
    navigate(`/${match.params.tournamentID}/leaderboards/documents?search=${search}`)
  }

  const getEmptySearchMessage = () => {
    let teamFilteredDocuments = getFilteredDocs(teamDocuments)
    let leaderboardFilteredDocuments = getFilteredDocs(leaderboardDocuments)
    let availableActions = [
      {
        title: "Clear Search",
        onClick: clearSearch,
        type: "light-grey no-wrap",
      },
    ]
    if(teamFilteredDocuments.length > 0)
    {
      availableActions.push(
        {
          title: "Search team documents",
          onClick: gotoTeamDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    else if(leaderboardFilteredDocuments.length > 0)
    {
      availableActions.push(
        {
          title: "Search leaderboard documents",
          onClick: gotoLeaderboardDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    else{
      availableActions.push(
        {
          title: "Search All documents",
          onClick: gotoDocs,
          buttonIcon: faSearch,
          type: "light-grey no-wrap"
        }
      )
    }
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for…"
        detail="Try changing the search term."
        actions={availableActions}
      />
    );
  };

  const getPrintouts = () => {
    return (
      <GSItemList
        type="selectable vertical"
        items={getFilteredPrintouts()}
        itemSelected={selectPrintout}
        listItem={(item) => <NavigationListItem page={item} />}
        emptyMessage={getEmptySearchMessage()}
      />
    );
  };

  const getMatchString = (doc) => {
    let matchString  = `${doc.label} ${doc.details}`.toLowerCase()
    if(doc.documents)
    {
      doc.documents.forEach?.(d => {matchString = `${matchString} ${getMatchString(d)}`})
    }
    return matchString
  }

  const searchMatch = (doc) => {
    return getMatchString(doc)?.includes?.(search?.toLowerCase())
  }

  const getFilteredDocs = (group) => {
    let filtered = group?.documents?.filter?.((doc) => 
      searchMatch(doc)
    )
    return (filtered ?? [])
  }

  const getFilteredPrintouts = () => {
    let roundPrintouts = [
      ...(currentRoundDocs?.documents ?? []),
      ...currentRoundLeaderboardDocs,
    ];
    return roundPrintouts?.filter?.((po) =>
      `${po.label} ${po.details}`.toLowerCase?.()?.includes?.(search)
    );
  };

  const clearSearch = () =>{
    setSearch("")
  }

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header={`Round ${getRoundName(currentRound)} Documents `}/>
        <SearchBar search={search} setSearch={setSearch} placeholder="Search Documents..."></SearchBar>
        {getPrintouts()}
      </gs-form>
    );
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})
  return (
    <tournament-round-printouts>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      />
    </tournament-round-printouts>
  );
};

export default TournamentRoundPrintouts;
