import { GSSidePanelPage, GSForm, GSInput } from "golfstatus_react_components";
import React, { useCallback, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import "./tournament-create-edit.scss";
import {
  selectTournamentNotifications,
  setTournamentNotifications,
  selectCurrentTournament,
  setCurrentTournament,
  createTournament,
  saveTournament,
  updateCurrentTournament,
} from "../../reducers/tournamentSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  faChevronLeft,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getFormSections } from "../../forms/TournamentForms";
import { getFormSections as quickStartForm } from "../../forms/TournamentQuickStartForm";
import {
  getOrganizationList,
  saveOrganization,
  selectOrganizationList,
} from "../../reducers/organizationSlice";
import { getCurrentUser } from "../../app/gs-session";
import {
  getOrganizationMembers,
  selectOrganizationMembers,
} from "../../reducers/userSlice";
import {
  clearUtilityNotifications,
  uploadFile,
  setCroppedTarget,
  selectCroppedTarget,
  selectUploadedFile,
  selectUtilityNotifications,
  clearUtility,
} from "../../reducers/utilitySlice";
import { createFormFromFile } from "../../helpers/Utilities";
import {
  getSaveBannerActions,
  useNotificationAction,
  useNotificationBanner,
} from "../../hooks/notificationHooks";
import { useFormValidation } from "../../hooks/formHooks";

const TournamentCreateEdit = (props) => {
  const currentUser = getCurrentUser();
  const [orgName, setOrgName] = useState("");
  const [memberSearch, setMemberSearch] = useState("");
  const [orgFilter, setOrgFilter] = useState({ userID: currentUser.uid });
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedPointOfContact, setSelectedPointOfContact] =
    useState(currentUser);
  const [tosAccepted, setTosAccepted] = useState(false);

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:id/*");
  const settingsMatch = useMatch("/:id/settings/details");

  //selectors
  const tournamentNotifications = useSelector(selectTournamentNotifications);
  const currentTournament = useSelector(selectCurrentTournament);
  const organizationList = useSelector(selectOrganizationList);
  const firstOrg = organizationList?.[0];
  const organizationMembers = useSelector(selectOrganizationMembers);
  const utilNotifications = useSelector(selectUtilityNotifications);
  const croppedTarget = useSelector(selectCroppedTarget);
  const uploadedFile = useSelector(selectUploadedFile);

  //custom hooks
  useNotificationAction("created", tournamentNotifications, () => {
    dispatch(clearUtilityNotifications());
    dispatch(setTournamentNotifications([]));
    if (props?.quickStart === true) {
      navigate(`/tournaments/success/${currentTournament?.id}`);
    } else {
      navigate(`/${currentTournament?.id}`);
    }
  });

  //effects
  useEffect(() => {
    dispatch(clearUtility());
    return () => {
      dispatch(clearUtility());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentTournament?.organization?.id) {
      setSelectedOrganization(currentTournament?.organization);
    }
    if (currentTournament?.pointOfContact?.id) {
      setSelectedPointOfContact(currentTournament?.pointOfContact);
    }
  }, [currentTournament]);

  useEffect(() => {
    dispatch(getOrganizationList(orgFilter));
  }, [orgFilter, dispatch]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getOrganizationMembers(selectedOrganization.id));
    }
  }, [selectedOrganization, dispatch]);

  useEffect(() => {
    if (
      organizationList.length === 1 &&
      selectedOrganization?.id !== firstOrg?.id &&
      currentTournament?.organization?.id === undefined
    ) {
      setSelectedOrganization(firstOrg);
    }
  }, [
    organizationList?.length,
    dispatch,
    firstOrg,
    selectedOrganization?.id,
    currentTournament?.organization?.id,
  ]);

  useEffect(() => {
    if (props?.quickStart === true) {
      const userEmailAddress = currentUser.email;
      const filteredOrganizations = organizationList.filter(
        (organization) => organization.email === userEmailAddress,
      );

      const sortedOrganizations = filteredOrganizations.sort(
        (a, b) => new Date(b?.tmsActiveUntil) - new Date(a?.tmsActiveUntil),
      );

      if (sortedOrganizations.length > 0) {
        setSelectedOrganization(sortedOrganizations[0]);
      }
    }
  }, [organizationList, currentUser.email, props?.quickStart]);

  useEffect(() => {
    if (props.quickStart === true) {
      const currentOrgName = selectedOrganization?.name || "";
      const updatedTournament = {
        name: `${new Date().getFullYear()} ${currentOrgName} Golf Tournament`,
        description: `Get ready to tee off at ${currentOrgName}'s golf event! Every swing supports ${currentOrgName}'s mission, so join us for good vibes and a great cause. Register early, be part of the fun, and let’s do good together!`,
      };
      dispatch(updateCurrentTournament(updatedTournament));
    }
  }, [selectedOrganization?.name, dispatch, props?.quickStart]);

  const clearName = () => {
    setOrgName("");
    setOrgFilter({});
  };
  const changeName = (e) => {
    setOrgName(e.target.value);
  };
  const submitSearch = () => {
    setOrgFilter({ ...orgFilter, name: orgName });
  };
  const clearMemberSearch = () => {
    setMemberSearch("");
  };
  const changeMemberSearch = (e) => {
    setMemberSearch(e.target.value);
  };

  const accept = () => {
    let update = { ...selectedOrganization };
    update.tosAcceptedAt = new Date();
    update.tosAcceptedBy = currentUser.uid;
    dispatch(saveOrganization(update));
  };

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.clearTournamentName = () => {
    dispatch(
      setCurrentTournament({
        ...currentTournament,
        name: "",
      }),
    );
  };

  context.tosAccepted = tosAccepted;

  context.toggleAccepted = () => {
    setIsValid(true);
    setTosAccepted(!tosAccepted);
  };

  context.updateTournament = (value, property) => {
    setIsValid(true);
    let tourney = { ...currentTournament };
    tourney[property] = value;
    return dispatch(setCurrentTournament(tourney));
  };

  context.getOrganizationSearch = () => {
    return (
      <div className="organization-search">
        <GSInput
          textValue={orgName}
          leftIcon={faSearch}
          rightIconClick={clearName}
          rightIcon={faTimesCircle}
          onChange={changeName}
          onSubmit={submitSearch}
          placeholder="Organization Name"
        ></GSInput>
      </div>
    );
  };

  context.getMemberSearch = () => {
    return (
      <div className="member-search">
        <GSInput
          textValue={memberSearch}
          leftIcon={faSearch}
          rightIconClick={clearMemberSearch}
          rightIcon={faTimesCircle}
          onChange={changeMemberSearch}
          placeholder="Member Name"
        ></GSInput>
      </div>
    );
  };
  context.getOrganizationList = () => {
    return organizationList?.filter?.((org) =>
      org?.name?.toLowerCase?.().includes?.(orgName?.toLowerCase?.()),
    );
  };
  context.getSelectedOrganization = () => {
    return selectedOrganization;
  };
  context.selectOrganization = (organization) => {
    if (!organization.tosAcceptedAt && !props.quickStart) {
      window.open(
        `https://organizations${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/${organization.id}`,
      );
      return;
    }
    setIsValid(true);
    setSelectedOrganization(organization);
  };
  context.removeSelectedOrganization = () => {
    setSelectedOrganization(false);
  };
  context.toggleTournamentWaitlist = () => {
    context.updateTournament(!currentTournament.hasWaitList, "hasWaitList");
  };
  context.togglePreflight = () => {
    context.updateTournament(!currentTournament.preflight, "preflight");
  };
  context.toggleHandicapScoring = () => {
    context.updateTournament(
      !currentTournament.handicapScoring,
      "handicapScoring",
    );
  };
  context.toggleUsePlayerAverageHandicap = () => {
    context.updateTournament(
      !currentTournament.usePlayerAverageHandicap,
      "usePlayerAverageHandicap",
    );
  };
  context.getOrganizationMembers = () => {
    return organizationMembers?.filter?.((member) =>
      member.name?.toLowerCase?.().includes(memberSearch?.toLowerCase?.()),
    );
  };
  context.getSelectedPointOfContact = () => {
    return selectedPointOfContact;
  };
  context.selectPointOfContact = (user) => {
    setIsValid(true);
    setSelectedPointOfContact(user);
  };
  context.removePointOfContact = () => {
    setSelectedPointOfContact(false);
  };
  context.validationFailed = () => {
    if (isValid) {
      setIsValid(false);
    }
  };
  //logo
  context.setLogo = (files) => {
    const file = files[0];
    if (file) {
      context.updateTournament(URL.createObjectURL(file), "logo");
      const data = createFormFromFile(file);
      dispatch(setCroppedTarget({ tournament: currentTournament, form: data }));
    }
  };
  context.removeLogo = () => {
    context.updateTournament("", "logo");
  };
  context.getLogo = () => {
    if (
      currentTournament.logo &&
      currentTournament.logo !== "/logos/default/missing.png"
    ) {
      return [{ url: currentTournament.logo, type: "image" }];
    }
    if (props?.quickStart === true && !currentTournament.logo) {
      return [
        {
          url: "https://api.golfstatus.dev/images/powered_by_golfstatus.jpg",
          type: "image",
        },
      ];
    }
    return [];
  };
  //

  const updloadImage = (callback) => {
    const data = croppedTarget.form;
    return dispatch(uploadFile(data));
  };

  const checkFileUpload = () => {
    if (croppedTarget?.tournament) {
      updloadImage();
    } else {
      createSaveTournament();
    }
  };

  const createSaveTournament = () => {
    let newTournament = { ...currentTournament };
    if (props?.quickStart === true) {
      newTournament.publiclyVisible = true;
      newTournament.registrationOpen = false;
      newTournament.numberOfTeams = "144";
    }
    newTournament.organization = selectedOrganization;
    newTournament.pointOfContact = selectedPointOfContact;
    if (uploadedFile) {
      newTournament.logo = `${process.env.REACT_APP_GOLFSTATUS_BASE_API_URL}${uploadedFile}`;
      dispatch(clearUtility());
    }
    if (match.params.id === "tournaments") {
      dispatch(createTournament(newTournament));
    } else {
      dispatch(saveTournament(newTournament));
    }
  };

  const createSaveCallback = useCallback(createSaveTournament, [
    dispatch,
    currentTournament,
    match.params.id,
    selectedOrganization,
    selectedPointOfContact,
    uploadedFile,
    props?.quickStart,
  ]);

  useEffect(() => {
    if ((uploadedFile ?? "") !== "") {
      createSaveCallback();
    }
  }, [uploadedFile, createSaveCallback]);

  const save = () => {
    if(props.quickStart && !selectedOrganization.tosAcceptedAt) {
      accept();
    }
    setUnsaved(false);
    setIsValid(false);
    checkFileUpload();
  };

  const deleteTournament = () => {
    navigate("delete");
  };

  const getActions = () => {
    let actions = [
      {
        name: settingsMatch ? "Save" : "Save & Continue",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    if (settingsMatch) {
      actions.push({
        name: "Delete",
        action: deleteTournament,
        type: "transparent red",
      });
    }
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    dispatch(clearUtilityNotifications());
    dispatch(setTournamentNotifications([]));
    if (props?.quickStart) {
      navigate("/tournaments");
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: "Tournament Details",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };
  const timeoutAction = () => {
    dispatch(setTournamentNotifications([]));
    dispatch(clearUtilityNotifications());
  };

  const getContent = () => {
    if (props?.quickStart === true) {
      return (
        <GSForm
          formTitle={"Quick Start"}
          formSections={quickStartForm(currentTournament, context)}
        ></GSForm>
      );
    }
    let title = "Create Tournament";
    if (settingsMatch) {
      title = "Edit Tournament";
    }

    return (
      <GSForm
        formTitle={title}
        formSections={getFormSections(currentTournament, context)}
      ></GSForm>
    );
  };
  const getDrawer = () => {
    return {
      actions: getActions(),
    };
  };
  let notificationSettings = {
    notifications: [...tournamentNotifications, ...utilNotifications],
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] =
    useNotificationBanner(notificationSettings);
  return (
    <tournament-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-create-edit>
  );
};

export default TournamentCreateEdit;
