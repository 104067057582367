
import React, { useEffect } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-auction.scss"
import { getAuctionForm } from "../../../forms/PromotionForm";
import { useDispatch, useSelector } from "react-redux";
import { clearTournamentNotifications, createExternalLink, getTournamentExternalLinks, saveExternalLinks, selectCurrentTournament, selectTournamentExternalLinks, selectTournamentNotifications, setAuctionLink } from "../../../reducers/tournamentSlice";
import { useFormValidation } from "../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionAuction = (props) => {
  const navigate = useNavigate()
  const dispatch  = useDispatch()
  const match = useMatch("/:tournamentID/promotion/auction/*")

  const externalLinks = useSelector(selectTournamentExternalLinks)
  const tournamentNotifications = useSelector(selectTournamentNotifications)
  const currentTournament = useSelector(selectCurrentTournament)

  const [context, isValid, setIsValid] = useFormValidation(false);

  const getValidUrl = (link) => {
    let url = link?.externalLink
    if(!url?.startsWith?.("http"))
    {
      url = `http://${url}`
    }
    return url
  }

  context.viewExternalLink = (link) => {
    window.open(getValidUrl(link))
  }

  context.updateAuctionLink = (value) => {
    setIsValid(true)
    dispatch(setAuctionLink(value))
  }

  useEffect(() => {
    dispatch(getTournamentExternalLinks(match.params.tournamentID))
    return () => {dispatch(clearTournamentNotifications())}
  }, [dispatch, match.params.tournamentID])

  const save = () => {
    setUnsaved(false);
    setIsValid(false)
    let update  = {...getAuction()}
    if(getAuction()?.id)
    {
      dispatch(saveExternalLinks(update))
    }
    else{
      update.tournament = currentTournament
      dispatch(createExternalLink(update))
    }
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Auction`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getAuction = () => {
    return externalLinks.find(l => l.linkType === "event_auction")
  }

  const getContent = () => {
    return <GSForm formTitle="Auction" formSections={getAuctionForm(context, getAuction())}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-promotion-auction>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-promotion-auction>
  );
};

//export the new namet
export default TournamentPromotionAuction;