
import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-discount-remove.scss"
import { useSelector } from "react-redux";
import { deleteDiscountCode, selectCurrentDiscountCode, selectNotifications } from "../../../../reducers/discountSlice";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import TournamentDiscountItem from "./tournament-discount-item";
import { useDispatch } from "react-redux";
import { useNotificationBanner, useNotificationNavigation } from "../../../../hooks/notificationHooks";

//Name the component
const TournamentDiscountRemove = (props) => {

  const [isValid] = useState(true)

  //hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch("/:tournamentID/promotion/discounts/:discountID/remove")

  //selectors
  const currentDiscountCode = useSelector(selectCurrentDiscountCode)
  const discountNotifications = useSelector(selectNotifications)

  //custom hooks
  useNotificationNavigation("removed", discountNotifications, -2)

  //component functionality
  const removeDiscountCode = () => {
    dispatch(deleteDiscountCode(match?.params?.discountID))
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removeDiscountCode,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Discount`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle={getRemoveTitle("Delete Discount")} formSections={removeItemForm(currentDiscountCode?.description, <TournamentDiscountItem {...currentDiscountCode}/>)}></GSForm>;
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({notifications: discountNotifications})

  return (
    //name the component tag
    <tournament-discount-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-discount-remove>
  );
};

//export the new namet
export default TournamentDiscountRemove;