import React, { useEffect } from "react";
import "./tournament-round-details.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  setTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { faBolt, faChevronLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { GSSidePanelPage, GSItemList } from "golfstatus_react_components";
import {
  activateScorecards,
  clearRoundNotifications,
  getRound,
  getRoundHoleAssignments,
  getRoundScorecards,
  selectCurrentRound,
  selectCurrentRoundScorecards,
  selectLoading,
  selectRoundNotifications,
} from "../../../reducers/roundSlice";
import { RoundNavigationItems } from "../../../forms/TournamentRoundForms";
import NavigationListItem from "../../navigation-list-item";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import { getRoundName } from "../../../helpers/Converters";

const TournamentRoundDetails = (props) => {
  const dispatch = useDispatch();
  const match = useMatch("/:id/rounds/:roundID/*");
  const navigate = useNavigate();

  const currentRound = useSelector(selectCurrentRound);
  const roundNotifications = useSelector(selectRoundNotifications)
  const scorecards = useSelector(selectCurrentRoundScorecards)
  const loading = useSelector(selectLoading)

  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundHoleAssignments(match.params.roundID));
    dispatch(getRoundScorecards(match.params.roundID));
    return () => {
      dispatch(clearRoundNotifications())
    }
  }, [dispatch, match.params.roundID]);

  const getRoundPageLayout = (page) => {
    return <NavigationListItem page={page} />;
  };

  const navigationItemSelected = (page) => {
    navigate(page.url);
  };

  let context = {};

  context.getLinks = () => {
    return (
      <GSItemList
        type="vertical"
        itemSelected={navigationItemSelected}
        items={RoundNavigationItems(currentRound)}
        listItem={(page) => getRoundPageLayout(page)}
      ></GSItemList>
    );
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const deleteRound = () => {
    navigate("remove")
  }

  const revertRound = () => {
    navigate("deactivate")
  }

  const navigateToHoleAssignments = () => {
    navigate("hole-assignments")
  }

  const createScorecards = () => {
    dispatch(activateScorecards(match.params.roundID));
    navigate("scoring")
  };

  const getActions = () => {
    let actions = [
      
    ];
    if(currentRound.state === "draft"){
      actions.unshift({ name: "Hole Assignments", buttonIcon: faBolt, action: navigateToHoleAssignments, type: "light-grey" })
      actions.push({ name: "Delete Round", action: deleteRound, type: "transparent red" },)
    }
    if(currentRound.state === "ready"){
      actions.unshift({ name: "Start Round", action: createScorecards, type: "green" })
      actions.push({ name: "Delete Round", action: deleteRound, type: "transparent red" },)
    }
    if(currentRound.state === "live"){
      if(scorecards?.length === 0){
        actions.push({ name: "Generate Scorecards", action: createScorecards, type: "black no-wrap" })
      }
      else{
        actions.push({ name: "Edit Scorecards", buttonIcon: faPen, action: () => {navigate("scoring")}, type: "light-grey no-wrap" })
      }
      actions.push({ name: "deactivate round", action: revertRound, type: "light-grey" })
    }
    return actions;
  };

  const getNavigation = () => {
    return {
      title: `Round ${getRoundName(currentRound)} Overview`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
    dispatch(setTournamentNotifications([]));
  };

  const getBannerActions = () => {
    return [
      {
        id: 1,
        title: "back",
        type: "secondary",
        actionClick: leftNavigation,
      },
    ];
  };

  context.isCreate = () => {
    return match?.params?.roundID === "new";
  };

  const getContent = () => {
    return (
      <div className="content">
        {context.getLinks()}
      </div>
    );
  };
  
  const getDrawer = () => {
    return {
      actions: getActions(),
    };
  };

  const [notificationsBanner] = useNotificationBanner({notifications: roundNotifications, bannerActions: getBannerActions(), timeoutAction})

  return (
    <tournament-round-details>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={notificationsBanner}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-round-details>
  );
};

export default TournamentRoundDetails;
