import {
  faCog,
  faFolderOpen,
  faPlus,
  faSearch,
  faTimesCircle,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSActionBar,
} from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import {
  getTournamentLeaderboards,
  getTournamentLeaderboardSettings,
  selectLeaderboardLoading,
  selectTournamentLeaderboardSettings,
  setCurrentLeaderboardSetting,
} from "../../../reducers/leaderboardSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import TournamentLeaderboardLineItem from "./tournament-leaderboard-line-item";

import "./tournament-leaderboards.scss";

const TournamentLeaderboards = (props) => {
  const [search, setSearch] = useState("");

  const match = useMatch("/:tournamentID/leaderboards/*");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const leaderboardSettings = useSelector(selectTournamentLeaderboardSettings);

  const leaderboardLoading = useSelector(selectLeaderboardLoading);

  const currentTournament = useSelector(selectCurrentTournament);

  useEffect(() => {
    dispatch(getTournamentLeaderboardSettings(match.params.tournamentID));
    dispatch(getTournamentLeaderboards(match.params.tournamentID));
  }, [dispatch, match.params.tournamentID]);

  const addLeaderboard = () => {
    dispatch(
      setCurrentLeaderboardSetting({
        roundLeaderboard: false,
        privacy: "public",
      })
    );
    navigate("add");
  };

  const tvLeaderboard = () => {
    window.open(
      `https://users${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/tms/${currentTournament.id}/leaderboards`
    );
  };

  const settings = () => {
    navigate("settings");
  };

  const printouts = () => {
    navigate("documents");
  };

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Add Leaderboard",
        actionClick: addLeaderboard,
        type: "black mobile-icon",
        actionIcon: faPlus,
      },
      {
        id: 1,
        buttonTitle: "Documents",
        actionClick: printouts,
        type: "light-grey mobile-icon",
        actionIcon: faFolderOpen,
      },
      {
        id: 1,
        actionClick: tvLeaderboard,
        type: "light-grey mobile-icon",
        actionIcon: faTv,
      },
      {
        id: 1,
        actionClick: settings,
        type: "light-grey mobile-icon",
        actionIcon: faCog,
      },
    ];
  };

  const getSearchActions = () => {
    return [];
  };

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={() => {
          setSearch("");
        }}
        placeholder="Search Leaderboards…"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></GSInput>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Leaderboards"
        detail="These are the leaderboards"
        actions={[
          {
            title: "Add",
            onClick: addLeaderboard,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the leaderboard you’re looking for…"
        detail="Try changing the search term or add a new leaderboard."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
          {
            title: "Add Leaderboard",
            buttonIcon: faPlus,
            onClick: addLeaderboard,
            type: "black",
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const selectLeaderboard = (leaderboardSetting) => {
    if (isSidePanelOpen()) {
      navigate(`/${match?.params?.tournamentID}/leaderboards`);
      return;
    }
    setCurrentLeaderboardSetting(leaderboardSetting);
    navigate(`${leaderboardSetting.id}`);
  };

  const getFilteredSettings = (isRoundLeaderboard, isSkin) => {
    return leaderboardSettings?.filter?.(
      (ls) =>
        ls?.name?.includes?.(search) &&
        ls.roundLeaderboard === isRoundLeaderboard &&
        ls.skins === isSkin
    );
  };

  const sortLeaderboards = (leaderboards, sortByRound) => {
    if (sortByRound) {
      leaderboards?.sort?.((a, b) => {
        const lowerRoundNumber = a?.tournamentRound?.number;
        const higherRoundNumber = b?.tournamentRound?.number;
        return (
          lowerRoundNumber - higherRoundNumber || a.name.localeCompare(b.name)
        );
      });
    } else {
      leaderboards?.sort?.((a, b) => {
        if (b.main === false) {
          return a.name.localeCompare(b.name);
        }
        return b;
      });
    }
  };

  const getGroups = () => {
    const tournament = getFilteredSettings(false, false);
    const rounds = getFilteredSettings(true, false);
    const skins = getFilteredSettings(true, true);

    sortLeaderboards(tournament, false);
    sortLeaderboards(rounds, true);
    sortLeaderboards(skins, true);

    let group = [];

    if (tournament?.length > 0) {
      group.push({
        list: (
          <div className="leaderbaord-group tournament-leaderboards">
            <GSActionBar
              header="Tournament Leaderboards"
              pageActions={[]}
            ></GSActionBar>
            <GSItemList
              type="vertical selectable"
              items={tournament}
              listItem={(item) => <TournamentLeaderboardLineItem {...item} />}
              itemSelected={selectLeaderboard}
              loading={leaderboardLoading?.includes?.(
                "getTournamentLeaderboardSettings"
              )}
              loadingMainText="Loading Leaderboards"
            ></GSItemList>
          </div>
        ),
      });
    }
    if (rounds?.length > 0) {
      group.push({
        list: (
          <div className="leaderbaord-group round-leaderboards">
            <GSActionBar header="Round Leaderboards"></GSActionBar>
            <GSItemList
              type="vertical selectable"
              items={rounds}
              listItem={(item) => <TournamentLeaderboardLineItem {...item} />}
              itemSelected={selectLeaderboard}
              emptyMessage={
                search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
              }
            ></GSItemList>
          </div>
        ),
      });
    }
    if (skins?.length > 0) {
      group.push({
        list: (
          <div className="leaderbaord-group skins-leaderboards">
            <GSActionBar header="Skins Leaderboards"></GSActionBar>
            <GSItemList
              type="vertical selectable"
              items={skins}
              listItem={(item) => <TournamentLeaderboardLineItem {...item} />}
              itemSelected={selectLeaderboard}
            ></GSItemList>
          </div>
        ),
      });
    }

    return group;
  };

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical leaderboards"
        items={getGroups()}
        listItem={(item) => item.list}
        itemSelected={selectLeaderboard}
        loading={leaderboardLoading?.includes?.(
          "getTournamentLeaderboardSettings"
        )}
        loadingMainText="Loading Leaderboards..."
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  return (
    <tournament-leaderboards>
      <GSListPage
        title="Leaderboards & Skins"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-leaderboards>
  );
};

export default TournamentLeaderboards;
