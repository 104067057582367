import React, { useState } from "react";
import {
  GSEmptyList,
  GSForm,
  GSQuickFilter,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-exclusivity.scss";
import { useDispatch } from "react-redux";
import { packageExclusivityForm } from "../../../../forms/TournamentPackageForm";
import { useSelector } from "react-redux";
import {
  getOrganizationAccessLists,
  selectAccessLists,
} from "../../../../reducers/accessSlice";
import {
  clearNotifications,
  getPackageAccessGroup,
  getPackageRounds,
  getTournamentPackage,
  saveTournamentPackage,
  selectCurrentPackageAccessGroup,
  selectCurrentPackageRounds,
  selectCurrentTournamentPackage,
  selectNotifications,
  setCurrentPackageAccessGroup,
} from "../../../../reducers/packageSlice";
import { selectCurrentTournament } from "../../../../reducers/tournamentSlice";
import { useEffect } from "react";
import OrganizationAccessListItem from "./organization-access-list-item";
import { useFormValidation } from "../../../../hooks/formHooks";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import SearchBar from "../../../search-bar";

//Name the component
const TournamentPackageExclusivity = (props) => {
  const [search, setSearch] = useState("");
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/exclusivity"
  );
  //selectors
  const accessLists = useSelector(selectAccessLists);
  const currentPackage = useSelector(selectCurrentTournamentPackage);
  const currentPackageAccessGroup = useSelector(
    selectCurrentPackageAccessGroup
  );
  const currentTournament = useSelector(selectCurrentTournament);
  const packageRounds = useSelector(selectCurrentPackageRounds);
  const packageNotifications = useSelector(selectNotifications);
  //form context
  const [context, isValid, setIsValid] = useFormValidation(false);

  context.getCurrentPackageAccessGroup = () => {
    return currentPackageAccessGroup;
};

  useEffect(() => {
    if (currentTournament?.organization?.id) {
      dispatch(getOrganizationAccessLists(currentTournament.organization.id));
    }
  }, [dispatch, currentTournament?.organization?.id]);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(getTournamentPackage(match.params.packageID));
      dispatch(getPackageRounds(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  useEffect(() => {
    if (currentPackage?.accessGroup?.id) {
      dispatch(getPackageAccessGroup(currentPackage.accessGroup?.id));
    }
  }, [currentPackage?.accessGroup?.id, dispatch]);

  const getDefaultSearch = () => {
    if(accessLists?.length === 0){
      return null
    }
    let input = {
      search,
      setSearch,
      placeholder: "Search Access Lists…",
      type: "no-pad"
    }
    return (
      <SearchBar {...input}/>
    );
  };

  const getFilteredList = () => {
    return accessLists?.filter?.((al) =>
      al.name?.toLowerCase?.()?.includes?.(search)
    );
  };

  const selectAccessGroup = (item) => {
    setIsValid(true);
    dispatch(setCurrentPackageAccessGroup(item));
  };

  const removeAccessGroup = () => {
    setIsValid(true);
    dispatch(setCurrentPackageAccessGroup({}));
  };

  // const goToOrganization = () => {
  //   const url = `http://organizations${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/${currentTournament?.organization?.id}/access`;
  //   window.open(url);
  // };

  const getEmptyAccessGroupMessage = () => {
    return (
      <GSEmptyList
        title="Package Exclusivity"
        detail={"Organizations can restrict package access to an exclusive list of registrants using Access Lists"}
        actions={[
          // {
          //   title: "Manage Access Groups",
          //   onClick: goToOrganization,
          //   type: "black",
          // },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We could not find the access list you’re looking for…"
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {setSearch("")},
            type: "light-grey",
          },
        ]}
      />
    );
  };

  context.getAccessQuickFilter = () => {
    return (
      <GSQuickFilter
        getItem={(item) => <OrganizationAccessListItem {...item} />}
        selectedItem={
          currentPackageAccessGroup?.id ? currentPackageAccessGroup : false
        }
        itemSelected={selectAccessGroup}
        itemRemoved={removeAccessGroup}
        filteredList={getFilteredList()}
        getDefaultSearch={getDefaultSearch}
        emptySearchList={search !== "" ? getEmptySearchMessage() : getEmptyAccessGroupMessage()}
        searchListType="grey-empty-list rounded-empty-list"
      ></GSQuickFilter>
    );
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let update = { ...currentPackage };
    update.accessGroup = currentPackageAccessGroup?.id
      ? currentPackageAccessGroup
      : null;
    update.tournamentRounds = packageRounds;
    dispatch(saveTournamentPackage(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `${currentPackage?.name} Exclusivity`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFormTitleBar = () => {
    return {
      formTitle: `${currentPackage?.name} Exclusivity`,
      formActions: [
        // {
        //   title: "Manage",
        //   actionIcon: faSitemap,
        //   actionClick: goToOrganization,
        //   type: "black no-wrap",
        // },
      ],
    };
  };

  const getContent = () => {
    return (
      <GSForm
        {...getFormTitleBar()}
        formSections={packageExclusivityForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: packageNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-package-exclusivity>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-exclusivity>
  );
};

//export the new namet
export default TournamentPackageExclusivity;
