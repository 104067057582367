import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const successForm = (name, view, action, disclaimer) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          value: (
            <div className="success-container full-width">
              <div className="disclaimer">{disclaimer}</div>
              <div className="item">{view}</div>
            </div>
          ),
          customView: true,
        },
      ],
    },
  ];
  return form;
};

export const getSuccessTitle = (title) => {
  return (
    <div className="success-header">
      <div className="icon">
        <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
      </div>
      {title}
    </div>
  );
};
