import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";

import "./tournament-packages-item.scss";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const TournamentPackagesItem = (props) => {
  const {
    name,
    packageType,
    packageCost,
    remainingPackages,
    state,
    purchasedCount,
    activateOn,
    deactivateOn,
  } = props;

  const infoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary no-wrap full-width capitalize",
        value: `${packageType} package`,
      },
      {
        type: "secondary no-wrap full-width",
        value: moneyWithCommas(packageCost),
      },
    ],
  };

  const purchaseSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `${purchasedCount} Purchased`,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${remainingPackages} Remaining`,
      },
    ],
  };

  const stateSection = () => {
    let title = state, css = title

    const currentTime = new Date().toISOString();

    if (
      state === "active" &&
      ((deactivateOn && currentTime > deactivateOn) ||
        (activateOn && currentTime < activateOn))
    ) {
      title = "deactivated";
      css = "inactive";
    }

    return {
      sectionItems: [
        {
          type: "primary no-wrap full-width",
          value: (
            <GSButton
              type={`status ${css} secondary pill`}
              title={title}
              buttonIcon={title === "deactivated" ? faCalendar : null}
            />
          ),
        },
      ],
    };
  };

  const infoColumn = {
    fit: "stretch",
    sections: [
      {
        ...infoSection,
      },
      purchaseSection,
      stateSection(),
    ],
  };

  const getGroup = () => {
    let group = [{ ...infoColumn }];
    return group;
  };

  return (
    <tournament-packages-item>
      <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </tournament-packages-item>
  );
};

export default TournamentPackagesItem;
