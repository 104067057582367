import React from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-team-exports.scss";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import NavigationListItem from "../../navigation-list-item";
import SearchBar from "../../search-bar";
import { useState } from "react";
import { useDocuments } from "../../../forms/TournamentPrintoutForms";
import {
  clearTournamentNotifications,
  downloadTournamentDocument,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

//Name the component
const TournamentTeamExports = (props) => {
  const match = useMatch("/:tournamentID/teams/documents");
  const dispatch = useDispatch();
  const [teamDocuments, roundsDocuments, leaderboardDocuments] = useDocuments(
    match
  );

  const navigate = useNavigate();
  const notifications = useSelector(selectTournamentNotifications);

  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams?.get("search") ?? "");

  const clearSearch = () => {
    setSearch("");
  };

  const gotoDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`);
  };

  const gotoRoundDocs = () => {
    navigate(`/${match.params.tournamentID}/rounds/documents?search=${search}`);
  };

  const gotoLeaderboardDocs = () => {
    navigate(
      `/${match.params.tournamentID}/leaderboards/documents?search=${search}`
    );
  };

  const getEmptySearchMessage = () => {
    let roundFilteredDocuments = getFilteredDocs(roundsDocuments);
    let leaderboardFilteredDocuments = getFilteredDocs(leaderboardDocuments);
    let availableActions = [
      {
        title: "Clear Search",
        onClick: clearSearch,
        type: "light-grey no-wrap",
      },
    ];
    if (roundFilteredDocuments.length > 0) {
      availableActions.push({
        title: "Search round documents",
        buttonIcon: faSearch,
        onClick: gotoRoundDocs,
        type: "light-grey no-wrap",
      });
    } else if (leaderboardFilteredDocuments.length > 0) {
      availableActions.push({
        title: "Search leaderboard documents",
        buttonIcon: faSearch,
        onClick: gotoLeaderboardDocs,
        type: "light-grey no-wrap",
      });
    } else {
      availableActions.push({
        title: "Search all documents",
        buttonIcon: faSearch,
        onClick: gotoDocs,
        type: "light-grey no-wrap",
      });
    }
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for…"
        detail="Try changing the search term."
        actions={availableActions}
      />
    );
  };

  const getEmptyTeamsMessage = () => {
    return (
      <GSEmptyList
        title="Team Documents."
        detail="When there are teams in the tournament, you can download documents pertaining to your team herre."
        actions={[
          {
            title: "Create team",
            onClick: gotoDocs,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  const getMatchString = (doc) => {
    let matchString = `${doc.label} ${doc.details}`.toLowerCase();
    if (doc.documents) {
      doc.documents.forEach?.((d) => {
        matchString = `${matchString} ${getMatchString(d)}`;
      });
    }
    return matchString;
  };

  const searchMatch = (doc) => {
    return getMatchString(doc)?.includes?.(search?.toLowerCase());
  };

  const getFilteredDocs = (group) => {
    let filtered = group?.documents?.filter?.((doc) => searchMatch(doc));
    return filtered ?? [];
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Team Documents`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const downloadDocument = (item) => {
    if (item.disabled) {
      return;
    }
    dispatch(downloadTournamentDocument(item));
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Team Documents"/>
        <SearchBar
          search={search}
          setSearch={setSearch}
          placeholder="Search Documents..."
        />
        <GSItemList
          emptyMessage={
            roundsDocuments.documents.length === 0
              ? getEmptyTeamsMessage()
              : getEmptySearchMessage()
          }
          type="vertical  selectable"
          items={getFilteredDocs(teamDocuments)}
          listItem={(item) => <NavigationListItem page={item} />}
          itemSelected={downloadDocument}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-team-exports>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-team-exports>
  );
};

//export the new namet
export default TournamentTeamExports;
