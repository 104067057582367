import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import "./tournament-reporting-order-item.scss";

import { getDateTimeOn } from "../../../helpers/Dates";
import { GSButton } from "golfstatus_react_components";
import { moneyWithCommas } from "../../../helpers/RegexHelper";

const TournamentReportingOrderItem = (props) => {
  const {
    orderPlacedAt,
    status,
    paymentType,
    registrationCustomer,
    registrationOrderItems,
    purchaseCost,
    stripeOrder,
    registrationOrderRefunds,
  } = props;

  const refunded = () => {
    return registrationOrderRefunds?.length > 0;
  };

  const totalRefundedAmount = registrationOrderRefunds?.reduce(
    (total, refund) => {
      return total + refund?.amount;
    },
    0
  );

  const orderSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width capitalize",
        value: paymentType?.replaceAll?.("_", " "),
      },
      {
        type: "secondary no-wrap full-width",
        value: `${getDateTimeOn(orderPlacedAt)}`,
      },
      {
        type: "secondary",
        value: stripeOrder ? `Stripe ID: ${stripeOrder}` : "Online Order",
      },
    ],
  };

  const customerSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: `${registrationCustomer?.fullName}`,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${registrationCustomer?.email}`,
      },
    ],
  };

  const packageSection = {
    sectionItems: [
      {
        type: "secondary full-width",
        value: `${registrationOrderItems
          ?.map((roi) => roi?.tournamentPackage?.name)
          .join?.(", ")}`,
      },
    ],
  };

  const customerColumn = {
    fit: "stretch",
    sections: [
      {
        ...customerSection,
      },
      { ...orderSection },
      { ...packageSection },
    ],
  };

  const color = () => {
    if (refunded()) {
      return "orange";
    } else if (status === "paid" || status === "sent") {
      return "green";
    }
    return "grey";
  };

  const statusSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width flex-end flex-start-mobile",
        value: (
          <GSButton
            type={`${color()} pill secondary`}
            title={refunded() ? "refunded" : status}
          />
        ),
      },
    ],
  };

  const costSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width flex-end flex-start-mobile",
        value: moneyWithCommas(purchaseCost),
      },
    ],
  };

  const refundSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width flex-end flex-start-mobile",
        value: moneyWithCommas(purchaseCost - totalRefundedAmount),
      },
      {
        type: "secondary strike no-wrap full-width flex-end flex-start-mobile",
        value: moneyWithCommas(purchaseCost),
      },
    ],
  };

  const costColumn = {
    sections: [
      {
        ...(refunded() ? refundSection : costSection),
      },
      { ...statusSection },
    ],
  };

  const getGroup = () => {
    let group = [{ ...customerColumn }, { ...costColumn }];
    return group;
  };

  return (
    <tournament-reporting-order-item>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-reporting-order-item>
  );
};

export default TournamentReportingOrderItem;
