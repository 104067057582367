import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faArrowCircleDown,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-rules.scss";
import { useFormValidation } from "../../../../hooks/formHooks";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { useSelector } from "react-redux";
import {
  clearRoundNotifications,
  getRound,
  saveRoundFormat,
  selectCurrentRound,
  selectCurrentRoundFormats,
  selectRoundNotifications,
  setCurrentRoundFormat,
} from "../../../../reducers/roundSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getRulesForm } from "../../../../forms/TournamentRoundForms";
import {
  clearTournamentNotifications,
  downloadTournamentDocument,
  selectTournamentNotifications,
} from "../../../../reducers/tournamentSlice";
import { tournamentRounds } from "../../../../app/api";
import { getRoundName } from "../../../../helpers/Converters";

//Name the component
const TournamentRoundRules = (props) => {
  const dispatch = useDispatch();
  const match = useMatch(":tournamentID/rounds/:roundID/rules");
  const currentRound = useSelector(selectCurrentRound);
  const currentRoundFormats = useSelector(selectCurrentRoundFormats);

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.getData = () => {
    return currentRoundFormats;
  };

  context.setData = (value, format) => {
    setIsValid(true);
    let update = { ...format };
    update.rules = value;
    dispatch(setCurrentRoundFormat(update));
  };

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
    }
    return () => {
      dispatch(clearRoundNotifications());
      dispatch(clearTournamentNotifications());
    };
  }, [dispatch, match?.params?.roundID]);

  const navigate = useNavigate();
  const notifications = [
    ...useSelector(selectRoundNotifications),
    ...useSelector(selectTournamentNotifications),
  ];

  const save = () => {
    setIsValid(false);
    currentRoundFormats.forEach?.((format) => {
      let update = { ...format };
      update.tournamentRound = currentRound;
      dispatch(saveRoundFormat(update));
    });
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${getRoundName(currentRound)} Rules`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getRuleSheetDownload = () => {
    return {
      label: "Rule Sheet",
      details: "Print out the rule sheet.",
      isDownload: true,
      ext: ".pdf",
      url: `${tournamentRounds}/${currentRound.id}/rule-sheet`,
      name: `Round${currentRound.number}-rule-export`,
      id: currentRound.id,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={`Round ${getRoundName(currentRound)} Rules`}
        formActions={[
          {
            title: "Download",
            type: "light-grey",
            actionIcon: faArrowCircleDown,
            actionClick: () => {
              dispatch(downloadTournamentDocument(getRuleSheetDownload()));
            },
          },
        ]}
        formSections={getRulesForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications());
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-round-rules>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-rules>
  );
};

//export the new namet
export default TournamentRoundRules;
