import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flights-auto-assign.scss";
import { useMemo } from "react";
import { getFlightAutoAssignForm } from "../../../forms/FlightingForm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  autoAssignFlights,
  clearNotifications,
  getTournamentFlights,
  getTournamentPreFlights,
  selectFlightNotifications,
  selectTournamentTeamPreflights,
} from "../../../reducers/flightSlice";
import { useEffect } from "react";
import { useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";

//Name the component
const TournamentFlightsAutoAssign = (props) => {
  const [isValid] = useState(true);
  const [sort, setSort] = useState({ label: "Team Handicap", value: "Team Handicap" })
  const [sortDirection, setSortDirection] = useState({ label: "Ascending (lowest to highest)", value: "Ascending" })

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flightNotifications = useSelector(selectFlightNotifications);
  const match = useMatch("/:tournamentID/flighting/assign/auto-assign");

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentFlights(match.params.tournamentID));
      dispatch(getTournamentPreFlights(match.params.tournamentID));
    }
    return () => {dispatch(getTournamentPreFlights(match.params.tournamentID));}
  }, [dispatch, match?.params?.tournamentID]);

  const tournamentPreflights = useSelector(selectTournamentTeamPreflights);

  const context = useMemo(() => ({}), []);

  const playerHandicapSort = (a,b) => {
    if(sortDirection.value === "Ascending")
    {
      return Math.min(...a.tournamentTeam.players.map(p => p.adjustedHandicap)) - Math.min(...b.tournamentTeam.players.map(p => p.adjustedHandicap))
    }
    else {
      return Math.max(...b.tournamentTeam.players.map(p => p.adjustedHandicap)) - Math.max(...a.tournamentTeam.players.map(p => p.adjustedHandicap))
    }
  }

  const teamHandicapSort = (a,b) => {
    if(sortDirection.value === "Ascending")
    {
      return a.tournamentTeam.adjustedHandicap - b.tournamentTeam.adjustedHandicap
    }
    else {
      return b.tournamentTeam.adjustedHandicap - a.tournamentTeam.adjustedHandicap
    }
  }

  const getSort = () => {
    if(sort.value === "Player Handicap")
    {
      return playerHandicapSort
    }
    else{
      return teamHandicapSort
    }
  }

  const getSortedTeamIds = () => {
    if (tournamentPreflights?.length > 0)
    {
      let sortedResults = [...tournamentPreflights[0].teamPreflights].sort(getSort());
      return sortedResults.map(sr => sr.id)
    }
    
  };

  const assignFlights = () => {
    if (match?.params?.tournamentID) {
      const settings = {
        tournamentID: match?.params?.tournamentID,
        teamIds: getSortedTeamIds(),
      };
      dispatch(autoAssignFlights(settings));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Assign Flights",
        action: assignFlights,
        isDisabled: !isValid,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  context.getSort = () => {
    return sort;
  }

  context.getSortDirection = () => {
    return sortDirection;
  }

  context.setSort = (option) => {
    setSort(option)
  }

  context.setSortDirection = (option) => {
    setSortDirection(option)
  }

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Auto Assign Flights`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Auto Assign Flights" formSections={getFlightAutoAssignForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  useNotificationNavigation("saved", flightNotifications, -1, "Flight Teams")

  const [bannerNotifications] = useNotificationBanner({notifications: flightNotifications, timeoutAction})

  return (
    //name the component tag
    <tournament-flights-auto-assign>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flights-auto-assign>
  );
};

//export the new namet
export default TournamentFlightsAutoAssign;
