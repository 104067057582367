import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-package-item-remove.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePackageItem,
  findCurrentPackageItemWithId,
  getAddOnPackageItems,
  getPlayerPackageItems,
  getSkinsAddOnPackageItems,
  getSponsorPackageItems,
  getTeamPackageItems,
  getTechnologySponsorPackageItems,
  selectAvailablePackageItems,
  selectCurrentTournamentPackageItem,
  selectNotifications,
  setCurrentPackageItem,
} from "../../../../reducers/packageSlice";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import TournamentPackageItemsItem from "./tournament-package-items-item";

//Name the component
const TournamentPackageItemRemove = (props) => {
  const [isValid] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/promotion/packages/:packageID/package-items/item/:packageItemID/remove"
  );

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTeamPackageItems(match.params.tournamentID));
      dispatch(getPlayerPackageItems(match.params.tournamentID));
      dispatch(getSponsorPackageItems(match.params.tournamentID));
      dispatch(getTechnologySponsorPackageItems(match.params.tournamentID));
      dispatch(getSkinsAddOnPackageItems(match.params.tournamentID));
      dispatch(getAddOnPackageItems(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  const currentPackageItem = useSelector(selectCurrentTournamentPackageItem);
  const notifications = useSelector(selectNotifications);
  const allPackageItems = useSelector(selectAvailablePackageItems);

  useEffect(() => {
    if (match?.params?.packageID) {
      dispatch(setCurrentPackageItem(match.params.packageID));
    }
  }, [dispatch, match?.params?.packageID]);

  useNotificationNavigation("removed", notifications, -2);

  useEffect(() => {
    if (match?.params?.packageItemID) {
      dispatch(findCurrentPackageItemWithId(match.params.packageItemID));
    }
  }, [allPackageItems?.length, dispatch, match?.params?.packageItemID]);

  const removeItem = () => {
    dispatch(deletePackageItem(currentPackageItem));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: removeItem,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Package Item`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Package Item")}
        formSections={removeItemForm(
          currentPackageItem.name,
          <TournamentPackageItemsItem {...currentPackageItem} />,
          "Delete",
          `Are you sure you want to Delete ${currentPackageItem.name}? This item will be removed from all packages and cannot be undone.`
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({ notifications });

  return (
    //name the component tag
    <tournament-package-item-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-package-item-remove>
  );
};

//export the new namet
export default TournamentPackageItemRemove;
