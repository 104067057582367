import React from "react";
import {
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
  GSActionBar
} from "golfstatus_react_components";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-leaderboards-exports.scss";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";
import { useDocuments } from "../../../forms/TournamentPrintoutForms";
import NavigationListItem from "../../navigation-list-item";
import { useDispatch } from "react-redux";
import {
  clearTournamentNotifications,
  downloadTournamentDocument,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import { useSelector } from "react-redux";
import { useState } from "react";
//Name the component
const TournamentLeaderboardsExports = (props) => {
  const navigate = useNavigate();

  const match = useMatch("/:tournamentID/leaderboards/documents");

  const dispatch = useDispatch();

  const [teamDocuments, roundsDocuments, leaderboardDocuments] = useDocuments(
    match
  );

  const notifications = useSelector(selectTournamentNotifications);

  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams?.get("search") ?? "");

  const clearSearch = () => {
    setSearch("");
  };

  const gotoDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`);
  };

  const gotoTeamDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`);
  };

  const gotoRoundDocs = () => {
    navigate(`/${match.params.tournamentID}/documents?search=${search}`);
  };

  const getEmptySearchMessage = () => {
    let teamFilteredDocuments = getFilteredDocs(teamDocuments);
    let roundFilteredDocuments = getFilteredDocs(roundsDocuments);
    let availableActions = [
      {
        title: "Clear Search",
        onClick: clearSearch,
        type: "light-grey no-wrap",
      },
    ];
    if (teamFilteredDocuments.length > 0) {
      availableActions.push({
        title: "Search team documents",
        onClick: gotoTeamDocs,
        type: "light-grey no-wrap",
      });
    } else if (roundFilteredDocuments.length > 0) {
      availableActions.push({
        title: "Search round documents",
        onClick: gotoRoundDocs,
        type: "light-grey no-wrap",
      });
    } else {
      availableActions.push({
        title: "Search All documents",
        buttonIcon: faSearch,
        onClick: gotoDocs,
        type: "light-grey no-wrap",
      });
    }
    return (
      <GSEmptyList
        title="We couldn’t find the document you’re looking for…"
        detail="Try changing the search term."
        actions={availableActions}
      />
    );
  };

  const getEmptyLeaderboardsMessage = () => {
    return (
      <GSEmptyList
        title="No rounds are currently live."
        detail="Leaderboard documents will be available for download after tournament rounds have been started."
        actions={[
          {
            title: "View Rounds",
            type: "black",
            onClick: () => {
              navigate(`/${match?.params?.tournamentID}/rounds`);
            },
          },
        ]}
      />
    );
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Leaderboard Documents`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const itemSelected = (item) => {
    if (item.disabled) {
      return;
    }
    dispatch(downloadTournamentDocument(item));
  };

  const getFilteredDocs = (group) => {
    let filtered = group?.documents?.filter?.((doc) =>
      doc?.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
    );
    return filtered;
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header={"Leaderboard Documents"} />
        <SearchBar
          search={search}
          setSearch={setSearch}
          placeholder="Search Documents…"
          type="sticky-side-panel pad"
        ></SearchBar>
        <GSItemList
          emptyMessage={
            search !== ""
              ? getEmptySearchMessage()
              : getEmptyLeaderboardsMessage()
          }
          type="vertical  selectable"
          items={getFilteredDocs(leaderboardDocuments)}
          listItem={(item) => <NavigationListItem page={item} />}
          itemSelected={itemSelected}
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-leaderboards-exports>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-leaderboards-exports>
  );
};

//export the new namet
export default TournamentLeaderboardsExports;
