import "./tournament-team-notes.scss";
import React, { useEffect } from "react";
import {
  GSActionBar,
  GSButton,
  GSCircleImage,
  GSEmptyList,
  GSInfoGroup,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentTeam,
  getTournamentTeam,
} from "../../../reducers/teamsSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

//Name the component
const TournamentTeamNotes = (props) => {
  const currentTeam = useSelector(selectCurrentTeam);
  const currentTournament = useSelector(selectCurrentTournament)

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/teams/:teamID/notes");

  useEffect(() => {
    dispatch(getTournamentTeam(match.params.teamID));
  }, [dispatch, match.params.teamID]);


  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Player Notes`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getPlayersWithMessages = () => {
    return currentTeam.players?.filter?.((p) => p.signupMessage);
  };

  const emailuser = (player) => {
    window.open(`mailto:${player.email}?subject=${currentTournament.name}`)
  }

  const getGroup = (player) => {
    let message = [
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              {
                type: "primary",
                value: <GSCircleImage size="x-large" url={player.avatar} />,
              },
            ],
          },
          {
            sectionItems: [
              { type: "primary capitalize no-wrap", value: player.name },
              { type: "secondary no-wrap", value: player.email },
              { type: "secondary capitalize no-wrap", value: player.phone },
            ],
          },
          {
            sectionItems: [{ type: "secondary message-bubble", value: player.signupMessage }],
          },
        ],
      },

    ];

    if(player.email)
    {
      message.push({
        sections: [
          {
            sectionItems: [{ type: "secondary", value: <GSButton onClick={() => {emailuser(player)}} buttonIcon={faEnvelope}></GSButton> }],
          },
        ],
      },)
    }

    return message
  };

  const getEmptyMessage = () => {
    return (
      <GSEmptyList
        title="View Player Notes"
        detail="Notes can be left by players during registration. Also, notes can be added manually via the player details page."
      />
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Player Notes"></GSActionBar>
        <GSItemList
          type="vertical"
          emptyMessage={getEmptyMessage()}
          items={getPlayersWithMessages()}
          listItem={(player) => (
            <div className="player-message">
              <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup(player)} />
            </div>
          )}
        ></GSItemList>
      </gs-form>
    );
  };

  return (
    //name the component tag
    <tournament-team-notes>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-team-notes>
  );
};

//export the new namet
export default TournamentTeamNotes;
