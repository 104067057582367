import React, { useEffect } from "react";
import "./tournament-round-flights.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSInfoGroup,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  getRound,
  getRoundFlights,
  selectCurrentRound,
  selectCurrentRoundFlights,
} from "../../../../reducers/roundSlice";

const TournamentRoundFlights = (props) => {
  const dispatch = useDispatch();
  const match = useMatch("/:id/rounds/:roundID/flights/*");
  const navigate = useNavigate();

  const currentRound = useSelector(selectCurrentRound);
  const currentRoundFlights = useSelector(selectCurrentRoundFlights);

  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundFlights(match.params.roundID));
  }, [dispatch, match.params.roundID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: `Round ${currentRound.number} Flights `,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFlightGroup = (flight) => {
    let group = [
      {
        fit: "no-wrap",
        sections: [
          {
            sectionItems: [
              { type: "primary", value: flight.tournamentFlight.position },
            ],
          },
        ],
      },
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              {
                type: "primary",
                value: flight.tournamentFlight.name,
              },
              { type: "secondary", value: flight.flightRoundTees.map(t => `${t.roundTee.name} Tee`).join(", ") },
            ],
          },
        ],
      },
      {
        sections: [
          {
            sectionItems: [
              { type: "secondary", value: <GSButton buttonIcon={faBars} /> },
            ],
          },
        ],
      },
    ];

    return group;
  };

  const getFlightList = () => {
    return (
      <GSItemList
        type="selectable vertical"
        items={currentRoundFlights}
        listItem={(item) => (
          <div className="flight">
            <GSInfoGroup
              dataGroups={getFlightGroup(item)}
            ></GSInfoGroup>
          </div>
        )}
      />
    );
  };

  const getContent = () => {
    return <div className="content">{getFlightList()}</div>;
  };

  return (
    <tournament-round-flights>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
      />
    </tournament-round-flights>
  );
};

export default TournamentRoundFlights;
