import {
  faFilter,
  faFolderOpen,
  faHandHoldingUsd,
  faSearch,
  faStream,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSActionBar,
  GSButton,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import {
  getEventOrderSummary,
  getOrders,
  selectOrderFilter,
  selectOrders,
  selectOrderSummary,
  setOrderFilter,
} from "../../../reducers/reportingSlice";
import TournamentReportingOrderItem from "./tournament-reporting-order-item";
import "./tournament-reporting.scss";
import TournamentReportingOrderSummary from "./tournament-reporting-order-summary";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";

const TournamentReporting = (props) => {
  const [search, setSearch] = useState("");

  const match = useMatch("/:tournamentID/reporting/*");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tournamentOrders = useSelector(selectOrders);
  const tournamentEventOrderSummary = useSelector(selectOrderSummary);
  const currentTournament = useSelector(selectCurrentTournament);
  const orderFilter = useSelector(selectOrderFilter);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getOrders(match.params.tournamentID));
      dispatch(getEventOrderSummary(match.params.tournamentID));
    }
  }, [match?.params?.tournamentID, dispatch]);

  const getFilterButtons = () => {
    return orderFilter.map?.((filter) => {
      return {
        name: filter.name,
        removeAction: () => {
          dispatch(
            setOrderFilter(orderFilter.filter?.((f) => f.name !== filter.name)),
          );
        },
      };
    });
  };

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Payouts",
        type: "black mobile-icon",
        actionClick: () => {
          navigate("payouts");
        },
        actionIcon: faHandHoldingUsd,
      },
      {
        id: 1,
        buttonTitle: "Documents",
        type: "light-grey mobile-icon",
        actionIcon: faFolderOpen,
        actionClick: () => {
          navigate("documents");
        },
      },
    ];
  };

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={() => {
          setSearch("");
        }}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder="Search Orders..."
      ></GSInput>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Manage Orders"
        detail="View orders, manage refunds, request payouts, and download reports."
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the order you’re looking for…"
        detail="Try changing the search term and filter or contact GolfStatus support."
        actions={[
          {
            title: "Clear All",
            type: "light-grey",
            onClick: () => {
              setSearch("");
              dispatch(setOrderFilter([]));
            },
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const itemSelected = (item) => {
    if (!isSidePanelOpen()) {
      navigate(item.id);
    } else {
      navigate(-1);
    }
  };

  const filteredOrders = () => {
    let filteredItems = tournamentOrders?.filter?.((to) => {
      const tournamentPackageNames = to.registrationOrderItems?.map((item) =>
        item.tournamentPackage?.name?.toLowerCase?.(),
      );
      const allTournamentPackageNames = tournamentPackageNames?.join(" ");
      const totalCostWithFees = parseFloat(to?.totalCostWithFees)?.toFixed(2);

      return `${to.registrationCustomer?.fullName?.toLowerCase?.()} ${to.registrationCustomer?.email?.toLowerCase?.()} ${allTournamentPackageNames} ${to?.stripeOrder?.toLowerCase?.()} $${totalCostWithFees} ${
        to?.last4
      }`.includes?.(search?.toLowerCase?.());
    });

    orderFilter.forEach?.((filter) => {
      filteredItems = filteredItems.filter((item) => filter.filter(item));
    });

    filteredItems.sort(
      (a, b) => new Date(b?.orderPlacedAt) - new Date(a?.orderPlacedAt),
    );

    return filteredItems;
  };

  const searchActions = () => {
    return [
      {
        actionIcon: faStream,
        actionClick: () => {
          navigate("filter");
        },
      },
    ];
  };

  const getItemList = () => {
    const filterButtons = getFilterButtons();
    return (
      <div className="content">
        <div className="hero">
          <TournamentReportingOrderSummary
            {...tournamentEventOrderSummary}
            showRequest={
              currentTournament.state === "final" &&
              currentTournament?.organization?.stripeId &&
              tournamentEventOrderSummary?.availableFunds > 0
            }
          />
        </div>
        <GSActionBar
          type="reporting-header"
          pageActions={searchActions()}
          header={<div className="reporting-search">{getDefaultSearch()}</div>}
        />
        {filterButtons?.length > 0 && (
          <div className="filter-buttons">
            <GSItemList
              itemSelected={(button) => button.removeAction()}
              type="horizontal medium-gap"
              items={filterButtons}
              listItem={(button) => (
                <GSButton
                  title={button.name}
                  type="black pill secondary"
                  rightIcon={faTimesCircle}
                ></GSButton>
              )}
            ></GSItemList>
          </div>
        )}
        <GSItemList
          type="vertical selectable orders"
          items={filteredOrders()}
          listItem={(item) => <TournamentReportingOrderItem {...item} />}
          emptyMessage={
            search !== "" || orderFilter.length > 0
              ? getEmptySearchMessage()
              : getEmptyListMessage()
          }
          itemSelected={itemSelected}
        ></GSItemList>
      </div>
    );
  };

  return (
    <tournament-reporting
      onClick={(e) => {
        if (isSidePanelOpen()) {
          navigate(-1);
        }
      }}
    >
      <GSListPage
        title="Orders & Payouts"
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-reporting>
  );
};

export default TournamentReporting;
