import React from "react";
import "./member-line-item.scss";

import {
  GSInfoGroup,
  GSCircleImage,
} from "golfstatus_react_components";

const MemberLineItem = (props) => {
  const { name, roles, avatar, email, type, firstName, lastName } = props;
  const getInfoGroup = () => {
    const group = [
      {
        title: <GSCircleImage size="large" url={avatar} />,
      },
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              { type: "primary", value: name ?? `${firstName} ${lastName}` },
              { type: "secondary", value: email },
              { type: "secondary", value: roles?.join?.(", ") ?? "Member" },
            ],
          },
          {
            sectionItems: [
              {
                type: "secondary",
                value: (
                  <div className="user-type">
                    {type ?? "Organization Member"}
                  </div>
                ),
              },
            ],
          },
        ],
      },
    ];
    
    return group;
  };
  return (
    <member-line-item>
      <GSInfoGroup type="list-item x-large-pad" dataGroups={getInfoGroup()} />
    </member-line-item>
  );
};

export default MemberLineItem;
