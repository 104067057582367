import React from "react";
import "./organization-line-item.scss";

import { GSInfoGroup, GSImage, GSButton } from "golfstatus_react_components";

import { getDate } from "../helpers/Dates";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const OrganizationLineItem = (props) => {
  const {
    name,
    logo,
    createdAt,
    type,
    organizationType,
    facilities,
    tosAcceptedAt
  } = props;
  const getImageLayout = () => {
    return (
      <GSImage
        src={logo}
        size={type === "compact" ? "x-small" : "small"}
        ratio="wide"
      />
    );
  };
  const getType = () => {
    return organizationType !== "unknown"
      ? organizationType?.replace?.("_", " ")
      : "Default Organization";
  };
  const getFacilities = () => {
    const facilitySet = [...new Set(facilities?.map?.((f) => f.name))];
    return facilitySet.join?.(", ");
  };
  const getInfoGroup = () => {
    let imageGroup = {
      fit: "hide-mobile",
      sections: [
        {
          sectionItems: [{ value: getImageLayout() }],
        },
      ],
    };
    let infoGroup = {
      fit: "stretch",
      sections: [
        {
          sectionItems: [
            { type: "primary", value: name },
            { type: "secondary capitalize", value: getType() },
          ],
          
        },
      ],
    };
    if(!tosAcceptedAt){
      infoGroup.sections.push({
        sectionItems:[
          {type: "secondary", value: <GSButton buttonIcon={faExclamationCircle} type="secondary orange" title="TOS required"/>}
        ]
      })
    }
    const grp = [imageGroup, infoGroup];
    if (type !== "compact") {
      infoGroup.sections.push({
        sectionItems: [
          { type: "secondary", value: getFacilities() },
        ],
      });
      grp.push({
        sections: [
          {
            sectionItems: [
              {
                type: "secondary no-wrap hide-mobile",
                value: `Created ${getDate(createdAt)}`,
              },
            ],
          },
        ],
      });
    }

    return grp;
  };

  return (
    <organization-line-item class={type}>
      <GSInfoGroup
        type="list-item x-large-pad"
        dataGroups={getInfoGroup()}
      ></GSInfoGroup>
    </organization-line-item>
  );
};

export default OrganizationLineItem;
