import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-promotion-visibility.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  createPromotion,
  saveTournamentPromotions,
  selectCurrentTournament,
  selectTournamentNotifications,
  setCurrentTournament,
} from "../../../reducers/tournamentSlice";
import { getEventPageVisibilityForm } from "../../../forms/PromotionForm";
import { useFormValidation } from "../../../hooks/formHooks";
import { getSaveBannerActions, useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentPromotionVisibility = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentNotifications = useSelector(selectTournamentNotifications)

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.updateTournamentPromotion = (value, property) => {
    setIsValid(true);
    let updateTournament = { ...currentTournament };
    let updateTournamentPromotion = {
      ...currentTournament.tournamentPromotion,
    };
    updateTournamentPromotion[property] = value;
    updateTournament.tournamentPromotion = updateTournamentPromotion;
    dispatch(setCurrentTournament(updateTournament));
  };

  const save = () => {
    setUnsaved(false);
    setIsValid(false)
    let promotion = { ...currentTournament.tournamentPromotion };
    promotion.tournament = currentTournament;
    if(promotion.id)
    {
      dispatch(saveTournamentPromotions(promotion));
    }
    else{
      dispatch(createPromotion(promotion))
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Additional Event Site Pages`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle="Additional Event Site Pages"
        formSections={getEventPageVisibilityForm(context, currentTournament)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: tournamentNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-promotion-visibility>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-promotion-visibility>
  );
};

//export the new namet
export default TournamentPromotionVisibility;
