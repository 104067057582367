import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons";
import { GSInfoGroup, GSButton } from "golfstatus_react_components";
import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { getDateTimeOn } from "../../../helpers/Dates";
import "./tournament-reporting-order-summary.scss";
import { moneyWithCommas } from "../../../helpers/RegexHelper"
import { useSelector } from "react-redux";
import { getPayouts, selectPayouts } from "../../../reducers/reportingSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const TournamentReportingOrderSummary = (props) => {
  const {
    availableFunds,
    // escrow,
    // infoiceTotal,
    lastPayoutDate,
    netRevenue,
    //organizationName,
    payoutTotal,
    refundTotal,
    registrationOrderCount,
    registrationOrderRefundCount,
    // tournamentEndsAt,
    // tournamentStartsAt,
    // tournamentState,
    // tournamentTitle,
    showRequest,
    hideNetRevenue,
    hidePayouts,
    hideRefunds
  } = props;

  const navigate = useNavigate()
  const payouts = useSelector(selectPayouts)

  const dispatch = useDispatch()
  const match = useMatch("/:tournamentID/*")

  useEffect(() => {
    if(match?.params?.tournamentID ?? !hidePayouts)
    {
      dispatch(getPayouts(match.params.tournamentID))
    }
  }, [dispatch, match?.params?.tournamentID, hidePayouts])

  const availableFundsSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: "Available Funds",
      },
    ],
  };

  const availableFundsSection2 = {
    sectionItems: [
      {
        type: "marquee no-wrap full-width",
        value: moneyWithCommas(availableFunds),
      },
    ],
  };

  const availableFundsSection3 = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `as of ${getDateTimeOn(((lastPayoutDate ?? undefined)))}`,
      },
    ],
  };

  const availableFundsSection4 = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSButton type="black" buttonIcon={faHandHoldingUsd} title="request Payout" onClick={()=> {navigate("payouts/request")}}/> ,
      },
    ],
  };

  let availableFundsColumn = {
    fit: "stretch",
    sections: [
      {
        ...availableFundsSection,
      },
      {...availableFundsSection2},
      {...availableFundsSection3},
    ],
  };

  if(showRequest){
    availableFundsColumn.sections.push({...availableFundsSection4})
  }

  const netRevenueSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: "Net Revenue",
      },
      {
        type: "primary no-wrap full-width",
        value: moneyWithCommas(netRevenue),
      },
    ],
  };

  const netRevenueSection2 = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `${registrationOrderCount} Orders`,
      },
    ],
  };

  const netRevenueColumn = {
    fit: "border",
    sections: [
      {
        ...netRevenueSection,
      },
      {...netRevenueSection2},
    ],
  };

  const payoutTotalSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: "Paid Out",
      },
      {
        type: "primary no-wrap full-width",
        value: moneyWithCommas(payoutTotal),
      },
    ],
  };

  const payoutTotalSection2 = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `${(payouts?.length ?? 0)} Payouts`,
      },
    ],
  };

  const payoutTotalColumn = {
    fit: "border",
    sections: [
      {
        ...payoutTotalSection,
      },
      {...payoutTotalSection2},
    ],
  };

  const refundTotalSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: "Refunds",
      },
      {
        type: "primary no-wrap full-width",
        value: moneyWithCommas(refundTotal),
      },
    ],
  };

  const refundTotalSection2 = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: `${registrationOrderRefundCount} Refunds`,
      },
    ],
  };

  const refundTotalColumn = {
    fit: "border",
    sections: [
      {
        ...refundTotalSection,
      },
      {...refundTotalSection2},
    ],
  };

  const getGroup = () => {
    let group = [{ ...availableFundsColumn }];
    if(!hideNetRevenue)
    {
      group.push({...netRevenueColumn})
    }
    if(!hidePayouts){
      group.push({...payoutTotalColumn})
    }

    if(!hideRefunds)
    {
      group.push({...refundTotalColumn})
    }
    return group;
  };

  return (
    <tournament-reporting-order-summary>
      <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
    </tournament-reporting-order-summary>
  );
};

export default TournamentReportingOrderSummary;
