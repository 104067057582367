import { useEffect, useState } from "react";

export const useResizeSplit = () => {
  const [splitNines, setSplitNines] = useState(false);

  const windowRezized = (e) => {
    if (e?.target?.innerWidth < 600) {
      setSplitNines(true);
    } else {
      setSplitNines(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setSplitNines(true);
    } else {
      setSplitNines(false);
    }
    window.addEventListener("resize", windowRezized);
    return () => window.removeEventListener("resize", windowRezized);
  }, []);

  return splitNines
}


export const useMinSize= (width) => {
  const [value, setValue] = useState(false);

  const windowRezized = (e) => {
    if (e?.target?.innerWidth < width) {
      setValue(true);
    } else {
      setValue(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setValue(true);
    } else {
      setValue(false);
    }
    window.addEventListener("resize", windowRezized);
    return () => window.removeEventListener("resize", windowRezized);
    // eslint-disable-next-line
  }, []);

  return value
}