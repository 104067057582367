import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";

import "./tournament-team-item.scss";

const TournamentTeamItem = (props) => {
  const {
    name,
    contactName,
    players,
    adjustedHandicap,
    flight,
    mainLeaderboardScoreDetails,
  } = props;

  //const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

  const contactInfo = {
    sectionItems: [
      {
        type: "primary full-width capitalize",
        value: `${name ?? `${contactName}'s Team`} ${
          adjustedHandicap > 0 ? `(${adjustedHandicap})` : ""
        }`,
      },
      {
        type: "secondary full-width",
        value: players
          ?.map?.((p) => `${p.name} (${p.adjustedHandicap})`)
          ?.join?.(", "),
      },
    ],
  };

  const playerInfo = {
    sectionItems: [
      {
        type: "secondary full-width",
        value: players
          ?.map?.((p) => `${p.name} (${p.adjustedHandicap})`)
          ?.join?.(", "),
      },
    ],
  };

  const flightInfo = {
    sectionItems: [
      {
        type: "secondary",
        value: `${flight?.name} flight`,
      },
    ],
  };

  const teamColumn = {
    fit: "stretch",
    sections:
      players?.length === 1 ? [{ ...playerInfo }] : [{ ...contactInfo }],
  };

  if (mainLeaderboardScoreDetails?.position) {
    flightInfo.sectionItems.unshift({
      type: "secondary",
      value: mainLeaderboardScoreDetails?.position,
    });
  }

  if (flight) {
    teamColumn.sections.push(flightInfo);
  }

  const getGroup = () => {
    let group = [{ ...teamColumn }];
    return group;
  };

  return (
    <tournament-team-item>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-team-item>
  );
};

export default TournamentTeamItem;
