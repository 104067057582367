import {
  GSButton,
  GSRadioGroup,
  GSSelect,
  GSToggle,
} from "golfstatus_react_components";
import TournamentLeaderboardLineItem from "../components/tournament/tournament-leaderboards/tournament-leaderboard-line-item";
import { getSelectedTieBreaker, tieBreakerMethods } from "./TournamentForms";

export const AddLeaderboardForm = (context, leaderboardSetting) => {
  if (context) {
    let settings = {
      type: "vertical",
      fields: [
        {
          label: "Leaderboard Type",
          value: (
            <GSRadioGroup
              options={context.getLeaderboardKinds()}
              selectedOption={context?.getLeaderboardKind()}
              selectionChanged={context?.setLeaderboardKind}
            ></GSRadioGroup>
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };

    let form = [{ ...settings }];

    if (context?.getLeaderboardKind?.()?.value !== 1) {
      form.push({
        title: "Leaderboard Round",
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Round",
            value: (
              <GSSelect
                isDisabled={!context.isAdd?.()}
                options={context?.getTournamentRounds?.()}
                selectedOption={context?.getSelectedRound?.()}
                onChange={context?.selectRound}
                placeholder="Select a Round..."
              />
            ),
            customView: true,
            isEditable: true,
            required: true,
            failedValidation: context.validationFailed,
            validation: {
              isValid: () => context?.getSelectedRound?.(),
              invalidLabel: "Round is required",
            },
          },
        ],
      });
    }

    form.push({
      title: "Leaderboard Setup",
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "leaderboard",
          failedValidation: context.validationFailed,
          validation: {
            isValid: () => context.getSelectedType?.()?.id ?? false,
            invalidLabel: "Round Facility is required",
          },
          value: (
            <GSSelect
              onChange={context.setType}
              isDisabled={!context.isAdd?.()}
              selectedOption={
                context.getSelectedType()?.id
                  ? {
                      label: context.getSelectedType?.()?.displayName,
                      value: context.getSelectedType?.(),
                    }
                  : null
              }
              options={context?.getLeaderboardTypes?.()}
              placeholder="Select a Leaderboard..."
            ></GSSelect>
          ),
          customView: true,
          isEditable: true,
          required: true,
        },
        {
          label: "Custom Name",
          value: leaderboardSetting?.customName,
          onChange: (e) =>
            context?.updateLeaderboardSetting(e.target.value, "customName"),
          placeholder: "Custom Name",
          isEditable: true,
        },
        {
          label: "privacy",
          value: (
            <GSRadioGroup
              selectionChanged={(option) => {
                context?.updateLeaderboardSetting(option.value, "privacy");
              }}
              options={getPrivacyOptions()}
              selectedOption={getSelectedPrivacy(leaderboardSetting.privacy)}
            ></GSRadioGroup>
          ),
          customView: true,
          isEditable: true,
          required: true,
        },
      ],
    });

    if (context?.getLeaderboardKind()?.value === 1) {
      const disableMainToggle = !context.mainChanged() && leaderboardSetting.main;

      let additionalFields = [
        {
          label: "Main Leaderboard",
          value: (
            <GSToggle
              label="This is the default leaderboard and used to assign teams to holes of multi-round tournaments."
              trueDescription="Yes"
              falseDescription="No"
              value={leaderboardSetting.main ?? false}
              onClick={context.setMainLeaderboard}
              disabled={disableMainToggle}
            ></GSToggle>
          ),
          hintText: disableMainToggle ? "Tournaments must have one main leaderboard. Set a new main leaderboard from any existing tournament leaderboard." : '',
          customView: true,
          isEditable: true,
        }
      ]

      if(!leaderboardSetting.main){
        additionalFields.push(
          {
            label: "Flighted Leaderboard",
            value: (
              <GSToggle
                value={leaderboardSetting.flighted ?? false}
                label="This will display leaderboards for each flight."
                trueDescription="Yes"
                falseDescription="No"
                onClick={() => {
                  context.updateLeaderboardSetting?.(
                    !leaderboardSetting.flighted,
                    "flighted"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          }
        )
      }

      form.push({
        title: "Additional Leaderboard Details",
        type: "vertical xx-large-gap",
        fields: additionalFields,
      });
    } else if (context?.isPreflight()) {
      form.push({
        title: "Additional Leaderboard Details",
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Flighted Leaderboard",
            value: (
              <GSToggle
                value={leaderboardSetting.flighted ?? false}
                label="This will display leaderboards for each flight."
                ttrueDescription="Yes"
                falseDescription="No"
                onClick={() => {
                  context.updateLeaderboardSetting?.(
                    !leaderboardSetting.flighted,
                    "flighted"
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      });
    }

    return form;
  }
};

const getPrivacyOptions = () => {
  return [
    { label: "Public", value: "public" },
    { label: "Private", value: "private" },
  ];
};

const getSelectedPrivacy = (privacy) => {
  return getPrivacyOptions()?.find?.((o) => o.value === privacy);
};

export const leaderboardSettingsForm = (context, tournament) => {
  if (context) {
    let tieBreakerInformation = {
      title: "Tiebreaks",
      type: "vertical large-gap",
      fields: [
        {
          label: "Tiebreaking Method",
          value: (
            <GSRadioGroup
              options={tieBreakerMethods}
              selectionChanged={(method) => {
                context.updateTournament(method.value, "tieBreakerMethod");
              }}
              selectedOption={getSelectedTieBreaker(tournament)}
            />
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };
    let deleteFlightInformation = {
      title: "Flighted Leaderboards",
      type: "vertical large-gap",
      fields: [
        {
          label: "Destroy Flights Calculations",
          description:
            "Destroy all flight calculations and reactivate the tournament.",
          value: (
            <GSButton
              title="Destroy Flight Calculations"
              type="red"
              onClick={context?.destroyFlights}
            ></GSButton>
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };
    let createFlightInformation = {
      title: "Flighted Leaderboards",
      type: "vertical large-gap",
      fields: [
        {
          label: "Calculate Flights",
          description:
            "Calculate all scores, create flights based on the results, and finalize the tournament.",
          value: (
            <GSButton
              title="Calculate Flights"
              type="green"
              onClick={context?.createFlights}
            ></GSButton>
          ),
          customView: true,
          isEditable: true,
        },
      ],
    };
    let form = [{ ...tieBreakerInformation }];
    if (!tournament.preflight && tournament?.numberOfFlights > 0) {
      if (tournament.state === "final") {
        form.push({ ...deleteFlightInformation });
      } else {
        form.push({ ...createFlightInformation });
      }
    }

    return form;
  }
};

//remove team
export const removeLeaderboardForm = (leaderboard) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-leaderboard">
          <div className="disclaimer">{`Are you sure you want to remove ${leaderboard?.name} from your tournament? This cannot be undone.`}</div>
          <TournamentLeaderboardLineItem {...leaderboard} />
        </div>
      ),
    },
  ];
  return form;
};

//remove team
export const markAllSkinsForm = (leaderboard) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="mark-all">
          <div className="disclaimer">{`Are you sure you want to update all the teams for this skins game ? This will override the current selection and cannot be undone.`}</div>
          <TournamentLeaderboardLineItem {...leaderboard} />
        </div>
      ),
    },
  ];
  return form;
};
