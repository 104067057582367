import { Route } from "react-router-dom"

import TournamentPromotion from "../../components/tournament/tournament-promotion/tournament-promotion";
import TournamentPromotionRegistration from "../../components/tournament/tournament-promotion/tournament-promotion-registration";
import TournamentPromotionEventDetails from "../../components/tournament/tournament-promotion/tournament-promotion-event-details";
import TournamentPromotionReceiptCustomization from "../../components/tournament/tournament-promotion/tournament-promotion-receipt-customization";
import TournamentPromotionAuction from "../../components/tournament/tournament-promotion/tournament-promotion-auction";
import TournamentPromotionVisibility from "../../components/tournament/tournament-promotion/tournament-promotion-visibility";
import TournamentPromotionPackages from "../../components/tournament/tournament-promotion/tournament-packages/tournament-promotion-packages";
import TournamentPackageCreateEdit from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-create-edit";
import TournamentPackageItemCreateEdit from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-item-create-edit";
import TournamentPackageDetails from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-details";
import TournamentPackageItems from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-items";
import TournamentPackageRemove from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-remove";
import TournamentPackageItemRemove from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-item-remove";
import TournamentPackageWatchEmails from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-watch-emails";
import TournamentPackageForms from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-forms";
import TournamentPackagePlayerInfo from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-player-info";
import TournamentPackageActivation from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-activation";
import TournamentPackageFormCreateEdit from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-form-create-edit";
import TournamentPackageFormRemove from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-form-remove";
import TournamentPackageWatchEmailRemove from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-watch-email-remove";
import TournamentPackageWatchEmailsAddEdit from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-watch-emails-add-edit";
import TournamentPackageCustomFieldAddEdit from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-custom-field-add-edit";
import TournamentPackageCustomFieldRemove from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-custom-field-remove";
import TournamentDiscounts from "../../components/tournament/tournament-promotion/tournament-discounts/tournament-discounts";
import TournamentDiscountAddEdit from "../../components/tournament/tournament-promotion/tournament-discounts/tournament-discount-add-edit";
import TournamentDiscountRemove from "../../components/tournament/tournament-promotion/tournament-discounts/tournament-discount-remove";
import TournamentPackageExclusivity from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-exclusivity";
import TournamentPackageRounds from "../../components/tournament/tournament-promotion/tournament-packages/tournament-package-rounds";

export const getTournamentPromotionRoutes = () => {
  return (
    <Route path="promotion" element={<TournamentPromotion />}>
            <Route
              path="registration"
              element={<TournamentPromotionRegistration />}
            ></Route>
            <Route
              path="packages"
              element={<TournamentPromotionPackages />}
            ></Route>
            <Route
              path="packages/add"
              element={<TournamentPackageCreateEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID"
              element={<TournamentPackageDetails />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/delete"
              element={<TournamentPackageRemove />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/edit"
              element={<TournamentPackageCreateEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/package-items"
              element={<TournamentPackageItems />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/player-info"
              element={<TournamentPackagePlayerInfo />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/activation"
              element={<TournamentPackageActivation />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/exclusivity"
              element={<TournamentPackageExclusivity />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/rounds"
              element={<TournamentPackageRounds />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/watch-emails"
              element={<TournamentPackageWatchEmails />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/watch-emails/:id"
              element={<TournamentPackageWatchEmailsAddEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/watch-emails/:id/remove"
              element={<TournamentPackageWatchEmailRemove />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/forms"
              element={<TournamentPackageForms />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/forms/:formID"
              element={<TournamentPackageFormCreateEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/forms/:formID/:customFieldID"
              element={<TournamentPackageCustomFieldAddEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/forms/:formID/:customFieldID/remove"
              element={<TournamentPackageCustomFieldRemove />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/forms/:formID/remove"
              element={<TournamentPackageFormRemove />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID"
              element={<TournamentPackageCreateEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/package-items/item/:packageItemID"
              element={<TournamentPackageItemCreateEdit />}
            ></Route>
            <Route
              path="packages/:tournamentPackageID/package-items/item/:packageItemID/remove"
              element={<TournamentPackageItemRemove />}
            ></Route>
            <Route path="discounts" element={<TournamentDiscounts />}></Route>
            <Route
              path="discounts/:discountID"
              element={<TournamentDiscountAddEdit />}
            ></Route>
            <Route
              path="discounts/:discountID/remove"
              element={<TournamentDiscountRemove />}
            ></Route>
            <Route
              path="customizations"
              element={<TournamentPromotionEventDetails />}
            ></Route>
            <Route
              path="visibility"
              element={<TournamentPromotionVisibility />}
            ></Route>
            <Route
              path="reciepts"
              element={<TournamentPromotionReceiptCustomization />}
            ></Route>
            <Route
              path="auction"
              element={<TournamentPromotionAuction />}
            ></Route>
          </Route>
  )
}