import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournemant-round-tee-details.scss";
import { useFormValidation } from "../../../../hooks/formHooks";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getTeeForm } from "../../../../forms/TournamentRoundForms";
import { useSelector } from "react-redux";
import {
  clearRoundNotifications,
  getRound,
  getRoundCourse,
  getRoundTee,
  saveRoundTeeAndHoles,
  selectCurrentRound,
  selectCurrentRoundCourse,
  selectCurrentRoundTee,
  selectRoundNotifications,
  setCurrentRoundCourse,
  setCurrentRoundTee,
} from "../../../../reducers/roundSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useResizeSplit } from "../../../../hooks/scorecardHooks";

//Name the component
const TournamentRoundTeeDetails = (props) => {
  const splitNines = useResizeSplit()
  const [context, isValid, setIsValid] = useFormValidation(false);
  const [currentDefaultTee, setCurrentDefaultTee] = useState({})

  const navigate = useNavigate();
  const notifications = useSelector(selectRoundNotifications);

  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/rounds/:roundID/courses/:courseID/tees/:teeID"
  );

  const course = useSelector(selectCurrentRoundCourse);
  const tee = useSelector(selectCurrentRoundTee);
  const currentRound = useSelector(selectCurrentRound)

  useEffect(() => {
    if (match?.params?.courseID) {
      dispatch(getRoundCourse(match.params.courseID));
    }
  }, [dispatch, match?.params?.courseID]);

  useEffect(() => {
    if (match?.params?.teeID) {
      dispatch(getRoundTee(match.params.teeID));
    }
  }, [dispatch, match?.params?.teeID]);

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
    }
  }, [dispatch, match?.params?.roundID]);


  useEffect(() => {
    const tee = course?.roundTees?.find?.(t => t.defaultTee)
    
    if(currentDefaultTee?.id !== tee?.id && course.id === match?.params?.courseID)
    {
      setCurrentDefaultTee(tee)
    }
  }, [course, match?.params?.courseID, currentDefaultTee?.id ])

  useEffect(() => {return () => {dispatch(clearRoundNotifications)}}, [dispatch])

  context.getCourse = () => {
    return course;
  };

  context.getTee = () => {
    return tee;
  };

  context.getRound = () => {
    return currentRound
  }

  context.getTeeHoles = () => {
    let teeHoles = course?.roundHoles?.filter?.((h) => h.teeId === tee?.id);
    return teeHoles?.map?.((t) => ({ position: t.number, par: t.par, yardage: t.yardage, handicap: t.handicap }))
      .sort?.((a, b) => a.position - b.position);
  };

  context.updateTee = (value, property) => {
    setIsValid(true)
    let update = {...tee}
    update[property] = value
    dispatch(setCurrentRoundTee(update))
  }

  context.updateScorecard = (e, props, property) => {
    setIsValid(true)
    let teeHoles = [...course?.roundHoles?.filter?.((h) => h.teeId === tee?.id)];
    let otherHoles = course?.roundHoles?.filter?.((h) => h.teeId !== tee?.id);
    let index = teeHoles?.findIndex?.(h => h.number === props.hole.position)
    let update = {...teeHoles[index]}
    update[property] = Number(e.target.value);
    update.updated = true
    teeHoles[index] = update
    let updatedCourse = {...course, roundHoles: [...otherHoles, ...teeHoles ]}
    dispatch(setCurrentRoundCourse(updatedCourse))
  }

  context.scorecardFocused = (e, props, property) => {
    e?.target?.select()
  }

  context.getSplitNines = () => {
    return splitNines
  }

  const save = () => {
    setIsValid(false);
    let update = {...tee}
    update["round-course"] = course;
    update.tee = {id: tee.teeId}
    update.par = course.roundHoles?.reduce?.((total, hole) => (total += hole.par), 0)
    dispatch(saveRoundTeeAndHoles(update))
    if(update.defaultTee && currentDefaultTee?.id && update.id !== currentDefaultTee?.id)
    {
      changeDefaultTee()
    }
  };

  const changeDefaultTee = () => {
    let update = {...currentDefaultTee, defaultTee: false}
    update["round-course"] = course;
    update.tee = {id: tee.teeId}
    dispatch(saveRoundTeeAndHoles(update))
  }

  const getDrawerActions = () => {
    if(currentRound.state === "live")
    {
      return []
    }
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Tee Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const toggleDefaultTee = () => {
    if(tee.id !== currentDefaultTee?.id)
    {
      context.updateTee(
        !tee?.defaultTee,
        "defaultTee"
      )
    }
    
  }

  const getFormActionBar = () => {
    return {
      formTitle: "Edit Tee",
      formActions: [
        {
          actionType: "toggle",
          pageActionProps: {
            value: tee?.defaultTee,
            trueDescription: "Default Tee",
            falseDescription: "Default Tee",
            rowReverse: true,
            onClick: toggleDefaultTee
          },
        }
      ]
    }
  }

  const getContent = () => {
    return <GSForm {...getFormActionBar()} formSections={getTeeForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournemant-round-tee-details>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournemant-round-tee-details>
  );
};

//export the new namet
export default TournamentRoundTeeDetails;
