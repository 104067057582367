import {
  faMinus,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { GSInfoGroup, GSInput, GSButton } from "golfstatus_react_components";
import React from "react";

import "./tournament-package-items-item.scss";

const TournamentPackageItemsItem = (props) => {
  const {
    name,
    selectedItem,
    addItem,
    updateQuantity,
    itemType
  } = props;

  const incrementQuantity = (e) => {
    updateQuantity?.(props, selectedItem.quantity + 1)
  };

  const decrementQuantity = (e) => {
    updateQuantity?.(props, selectedItem.quantity - 1)
  };

  const infoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (updateQuantity || selectedItem === undefined ) ? name : `${name} (x${selectedItem?.quantity})`,
      },
      {
        type: "secondary full-width capitalize",
        value: itemType?.replace?.("-"," "),
      },
    ],
  };

  const quantitySection = {
    sectionItems: [
      {
        type: "Primary no-wrap full-width",
        value: (
          <GSInput
            leftIcon={faMinus}
            rightIcon={faPlus}
            rightIconClick={incrementQuantity}
            leftIconClick={decrementQuantity}
            textValue={selectedItem?.quantity}
          />
        ),
      },
    ],
  };

  const editSection = {
    sectionItems: [
      {
        type: "Primary no-wrap full-width",
        value: (
          <GSButton title="add to package" buttonIcon={faPlusCircle} type="light-grey" onClick={(e) => {addItem?.(props); e?.stopPropagation?.()}}></GSButton>
        ),
      },
    ],
  };

  const infoColumn = {
    fit: "stretch",
    sections: [
      {
        ...infoSection,
      },
    ],
  };

  const quantityColumn = {
    sections: [
      {
        ...quantitySection,
      },
    ],
  };
  const editColumn = {
    sections: [
      {
        ...editSection,
      },
    ],
  };

  const getGroup = () => {
    let group = [{ ...infoColumn }];
    if (selectedItem) {
      if(updateQuantity){
        group.push({ ...quantityColumn });
      }
    } else if(addItem) {
      group.push({ ...editColumn });
    }

    return group;
  };

  return (
    <tournament-package-items-item>
      <GSInfoGroup listType={"mobile-vertical"} dataGroups={getGroup()}></GSInfoGroup>
    </tournament-package-items-item>
  );
};

export default TournamentPackageItemsItem;
