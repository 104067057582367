import { Route } from "react-router-dom"
import TournamentSettings from "../../components/tournament/tournament-settings/tournament-settings";
import TournamentSettingsRemove from "../../components/tournament/tournament-settings/tournament-settings-remove";
import TournamentSettingsManagers from "../../components/tournament/tournament-settings/tournament-settings-managers";
import TournamentSettingsManagersEdit from "../../components/tournament/tournament-settings/tournament-settings-managers-edit";
import TournamentSettingsDelete from "../../components/tournament/tournament-settings/tournament-settings-delete";
import TournamentCreateEdit from "../../components/tournament-list/tournament-create-edit";
import TournamentSettingsPayments from "../../components/tournament/tournament-settings/tournament-settings-payments";
import TournamentSettingsDonationWatchEmail from "../../components/tournament/tournament-settings/tournament-settings-donation-watch-email";
import TournamentSettingsDonationWatchItemEmailDelete from "../../components/tournament/tournament-settings/tournament-settings-donation-watch-email-delete";
import TournamentSettingsPaymentsOrganization from "../../components/tournament/tournament-settings/tournament-settings-payments-organization";
import TournamentSettingsPaymentsWatch from "../../components/tournament/tournament-settings/tournament-settings-payments-watch";
import TournamentSettingsPaymentsInvoice from "../../components/tournament/tournament-settings/tournament-settings-payments-invoice";
import TouranmentSettingsDefaultWatchItems from "../../components/tournament/tournament-settings/tournament-settings-default-watch-items";
import TouranmentSettingsDefaultWatchItemAddEdit from "../../components/tournament/tournament-settings/tournament-settings-default-watch-item-add-edit";
import TouranmentSettingsDefaultWatchItemDelete from "../../components/tournament/tournament-settings/tournament-settings-default-watch-item-delete";
import TournamentPromotionReceiptCustomization from "../../components/tournament/tournament-promotion/tournament-promotion-receipt-customization";
import TournamentSettingsPaymentsCustomerInvoiceWatch from "../../components/tournament/tournament-settings/tournament-settings-payments-customer-invoice-watch";
import TournamentSettingsPaymentsCustomerInvoiceWatchEmail from "../../components/tournament/tournament-settings/tournament-settings-payments-customer-invoice-watch-email";
import TournamentSettingsPaymentsCustomerInvoiceWatchEmailDelete from "../../components/tournament/tournament-settings/tournament-settings-payments-customer-invoice-watch-email-delete";

export const getSettingsRoutes = () => {
  return (
    <Route path="settings" element={<TournamentSettings />}>
      <Route path="details" element={<TournamentCreateEdit />}></Route>
      <Route path="payments" element={<TournamentSettingsPayments />}></Route>
      <Route path="payments/organization" element={<TournamentSettingsPaymentsOrganization />}></Route>
      <Route path="payments/watch" element={<TournamentSettingsPaymentsWatch />}></Route>
      <Route path="payments/watch/:id" element={<TournamentSettingsDonationWatchEmail />}></Route>
      <Route path="payments/watch/:id/delete" element={<TournamentSettingsDonationWatchItemEmailDelete />}></Route>
      <Route path="payments/invoice" element={<TournamentSettingsPaymentsInvoice />}></Route>
      <Route path="payments/customer-invoice-watch" element={<TournamentSettingsPaymentsCustomerInvoiceWatch />}></Route>
      <Route path="payments/customer-invoice-watch/:id" element={<TournamentSettingsPaymentsCustomerInvoiceWatchEmail />}></Route>
      <Route path="payments/customer-invoice-watch/:id/delete" element={<TournamentSettingsPaymentsCustomerInvoiceWatchEmailDelete />}></Route>
      <Route path="payments/receipts" element={<TournamentPromotionReceiptCustomization/>}></Route>
      <Route path="default-watch-items" element={<TouranmentSettingsDefaultWatchItems />}></Route>
      <Route path="default-watch-items/:id" element={<TouranmentSettingsDefaultWatchItemAddEdit />}></Route>
      <Route path="default-watch-items/:id/delete" element={<TouranmentSettingsDefaultWatchItemDelete />}></Route>
      <Route path="managers" element={<TournamentSettingsManagers />}></Route>
      <Route path="managers/add" element={<TournamentSettingsManagersEdit />}></Route>
      <Route path="managers/:managerType/:managerId" element={<TournamentSettingsManagersEdit />}></Route>
      <Route path="managers/:managerType/:managerId/remove" element={<TournamentSettingsRemove />}></Route>
      <Route path="details/delete" element={<TournamentSettingsDelete />}></Route>
    </Route>
  )
}