import React from "react";
import "./tournament-info-group.scss";
import { GSInfoGroup, GSActionBar, GSImage } from "golfstatus_react_components";
import { getDate } from "../helpers/Dates";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const TournamentInfoGroup = (props) => {
  const { tournament } = props;

  const navigate = useNavigate();

  const getStartDate = () => {
    if (tournament?.startAt !== null) {
      return tournament?.numberOfRounds > 1
        ? `${getDate(tournament?.startAt)} - ${getDate(tournament?.endAt)}`
        : getDate(tournament?.startAt);
    }
    return "No Date Selected";
  };
  const getStateColor = (state) => {
    switch (state) {
      case "final":
        return "black";
      case "active":
        return "green";
      case "draft":
        return "light-grey";
      default:
        return "";
    }
  };
  const goToCopyForm = (e) => {
    e?.stopPropagation();
    navigate(`/tournaments/copy/${tournament?.id}`);
  };
  const getTournamentDataGroup = (tournament) => {
    let item = [
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              {
                type: "primary full-width",
                value: (
                  <GSActionBar
                    header={
                      <GSImage size="x-small wide" src={tournament?.logo} />
                    }
                    pageActions={[
                      {
                        buttonTitle:
                          tournament.state !== "final"
                            ? tournament.state
                            : "complete",
                        type: `secondary status pill ${getStateColor(
                          tournament.state
                        )}`,
                      },
                      {
                        actionIcon: faClone,
                        type: "secondary transparent grey",
                        actionClick: goToCopyForm,
                      },
                    ]}
                  />
                ),
              },
            ],
          },
          {
            sectionItems: [
              { type: "primary no-wrap ellipsis", value: tournament?.name },
              {
                type: "secondary no-wrap",
                value: tournament?.organization?.name,
              },
            ],
          },
          {
            sectionItems: [
              {
                type: "secondary no-wrap",
                value: getStartDate(),
              },
              {
                type: "secondary no-wrap",
                value:
                  tournament?.facilities?.[0]?.name ?? "No course selected",
              },
            ],
          },
        ],
      },
    ];
    return item;
  };
  return (
    <tournament-info-group>
      <GSInfoGroup dataGroups={getTournamentDataGroup(tournament)} />
    </tournament-info-group>
  );
};

export default TournamentInfoGroup;
