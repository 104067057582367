import { GSInfoGroup, utilities } from 'golfstatus_react_components';
import React from 'react';
import './facility-line-item.scss';

const FacilityLineItem = (props) => {
  const { logo, name, city, state } = props;
  const hasLogo = () => {
    return logo && logo !== '/logos/default/missing.png';
  };
  const getImageLayout = () => {
    return (
      <div className='facility-logo'>
        {hasLogo() ? <img src={logo} alt=''></img> : utilities.getInitial(name)}
      </div>
    );
  };
  const getInfoGroup = () => {
    return [
      {
        fit: "hide-mobile",
        sections: [
          {
            sectionItems: [
              {value: getImageLayout()}
            ]
          }
        ]
      },
      {
        sections: [
          {
            sectionItems: [
              { type: 'primary', value: name },
              { type: 'secondary', value: `${city}, ${state}` },
            ],
          },
        ],
      },
    ];
  };
  return (
    <facility-line-item>
      <GSInfoGroup dataGroups={getInfoGroup()} />
    </facility-line-item>
  );
};

export default FacilityLineItem;
