import Cookies from "js-cookie";

export function getCurrentUser() {
  const gsSessionCookie = Cookies.get("golfstatus-session");
  let gsSessionUser = {};
  if (gsSessionCookie) {
    gsSessionUser = JSON.parse(gsSessionCookie).authenticated;
    return gsSessionUser;
  } else {
    window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
  }
}

export function logOut() {
  //Cookies.remove('golfstatus-session', { domain: process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN,});
  let logoutRedirectUrl = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
  if (Cookies.get("golfstatus-previous-link")) {
    let previousLink = JSON.parse(Cookies.get("golfstatus-previous-link"));
    if (previousLink && previousLink.linkName) {
      logoutRedirectUrl = previousLink.linkUrl;
    }
  }
  return logoutRedirectUrl
}
