import React from "react";
import "./tournament-round-tile.scss";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import { getDateTimeOn } from "../../../helpers/Dates";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  activateScorecards,
  //lockScoring,
  saveRound,
  updateRound,
} from "../../../reducers/roundSlice";
import { updateCurrentTournamentRound } from "../../../reducers/tournamentSlice";
import { faBolt, faPen } from "@fortawesome/free-solid-svg-icons";

import { getRoundName, getStateColor } from "../../../helpers/Converters";

const TournamentRoundTile = (props) => {
  const { round } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const toggleLockRound = (round) => {
  //   dispatch(lockScoring(round.id)).then((response) => {
  //     let update = { ...round };
  //     update.scoringLocked = !round.scoringLocked;
  //     dispatch(updateRound(update));
  //     dispatch(updateCurrentTournamentRound(update));
  //   });
  // };

  const startRound = () => {
    let update = { ...round };
    update.state = "live";
    dispatch(updateRound(update));
    dispatch(updateCurrentTournamentRound(update));
    dispatch(saveRound(update));
    dispatch(activateScorecards(round.id))
    navigate(`rounds/${round.id}/scoring`);
  };

  const editScorecards = (round) => {
    navigate(`rounds/${round.id}/scoring`);
  };

  const getStateButton = () => {
    switch (round.state) {
      case "live":
        return round.scoringLocked ? (
          <GSButton
            type={"light-grey"}
            title={"edit scorecards"}
            buttonIcon={faPen}
            onClick={(e) => {
              editScorecards(round);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        ) : (
          <GSButton
            type={"light-grey"}
            title={"edit scorecards"}
            buttonIcon={faPen}
            onClick={(e) => {
              editScorecards(round);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
      case "ready":
        return (
          <GSButton
            type={"green"}
            title={"Start Round"}
            onClick={(e) => {
              startRound(round);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
      case "draft":
        return (
          <GSButton
            type={"light-grey"}
            title={"Hole Assignments"}
            buttonIcon={faBolt}
            onClick={(e) => {
              navigate(`rounds/${round.id}/hole-assignments`);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
        case "final":
        return (
          <GSButton
            type={"light-grey"}
            title={"View Leaderboards"}
            onClick={(e) => {
              navigate(`leaderboards`);
              e?.stopPropagation?.();
            }}
          ></GSButton>
        );
      default:
        return (
          <GSButton type={"light-grey"} title={"Round Details"}></GSButton>
        );
    }
  };

  const roundName = {
    sectionItems: [
      {
        type: "primary no-wrap",
        value: `Round ${getRoundName(round)}`,
      },
    ],
  };

  const roundInfo = {
    sectionItems: [
      {
        type: "secondary no-wrap",
        value: `${round?.tournamentFormats?.map?.((f) => f.name)?.join?.(",")}`,
      },
      {
        type: "secondary no-wrap",
        value: `${getDateTimeOn(round?.startAt)}` ?? `TBD`,
      },
      {
        type: "secondary no-wrap",
        value: round?.facility?.name ?? `Invalid Facility`,
      },
      {
        type: "secondary no-wrap",
        value: round?.courses?.[0]?.name
          ? `${round?.courses
              ?.map?.((t) => t.name)
              ?.join?.(" Course, ")} Course`
          : ``,
      },
    ],
  };

  const roundState = {
    sectionItems: [
      {
        type: " no-wrap",
        value: (
          <GSButton
            type={`secondary pill ${getStateColor(round)}`}
            title={round?.state}
          ></GSButton>
        ),
      },
    ],
  };

  const roundActions = {
    sectionItems: [
      {
        type: " no-wrap full-width",
        value: getStateButton(),
      },
    ],
  };

  let group = [
    {
      fit: "stretch",
      sections: [
        { ...roundName },
        { ...roundInfo },
        { ...roundState },
        { ...roundActions },
      ],
    },
  ];
  return (
    <tournament-round-tile>
      <GSInfoGroup dataGroups={group} />
    </tournament-round-tile>
  );
};

export default TournamentRoundTile;
