import React from "react";
import { GSForm, GSSidePanelPage, GSField } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-order-refund.scss";
import { useFormValidation } from "../../../hooks/formHooks";
import {
  useNotificationAction,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getWarningTitle, warnItemForm } from "../../../forms/DeleteForm";
import { getReportingForm } from "../../../forms/ReportingForm";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getOrders,
  refundOrder,
  selectNotifications,
  selectOrders,
} from "../../../reducers/reportingSlice";
import { moneyWithCommas } from "../../../helpers/RegexHelper";
import { useDispatch } from "react-redux";

//Name the component
const TournamentReportingOrderRefund = (props) => {
  const [context, isValid, setIsValid] = useFormValidation(true);
  const match = useMatch("/:tournamentID/reporting/:orderID/refund");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrder = useSelector(selectOrders)?.find?.(
    (o) => o.id === match?.params?.orderID
  );
  const notifications = useSelector(selectNotifications);

  useNotificationNavigation("saved", notifications, -1, "Refund Order");
  useNotificationAction(
    "saved",
    notifications,
    (notification) => {
      dispatch(getOrders(match?.params?.tournamentID));
    },
    "Refund Order"
  );

  const save = () => {
    setIsValid(false);
    dispatch(refundOrder(currentOrder));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Refund",
        isDisabled: !isValid,
        action: save,
        type: "orange",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Refund Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getWarningTitle("Refund Order")}
        formSections={warnItemForm(
          "Refunds will be made in the form of the original payment. If you need to refund an alternate amount, please contact GolfStatus support.",
          <GSForm
            formTitle={
              <GSField
                type="row emphasize"
                label="Refund Total"
                value={moneyWithCommas(currentOrder?.netProfit)}
              />
            }
            formSections={getReportingForm(context, currentOrder)}
          />
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-order-refund>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-order-refund>
  );
};

//export the new namet
export default TournamentReportingOrderRefund;
