import "./tournament-sponsors.scss";
import {
  faFolderOpen,
  faPlus,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
  GSActionBar,
} from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentSponsors,
  saveTournamentSponsorOrder,
  selectSponsorList,
  setCurrentSponsor,
  setSponsorList,
} from "../../../reducers/sponsorSlice";
import TournamentSponsorLineItem from "./tournament-sponsor-line-item";
import { shuffleIndex } from "../../../helpers/Utilities";
import { useMemo } from "react";

const TournamentSponsors = (props) => {
  const [search, setSearch] = useState("");
  const match = useMatch("/:tournamentID/sponsors/*");

  const tournamentSponsors = useSelector(selectSponsorList);

  const holeSponsors = useMemo(() => {
    return tournamentSponsors
      .filter((s) => s.sponsorshipType === "hole")
      .map((hs, index) => ({ ...hs, sortOrder: index }));
  }, [tournamentSponsors]);

  const leaderboardSponsors = useMemo(() => {
    return tournamentSponsors
      .filter((s) => s.sponsorshipType === "leaderboard")
      .map((hs, index) => ({ ...hs, sortOrder: index }));
  }, [tournamentSponsors]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTournamentSponsors(match.params.tournamentID));
  }, [dispatch, match?.params?.tournamentID]);

  const addSponsor = () => {
    dispatch(
      setCurrentSponsor({
        sponsorshipType: "hole",
        name: "",
        logo: false,
        mobileLogo: false,
        contactName: "",
        email: "",
      })
    );
    navigate("add");
  };

  const clearSearch = () => {
    setSearch("");
  };

  const printouts = () => {
    navigate("documents")
  }

  const getPageActions = () => {
    return [
      {
        id: 1,
        buttonTitle: "Add Sponsor",
        actionClick: addSponsor,
        type: "black mobile-icon",
        actionIcon: faPlus,
      },
      {
        id: 1,
        buttonTitle: "Documents",
        type: "light-grey mobile-icon",
        actionClick: printouts,
        actionIcon: faFolderOpen,
      },
    ];
  };

  const getSearchActions = () => {
    return [];
  };

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        rightIconClick={clearSearch}
        placeholder="Search Sponsors…"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      ></GSInput>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Add & Manage Sponsors"
        detail="Sponsors elevate your tournament experience."
        actions={[
          {
            title: "Add Sponsor",
            buttonIcon: faPlus,
            onClick: addSponsor,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the sponsor you’re looking for…"
        detail="Try changing the search term or add a new sponsor."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "add Sponsor",
            buttonIcon: faPlus,
            onClick: addSponsor,
            type: "black",
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const filterSponsors = (sponList) => {
    return sponList.filter?.((s) =>
      s.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
    );
  };

  const reOrderSponsor = (
    sourceSponsor,
    sourceIndex,
    destSponsor,
    destIndex
  ) => {
    let sponsors = holeSponsors
      ?.map?.((p, index) => ({
        ...p,
        sortOrder: shuffleIndex(index, sourceIndex, destIndex),
      }))
      .sort((a, b) => a.sortOrder - b.sortOrder);
    dispatch(setSponsorList([...leaderboardSponsors, ...sponsors]));
    saveOrder(sponsors);
  };

  const saveOrder = (sponsorlist) => {
    sponsorlist.forEach(async (sponsor) => {
      dispatch(saveTournamentSponsorOrder(sponsor));
    });
  };

  const openSponsor = (item) => {
    if (isSidePanelOpen()) {
      navigate(`/${match.params.tournamentID}/sponsors`);
      return;
    }
    navigate(`${item.id}`);
  };

  const getGroups = () => {
    const leaderboard = filterSponsors(leaderboardSponsors);
    const hole = filterSponsors(holeSponsors);
    let group = [];

    if (leaderboard?.length > 0) {
      group.push({
        list: (
          <div className="leaderboard-sponsors">
            <GSActionBar
              header="Technology Sponsor"
              pageActions={[]}
            ></GSActionBar>
            <GSItemList
              type="vertical x-large-gap list-item selectable"
              items={leaderboard}
              itemSelected={openSponsor}
              listItem={(item) => (
                <TournamentSponsorLineItem
                  {...item}
                ></TournamentSponsorLineItem>
              )}
            ></GSItemList>
          </div>
        ),
      });
    }
    if (hole?.length > 0) {
      group.push({
        list: (
          <div className="hole-sponsors">
            <GSActionBar header="Sponsors"></GSActionBar>
            <GSItemList
              type="vertical list-item selectable"
              items={hole}
              itemSelected={openSponsor}
              listItem={(item) => (
                <TournamentSponsorLineItem
                  {...item}
                ></TournamentSponsorLineItem>
              )}
              isSortable={true}
              enableStep={false}
              itemMoved={reOrderSponsor}
              topScrollMargin={160}
              dragOffset={-100}
            ></GSItemList>
          </div>
        ),
      });
    }
    return group;
  };

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical all-sponsors"
        items={getGroups()}
        listItem={(group) => group.list}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  return (
    <tournament-sponsors>
      <GSListPage
        title="Sponsors"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-sponsors>
  );
};

export default TournamentSponsors;
