import React, { useEffect, useMemo } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

import "./tournament-team-payments.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeamRegistrationOrder,
  selectCurrentTeamRegistrationOrder,
} from "../../../reducers/teamsSlice";
import { getRegistrationForm } from "../../../forms/TeamForm";

//Name the component
const TournamentTeamPayments = (props) => {
  const navigate = useNavigate();

  const currentTeamRegistrationOrder = useSelector(
    selectCurrentTeamRegistrationOrder
  );
  const dispatch = useDispatch();

  const match = useMatch("/:tournamentID/teams/:teamID/payment");

  useEffect(() => {
    dispatch(getTournamentTeamRegistrationOrder(match.params.teamID));
  }, [dispatch, match.params.teamID]);

  const context = useMemo(() => ({}), []);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Tournament Team Payments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };
  const getDrawerActions = () => {
    let actions = [
      {
        name: "View Order",
        action: () => {navigate(`/${match.params.tournamentID}/reporting/${currentTeamRegistrationOrder.id}`)},
        type: "light-grey",
      },
    ];
    return actions;
  };

  const getContent = () => {
    return (
      <GSForm
        formSections={getRegistrationForm(
          currentTeamRegistrationOrder,
          context
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    //name the component tag
    <tournament-team-payments>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-team-payments>
  );
};

//export the new namet
export default TournamentTeamPayments;
