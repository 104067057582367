import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { accessGroups, getThunkResponse, organizations } from "../app/api";
import gsApi from "../auth/auth";

export const getOrganizationAccessLists = createAsyncThunk(
  "access/getOrganizationAccessLists",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${organizations}/${id}/access-groups`);
    return response;
  }
);

export const getOrganizationAccessList = createAsyncThunk(
  "access/getOrganizationAccessList",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${accessGroups}/${id}`);
    return response;
  }
);

//replace all occurences of access with the name of your new slice
export const accessSlice = createSlice({
  name: "access",
  initialState: {
    accessLists: [],
    currentAccessList: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setAccessLists: (state, action) => {
      state.accessLists = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...getThunkResponse(getOrganizationAccessLists, (state, action) => {state.accessLists = action.payload})
    
  },
});

export const {
  setList,
  addNotification,
  clearNotifications,
} = accessSlice.actions;


//export any selectors needed 
export const selectAccessLists = (state) => state?.[accessSlice.name]?.accessLists;

export const selectCurrentAccessList = (state) => state?.[accessSlice.name]?.currentAccessList;

export const selectNotifications = (state) => state?.[accessSlice.name]?.notifications;

export const selectLoading = (state) => state?.[accessSlice.name]?.loading;

export default accessSlice.reducer;

//ADD reducer to store.js