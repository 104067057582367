import React, { useEffect, useState } from "react";
import {
  GSEmptyList,
  GSInput,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

import "./tournament-team-scorecards.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeam,
  getTournamentTeamScorecards,
  selectCurrentTeam,
  selectCurrentTeamScorecards,
  selectLoading,
} from "../../../reducers/teamsSlice";
import {
  getTournamentRounds,
  selectTournamentRounds,
} from "../../../reducers/roundSlice";
import TournamentRoundScoringTeamItem from "../tournament-rounds/round-scoring/tournament-round-scoring-team-item";
import TournamentTeamDetailHero from "./tournament-team-detail-hero";

//Name the component
const TournamentTeamScorecards = (props) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/teams/:teamID/scorecards");

  const currentTeamScorecards = useSelector(selectCurrentTeamScorecards);
  const currentTeam = useSelector(selectCurrentTeam);
  const tournamentRounds = useSelector(selectTournamentRounds);
  const loading = useSelector(selectLoading)

  useEffect(() => {
    dispatch(getTournamentTeamScorecards(match.params.teamID));
    dispatch(getTournamentTeam(match.params.teamID));
  }, [dispatch, match.params.teamID]);

  useEffect(() => {
    dispatch(getTournamentRounds(match.params.tournamentID));
  }, [dispatch, match.params.tournamentID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Scorecards`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getTeamScorecard = (item) => {
    return {
      ...currentTeam,
      liveTournamentScorecard: item,
      tournamentRound: tournamentRounds?.find?.(
        (tr) => tr.id === item?.tournamentRound?.id
      ),
    };
  };

  const getFilteredScorecards = () => {
    const mappedScorecards = currentTeamScorecards?.map?.((ts) =>
      getTeamScorecard(ts)
    );
    return mappedScorecards?.filter?.((ms) =>
      `${ms?.tournamentRound?.number} ${
        ms?.tournamentRound?.roundName ? ms?.tournamentRound?.roundName : ""
      }`.includes(search)
    );
  };

  const gotoScorecard = (item) => {
    navigate(
      `/${match.params.tournamentID}/rounds/${item?.tournamentRound?.id}/scoring/${item?.liveTournamentScorecard?.id}`
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title={`${currentTeam.contactName}'s Scorecards`}
        detail="Scorecards will be listed here after hole assignments are completed and scorecards are generated for each round."
        actions={[
          {
            title: "View rounds",
            onClick: () => {
              navigate(`/${match.params.tournamentID}/rounds`);
            },
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchListMessage = () => {
    return (
      <GSEmptyList
        title={`We couldn’t find the scorecard you’re looking for…`}
        detail="Try changing the search term or view the Rounds page to confirm scorecards have been generated."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch('');
            },
            type: "light-grey",
          },
          {
            title: "View rounds",
            onClick: () => {
              navigate(`/${match.params.tournamentID}/rounds`);
            },
            type: "black",
          },
        ]}
      />
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <TournamentTeamDetailHero {...currentTeam} />
        {currentTeamScorecards?.length > 0 ? (
          <div className="search">
            <GSInput
              placeholder="Search Scorecards..."
              textValue={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              leftIcon={faSearch}
              rightIcon={faTimesCircle}
            ></GSInput>
          </div>
        ) : null}

        <GSItemList
          itemSelected={gotoScorecard}
          items={getFilteredScorecards()}
          type="vertical selectable"
          listItem={(item) => <TournamentRoundScoringTeamItem {...item} />}
          emptyMessage={search !== "" ? getEmptySearchListMessage() : getEmptyListMessage()}
        ></GSItemList>
      </gs-form>
    );
  };

  return (
    //name the component tag
    <tournament-team-scorecards>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-team-scorecards>
  );
};

//export the new namet
export default TournamentTeamScorecards;
