import { GSButton, GSImage, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import "./tournament-sponsor-line-item.scss";

const TournamentSponsorLineItem = (props) => {

  const {logo, name, contactName, email, sponsorshipType, status} = props

  const sponorImageSection = {
    
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSImage src={logo} size="wide medium"/>,
      },
    ]
  }

  const firstColumn ={
    sections: [
      {
        ...sponorImageSection
      },
    ],
  }

  const sponsorInfoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: name,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${sponsorshipType === "leaderboard" ? "Technology": ""} Sponsor`,
      },
    ]
  }

  const statusSection = {
    sectionItems: [
      {
        type: "primary",
        value: <GSButton title={status} type={`secondary pill status ${status}`}></GSButton>
      }
    ]
  }

  const sponsorContactSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: contactName,
      },
      {
        type: "secondary no-wrap full-width",
        value: email,
      },
    ]
  }

  const secondColumn ={
    fit: "stretch",
    sections: [
      {
        ...sponsorInfoSection
      },
      {
        ...sponsorContactSection
      }
    ],
  }

  const statusColumn = {
    sections: [
      statusSection
    ]
  }

  const getGroup = () => {
    let group = [{...firstColumn},{...secondColumn}]
    if(sponsorshipType === "leaderboard"){
      group.push(statusColumn)
    }
    return group
  }

  return <tournament-sponsor-line-item>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </tournament-sponsor-line-item>
}


export default TournamentSponsorLineItem