import "./tournament-sponsors-create-edit-image.scss";
import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { imageEditForm } from "../../../forms/SponsorForm";
import { useSelector } from "react-redux";
import {
  selectCurrentSponsor,
  setCurrentSponsor,
} from "../../../reducers/sponsorSlice";
import { useFormDataValidation, useImageCropper } from "../../../hooks/formHooks";
import { useEffect } from "react";

//Name the component
const TournamentSponsorsCreateEditImage = (props) => {
  const navigate = useNavigate();
  const currentSponsor = useSelector(selectCurrentSponsor);

  const [cropParams] = useSearchParams()
  const property = cropParams?.get("property")

  const [context, isValid] = useFormDataValidation({initialValid: true, data: currentSponsor, setData: setCurrentSponsor});
  const [croppedImg, showCroppedImage, saveCroppedLogo, applyCrop, undoCrop] = useImageCropper(context, property)

  useEffect(() => {
    const editor = document.getElementById("image-editor")
    editor.focus()
  }, [])

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: saveLogo,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const saveLogo = () => {
    saveCroppedLogo();
    leftNavigation();
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Edit Sponsor Image`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  context.getActions = () => {
    if (!showCroppedImage) {
      return [
        { buttonTitle: "Preview", actionClick: applyCrop, type: "transparent" },
      ];
    } else {
      return [
        {
          buttonTitle: "revert",
          actionIcon: faUndoAlt,
          actionClick: undoCrop,
          type: "transparent red",
        },
      ];
    }
  };

  context.getRatio = () => {
    return property === "logoMobile" ? 4 : 2;
  };

  context.getMinHeight = () => {
    return property === "logoMobile" ? 150 : 300;
  };

  context.getLogo = () => {
    return currentSponsor?.[property];
  };

  const getContent = () => {
    return (
      <GSForm
      formTitle="Edit Image"
      formActions={context.getActions()}
        formSections={imageEditForm(
          context,
          showCroppedImage,
          croppedImg
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const keyPressed = () => {
    saveLogo();
  };

  return (
    //name the component tag
    <tournament-sponsors-create-edit-image
      id="image-editor"
      onKeyPress={keyPressed}
      tabIndex={1}
    >
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-sponsors-create-edit-image>
  );
};

//export the new namet
export default TournamentSponsorsCreateEditImage;
