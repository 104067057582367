import { Route } from "react-router-dom";
import TournamentCopy from "../components/tournament-list/tournament-copy";
import TournamentCreateEdit from "../components/tournament-list/tournament-create-edit";
import TournamentList from "../components/tournament-list/tournament-list";
import TournamentListFilter from "../components/tournament-list/tournament-list-filter";
import TournamentQuickStartSuccess from "../components/tournament-list/tournament-quick-start-success";

export const getTournamentRoutes = () => {
  return (
    <Route path={"/tournaments"} element={<TournamentList />}>
      <Route path="filter" element={<TournamentListFilter />}></Route>
      <Route path="new" element={<TournamentCreateEdit />}></Route>
      <Route path="copy/:id" element={<TournamentCopy />}></Route>
      <Route
        path="quick-start"
        element={<TournamentCreateEdit quickStart={true} />}
      ></Route>
      <Route
        path="success/:id"
        element={<TournamentQuickStartSuccess />}
      ></Route>
    </Route>
  );
};
