import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import {
  GSToggle,
  validations,
  GSSelect,
  GSItemList,
  GSButton,
} from "golfstatus_react_components";
import TournamentPackageFormItem from "../components/tournament/tournament-promotion/tournament-packages/tournament-package-form-item";
import TournamentPackageItemsItem from "../components/tournament/tournament-promotion/tournament-packages/tournament-package-items-item";
import TournamentPackagesItem from "../components/tournament/tournament-promotion/tournament-packages/tournament-packages-item";
import { getDateTimeYMD } from "../helpers/Dates";
import CustomFieldOptionLineItem from "../components/tournament/tournament-promotion/tournament-packages/custom-field-option-line-item";
import OnBlur from "../components/on-blur";

export const getPackageForm = (context, tournamentPackage) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Package Name",
            placeholder: "Package Name",
            value: tournamentPackage?.name,
            failedValidation: context.validationFailed,
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "name");
            },
            isEditable: true,
            required: true,
          },
          {
            label: "Description",
            placeholder: "Description",
            value: tournamentPackage?.description,
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "description");
            },
            type: "text-area",
            rows: 5,
            isEditable: true,
          },
          {
            label: "Price",
            placeholder: "0.00",
            value: tournamentPackage?.cost,
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "cost");
            },
            leftIcon: faDollarSign,
            isEditable: true,
            failedValidation: context.validationFailed,
            type: "number",
            required: true,
            validation: {
              isValid: context.isValidPackagePrice,
              invalidLabel: `This package includes a Tech Sponsor package item, the price must be at least $${context.minTechSponsorPrice}.`,
            },
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Inventory Details",
        fields: [
          {
            label: "Package Inventory",
            description: "Set the number of packages available for purchase.",
            placeholder: "Package Inventory (e.g. 25)",
            value: tournamentPackage?.availableLimit,
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "availableLimit");
            },
            isEditable: true,
            type: "number",
            failedValidation: context.validationFailed,
            required: true,
            validation: {
              isValid: context.isValidPackageInventory,
              invalidLabel:
                tournamentPackage?.availableLimit < 0
                  ? "Cannot be less than 0."
                  : "This package includes a Tech Sponsor package item, inventory cannot be more than 1.",
            },
          },
          {
            label: "Display Package Inventory",
            description:
              "Display the number of available packages on the event site.",
            value: (
              <GSToggle
                value={!tournamentPackage?.hideAvailableLimit}
                trueDescription="Yes"
                falseDescription="No"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage?.hideAvailableLimit,
                    "hideAvailableLimit",
                  );
                }}
              ></GSToggle>
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackagePlayerInfoForm = (context, tournamentPackage) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "First Name",
            value: (
              <GSToggle
                value={tournamentPackage.requirePlayerFirstName}
                falseDescription="Not Required"
                trueDescription="Required"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage.requirePlayerFirstName,
                    "requirePlayerFirstName",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Last Name",
            value: (
              <GSToggle
                value={tournamentPackage.requirePlayerLastName}
                falseDescription="Not Required"
                trueDescription="Required"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage.requirePlayerLastName,
                    "requirePlayerLastName",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Email Address",
            value: (
              <GSToggle
                value={tournamentPackage.requirePlayerEmail}
                falseDescription="Not Required"
                trueDescription="Required"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage.requirePlayerEmail,
                    "requirePlayerEmail",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "Phone",
            value: (
              <GSToggle
                value={tournamentPackage.requirePlayerPhone}
                falseDescription="Not Required"
                trueDescription="Required"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage.requirePlayerPhone,
                    "requirePlayerPhone",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
          {
            label: "GHIN / Handicap Index",
            value: (
              <GSToggle
                value={tournamentPackage.requirePlayerGhinOrHandicap}
                falseDescription="Not Required"
                trueDescription="Required"
                onClick={() => {
                  context?.updatePackage?.(
                    !tournamentPackage.requirePlayerGhinOrHandicap,
                    "requirePlayerGhinOrHandicap",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageActivationForm = (context, tournamentPackage) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Activate On",
            value: tournamentPackage.activateOn
              ? getDateTimeYMD(tournamentPackage.activateOn)
              : "",
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "activateOn");
            },
            type: "datetime-local",
            isEditable: true,
          },
          {
            label: "Deactivate On",
            value: tournamentPackage.deactivateOn
              ? getDateTimeYMD(tournamentPackage.deactivateOn)
              : "",
            onChange: (e) => {
              context?.updatePackage?.(e.target.value, "deactivateOn");
            },
            type: "datetime-local",
            isEditable: true,
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageWatchForm = (context) => {
  if (context) {
    const form = [
      {
        type: "vertical",
        fields: [
          {
            label: `${context.getCurrentPackage?.()?.name} Watch Emails`,
            description:
              "Everyone on this list will be notified when this package has been purchased.",
            value: context?.getPackageWatchList?.(),
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageFormCreate = (context, packageForm) => {
  if (context) {
    const formSection = {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Form Name",
          placeholder: "Form Name",
          value: packageForm?.name,
          onSubmit: context?.submit,
          failedValidation: context?.validationFailed,
          onChange: (e) => {
            context.updateForm(e.target.value, "name");
          },
          isEditable: true,
          required: true,
        },
      ],
    };

    const fieldSection = {
      type: "vertical",
      title: "Form Questions",
      sectionActions: context?.getFieldActions?.(),
      fields: [
        {
          label: "Form Questions",
          value: context?.getPackageWatchList?.(),
        },
      ],
    };

    const form = [formSection];

    if (context?.isEdit()) {
      form.push(fieldSection);
    }
    return form;
  }
};

export const getPackageItemsForm = (context) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: `${context.getPackage?.()?.name} Items`,
            value: context?.getPackageItemList?.(),
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageFormsForm = (context) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: `${context?.getCurrentPackage?.()?.name} Forms`,
            value: context?.getPackageFormList?.(),
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageEmailForm = (context, watchItem) => {
  const { validEmail } = validations;
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Email Address",
            placeholder: "Email Address",
            value: watchItem?.email,
            failedValidation: context?.validationFailed,
            onSubmit: context?.submitEmail,
            validation: {
              isValid: validEmail,
              invalidLabel: "Not a valid email address",
            },
            isEditable: true,
            required: true,
            onChange: (e) =>
              context.updateWatchItem?.(e.target?.value, "email"),
          },
        ],
      },
    ];
    return form;
  }
};

export const getPackageCustomFieldForm = (
  context,
  customField,
  optionsList,
) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Question",
            placeholder: "e.g. What is your shirt size?",
            value: customField?.name,
            isEditable: true,
            onSubmit: context.submitForm,
            required: true,
            failedValidation: context?.validationFailed,
            onChange: (e) =>
              context.updateCustomField?.(e.target?.value, "name"),
          },
        ],
      },
      {
        type: "vertical xx-large-gap",
        title: "Response Details",
        fields: [
          {
            label: "Response Type",
            isEditable: true,
            required: true,
            customView: true,
            value: (
              <GSSelect
                onChange={(option) =>
                  context.updateCustomField(option.value, "fieldType")
                }
                selectedOption={context.typeOptions.find(
                  (o) => o.value === customField.fieldType,
                )}
                options={context.typeOptions}
              />
            ),
          },
          {
            label: "Response Placeholder",
            placeholder: "e.g. Select a Shirt Size...",
            value: customField?.placeholder,
            isEditable: true,
            failedValidation: context?.validationFailed,
            onChange: (e) =>
              context.updateCustomField?.(e.target?.value, "placeholder"),
          },
          {
            label: "Response Required",
            value: (
              <GSToggle
                value={customField.required ?? false}
                falseDescription="No"
                trueDescription="YES"
                onClick={() => {
                  context?.updateCustomField?.(
                    !customField.required,
                    "required",
                  );
                }}
              />
            ),
            customView: true,
            isEditable: true,
          },
        ],
      },
    ];

    if (customField?.fieldType === "select") {
      form.push(optionsForm(context, optionsList));
    }

    return form;
  }
};

const optionsForm = (context, optionsList) => {
  return {
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "Response Options",
        value: (
          <GSItemList
            type="vertical large-gap"
            items={optionsList}
            listItem={(option) => (
              <CustomFieldOptionLineItem
                {...option}
                removeOption={context?.removeOption}
              />
            )}
            emptyMessage="There are no options added."
          />
        ),
        customView: true,
        isEditable: true,
      },
      {
        label: "Add Option",
        placeholder: "Add Option",
        value: context?.getCurrentOptionText?.(),
        failedValidation: context?.validationFailed,
        onChange: (e) => {
          context.updateCurrentOptionText(e.target.value);
        },
        isEditable: true,
      },
      {
        value: (
          <GSButton
            type="black full"
            title="add option"
            onClick={context?.addOption}
            isDisabled={!context?.getCurrentOptionText?.()}
          />
        ),
        customView: true,
        isEditable: true,
      },
    ],
  };
};

export const getPackageItemForm = (context, tournamentPackageItem) => {
  if (context) {
    const form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Item Type",
            value: (
              <OnBlur>
                <GSSelect
                  options={getItemTypes(
                    tournamentPackageItem,
                    context.hasTechSponsor,
                  )}
                  selectedOption={context.getSelectedItemType()}
                  onChange={context.setSelectedType}
                  placeholder="Select an Item Type"
                />
              </OnBlur>
            ),
            customView: true,
            onChange: (e) => {
              context?.updatePackageItem?.(e.target.value, "name");
            },
            required: true,
            validation: {
              isValid: () =>
                context.getSelectedItemType()?.value !== "technology-sponsor" ||
                (context.currentPackage.cost >= context.minTechSponsorPrice &&
                  context.currentPackage.availableLimit <= 1),
              invalidLabel:
                context.currentPackage.availableLimit > 1
                  ? "To include a Tech Sponsor package item, the package inventory amount cannot be more than 1."
                  : `To include a Tech Sponsor package item, the package price must be at least $${context.minTechSponsorPrice}.`,
            },
            failedValidation: context.validationFailed,
            isEditable: true,
          },
          {
            label: "Item Name",
            placeholder: "Item Name",
            value: tournamentPackageItem?.name,
            onChange: (e) => {
              context?.updatePackageItem?.(e.target.value, "name");
            },
            required: true,
            failedValidation: context.validationFailed,
            isEditable: true,
          },
          {
            label: "Description",
            placeholder: "Description",
            value: tournamentPackageItem?.description,
            onChange: (e) => {
              context?.updatePackageItem?.(e.target.value, "description");
            },
            type: "text-area",
            rows: 5,
            isEditable: true,
          },
        ],
      },
    ];
    if (context?.getSelectedItemType()?.value === "technology-sponsor") {
      form.push({
        type: "vertical xx-large-gap",
        title: "Additional Information",
        fields: [
          // disabling the flags toggle for now as it is not working
          // {
          //   label: "Custom Flags",
          //   value: (
          //     <GSToggle
          //       onClick={() =>
          //         context?.updatePackageItem?.(
          //           !tournamentPackageItem?.withFlags,
          //           "withFlags",
          //         )
          //       }
          //       trueDescription="Included"
          //       falseDescription="Not Included"
          //       value={tournamentPackageItem?.withFlags ?? false}
          //     ></GSToggle>
          //   ),
          //   customView: true,
          // },
          {
            label: "Dormie Network Stay & Play",
            value: (
              <GSToggle
                onClick={() =>
                  context?.updatePackageItem?.(
                    !tournamentPackageItem?.includeDormieStayAndPlay,
                    "includeDormieStayAndPlay",
                  )
                }
                trueDescription="Included"
                falseDescription="Not Included"
                value={tournamentPackageItem?.includeDormieStayAndPlay ?? false}
              ></GSToggle>
            ),
            customView: true,
          },
        ],
      });
    }
    return form;
  }
};

export const getItemTypes = (tournamentPackageItem, hasTechSponsor) => {
  let itemTypes = [
    { label: "Team Registration", value: "team" },
    { label: "Individual Registration", value: "player" },
    { label: "Sponsor", value: "sponsor" },
    { label: "Tech Sponsor", value: "technology-sponsor" },
    { label: "Skins Registration", value: "skins" },
    { label: "Add On", value: "add-on" },
  ];

  if (tournamentPackageItem?.itemType) {
    return itemTypes.filter?.(
      (t) => t.value === tournamentPackageItem.itemType,
    );
  }

  if (hasTechSponsor) {
    return itemTypes.filter?.((t) => t.value !== "technology-sponsor");
  }

  return itemTypes;
};

//remove package
export const removePackageForm = (tournamentPackage) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-container">
          <div className="disclaimer">{`Are you sure you want to remove ${tournamentPackage?.name} from your tournament? This cannot be undone.`}</div>
          <div className="item">
            <TournamentPackagesItem {...tournamentPackage} />
          </div>
        </div>
      ),
    },
  ];
  return form;
};

//remove package
export const removePackageItemForm = (tournamentPackageItem) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-container">
          <div className="disclaimer">{`Are you sure you want to remove ${tournamentPackageItem?.name} from your tournament? This cannot be undone.`}</div>
          <div className="item">
            <TournamentPackageItemsItem {...tournamentPackageItem} />
          </div>
        </div>
      ),
    },
  ];
  return form;
};

//remove package
export const removeForm = (tournamentPackageForm) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-container">
          <div className="disclaimer">{`Are you sure you want to remove ${tournamentPackageForm?.name} from your tournament? This cannot be undone.`}</div>
          <div className="item">
            <TournamentPackageFormItem {...tournamentPackageForm} />
          </div>
        </div>
      ),
    },
  ];
  return form;
};

//remove package
export const removeCustomItemForm = (customItem) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      extras: (
        <div className="remove-container">
          <div className="disclaimer">{`Are you sure you want to remove ${customItem?.name} from your tournament? This cannot be undone.`}</div>
          <div className="item">{customItem.name}</div>
        </div>
      ),
    },
  ];
  return form;
};

//package exclusivity

export const packageExclusivityForm = (context) => {
  if (context) {
    let form = [
      {
        type: "vertical xx-large-gap",
        fields: [
          {
            label: "Access List",

            description: context.getCurrentPackageAccessGroup()?.id
              ? ""
              : "This package is currently available to all registrants. Select an access list to limit availability.",
            value: context.getAccessQuickFilter?.(),
          },
        ],
      },
    ];

    return form;
  }
};
