
import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-flight-remove.scss"
import { useSelector } from "react-redux";
import { clearNotifications, getTournamentFlight, removeTournamentFlight, selectCurrentTournamentFlight, selectFlightLoading, selectFlightNotifications, selectTournamentFlights } from "../../../reducers/flightSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNotificationNavigation, useNotificationAction, useNotificationBanner } from "../../../hooks/notificationHooks";
import { setNotifications } from "../../../reducers/appSlice"
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import TournamentFlightLineItem from "./tournament-flight-line-item";
//Name the component
const TournamentFlightRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  //hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useMatch(":tournamentID/flighting/:flightID/remove")

  //selectors
  const currentFlight = useSelector(selectCurrentTournamentFlight)
  const tournamentFlights = useSelector(selectTournamentFlights)
  const flightNotifications = useSelector(selectFlightNotifications)
  const loading = useSelector(selectFlightLoading)

  //custom hooks
  useNotificationNavigation("removed", flightNotifications, -2)
  useNotificationAction("removed", flightNotifications, (notification) => {dispatch(setNotifications([notification]))})

  //effects
  useEffect(() => {
    if(match?.params?.flightID)
    {
      dispatch(getTournamentFlight(match?.params?.flightID))
    }
    return () => {dispatch(clearNotifications())}
  }, [dispatch, match?.params?.flightID ])

  //component functionality

  const remove = () => {
    setIsValid(false)
    if(currentFlight)
    {
      let flights = tournamentFlights.filter(f => f.position === currentFlight.position)
      flights.forEach(f => dispatch(removeTournamentFlight(f.id)))
    }
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        action: remove,
        isDisabled: !isValid,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Flight`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getCurrentFlight = () => {
    let flight = {...tournamentFlights?.find?.(f => f.id === currentFlight.id)}
    flight.preflights = tournamentFlights.filter(f => f.position === currentFlight.position)
    return flight
  }


  const getContent = () => {
    return <GSForm formTitle={getRemoveTitle("Delete Flight")} formSections={removeItemForm(currentFlight.name, <TournamentFlightLineItem {...getCurrentFlight()} />)}></GSForm>;
  };

  const timeoutAction = () => {
    dispatch(clearNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({notifications: flightNotifications, timeoutAction} )

  return (
    //name the component tag
    <tournament-flight-remove>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flight-remove>
  );
};

//export the new namet
export default TournamentFlightRemove;