import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-create-edit.scss";
import { AddLeaderboardForm } from "../../../forms/LeaderboardForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  createTournamentLeaderboardSetting,
  getLeaderboardSetting,
  getLeaderboardSettingRound,
  getRoundLeaderboardTypes,
  getTournamentLeaderboardSettings,
  getRoundSpecialLeaderboardTypes,
  saveTournamentLeaderboardSetting,
  selectCurrentLeaderboardSetting,
  selectLeaderboardNotifications,
  selectRoundLeaderboardTypes,
  selectRoundSpecialLeaderboardTypes,
  selectTournamentLeaderboardSettings,
  setCurrentLeaderboardSetting,
} from "../../../reducers/leaderboardSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import {
  getSaveBannerActions,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { useFormValidation } from "../../../hooks/formHooks";

//Name the component
const TournamentLeaderboardCreateEdit = (props) => {
  const matchAdd = useMatch("/:tournamentID/leaderboards/add");
  const matchEdit = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/*"
  );

  const [selectedRound, setSelectedRound] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [leaderboardKind, setLeaderboardKind] = useState({
    label: "Tournament Leaderboard",
    value: 1,
  });
  const [mainChanged, setMainChanged] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const roundLeaderboardTypes = useSelector(selectRoundLeaderboardTypes);
  const roundSpecialLeaderboardTypes = useSelector(
    selectRoundSpecialLeaderboardTypes
  );
  const currentTournament = useSelector(selectCurrentTournament);
  const tournamentLeaderboardSettings = useSelector(
    selectTournamentLeaderboardSettings
  );
  const currentLeaderboardSetting = useSelector(
    selectCurrentLeaderboardSetting
  );
  const leaderboardNotifications = useSelector(selectLeaderboardNotifications);

  useNotificationNavigation(
    "created",
    leaderboardNotifications,
    `/${matchAdd?.params?.tournamentID}/leaderboards/[id]`
  );

  useEffect(() => {
    if (matchEdit?.params?.leaderboardSettingID !== "add") {
      dispatch(getLeaderboardSetting(matchEdit?.params?.leaderboardSettingID));
      dispatch(
        getLeaderboardSettingRound(matchEdit?.params?.leaderboardSettingID)
      );
    }
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, matchEdit?.params?.leaderboardSettingID]);

  useEffect(() => {
    if (currentLeaderboardSetting.tournamentRound) {
      setSelectedRound(currentLeaderboardSetting.tournamentRound);
    }
    if (currentLeaderboardSetting.roundLeaderboard) {
      setLeaderboardKind(
        currentLeaderboardSetting.roundLeaderboard
          ? currentLeaderboardSetting.skins
            ? { label: "Skins Leaderboard", value: 3 }
            : { label: "Round Leaderboard", value: 2 }
          : { label: "Tournament Leaderboard", value: 1 }
      );
    }
    if (currentLeaderboardSetting.name) {
      setSelectedType({ id: 1, displayName: currentLeaderboardSetting.name });
    }
  }, [
    currentLeaderboardSetting.tournamentRound,
    currentLeaderboardSetting.roundLeaderboard,
    currentLeaderboardSetting.skins,
    currentLeaderboardSetting.name,
  ]);

  const getAvailableLeaderboardTypes = (types) => {
    let currentTypes = tournamentLeaderboardSettings
      ?.filter?.((ls) => ls.tournamentRound?.id === selectedRound?.id)
      ?.map?.((fls) => fls.name);
    return types?.filter?.((lt) => !currentTypes.includes(lt.displayName));
  };

  const [context, isValid, setIsValid] = useFormValidation(false);

  context.toggleRoundLeaderboard = () => {
    dispatch(
      setCurrentLeaderboardSetting({
        ...currentLeaderboardSetting,
        roundLeaderboard: !currentLeaderboardSetting.roundLeaderboard,
      })
    );
  };

  context.selectRound = (option) => {
    context.setType({});
    setSelectedRound(option.value);
    dispatch(getRoundLeaderboardTypes(option.value.id));
    dispatch(getRoundSpecialLeaderboardTypes(option.value.id));
  };

  context.getSelectedRound = () => {
    if (selectedRound) {
      return context
        .getTournamentRounds()
        ?.find?.((tr) => tr.value?.id === selectedRound?.id);
    }
  };

  context.getLeaderboardKinds = () => {
    if (context.isAdd()) {
      return [
        { label: "Tournament Leaderboard", value: 1 },
        { label: "Round Leaderboard", value: 2 },
        { label: "Skins Leaderboard", value: 3 },
      ];
    } else {
      return [leaderboardKind];
    }
  };

  context.getLeaderboardTypes = () => {
    if (leaderboardKind?.value === 1)
      return getAvailableLeaderboardTypes(
        currentTournament.leaderboardTypes
      )?.map?.((t) => ({
        label: t.displayName,
        value: t,
      }));
    else if (leaderboardKind?.value === 2)
      return getAvailableLeaderboardTypes([
        ...roundLeaderboardTypes,
        ...roundSpecialLeaderboardTypes,
      ])
        ?.filter?.((lt) => !lt.id.includes("skins"))
        ?.map?.((t) => ({
          label: t.roundDisplayName,
          value: t,
        }));
    else if (leaderboardKind?.value === 3)
      return getAvailableLeaderboardTypes(roundLeaderboardTypes)
        ?.filter?.((lt) => lt.id.includes("skins"))
        ?.map?.((t) => ({
          label: t.displayName,
          value: t,
        }));
  };

  context.getTournamentRounds = () => {
    return currentTournament.tournamentRounds?.map?.((r) => ({
      label: r.roundName ?? `Round ${r.number}`,
      value: r,
    }));
  };

  context.updateLeaderboardSetting = (value, property) => {
    setIsValid(true);
    if(property === 'main') {
      setMainChanged(true);
    }
    let update = { ...currentLeaderboardSetting };
    update[property] = value;
    dispatch(setCurrentLeaderboardSetting(update));
  };

  context.validationFailed = () => {
    setIsValid(false);
  };

  context.getSelectedType = () => {
    return selectedType;
  };

  context.setType = (option) => {
    setIsValid(true);
    setSelectedType(option.value);
  };

  context.getLeaderboardKind = () => {
    return leaderboardKind;
  };

  context.setLeaderboardKind = (option) => {
    context.setType({});
    setLeaderboardKind(option);
  };

  context.isAdd = () => {
    return matchAdd;
  };

  context.setMainLeaderboard = () => {
    context.updateLeaderboardSetting?.(!currentLeaderboardSetting.main, "main");
  };

  context.isPreflight = () => {
    return currentTournament?.preflight;
  };

  context.mainChanged = () => {
    return mainChanged;
  }

  const save = () => {
    setUnsaved(false);
    setIsValid(false);
    let save = { ...currentLeaderboardSetting };
    save.tournament = currentTournament;
    save.tournamentRound = selectedRound.id ? selectedRound : null;
    save.leaderboardType = selectedType;
    if(save.main){
      save.flighted = true
    }
    if (context.isAdd()) {
      dispatch(createTournamentLeaderboardSetting(save)).then((res) => {
        // refresh leaderboard settings if we set it to main,
        // to make leaderboard list update
        if(!res.error && mainChanged) {
          dispatch(getTournamentLeaderboardSettings(currentTournament.id));
          setMainChanged(false);
        }
      });
    } else {
      dispatch(saveTournamentLeaderboardSetting(save)).then((res) => {
        if(!res.error && mainChanged) {
          dispatch(getTournamentLeaderboardSettings(currentTournament.id));
          setMainChanged(false);
        }
      });
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    if (isValid && !bannerNotifications) {
      setUnsaved(true);
      return;
    }
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Leaderboard Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={
          matchEdit?.params?.leaderboardSettingID === "add"
            ? "Add Leaderboard"
            : "Edit Leaderboard"
        }
        formSections={AddLeaderboardForm(context, currentLeaderboardSetting)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: leaderboardNotifications,
    saveAction: save,
    bannerActions: getSaveBannerActions(save, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications, setUnsaved] = useNotificationBanner(
    notificationSettings
  );

  return (
    //name the component tag
    <tournament-leaderboard-create-edit>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-create-edit>
  );
};

//export the new namet
export default TournamentLeaderboardCreateEdit;
