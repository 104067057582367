import React from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-default-watch-item-delete.scss";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import { useSelector } from "react-redux";
import {
  deleteDefaultWatchItem,
  getTournamentDefaultWatchItem,
  selectCurrentDefaultWatchItem,
  selectSettingsNotifications,
} from "../../../reducers/settingsSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import TournamentPackageWatchItem from "../tournament-promotion/tournament-packages/tournament-package-watch-item";

//Name the component
const TournamentSettingsDefaultWatchItemDelete = (props) => {

  const dispatch = useDispatch();
  const match = useMatch(
    "/:tournamentID/settings/default-watch-items/:watchItemID/delete"
  );

  useEffect(() => {
    if (match?.params?.watchItemID) {
      dispatch(getTournamentDefaultWatchItem(match?.params?.watchItemID));
    }
  }, [dispatch, match?.params?.watchItemID]);

  const navigate = useNavigate();
  const notifications = useSelector(selectSettingsNotifications);

  const currentWatchItem = useSelector(selectCurrentDefaultWatchItem);

  useNotificationNavigation("removed", notifications, -2, "Default Watch Item");

  const save = () => {
    dispatch(deleteDefaultWatchItem(match?.params?.watchItemID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        action: save,
        isDisabled: notifications?.length > 0,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Email`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Email")}
        formSections={removeItemForm(
          currentWatchItem.email,
          <TournamentPackageWatchItem {...currentWatchItem}/>
          , "delete", `Are you sure you want to delete ${currentWatchItem.email}?`
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-settings-default-watch-item-delete>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-default-watch-item-delete>
  );
};

//export the new namet
export default TournamentSettingsDefaultWatchItemDelete;
