import { Route } from "react-router-dom";
import TournamentRoundFlights from "../../components/tournament/tournament-rounds/round-flights/tournament-round-flights";
import TournamentRoundHoleAssignment from "../../components/tournament/tournament-rounds/round-hole-assignments/tournament-round-hole-assignment";
import TournamentRoundHoleAssignmentLock from "../../components/tournament/tournament-rounds/round-hole-assignments/tournament-round-hole-assignment-lock";
import TournamentRoundHoleAutoAssignment from "../../components/tournament/tournament-rounds/round-hole-assignments/tournament-round-hole-auto-assigment";
import TournamentRoundRemoveHoleAssignments from "../../components/tournament/tournament-rounds/round-hole-assignments/tournament-round-remove-hole-assignments";
import TournamentRoundTeamHoleAssignments from "../../components/tournament/tournament-rounds/round-hole-assignments/tournament-round-team-hole-assignments";
import TournamentRoundLeaderboards from "../../components/tournament/tournament-rounds/round-leaderboards/tournament-round-leaderboards";
import TournamentRoundPrintouts from "../../components/tournament/tournament-rounds/round-printouts/tournament-round-printouts";
import TournamentRoundRules from "../../components/tournament/tournament-rounds/round-rules/tournament-round-rules";
import TournamentRoundScoring from "../../components/tournament/tournament-rounds/round-scoring/tournament-round-scoring";
import TournamentRoundScoringSettings from "../../components/tournament/tournament-rounds/round-scoring/tournament-round-scoring-settings";
import TournamentRoundScoringSettingsGHIN from "../../components/tournament/tournament-rounds/round-scoring/tournament-round-scoring-settings-ghin";
import TournamentRoundTeamScorecard from "../../components/tournament/tournament-rounds/round-scoring/tournament-round-team-scorecard";
import TournamentRoundUploadScores from "../../components/tournament/tournament-rounds/round-scoring/tournament-round-upload-scores";
import TournamentRoundTeeSettings from "../../components/tournament/tournament-rounds/round-tee-settings/tournament-round-tee-settings";
import TournamentRoundTeeDetails from "../../components/tournament/tournament-rounds/round-tee-settings/tournemant-round-tee-details";
import TournamentRoundHoleSponsors from "../../components/tournament/tournament-rounds/sponsor-round-holes/tournament-round-hole-sponsors";
import TournamentRoundCreateEdit from "../../components/tournament/tournament-rounds/tournament-round-create-edit";
import TournamentRoundDetails from "../../components/tournament/tournament-rounds/tournament-round-details";
import TournamentRoundRemove from "../../components/tournament/tournament-rounds/tournament-round-remove";
import TournamentRounds from "../../components/tournament/tournament-rounds/tournament-rounds";
import TournamentRoundsExports from "../../components/tournament/tournament-rounds/tournament-rounds-exports";
import TournamentRoundDeactivate from "../../components/tournament/tournament-rounds/tournament-round-deactivate";
//import SidePanelPage from "../../templates/side-panel-page"

export const getTournamentRoundRoutes = () => {
  return (
    <Route path={"rounds"} element={<TournamentRounds />}>
      <Route path="new" element={<TournamentRoundCreateEdit />}></Route>
      <Route path="documents" element={<TournamentRoundsExports />}></Route>
      <Route path=":roundID" element={<TournamentRoundDetails />}></Route>
      <Route path=":roundID/remove" element={<TournamentRoundRemove />}></Route>
      <Route
        path=":roundID/copy"
        element={<TournamentRoundCreateEdit />}
      ></Route>
      <Route
        path=":roundID/details"
        element={<TournamentRoundCreateEdit />}
      ></Route>
      <Route
        path=":roundID/deactivate"
        element={<TournamentRoundDeactivate />}
      ></Route>
      <Route
        path=":roundID/hole-assignments"
        element={<TournamentRoundTeamHoleAssignments />}
      ></Route>
      <Route
        path=":roundID/hole-assignments/:id"
        element={<TournamentRoundHoleAssignment />}
      ></Route>
      <Route
        path=":roundID/hole-assignments/remove-all"
        element={<TournamentRoundRemoveHoleAssignments />}
      ></Route>
      <Route
        path=":roundID/hole-assignments/auto-assign"
        element={<TournamentRoundHoleAutoAssignment />}
      ></Route>
      <Route
        path=":roundID/hole-assignments/lock-holes"
        element={<TournamentRoundHoleAssignmentLock />}
      ></Route>
      <Route
        path=":roundID/hole-sponsors"
        element={<TournamentRoundHoleSponsors />}
      ></Route>
      <Route
        path=":roundID/flights"
        element={<TournamentRoundFlights />}
      ></Route>
      <Route
        path=":roundID/skins"
        element={<TournamentRoundCreateEdit />}
      ></Route>
      <Route
        path=":roundID/leaderboards"
        element={<TournamentRoundLeaderboards />}
      ></Route>
      <Route path=":roundID/rules" element={<TournamentRoundRules />}></Route>
      <Route
        path=":roundID/courses"
        element={<TournamentRoundTeeSettings />}
      ></Route>
      <Route
        path=":roundID/courses/:courseID/tees/:teeID"
        element={<TournamentRoundTeeDetails />}
      ></Route>
      <Route
        path=":roundID/documents"
        element={<TournamentRoundPrintouts />}
      ></Route>
      <Route
        path=":roundID/scoring"
        element={<TournamentRoundScoring />}
      ></Route>
      <Route
        path=":roundID/scoring/:type/:id"
        element={<TournamentRoundScoring />}
      ></Route>
      <Route
        path=":roundID/scoring/upload"
        element={<TournamentRoundUploadScores />}
      ></Route>
      <Route
        path=":roundID/scoring/settings"
        element={<TournamentRoundScoringSettings />}
      ></Route>
      <Route
        path=":roundID/scoring/settings/GHIN"
        element={<TournamentRoundScoringSettingsGHIN />}
      ></Route>
      <Route
        path=":roundID/scoring/:liveTournamentScorecardID"
        element={<TournamentRoundTeamScorecard />}
      ></Route>
    </Route>
  );
};
