import React, { useState } from "react";
import {
  GSActionBar,
  GSField,
  GSItemList,
  GSScorecard,
  GSSidePanelPage,
  ScoreTotal,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faEye,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-round-team-scorecard.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  clearNotifications,
  getLiveTournamentScorecardLiveRounds,
  saveLiveTournamentRound,
  saveTeamRound,
  selectAnnounceScore,
  selectCurrentLiveTournamentScorecard,
  selectLoading,
  selectNotifications,
  setLiveTournamentScorecard,
  updateLiveTournamentRound,
} from "../../../../reducers/scorecardSlice";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { useResizeSplit } from "../../../../hooks/scorecardHooks";
import { getRoundName } from "../../../../helpers/Converters";

//Name the component
const TournamentRoundTeamScorecard = (props) => {
  const [isValid, setIsValid] = useState(false);
  const [editable, setEditable] = useState(true);
  const splitNines = useResizeSplit();
  //url parameters
  const [scorecardParams] = useSearchParams();
  const teamID = scorecardParams.get("teamID");
  const playerID = scorecardParams.get("playerID");
  //hooks
  const navigate = useNavigate();
  const match = useMatch(
    "/:tournamentID/rounds/:roundID/scoring/:liveTournamentScorecardID"
  );
  const dispatch = useDispatch();
  //selectors
  const currentLiveTournamentScorecard = useSelector(
    selectCurrentLiveTournamentScorecard
  );
  const notifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading);
  const announceScore = useSelector(selectAnnounceScore);
  //memos
  let utterance = useMemo(() => new SpeechSynthesisUtterance(), []);
  let speechSynthesis = useMemo(() => window.speechSynthesis, []);
  //effects
  useEffect(() => {
    dispatch(
      getLiveTournamentScorecardLiveRounds(
        match.params.liveTournamentScorecardID
      )
    );
    return () => {
      dispatch(setLiveTournamentScorecard({}));
    };
  }, [match?.params?.liveTournamentScorecardID, dispatch]);

  const save = () => {
    setIsValid(false)
    let updates = currentLiveTournamentScorecard?.liveRounds?.filter?.(
      (ltr) => ltr.updated
    );
    if (updates?.length > 0) {
      updates.forEach?.((update) => {
        dispatch(saveLiveTournamentRound(update));
        if (update?.teamRound?.updated) {
          dispatch(saveTeamRound(update.teamRound));
        }
      });
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Scorecard Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getLiveScorecardRounds = () => {
    if (teamID) {
      return currentLiveTournamentScorecard?.liveRounds?.filter?.(
        (ltr) => ltr?.tournamentTeam?.id === teamID
      );
    }
    if (playerID) {
      return currentLiveTournamentScorecard?.liveRounds?.filter?.(
        (ltr) => ltr?.player?.id === playerID
      );
    }
    return currentLiveTournamentScorecard?.liveRounds;
  };

  const getPageActions = () => {
    let actions = [
      {
        actionIcon: editable ? faEye: faPen,
        actionClick: () => {
          setEditable((previousState) => !previousState);
        },
        type: "light-grey no-wrap",
      },
    ];
    return actions;
  };

  const changeScore = (liveTournamentRound, score, value) => {
    if (value) {
      setIsValid(true);
      let index = liveTournamentRound.scores?.findIndex?.(
        (s) => s.id === score.hole.id
      );
      let update = { ...liveTournamentRound };
      update["tournament-team"] = update.tournamentTeam;
      update["live-tournament-scorecard"] = update.liveScorecard;
      update.scores = [...liveTournamentRound.scores];
      update.scores[index] = {
        ...score.hole,
        score: parseInt(value),
        updated_at: new Date().toISOString(),
        live_score_key: score.hole.liveScoreKey,
      };
      update.updated = true;
      update.updatedAt = new Date().toISOString();
      dispatch(updateLiveTournamentRound(update));
    }
  };

  const scoreChanged = (e, score, item) => {
    changeScore(item, score, e.target.value);
  };

  const toggleOverride = (liveRound) => {
    setIsValid(true);
    let update = { ...liveRound?.teamRound };
    update.overrideScore = !update.overrideScore;
    update.updated = true;
    update["tournament-team"] = update.tournamentTeam;
    update["live-tournament-scorecard"] = update.liveScorecard;
    let updatedLiveRound = { ...liveRound, updated: true, teamRound: update };
    dispatch(updateLiveTournamentRound(updatedLiveRound));
  };

  const updateTeamRound = (liveRound, property, value) => {
    setIsValid(true);
    let update = { ...liveRound?.teamRound };
    update[property] = value;
    update.updated = true;
    let updatedLiveRound = { ...liveRound, updated: true, teamRound: update };
    dispatch(updateLiveTournamentRound(updatedLiveRound));
  };

  // const updatePoint = (liveRound, point, value) => {
  //   let points = [...liveRound?.teamRound.pointsScores];
  //   let pointIndex = points?.findIndex?.((p) => p.id === point.id);
  //   let update = { ...point };
  //   update.points = value;
  //   points[pointIndex] = update;
  //   updateTeamRound(liveRound, "pointsScores", points);
  // };

  const scoreFocused = (e, score) => {
    e.target.select();
    if (announceScore) {
      speechSynthesis.cancel();
      utterance.rate = 3;
      utterance.text = `${score.hole.score}`;
      speechSynthesis.speak(utterance);
    }
  };
  const scoreKeyUp = (e, score, item) => {
    if (e.keyCode === 9) {
      return;
    }
    let index = item.scores?.findIndex?.((s) => s.id === score?.hole?.id);
    if (e.keyCode === 37) {
      let nextID = item?.scores?.[index - 1]?.id;
      document.getElementById?.(nextID)?.focus?.();
      return;
    }
    let nextID = item?.scores?.[index + 1]?.id;
    document.getElementById?.(nextID)?.focus?.();
  };

  const getOverrides = (liveRound) => {
    return (
      <div className="overrides">
        
        <div className="override totals">
          <GSField
            label="Gross total"
            value={liveRound.teamRound.grossScore}
            placeholder="Gross Total"
            onChange={(e) => {
              updateTeamRound(liveRound, "grossScore", e.target.value);
            }}
            isEditable={editable}
          ></GSField>
          <GSField
            label="Net total"
            placeholder="Net Total"
            value={liveRound.teamRound.netScore}
            onChange={(e) => {
              updateTeamRound(liveRound, "netScore", e.target.value);
            }}
            isEditable={editable}
          ></GSField>
        </div>
        {/* {liveRound.teamRound?.pointsScores?.length > 0 ? (
          <>
            <GSActionBar header={`${liveRound.userName}'s Points`}></GSActionBar>
            <div className="override points full-width">
              <GSItemList
                type="horizontal large-gap scrollable full-width"
                items={liveRound.teamRound.pointsScores}
                listItem={(item) => (
                  <GSField
                    label={item.name}
                    placeholder={item.name}
                    value={item.points}
                    isEditable={editable}
                    onChange={(e) => {
                      updatePoint(liveRound, item, e.target.value);
                    }}
                  ></GSField>
                )}
              ></GSItemList>
            </div>
          </>
        ) : (
          ""
        )} */}
      </div>
    );
  };

  const getToggle = (liveRound) => {
    if(!editable){
      return []
    }
    return [
      {
        actionType: "toggle",
        pageActionProps:{
          rowReverse: true,
          trueDescription: "Override Total",
          falseDescription: "Override Total",
          value: liveRound.teamRound.overrideScore,
          onClick: () => toggleOverride(liveRound)
        }
      }
    ]
  }

  const getIndividualScorecard = (liveRound) => {
    let scores = liveRound.scores.map(score => ({position: score.position, number: score.number, par: score.par, score: score.score, id: score.id, liveScoreKey: score.liveScoreKey}))
    return (
      <div className="live-scorecard">
        <GSActionBar header={`${liveRound.userName}`} pageActions={getToggle(liveRound)}></GSActionBar>
        {liveRound.teamRound.overrideScore ? (
          getOverrides(liveRound)
        ) : (
          <GSScorecard
            scorecardChanged={(e, score) => {
              scoreChanged(e, score, liveRound);
            }}
            scorecardFocused={scoreFocused}
            scorecardKeyUp={(e, score) => {
              scoreKeyUp(e, score, liveRound);
            }}
            splitNines={splitNines}
            showTotalColumn
            holes={scores}
            user={{ name: `Round ${getRoundName(liveRound?.tournamentRound)}` }}
            editable={editable? ["score"] : []}
            totalScore={<ScoreTotal scores={liveRound.scores}></ScoreTotal>}
          />
        )}
      </div>
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Scorecard Details" pageActions={getPageActions()} />
        <GSItemList
          type="vertical"
          items={getLiveScorecardRounds()}
          emptyMessage="Loading Scorecards..."
          loading={loading.includes?.("getLiveTournamentScorecardLiveRounds")}
          loadingMainText="Loading Scorecards..."
          listItem={(item) => getIndividualScorecard(item)}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-round-team-scorecard>
      <GSSidePanelPage
      loading={loading?.length > 0}
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-team-scorecard>
  );
};

//export the new namet
export default TournamentRoundTeamScorecard;
