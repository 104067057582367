import React from "react";
import {
  GSForm,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-remove-hole-assignments.scss";
import { getRemoveTitle, removeItemForm } from "../../../../forms/DeleteForm";
import { useSelector } from "react-redux";
import {
  clearRoundNotifications,
  getRound,
  getRoundHoleAssignments,
  removeHoleAssignments,
  selectCurrentRound,
  selectCurrentRoundHoleAssignments,
  selectRoundNotifications,
} from "../../../../reducers/roundSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TournamentRoundHoleAssignmentItem from "./tournament-round-hole-assignment-item";
import {
  getTournamentTeams,
  selectTournamentTeams,
} from "../../../../reducers/teamsSlice";
import { useNotificationBanner, useNotificationNavigation } from "../../../../hooks/notificationHooks";

//Name the component
const TournamentRoundRemoveHoleAssignments = (props) => {
  //hooks
  const navigate = useNavigate();
  const match = useMatch(
    "/:tournamentID/rounds/:roundID/hole-assignments/remove-all"
  );
  const dispatch = useDispatch();

  //selectors
  const currentRound = useSelector(selectCurrentRound);
  const roundHoleAssignments = useSelector(selectCurrentRoundHoleAssignments);
  const notifications = useSelector(selectRoundNotifications);
  const tournamentTeams = useSelector(selectTournamentTeams);

  //custom hooks
  useNotificationNavigation("removed", notifications, -1)

  //effects
  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
      dispatch(getRoundHoleAssignments(match.params.roundID));
    }
  }, [dispatch, match?.params?.roundID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
    }
    return () => {dispatch(clearRoundNotifications())}
  }, [dispatch, match?.params?.tournamentID]);

  //component functionality
  const removeAllHoleAssignments = () => {
    dispatch(removeHoleAssignments(match?.params?.roundID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Clear",
        action: removeAllHoleAssignments,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Clear Hole Assignments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getHoleAssignments = () => {
    return roundHoleAssignments?.map?.((rha) => ({
      ...rha,
      tournamentTeams: rha.tournamentTeams?.map?.((hatt) =>
        tournamentTeams?.find?.((tt) => tt?.id === hatt?.id)
      ),
    }));
  };

  const getHoleAssignmentList = () => {
    return (
      <GSItemList
        type="vertical small-gap"
        items={getHoleAssignments()}
        listItem={(item) => <TournamentRoundHoleAssignmentItem {...item} />}
      ></GSItemList>
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Clear Hole Assignments")}
        formSections={removeItemForm(
          `Round ${currentRound.number} hole assignments`,
          getHoleAssignmentList(), "clear"
        )}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
  }

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-round-remove-hole-assignments>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-remove-hole-assignments>
  );
};

//export the new namet
export default TournamentRoundRemoveHoleAssignments;
