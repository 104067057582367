import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  createThunkResponse,
  customerInvoices,
  defaultWatchItems,
  deleteThunkResponse,
  getThunkResponse,
  saveThunkResponse,
  tournament,
  tournamentPaymentSettings,
} from "../app/api";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  shortDelay,
} from "../helpers/JSONapi";

export const getSettingsManagers = createAsyncThunk(
  "settings/getSettingsManagers",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/access-control-list`
    );
    return response;
  }
);

export const getTournamentPaymentSettings = createAsyncThunk(
  "settings/getTournamentPaymentSettings",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/tournament-payment-setting`
    );
    return response;
  }
);

export const getTournamentDefaultWatchItems = createAsyncThunk(
  "settings/getTournamentDefaultWatchItems",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/default-watch-items`
    );
    return response;
  }
);

export const getTournamentDefaultWatchItem = createAsyncThunk(
  "settings/getTournamentDefaultWatchItem",
  async (watchItemID, thunkAPI) => {
    const response = await gsApi.get(`${defaultWatchItems}/${watchItemID}`);
    return response;
  }
);

export const getTournamentCustomerInvoice = createAsyncThunk(
  "settings/getTournamentCustomerInvoice",
  async (id, thunkAPI) => {
    const response = await gsApi.get(
      `${tournamentPaymentSettings}/${id}/customer-invoice`
    );
    return response;
  }
);

export const getTournamentPaymentSettingsByID = createAsyncThunk(
  "settings/getTournamentPaymentSettings",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${tournamentPaymentSettings}/${id}`);
    return response;
  }
);

//update settings

export const updateDefaultWatchItem = createAsyncThunk(
  "tournaments/updateDefaultWatchItem",
  async (defaultWatchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      defaultWatchItem,
      "Default-watch-items",
      getOpts(defaultWatchItem, [
        ...getIDRelationship("tournament"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${defaultWatchItems}/${defaultWatchItem.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const updateTournamentPaymentSettings = createAsyncThunk(
  "settings/updateTournamentPaymentSettings",
  async (tournamentPaymentSettingUpdate, thunkAPI) => {
    let serializedData = getSerializedData(
      tournamentPaymentSettingUpdate,
      "tournament-payment-settings",
      getOpts(tournamentPaymentSettingUpdate, [
        ...getIDRelationship("tournament"),
      ])
    );

    return await getResponse(
      gsApi.patch,
      `${tournamentPaymentSettings}/${tournamentPaymentSettingUpdate.id}`,
      serializedData,
      thunkAPI
    );
  }
);

export const saveCustomerInvoice = createAsyncThunk(
  "settings/saveCustomerInvoice",
  async (customerInvoice, thunkAPI) => {
    let serializedData = getSerializedData(
      customerInvoice,
      "customer-invoices",
      getOpts(customerInvoice)
    );

    return await getResponse(
      gsApi.patch,
      `${customerInvoices}/${customerInvoice.id}`,
      serializedData,
      thunkAPI
    );
  }
);

//create

export const createDefaultWatchItem = createAsyncThunk(
  "settings/createDefaultWatchItem",
  async (defaultWatchItem, thunkAPI) => {
    let serializedData = getSerializedData(
      defaultWatchItem,
      "default-watch-items",
      getOpts(defaultWatchItem, [...getIDRelationship("tournament")])
    );

    return await getResponse(
      gsApi.post,
      `${defaultWatchItems}`,
      serializedData,
      thunkAPI
    );
  }
);

export const createCustomerInvoice = createAsyncThunk(
  "settings/createCustomerInvoice",
  async (customerInvoice, thunkAPI) => {
    let opts = getOpts(customerInvoice, [
      ...getIDRelationship("tournament-payment-setting"),
    ]);
    opts.keyForAttribute = "spinal-case";
    let serializedData = getSerializedData(
      customerInvoice,
      "customer-invoices",
      opts
    );

    return await getResponse(
      gsApi.post,
      `${customerInvoices}`,
      serializedData,
      thunkAPI
    );
  }
);

//delete
export const deleteDefaultWatchItem = createAsyncThunk('settings/deleteDefaultWatchItem', async (watchID, thunkAPI) => {
  const response = await gsApi.delete(`${defaultWatchItems}/${watchID}`, {data: {}});
  return response;
})

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    managers: [],
    currentManager: {},
    currentPaymentSettings: {},
    defaultWatchItems: [],
    currentDefaultWatchItem: {},
    customerInvoice: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setManagers: (state, action) => {
      state.managers = action.payload;
    },
    setCurrentManager: (state, action) => {
      state.currentManager = {};
    },
    setPaymentSetting: (state, action) => {
      state.currentPaymentSettings = action.payload;
    },
    setCustomerInvoice: (state, action) => {
      state.customerInvoice = action.payload;
    },
    setCurrentWatchItem: (state, action) => {
      state.currentDefaultWatchItem = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...getThunkResponse(getSettingsManagers, (state, action) => {
      state.managers = action.payload;
    }),
    ...getThunkResponse(getTournamentPaymentSettings, (state, action) => {
      state.currentPaymentSettings = action.payload;
    }),
    ...saveThunkResponse(updateTournamentPaymentSettings, (state, action) => {
      state.currentPaymentSettings = action.payload;
    }),
    ...getThunkResponse(getTournamentCustomerInvoice, (state, action) => {
      state.customerInvoice = action.payload;
    }),
    ...createThunkResponse(
      createCustomerInvoice,
      (state, action) => {},
      "Customer Invoice"
    ),
    ...saveThunkResponse(
      saveCustomerInvoice,
      (state, action) => {},
      "Customer Invoice"
    ),
    ...getThunkResponse(getTournamentDefaultWatchItems, (state, action) => {
      state.defaultWatchItems = action.payload;
    }),
    ...getThunkResponse(getTournamentDefaultWatchItem, (state, action) => {
      state.currentDefaultWatchItem = action.payload;
    }),
    ...saveThunkResponse(updateDefaultWatchItem, (state, action) => {}, "Default Watch Item", shortDelay),
    ...createThunkResponse(createDefaultWatchItem, (state, action) => {}, "Default Watch Item", shortDelay),
    ...deleteThunkResponse(deleteDefaultWatchItem, (state, action) => {}, "Default Watch Item", shortDelay)
  },
});

export const {
  setManagers,
  setCurrentManager,
  setPaymentSetting,
  setCurrentWatchItem,
  addNotification,
  clearNotifications,
  setCustomerInvoice,
} = settingsSlice.actions;

//export any selectors needed
export const selectManagers = (state) => state?.[settingsSlice.name]?.managers;

export const selectCurrentPaymentSettings = (state) =>
  state?.[settingsSlice.name]?.currentPaymentSettings;

export const selectCurrentManager = (state) =>
  state?.[settingsSlice.name]?.currentManager;

export const selectCustomerInvoice = (state) =>
  state?.[settingsSlice.name]?.customerInvoice;

export const selectSettingsNotifications = (state) =>
  state?.[settingsSlice.name]?.notifications;

export const selectDefaultWatchItems = (state) =>
  state?.[settingsSlice.name]?.defaultWatchItems;

export const selectCurrentDefaultWatchItem = (state) =>
  state?.[settingsSlice.name]?.currentDefaultWatchItem;

export default settingsSlice.reducer;

//ADD reducer to store.js
