import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import { fileUplaod, thunkResponse } from "../app/api";
import {
  extraLongDelay,
  mediumDelay,
  shortDelay,
} from "../helpers/JSONapi";

export const uploadFile = createAsyncThunk(
  "utilities/uploadFile",
  async (file, thunkAPI) => {
    const response = await gsApi.post(`${fileUplaod}`, file);
    return response;
  }
);

export const utilitySlice = createSlice({
  name: "utilities",
  initialState: {
    uploadedFile: "",
    croppedImage: {},
    croppedTarget: {},
    notifications: [],
    loading: []
  },
  reducers: {
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    setCroppedTarget: (state, action) => {
      state.croppedTarget = action.payload;
    },
    notifySlideOut: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearUtility: (state, action) => {
      state.uploadedFile = "";
      state.croppedImage = {};
      state.croppedTarget = {};
      state.notifications = [];
    },
    clearUtilityNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    ...thunkResponse(
      uploadFile,
      (state, action) => {
        state.uploadedFile = action.payload.data.location;
      },
      "File Upload",
      {
        pendingText: "Uploading File",
        pendingDelay: mediumDelay,
        fulfilledText: "File has been uploaded!",
        fulfilledDelay: shortDelay,
        rejectedDelay: extraLongDelay,
      }
    ),
  },
});

export const {
  setUploadedFile,
  setCroppedImage,
  setCroppedTarget,
  notifySlideOut,
  clearUtilityNotifications,
  clearUtility,
} = utilitySlice.actions;

export const selectCroppedTarget = (state) => state.utilities.croppedTarget;

export const selectUploadedFile = (state) => state.utilities.uploadedFile;

export const selectUtilityNotifications = (state) =>
  state.utilities.notifications;

export default utilitySlice.reducer;
