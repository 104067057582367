import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  getOpts,
  getResponse,
  getSerializedData,
  longDelay,
} from "../helpers/JSONapi";

import {
  createThunkResponse,
  deleteThunkResponse,
  getThunkResponse,
  saveThunkResponse,
  sponsorRoundHoles,
  sponsors,
  tournament,
} from "../app/api";

//get

export const getTournamentSponsors = createAsyncThunk(
  "sponsors/getTournamentSponsors",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(`${tournament}/${tournamentID}/sponsors`);
    return response;
  },
);

export const getTournamentSponsor = createAsyncThunk(
  "sponsors/getTournamentSponsor",
  async (sponsorID, thunkAPI) => {
    const response = await gsApi.get(`${sponsors}/${sponsorID}`);
    return response;
  },
);

export const getTournamentSponsorRoundHoles = createAsyncThunk(
  "sponsors/getTournamentSponsorRoundHoles",
  async (tournamentID, thunkAPI) => {
    const response = await gsApi.get(
      `${tournament}/${tournamentID}/sponsor-round-holes`,
    );
    return response;
  },
);

//post

export const createTournamentSponsor = createAsyncThunk(
  "sponsors/createTournamentSponsor",
  async (sponsor, thunkAPI) => {
    let serializedData = getSerializedData(
      sponsor,
      "sponsors",
      getOpts(sponsor, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.post,
      `${sponsors}`,
      serializedData,
      thunkAPI,
    );
  },
);

//patch

export const saveTournamentSponsor = createAsyncThunk(
  "sponsors/saveTournamentSponsor",
  async (sponsor, thunkAPI) => {
    let serializedData = getSerializedData(
      sponsor,
      "sponsors",
      getOpts(sponsor, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.patch,
      `${sponsors}/${sponsor.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const saveTournamentSponsorOrder = createAsyncThunk(
  "sponsors/saveTournamentSponsorOrder",
  async (sponsor, thunkAPI) => {
    let serializedData = getSerializedData(
      sponsor,
      "sponsors",
      getOpts(sponsor, [...getTournamentRelationship()]),
    );
    return await getResponse(
      gsApi.patch,
      `${sponsors}/${sponsor.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

export const saveSponosorRoundHole = createAsyncThunk(
  "sponsors/saveSponosorRoundHole",
  async (sponsorRoundHole, thunkAPI) => {
    let serializedData = getSerializedData(
      sponsorRoundHole,
      "sponsor-round-holes",
      getOpts(sponsorRoundHole, [
        ...getTournamentRelationship(),
        ...getSponsorRelationship(),
        ...getRoundCourseRelationship(),
        ...getTournamentRelationship(),
      ]),
    );
    return await getResponse(
      gsApi.patch,
      `${sponsorRoundHoles}/${sponsorRoundHole.id}`,
      serializedData,
      thunkAPI,
    );
  },
);

//remove

export const removeTournamentSponsor = createAsyncThunk(
  "sponsors/removeTournamentSponsor",
  async (sponsorID, thunkAPI) => {
    const response = await gsApi.delete(`${sponsors}/${sponsorID}`, {
      data: {},
    });
    return response;
  },
);

export const sponsorSlice = createSlice({
  name: "sponsors",
  initialState: {
    sponsorList: [],
    currentSponsor: {},
    tournamentSponsorRoundHoles: [],
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 77
  reducers: {
    setSponsorList: (state, action) => {
      state.sponsorList = action.payload;
    },
    setRoundHoleSponsor: (state, action) => {
      const index = state.tournamentSponsorRoundHoles.findIndex?.(
        (tsrh) => tsrh.id === action.payload.id,
      );
      state.tournamentSponsorRoundHoles[index] = action.payload;
    },
    setCurrentSponsor: (state, action) => {
      state.currentSponsor = action.payload;
    },
    setTournamentSponsorRoundHoles: (state, action) => {
      state.tournamentSponsorRoundHoles = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: {
    //get Tournament Sponsors
    ...getThunkResponse(getTournamentSponsors, (state, action) => {
      state.sponsorList = action.payload;
    }),
    //get Tournament Sponsor Round Holes
    ...getThunkResponse(getTournamentSponsorRoundHoles, (state, action) => {
      state.tournamentSponsorRoundHoles = action.payload;
    }),
    //get Tournament Sponsors
    ...getThunkResponse(getTournamentSponsor, (state, action) => {
      state.currentSponsor = action.payload;
    }),
    //create sponsor
    ...createThunkResponse(
      createTournamentSponsor,
      (state, action) => {
        state.sponsorList = [...state.sponsorList, action.payload];
      },
      "Tournament Sponsor",
      longDelay,
    ),
    //save sponsor
    ...saveThunkResponse(
      saveTournamentSponsor,
      (state, action) => {
        const sponsorIndex = state.sponsorList.findIndex(
          (s) => s.id === action.payload.id,
        );
        state.sponsorList[sponsorIndex] = action.payload;
      },
      "Tournament Sponsor",
    ),
    //save sponsor round hole
    ...saveThunkResponse(
      saveSponosorRoundHole,
      (state, action) => {
        const sponsorIndex = state.tournamentSponsorRoundHoles.findIndex(
          (s) => s.id === action.payload.id,
        );
        state.tournamentSponsorRoundHoles[sponsorIndex] = {
          ...state.tournamentSponsorRoundHoles[sponsorIndex],
          ...action.payload,
        };
      },
      "Sponsor Assignment",
    ),
    //remove Sponsor
    ...deleteThunkResponse(
      removeTournamentSponsor,
      (state, action) => {
        state.sponsorList = state.sponsorList.filter?.(
          (t) => t.id !== action.meta.arg,
        );
        state.currentSponsor = {};
      },
      "Tournament Sponsor",
    ),
    //sponsor order
    ...saveThunkResponse(
      saveTournamentSponsorOrder,
      (state, action) => {
        // const sponsorIndex = state.sponsorList.findIndex(
        //   (s) => s.id === action.payload.id
        // );
        //state.sponsorList[sponsorIndex] = action.payload;
      },
      "Sponsor",
    ),
  },
});

export const {
  setSponsorList,
  setRoundHoleSponsor,
  setCurrentSponsor,
  setTournamentSponsorRoundHoles,
  addNotification,
  clearNotifications,
} = sponsorSlice.actions;

//add to store and replace template
export const selectSponsorList = (state) =>
  state?.[sponsorSlice.name]?.sponsorList;

export const selectCurrentSponsor = (state) =>
  state?.[sponsorSlice.name]?.currentSponsor;

export const selectSponsorNotifications = (state) =>
  state?.[sponsorSlice.name]?.notifications;

export const selectTournamentSponsorRoundHoles = (state) =>
  state?.[sponsorSlice.name]?.tournamentSponsorRoundHoles;

export const selectLoading = (state) => state?.[sponsorSlice.name]?.loading;

export default sponsorSlice.reducer;

function getTournamentRelationship() {
  const relationships = [];
  relationships.push({
    name: "tournament",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getSponsorRelationship() {
  const relationships = [];
  relationships.push({
    name: "sponsor",
    opt: { ref: "id", included: true },
  });
  return relationships;
}

function getRoundCourseRelationship() {
  const relationships = [];
  relationships.push({
    name: "roundCourse",
    opt: { ref: "id", included: true },
  });
  return relationships;
}
