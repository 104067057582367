import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import { getThunkResponse, usersURL } from "../app/api";
import { GSItemInfo } from "golfstatus_react_components";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
//import { faUserShield } from "@fortawesome/free-solid-svg-icons";

export const selectUser = createAsyncThunk(
  "app/selectUser",
  async (userID, thunkAPI) => {
    let url = `${usersURL}/${userID}`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getManagerInvitations = createAsyncThunk(
  "app/getManagerInvitations",
  async (id, thunkAPI) => {
    const response = await gsApi.get(`${usersURL}/${id}/manager-invitations`);
    return response;
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState: {
    title: "Tournament Management",
    theme: "light",
    loggedInUser: {},
    nav: [],
    notifications: [],
    loading: [],
    history: [],
  },
  reducers: {
    setNav: (state, action) => {
      state.nav = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },
    removeNotification: (state, action) => {
      const newNotifications = state.notifications.filter(
        (not) => not.id !== action.payload.id
      );
      state.notifications = newNotifications;
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    addPathToHistory: (state, action) => {
      state.history.unshift(action.payload);
    },
  },
  extraReducers: {
    [selectUser.fulfilled]: (state, action) => {
      state.loggedInUser = action.payload;
    },
    ...getThunkResponse(getManagerInvitations, (state, action) => {
      state.notifications = action?.payload?.map?.((invitation) => ({
        id: invitation.id,
        header: (
          <GSItemInfo
            header={
              invitation.tournament
                ? "Tournament Manager Invitation"
                : "Tournament Organizer Invitation"
            }
            value={
              invitation?.tournament
                ? `You’ve been invited to join ${invitation?.tournament.name} as a Tournament Manager.`
                : `You’ve been invited to join ${invitation?.organization.name} as a Tournament Organizer. `
            }
            icon={faPaperPlane}
          />
        ),
        state: "success",
        invitation: invitation,
        type: "invite",
      }));
    }),
  },
});

export const {
  setNav,
  setTheme,
  addNotification,
  setNotifications,
  clearNotifications,
  setHistory,
  addPathToHistory,
  removeNotification,
} = appSlice.actions;

export const selectNav = (state) => state.app.nav;
export const selectTitle = (state) => state.app.title;
export const selectAppNotifications = (state) => state.app.notifications;
export const selectTheme = (state) => state.app.theme;
export const selectLoggedInUser = (state) => state.app.loggedInUser;
export const selectHistory = (state) => state.app.history;

export default appSlice.reducer;
