import React, { useEffect } from "react";
import "./tournament-round-leaderboards.scss";

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  getRound,
  getRoundLeaderboards,
  selectCurrentRound,
  selectCurrentRoundLeaderboards,
  selectLoading,
} from "../../../../reducers/roundSlice";
import TournamentLeaderboardLineItem from "../../tournament-leaderboards/tournament-leaderboard-line-item";
import { getRoundName } from "../../../../helpers/Converters";

const TournamentRoundLeaderboards = (props) => {
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/leaderboards/*");
  const navigate = useNavigate();

  const currentRound = useSelector(selectCurrentRound);
  const currentRoundLeaderboards = useSelector(selectCurrentRoundLeaderboards);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getRound(match.params.roundID));
    dispatch(getRoundLeaderboards(match.params.roundID));
  }, [dispatch, match.params.roundID]);

  const addLeaderboard = () => {
    navigate(`/${match?.params?.tournamentID}/leaderboards/add`);
  };
  const viewLeaderboards = () => {
    navigate(`/${match?.params?.tournamentID}/leaderboards`);
  };
  const getActions = () => {
    let actions = [
      {
        name: "View Leaderboards",
        action: viewLeaderboards,
        type: "light-grey",
      },
    ];
    return actions;
  };

  const getPageAction = () => {
    return [
      {
        title: "Add Leaderboard",
        actionClick: addLeaderboard,
        actionIcon: faPlus,
        type: "black no-wrap mobile-icon",
      },
    ];
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: `Round ${getRoundName(currentRound)} Leaderboards & Skins `,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const leaderboardSelected = (leaderboard) => {
    navigate(`/${match?.params?.tournamentID}/leaderboards/${leaderboard.id}`);
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Manage Leaderboards & Skins"
        detail="Once added, round leaderboards will be displayed here."
        actions={[
          {
            title: "Add Leaderboard",
            buttonIcon: faPlus,
            onClick: addLeaderboard,
            type: "black",
          },
        ]}
      />
    );
  };

  const getSortedLeaderboards = () => {
    const sortedLeaderboards = [...currentRoundLeaderboards]?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return sortedLeaderboards;
  };

  const getLeaderboards = () => {
    return (
      <GSItemList
        type="selectable vertical"
        items={getSortedLeaderboards()}
        itemSelected={leaderboardSelected}
        emptyMessage={getEmptyListMessage()}
        loading={loading.includes("getRoundLeaderboards")}
        loadingMainText="Loading Round Leaderboards..."
        listItem={(item) => <TournamentLeaderboardLineItem {...item} />}
      />
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar
          type="form-header"
          header={`Round ${getRoundName(currentRound)} Leaderboards & Skins `}
          pageActions={getPageAction()}
        />
        {getLeaderboards()}
      </gs-form>
    );
  };

  const getDrawer = () => {
    return {
      actions: getActions(),
    };
  };

  return (
    <tournament-round-leaderboards>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      />
    </tournament-round-leaderboards>
  );
};

export default TournamentRoundLeaderboards;
