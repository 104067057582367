import React, { useState } from "react";
import { GSForm, GSRadioGroup, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./tournament-settings-delete.scss";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import { useSelector } from "react-redux";
import {
  clearTournamentNotifications,
  deleteTournament,
  selectCurrentTournament,
  selectTournamentNotifications,
} from "../../../reducers/tournamentSlice";
import TournamentLineItem from "../../tournament-line-item";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setNotifications } from "../../../reducers/appSlice";
import { useNotificationAction, useNotificationBanner, useNotificationNavigation } from "../../../hooks/notificationHooks";

//Name the component
const TournamentSettingsDelete = (props) => {
  const [isValid, setIsValid] = useState(true);
  const [selectedReason, setSelectedReason] = useState({label: "Event Canceled", value: "event_canceled" })

  const dispatch = useDispatch();

  const currentTournament = useSelector(selectCurrentTournament);
  const notifications = useSelector(selectTournamentNotifications)

  const getDeleteReasons = () => {
    return [
      {label: "Tournament Cancellation", value: "event_canceled" },
      {label: "Duplicate Tournament", value: "duplicate" },
      {label: "Test Tournament", value: "test_event" },
      {label: "Other", value: "other" }
    ]
  }

  const navigate = useNavigate();

  useEffect( () => {
    return () => {dispatch(clearTournamentNotifications())}
  }, [dispatch])

  useNotificationNavigation("removed", notifications, "/", "Tournament")
  useNotificationAction("removed", notifications, (notification) => {dispatch(setNotifications([notification]))}, "Tournament")

  const deleteCurrentTournament = () => {
    setIsValid(false)
    let update = { ...currentTournament };
    update.state = "deleted";
    update.deleteReason = selectedReason.value;
    dispatch(deleteTournament(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: deleteCurrentTournament,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Tournament`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getForm = () => {
    let form = removeItemForm(
      currentTournament.name,
      <TournamentLineItem tournament={currentTournament} />
    )

    form[0].fields.push({
      label: "Deletion Reason",
      value:<GSRadioGroup selectedOption={selectedReason} options={getDeleteReasons()} selectionChanged={(option) => {setSelectedReason(option)}} /> 
    })

    return form;
  }

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle(`Delete ${currentTournament.name}`)}
        formSections={getForm()}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearTournamentNotifications())
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <tournament-settings-delete>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-delete>
  );
};

//export the new namet
export default TournamentSettingsDelete;
