import "./tournament-sponsor-hole-assignment.scss";
import React, { useEffect, useMemo } from "react";
import {
  GSEmptyList,
  GSForm,
  GSImage,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faExternalLinkSquareAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { holeAssignmentForm } from "../../../forms/SponsorForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentRounds,
  selectTournamentRounds,
} from "../../../reducers/roundSlice";
import {
  clearNotifications,
  getTournamentSponsor,
  getTournamentSponsors,
  getTournamentSponsorRoundHoles,
  selectCurrentSponsor,
  selectSponsorList,
  selectSponsorNotifications,
  selectTournamentSponsorRoundHoles,
} from "../../../reducers/sponsorSlice";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { useNotificationBanner } from "../../../hooks/notificationHooks";

//Name the component
const TournamentSponsorHoleAssignment = (props) => {
  const navigate = useNavigate();
  const match = useMatch("/:tournamentID/sponsors/:sponsorID/holes");

  const rounds = useSelector(selectTournamentRounds);
  const tournamentSponsorRoundHoles = useSelector(
    selectTournamentSponsorRoundHoles,
  );
  const currentSponsor = useSelector(selectCurrentSponsor);
  const notifications = useSelector(selectSponsorNotifications);
  const sponsorList = useSelector(selectSponsorList);

  const dispatch = useDispatch();

  const context = useMemo(() => ({}), []);

  const lockSponsorAssignment = !sponsorList.some(
    (sponsor) => sponsor.sponsorshipType === "leaderboard",
  );

  useEffect(() => {
    dispatch(getTournamentSponsor(match?.params?.sponsorID));
    dispatch(getTournamentRounds(match?.params?.tournamentID));
    dispatch(getTournamentSponsorRoundHoles(match?.params?.tournamentID));
    dispatch(getTournamentSponsors(match?.params?.tournamentID));
  }, [dispatch, match?.params?.tournamentID, match?.params?.sponsorID]);

  const leftNavigation = () => {
    navigate(-1);
    dispatch(clearNotifications());
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Sponsored Holes`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const defaultBanner = useMemo(
    () => ({
      state: "grey",
      title: getNotificationItemInfo(
        `Selections will automatically be saved.`,
        faSave,
      ),
    }),
    [],
  );

  const getFormTitle = () => {
    return (
      <div className="form-header">
        <GSImage
          size="medium ultra-wide"
          src={currentSponsor.logoMobile}
        ></GSImage>
      </div>
    );
  };

  const getPaidMessage = () => {
    return (
      <GSEmptyList
        title="Paid Feature"
        detail="The Technology Sponsorship must be purchased to unlock in-app hole sponsor assignments. Please contact your sales or client success representative with questions or to learn more."
        actions={[
          {
            title: "learn more",
            rightIcon: faExternalLinkSquareAlt,
            onClick: () => {
              window.open(
                "https://golfstatus.com/technology-sponsor",
                "_blank",
              );
            },
            type: "light-grey",
          },
        ]}
      />
    );
  };

  context.lockSponsorAssignment = lockSponsorAssignment;

  context.getPaidMessage = () => {
    return (
      <GSItemList items={[]} type="vertical" emptyMessage={getPaidMessage()} />
    );
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getFormTitle()}
        formSections={holeAssignmentForm(context, rounds)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawerActions = () => {
    let actions = [];
    return actions;
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  context.getTournamentSponsorRoundHoleOptions = (round) => {
    return tournamentSponsorRoundHoles
      ?.filter?.((trh) => trh?.tournamentRound?.id === round?.id)
      ?.map?.((h) => ({
        label: `Hole ${h.hole}`,
        subLabel: `Par ${h.holePar}`,
        value: h,
      }));
  };

  context.getSponsor = () => {
    return currentSponsor;
  };

  context.viewRoundHoleAssignments = (round) => {
    timeoutAction();
    navigate(`/${match.params.tournamentID}/rounds/${round.id}/hole-sponsors`);
  };

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction,
    defaultBanner,
  });

  return (
    //name the component tag
    <tournament-sponsor-hole-assignment>
      <GSSidePanelPage
        header={getNavigation()}
        banner={lockSponsorAssignment ? "" : banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-sponsor-hole-assignment>
  );
};

//export the new namet
export default TournamentSponsorHoleAssignment;
