import React from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-tee-settings.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getRound,
  getRoundCourses,
  getRoundTees,
  selectCurrentRound,
  selectCurrentRoundCourses,
  selectLoading,
  setCurrentRoundCourses,
} from "../../../../reducers/roundSlice";
import { useEffect } from "react";
import SearchBar from "../../../search-bar";
import TournamentRoundTeeItem from "./tournament-round-tee-item";
import { useState } from "react";
import { getRoundName } from "../../../../helpers/Converters";

//Name the component
const TournamentRoundTeeSettings = (props) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const notifications = [];

  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/rounds/:roundID/courses");

  const currentRoundCourses = useSelector(selectCurrentRoundCourses);
  const currentRound = useSelector(selectCurrentRound);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRoundTees(match.params.roundID));
      dispatch(getRoundCourses(match.params.roundID));
      dispatch(getRound(match.params.roundID));
    }
  }, [dispatch, match?.params?.roundID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${getRoundName(currentRound)} Tees`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getFilteredTees = (tees) => {
    return tees?.filter?.((t) =>
      t.name.toLowerCase?.().includes?.(search?.toLowerCase?.())
    );
  };

  const selectTee = (course, tee) => {
    dispatch(setCurrentRoundCourses([]));
    navigate(`${course.id}/tees/${tee.id}`);
  };

  const getTeeList = (course) => {
    const tees = getFilteredTees(course.roundTees);
    if (tees?.length > 0) {
      return (
        <div className="course">
          <GSActionBar
          type="grey-header x-large-pad"
            header={`${course?.facility?.name} / ${course?.course?.name} `}
          />
          <GSItemList
            type="vertical selectable"
            items={tees}
            listItem={(item) => <TournamentRoundTeeItem {...item} />}
            itemSelected={(tee) => {
              selectTee(course, tee);
            }}
          />
        </div>
      );
    }
    else{
      return <div className="course"><GSItemList type={"vertical"} emptyMessage={getEmptySearchMessage()}/></div>
    }
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the tee you’re looking for..."
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            type: "light-grey",
            onClick: () => {setSearch("")}
          },
          {
            title: "Edit Round",
            type: "light-grey",
            onClick: () => {navigate(`../${match?.params?.roundID}/details`)}
          },
        ]}
      />
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header={`Round ${getRoundName(currentRound)} Tees`} />
        <SearchBar
          search={search}
          setSearch={setSearch}
          placeholder="Search Tees..."
        />
        <GSItemList
          loading={loading?.length !== 0}
          loadingMainText="Loading Courses & Tees"
          emptyMessage={getEmptySearchMessage()}
          type="vertical"
          items={currentRoundCourses}
          listItem={(item) => getTeeList(item)}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {};

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-round-tee-settings>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-round-tee-settings>
  );
};

//export the new namet
export default TournamentRoundTeeSettings;
