import React, { useEffect, useMemo, useState } from "react";
import {
  GSInput,
  GSSidePanelPage,
  GSEmptyList,
  GSActionBar,
  GSItemList,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faPlus,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSettingsManagers,
  selectManagers,
} from "../../../reducers/settingsSlice";

//import scss
import "./tournament-settings-managers.scss";
import {
  clearNotifications,
  resendManagerInvitation,
  selectUsersNotifications,
  setManager,
} from "../../../reducers/userSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";
import TournamentSettingsManagerItem from "./tournament-settings-manager-item";

//Name the component
const TournamentSettingsManagers = (props) => {
  const [search, setSearch] = useState("");

  const userNotifications = useSelector(selectUsersNotifications);
  const currentTournament = useSelector(selectCurrentTournament);
  const managers = useSelector(selectManagers);

  const dispatch = useDispatch();
  const match = useMatch("/:id/settings/managers");
  let navigate = useNavigate();

  const context = useMemo(() => ({}), []);

  context.getDefaultSearch = () => {
    return (
      <div>
        <GSInput
          placeholder="Search Tournament Managers"
          textValue={search}
          leftIcon={faSearch}
          rightIcon={faTimesCircle}
          rightIconClick={clearSearch}
          onChange={(e) => setSearch(e.target.value)}
        ></GSInput>
      </div>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Tournament Managers"
        detail="Tournament Managers are people outside of your organization that you want to have access to your tournament."
        actions={[
          {
            title: "Add Manager",
            onClick: addManager,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term or add a new manager."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {
              setSearch("");
            },
            type: "light-grey",
          },
          {
            title: "Add Manager",
            onClick: addManager,
            buttonIcon: faPlus,
            type: "black no-wrap",
          },
        ]}
      />
    );
  };

  context.getEmptyListMessage = () => {
    if (search !== "") {
      return getEmptySearchMessage();
    }
    return getEmptyListMessage();
  };

  context.getFilteredList = () => {
    let tournamentManagers =
      managers?.tournamentManagers?.map?.((om) => ({
        ...om,
        url: "manager",
        type: "Tournament Manager",
      })) ?? [];

    return [...tournamentManagers].filter?.((m) =>
      m.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
    );
  };

  context.getFilteredOrgList = () => {
    let orgMembers =
      managers?.organizationMembers?.map?.((om) => ({
        ...om,
        url: "member",
        type: "Organization Member",
      })) ?? [];
    let orgManagers =
      managers?.organizationManagers?.map?.((om) => ({
        ...om,
        url: "organizer",
        type: "Tournament Organizer",
      })) ?? [];
    return [...orgMembers, ...orgManagers].filter?.((m) =>
      m.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
    );
  };

  context.resendManagerInvitation = (manager) => {
    dispatch(resendManagerInvitation(manager.id));
  };

  context.managerSelected = (manager) => {
    dispatch(clearNotifications());
    if (manager.url === "manager") {
      navigate(`${manager.url}/${manager.id}`);
    } else {
      // window.open(
      //   `https://organizations.golfstatus.dev/${currentTournament.organization.id}/permissions/${manager.id}/edit-member`
      // );
    }
  };

  useEffect(() => {
    dispatch(getSettingsManagers(match.params.id));
    return () => {
      dispatch(clearNotifications());
    };
  }, [dispatch, match.params.id]);

  const clearSearch = () => {
    setSearch("");
  };

  const getDrawerActions = () => {
    let actions = [];
    return actions;
  };

  const leftNavigation = () => {
    navigate(`/${currentTournament?.id}/settings`);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Users & Permissions`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const addManager = () => {
    dispatch(clearNotifications());
    dispatch(
      setManager({ name: "", email: "", phone: "", organizationName: "" })
    );
    navigate("add");
  };



  const getpageActions = () => {
    return [
      {
        type: "no-wrap black mobile-icon",
        buttonTitle: "Add Manager",
        actionClick: addManager,
        actionIcon: faPlus,
      },
    ];
  };

  const getInput = () => {
    return {
      search,
      setSearch,
      placeholder: "Search Users...",
    };
  };

  const getList = (list, header) => {
    if (list?.length > 0)
      return (
        <div className="user-list">
          <GSActionBar type="grey-header x-large-pad" header={header} />
          <GSItemList
            type="vertical selectable"
            items={list}
            itemSelected={context.managerSelected}
            listItem={(item) => (
              <TournamentSettingsManagerItem
                resendInvitation={context.resendManagerInvitation}
                {...item}
              />
            )}
          />
        </div>
      );
  };

  const getuserList = () => {
    let lists = [];
    const tourneyList = context.getFilteredList();
    const orgList = context.getFilteredOrgList();
    if (tourneyList?.length > 0)
      lists.push({
        header: "Tournament Managers",
        list: tourneyList,
      });

    if (orgList?.length > 0) {
      lists.push({
        header: "Organization Users",
        list: orgList,
      });
    }
    return lists;
  };

  const getContent = (props) => {
    return (
      <gs-form>
        <GSActionBar
        type="form-header"
          header="Users & Permissions"
          pageActions={getpageActions()}
        />
        <SearchBar {...getInput()} />
        <GSItemList
          type="vertical"
          items={getuserList()}
          listItem={(item) => getList(item.list, item.header)}
          emptyMessage={search !== "" ? getEmptySearchMessage() : getEmptyListMessage()}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: userNotifications,
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-settings-managers>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-settings-managers>
  );
};

//export the new name
export default TournamentSettingsManagers;
