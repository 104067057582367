import React, { useEffect, useState } from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSInput,
  GSItemList,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faChevronLeft,
  faSave,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-round-hole-assignment-lock.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRoundNotifications,
  getRound,
  getRoundHoleAssignments,
  saveRoundHoleAssignment,
  selectCurrentRoundHoleAssignments,
  selectRoundNotifications,
  setCurrentRoundHoleAssignment,
} from "../../../../reducers/roundSlice";
import {
  getTournamentTeams,
  selectTournamentTeams,
} from "../../../../reducers/teamsSlice";
import TournamentRoundHoleAssignmentItem from "./tournament-round-hole-assignment-item";
import { getNotificationItemInfo } from "../../../../helpers/Converters";
import {useNotificationBanner} from "../../../../hooks/notificationHooks";
import { useMemo } from "react";

//Name the component
const TournamentRoundHoleAssignmentLock = (props) => {
  const [search, setSearch] = useState("");
  const [parFilter, setParFilter] = useState();

  //hooks
  const navigate = useNavigate();
  const match = useMatch(
    "/:tournamentID/rounds/:roundID/hole-assignments/lock-holes"
  );
  const dispatch = useDispatch();

  //selectors
  const roundHoleAssignments = useSelector(selectCurrentRoundHoleAssignments);
  const notifications = useSelector(selectRoundNotifications);
  const tournamentTeams = useSelector(selectTournamentTeams);
  
  //effects
  useEffect(() => {
    if (match?.params?.roundID) {
      dispatch(getRound(match.params.roundID));
      dispatch(getRoundHoleAssignments(match.params.roundID));
    }
    return () => {dispatch(clearRoundNotifications())}
  }, [dispatch, match?.params?.roundID]);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
    }
  }, [dispatch, match?.params?.tournamentID]);

  // const getDrawerActions = () => {
  //   let actions = [
  //     {
  //       name: "Done",
  //       action: leftNavigation,
  //       type: "black",
  //     },
  //   ];
  //   return actions;
  // };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the hole you’re looking for…"
        detail="Try changing the search term and filter."
        actions={[
          {
            title: "Clear ALl",
            onClick: () => {setSearch("")},
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Lock Holes`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const defaultBanner = useMemo(() => ({title: getNotificationItemInfo(`Selections will be automatically saved.`, faSave), state: "grey"}),[])

  const getHoleAssignments = () => {
    const filteredHoleAssignments = roundHoleAssignments.filter?.((rha) =>
      `${rha.hole}${rha?.position?.toLowerCase?.()}`.includes?.(
        search?.toLowerCase?.()
      ) && rha.holePar === (parFilter ?? rha.holePar)
    );
    return filteredHoleAssignments?.map?.((rha) => ({
      ...rha,
      tournamentTeams: rha.tournamentTeams?.map?.((hatt) =>
        tournamentTeams?.find?.((tt) => tt?.id === hatt?.id)
      ),
    }));
  };

  const toggleLock = (roundHoleAssignment) => {
    let update = { ...roundHoleAssignment };
    update.locked = !update.locked;
    dispatch(setCurrentRoundHoleAssignment(update));
    dispatch(saveRoundHoleAssignment(update));
  };

  const getHoleAssignmentList = () => {
    return (
      <GSItemList
        type="vertical small-gap"
        items={getHoleAssignments()}
        listItem={(item) => (
          <TournamentRoundHoleAssignmentItem
            {...item}
            toggleLock={toggleLock}
          />
        )}
        emptyMessage={getEmptyListMessage()}
      ></GSItemList>
    );
  };

  const parOptions = () => {
    return [{ label: "Par 3", value: 3 }, { label: "Par 4" , value: 4}, { label: "Par 5", value: 5 }];
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Lock Holes"></GSActionBar>
        <div className="search">
          <GSInput
            placeholder="Search Hole Numbers…"
            leftIcon={faSearch}
            rightIcon={faTimesCircle}
            rightIconClick={()=>{setSearch("")}}
            textValue={search}
            onChange={(e) => {
              setSearch(e.target?.value);
            }}
          ></GSInput>
          <GSSelect isClearable options={parOptions()} placeholder="Select Par..." onChange={(option) => {setParFilter(option?.value)}}></GSSelect>
        </div>
        {getHoleAssignmentList()}
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearRoundNotifications())
  };

  // const getDrawer = () => {
  //   return {
  //     actions: getDrawerActions(),
  //   };
  // };

  const [bannerNotifications] = useNotificationBanner({notifications, timeoutAction, defaultBanner})

  return (
    //name the component tag
    <tournament-round-hole-assignment-lock>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        //drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-round-hole-assignment-lock>
  );
};

//export the new namet
export default TournamentRoundHoleAssignmentLock;
