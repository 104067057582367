import React, { useState } from "react";
import {
  GSActionBar,
  GSEmptyList,
  GSItemList,
  GSSelect,
  GSSidePanelPage,
} from "golfstatus_react_components";
import {
  faBolt,
  faChevronLeft,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./tournament-flights-assign-all.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  updateTeamFlight,
  getTournamentPreFlights,
  selectFlightLoading,
  selectTournamentTeamPreflights,
  savePreflight,
  clearNotifications,
  selectFlightNotifications,
  getTournamentFlights,
} from "../../../reducers/flightSlice";
import TournamentTeamItem from "../tournament-leaderboards/tournament-team-item";
import { shuffleIndex } from "../../../helpers/Utilities";
import { getNotificationItemInfo } from "../../../helpers/Converters";
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import SearchBar from "../../search-bar";

//Name the component
const TournamentFlightsAssignAll = (props) => {
  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams?.get("search") ?? "");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch("/:tournamentID/flighting/assign");

  const tournamentPreflights = useSelector(selectTournamentTeamPreflights);
  const loading = useSelector(selectFlightLoading);
  const flightNotifications = useSelector(selectFlightNotifications);

  useEffect(() => {
    if (match?.params?.tournamentID) {
      dispatch(getTournamentPreFlights(match.params.tournamentID));
    }
    return () => {
      dispatch(getTournamentFlights(match.params.tournamentID));
      dispatch(clearNotifications());
    };
  }, [dispatch, match?.params?.tournamentID]);

  const clearAllAssignments = () => {
    navigate("remove-all");
  };

  const autoAssign = () => {
    navigate("auto-assign");
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Clear Assignments",
        action: clearAllAssignments,
        type: "transparent red",
      },
    ];
    return actions;
  };

  const leftNavigation = () => {
    timeoutAction();
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Flight Assignments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const clearSearch = () => {
    setSearch("");
  };

  const manageTeams = () => {
    navigate(`/${match.params.tournamentID}/teams`);
  };

  const preflightSettings = () => {
    navigate(`/${match.params.tournamentID}/flighting/settings`);
  };

  const getEmptyPreflightMessage = () => {
    return (
      <GSEmptyList
        title="Tournament is not preflighted"
        detail="To assign teams to flights the tournament must be preflighted."
        actions={[
          {
            title: "Preflight settings",
            onClick: preflightSettings,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Pre-Flight Players & Teams"
        detail="Once player and teams are added to this tournament, they can be assigned to a flight."
        actions={[
          {
            title: "Add Team",
            buttonIcon: faPlus,
            onClick: manageTeams,
            type: "black",
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term or add a new team."
        actions={[
          {
            title: "Clear Search",
            onClick: clearSearch,
            type: "light-grey",
          },
          {
            title: "Add Team",
            buttonIcon: faPlus,
            onClick: manageTeams,
            type: "black",
          },
        ]}
      />
    );
  };

  const teamFilter = (teams) => {
    return teams?.filter?.((t) =>
      `${t?.tournamentTeam?.contactName} ${
        t?.tournamentTeam?.name
      } ${t?.tournamentTeam?.players?.map?.((p) => p.name)?.join(" ")}`
        ?.toLowerCase?.()
        .includes?.(search.toLowerCase?.())
    );
  };

  const getFlightIndex = (flight, preflight) => {
    return preflight.flightSet.findIndex((fs) => fs === flight);
  };

  const getSelectedTeams = (preflight) => {
    return preflight.teamPreflights
      ?.filter?.((tpf) => tpf.flight !== null)
      .sort?.(
        (a, b) =>
          getFlightIndex(a.flight, preflight) -
            getFlightIndex(b.flight, preflight) || a.position - b.position
      );
  };

  const getAvailableTeams = (preflight) => {
    let availableTeams = preflight.teamPreflights
      .filter?.((tpf) => tpf.flight === null)
      .sort(
        (a, b) =>
          a.tournamentTeam.adjustedHandicap - b.tournamentTeam.adjustedHandicap
      );

    return [...availableTeams, ...getSelectedTeams(preflight)];
  };

  const getPreflightTeams = (preflight, flight) => {
    return preflight.teamPreflights?.filter?.((tpf) => tpf.flight === flight);
  };

  const updateFlight = (flight, team, position) => {
    if (team) {
      let update = {
        ...team,
        flight: flight,
        position: position,
      };
      dispatch(updateTeamFlight({ update }));
      dispatch(savePreflight(update));
    }
  };

  const getNextPosition = (preflight, flight) => {
    return getPreflightTeams(preflight, flight)?.length + 1;
  };

  const getSeletor = (preflight, pf) => {
    return (
      <GSSelect
        placeholder="Select a Flight..."
        onChange={(option) => {
          updateFlight(
            option.value,
            pf,
            getNextPosition(preflight, option.value)
          );
          if (option.value === null) {
            removeFromFlight(pf, preflight);
          }
        }}
        options={[
          ...preflight.flightSet.map((fs) => ({
            label: `${fs} Flight`,
            value: fs,
          })),
          { label: "Unassigned", value: null },
        ]}
        selectedOption={
          pf?.flight
            ? {
                label: `${pf.flight} Flight`,
                value: pf.flight,
              }
            : null
        }
      ></GSSelect>
    );
  };

  const removeFromFlight = (sourceItem, preflight) => {
    if (sourceItem.flight !== null) {
      let sourceFlightTeams = getPreflightTeams(
        preflight,
        sourceItem.flight
      ).filter?.((sft) => sft.id !== sourceItem.id);
      sourceFlightTeams.forEach((sft, index) =>
        updateFlight(sft.flight, sft, index + 1)
      );
    }
  };

  const itemMoved = (
    sourceItem,
    sourceIndex,
    destItem,
    destIndex,
    preflight
  ) => {
    if (!destItem) {
      return;
    }

    if (destItem?.flight === null) {
      updateFlight(null, sourceItem, null);
      removeFromFlight(sourceItem, preflight);
      return;
    }

    let source = { ...sourceItem };

    let flightTeams = [...getPreflightTeams(preflight, destItem?.flight)];

    if (destItem?.flight !== sourceItem.flight) {
      source.position = flightTeams.length + 1;
      flightTeams.push({ ...source });
      if (sourceItem.flight !== null) {
        removeFromFlight(sourceItem, preflight);
      }
    }

    const shuffled = flightTeams.map?.((pft, index) => ({
      ...pft,
      position: shuffleIndex(pft.position, source.position, destItem?.position),
    }));

    shuffled.forEach?.((ft) => updateFlight(destItem?.flight, ft, ft.position));
  };

  const getPreflightInfo = (preflight) => {
    const teams = teamFilter(getAvailableTeams(preflight));
    let preflightIndices = preflight.flightSet?.map?.((fs) => ({
      index: teams.findIndex((t) => t.flight === fs),
      view: (
        <GSActionBar
          type="grey-header x-large-pad"
          key={fs}
          header={`${fs} Flight`}
        />
      ),
    }));
    preflightIndices.push({
      index: teams?.findIndex?.((t) => t.flight === null),
      view: (
        <GSActionBar
          type="grey-header x-large-pad"
          key={"unassigned"}
          header="Unassigned"
          pageActions={[
            {
              title: "Auto Assign",
              actionIcon: faBolt,
              type: "black mobile-icon",
              actionClick: autoAssign,
            },
          ]}
        />
      ),
    });
    return (
      <GSItemList
        type="vertical team-list"
        items={teams}
        isSortable={true}
        enableStep={false}
        itemMoved={(sourceItem, sourceIndex, destItem, destIndex) =>
          itemMoved(sourceItem, sourceIndex, destItem, destIndex, preflight)
        }
        topScrollMargin={150}
        indexItems={preflightIndices}
        listItem={(pf, index) => (
          <div className="preflight">
            <TournamentTeamItem {...pf.tournamentTeam}></TournamentTeamItem>
            {getSeletor(preflight, pf)}
          </div>
        )}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  const getSearch = () => {
    let input = {
      search,
      setSearch,
      placeholder: "Search Players and Teams...",
      type: "sticky-side-panel pad",
    };
    return <SearchBar {...input} />;
  };

  const getContent = () => {
    return (
      <gs-form>
        <GSActionBar type="form-header" header="Flight Assignments" />
        {getSearch()}
        <GSItemList
          type="vertical"
          items={tournamentPreflights}
          listItem={(flight) => getPreflightInfo(flight)}
          emptyMessage={getEmptyPreflightMessage()}
          loading={loading.includes("getTournamentPreFlights")}
          loadingMainText="Loading Flight Teams..."
        ></GSItemList>
      </gs-form>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  let notificationSettings = {
    notifications: flightNotifications,
    timeoutAction,
    defaultBanner: {
      title: getNotificationItemInfo(
        `Selections will be automatically saved.`,
        faInfoCircle
      ),
      state: "grey",
    },
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  return (
    //name the component tag
    <tournament-flights-assign-all>
      <GSSidePanelPage
      loading={loading?.includes?.("getTournamentPreFlights")}
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-flights-assign-all>
  );
};

//export the new namet
export default TournamentFlightsAssignAll;
