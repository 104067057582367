import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-reporting-order-refund.scss";

import {
  useNotificationAction,
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getWarningTitle, warnItemForm } from "../../../forms/DeleteForm";
import { useSelector } from "react-redux";
import {
  clearNotifications,
  getOrders,
  selectNotifications,
  selectOrders,
  voidOrder,
} from "../../../reducers/reportingSlice";
import { useDispatch } from "react-redux";
import TournamentReportingOrderItem from "./tournament-reporting-order-item";

//Name the component
const TournamentReportingOrderVoidOrder = (props) => {
  const [isValid, setIsValid] = useState(true);
  const match = useMatch("/:tournamentID/reporting/:orderID/void-order");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrder = useSelector(selectOrders)?.find?.(
    (o) => o.id === match?.params?.orderID
  );
  const notifications = useSelector(selectNotifications);

  useNotificationNavigation("saved", notifications, -2, "Refund Order");
  useNotificationAction(
    "saved",
    notifications,
    (notification) => {
      dispatch(getOrders(match?.params?.tournamentID));
    },
    "Refund Order"
  );

  const save = () => {
    setIsValid(false);
    dispatch(voidOrder(match?.params?.orderID));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Void",
        isDisabled: !isValid,
        action: save,
        type: "orange",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: "Void Details",
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getWarningTitle("Void Order")}
        formSections={warnItemForm(
          "This order will be cancelled, removed from the list of transactions, and the credit card will not be charged. This cannot be undone.",
          <TournamentReportingOrderItem {...currentOrder} />
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-reporting-order-refund>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-reporting-order-refund>
  );
};

//export the new namet
export default TournamentReportingOrderVoidOrder;
