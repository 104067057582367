import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSImage, GSInfoGroup, GSSelect } from "golfstatus_react_components";
import React from "react";
import './tournament-round-hole-sponsor-item.scss'

const TournamentRoundHoleSponsorItem = (props) => {
  const { hole, holePar, sponsor, setSponsor, getSponsorOptions } = props;
  let group = [
    {
      fit: "no-wrap hide-while-sorting",
      sections: [
        {
          sectionItems: [
            { type: "primary", value: `Hole ${hole}` },
            { type: "secondary", value: `Par ${holePar}` },
          ],
        },
      ],
    },
  ];

  if (sponsor) {
    group.push(
      {
        fit:"hide-mobile",
        sections: [
          {
            sectionItems: [
              {
                type: "primary full-width",
                value: (
                  <GSImage
                    size="medium ultra-wide"
                    src={sponsor?.logoMobile}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              {
                type: "primary full-width",
                value: sponsor?.name,
              },
              {
                type: "secondary full-width capitalize",
                value: `${sponsor?.sponsorshipType} sponsor`,
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            sectionItems: [
              {
                type: "secondary no-wrap",
                value: (
                  <GSButton
                    buttonIcon={faTimesCircle}
                    onClick={() =>
                      setSponsor?.(props, { value: undefined })
                    }
                  />
                ),
              },
            ],
          },
        ],
      }
    );
  } else {
    group.push({
      fit: "stretch",
      sections: [
        {
          sectionItems: [
            {
              type: "primary full-width",
              value: (
                <GSSelect
                placeholder="Select a Sponsor..."
                  options={getSponsorOptions?.()}
                  onChange={(option) => {
                    setSponsor?.(props, option);
                  }}
                />
              ),
            },
          ],
        },
      ],
    });
  }

  return (
    <tournament-round-hole-sponsor-item>
      <div className="hole-assignment">
        <GSInfoGroup
          listType=""
          dataGroups={group}
        ></GSInfoGroup>
      </div>
    </tournament-round-hole-sponsor-item>
  );
};

export default TournamentRoundHoleSponsorItem