import React, { useEffect, useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import {
  faCheckCircle,
  faChevronLeft,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-skins-management-mark-all.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addOnTeamUpdateAll,
  selectCurrentLeaderboardSetting,
  selectLeaderboardNotifications,
  clearNotifications,
  getTournamentAddOnTeams,
  getTournamentAddOns,
  getLeaderboardSetting,
} from "../../../reducers/leaderboardSlice";
import { getTournamentTeams } from "../../../reducers/teamsSlice";
import {
  useNotificationBanner,
  useNotificationNavigation,
} from "../../../hooks/notificationHooks";
import { getWarningTitle, warnItemForm } from "../../../forms/DeleteForm";
import TournamentLeaderboardLineItem from "./tournament-leaderboard-line-item";

//Name the component
const TournamentLeaderboardSkinsManagementMarkAll = (props) => {
  const { status } = props;

  const [isValid] = useState(true);

  const navigate = useNavigate();
  const match = useMatch(
    "/:tournamentID/leaderboards/:leaderboardID/skins-management/:addOnID/*"
  );
  const dispatch = useDispatch();

  const currentLeaderboardSetting = useSelector(
    selectCurrentLeaderboardSetting
  );
  const leaderboardNotifications = useSelector(selectLeaderboardNotifications);

  useEffect(() => {
    if (match?.params?.tournamentID && match?.params?.leaderboardID) {
      dispatch(getTournamentTeams(match.params.tournamentID));
      dispatch(getTournamentAddOnTeams(match.params.tournamentID));
      dispatch(getTournamentAddOns(match.params.tournamentID));
      dispatch(getLeaderboardSetting(match.params.leaderboardID));
    }
  }, [dispatch, match?.params?.tournamentID, match?.params?.leaderboardID]);

  const markAll = () => {
    if (status && match?.params?.addOnID && match?.params?.tournamentID) {
      let update = {
        add_on_id: match.params.addOnID,
        all_in_or_out: status,
        tournament_id: match.params.tournamentID,
      };
      dispatch(addOnTeamUpdateAll(update));
    }
  };

  useNotificationNavigation("saved", leaderboardNotifications, -1, "Teams");

  const getDrawerActions = () => {
    let actions = [
      {
        name: getHeader(),
        isDisabled: !isValid,
        action: markAll,
        type: "orange",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Mark All`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getHeader = () => {
    return match?.params?.["*"] === "mark-all-in" ? "Mark All In" : "Mark All Out"
  }

  const getContent = () => {
    return (
      <GSForm
        formTitle={getWarningTitle(getHeader(), match?.params?.["*"] === "mark-all-in" ? faCheckCircle : faTimesCircle)}
        formSections={warnItemForm(
          `Are you sure you want to override the current selections for this skins game? This cannot be undone.`,
          <TournamentLeaderboardLineItem {...currentLeaderboardSetting} />
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({
    notifications: leaderboardNotifications,
    timeoutAction,
  });

  return (
    //name the component tag
    <tournament-leaderboard-skins-management-mark-all>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-skins-management-mark-all>
  );
};

//export the new namet
export default TournamentLeaderboardSkinsManagementMarkAll;
