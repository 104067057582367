import {
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { getDateTimeOn } from "../../../helpers/Dates";
import logo from "../../../images/website.png";

import "./tournament-promotion-quick-view.scss";

const TournamentPromotionQuickView = (props) => {
  const { name, registrationClose, publicUrl, openRegistration, state } = props;

  const getPrivacy = () => {
    return openRegistration ? "Public Registration" : "Private Registration";
  };

  const getPrivacyState = () => {
    return openRegistration ? "green" : "light-grey";
  };

  const getPrivacyIcon = () => {
    return openRegistration ? faEye : faEyeSlash;
  };

  const logoSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <img src={logo} alt="Logo"></img>,
      },
    ],
  };

  const firstColumn = {
    sections: [
      {
        ...logoSection,
      },
    ],
  };

  const eventInfo = {
    sectionItems: [
      {
        type: "primary  ellipsis full-width",
        value: `${name}'s Event Site`,
      },
    ],
  };

  if (registrationClose) {
    eventInfo.sectionItems.push({
      type: "secondary full-width",
      value: `Registration closes at ${getDateTimeOn(registrationClose)}`,
    });
  }

  const statusInfo = {
    sectionItems: [
      {
        type: "primary no-wrap",
        value: (
          <GSButton
            title={getPrivacy()}
            type={`secondary pill status ${getPrivacyState()}`}
            buttonIcon={getPrivacyIcon()}
          />
        ),
      },
    ],
  };

  const secondColum = {
    fit: "stretch",
    sections: [
      {
        ...eventInfo,
      },
      { ...statusInfo },
    ],
  };

  const viewWebsite = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            type="light-grey "
            rightIcon={faExternalLinkSquareAlt}
            title="view website"
            onClick={(e) => {
              window.open(
                `https://events${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/event/${publicUrl}`,
              );
              e?.stopPropagation?.();
            }}
          ></GSButton>
        ),
      },
    ],
  };

  const viewRegistrationPage = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            type="light-grey "
            rightIcon={faExternalLinkSquareAlt}
            title="Event Registration"
            onClick={(e) => {
              window.open(
                `https://events${process.env.REACT_APP_GOLFSTATUS_APP_COOKIE_DOMAIN}/event/${publicUrl}/register?mobile=true`,
              );
              e?.stopPropagation?.();
            }}
          ></GSButton>
        ),
      },
    ],
  };

  const thirdColumn = () => {
    const sections = [viewWebsite];
    if (state === 'active' && !openRegistration) {
      sections.push(viewRegistrationPage);
    }
    return {
      sections,
    };
  };

  const getGroup = () => {
    let group = [{ ...firstColumn }, { ...secondColum }, { ...thirdColumn() }];
    return group;
  };

  return (
    <tournament-promotion-quick-view>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-promotion-quick-view>
  );
};

export default TournamentPromotionQuickView;
