import {
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInput,
  GSItemList,
  GSListPage,
  GSEmptyList,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { useMatch } from "react-router-dom";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { setTheme } from "../../../reducers/appSlice";
import { selectCurrentTournament } from "../../../reducers/tournamentSlice";
import NavigationListItem from "../../navigation-list-item";
import './tournament-settings.scss';


const TournamentSettings = (props) => {
  const [search, setSearch] = useState("");

  const match = useMatch("/:tournamentID/settings/*");
  const navigate = useNavigate() 
  const dispatch = useDispatch()

  const currentTournament = useSelector(selectCurrentTournament)

  const getPageActions = () => {
    return [];
  };

  const getSearchActions = () => {
    return [];
  };

  const getDefaultSearch = () => {
    return (
      <GSInput
        textValue={search}
        leftIcon={faSearch}
        rightIcon={faTimesCircle}
        placeholder="Search Settings & Preferences..."
        rightIconClick={() => {setSearch("")}}
        onChange={(e) => {
          setSearch(e.target.value);
          if(e.target.value === "dark mode"){
            dispatch(setTheme("dark"))
          }
        }}
      ></GSInput>
    );
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Settings & Preferences"
        detail="Promote your tournament with the settings provided"
        actions={[
          {
            title: "Add",
            type: "black",
          },
        ]}
      />
    );
  };

  const settingSelected = (page) => {
    if(isSidePanelOpen())
    {
      navigate(`/${currentTournament.id}/settings`)
      return
    }
    if(page.isExternal)
    {
      window.open(page.url)
    }
    else{
      navigate(page.url)
    }
  }

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find the setting you’re looking for…"
        detail="Try changing the search term or tap the chat icon to talk with GolfStatus customer support."
        actions={[
          {
            title: "Clear Search",
            onClick: () => {setSearch("")},
            type: "light-grey",
          },
        ]}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getFilteredPages = () => {
    return getSettingsPages(currentTournament).filter(p => p.label.includes(search) || p.details.includes(search))
  }

  const getItemList = () => {
    return (
      <GSItemList
        type="vertical selectable"
        items={getFilteredPages()}
        listItem={(item) => (<NavigationListItem page={item}/>)}
        itemSelected={settingSelected}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
      ></GSItemList>
    );
  };

  return (
    <tournament-promotion>
  
      <GSListPage
        title="Settings & Preferences"
        getDefaultSearch={getDefaultSearch}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
      ></GSListPage>
    </tournament-promotion>
  );
};

export default TournamentSettings;

export const getSettingsPages = (tournament) => {
  let pages = [
    {
      label: "Tournament Details",
      details:
        "Manage tournament name and graphic, organization details, field size, and handicap details.",
      isExternal: false,
      url: "details",
    },
    {
      label: "Users & Permissions",
      details:
        "Provide others with access to manage this tournament.",
      isExternal: false,
      url: "managers",
    },
    {
      label: "Payments",
      details:
        "Manage donations, invoices, receipt customization, and more.",
      isExternal: false,
      url: "payments",
    },
    {
      label: "Tournament Watch Emails",
      details:
        "Manage who receives email notifications for all purchases and donations.",
      isExternal: false,
      url: "default-watch-items",
    }
  ];
  return pages;
};


