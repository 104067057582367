import { activateScorecards, clearRoundNotifications } from "../reducers/roundSlice";
import { clearTournamentNotifications, finalizeTournament, saveTournament } from "../reducers/tournamentSlice";

export const getPrimaryTournamentActions = (tournament) => {
  let actions = [];

  const draftRound = tournament?.tournamentRounds?.find?.(round => round.state === "draft")
  const readyRound = tournament?.tournamentRounds?.find?.(round => round.state === "ready")
  const liveRound = tournament?.tournamentRounds?.find?.(round => round.state === "live")

  if (tournament.numberOfRounds === 0) {
    actions.push({ label: "Create Round", url: `/${tournament.id}/rounds` });
  }

  if (tournament.state === "draft" && tournament.numberOfRounds > 0 ) {
    actions.push({
      label: "Activate Tournament",
      dispatchAction: saveTournament({...tournament, state: "active"}),
      cleanUpAction: clearTournamentNotifications(),
    });
  }

  if (!tournament.isRegistrationActive && !tournament.registrationClosed) {
    actions.push({
      label: "Promote Tournament",
      url: `/${tournament.id}/promotion`,
    });
  }

  if(readyRound){
    actions.push({
      label: `Start Round ${readyRound.number}`,
      dispatchAction: activateScorecards(readyRound.id),
      cleanUpAction: clearRoundNotifications(),
      url: `/${tournament?.id}/rounds/${readyRound?.id}/scoring`,
    });
  }

  if(liveRound){
    actions.push({
      label: `Round ${liveRound.number} Scoring`,
      url: `/${tournament?.id}/rounds/${liveRound?.id}/scoring`,
    });
  }

  if(draftRound){
    actions.push({
      label: `Round ${draftRound.number} Hole Assignments`,
      url: `/${tournament.id}/rounds/${draftRound.id}/hole-assignments`,
    });
  }

  if (tournament.isRegistrationActive ) {
    actions.push({
      label: "Close Registration",
      dispatchAction: saveTournament({...tournament, registrationClose: new Date().toISOString()}),
      cleanUpAction: clearTournamentNotifications(),
    });
  }

  if (tournament.state === "active" && tournament?.tournamentRounds !== undefined && !draftRound && !readyRound && !liveRound) {
    actions.push({
      label: "Finalize Tournament",
      dispatchAction: finalizeTournament(tournament.id),
      cleanUpAction: clearTournamentNotifications(),
    });
  }

  if(actions.length < 3){
    actions.push({
      label: "Edit Tournament",
      url: `/${tournament.id}/settings/details`,
    });
  }

  if((tournament.state === 'final' || tournament.state === 'active') && actions.length < 3){
    actions.push({
      label: "Leaderboards",
      url: `/${tournament.id}/leaderboards`,
    });
  }

  if(actions.length < 3){
    actions.push({
      label: "Reporting & Payouts",
      url: `/${tournament.id}/reporting`,
    });
  }
  
  return actions;
};

export const getSecondaryTournamentActions = (tournament) => {
  let actions = [];

  if(tournament.state !== 'final')
  {
    actions.push({ label: "Manage Flights", url: `/${tournament.id}/flighting` });
    actions.push({ label: "Manage Teams", url: `/${tournament.id}/teams` });
    actions.push({
      label: "Manage Leaderboards",
      url: `/${tournament.id}/leaderboards`,
    });
  }

  
  return actions;
};
