import React, { useState } from "react";
import { GSForm, GSSidePanelPage } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-remove.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  removeLeaderboardSetting,
  selectCurrentLeaderboardSetting,
  selectLeaderboardNotifications,
} from "../../../reducers/leaderboardSlice";
import { getRemoveTitle, removeItemForm } from "../../../forms/DeleteForm";
import { useEffect } from "react";
import {addNotification} from "../../../reducers/appSlice"
import { useNotificationBanner } from "../../../hooks/notificationHooks";
import TournamentLeaderboardLineItem from "./tournament-leaderboard-line-item";
//Name the component
const TournamentLeaderboardRemove = (props) => {
  const [isValid, setIsValid] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentLeaderboardSetting = useSelector(
    selectCurrentLeaderboardSetting
  );
  const leaderboardNotifications = useSelector(selectLeaderboardNotifications);

  const match = useMatch(
    "/:tournamentID/leaderboards/:leaderboardSettingID/remove"
  );

  useEffect(() => {
    if (
      leaderboardNotifications.length === 1 &&
      leaderboardNotifications?.[0]?.result === "removed"
    ) {
      navigate(-2, { replace: true });
      dispatch(clearNotifications())
      dispatch(addNotification(leaderboardNotifications?.[0]))
    }
  }, [navigate, leaderboardNotifications, dispatch]);

  const remove = () => {
    setIsValid(false)
    if (match.params.leaderboardSettingID) {
      dispatch(removeLeaderboardSetting(match.params.leaderboardSettingID));
    }
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Delete",
        isDisabled: !isValid,
        action: remove,
        type: "red",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Delete Leaderboard`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={getRemoveTitle("Delete Leaderboard")}
        formSections={removeItemForm(currentLeaderboardSetting.name, <TournamentLeaderboardLineItem {...currentLeaderboardSetting} />, "delete")}
      ></GSForm>
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [bannerNotifications] = useNotificationBanner({notifications: leaderboardNotifications, timeoutAction})

  return (
    //name the component tag
    <tournament-leaderboard-remove>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </tournament-leaderboard-remove>
  );
};

//export the new namet
export default TournamentLeaderboardRemove;
